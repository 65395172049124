import React, { useEffect, useRef } from "react";
import { TiLocationArrow } from "react-icons/ti";
import { gsap } from "gsap";

const ZeroStocksLearnMoreCustomModal = ({ 
  isOpen, 
  onClose, 
  title = 'No Title Provided', 
  description = 'No content available at this time.', 
  image = '/assets/images/Zero-Stocks.webp' 
}) => {
  const modalRef = useRef(null);
  const closeButtonRef = useRef(null);
  const logoRef = useRef(null);
  const imageRef = useRef(null);
  const descriptionRef = useRef(null);
  const titleRef = useRef(null);
  const bottomCloseButtonRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      // Animation for the entire modal
      gsap.fromTo(
        modalRef.current, 
        { scale: 0.8, opacity: 0, boxShadow: '0 0 0px rgba(64, 224, 208, 0)' }, 
        { 
          scale: 1, 
          opacity: 1, 
          boxShadow: '0 0 30px rgba(64, 224, 208, 0.6)', 
          duration: 0.8, 
          ease: "power3.out" 
        }
      );

      // Turquoise Glow pulse effect on the modal
      gsap.to(modalRef.current, { 
        boxShadow: '0 0 30px rgba(64, 224, 208, 0.8)', 
        repeat: -1, 
        yoyo: true, 
        duration: 2 
      });

      // Glow effect on the close button
      gsap.to(closeButtonRef.current, { 
        boxShadow: '0 0 20px rgba(64, 224, 208, 0.8)', 
        repeat: -1, 
        yoyo: true, 
        duration: 2 
      });

      // Spinning close button animation (Top-right)
      gsap.fromTo(
        closeButtonRef.current, 
        { opacity: 0, rotate: -180 }, 
        { 
          opacity: 1, 
          rotate: 0, 
          duration: 0.8, 
          ease: "power3.out" 
        }
      );

      // Glow and pulse effect on bottom Close button
      gsap.to(bottomCloseButtonRef.current, { 
        boxShadow: '0 0 20px rgba(64, 224, 208, 0.8)', 
        repeat: -1, 
        yoyo: true, 
        duration: 2 
      });

      // Logo animation (in-and-out scale and glow)
      gsap.fromTo(
        logoRef.current, 
        { opacity: 0, scale: 0.8 }, 
        { 
          opacity: 1, 
          scale: 1, 
          boxShadow: '0 0 15px rgba(64, 224, 208, 0.8)', 
          duration: 0.8, 
          ease: "power2.out" 
        }
      );

      // In-and-out scale animation for the logo
      gsap.to(logoRef.current, { 
        scale: 1.1, 
        repeat: -1, 
        yoyo: true, 
        duration: 3, 
        ease: "power1.inOut" 
      });

      // Image animation
      gsap.fromTo(
        imageRef.current, 
        { opacity: 0, scale: 0.9 }, 
        { 
          opacity: 1, 
          scale: 1, 
          duration: 0.8, 
          ease: "power3.out", 
          delay: 0.4 
        }
      );

      // Description list animation (staggered)
      const descriptionItems = descriptionRef.current?.querySelectorAll("li");
      if (descriptionItems) {
        gsap.fromTo(
          descriptionItems, 
          { opacity: 0, x: -20 }, 
          { 
            opacity: 1, 
            x: 0, 
            stagger: 0.1, 
            duration: 0.6, 
            ease: "power3.out", 
            delay: 0.6 
          }
        );
      }

      // Title letter-by-letter drop-in animation
      const titleChars = titleRef.current?.querySelectorAll("span");
      if (titleChars) {
        gsap.fromTo(
          titleChars, 
          { opacity: 0, y: -50 }, 
          { 
            opacity: 1, 
            y: 0, 
            stagger: 0.05, 
            duration: 0.8, 
            ease: "power3.out" 
          }
        );
      }

      // Radial Glow on hover for bottom close button
      const button = bottomCloseButtonRef.current;

      button.addEventListener('mouseenter', () => {
        gsap.to(button, { 
          boxShadow: '0 0 30px rgba(64, 224, 208, 0.8)', 
          duration: 0.4 
        });
      });

      button.addEventListener('mouseleave', () => {
        gsap.to(button, { 
          boxShadow: '0 0 0px rgba(64, 224, 208, 0)', 
          duration: 0.4 
        });
      });

      return () => {
        button.removeEventListener('mouseenter', () => {});
        button.removeEventListener('mouseleave', () => {});
      };

    }
  }, [isOpen]);

  const handleClose = () => {
    // Portal zoom-out effect for the modal
    gsap.to(modalRef.current, {
      scale: 0.1, 
      opacity: 0, 
      duration: 0.6, 
      ease: "power3.inOut"
    });

    // Portal zoom-out effect for the title
    const titleChars = titleRef.current?.querySelectorAll("span");
    if (titleChars) {
      gsap.to(titleChars, { 
        scale: 0.1, 
        opacity: 0, 
        duration: 0.6, 
        ease: "power3.inOut" 
      });
    }

    // Call the onClose function after animation
    gsap.delayedCall(0.6, onClose);
  };

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-[9999] transition-opacity duration-300"
      role="dialog" 
      aria-modal="true"
      aria-labelledby="modal-title"
    >
      <div 
        className="relative bg-black text-white rounded-2xl shadow-2xl w-full max-w-4xl p-6 md:p-10 border border-silver"
        ref={modalRef}
      >
        
        {/* Close Button (Top Right) */}
        <button 
          onClick={handleClose} 
          ref={closeButtonRef}
          className="absolute top-4 right-4 text-gray-400 hover:text-black text-2xl transition-colors"
          aria-label="Close modal"
        >
          ✕
        </button>

        {/* ZeroBank Logo */}
        <div className="flex justify-center mb-4">
          <div className="w-32 h-32 p-2 border-2 border-silver rounded-xl shadow-md bg-black flex items-center justify-center">
            <img 
              src="/assets/images/Zero-Stocks.webp" 
              alt="Zero Bank Logo" 
              className="w-24 h-auto rounded-lg" 
              ref={logoRef}
            />
          </div>
        </div>

        {/* Image Section */}
        {image && (
          <div className="mb-6 p-2 border border-silver rounded-xl">
            <img 
              src={image} 
              alt={title} 
              className="w-full h-40 rounded-xl shadow-md object-cover" 
              ref={imageRef} 
            />
          </div>
        )}

        {/* Title */}
        <h2 
          id="modal-title" 
          className="text-2xl font-extrabold text-center mb-4"
          ref={titleRef}
        >
          {title.split('').map((char, index) => (
            <span key={index} className="inline-block">{char}</span>
          ))}
        </h2>

        {/* Description */}
        <div 
          className="text-white/90 text-sm md:text-base leading-4"
          ref={descriptionRef}
        >
          <ul className="list-disc list-inside space-y-2">
            {description.split('\n').filter(point => point.trim() !== '').map((point, index) => (
              <li key={index} className="flex items-start gap-2">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  strokeWidth="2" 
                  stroke="#C0C0C0" 
                  className="w-5 h-5 mt-[2px] flex-shrink-0"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                </svg>
                <span>{point}</span>
              </li>
            ))}
          </ul>
        </div>

        {/* Footer with Close Button */}
        <div className="flex justify-center mt-6">
      <button
        onClick={handleClose}
        ref={bottomCloseButtonRef}
        className="py-2 px-8 rounded-full border border-silver text-silver hover:border-gray-900 hover:text-gray-900 hover:bg-teal-500 transition-all duration-300 ease-in-out flex items-center gap-2"

        aria-label="Close modal"
      >
        Close
        <TiLocationArrow className="text-xl" />
      </button>
    </div>
      </div>
    </div>
  );
};

export default ZeroStocksLearnMoreCustomModal;
