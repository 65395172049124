export const CoreFeatureDetails = {
  "Automated Market Maker (AMM)": {
    title: "Automated Market Maker (AMM)",
    description: `
      Zero-Dex’s AMM facilitates decentralised token swaps without intermediaries, ensuring efficient and transparent trading for all users.
      
      **Key Features:**
      **Liquidity Pools:** Users can contribute to pools to enable seamless token swaps.
      **Price Discovery:** Dynamic algorithms determine token prices based on supply and demand.
      **Decentralisation:** Eliminates the need for traditional order books or centralised exchanges.
      **Slippage Control:** Minimises price impact during large trades.
    `,
    image: "/assets/images/zero-dex/automated-market-maker.webp",
  },
  "Cross-Chain Compatibility": {
    title: "Cross-Chain Compatibility",
    description: `
      Unlock broader trading opportunities by enabling seamless transactions across multiple blockchain ecosystems with Zero-Dex.
      
      **Key Features:**
      **Multi-Blockchain Support:** Trade assets on Ethereum, Binance Smart Chain, and more.
      **Interoperability Bridges:** Ensure secure asset transfers between chains.
      **Enhanced Liquidity:** Access liquidity from multiple networks in one platform.
      **User-Friendly Interface:** Simplifies cross-chain swaps for all users.
    `,
    image: "/assets/images/zero-dex/cross-chain-compatibility.webp",
  },
  "Yield Farming": {
    title: "Yield Farming",
    description: `
      Stake tokens in liquidity pools to earn rewards and generate passive income with Zero-Dex’s yield farming tools.
      
      **Key Features:**
      **High APYs:** Competitive returns on staked assets.
      **Flexible Lock-In Periods:** Choose staking durations that suit your strategy.
      **Reward Tokens:** Earn platform-native tokens or partner assets as rewards.
      **Dashboard Management:** Track earnings and pool performance in real-time.
    `,
    image: "/assets/images/zero-dex/yield-farming.webp",
  },
  "Blockchain Security": {
    title: "Blockchain Security",
    description: `
      Zero-Dex ensures transaction immutability and transparency, leveraging decentralised blockchain technology for ultimate security.
      
      **Key Features:**
      **Immutable Ledger:** Prevents unauthorised alterations of transaction records.
      **Decentralised Architecture:** Eliminates central points of failure.
      **Auditability:** All transactions are verifiable on the blockchain.
      **Smart Contract Security:** Regular audits ensure platform integrity.
    `,
    image: "/assets/images/zero-dex/blockchain-security.webp",
  },
  "Real-Time Trading": {
    title: "Real-Time Trading",
    description: `
      Experience lightning-fast transaction execution with Zero-Dex, reducing delays and slippage for an optimised trading experience.
      
      **Key Features:**
      **Low Latency:** Trades are executed in milliseconds.
      **High Throughput:** Supports large transaction volumes without performance degradation.
      **Dynamic Slippage Control:** Ensures minimal slippage even during volatile markets.
      **Instant Settlement:** Funds are available immediately after trade execution.
    `,
    image: "/assets/images/zero-dex/real-time-trading.webp",
  },
  "Advanced Analytics": {
    title: "Advanced Analytics",
    description: `
      Gain actionable insights into market trends, trading patterns, and liquidity performance to enhance your decision-making with Zero-Dex’s analytics.
      
      **Key Features:**
      **Market Trends Dashboard:** Visualise token performance and trading volumes.
      **Liquidity Insights:** Monitor pool contributions and rewards in real-time.
      **Customisable Reports:** Export detailed analytics tailored to your needs.
      **Predictive Analytics:** Forecast market movements based on historical data.
    `,
    image: "/assets/images/zero-dex/advanced-analytics.webp",
  },
  "Decentralised Governance": {
    title: "Decentralised Governance",
    description: `
      Participate in platform decision-making with Zero-Dex’s decentralised governance system, giving users the power to shape the platform’s future.
      
      **Key Features:**
      **Voting System:** Vote on proposals for platform improvements and policies.
      **Proposal Creation:** Submit ideas for community consideration and approval.
      **Transparent Governance:** All votes and proposals are recorded on-chain.
      **Incentivized Participation:** Earn rewards for active governance involvement.
    `,
    image: "/assets/images/zero-dex/decentralised-governance.webp",
  },
  "Low Fees": {
    title: "Low Fees",
    description: `
      Maximise profitability with Zero-Dex’s cost-efficient fee structure, designed to minimise transaction costs for trades and swaps.
      
      **Key Features:**
      **Flat Fee Structure:** Transparent and predictable fees for all transactions.
      **Gas Optimisation:** Efficient contracts reduce blockchain gas fees.
      **Discount Incentives:** Enjoy reduced fees with native token usage.
      **Global Accessibility:** Low fees encourage participation from all user tiers.
    `,
    image: "/assets/images/zero-dex/low-fees.webp",
  },
  "Liquidity Pool Management": {
    title: "Liquidity Pool Management",
    description: `
      Simplify liquidity contributions with Zero-Dex’s management tools, allowing users to monitor, add, or remove liquidity effortlessly.
      
      **Key Features:**
      **Intuitive Pool Management:** User-friendly interfaces for pool contributions.
      **Performance Monitoring:** Track pool ROI and performance metrics in real time.
      **Auto-Compounding:** Automatically reinvest rewards for maximum returns.
      **Customisable Pools:** Set parameters like fees and token weights for tailored strategies.
    `,
    image: "/assets/images/zero-dex/liquidity-pool-management.webp",
  },
};


export const TechnicalCapabilitiesDetails = {
  "Microservices-Based Architecture": {
    title: "Microservices-Based Architecture",
    description: `
      Zero-Dex’s architecture is built on microservices to ensure scalable, modular components that enhance trading performance and uptime.
      
      **Key Features:**
      **Scalability:** Seamlessly handle increasing user and transaction volumes without compromising performance.
      **Modular Design:** Independent modules allow for isolated upgrades and maintenance.
      **Fault Tolerance:** Minimise downtime with redundancy and failover systems.
      **High Availability:** Guarantees consistent access and uptime for all users.
    `,
    image: "/assets/images/zero-dex/microservices-architecture.webp",
  },
  "Automated Market Maker (AMM) Engine": {
    title: "Automated Market Maker (AMM) Engine",
    description: `
      Zero-Dex’s AMM engine optimises token swaps and ensures efficient pricing with deep liquidity across trading pairs.
      
      **Key Features:**
      **Dynamic Liquidity Allocation:** Adjusts liquidity based on market conditions.
      **Efficient Pricing Algorithms:** Guarantees fair market rates with minimal slippage.
      **Seamless Token Swaps:** Eliminates intermediaries for decentralised trading.
      **Fee Optimisation:** Reduces costs for both liquidity providers and traders.
    `,
    image: "/assets/images/zero-dex/amm-engine.webp",
  },
  "Cross-Chain Compatibility": {
    title: "Cross-Chain Compatibility",
    description: `
      Enable seamless multi-chain transactions with Zero-Dex, integrating major blockchain ecosystems like Ethereum, Binance Smart Chain, and more.
      
      **Key Features:**
      **Multi-Chain Support:** Trade assets across various blockchain networks.
      **Interoperability Bridges:** Ensure secure and efficient token transfers between chains.
      **Unified Trading Experience:** Access liquidity from multiple chains in a single interface.
      **Enhanced Flexibility:** Expand trading options with a diverse set of blockchain assets.
    `,
    image: "/assets/images/zero-dex/cross-chain-compatibility.webp",
  },
  "Real-Time Trade Matching": {
    title: "Real-Time Trade Matching",
    description: `
      Facilitates instantaneous trade execution and order matching, ensuring high-speed decentralised transactions on Zero-Dex.
      
      **Key Features:**
      **Low Latency:** Trade execution occurs within milliseconds, ensuring minimal delays.
      **Optimised Matching Engine:** Matches buy and sell orders seamlessly.
      **Scalable Infrastructure:** Handles large trading volumes without performance degradation.
      **Dynamic Slippage Control:** Minimises price impacts on large trades.
    `,
    image: "/assets/images/zero-dex/real-time-matching.webp",
  },
  "API-First Integration": {
    title: "API-First Integration",
    description: `
      Zero-Dex provides developers with robust APIs, enabling seamless integration of its liquidity pools, trading, and analytics into external applications.
      
      **Key Features:**
      **Comprehensive Documentation:** Simplifies API integration for developers.
      **Real-Time Data Access:** Fetch live trading data, liquidity metrics, and analytics.
      **Custom Integration Options:** Adapt APIs to suit unique application needs.
      **High Reliability:** APIs designed for scalability and minimal downtime.
    `,
    image: "/assets/images/zero-dex/api-integration.webp",
  },
  "Cloud-Native Scalability": {
    title: "Cloud-Native Scalability",
    description: `
      Zero-Dex operates on cloud platforms such as AWS and GCP, ensuring high availability, disaster recovery, and global reach.
      
      **Key Features:**
      **Elastic Scaling:** Automatically adjusts resources to meet user demand.
      **Disaster Recovery:** Cloud-based backups protect against data loss.
      **Global Availability:** Data centers worldwide provide low-latency access.
      **Continuous Monitoring:** Ensures performance and uptime across all regions.
    `,
    image: "/assets/images/zero-dex/cloud-scalability.webp",
  },
};


export const KeyBenefitsDetails = {
  "Enhanced Liquidity Accessibility": {
    title: "Enhanced Liquidity Accessibility",
    description: `
      Zero-Dex provides seamless access to deep liquidity pools, ensuring minimal slippage and optimal trading conditions for users.
      
      **Key Features:**
      **Deep Liquidity Pools:** Access extensive liquidity for high-volume trades.
      **Minimised Slippage:** Execute large trades without significant price impact.
      **Global Access:** Connect to liquidity from multiple blockchain networks.
      **Dynamic Pool Balancing:** Optimise liquidity distribution for popular trading pairs.
    `,
    image: "/assets/images/zero-dex/liquidity-accessibility.webp",
  },
  "Decentralised and Transparent Trading": {
    title: "Decentralised and Transparent Trading",
    description: `
      Trade with confidence knowing that every transaction is secured and transparent, leveraging the immutability of blockchain technology.
      
      **Key Features:**
      **On-Chain Verification:** All trades are recorded on the blockchain for complete transparency.
      **Trustless Transactions:** Execute trades without relying on intermediaries.
      **Immutable Records:** Ensure that all transaction data is tamper-proof.
      **Enhanced Security:** Benefit from blockchain’s inherent security features.
    `,
    image: "/assets/images/zero-dex/decentralised-trading.webp",
  },
  "Cross-Chain Token Swaps": {
    title: "Cross-Chain Token Swaps",
    description: `
      Facilitate token swaps across multiple blockchain ecosystems, opening up broader market opportunities and trading possibilities.
      
      **Key Features:**
      **Multi-Chain Support:** Swap tokens across Ethereum, Binance Smart Chain, and more.
      **Interoperability Bridges:** Enable secure and efficient cross-chain transactions.
      **Unified Interface:** Trade seamlessly without switching between platforms.
      **Expanded Asset Options:** Access a wide variety of tokens from multiple ecosystems.
    `,
    image: "/assets/images/zero-dex/cross-chain-swaps.webp",
  },
  "Lower Trading Fees": {
    title: "Lower Trading Fees",
    description: `
      Enjoy competitive trading fees with Zero-Dex’s decentralised model, eliminating costly intermediaries and maximising cost efficiency.
      
      **Key Features:**
      **Transparent Fee Structure:** No hidden charges or unexpected costs.
      **Reduced Costs:** Lower fees compared to traditional exchanges.
      **Fee Incentives:** Earn rewards for providing liquidity or staking.
      **Optimised for Traders:** Designed to benefit frequent and high-volume traders.
    `,
    image: "/assets/images/zero-dex/lower-trading-fees.webp",
  },
  "User-Controlled Assets": {
    title: "User-Controlled Assets",
    description: `
      Retain complete control of your assets with Zero-Dex’s non-custodial wallet integrations, ensuring true decentralisation and security.
      
      **Key Features:**
      **Non-Custodial Wallets:** Maintain full ownership of your assets.
      **Enhanced Privacy:** No need to share private keys or sensitive information.
      **Direct Control:** Manage, trade, and store tokens without third-party oversight.
      **Secure Storage:** Use decentralised wallets like MetaMask and WalletConnect.
    `,
    image: "/assets/images/zero-dex/user-controlled-assets.webp",
  },
  "Real-Time Trade Execution": {
    title: "Real-Time Trade Execution",
    description: `
      Experience lightning-fast order matching and trade execution, optimising your trading experience with Zero-Dex.
      
      **Key Features:**
      **Instant Order Matching:** Execute trades without delay.
      **Low Latency:** Ensure high-speed transactions for competitive trading.
      **Efficient Matching Engine:** Handles high trading volumes seamlessly.
      **Optimised for Scalability:** Supports real-time trading for global users.
    `,
    image: "/assets/images/zero-dex/real-time-execution.webp",
  },
};


export const UseCasesDetails = {
  "Decentralised Trading": {
    title: "Decentralised Trading",
    description: `
      Empower users with secure and transparent peer-to-peer token swaps, eliminating the need for centralised exchanges and intermediaries.
      
      **Key Features:**
      **Trustless Transactions:** Conduct trades directly on the blockchain without third-party oversight.
      **Immutable Records:** All transactions are recorded transparently on-chain.
      **Enhanced Security:** Minimise risks associated with centralised exchange vulnerabilities.
      **Global Accessibility:** Enable trading for users worldwide without geographic restrictions.
    `,
    image: "/assets/images/zero-dex/decentralised-trading.webp",
  },
  "Cross-Chain Liquidity Management": {
    title: "Cross-Chain Liquidity Management",
    description: `
      Seamlessly manage liquidity across multiple blockchain ecosystems, enhancing connectivity and access within the DeFi space.
      
      **Key Features:**
      **Multi-Chain Integration:** Support for Ethereum, Binance Smart Chain, Polygon, and more.
      **Interoperable Liquidity Pools:** Enable liquidity sharing across chains.
      **Reduced Fragmentation:** Centralise liquidity management for multiple tokens and platforms.
      **Improved DeFi Connectivity:** Enhance trading opportunities across ecosystems.
    `,
    image: "/assets/images/zero-dex/cross-chain-liquidity.webp",
  },
  "Yield Farming & Staking": {
    title: "Yield Farming & Staking",
    description: `
      Maximise user earnings by providing robust yield farming and staking opportunities within Zero-Dex’s liquidity pools.
      
      **Key Features:**
      **Passive Income:** Earn rewards by staking tokens in liquidity pools.
      **Dynamic APY Rates:** Optimise returns based on pool activity and market conditions.
      **User-Friendly Interface:** Simplify staking and yield farming processes.
      **Integrated DeFi Tools:** Access analytics to track earnings and manage stakes.
    `,
    image: "/assets/images/zero-dex/yield-farming-staking.webp",
  },
  "Token Swapping for DeFi Protocols": {
    title: "Token Swapping for DeFi Protocols",
    description: `
      Provide fast, efficient, and cost-effective token swaps for users engaging with decentralised finance platforms.
      
      **Key Features:**
      **Optimised Swaps:** Ensure minimal slippage and competitive pricing for token swaps.
      **DeFi Integration:** Seamlessly connect with major DeFi platforms.
      **Scalable Infrastructure:** Handle high transaction volumes without delays.
      **Real-Time Execution:** Enable instant swaps to support active trading strategies.
    `,
    image: "/assets/images/zero-dex/token-swapping.webp",
  },
  "Institutional Liquidity Providers": {
    title: "Institutional Liquidity Providers",
    description: `
      Attract institutional participants by offering deep liquidity pools with minimised slippage and robust security measures.
      
      **Key Features:**
      **High Liquidity:** Support for large-scale trades with minimal price impact.
      **Customisable Pool Configurations:** Cater to institutional needs with tailored pool settings.
      **Advanced Reporting:** Provide detailed insights and analytics for liquidity management.
      **Regulatory Compliance:** Ensure adherence to financial regulations for institutional trust.
    `,
    image: "/assets/images/zero-dex/institutional-liquidity.webp",
  },
  "NFT Market Liquidity": {
    title: "NFT Market Liquidity",
    description: `
      Facilitate liquidity and trading opportunities within NFT marketplaces, unlocking new potential for tokenised digital assets.
      
      **Key Features:**
      **Seamless NFT Integration:** Connect liquidity pools with leading NFT platforms.
      **Tokenised Asset Trading:** Support trading of fractionalised NFTs and other digital assets.
      **Market Expansion:** Enable efficient trading for rare and high-value NFTs.
      **Dynamic Pricing Models:** Optimise NFT liquidity with real-time pricing adjustments.
    `,
    image: "/assets/images/zero-dex/nft-liquidity.webp",
  },
};


export const SecurityAndComplianceDetails = {
  "Blockchain-Backed Transactions": {
    title: "Blockchain-Backed Transactions",
    description: `
      Zero-Dex leverages blockchain technology to ensure every trade and liquidity operation is transparent, immutable, and secure.
      
      **Key Features:**
      **Transparent Trading:** All transactions are recorded on-chain for maximum transparency.
      **Immutable Records:** Blockchain's immutability ensures trade data cannot be tampered with.
      **Decentralised Security:** Removes reliance on centralised systems for enhanced security.
      **Trustless Environment:** Users retain control over assets throughout the trading process.
    `,
    image: "/assets/images/zero-dex/blockchain-transactions.webp",
  },
  "End-to-End Encryption": {
    title: "End-to-End Encryption",
    description: `
      Safeguard user data and trades with advanced encryption protocols that ensure secure data transfer and storage.
      
      **Key Features:**
      **Data Protection:** Encrypt sensitive information at rest and in transit.
      **Enhanced Privacy:** Prevent unauthorised access to user data and trading activities.
      **Industry Standards:** Compliant with AES-256 encryption standards.
      **Secure Key Management:** Robust methods for managing encryption keys.
    `,
    image: "/assets/images/zero-dex/encryption.webp",
  },
  "Two-Factor Authentication (2FA)": {
    title: "Two-Factor Authentication (2FA)",
    description: `
      Enhance user account security with an additional authentication layer to prevent unauthorised access.
      
      **Key Features:**
      **Multi-Layered Security:** Protect accounts with something users know (password) and something they have (OTP or device).
      **Device Integration:** Use mobile apps or email for authentication codes.
      **Real-Time Alerts:** Notify users of suspicious login attempts.
      **Ease of Use:** Seamless integration with the Zero-Dex platform.
    `,
    image: "/assets/images/zero-dex/2fa.webp",
  },
  "Real-Time Fraud Detection": {
    title: "Real-Time Fraud Detection",
    description: `
      Monitor trading and liquidity operations with AI-driven algorithms that detect and mitigate fraudulent activities instantly.
      
      **Key Features:**
      **Anomaly Detection:** Identify unusual trading behaviors in real time.
      **Proactive Security:** Block suspicious transactions before they are executed.
      **AI-Powered Analysis:** Continuously improve fraud detection models with machine learning.
      **24/7 Monitoring:** Ensure constant oversight of platform activities.
    `,
    image: "/assets/images/zero-dex/fraud-detection.webp",
  },
  "Zero Trust Architecture": {
    title: "Zero Trust Architecture",
    description: `
      Implement a security model that prioritizes verification and minimises risks associated with insider threats and unauthorised actions.
      
      **Key Features:**
      **Verification-First Approach:** Require identity verification at every access point.
      **Granular Permissions:** Define specific access levels for users and devices.
      **Continuous Monitoring:** Validate trustworthiness of all actions in real time.
      **Insider Threat Protection:** Reduce risks associated with internal misuse.
    `,
    image: "/assets/images/zero-dex/zero-trust.webp",
  },
  "Regulatory Compliance": {
    title: "Regulatory Compliance",
    description: `
      Adhere to global standards such as KYC and AML, creating a compliant and secure environment for decentralised trading.
      
      **Key Features:**
      **KYC Integration:** Verify user identities to prevent fraud and financial crimes.
      **AML Protocols:** Monitor transactions to ensure compliance with anti-money laundering regulations.
      **Global Standards:** Align with major regulatory frameworks such as GDPR and FATF.
      **Audit Trails:** Maintain complete transaction logs for regulatory review.
    `,
    image: "/assets/images/zero-dex/kyc-aml.webp",
  },
};


export const BusinessBenefitsDetails = {
  "Revenue Growth Through Liquidity Provision": {
    title: "Revenue Growth Through Liquidity Provision",
    description: `
      Drive significant revenue by attracting traders and liquidity providers to Zero-Dex’s deep liquidity pools, offering competitive advantages like minimal slippage and high rewards.
      
      **Key Benefits:**
      **Incentivized Liquidity:** Reward liquidity providers with yield farming opportunities.
      **Higher Trading Volume:** Minimal slippage encourages frequent trading activity.
      **Fee Generation:** Earn consistent revenue from transaction fees within liquidity pools.
      **Institutional Participation:** Attract large-scale liquidity providers with robust pool mechanics.
    `,
    image: "/assets/images/zero-dex/liquidity-revenue-growth.webp",
  },
  "Enhanced Market Reach": {
    title: "Enhanced Market Reach",
    description: `
      Expand your platform’s presence by enabling cross-chain trading and liquidity management, opening new opportunities in diverse blockchain ecosystems.
      
      **Key Benefits:**
      **Cross-Chain Compatibility:** Integrate with multiple blockchains like Ethereum, Binance Smart Chain, and Solana.
      **Broader User Base:** Attract traders and developers from various ecosystems.
      **DeFi Expansion:** Enable users to access a wide range of decentralised financial applications.
      **Global Accessibility:** Empower users worldwide with multi-chain connectivity.
    `,
    image: "/assets/images/zero-dex/market-reach.webp",
  },
  "Increased Platform Engagement": {
    title: "Increased Platform Engagement",
    description: `
      Boost user retention and increase trading volume by offering seamless token swaps, staking opportunities, and attractive yield farming incentives.
      
      **Key Benefits:**
      **User-Centric Features:** Provide tools like staking and yield farming to encourage active participation.
      **Higher Retention Rates:** Engage users with frequent rewards and low trading fees.
      **Community Growth:** Build a loyal user base with gamified incentives.
      **Improved Trading Volume:** More engaged users contribute to higher trading activity.
    `,
    image: "/assets/images/zero-dex/platform-engagement.webp",
  },
  "Operational Efficiency": {
    title: "Operational Efficiency",
    description: `
      Simplify trading operations and reduce overhead costs with Zero-Dex’s automated AMM and efficient fee structures, minimising the need for manual intervention.
      
      **Key Benefits:**
      **Automated Processes:** Reduce reliance on manual trading systems.
      **Cost Efficiency:** Lower operational costs through smart contract automation.
      **High Uptime:** Cloud-native infrastructure ensures seamless trading.
      **Scalable Operations:** Handle increasing user activity without performance bottlenecks.
    `,
    image: "/assets/images/zero-dex/operational-efficiency.webp",
  },
  "Regulatory Compliance Enablement": {
    title: "Regulatory Compliance Enablement",
    description: `
      Ensure compliance with global financial regulations through integrated KYC/AML tools and real-time transaction monitoring for a secure and trusted trading environment.
      
      **Key Benefits:**
      **KYC Integration:** Simplify user verification to meet regulatory requirements.
      **AML Protocols:** Monitor transactions for suspicious activities.
      **Compliant Ecosystem:** Align with global standards such as GDPR and FATF.
      **Reduced Risk:** Mitigate the risk of regulatory penalties with built-in compliance features.
    `,
    image: "/assets/images/zero-dex/compliance-enablement.webp",
  },
  "Enhanced Brand Trust": {
    title: "Enhanced Brand Trust",
    description: `
      Build strong credibility among users and institutional clients by providing a secure, transparent, and reliable platform for cross-chain trading and liquidity management.
      
      **Key Benefits:**
      **Transparent Operations:** Blockchain technology ensures full transaction visibility.
      **Secure Environment:** Gain user confidence with advanced security protocols.
      **Institutional Appeal:** Demonstrate reliability to attract enterprise clients.
      **User Loyalty:** Foster trust through consistent performance and low fees.
    `,
    image: "/assets/images/zero-dex/brand-trust.webp",
  },
};


export const FutureEnhancementsDetails = {
  "Cross-Chain Asset Bridging": {
    title: "Cross-Chain Asset Bridging",
    description: `
      Expand Zero-Dex's compatibility to support seamless asset transfers across emerging blockchain ecosystems, enhancing interoperability and market access.
      
      **Key Features:**
      **Multi-Chain Integration:** Connect with blockchains like Solana, Polkadot, and Avalanche.
      **Asset Portability:** Allow users to transfer assets easily across networks.
      **DeFi Ecosystem Expansion:** Enable access to a broader range of decentralised applications.
      **Secure Bridging Mechanisms:** Employ smart contract verification for secure cross-chain transactions.
    `,
    image: "/assets/images/zero-dex/cross-chain-bridging.webp",
  },
  "Advanced AMM Features": {
    title: "Advanced AMM Features",
    description: `
      Introduce customisable Automated Market Maker (AMM) configurations, empowering users to optimise liquidity pools and trading parameters.
      
      **Key Features:**
      **Dynamic Fee Rates:** Allow users to set variable fees based on market conditions.
      **Custom Liquidity Weights:** Enable tailored pool configurations for better asset balancing.
      **Enhanced Pool Analytics:** Provide real-time insights into pool performance.
      **Incentive Structures:** Support rewards for specific AMM configurations.
    `,
    image: "/assets/images/zero-dex/advanced-amm-features.webp",
  },
  "NFT Trading Integration": {
    title: "NFT Trading Integration",
    description: `
      Enable direct NFT trading on Zero-Dex, integrating liquidity provision for digital assets alongside traditional token swaps.
      
      **Key Features:**
      **NFT Liquidity Pools:** Facilitate liquidity for NFT collections.
      **Instant NFT Swaps:** Trade NFTs directly without the need for traditional marketplaces.
      **Cross-Asset Compatibility:** Allow NFT-to-token and token-to-NFT transactions.
      **Marketplace Integration:** Connect with popular NFT marketplaces for expanded offerings.
    `,
    image: "/assets/images/zero-dex/nft-trading.webp",
  },
  "AI-Powered Market Analytics": {
    title: "AI-Powered Market Analytics",
    description: `
      Leverage artificial intelligence to provide traders and liquidity providers with actionable market insights and predictive analytics.
      
      **Key Features:**
      **Real-Time Market Insights:** Deliver live updates on price trends and trading volumes.
      **Predictive Analytics:** Forecast market movements using advanced AI models.
      **Customisable Dashboards:** Allow users to personalise analytics views.
      **Risk Assessment Tools:** Help users mitigate risks with AI-driven insights.
    `,
    image: "/assets/images/zero-dex/ai-market-analytics.webp",
  },
  "Enhanced Governance Mechanisms": {
    title: "Enhanced Governance Mechanisms",
    description: `
      Empower Zero-Dex users with advanced decentralised governance tools to facilitate community-driven decision-making for platform upgrades and policies.
      
      **Key Features:**
      **DAO Integration:** Implement decentralised autonomous organisation governance.
      **Voting Mechanisms:** Allow token holders to propose and vote on platform changes.
      **Transparent Governance Logs:** Maintain public records of all governance actions.
      **Stake-Based Voting Power:** Enable weighted votes based on staked assets.
    `,
    image: "/assets/images/zero-dex/governance-mechanisms.webp",
  },
  "Yield Optimisation Algorithms": {
    title: "Yield Optimisation Algorithms",
    description: `
      Introduce AI-driven algorithms to help users maximise rewards and liquidity efficiency within staking and farming pools.
      
      **Key Features:**
      **Real-Time Yield Tracking:** Monitor returns on staked assets dynamically.
      **Liquidity Allocation Models:** Optimise asset placement across pools.
      **User-Specific Recommendations:** Provide tailored suggestions for maximising rewards.
      **Risk Mitigation Analytics:** Highlight low-risk, high-reward opportunities.
    `,
    image: "/assets/images/zero-dex/yield-optimisation.webp",
  },
};


