import React, { useEffect, useState, useRef } from "react";
import AnimatedTitle from "../components/AnimatedTitle";
import { TiArrowDown, TiArrowUp } from "react-icons/ti";
import BackToProductsButton from "../components/BackToProductsButton";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Footer from "../components/Footer";
import LearnMoreButton from "../components/LearnMoreButton";
import ZeroComplyLearnMoreCustomModal from "../products-pages/7-ZeroComplyLearnMoreCustomModal";
import ZeroComplyLogo from "../assets/Zero-Comply.webp";

import { 
  CoreFeatureDetails,
  UserPersonasDetails,
  KeyBenefitsDetails,
  TechnicalArchitectureDetails,
  KeyFeaturesDetails,  
  SecurityAndComplianceDetails,  
  FutureEnhancementsDetails
} from "../data/7-ZeroComply/7-ZeroComplyDeepFeaturesData";

import {
  CoreFeatures,
  UserPersonas,
  KeyBenefits,
  TechnicalArchitecture,
  KeyFeatures,  
  SecurityAndCompliance,  
  FutureEnhancements
} from "../data/7-ZeroComply/7-ZeroComplyFeaturesData"; 

gsap.registerPlugin(ScrollTrigger);

const ZeroComply = () => {
  const logoRef = useRef(null);
  const [scrollDownClicked, setScrollDownClicked] = useState(false);
  const [isBottom, setIsBottom] = useState(false);

  const coreFeaturesRefs = useRef([]);
  const scrollDownRef = useRef(null);
  const scrollTopRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalDescription, setModalDescription] = useState("");
  const [modalImage, setModalImage] = useState("");

  useEffect(() => {
    // Always scroll to the top on component mount
    window.scrollTo({ top: 0, behavior: "smooth" });

    // Animate the logo when the component mounts
    if (logoRef.current) {
      gsap.fromTo(
        logoRef.current,
        { opacity: 0, y: -40 },
        { opacity: 1, y: 0, duration: 1.5, ease: "power3.out" }
      );
    }

    // Animate the "Core Features" items in batches
    const batchSize = 3;
    for (let i = 0; i < coreFeaturesRefs.current.length; i += batchSize) {
      const batch = coreFeaturesRefs.current.slice(i, i + batchSize).filter(Boolean);
      if (batch.length > 0) {
        gsap.fromTo(
          batch,
          { opacity: 0, y: 50 },
          {
            opacity: 1,
            y: 0,
            duration: 1,
            stagger: 0.15,
            delay: (i / batchSize) * 1.5,
            ease: "power3.out",
            scrollTrigger: {
              trigger: batch[0],
              start: "top 90%",
              toggleActions: "play none none none",
            },
          }
        );
      }
    }

    // Animate the "Scroll Down" button
    if (scrollDownRef.current) {
      gsap.fromTo(
        scrollDownRef.current,
        { opacity: 0, y: -20 },
        { opacity: 1, y: 0, duration: 1 }
      );
    }

    // Animate the "Back to Top" button
    if (scrollTopRef.current) {
      gsap.fromTo(
        scrollTopRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          scrollTrigger: {
            trigger: scrollTopRef.current,
            start: "top bottom",
          },
        }
      );
    }
  }, []);

  // Check if the user has scrolled to the bottom
  const checkIfBottom = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 200) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  };

  // Scroll to main content
  const scrollToContent = () => {
    const content = document.getElementById("content");
    if (content) {
      const contentOffsetTop = content.offsetTop;
      window.scrollTo({
        top: contentOffsetTop - 100,
        behavior: "smooth",
      });
    }
    setScrollDownClicked(true);
  };

  // Scroll to the top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setScrollDownClicked(false);
  };

  // Add/remove scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", checkIfBottom);
    return () => {
      window.removeEventListener("scroll", checkIfBottom);
    };
  }, []);

  const openModal = (title) => {
    const featureDetails = 
      CoreFeatureDetails[title] || 
      UserPersonasDetails[title] || 
      KeyBenefitsDetails[title] || 
      TechnicalArchitectureDetails[title] || 
      KeyFeaturesDetails[title] || 
      SecurityAndComplianceDetails[title] || 
      FutureEnhancementsDetails[title] || 
      CoreFeatureDetails["Default"];
    
    setModalTitle(featureDetails.title);
    setModalDescription(featureDetails.description);
    setModalImage(featureDetails.image);
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
    setModalTitle('');
    setModalDescription('');
    setModalImage('');
  };

  return (
    <div className="w-screen min-h-screen bg-black text-blue-50">
      {/* Hero Section */}
      <div className="relative w-screen h-screen overflow-hidden">
        <video
          src="/videos/zero-comply-hero.mp4"
          autoPlay
          loop
          muted
          className="absolute left-0 top-0 w-full h-full object-cover"
        />
        {/* LOGO AND TITLE CONTAINER */}
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/60 logo-title-container">
        <div ref={logoRef} className="logo">
          <img
            src={ZeroComplyLogo}
            alt="Zero Comply Logo"
            className="w-40 h-40 object-contain"
          />
        </div>
        <AnimatedTitle
          title="Zero-Comply"
          containerClass="text-center text-blue-50 font-zentry mt-4 mb-40" // Added margin-top to create spacing
        />
        </div>
      </div>

      {/* Scroll Down Button */}
      {!scrollDownClicked && (
        <div
          ref={scrollDownRef}
          className="fixed bottom-10 left-1/2 transform -translate-x-1/2 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
          onClick={scrollToContent}
        >
          <TiArrowDown size={24} className="text-white/40 hover:text-white" />
          <span className="text-white/40 hover:text-white">SCROLL DOWN</span>
        </div>
      )}

      {/* Content Section */}
      <div id="content" className="px-10 py-20 mx-auto max-w-7xl">
        {/* Introduction */}
        <div className="mb-10 text-center">
        <h2 className="text-4xl font-extrabold text-teal-600">Regulatory Compliance Simplified</h2>
        <p className="mt-2 text-lg md:text-xl text-gray-300">
            Zero-Comply empowers financial institutions with efficient and automated compliance solutions.
          </p>
        </div>

        {/* Core Features */}
          <section className="px-4 py-8 bg-black">
          <div className="mb-8 text-center">
            <h2 className="text-4xl font-extrabold text-teal-600">Core Features</h2>
            <p className="mt-2 text-lg md:text-xl text-gray-300">
              Discover the powerful capabilities that position Zero-Comply as a frontrunner in compliance workflow automation, predictive analytics, and real-time monitoring, ensuring seamless global regulatory adherence.
            </p>
            <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
            {CoreFeatures.map((item, index) => (
              <div
                key={index}
                className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
              >
                {/* Background Image */}
                <div
                  className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                  style={{ backgroundImage: `url(${item.image})` }}
                ></div>

                {/* Content */}
                <div className="relative z-10 flex flex-col flex-grow">
                  <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>
                  <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>
                  <div className="mt-auto self-start">
                    <LearnMoreButton
                      productId={index}
                      onClick={() => openModal(item.title)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          </section>

          <br />

          {/* User Personas */}
          <section className="px-4 py-8 bg-black">
          <div className="mb-8 text-center">
            <h2 className="text-4xl font-extrabold text-teal-600">User Personas</h2>
            <p className="mt-2 text-lg md:text-xl text-gray-300">
              Empowering Compliance Teams, Mitigating Risks, and Driving Informed Decision-Making Across Key User Roles.
            </p>
            <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
            {UserPersonas.map((item, index) => (
              <div
                key={index}
                className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
              >
                {/* Background Image */}
                <div
                  className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                  style={{ backgroundImage: `url(${item.image})` }}
                ></div>

                {/* Content */}
                <div className="relative z-10 flex flex-col flex-grow">
                  <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>
                  <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>
                  <div className="mt-auto self-start">
                    <LearnMoreButton
                      productId={index}
                      onClick={() => openModal(item.title, item.description)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          </section>


        <br />

        {/* Highlights Section */}
        <section className="mt-20 space-y-16">

        {/* First Highlight */}
          <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row">
          <div className="w-full md:w-1/2">
            <img
              src="/assets/images/zero-comply/workflow-automation.webp"
              alt="Compliance Workflow Automation"
              className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
            />
          </div>
          <div className="w-full md:w-1/2">
            <h3 className="text-3xl md:text-4xl font-extrabold text-white">Compliance Workflow Automation</h3>
            <p className="mt-4">
              Streamline compliance processes with smart automation, enabling businesses to trigger tasks automatically based on regulatory changes or policy updates.
            </p>
          </div>
        </div>

        {/* Second Highlight */}
        <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row-reverse">
          <div className="w-full md:w-1/2">
            <img
              src="/assets/images/zero-comply/predictive-analytics.webp"
              alt="Predictive Analytics"
              className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
            />
          </div>
          <div className="w-full md:w-1/2">
            <h3 className="text-3xl md:text-4xl font-extrabold text-white">Predictive Analytics</h3>
            <p className="mt-4">
              Leverage AI-driven predictive analytics to foresee compliance risks, helping businesses proactively address potential breaches before they escalate.
            </p>
          </div>
        </div>
        </section>
        <br /><br /><br />   
        
        {/* Key Benefits */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
           Key Benefits
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Unlock Operational Efficiency, Mitigate Risks, and Drive Compliance Excellence with Key Business Benefits.
          </p>
          {/* Optional Divider */}
          <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {KeyBenefits.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>
                
                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton
                    productId={index}
                    onClick={() => openModal(item.title, item.description)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>

        <br /><br /><br />   
        
        {/* Technical Architecture */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
          Technical Architecture
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Powerful, Scalable, and Secure — The Technical Backbone That Drives Zero-Comply's Compliance Excellence.
          </p>
          {/* Optional Divider */}
          <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {TechnicalArchitecture.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>
                
                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton
                    productId={index}
                    onClick={() => openModal(item.title, item.description)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>

        {/* Key Features */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
           Key Features
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Essential Tools for Compliance Mastery — Explore Key Features That Streamline, Secure, and Simplify Regulatory Management.
          </p>
        {/* Optional Divider */}
        <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {KeyFeatures.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton 
                  productId={index} 
                  onClick={() => openModal(item.title)} 
                  />              
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Security & Compliance */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
            Security & Compliance
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Comprehensive Security & Compliance — Safeguard Your Compliance Operations with Advanced Security, Privacy Controls, and Global Regulatory Adherence.
          </p>
        {/* Optional Divider */}
        <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {SecurityAndCompliance.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton 
                  productId={index} 
                  onClick={() => openModal(item.title)} 
                  />              
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>
        <br />        


        <br />

        
        </section>
        <br />
        <br /><br />

         {/* Third Highlight */}
            <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row">
            <div className="w-full md:w-1/2">
              <img
                src="/assets/images/zero-comply/real-time-monitoring.webp"
                alt="Real-Time Monitoring"
                className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
              />
            </div>
            <div className="w-full md:w-1/2">
              <h3 className="text-3xl md:text-4xl font-extrabold text-white">Real-Time Monitoring</h3>
              <p className="mt-4">
                Monitor compliance activities 24/7 with instant alerts for anomalies, helping organisations remain aware and prepared for regulatory changes in real time.
              </p>
            </div>
          </div>

          {/* Fourth Highlight */}
          <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row-reverse">
            <div className="w-full md:w-1/2">
              <img
                src="/assets/images/zero-comply/audit-reports.webp"
                alt="Audit-Ready Reports"
                className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
              />
            </div>
            <div className="w-full md:w-1/2">
              <h3 className="text-3xl md:text-4xl font-extrabold text-white">Audit-Ready Reports</h3>
              <p className="mt-4">
                Generate comprehensive, audit-ready compliance reports with a single click, reducing preparation time and ensuring audit-readiness at all times.
              </p>
            </div>
          </div>
      </section>
      <br /><br /><br />                

      {/* Future Enhancements */}
      <section className="px-4 py-8 bg-black">
      {/* Title Container */}
      <div className="mb-8 text-center">
        <h2 className="text-4xl font-extrabold text-teal-600">
        Future Enhancements
        </h2>
        <p className="mt-2 text-lg md:text-xl text-gray-300">
        Future Innovations — Unlock New Possibilities with AI-Driven Risk Scoring, Blockchain-Backed Audit Trails, and Customisable Compliance Tools.
        </p>
      {/* Optional Divider */}
      <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
      </div>

      {/* Features Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
        {FutureEnhancements.map((item, index) => (
          <div
            key={index}
            className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
          >
            {/* Background Image */}
            <div
              className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
              style={{ backgroundImage: `url(${item.image})` }}
            ></div>

            {/* Content Container */}
            <div className="relative z-10 flex flex-col flex-grow">
              <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

              {/* Description with consistent space below */}
              <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

              {/* Learn More Button Positioned at Bottom Left */}
              <div className="mt-auto self-start">
                <LearnMoreButton 
                productId={index} 
                onClick={() => openModal(item.title)} 
                />              
              </div>
            </div>
          </div>
        ))}
      </div>
      </section>
      <br /> 

        {/* Back to Products Button */}
        <div className="flex justify-center mt-10">
          <BackToProductsButton />
        </div>
      </div>

      {/* Back to Top Button */}
      {isBottom && (
        <div
          ref={scrollTopRef}
          className="fixed bottom-10 left-5 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
          onClick={scrollToTop}
        >
          <TiArrowUp
            size={24}
            className="text-white/40 hover:text-white transition-transform duration-300"
          />
          <span className="text-white/40 hover:text-white">BACK TO TOP</span>
        </div>
      )}

      {/* Footer */}
      <Footer />

      <ZeroComplyLearnMoreCustomModal 
        isOpen={isModalOpen} 
        onClose={closeModal} 
        title={modalTitle} 
        description={modalDescription} 
        image={modalImage} 
      />
    </div>
  );
};

export default ZeroComply;
