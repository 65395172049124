export const CoreFeatureDetails = {
  "Real-Time Staking Rewards": {
    title: "Real-Time Staking Rewards",
    description: `
      Earn rewards seamlessly with Zero-Stake's real-time staking capabilities, empowering users to benefit instantly from their contributions to liquidity pools.
      
      **Key Features:**
      **Instant Reward Distribution:** Rewards are credited to users' wallets in real time, without delays.
      **Flexible Staking Options:** Stake tokens across multiple pools tailored to your investment goals.
      **Scalable Architecture:** Handles high transaction volumes while ensuring reward consistency.
      **Low Barrier to Entry:** Stake any amount and start earning immediately.
    `,
    image: "/assets/images/zero-stake/real-time-rewards.webp",
  },
  "Cross-Chain Liquidity Provision": {
    title: "Cross-Chain Liquidity Provision",
    description: `
      Bridge the gap between blockchain ecosystems with Zero-Stake's cross-chain liquidity solutions, enhancing access and flexibility.
      
      **Key Features:**
      **Multi-Chain Support:** Compatible with Ethereum, Binance Smart Chain, Polygon, and more.
      **Decentralised Liquidity Pools:** Provide liquidity across chains without centralised intermediaries.
      **Seamless Interoperability:** Enables token transfers and swaps across multiple networks.
      **Minimised Slippage:** Advanced algorithms optimise trade execution for liquidity providers.
    `,
    image: "/assets/images/zero-stake/cross-chain-liquidity.webp",
  },
  "Governance Portal": {
    title: "Governance Portal",
    description: `
      Participate in platform governance and influence the direction of Zero-Stake by voting on key proposals and initiatives.
      
      **Key Features:**
      **Decentralised Decision-Making:** Every stakeholder has a voice in shaping the platform's future.
      **Proposal Creation:** Users can submit and vote on new proposals to improve platform functionality.
      **Transparent Voting System:** Votes are recorded on the blockchain for immutability and transparency.
      **Incentivized Participation:** Earn rewards for active engagement in governance activities.
    `,
    image: "/assets/images/zero-stake/governance-portal.webp",
  },
  "Yield Optimisation": {
    title: "Yield Optimisation",
    description: `
      Unlock maximum returns on your staked assets with Zero-Stake's AI-driven yield optimisation strategies.
      
      **Key Features:**
      **Dynamic Allocation:** AI reallocates assets to the highest-performing pools in real time.
      **Risk Management:** Balances risk and reward for sustainable growth.
      **Historical Insights:** Leverages past performance data to optimise yield strategies.
      **Custom Strategies:** Users can customise parameters to align with personal risk tolerance and goals.
    `,
    image: "/assets/images/zero-stake/yield-optimisation.webp",
  },
  "Advanced Analytics": {
    title: "Advanced Analytics",
    description: `
      Gain unparalleled insights into staking performance with Zero-Stake's robust analytics tools, designed for informed decision-making.
      
      **Key Features:**
      **Performance Metrics:** Monitor staking rewards, APY trends, and pool performance.
      **Real-Time Data:** Access live updates on transaction and staking activity.
      **Customisable Dashboards:** Tailor analytics views to suit individual preferences.
      **Exportable Reports:** Generate detailed reports for tracking and compliance purposes.
    `,
    image: "/assets/images/zero-stake/advanced-analytics.webp",
  },
  "Enhanced Security": {
    title: "Enhanced Security",
    description: `
      Protect your assets with Zero-Stake's state-of-the-art security framework, ensuring a safe and reliable staking experience.
      
      **Key Features:**
      **Smart Contract Audits:** Regular audits ensure the integrity of all contracts.
      **AI-Driven Anomaly Detection:** Identifies and mitigates potential threats in real time.
      **End-to-End Encryption:** Safeguards all user data and transactions.
      **Role-Based Access Control:** Restricts access based on user roles to minimise unauthorised activities.
    `,
    image: "/assets/images/zero-stake/enhanced-security.webp",
  },
};


export const TechnicalCapabilitiesDetails = {
  "Decentralised Infrastructure": {
    title: "Decentralised Infrastructure",
    description: `
      Zero-Stake operates on a fully decentralised infrastructure, ensuring reliability and trust through blockchain technology.
      
      **Key Features:**
      **Decentralised Network Nodes:** Eliminate single points of failure with distributed nodes across the network.
      **Transparent Operations:** All transactions are recorded immutably on the blockchain.
      **High Availability:** Ensures uptime through redundancy in its decentralised structure.
      **Scalable Design:** Adapts to network demands with minimal latency or disruptions.
    `,
    image: "/assets/images/zero-stake/decentralised-infrastructure.webp",
  },
  "Staking Module Integration": {
    title: "Staking Module Integration",
    description: `
      Simplify staking implementation with Zero-Stake's modular integration framework, supporting multiple token standards.
      
      **Key Features:**
      **Customisable Parameters:** Define lock-in periods, APY rates, and withdrawal limits.
      **Multi-Token Support:** Compatible with ERC-20, BEP-20, and other token standards.
      **Developer-Friendly APIs:** Easy-to-use APIs for seamless integration with DeFi platforms.
      **Quick Deployment:** Reduce setup time with pre-built, configurable staking modules.
    `,
    image: "/assets/images/zero-stake/staking-module-integration.webp",
  },
  "Cross-Chain Bridge Support": {
    title: "Cross-Chain Bridge Support",
    description: `
      Facilitate seamless token transfers between blockchain networks with Zero-Stake's advanced cross-chain bridging capabilities.
      
      **Key Features:**
      **Multi-Blockchain Compatibility:** Supports Ethereum, Binance Smart Chain, Polygon, and more.
      **Enhanced Liquidity Movement:** Seamlessly transfer liquidity across ecosystems.
      **Low-Cost Transfers:** Minimise gas fees with optimised bridge protocols.
      **Secure Transactions:** Utilise cryptographic proofs to ensure the safety of cross-chain token movements.
    `,
    image: "/assets/images/zero-stake/cross-chain-bridge-support.webp",
  },
  "Smart Contract Automation": {
    title: "Smart Contract Automation",
    description: `
      Automate key processes such as staking rewards, liquidity management, and governance with Zero-Stake's smart contract technology.
      
      **Key Features:**
      **Automated Staking Rewards:** Distribute rewards without manual intervention.
      **Governance Execution:** Implement voting results via automated smart contracts.
      **Liquidity Pool Management:** Adjust pool parameters dynamically through contract logic.
      **Audited Contracts:** Ensure security and integrity with regular contract audits.
    `,
    image: "/assets/images/zero-stake/smart-contract-automation.webp",
  },
  "Advanced Analytics Engine": {
    title: "Advanced Analytics Engine",
    description: `
      Gain real-time insights into platform performance with Zero-Stake's robust analytics engine, empowering data-driven decision-making.
      
      **Key Features:**
      **Reward Tracking:** Monitor staking rewards and ROI in real time.
      **Governance Metrics:** Track voting participation and proposal impacts.
      **Liquidity Performance:** Analyze liquidity pool contributions and trends.
      **Custom Dashboards:** Tailor analytics displays to focus on user-specific metrics.
    `,
    image: "/assets/images/zero-stake/advanced-analytics-engine.webp",
  },
  "Blockchain Interoperability": {
    title: "Blockchain Interoperability",
    description: `
      Zero-Stake supports seamless interaction with leading blockchain ecosystems, enabling broader adoption and integration.
      
      **Key Features:**
      **Multi-Chain Integration:** Operates across Ethereum, Binance Smart Chain, Polygon, and other networks.
      **Unified Protocols:** Standardized APIs for connecting different blockchain environments.
      **Cross-Network Compatibility:** Supports multi-token staking and liquidity provision.
      **Future-Ready Design:** Scalable to accommodate emerging blockchain technologies.
    `,
    image: "/assets/images/zero-stake/blockchain-interoperability.webp",
  },
};


export const KeyBenefitsDetails = {
  "Enhanced Staking Rewards": {
    title: "Enhanced Staking Rewards",
    description: `
      Maximise user returns with advanced staking algorithms and adaptable reward distribution mechanisms, offering competitive yields.
      
      **Key Features:**
      **Dynamic APY Rates:** Adjust annual percentage yields based on market conditions and staking pool performance.
      **Flexible Reward Distribution:** Choose between daily, weekly, or custom payout intervals.
      **Incentive Programs:** Encourage long-term staking through bonus rewards and loyalty incentives.
      **Transparent Calculations:** Provide users with real-time visibility into reward accrual and distribution.
    `,
    image: "/assets/images/zero-stake/enhanced-staking-rewards.webp",
  },
  "Seamless Governance Participation": {
    title: "Seamless Governance Participation",
    description: `
      Empower users to actively participate in platform governance through an intuitive voting and proposal system.
      
      **Key Features:**
      **User-Friendly Interface:** Simplify the process of submitting and voting on proposals.
      **Delegated Voting:** Allow users to delegate their voting power to trusted representatives.
      **Proposal Transparency:** Ensure all governance proposals and decisions are immutably recorded on the blockchain.
      **Incentivized Participation:** Reward active governance participants with additional tokens or benefits.
    `,
    image: "/assets/images/zero-stake/seamless-governance-participation.webp",
  },
  "Cross-Chain Liquidity": {
    title: "Cross-Chain Liquidity",
    description: `
      Enhance liquidity sharing across blockchain ecosystems, improving transaction efficiency and market accessibility.
      
      **Key Features:**
      **Multi-Network Liquidity Pools:** Support liquidity provisioning across Ethereum, Binance Smart Chain, and more.
      **Reduced Transaction Costs:** Optimise liquidity movement to minimise gas fees and slippage.
      **Unified Asset Management:** Consolidate liquidity from various blockchains for streamlined operations.
      **Improved Market Depth:** Increase trading volumes and reduce price volatility through deep liquidity pools.
    `,
    image: "/assets/images/zero-stake/cross-chain-liquidity.webp",
  },
  "Real-Time Analytics": {
    title: "Real-Time Analytics",
    description: `
      Equip users with actionable insights through live metrics on staking performance, pool health, and governance participation.
      
      **Key Features:**
      **Performance Dashboards:** Visualise staking rewards, pool utilisation, and governance statistics.
      **Customisable Metrics:** Allow users to track metrics most relevant to their investment goals.
      **Historical Data Analysis:** Access trends and performance reports for better decision-making.
      **Mobile-First Design:** Provide analytics through responsive dashboards accessible on any device.
    `,
    image: "/assets/images/zero-stake/real-time-analytics.webp",
  },
  "Secure Smart Contracts": {
    title: "Secure Smart Contracts",
    description: `
      Leverage rigorously audited smart contracts to eliminate vulnerabilities and ensure trustless interactions.
      
      **Key Features:**
      **Regular Audits:** Conduct third-party smart contract audits to guarantee security and compliance.
      **Immutable Codebase:** Ensure code integrity with blockchain immutability.
      **Role-Based Permissions:** Implement access controls to minimise operational risks.
      **Fail-Safe Mechanisms:** Include fallback options to handle unexpected contract failures or anomalies.
    `,
    image: "/assets/images/zero-stake/secure-smart-contracts.webp",
  },
  "Efficient Resource Allocation": {
    title: "Efficient Resource Allocation",
    description: `
      Optimise resource utilisation across staking pools and governance systems to improve platform performance.
      
      **Key Features:**
      **Dynamic Pool Rebalancing:** Automatically redistribute resources to underperforming pools.
      **Cost-Effective Operations:** Reduce overhead through automated resource management.
      **Performance Monitoring:** Track resource usage and optimise allocation in real time.
      **Scalability:** Adapt to increasing user demands without compromising performance or efficiency.
    `,
    image: "/assets/images/zero-stake/resource-allocation.webp",
  },
};


export const UseCasesDetails = {
  "Decentralised Staking Platforms": {
    title: "Decentralised Staking Platforms",
    description: `
      Facilitate secure and transparent staking, enabling users to earn rewards while maintaining full control of their assets.
      
      **Key Features:**
      **Non-Custodial Staking:** Users retain full control of their private keys and staked assets.
      **Dynamic Reward Allocation:** Adjust staking rewards based on pool performance and participation rates.
      **Secure Smart Contracts:** Ensure trustless staking operations with rigorously audited contracts.
      **User-Friendly Interfaces:** Simplify staking with intuitive dashboards and step-by-step guides.
    `,
    image: "/assets/images/zero-stake/decentralised-staking-platforms.webp",
  },
  "Governance Token Systems": {
    title: "Governance Token Systems",
    description: `
      Empower users to shape the platform's future by voting on proposals and participating in governance with tokens.
      
      **Key Features:**
      **Token-Based Voting:** Allocate voting power based on token holdings or staking contributions.
      **Transparent Proposals:** Make all governance decisions visible on-chain for accountability.
      **Community Incentives:** Reward active participants with governance rewards or additional tokens.
      **Decentralised Decision-Making:** Reduce reliance on centralised control with community-led governance.
    `,
    image: "/assets/images/zero-stake/governance-token-systems.webp",
  },
  "Cross-Chain Liquidity Solutions": {
    title: "Cross-Chain Liquidity Solutions",
    description: `
      Facilitate seamless token swaps and liquidity sharing across blockchain networks, enhancing accessibility and efficiency.
      
      **Key Features:**
      **Interoperable Bridges:** Enable seamless transfers of assets between blockchains like Ethereum, Binance Smart Chain, and others.
      **Reduced Slippage:** Minimise price fluctuations during swaps with deep liquidity pools.
      **Unified Asset Management:** Aggregate liquidity across chains for streamlined management.
      **Increased Market Efficiency:** Boost DeFi protocol performance with improved liquidity flow.
    `,
    image: "/assets/images/zero-stake/cross-chain-liquidity-solutions.webp",
  },
  "DeFi Yield Farming": {
    title: "DeFi Yield Farming",
    description: `
      Offer users staking opportunities to earn rewards while enhancing liquidity for decentralised finance protocols.
      
      **Key Features:**
      **High APY Opportunities:** Provide competitive annual percentage yields for liquidity contributions.
      **Flexible Lock-In Periods:** Allow users to choose staking durations that fit their financial goals.
      **Dual Reward Systems:** Enable simultaneous earning of staking rewards and platform tokens.
      **Real-Time ROI Tracking:** Display potential returns and current earnings with live analytics.
    `,
    image: "/assets/images/zero-stake/defi-yield-farming.webp",
  },
  "Enterprise Blockchain Solutions": {
    title: "Enterprise Blockchain Solutions",
    description: `
      Empower businesses with blockchain-based staking solutions for supply chain transparency, employee rewards, and internal governance.
      
      **Key Features:**
      **Tokenised Employee Rewards:** Create and distribute staking-based rewards for employees.
      **Supply Chain Visibility:** Use token staking to ensure transparent and tamper-proof supply chain tracking.
      **Corporate Governance:** Streamline internal voting systems with blockchain-secured governance tokens.
      **Custom Integration Support:** Tailor staking solutions to meet enterprise-specific requirements.
    `,
    image: "/assets/images/zero-stake/enterprise-blockchain-solutions.webp",
  },
  "Gaming and Metaverse Economies": {
    title: "Gaming and Metaverse Economies",
    description: `
      Integrate staking and governance systems into blockchain-based games and virtual worlds to enhance engagement and value creation.
      
      **Key Features:**
      **Game-Specific Staking Pools:** Offer unique rewards for staking game tokens within the ecosystem.
      **Player Governance:** Allow users to vote on in-game policies or feature updates.
      **Earning Opportunities:** Enable players to earn staking rewards while participating in the metaverse economy.
      **Seamless NFT Integration:** Support staking of in-game assets or NFTs for additional benefits.
    `,
    image: "/assets/images/zero-stake/gaming-metaverse-economies.webp",
  },
};


export const SecurityAndComplianceDetails = {
  "Blockchain-Backed Security": {
    title: "Blockchain-Backed Security",
    description: `
      Ensure the transparency, immutability, and security of all staking transactions with a blockchain-powered security infrastructure.
      
      **Key Features:**
      **Immutable Ledger:** All transactions are recorded on a blockchain for permanent, tamper-proof security.
      **Transparent Operations:** Users can verify transactions independently, ensuring trust in the platform.
      **Decentralised Security:** Eliminates central points of failure, enhancing reliability and protection against attacks.
      **Auditable History:** Provides a complete on-chain record for compliance and verification.
    `,
    image: "/assets/images/zero-stake/blockchain-security.webp",
  },
  "KYC/AML Integration": {
    title: "KYC/AML Integration",
    description: `
      Safeguard the platform by enforcing Know Your Customer (KYC) and Anti-Money Laundering (AML) compliance for secure staking operations.
      
      **Key Features:**
      **Identity Verification:** Verify user identities during onboarding to ensure platform security.
      **Transaction Monitoring:** Detect and flag suspicious activities in real time.
      **Global Compliance Standards:** Align with financial regulations worldwide, reducing legal risks.
      **Seamless User Experience:** Integrate KYC/AML processes without compromising ease of use.
    `,
    image: "/assets/images/zero-stake/kyc-aml-integration.webp",
  },
  "End-to-End Data Encryption": {
    title: "End-to-End Data Encryption",
    description: `
      Protect sensitive staking data with robust encryption protocols that secure information during storage and transmission.
      
      **Key Features:**
      **Data at Rest Encryption:** Prevent unauthorised access to stored user and transaction data.
      **Secure Data Transmission:** Encrypt all communications to ensure data integrity during transit.
      **Advanced Algorithms:** Utilise AES-256 encryption for enterprise-grade security.
      **Compliance Ready:** Meets global data protection standards for encrypted interactions.
    `,
    image: "/assets/images/zero-stake/end-to-end-encryption.webp",
  },
  "Smart Contract Auditing": {
    title: "Smart Contract Auditing",
    description: `
      All staking-related smart contracts undergo thorough audits to identify and fix vulnerabilities, ensuring a secure platform.
      
      **Key Features:**
      **Professional Audits:** Contracts are vetted by top-tier blockchain security firms.
      **Automated Vulnerability Scans:** Identify risks before deployment.
      **Regular Updates:** Apply patches and updates to maintain smart contract integrity.
      **User Assurance:** Build trust by showcasing audit certifications and results.
    `,
    image: "/assets/images/zero-stake/smart-contract-auditing.webp",
  },
  "Role-Based Access Control (RBAC)": {
    title: "Role-Based Access Control (RBAC)",
    description: `
      Enhance operational security by granting permissions based on user roles, ensuring only authorised individuals can perform critical actions.
      
      **Key Features:**
      **Granular Permissions:** Control access to specific functions based on predefined roles.
      **Administrator Security:** Safeguard sensitive administrative functions.
      **Audit Trails:** Monitor role-based actions to identify potential breaches or misuse.
      **User Segmentation:** Create distinct access levels for end-users, developers, and admins.
    `,
    image: "/assets/images/zero-stake/rbac.webp",
  },
  "GDPR & Privacy Compliance": {
    title: "GDPR & Privacy Compliance",
    description: `
      Protect user privacy by adhering to GDPR and other global privacy regulations, ensuring secure and consent-based data handling.
      
      **Key Features:**
      **Data Minimisation:** Collect and process only essential user data.
      **Consent Management:** Allow users to control their data-sharing preferences.
      **Right to Be Forgotten:** Comply with user requests for data deletion.
      **Privacy by Design:** Integrate privacy considerations into every feature of the platform.
    `,
    image: "/assets/images/zero-stake/gdpr-compliance.webp",
  },
};


export const BusinessBenefitsDetails = {
  "Enhanced Stakeholder Confidence": {
    title: "Enhanced Stakeholder Confidence",
    description: `
      Increase trust among stakeholders by providing a secure, transparent staking environment that leverages blockchain technology.
      
      **Key Features:**
      **Immutable Transactions:** Build stakeholder confidence with tamper-proof blockchain records.
      **Transparency:** Ensure complete visibility into staking activities and platform operations.
      **Secure Infrastructure:** Mitigate risks with state-of-the-art security measures, including smart contract auditing.
      **Stakeholder Trust:** Attract more investors and users with a reputation for reliability and innovation.
    `,
    image: "/assets/images/zero-stake/stakeholder-confidence.webp",
  },
  "Regulatory Compliance Enablement": {
    title: "Regulatory Compliance Enablement",
    description: `
      Stay compliant with global KYC, AML, and GDPR standards, ensuring legal adherence and reducing operational risks.
      
      **Key Features:**
      **Global Standards Alignment:** Meet regulatory requirements across multiple jurisdictions.
      **Automated Compliance Checks:** Simplify KYC and AML processes with integrated identity verification tools.
      **Data Privacy Protections:** Maintain user trust by adhering to GDPR and similar frameworks.
      **Reduced Legal Risks:** Minimise penalties and downtime due to non-compliance issues.
    `,
    image: "/assets/images/zero-stake/compliance-enablement.webp",
  },
  "Operational Scalability": {
    title: "Operational Scalability",
    description: `
      Streamline operations with automated staking and liquidity management tools, allowing the platform to scale efficiently.
      
      **Key Features:**
      **Automated Staking Processes:** Reduce manual intervention with smart contract automation.
      **Efficient Liquidity Management:** Balance liquidity pools for optimal platform performance.
      **Seamless Scaling:** Handle growing user bases and transaction volumes without compromising performance.
      **Cost Reduction:** Lower operational expenses with reduced human overhead.
    `,
    image: "/assets/images/zero-stake/operational-scalability.webp",
  },
  "Increased User Retention": {
    title: "Increased User Retention",
    description: `
      Boost engagement and loyalty with user-friendly staking options, competitive rewards, and an intuitive interface.
      
      **Key Features:**
      **Reward Optimisation:** Offer attractive staking yields to incentivize participation.
      **User-Friendly Design:** Provide a seamless onboarding and staking experience.
      **Engagement Tools:** Utilise notifications and analytics to keep users informed and active.
      **Long-Term Loyalty:** Build a community of recurring users through gamified incentives and reliable performance.
    `,
    image: "/assets/images/zero-stake/user-retention.webp",
  },
  "Revenue from Transaction Fees": {
    title: "Revenue from Transaction Fees",
    description: `
      Generate consistent income by leveraging transaction fees and staking commissions, ensuring sustainable growth.
      
      **Key Features:**
      **Flexible Fee Structures:** Adjust fees to maximise profitability without alienating users.
      **Staking Commissions:** Earn a percentage of user staking rewards as platform revenue.
      **Scalable Income Model:** Increase revenue as transaction volumes grow.
      **Predictable Cash Flow:** Benefit from recurring income streams for financial stability.
    `,
    image: "/assets/images/zero-stake/transaction-revenue.webp",
  },
  "Brand Reputation as a Market Leader": {
    title: "Brand Reputation as a Market Leader",
    description: `
      Establish your platform as a trusted and innovative leader in the DeFi and staking ecosystem, attracting new users and partnerships.
      
      **Key Features:**
      **Innovative Offerings:** Showcase advanced features like cross-chain staking and AI-driven yield optimisation.
      **Community Building:** Foster a loyal user base through transparency and consistent engagement.
      **Partnership Opportunities:** Attract collaborators and institutional investors with a strong market presence.
      **Market Recognition:** Stand out as a cutting-edge, reliable solution in a competitive industry.
    `,
    image: "/assets/images/zero-stake/brand-reputation.webp",
  },
};


export const FutureEnhancementsDetails = {
  "Cross-Chain Staking Integration": {
    title: "Cross-Chain Staking Integration",
    description: `
      Expand staking capabilities across multiple blockchain networks, enabling users to stake tokens on Ethereum, Solana, Binance Smart Chain, and beyond.
      
      **Key Features:**
      **Multi-Blockchain Support:** Seamless staking integration across leading blockchain ecosystems.
      **Interoperable Smart Contracts:** Enable secure staking and liquidity transfers between chains.
      **Flexibility for Users:** Allow stakers to diversify their staking portfolios across networks.
      **Increased Liquidity:** Enhance platform liquidity with cross-chain staking pools.
    `,
    image: "/assets/images/zero-stake/cross-chain-staking.webp",
  },
  "Dynamic Reward Mechanisms": {
    title: "Dynamic Reward Mechanisms",
    description: `
      Introduce adaptive reward systems that respond to staking duration, token volume, and market dynamics to incentivize user engagement.
      
      **Key Features:**
      **Duration-Based Rewards:** Higher rewards for longer staking commitments.
      **Volume-Weighted Incentives:** Adjust rewards based on token volume staked.
      **Market-Driven Adjustments:** Dynamically calculate rewards based on token demand and supply.
      **Customisable Policies:** Platform operators can define tailored reward parameters.
    `,
    image: "/assets/images/zero-stake/dynamic-rewards.webp",
  },
  "Governance Token Integration": {
    title: "Governance Token Integration",
    description: `
      Empower users with governance tokens, allowing them to participate in platform decision-making and shape the future of Zero-Stake.
      
      **Key Features:**
      **Proposal Voting:** Users can vote on platform upgrades, reward structures, and policy changes.
      **Stake-Based Influence:** Token holders gain voting power proportional to their holdings.
      **Decentralised Governance:** Foster a user-driven, transparent governance ecosystem.
      **Incentivized Participation:** Reward governance participants with additional tokens.
    `,
    image: "/assets/images/zero-stake/governance-token.webp",
  },
  "Real-Time Staking Analytics": {
    title: "Real-Time Staking Analytics",
    description: `
      Offer advanced analytics dashboards for users to monitor staking performance, pool health, and reward distribution in real time.
      
      **Key Features:**
      **Performance Metrics:** Track staking yield, pool contributions, and reward distribution.
      **Health Indicators:** Identify underperforming or overutilised staking pools.
      **Customisable Dashboards:** Tailor analytics views to individual user preferences.
      **Data Export Options:** Export analytics for personal records or regulatory compliance.
    `,
    image: "/assets/images/zero-stake/staking-analytics.webp",
  },
  "Enhanced Security Audits": {
    title: "Enhanced Security Audits",
    description: `
      Implement regular automated and manual audits for smart contracts to identify vulnerabilities and ensure staking operations' safety.
      
      **Key Features:**
      **Automated Auditing Tools:** Leverage AI to detect smart contract anomalies.
      **Third-Party Reviews:** Engage professional auditors for comprehensive security assessments.
      **Continuous Monitoring:** Real-time security checks for ongoing protection.
      **Compliance Assurance:** Maintain adherence to security and regulatory standards.
    `,
    image: "/assets/images/zero-stake/security-audits.webp",
  },
  "NFT Staking Opportunities": {
    title: "NFT Staking Opportunities",
    description: `
      Enable users to stake NFTs and earn unique rewards, expanding the platform's functionality and digital asset use cases.
      
      **Key Features:**
      **Flexible Staking Models:** Support staking of both individual and bundled NFTs.
      **Custom Rewards:** Earn exclusive tokens, in-game assets, or platform benefits.
      **NFT Utility Expansion:** Add new use cases for NFTs within the staking ecosystem.
      **Seamless Integration:** Compatible with existing NFT standards like ERC-721 and ERC-1155.
    `,
    image: "/assets/images/zero-stake/nft-staking.webp",
  },
};

