import React, { useEffect, useState, useRef } from "react";
import AnimatedTitle from "../components/AnimatedTitle";
import { TiArrowDown, TiArrowUp } from "react-icons/ti";
import BackToProductsButton from "../components/BackToProductsButton";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Footer from "../components/Footer";
import LearnMoreButton from "../components/LearnMoreButton";
import ZeroSuperviseLearnMoreCustomModal from "../products-pages/8-ZeroSuperviseLearnMoreCustomModal";
import ZeroSuperviseLogo from "../assets/Zero-Supervise.webp";

import { 
  CoreFeatureDetails,
  UserPersonasDetails,
  KeyBenefitsDetails,
  TechnicalCapabilitiesDetails,
  KeyFeaturesDetails,  
  SecurityAndComplianceDetails,  
  FutureEnhancementsDetails
} from "../data/8-ZeroSupervise/8-ZeroSuperviseDeepFeaturesData";

import {
  CoreFeatures,
  UserPersonas,
  KeyBenefits,
  TechnicalCapabilities,
  KeyFeatures,  
  SecurityAndCompliance,  
  FutureEnhancements
} from "../data/8-ZeroSupervise/8-ZeroSuperviseFeaturesData"; 

gsap.registerPlugin(ScrollTrigger);

const ZeroSupervise = () => {
  const logoRef = useRef(null);
  const [scrollDownClicked, setScrollDownClicked] = useState(false);
  const [isBottom, setIsBottom] = useState(false);

  const coreFeaturesRefs = useRef([]);
  const scrollDownRef = useRef(null);
  const scrollTopRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalDescription, setModalDescription] = useState("");
  const [modalImage, setModalImage] = useState("");

  useEffect(() => {
    // Always scroll to the top on component mount
    window.scrollTo({ top: 0, behavior: "smooth" });

    // Animate the logo when the component mounts
    if (logoRef.current) {
      gsap.fromTo(
        logoRef.current,
        { opacity: 0, y: -40 },
        { opacity: 1, y: 0, duration: 1.5, ease: "power3.out" }
      );
    }

    // Animate the "Core Features" items in batches
    const batchSize = 3;
    for (let i = 0; i < coreFeaturesRefs.current.length; i += batchSize) {
      const batch = coreFeaturesRefs.current.slice(i, i + batchSize).filter(Boolean);
      if (batch.length > 0) {
        gsap.fromTo(
          batch,
          { opacity: 0, y: 50 },
          {
            opacity: 1,
            y: 0,
            duration: 1,
            stagger: 0.15,
            delay: (i / batchSize) * 1.5,
            ease: "power3.out",
            scrollTrigger: {
              trigger: batch[0],
              start: "top 90%",
              toggleActions: "play none none none",
            },
          }
        );
      }
    }

    // Animate the "Scroll Down" button
    if (scrollDownRef.current) {
      gsap.fromTo(
        scrollDownRef.current,
        { opacity: 0, y: -20 },
        { opacity: 1, y: 0, duration: 1 }
      );
    }

    // Animate the "Back to Top" button
    if (scrollTopRef.current) {
      gsap.fromTo(
        scrollTopRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          scrollTrigger: {
            trigger: scrollTopRef.current,
            start: "top bottom",
          },
        }
      );
    }
  }, []);

  // Check if the user has scrolled to the bottom
  const checkIfBottom = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 200) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  };

  // Scroll to main content
  const scrollToContent = () => {
    const content = document.getElementById("content");
    if (content) {
      const contentOffsetTop = content.offsetTop;
      window.scrollTo({
        top: contentOffsetTop - 100,
        behavior: "smooth",
      });
    }
    setScrollDownClicked(true);
  };

  // Scroll to the top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setScrollDownClicked(false);
  };

  // Add/remove scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", checkIfBottom);
    return () => {
      window.removeEventListener("scroll", checkIfBottom);
    };
  }, []);

  const openModal = (title) => {
    const featureDetails = 
      CoreFeatureDetails[title] || 
      UserPersonasDetails[title] || 
      KeyBenefitsDetails[title] || 
      TechnicalCapabilitiesDetails[title] || 
      KeyFeaturesDetails[title] || 
      SecurityAndComplianceDetails[title] || 
      FutureEnhancementsDetails[title] || 
      CoreFeatureDetails["Default"];
    
    setModalTitle(featureDetails.title);
    setModalDescription(featureDetails.description);
    setModalImage(featureDetails.image);
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
    setModalTitle('');
    setModalDescription('');
    setModalImage('');
  };

  return (
    <div className="w-screen min-h-screen bg-black text-blue-50">
      {/* Hero Section */}
      <div className="relative w-screen h-screen overflow-hidden">
        <video
          src="/videos/zero-supervise-hero.mp4"
          autoPlay
          loop
          muted
          className="absolute left-0 top-0 size-full object-cover"
        />
        {/* LOGO AND TITLE CONTAINER */}
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/60 logo-title-container">
        <div ref={logoRef} className="logo">
          <img
            src={ZeroSuperviseLogo}
            alt="Zero Supervise Logo"
            className="w-40 h-40 object-contain"
          />
        </div>
        <AnimatedTitle
          title="Zero-Supervise"
          containerClass="text-center text-blue-50 font-zentry mt-4 mb-40" // Added margin-top to create spacing
        />
        </div>
      </div>

      {/* Scroll Down Button */}
      {!scrollDownClicked && (
        <div
          ref={scrollDownRef}
          className="fixed bottom-10 left-1/2 transform -translate-x-1/2 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
          onClick={scrollToContent}
        >
          <TiArrowDown size={24} className="text-white/40 hover:text-white" />
          <span className="text-white/40 hover:text-white">SCROLL DOWN</span>
        </div>
      )}

      {/* Content Section */}
      <div id="content" className="px-10 py-20 mx-auto max-w-7xl">
        {/* Introduction */}
        <div className="mb-10 text-center">
        <h2 className="text-4xl font-extrabold text-teal-600">Financial Market Monitoring</h2>
        <p className="mt-2 text-lg md:text-xl text-gray-300">
            Empowering regulatory bodies with advanced tools to monitor and maintain market integrity.
          </p>
        </div>

        {/* Core Features */}
        <section className="px-4 py-8 bg-black">
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">Core Features</h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
            Unveil the Game-Changing Capabilities of Zero-Supervise: Real-Time Market Monitoring, Predictive Compliance, and Blockchain Audit Trails for Transparent and Efficient Regulatory Oversight.
          </p>
          <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {CoreFeatures.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>
                <div className="mt-auto self-start">
                  <LearnMoreButton
                    productId={index}
                    onClick={() => openModal(item.title)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>

        <br />

        {/* Technical Capabilities */}
        <section className="px-4 py-8 bg-black">
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">Technical Capabilities</h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
            Experience the Cutting-Edge Technical Capabilities of Zero-Supervise: Microservices, Blockchain Integration, and Real-Time Data Streaming for Scalable, Secure, and Intelligent Market Oversight.
          </p>
          <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {TechnicalCapabilities.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>
                <div className="mt-auto self-start">
                  <LearnMoreButton
                    productId={index}
                    onClick={() => openModal(item.title, item.description)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>


        <br />

        {/* Highlights Section */}
          <section className="mt-20 space-y-16">

          {/* First Highlight */}
          <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row">
            <div className="w-full md:w-1/2">
              <img
                src="/assets/images/zero-supervise/real-time-surveillance.webp"
                alt="Real-Time Market Surveillance"
                className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
              />
            </div>
            <div className="w-full md:w-1/2">
              <h3 className="text-3xl md:text-4xl font-extrabold text-white">Real-Time Market Surveillance</h3>
              <p className="mt-4">
                Monitor financial markets in real-time, detecting potential misconduct, suspicious trades, and market manipulation as it happens.
              </p>
            </div>
          </div>

          {/* Second Highlight */}
          <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row-reverse">
            <div className="w-full md:w-1/2">
              <img
                src="/assets/images/zero-supervise/ai-predictive-compliance.webp"
                alt="AI-Powered Predictive Compliance"
                className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
              />
            </div>
            <div className="w-full md:w-1/2">
              <h3 className="text-3xl md:text-4xl font-extrabold text-white">AI-Powered Predictive Compliance</h3>
              <p className="mt-4">
                Leverage AI to forecast compliance risks before they escalate, allowing regulatory authorities to act preemptively.
              </p>
            </div>
          </div>
        </section>
        <br /><br /><br />   
        
        {/* Key Benefits */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
           Key Benefits
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Unlock the Key Benefits of Zero-Supervise: Proactive Risk Management, Audit-Ready Reports, and Scalable Compliance to Meet Evolving Regulatory Demands.
          </p>
          {/* Optional Divider */}
          <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {KeyBenefits.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>
                
                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton
                    productId={index}
                    onClick={() => openModal(item.title, item.description)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>

        <br /><br /><br />   
        
        {/* User Personas */}
        <section className="px-4 py-8 bg-black">
          <div className="mb-8 text-center">
            <h2 className="text-4xl font-extrabold text-teal-600">
            User Personas
            </h2>
            <p className="mt-2 text-lg md:text-xl text-gray-300">
            Empowering Key Stakeholders: From Regulatory Authorities to Compliance Teams, Zero-Supervise Delivers Customised Oversight and Control Across the Financial Ecosystem.
            </p>
            <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
            {UserPersonas.map((item, index) => (
              <div
                key={index}
                className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
              >
                <div
                  className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                  style={{ backgroundImage: `url(${item.image})` }}
                ></div>

                <div className="relative z-10 flex flex-col flex-grow">
                  <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                  <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                  <div className="mt-auto self-start">
                    <LearnMoreButton
                      productId={index}
                      onClick={() => openModal(item.title, item.description)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Key Features */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
           Key Features
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Essential Features of Zero-Supervise: Real-Time Surveillance, Automated Workflows, and Predictive Risk Indicators to Drive Compliance Excellence.
          </p>
        {/* Optional Divider */}
        <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {KeyFeatures.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton 
                  productId={index} 
                  onClick={() => openModal(item.title)} 
                  />              
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Security & Compliance */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
          Comprehensive Security & Compliance
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          GDPR Alignment, Real-Time Anomaly Detection, and Advanced Role-Based Access Controls for Complete Regulatory Assurance.
          </p>
        {/* Optional Divider */}
        <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {SecurityAndCompliance.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton 
                  productId={index} 
                  onClick={() => openModal(item.title)} 
                  />              
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>
        <br />        


        <br />

        
        </section>
        <br />
        <br /><br />

         {/* Third Highlight */}
          <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row">
          <div className="w-full md:w-1/2">
            <img
              src="/assets/images/zero-supervise/blockchain-audit-trails.webp"
              alt="Blockchain-Powered Audit Trails"
              className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
            />
          </div>
          <div className="w-full md:w-1/2">
            <h3 className="text-3xl md:text-4xl font-extrabold text-white">Blockchain-Powered Audit Trails</h3>
            <p className="mt-4">
              Ensure immutable, verifiable audit trails using blockchain technology, guaranteeing data integrity and regulatory transparency.
            </p>
          </div>
        </div>

        {/* Fourth Highlight */}
        <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row-reverse">
          <div className="w-full md:w-1/2">
            <img
              src="/assets/images/zero-supervise/automated-reporting.webp"
              alt="Automated Compliance Reporting"
              className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
            />
          </div>
          <div className="w-full md:w-1/2">
            <h3 className="text-3xl md:text-4xl font-extrabold text-white">Automated Compliance Reporting</h3>
            <p className="mt-4">
              Streamline compliance reporting with fully automated audit-ready reports, saving time and reducing operational burdens.
            </p>
          </div>
        </div>
      </section>
      <br /><br /><br />                

      {/* Future Enhancements */}
      <section className="px-4 py-8 bg-black">
      {/* Title Container */}
      <div className="mb-8 text-center">
        <h2 className="text-4xl font-extrabold text-teal-600">
        Future Enhancements
        </h2>
        <p className="mt-2 text-lg md:text-xl text-gray-300">
        AI Risk Scoring, DeFi Oversight, and Mobile Access to Revolutionise Regulatory Compliance and Market Supervision.
        </p>
      {/* Optional Divider */}
      <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
      </div>

      {/* Features Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
        {FutureEnhancements.map((item, index) => (
          <div
            key={index}
            className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
          >
            {/* Background Image */}
            <div
              className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
              style={{ backgroundImage: `url(${item.image})` }}
            ></div>

            {/* Content Container */}
            <div className="relative z-10 flex flex-col flex-grow">
              <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

              {/* Description with consistent space below */}
              <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

              {/* Learn More Button Positioned at Bottom Left */}
              <div className="mt-auto self-start">
                <LearnMoreButton 
                productId={index} 
                onClick={() => openModal(item.title)} 
                />              
              </div>
            </div>
          </div>
        ))}
      </div>
      </section>
      <br /> 

        {/* Back to Products Button */}
        <div className="flex justify-center mt-10">
          <BackToProductsButton />
        </div>
      </div>

      {/* Back to Top Button */}
      {isBottom && (
        <div
          ref={scrollTopRef}
          className="fixed bottom-10 left-5 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
          onClick={scrollToTop}
        >
          <TiArrowUp
            size={24}
            className="text-white/40 hover:text-white transition-transform duration-300"
          />
          <span className="text-white/40 hover:text-white">BACK TO TOP</span>
        </div>
      )}

      {/* Footer */}
      <Footer />

      <ZeroSuperviseLearnMoreCustomModal 
        isOpen={isModalOpen} 
        onClose={closeModal} 
        title={modalTitle} 
        description={modalDescription} 
        image={modalImage} 
      />
    </div>
  );
};

export default ZeroSupervise;