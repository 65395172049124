export const CoreFeatures = [
    {
        title: "Multi-Chain Compatibility",
        description: 
            "Effortlessly manage tokens across major blockchains like Ethereum, Binance Smart Chain, Solana, and Polygon, all from one wallet interface.",
        image: "/assets/images/zero-crypto-wallet/multi-chain-compatibility.webp",
    },
    {
        title: "DeFi Integration",
        description: 
            "Directly access DeFi protocols to stake, lend, borrow, and participate in liquidity mining, enabling wealth generation within the wallet.",
        image: "/assets/images/zero-crypto-wallet/defi-integration.webp",
    },
    {
        title: "Enhanced Security",
        description: 
            "Protect your assets with AES encryption, biometric authentication, and multi-factor authentication, ensuring unparalleled security.",
        image: "/assets/images/zero-crypto-wallet/enhanced-security.webp",
    },
    {
        title: "Customisable Portfolio Dashboard",
        description: 
            "Track your assets with real-time analytics, profit and loss summaries, and a clear portfolio overview tailored to your preferences.",
        image: "/assets/images/zero-crypto-wallet/portfolio-dashboard.webp",
    },
    {
        title: "NFT Management",
        description: 
            "Store, view, and trade your NFTs with a sleek integrated gallery, simplifying your digital art collection experience.",
        image: "/assets/images/zero-crypto-wallet/nft-management.webp",
    },
    {
        title: "DApp Browser",
        description: 
            "Seamlessly interact with decentralised applications like Uniswap and Aave, opening new opportunities in the blockchain ecosystem.",
        image: "/assets/images/zero-crypto-wallet/dapp-browser.webp",
    },
];

export const TechnicalCapabilities = [
    {
        title: "Non-Custodial Design",
        description: 
            "Ensures private keys are stored locally on user devices with AES encryption, giving users complete ownership and control.",
        image: "/assets/images/zero-crypto-wallet/non-custodial-design.webp",
    },
    {
        title: "Multi-Chain Integration Layer",
        description: 
            "Supports interaction with EVM-compatible chains like Ethereum, Binance Smart Chain, Solana, and Polygon, enhancing blockchain interoperability.",
        image: "/assets/images/zero-crypto-wallet/multi-chain-integration.webp",
    },
    {
        title: "Biometric and 2FA Security",
        description: 
            "Leverages biometric authentication and two-factor authentication for unparalleled protection against unauthorised access.",
        image: "/assets/images/zero-crypto-wallet/biometric-security.webp",
    },
    {
        title: "DApp Browser Integration",
        description: 
            "Includes a built-in browser to connect seamlessly with decentralised applications (DApps) for trading, gaming, and governance.",
        image: "/assets/images/zero-crypto-wallet/dapp-browser1.webp",
    },
    {
        title: "Real-Time Gas Optimisation",
        description: 
            "Optimises gas fees with real-time calculations, enabling efficient and cost-effective transactions across blockchains.",
        image: "/assets/images/zero-crypto-wallet/gas-optimisation.webp",
    },
    {
        title: "WalletConnect Support",
        description: 
            "Integrates WalletConnect for secure connections with DeFi platforms, DApps, and other blockchain ecosystems.",
        image: "/assets/images/zero-crypto-wallet/walletconnect-support.webp",
    },
];

export const KeyBenefits = [
    {
        title: "Full Asset Ownership",
        description: 
            "Empower users with non-custodial wallet features, ensuring complete control and ownership of their digital assets.",
        image: "/assets/images/zero-crypto-wallet/full-asset-ownership.webp",
    },
    {
        title: "Enhanced Financial Freedom",
        description: 
            "Access decentralised finance protocols, staking, and liquidity pools to grow wealth directly from the wallet.",
        image: "/assets/images/zero-crypto-wallet/financial-freedom.webp",
    },
    {
        title: "Streamlined User Experience",
        description: 
            "Enjoy a user-friendly interface with Customisable dashboards and intuitive navigation, ideal for both beginners and experts.",
        image: "/assets/images/zero-crypto-wallet/streamlined-ux.webp",
    },
    {
        title: "Top-Tier Security Features",
        description: 
            "Benefit from AES encryption, biometric authentication, and multi-factor authentication for maximum protection against threats.",
        image: "/assets/images/zero-crypto-wallet/security-features.webp",
    },
    {
        title: "Cross-Platform Accessibility",
        description: 
            "Seamlessly switch between web, mobile, and desktop apps with synchronised access to your digital assets.",
        image: "/assets/images/zero-crypto-wallet/cross-platform-accessibility.webp",
    },
    {
        title: "Future-Proof Innovation",
        description: 
            "Stay ahead with features like NFT support, advanced analytics, and DApp integration to meet evolving blockchain needs.",
        image: "/assets/images/zero-crypto-wallet/future-proof-innovation.webp",
    },
];


export const UseCases = [
    {
        title: "DeFi Investments",
        description: 
            "Enable users to participate in staking, lending, and liquidity mining with direct access to DeFi protocols from the wallet.",
        image: "/assets/images/zero-crypto-wallet/defi-investments.webp",
    },
    {
        title: "NFT Management",
        description: 
            "Store, display, and trade NFTs within a built-in gallery, catering to collectors and digital art enthusiasts.",
        image: "/assets/images/zero-crypto-wallet/nft-management1.webp",
    },
    {
        title: "Cross-Chain Asset Management",
        description: 
            "Allow users to manage and transfer digital assets across multiple blockchain networks, eliminating the need for multiple wallets.",
        image: "/assets/images/zero-crypto-wallet/cross-chain-management.webp",
    },
    {
        title: "Secure Transactions",
        description: 
            "Provide users with advanced security measures for sending and receiving funds securely, protecting against unauthorised access.",
        image: "/assets/images/zero-crypto-wallet/secure-transactions.webp",
    },
    {
        title: "DApp Integration",
        description: 
            "Enable seamless interaction with decentralised applications, such as decentralised exchanges, games, and governance platforms.",
        image: "/assets/images/zero-crypto-wallet/dapp-integration.webp",
    },
    {
        title: "Portfolio Tracking",
        description: 
            "Offer real-time analytics and tracking tools to monitor portfolio performance, profits, and losses in a single dashboard.",
        image: "/assets/images/zero-crypto-wallet/portfolio-tracking.webp",
    },
];

export const SecurityAndCompliance = [
    {
        title: "Advanced Encryption (AES-256)",
        description: 
            "Protect sensitive user data and private keys with robust AES-256 encryption, ensuring the highest level of security.",
        image: "/assets/images/zero-crypto-wallet/aes-256-encryption.webp",
    },
    {
        title: "Biometric Authentication",
        description: 
            "Enable secure and seamless access using biometric authentication, such as fingerprint and facial recognition.",
        image: "/assets/images/zero-crypto-wallet/biometric-authentication1.webp",
    },
    {
        title: "KYC/AML Integration",
        description: 
            "Comply with global regulations by incorporating Know Your Customer (KYC) and Anti-Money Laundering (AML) checks directly within the wallet.",
        image: "/assets/images/zero-crypto-wallet/kyc-aml-compliance.webp",
    },
    {
        title: "Role-Based Access Control (RBAC)",
        description: 
            "Limit access to wallet features based on user roles, ensuring unauthorised individuals cannot access sensitive areas.",
        image: "/assets/images/zero-crypto-wallet/rbac.webp",
    },
    {
        title: "Comprehensive Audit Trails",
        description: 
            "Track all actions and transactions within the wallet to ensure transparency and compliance with regulatory requirements.",
        image: "/assets/images/zero-crypto-wallet/audit-trails.webp",
    },
    {
        title: "GDPR Compliance",
        description: 
            "Adhere to GDPR standards by ensuring secure handling of user data, empowering users with privacy and control.",
        image: "/assets/images/zero-crypto-wallet/gdpr-compliance.webp",
    },
];


export const BusinessBenefits = [
    {
        title: "Enhanced User Retention",
        description: 
            "Provide a secure, user-friendly wallet experience, boosting customer loyalty and encouraging long-term engagement.",
        image: "/assets/images/zero-crypto-wallet/user-retention.webp",
    },
    {
        title: "Regulatory Compliance Simplification",
        description: 
            "Reduce compliance overhead with integrated KYC, AML, and GDPR adherence, ensuring smooth operations across regions.",
        image: "/assets/images/zero-crypto-wallet/compliance-simplification.webp",
    },
    {
        title: "Operational Cost Savings",
        description: 
            "Automate security and compliance processes, minimising the need for manual interventions and reducing operational expenses.",
        image: "/assets/images/zero-crypto-wallet/operational-cost-savings.webp",
    },
    {
        title: "Increased Market Competitiveness",
        description: 
            "Stand out with advanced multi-chain, DeFi, and NFT support, attracting a broader user base and increasing platform value.",
        image: "/assets/images/zero-crypto-wallet/market-competitiveness.webp",
    },
    {
        title: "Boosted Brand Reputation",
        description: 
            "Foster trust by offering industry-leading security features and seamless DeFi integration, elevating brand credibility.",
        image: "/assets/images/zero-crypto-wallet/brand-reputation.webp",
    },
    {
        title: "Revenue Growth via DeFi Services",
        description: 
            "Unlock new revenue streams through wallet-integrated DeFi services, enabling staking, lending, and liquidity mining.",
        image: "/assets/images/zero-crypto-wallet/revenue-growth.webp",
    },
];

export const FutureEnhancements = [
    {
        title: "Decentralised Identity (DID) Integration",
        description: 
            "Incorporate decentralised identity (DID) solutions to provide secure, privacy-first user verification and authentication.",
        image: "/assets/images/zero-crypto-wallet/did-integration.webp",
    },
    {
        title: "Expanded Multi-Chain Support",
        description: 
            "Add compatibility for emerging blockchains like Solana and Avalanche, ensuring broader network access for users.",
        image: "/assets/images/zero-crypto-wallet/expanded-multi-chain.webp",
    },
    {
        title: "Fiat On/Off Ramp Integration",
        description: 
            "Enable seamless conversion between fiat and cryptocurrency directly within the wallet, enhancing accessibility.",
        image: "/assets/images/zero-crypto-wallet/fiat-on-off-ramp.webp",
    },
    {
        title: "Advanced Portfolio Analytics",
        description: 
            "Introduce predictive analytics and detailed insights to help users optimise their investment strategies.",
        image: "/assets/images/zero-crypto-wallet/advanced-portfolio-analytics.webp",
    },
    {
        title: "Zero-DApp Store",
        description: 
            "Launch an integrated marketplace for accessing and managing decentralised applications directly from the wallet.",
        image: "/assets/images/zero-crypto-wallet/zero-dapp-store.webp",
    },
    {
        title: "Automated Smart Contract Deployment",
        description: 
            "Provide tools for users to deploy and manage smart contracts with minimal technical knowledge, simplifying blockchain interaction.",
        image: "/assets/images/zero-crypto-wallet/smart-contract-deployment.webp",
    },
];











