export const CoreFeatureDetails = {
    "Multi-Chain Compatibility": {
      title: "Multi-Chain Compatibility",
      description: `
        Effortlessly manage tokens across major blockchains like Ethereum, Binance Smart Chain, Solana, and Polygon, all from one wallet interface.
        
        Key Features:
        **Cross-Chain Support:** Seamlessly handle assets on multiple blockchain networks.
        **Unified Interface:** Manage all tokens and wallets within a single intuitive dashboard.
        **Future-Proof:** Expandable support for emerging blockchains.
      `,
      image: "/assets/images/zero-crypto-wallet/multi-chain-compatibility.webp"
    },
  
    "DeFi Integration": {
      title: "DeFi Integration",
      description: `
        Directly access DeFi protocols to stake, lend, borrow, and participate in liquidity mining, enabling wealth generation within the wallet.
        
        Key Features:
        **Staking and Lending:** Grow wealth through staking and lending protocols.
        **Liquidity Pools:** Join liquidity pools to earn passive income.
        **DeFi Simplified:** Access decentralised finance without leaving the wallet.
      `,
      image: "/assets/images/zero-crypto-wallet/defi-integration.webp"
    },
  
    "Enhanced Security": {
      title: "Enhanced Security",
      description: `
        Protect your assets with AES encryption, biometric authentication, and multi-factor authentication, ensuring unparalleled security.
        
        Key Features:
        **AES Encryption:** Industry-standard encryption for maximum data protection.
        **Biometric Authentication:** Use fingerprint or face recognition for secure access.
        **Multi-Factor Authentication:** Add an extra layer of protection with MFA.
      `,
      image: "/assets/images/zero-crypto-wallet/enhanced-security.webp"
    },
  
    "Customisable Portfolio Dashboard": {
      title: "Customisable Portfolio Dashboard",
      description: `
        Track your assets with real-time analytics, profit and loss summaries, and a clear portfolio overview tailored to your preferences.
        
        Key Features:
        **Real-Time Analytics:** Monitor market trends and portfolio performance.
        **Profit/Loss Tracking:** Understand gains and losses with detailed metrics.
        **Custom Layouts:** Personalise dashboard widgets for an optimised view.
      `,
      image: "/assets/images/zero-crypto-wallet/portfolio-dashboard.webp"
    },
  
    "NFT Management": {
      title: "NFT Management",
      description: `
        Store, view, and trade your NFTs with a sleek integrated gallery, simplifying your digital art collection experience.
        
        Key Features:
        **Built-In Gallery:** Display and manage NFTs in a visually stunning gallery.
        **Trading Simplified:** Trade NFTs directly from the wallet.
        **Multi-Chain NFT Support:** Manage NFTs across multiple blockchain ecosystems.
      `,
      image: "/assets/images/zero-crypto-wallet/nft-management.webp"
    },
  
    "DApp Browser": {
      title: "DApp Browser",
      description: `
        Seamlessly interact with decentralised applications like Uniswap and Aave, opening new opportunities in the blockchain ecosystem.
        
        Key Features:
        **DApp Accessibility:** Explore and use DApps directly within the wallet.
        **DeFi Integration:** Easily connect to DeFi platforms for trading and governance.
        **Gaming and Beyond:** Access blockchain games and other innovative DApps.
      `,
      image: "/assets/images/zero-crypto-wallet/dapp-browser.webp"
    },
  
    "Default": {
      title: "Default Title",
      description: "Content not available.",
      image: "/assets/images/default-image.webp"
    }
  };

  
  export const TechnicalCapabilitiesDetails = {
    "Non-Custodial Design": {
      title: "Non-Custodial Design",
      description: `
        Ensures private keys are stored locally on user devices with AES encryption, giving users complete ownership and control.
        
        Key Features:
        **Local Key Storage:** Private keys never leave the user's device.
        **AES Encryption:** State-of-the-art encryption safeguards sensitive data.
        **Full Ownership:** Users retain full control over their crypto assets without relying on third parties.
      `,
      image: "/assets/images/zero-crypto-wallet/non-custodial-design.webp"
    },
  
    "Multi-Chain Integration Layer": {
      title: "Multi-Chain Integration Layer",
      description: `
        Supports interaction with EVM-compatible chains like Ethereum, Binance Smart Chain, Solana, and Polygon, enhancing blockchain interoperability.
        
        Key Features:
        **Cross-Chain Transactions:** Effortlessly move assets between supported blockchains.
        **EVM-Compatible Support:** Includes major networks like Ethereum, Binance Smart Chain, and Polygon.
        **Scalable Interoperability:** Expandable for future blockchain integrations.
      `,
      image: "/assets/images/zero-crypto-wallet/multi-chain-integration.webp"
    },
  
    "Biometric and 2FA Security": {
      title: "Biometric and 2FA Security",
      description: `
        Leverages biometric authentication and two-factor authentication for unparalleled protection against unauthorised access.
        
        Key Features:
        **Biometric Login:** Use fingerprint or face recognition for secure and quick access.
        **Two-Factor Authentication (2FA):** Add an extra layer of protection with OTP or app-based codes.
        **Advanced Protection:** Prevent unauthorised access with multi-layered security protocols.
      `,
      image: "/assets/images/zero-crypto-wallet/biometric-security.webp"
    },
  
    "DApp Browser Integration": {
      title: "DApp Browser Integration",
      description: `
        Includes a built-in browser to connect seamlessly with decentralised applications (DApps) for trading, gaming, and governance.
        
        Key Features:
        **Seamless Access:** Directly interact with DApps from within the wallet.
        **Diverse Applications:** Access DeFi platforms, games, and governance tools.
        **Web3 Integration:** Fully compatible with Web3 standards for decentralised browsing.
      `,
      image: "/assets/images/zero-crypto-wallet/dapp-browser.webp"
    },
  
    "Real-Time Gas Optimisation": {
      title: "Real-Time Gas Optimisation",
      description: `
        Optimises gas fees with real-time calculations, enabling efficient and cost-effective transactions across blockchains.
        
        Key Features:
        **Cost Efficiency:** Minimise transaction fees with optimised gas settings.
        **Real-Time Updates:** Receive instant suggestions for the best gas prices.
        **Multi-Network Optimisation:** Supports gas fee management across multiple blockchains.
      `,
      image: "/assets/images/zero-crypto-wallet/gas-optimisation.webp"
    },
  
    "WalletConnect Support": {
      title: "WalletConnect Support",
      description: `
        Integrates WalletConnect for secure connections with DeFi platforms, DApps, and other blockchain ecosystems.
        
        Key Features:
        **Secure Connections:** Link to external platforms without compromising security.
        **DApp Compatibility:** Supports connections with major DeFi and NFT platforms.
        **Easy QR Code Pairing:** Quickly connect using WalletConnect’s QR code system.
      `,
      image: "/assets/images/zero-crypto-wallet/walletconnect-support.webp"
    },
  
    "Default": {
      title: "Default Title",
      description: "Content not available.",
      image: "/assets/images/default-image.webp"
    }
  };

  export const KeyBenefitsDetails = {
    "Full Asset Ownership": {
      title: "Full Asset Ownership",
      description: `
        Empower users with non-custodial wallet features, ensuring complete control and ownership of their digital assets.
        
        Key Benefits:
        **Total Control:** Private keys stored locally, ensuring user sovereignty over funds.
        **Enhanced Privacy:** No third-party access to sensitive data or assets.
        **User Empowerment:** Retain full ownership without relying on custodial services.
      `,
      image: "/assets/images/zero-crypto-wallet/full-asset-ownership.webp"
    },
  
    "Enhanced Financial Freedom": {
      title: "Enhanced Financial Freedom",
      description: `
        Access decentralised finance protocols, staking, and liquidity pools to grow wealth directly from the wallet.
        
        Key Benefits:
        **DeFi Access:** Seamlessly interact with lending, staking, and liquidity mining platforms.
        **Passive Income:** Earn rewards through staking and DeFi participation.
        **Wealth Building:** Unlock tools to grow and diversify digital assets.
      `,
      image: "/assets/images/zero-crypto-wallet/financial-freedom.webp"
    },
  
    "Streamlined User Experience": {
      title: "Streamlined User Experience",
      description: `
        Enjoy a user-friendly interface with Customisable dashboards and intuitive navigation, ideal for both beginners and experts.
        
        Key Benefits:
        **Customisable Dashboards:** Tailor the interface to match individual preferences.
        **Intuitive Design:** Simplified navigation for seamless interactions.
        **Beginner-Friendly:** Clear onboarding steps to guide new users.
      `,
      image: "/assets/images/zero-crypto-wallet/streamlined-ux.webp"
    },
  
    "Top-Tier Security Features": {
      title: "Top-Tier Security Features",
      description: `
        Benefit from AES encryption, biometric authentication, and multi-factor authentication for maximum protection against threats.
        
        Key Benefits:
        **Unmatched Encryption:** Secure sensitive data with AES-256 encryption.
        **Multi-Factor Authentication:** Add additional layers of security for wallet access.
        **Biometric Security:** Utilise facial recognition and fingerprint scans for secure logins.
      `,
      image: "/assets/images/zero-crypto-wallet/security-features.webp"
    },
  
    "Cross-Platform Accessibility": {
      title: "Cross-Platform Accessibility",
      description: `
        Seamlessly switch between web, mobile, and desktop apps with synchronised access to your digital assets.
        
        Key Benefits:
        **Multi-Device Access:** Manage assets across web, mobile, and desktop platforms.
        **Real-Time Sync:** Keep data and transactions consistent across devices.
        **Always Connected:** Access your wallet anytime, anywhere.
      `,
      image: "/assets/images/zero-crypto-wallet/cross-platform-accessibility.webp"
    },
  
    "Future-Proof Innovation": {
      title: "Future-Proof Innovation",
      description: `
        Stay ahead with features like NFT support, advanced analytics, and DApp integration to meet evolving blockchain needs.
        
        Key Benefits:
        **NFT Ready:** Store, view, and trade NFTs seamlessly.
        **Cutting-Edge Features:** Enjoy ongoing updates to stay competitive in the blockchain space.
        **Blockchain Ecosystem Support:** Connect with emerging DApps and platforms.
      `,
      image: "/assets/images/zero-crypto-wallet/future-proof-innovation.webp"
    },
  
    "Default": {
      title: "Default Title",
      description: "Content not available.",
      image: "/assets/images/default-image.webp"
    }
  };

  export const UseCasesDetails = {
    "DeFi Investments": {
      title: "DeFi Investments",
      description: `
        Enable users to participate in staking, lending, and liquidity mining with direct access to DeFi protocols from the wallet.
        
        Key Use Cases:
        **Staking & Lending:** Users can earn passive income through DeFi protocols.
        **Liquidity Mining:** Participate in liquidity pools to generate rewards.
        **Seamless Integration:** Directly connect to DeFi platforms without external tools.
      `,
      image: "/assets/images/zero-crypto-wallet/defi-investments.webp"
    },
  
    "NFT Management": {
      title: "NFT Management",
      description: `
        Store, display, and trade NFTs within a built-in gallery, catering to collectors and digital art enthusiasts.
        
        Key Use Cases:
        **NFT Gallery:** View and organise your NFT collection in a visually appealing format.
        **NFT Trading:** Seamlessly buy and sell NFTs directly from the wallet.
        **Cross-Chain Support:** Manage NFTs across multiple blockchain ecosystems.
      `,
      image: "/assets/images/zero-crypto-wallet/nft-management.webp"
    },
  
    "Cross-Chain Asset Management": {
      title: "Cross-Chain Asset Management",
      description: `
        Allow users to manage and transfer digital assets across multiple blockchain networks, eliminating the need for multiple wallets.
        
        Key Use Cases:
        **Unified Wallet Interface:** Manage assets across Ethereum, Binance Smart Chain, Solana, and more.
        **Effortless Transfers:** Instantly move assets between compatible blockchain networks.
        **Increased Flexibility:** Users can access multiple blockchains from a single wallet.
      `,
      image: "/assets/images/zero-crypto-wallet/cross-chain-management.webp"
    },
  
    "Secure Transactions": {
      title: "Secure Transactions",
      description: `
        Provide users with advanced security measures for sending and receiving funds securely, protecting against unauthorised access.
        
        Key Use Cases:
        **AES Encryption:** Encrypt transaction data to protect sensitive information.
        **Biometric Authentication:** Ensure secure access using fingerprint or facial recognition.
        **Two-Factor Authentication:** Add an additional layer of security for transaction approvals.
      `,
      image: "/assets/images/zero-crypto-wallet/secure-transactions.webp"
    },
  
    "DApp Integration": {
      title: "DApp Integration",
      description: `
        Enable seamless interaction with decentralised applications, such as decentralised exchanges, games, and governance platforms.
        
        Key Use Cases:
        **Decentralised Exchange (DEX) Access:** Directly trade assets without relying on centralised exchanges.
        **Gaming and NFTs:** Interact with blockchain-based games and NFTs seamlessly.
        **Governance Participation:** Engage in decentralised governance decisions from within the wallet.
      `,
      image: "/assets/images/zero-crypto-wallet/dapp-integration.webp"
    },
  
    "Portfolio Tracking": {
      title: "Portfolio Tracking",
      description: `
        Offer real-time analytics and tracking tools to monitor portfolio performance, profits, and losses in a single dashboard.
        
        Key Use Cases:
        **Real-Time Portfolio Overview:** Track asset performance and see gains or losses instantly.
        **Detailed Analytics:** Utilise performance metrics such as ROI, P&L, and risk analysis.
        **Customisable Dashboard:** Personalise views for a tailored investment experience.
      `,
      image: "/assets/images/zero-crypto-wallet/portfolio-tracking.webp"
    },
  
    "Default": {
      title: "Default Title",
      description: "Content not available.",
      image: "/assets/images/default-image.webp"
    }
  };

  export const SecurityAndComplianceDetails = {
    "Advanced Encryption (AES-256)": {
      title: "Advanced Encryption (AES-256)",
      description: `
        Protect sensitive user data and private keys with robust AES-256 encryption, ensuring the highest level of security.
        
        Key Features:
        **Data Encryption:** Encrypt all sensitive data, including private keys and user transactions.
        **Uncompromised Security:** Utilise the industry-standard AES-256 for maximum protection.
        **User Privacy:** Ensure all user information remains confidential and secure.
      `,
      image: "/assets/images/zero-crypto-wallet/aes-256-encryption.webp"
    },
  
    "Biometric Authentication": {
      title: "Biometric Authentication",
      description: `
        Enable secure and seamless access using biometric authentication, such as fingerprint and facial recognition.
        
        Key Features:
        **Seamless Login:** Utilise biometric data for quick and secure wallet access.
        **Dual Protection:** Combine biometrics with PIN or password for enhanced security.
        **Device-Specific Security:** Leverage the security hardware of modern devices for precise authentication.
      `,
      image: "/assets/images/zero-crypto-wallet/biometric-authentication.webp"
    },
  
    "KYC/AML Integration": {
      title: "KYC/AML Integration",
      description: `
        Comply with global regulations by incorporating Know Your Customer (KYC) and Anti-Money Laundering (AML) checks directly within the wallet.
        
        Key Features:
        **Regulatory Compliance:** Meet global KYC/AML requirements seamlessly.
        **Integrated Verification:** Verify user identity directly through the wallet interface.
        **Fraud Prevention:** Reduce illicit activities with robust identity checks.
      `,
      image: "/assets/images/zero-crypto-wallet/kyc-aml-compliance.webp"
    },
  
    "Role-Based Access Control (RBAC)": {
      title: "Role-Based Access Control (RBAC)",
      description: `
        Limit access to wallet features based on user roles, ensuring unauthorised individuals cannot access sensitive areas.
        
        Key Features:
        **Customisable Roles:** Assign permissions based on user roles.
        **Enhanced Security:** Restrict access to sensitive data and features.
        **Scalable Management:** Suitable for both individual and organisational users.
      `,
      image: "/assets/images/zero-crypto-wallet/rbac.webp"
    },
  
    "Comprehensive Audit Trails": {
      title: "Comprehensive Audit Trails",
      description: `
        Track all actions and transactions within the wallet to ensure transparency and compliance with regulatory requirements.
        
        Key Features:
        **Action Logs:** Maintain a record of all user and system activities.
        **Regulatory Compliance:** Ensure adherence to financial and blockchain standards.
        **User Accountability:** Provide a clear history of actions for auditing.
      `,
      image: "/assets/images/zero-crypto-wallet/audit-trails.webp"
    },
  
    "GDPR Compliance": {
      title: "GDPR Compliance",
      description: `
        Adhere to GDPR standards by ensuring secure handling of user data, empowering users with privacy and control.
        
        Key Features:
        **Data Minimisation:** Collect and store only necessary user data.
        **Privacy Controls:** Allow users to control how their data is used.
        **Regulatory Adherence:** Ensure compliance with GDPR and similar data protection regulations.
      `,
      image: "/assets/images/zero-crypto-wallet/gdpr-compliance.webp"
    },
  
    "Default": {
      title: "Default Title",
      description: "Content not available.",
      image: "/assets/images/default-image.webp"
    }
  };

  
  export const BusinessBenefitsDetails = {
    "Enhanced User Retention": {
      title: "Enhanced User Retention",
      description: `
        Provide a secure, user-friendly wallet experience, boosting customer loyalty and encouraging long-term engagement.
        
        Key Benefits:
        **User-Friendly Design:** Simplify wallet navigation to cater to all user levels.
        **Loyalty Building:** Encourage long-term engagement through seamless experiences.
        **Security Assurance:** Retain users by delivering a secure environment for asset management.
      `,
      image: "/assets/images/zero-crypto-wallet/user-retention.webp"
    },
  
    "Regulatory Compliance Simplification": {
      title: "Regulatory Compliance Simplification",
      description: `
        Reduce compliance overhead with integrated KYC, AML, and GDPR adherence, ensuring smooth operations across regions.
        
        Key Benefits:
        **Streamlined Compliance:** Automate adherence to global regulations.
        **Cost Efficiency:** Minimise legal and administrative expenses.
        **Global Compatibility:** Operate seamlessly across multiple jurisdictions.
      `,
      image: "/assets/images/zero-crypto-wallet/compliance-simplification.webp"
    },
  
    "Operational Cost Savings": {
      title: "Operational Cost Savings",
      description: `
        Automate security and compliance processes, minimising the need for manual interventions and reducing operational expenses.
        
        Key Benefits:
        **Process Automation:** Eliminate repetitive tasks with automated workflows.
        **Reduced Overhead:** Cut costs associated with manual monitoring and compliance checks.
        **Resource Optimisation:** Allow teams to focus on strategic initiatives.
      `,
      image: "/assets/images/zero-crypto-wallet/operational-cost-savings.webp"
    },
  
    "Increased Market Competitiveness": {
      title: "Increased Market Competitiveness",
      description: `
        Stand out with advanced multi-chain, DeFi, and NFT support, attracting a broader user base and increasing platform value.
        
        Key Benefits:
        **Multi-Chain Innovation:** Appeal to diverse blockchain users.
        **DeFi and NFT Integration:** Expand capabilities to meet market demands.
        **Broader Appeal:** Attract a wide range of users with cutting-edge features.
      `,
      image: "/assets/images/zero-crypto-wallet/market-competitiveness.webp"
    },
  
    "Boosted Brand Reputation": {
      title: "Boosted Brand Reputation",
      description: `
        Foster trust by offering industry-leading security features and seamless DeFi integration, elevating brand credibility.
        
        Key Benefits:
        **Trust Building:** Establish your wallet as a secure and reliable platform.
        **Market Leadership:** Lead the market with innovative blockchain solutions.
        **Enhanced Credibility:** Solidify your brand’s reputation in the blockchain space.
      `,
      image: "/assets/images/zero-crypto-wallet/brand-reputation.webp"
    },
  
    "Revenue Growth via DeFi Services": {
      title: "Revenue Growth via DeFi Services",
      description: `
        Unlock new revenue streams through wallet-integrated DeFi services, enabling staking, lending, and liquidity mining.
        
        Key Benefits:
        **DeFi Revenue:** monetise staking, lending, and liquidity tools.
        **User Engagement:** Attract users seeking financial growth opportunities.
        **Scalable Opportunities:** Expand offerings as DeFi markets evolve.
      `,
      image: "/assets/images/zero-crypto-wallet/revenue-growth.webp"
    },
  
    "Default": {
      title: "Default Title",
      description: "Content not available.",
      image: "/assets/images/default-image.webp"
    }
  };

  export const FutureEnhancementsDetails = {
    "Decentralised Identity (DID) Integration": {
      title: "Decentralised Identity (DID) Integration",
      description: `
        Incorporate decentralised identity (DID) solutions to provide secure, privacy-first user verification and authentication.
        
        Key Enhancements:
        **Privacy-First Authentication:** Enable users to verify their identity without exposing sensitive information.
        **Blockchain-Based Identity:** Leverage decentralised identifiers for secure and transparent identity management.
        **Enhanced Security:** Reduce fraud risks with robust DID technology.
      `,
      image: "/assets/images/zero-crypto-wallet/did-integration.webp"
    },
  
    "Expanded Multi-Chain Support": {
      title: "Expanded Multi-Chain Support",
      description: `
        Add compatibility for emerging blockchains like Solana and Avalanche, ensuring broader network access for users.
        
        Key Enhancements:
        **Broader Blockchain Access:** Support for popular and emerging blockchains.
        **Enhanced Interoperability:** Enable seamless asset transfers across multiple networks.
        **Future-Ready Architecture:** Expand compatibility as new blockchains are adopted.
      `,
      image: "/assets/images/zero-crypto-wallet/expanded-multi-chain.webp"
    },
  
    "Fiat On/Off Ramp Integration": {
      title: "Fiat On/Off Ramp Integration",
      description: `
        Enable seamless conversion between fiat and cryptocurrency directly within the wallet, enhancing accessibility.
        
        Key Enhancements:
        **Simplified Transactions:** Buy and sell cryptocurrency with ease.
        **Improved Accessibility:** Attract a broader user base with fiat integration.
        **Regulatory Compliance:** Ensure adherence to financial regulations for fiat transactions.
      `,
      image: "/assets/images/zero-crypto-wallet/fiat-on-off-ramp.webp"
    },
  
    "Advanced Portfolio Analytics": {
      title: "Advanced Portfolio Analytics",
      description: `
        Introduce predictive analytics and detailed insights to help users optimise their investment strategies.
        
        Key Enhancements:
        **Predictive Analytics:** Utilise AI models to forecast market trends.
        **Comprehensive Insights:** Access detailed performance metrics and recommendations.
        **Strategy Optimisation:** Empower users to make informed financial decisions.
      `,
      image: "/assets/images/zero-crypto-wallet/advanced-portfolio-analytics.webp"
    },
  
    "Zero-DApp Store": {
      title: "Zero-DApp Store",
      description: `
        Launch an integrated marketplace for accessing and managing decentralised applications directly from the wallet.
        
        Key Enhancements:
        **DApp Marketplace:** Browse, access, and manage DApps seamlessly.
        **User-Friendly Interface:** Simplify DApp discovery and usage.
        **Expanded Functionality:** Offer a gateway to gaming, DeFi, and other blockchain applications.
      `,
      image: "/assets/images/zero-crypto-wallet/zero-dapp-store.webp"
    },
  
    "Automated Smart Contract Deployment": {
      title: "Automated Smart Contract Deployment",
      description: `
        Provide tools for users to deploy and manage smart contracts with minimal technical knowledge, simplifying blockchain interaction.
        
        Key Enhancements:
        **No-Code Deployment:** Allow users to deploy contracts without coding expertise.
        **Smart Contract Management:** Simplify contract updates and interactions.
        **Developer-Friendly Tools:** Attract developers with intuitive contract deployment features.
      `,
      image: "/assets/images/zero-crypto-wallet/smart-contract-deployment.webp"
    },
  
    "Default": {
      title: "Default Title",
      description: "Content not available.",
      image: "/assets/images/default-image.webp"
    }
  };
  



