export const CoreFeatureDetails = {
  "Mobile Payments": {
    title: "Mobile Payments",
    description: `
      Send, receive, and manage payments using fiat or crypto for both online and in-store purchases.
      
      Key Features:
      **Multi-Currency Support:** Pay using various fiat and cryptocurrencies.
      **Seamless Payment Experience:** Instant payments for online and in-store purchases.
      **Cross-Platform Access:** Access payments from mobile, desktop, and tablet devices.
    `,
    image: "/assets/images/zero-wave/mobile-payments.webp"
  },

  "Crypto Wallet": {
    title: "Crypto Wallet",
    description: `
      Store, trade, and manage major cryptocurrencies, ensuring safe and simple access to the world of digital currencies.
      
      Key Features:
      **Multi-Currency Support:** Store multiple cryptocurrencies like Bitcoin, Ethereum, and more.
      **Secure Storage:** Uses advanced encryption to safeguard your wallet.
      **Buy, Sell & Trade:** Seamless trading of cryptocurrencies within the wallet.
    `,
    image: "/assets/images/zero-wave/crypto-wallet.webp"
  },

  "Investment Tools": {
    title: "Investment Tools",
    description: `
      Track market data, monitor portfolio performance, and make smarter investment decisions.
      
      Key Features:
      **Portfolio Tracking:** View asset performance in real time.
      **Market Analysis:** Advanced analysis of stocks, commodities, and crypto.
      **Custom Reports:** Generate reports for performance and ROI.
    `,
    image: "/assets/images/zero-wave/investment-tools.webp"
  },

  "Real-Time Payments": {
    title: "Real-Time Payments",
    description: `
      Send money instantly to any location worldwide with ultra-fast cross-border payment processing.
      
      Key Features:
      **Instant Transfers:** Ultra-fast payment processing for international transfers.
      **Global Reach:** Send payments to any region of the world.
      **Currency Conversion:** Real-time currency conversion at competitive rates.
    `,
    image: "/assets/images/zero-wave/real-time-payments.webp"
  },

  "Multi-Currency Support": {
    title: "Multi-Currency Support",
    description: `
      Manage multiple fiat currencies and cryptocurrencies in one wallet, simplifying financial transactions across the globe.
      
      Key Features:
      **Multi-Currency Wallet:** Store, send, and receive multiple currencies.
      **Cross-Border Payments:** Simplify international transfers using multiple fiat currencies.
      **Currency Conversion:** View exchange rates and convert currencies on demand.
    `,
    image: "/assets/images/zero-wave/multi-currency-support.webp"
  },

  "Advanced Analytics": {
    title: "Advanced Analytics",
    description: `
      Monitor transaction history, wallet activity, and investment performance with real-time insights.
      
      Key Features:
      **Performance Insights:** View asset growth, profit/loss, and trends.
      **Transaction History:** Audit every transaction with full traceability.
      **Real-Time Updates:** Live analytics for up-to-date information.
    `,
    image: "/assets/images/zero-wave/advanced-analytics.webp"
  }
};


export const UserExperienceEnhancementsFeaturesDetails = {
  "User-Friendly UX": {
    title: "User-Friendly UX",
    description: `
      An intuitive design that simplifies financial navigation, enhancing user satisfaction and retention.
      
      Key Features:
      **Simple Interface:** Intuitive layout for easy navigation.
      **Optimised Flows:** Reduce steps required to complete key actions.
      **Visual Cues:** Clear prompts and highlights for important functions.
    `,
    image: "/assets/images/zero-wave/user-friendly-ux.webp"
  },

  "Educational Tools": {
    title: "Educational Tools",
    description: `
      Interactive guides and tutorials to support crypto beginners and provide financial literacy resources.
      
      Key Features:
      **Onboarding Tutorials:** Guided walkthroughs for new users.
      **Knowledge Base:** Access to articles, FAQs, and how-to guides.
      **Interactive Lessons:** Hands-on learning modules with interactive features.
    `,
    image: "/assets/images/zero-wave/educational-tools.webp"
  },

  "Custom Alerts & Notifications": {
    title: "Custom Alerts & Notifications",
    description: `
      Receive notifications for price changes, transaction updates, and market trends to stay informed.
      
      Key Features:
      **Customisable Alerts:** Tailor alerts for market shifts, prices, and events.
      **Multi-Channel Notifications:** Receive updates via SMS, email, and app notifications.
      **Real-Time Alerts:** Get instant alerts for market changes.
    `,
    image: "/assets/images/zero-wave/alerts-notifications.webp"
  },

  "Multi-Platform Access": {
    title: "Multi-Platform Access",
    description: `
      Access Zero-Wave from mobile, desktop, and web platforms with a consistent experience across all devices.
      
      Key Features:
      **Cross-Platform Sync:** Seamless sync across mobile, desktop, and web.
      **Responsive Design:** Flawless experience on any device size.
      **Multi-Device Login:** Use multiple devices simultaneously with session sync.
    `,
    image: "/assets/images/zero-wave/multi-platform-access.webp"
  },

  "Customisable Dashboard": {
    title: "Customisable Dashboard",
    description: `
      Users can personalise their dashboard layout to prioritise key financial data and investments.
      
      Key Features:
      **Drag-and-Drop Layouts:** Rearrange widgets to suit user needs.
      **Customisable Widgets:** Add, remove, and resize key elements.
      **Save Preferences:** Store layout preferences for a personalised experience.
    `,
    image: "/assets/images/zero-wave/customisable-dashboard.webp"
  },

  "Dark & Light Modes": {
    title: "Dark & Light Modes",
    description: `
      Users can switch between dark and light themes for a more personalised and visually comfortable experience.
      
      Key Features:
      **Light & Dark Themes:** Switch between dark and light themes instantly.
      **Eye Comfort Mode:** Reduce strain on eyes during extended use.
      **User Preference Memory:** System remembers the last selected mode.
    `,
    image: "/assets/images/zero-wave/dark-light-modes.webp"
  }
};


export const TechnicalCapabilitiesFeaturesDetails = {
  "Cross-Platform Compatibility": {
    title: "Cross-Platform Compatibility",
    description: `
      Developed with React Native for a unified codebase, allowing seamless access across iOS, Android, and desktop.
      
      Key Features:
      **Multi-Platform Support:** Compatible with iOS, Android, and desktop devices.
      **Unified Codebase:** Write once, run on multiple platforms.
      **Consistent UX:** Maintain a consistent user experience across devices.
    `,
    image: "/assets/images/zero-wave/cross-platform-compatibility.webp"
  },

  "Modular Microservices": {
    title: "Modular Microservices",
    description: `
      Microservice architecture ensures easy updates, fast deployments, and high system availability.
      
      Key Features:
      **Decoupled Services:** Isolated modules for payments, analytics, and reporting.
      **High Availability:** Resilient system with redundancy for zero downtime.
      **Scalability:** Scale individual services as needed for optimal performance.
    `,
    image: "/assets/images/zero-wave/microservices.webp"
  },

  "Zero-Blockchain Integration": {
    title: "Zero-Blockchain Integration",
    description: `
      Direct integration with Zero-Blockchain allows for secure, transparent, and traceable on-chain transactions.
      
      Key Features:
      **On-Chain Verification:** All transactions are verifiable on-chain.
      **Smart Contract Integration:** Automate contract execution with smart contracts.
      **Immutable Ledger:** Transaction history is permanent and tamper-proof.
    `,
    image: "/assets/images/zero-wave/blockchain-integration.webp"
  },

  "Secure API Gateway": {
    title: "Secure API Gateway",
    description: `
      Leverages a secure API gateway to facilitate communication between financial data providers and the Zero-Wave app.
      
      Key Features:
      **API Rate Limiting:** Prevents API abuse and overloading.
      **Data Security:** Encrypted communication with financial data providers.
      **Load Balancing:** Distributes requests to ensure stable performance.
    `,
    image: "/assets/images/zero-wave/api-gateway.webp"
  },

  "Continuous Deployment (CI/CD)": {
    title: "Continuous Deployment (CI/CD)",
    description: `
      Implements CI/CD pipelines to automate deployment, reduce downtime, and enable continuous improvement.
      
      Key Features:
      **Automated Deployments:** Automatically push updates to production.
      **Rollback Support:** Instantly revert to previous versions if needed.
      **Faster Release Cycles:** Reduce deployment time for faster releases.
    `,
    image: "/assets/images/zero-wave/continuous-deployment.webp"
  },

  "State Management": {
    title: "State Management",
    description: `
      Utilises lightweight state management with Zustand to ensure smooth state transitions and high performance.
      
      Key Features:
      **Lightweight State Management:** Reduces app overhead with minimal state.
      **High Performance:** Optimised for fast, efficient data updates.
      **Global State Synchronisation:** Consistent state across multiple components.
    `,
    image: "/assets/images/zero-wave/state-management.webp"
  }
};

export const SecurityAndComplianceFeaturesDetails = {
  "Biometric Authentication": {
    title: "Biometric Authentication",
    description: `
      Use biometric data such as FaceID or fingerprint scanning to ensure secure login and account access.
      
      Key Features:
      **FaceID & Fingerprint Access:** Log in with facial recognition or fingerprint scanning.
      **Fast & Secure Logins:** Faster login with enhanced security.
      **Multi-Device Support:** Works on mobile, tablets, and desktop devices.
    `,
    image: "/assets/images/zero-wave/biometric-authentication.webp"
  },

  "Multi-Factor Authentication (MFA)": {
    title: "Multi-Factor Authentication (MFA)",
    description: `
      Reinforce user security with two-factor authentication (2FA) for an added layer of protection.
      
      Key Features:
      **Two-Factor Authentication (2FA):** Adds an extra layer of security during login.
      **Time-Based One-Time Passwords (TOTP):** Use one-time passcodes for secure access.
      **Email & SMS Verification:** Receive login codes via email or SMS.
    `,
    image: "/assets/images/zero-wave/mfa-security.webp"
  },

  "KYC/AML Compliance": {
    title: "KYC/AML Compliance",
    description: `
      Adheres to Know Your Customer (KYC) and Anti-Money Laundering (AML) regulations to protect users and comply with financial standards.
      
      Key Features:
      **Identity Verification:** Verify users with government-issued IDs.
      **AML Risk Screening:** Screen users against global watchlists.
      **Compliance Monitoring:** Continuous compliance checks to prevent fraudulent activity.
    `,
    image: "/assets/images/zero-wave/kyc-aml-compliance.webp"
  },

  "Data Privacy (GDPR Compliance)": {
    title: "Data Privacy (GDPR Compliance)",
    description: `
      Ensures user privacy and data protection in line with GDPR regulations, safeguarding sensitive information.
      
      Key Features:
      **GDPR Compliance:** Full compliance with GDPR privacy regulations.
      **Data Anonymisation:** Encrypt and anonymise user data.
      **User Consent Management:** Control over data collection and usage.
    `,
    image: "/assets/images/zero-wave/gdpr-compliance.webp"
  },

  "End-to-End Encryption": {
    title: "End-to-End Encryption",
    description: `
      Encrypts user data at rest and in transit, providing industry-standard protection for financial transactions.
      
      Key Features:
      **Data Encryption (AES-256):** Industry-standard AES-256 encryption.
      **Encrypted Data Transfers:** Encrypts all data sent over networks.
      **Tamper-Proof Data:** Data is immutable and tamper-proof.
    `,
    image: "/assets/images/zero-wave/data-encryption.webp"
  },

  "Fraud Detection System": {
    title: "Fraud Detection System",
    description: `
      AI-driven fraud detection system to prevent suspicious activities and ensure user protection.
      
      Key Features:
      **AI-Powered Detection:** Detect suspicious activities in real time.
      **Behavioral Analytics:** Identify unusual user behavior for fraud prevention.
      **Automated Alerts:** Receive alerts for high-risk transactions.
    `,
    image: "/assets/images/zero-wave/fraud-detection.webp"
  }
};

export const UserJourneyFeaturesDetails = {
  "Onboarding": {
    title: "Onboarding",
    description: `
      Users sign up using their email, social login, or phone number, and complete the KYC process using biometric verification.
      
      Key Features:
      **Multi-Method Sign-Up:** Sign up via email, phone number, or social accounts.
      **Biometric Verification:** Verify user identity using facial recognition or fingerprint.
      **Instant Activation:** Get immediate access to key features after sign-up.
    `,
    image: "/assets/images/zero-wave/onboarding.webp"
  },

  "Wallet Setup": {
    title: "Wallet Setup",
    description: `
      Link bank accounts, payment cards, and crypto wallets to manage multiple financial assets from a single dashboard.
      
      Key Features:
      **Bank Integration:** Link multiple bank accounts and payment cards.
      **Crypto Wallet Support:** Store and manage multiple crypto wallets.
      **Unified Dashboard:** View and manage all financial assets from one central hub.
    `,
    image: "/assets/images/zero-wave/wallet-setup.webp"
  },

  "Payments & Transfers": {
    title: "Payments & Transfers",
    description: `
      Send money to family, friends, or businesses using fiat or cryptocurrency with low fees and instant transfers.
      
      Key Features:
      **Instant Transfers:** Send payments instantly to any destination.
      **Fiat & Crypto Support:** Transfer both fiat and cryptocurrency funds.
      **Global Reach:** Send funds globally with real-time status updates.
    `,
    image: "/assets/images/zero-wave/payments-transfers.webp"
  },

  "Crypto Trading": {
    title: "Crypto Trading",
    description: `
      Users can trade major cryptocurrencies directly within the app, with support for real-time trading and tracking.
      
      Key Features:
      **Real-Time Trading:** Execute trades in real-time with live price updates.
      **Multi-Crypto Support:** Trade major cryptocurrencies like BTC, ETH, and more.
      **Market Insights:** Access live market trends and analytics for better trading decisions.
    `,
    image: "/assets/images/zero-wave/crypto-trading.webp"
  },

  "Investment Tracking": {
    title: "Investment Tracking",
    description: `
      Track stock, crypto, and investment portfolios with real-time performance data and in-depth analytics.
      
      Key Features:
      **Portfolio View:** Monitor stocks, crypto, and investment portfolios.
      **Performance Insights:** Get in-depth analytics on profits, losses, and returns.
      **Custom Reports:** Generate detailed reports on your portfolio's performance.
    `,
    image: "/assets/images/zero-wave/investment-tracking.webp"
  },

  "Support & Assistance": {
    title: "Support & Assistance",
    description: `
      Access customer support through live chat, in-app support tickets, and self-service help guides.
      
      Key Features:
      **Live Chat Support:** Connect with support agents in real time.
      **In-App Help Center:** Access FAQs, how-to guides, and step-by-step instructions.
      **Ticket System:** Submit and track support requests directly within the app.
    `,
    image: "/assets/images/zero-wave/support-assistance.webp"
  }
};

