import React, { useEffect, useState, useRef } from "react";
import AnimatedTitle from "../components/AnimatedTitle";
import { TiArrowDown, TiArrowUp } from "react-icons/ti";
import BackToProductsButton from "../components/BackToProductsButton";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Footer from "../components/Footer";
import LearnMoreButton from "../components/LearnMoreButton";
import ZeroPredictLearnMoreCustomModal from "../products-pages/16-ZeroPredictLearnMoreCustomModal";
import ZeroPredictLogo from "../assets/Zero-Predict.webp";


import { 
  CoreFeatureDetails,
  TechnicalCapabilitiesDetails,
  KeyBenefitsDetails,
  UseCasesDetails,
  SecurityAndComplianceDetails,
  BusinessBenefitsDetails,
  FutureEnhancementsDetails
} from "../data/16-ZeroPredict/16-ZeroPredictDeepFeaturesData";

import {
  CoreFeatures,
  TechnicalCapabilities,
  KeyBenefits,
  UseCases,
  SecurityAndCompliance,
  BusinessBenefits,
  FutureEnhancements
} from "../data/16-ZeroPredict/16-ZeroPredictFeaturesData"; 

gsap.registerPlugin(ScrollTrigger);

const ZeroPredict = () => {
  const logoRef = useRef(null);
  const [scrollDownClicked, setScrollDownClicked] = useState(false);
  const [isBottom, setIsBottom] = useState(false);

  const coreFeaturesRefs = useRef([]);
  const scrollDownRef = useRef(null);
  const scrollTopRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalDescription, setModalDescription] = useState("");
  const [modalImage, setModalImage] = useState("");

  useEffect(() => {
    // Always scroll to the top on component mount
    window.scrollTo({ top: 0, behavior: "smooth" });

    // Animate the logo when the component mounts
    if (logoRef.current) {
      gsap.fromTo(
        logoRef.current,
        { opacity: 0, y: -40 },
        { opacity: 1, y: 0, duration: 1.5, ease: "power3.out" }
      );
    }

    // Animate the "Core Features" items in batches
    const batchSize = 3;
    for (let i = 0; i < coreFeaturesRefs.current.length; i += batchSize) {
      const batch = coreFeaturesRefs.current.slice(i, i + batchSize).filter(Boolean);
      if (batch.length > 0) {
        gsap.fromTo(
          batch,
          { opacity: 0, y: 50 },
          {
            opacity: 1,
            y: 0,
            duration: 1,
            stagger: 0.15,
            delay: (i / batchSize) * 1.5,
            ease: "power3.out",
            scrollTrigger: {
              trigger: batch[0],
              start: "top 90%",
              toggleActions: "play none none none",
            },
          }
        );
      }
    }

    // Animate the "Scroll Down" button
    if (scrollDownRef.current) {
      gsap.fromTo(
        scrollDownRef.current,
        { opacity: 0, y: -20 },
        { opacity: 1, y: 0, duration: 1 }
      );
    }

    // Animate the "Back to Top" button
    if (scrollTopRef.current) {
      gsap.fromTo(
        scrollTopRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          scrollTrigger: {
            trigger: scrollTopRef.current,
            start: "top bottom",
          },
        }
      );
    }
  }, []);

  // Check if the user has scrolled to the bottom
  const checkIfBottom = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 200) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  };

  // Scroll to main content
  const scrollToContent = () => {
    const content = document.getElementById("content");
    if (content) {
      const contentOffsetTop = content.offsetTop;
      window.scrollTo({
        top: contentOffsetTop - 100,
        behavior: "smooth",
      });
    }
    setScrollDownClicked(true);
  };

  // Scroll to the top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setScrollDownClicked(false);
  };

  // Add/remove scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", checkIfBottom);
    return () => {
      window.removeEventListener("scroll", checkIfBottom);
    };
  }, []);

  const openModal = (title) => {
    const featureDetails = 
      CoreFeatureDetails[title] || 
      TechnicalCapabilitiesDetails[title] || 
      KeyBenefitsDetails[title] || 
      UseCasesDetails[title] || 
      SecurityAndComplianceDetails[title] || 
      BusinessBenefitsDetails[title] || 
      FutureEnhancementsDetails[title] || 
      CoreFeatureDetails["Default"];
    
    setModalTitle(featureDetails.title);
    setModalDescription(featureDetails.description);
    setModalImage(featureDetails.image);
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
    setModalTitle('');
    setModalDescription('');
    setModalImage('');
  };

  return (
    <div className="w-screen min-h-screen bg-black text-blue-50">
      {/* Hero Section */}
      <div className="relative w-screen h-screen overflow-hidden">
        <video
          src="/videos/zero-predict-hero.mp4"
          autoPlay
          loop
          muted
          className="absolute left-0 top-0 size-full object-cover"
        />
        {/* LOGO AND TITLE CONTAINER */}
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/60 logo-title-container">
        <div ref={logoRef} className="logo">
          <img
            src={ZeroPredictLogo}
            alt="Zero Predict Logo"
            className="w-40 h-40 object-contain"
          />
        </div>
        <AnimatedTitle
          title="Zero-Predict"
          containerClass="text-center text-blue-50 font-zentry mt-4 mb-40" // Added margin-top to create spacing
        />
        </div>
      </div>

      {/* Scroll Down Button */}
      {!scrollDownClicked && (
        <div
          ref={scrollDownRef}
          className="fixed bottom-10 left-1/2 transform -translate-x-1/2 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
          onClick={scrollToContent}
        >
          <TiArrowDown size={24} className="text-white/40 hover:text-white" />
          <span className="text-white/40 hover:text-white">SCROLL DOWN</span>
        </div>
      )}

      {/* Content Section */}
      <div id="content" className="px-10 py-20 mx-auto max-w-7xl">
        {/* Introduction */}
        <div className="mb-10 text-center">
        <h2 className="text-4xl font-extrabold text-teal-600">AI Fraud Detection</h2>
        <p className="mt-2 text-lg md:text-xl text-gray-300">
            Protecting blockchain transactions with advanced AI-powered fraud detection and prevention tools.
          </p>
        </div>

        {/* Core Features */}
        <section className="px-4 py-8 bg-black">
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">Core Features</h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
            Core Capabilities of Zero-Predict: Advanced Fraud Prevention for Blockchain Transactions.
          </p>
          <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {CoreFeatures.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>
                <div className="mt-auto self-start">
                  <LearnMoreButton
                    productId={index}
                    onClick={() => openModal(item.title)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>

        <br />

        {/* Technical Capabilities */}
        <section className="px-4 py-8 bg-black">
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">Technical Capabilities</h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
            Technical Excellence: Zero-Predict’s Architecture and Scalability Features.
          </p>
          <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {TechnicalCapabilities.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>
                <div className="mt-auto self-start">
                  <LearnMoreButton
                    productId={index}
                    onClick={() => openModal(item.title, item.description)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>


        <br />

        {/* Highlights Section */}
          <section className="mt-20 space-y-16">

          {/* First Highlight */}
          <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row">
            <div className="w-full md:w-1/2">
              <img
                src="/assets/images/zero-predict/real-time-fraud-detection.webp"
                alt="Real-Time Fraud Detection"
                className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
              />
            </div>
            <div className="w-full md:w-1/2">
              <h3 className="text-3xl md:text-4xl font-extrabold text-white">Real-Time Fraud Detection</h3>
              <p className="mt-4">
                Detect fraudulent activities instantly with predictive algorithms, ensuring secure and seamless blockchain transactions.
              </p>
            </div>
          </div>

          {/* Second Highlight */}
          <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row-reverse">
            <div className="w-full md:w-1/2">
              <img
                src="/assets/images/zero-predict/machine-learning-patterns.webp"
                alt="Machine Learning & Pattern Recognition"
                className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
              />
            </div>
            <div className="w-full md:w-1/2">
              <h3 className="text-3xl md:text-4xl font-extrabold text-white">Machine Learning & Pattern Recognition</h3>
              <p className="mt-4">
                Adapt to emerging threats with self-learning ML models that stay ahead of evolving fraud techniques.
              </p>
            </div>
          </div>

          {/* Third Highlight */}
          <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row">
            <div className="w-full md:w-1/2">
              <img
                src="/assets/images/zero-predict/anomaly-detection.webp"
                alt="Advanced Anomaly Detection"
                className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
              />
            </div>
            <div className="w-full md:w-1/2">
              <h3 className="text-3xl md:text-4xl font-extrabold text-white">Advanced Anomaly Detection</h3>
              <p className="mt-4">
                Identify transaction anomalies in real-time, flagging unusual patterns that may indicate potential fraud.
              </p>
            </div>
          </div>

          {/* Fourth Highlight */}
          <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row-reverse">
            <div className="w-full md:w-1/2">
              <img
                src="/assets/images/zero-predict/alerts-notifications.webp"
                alt="Customisable Alerts & Notifications"
                className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
              />
            </div>
            <div className="w-full md:w-1/2">
              <h3 className="text-3xl md:text-4xl font-extrabold text-white">Customisable Alerts & Notifications</h3>
              <p className="mt-4">
                Stay informed with instant alerts for suspicious activities, spikes in transaction volumes, and behavioral anomalies.
              </p>
            </div>
          </div>
        </section>
        <br /><br /><br />   
        
        {/* Key Benefits */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
           Key Benefits
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Transformative Benefits: Enhancing Security and Operational Efficiency with Zero-Predict.
          </p>
          {/* Optional Divider */}
          <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {KeyBenefits.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>
                
                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton
                    productId={index}
                    onClick={() => openModal(item.title, item.description)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>

        <br /><br /><br />   
        
        {/* Use Cases */}
        <section className="px-4 py-8 bg-black">
          <div className="mb-8 text-center">
            <h2 className="text-4xl font-extrabold text-teal-600">
            Use Cases
            </h2>
            <p className="mt-2 text-lg md:text-xl text-gray-300">
            Diverse Applications: Zero-Predict's Versatility Across Blockchain Ecosystems.
            </p>
            <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
            {UseCases.map((item, index) => (
              <div
                key={index}
                className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
              >
                <div
                  className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                  style={{ backgroundImage: `url(${item.image})` }}
                ></div>

                <div className="relative z-10 flex flex-col flex-grow">
                  <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                  <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                  <div className="mt-auto self-start">
                    <LearnMoreButton
                      productId={index}
                      onClick={() => openModal(item.title, item.description)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>        

        {/* Security & Compliance */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
          Security & Compliance
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Robust Security and Regulatory Compliance: Safeguarding Blockchain Ecosystems.
          </p>
        {/* Optional Divider */}
        <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {SecurityAndCompliance.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton 
                  productId={index} 
                  onClick={() => openModal(item.title)} 
                  />              
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>
        <br />        


        <br />

        
        </section>
        <br />
        <br /><br />         

        {/* Fifth Highlight */}
        <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row">
        <div className="w-full md:w-1/2">
          <img
            src="/assets/images/zero-predict/proactive-response.webp"
            alt="Proactive Response Automation"
            className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
          />
        </div>
        <div className="w-full md:w-1/2">
          <h3 className="text-3xl md:text-4xl font-extrabold text-white">Proactive Response Automation</h3>
          <p className="mt-4">
            Automatically block or freeze suspicious accounts during critical events without the need for manual intervention.
          </p>
        </div>
      </div>

      {/* Sixth Highlight */}
      <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row-reverse">
        <div className="w-full md:w-1/2">
          <img
            src="/assets/images/zero-predict/comprehensive-analytics.webp"
            alt="Comprehensive Analytics & Reporting"
            className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
          />
        </div>
        <div className="w-full md:w-1/2">
          <h3 className="text-3xl md:text-4xl font-extrabold text-white">Comprehensive Analytics & Reporting</h3>
          <p className="mt-4">
            Access advanced analytics to track fraud attempts, analyze patterns, and measure the security performance of your platform.
          </p>
        </div>
      </div>

      {/* Seventh Highlight */}
      <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row">
        <div className="w-full md:w-1/2">
          <img
            src="/assets/images/zero-predict/ml-engine.webp"
            alt="Dynamic Machine Learning Engine"
            className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
          />
        </div>
        <div className="w-full md:w-1/2">
          <h3 className="text-3xl md:text-4xl font-extrabold text-white">Dynamic Machine Learning Engine</h3>
          <p className="mt-4">
            Benefit from a continuously learning ML engine that evolves with incoming blockchain data to enhance fraud detection capabilities.
          </p>
        </div>
      </div>

      {/* Eighth Highlight */}
      <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row-reverse">
        <div className="w-full md:w-1/2">
          <img
            src="/assets/images/zero-predict/blockchain-support.webp"
            alt="Multi-Chain Blockchain Support"
            className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
          />
        </div>
        <div className="w-full md:w-1/2">
          <h3 className="text-3xl md:text-4xl font-extrabold text-white">Multi-Chain Blockchain Support</h3>
          <p className="mt-4">
            Seamlessly integrate with Ethereum, Solana, Polygon, and other major blockchain protocols for comprehensive fraud prevention.
          </p>
        </div>
      </div>
            
      <br /><br /><br />                

      {/* Business Benefits */}
      <section className="px-4 py-8 bg-black">
      {/* Title Container */}
      <div className="mb-8 text-center">
        <h2 className="text-4xl font-extrabold text-teal-600">
        Business Benefits
        </h2>
        <p className="mt-2 text-lg md:text-xl text-gray-300">
        Driving Business Growth with Advanced Fraud Prevention and Compliance.
        </p>
      {/* Optional Divider */}
      <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
      </div>

      {/* Features Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
        {BusinessBenefits.map((item, index) => (
          <div
            key={index}
            className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
          >
            {/* Background Image */}
            <div
              className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
              style={{ backgroundImage: `url(${item.image})` }}
            ></div>

            {/* Content Container */}
            <div className="relative z-10 flex flex-col flex-grow">
              <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

              {/* Description with consistent space below */}
              <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

              {/* Learn More Button Positioned at Bottom Left */}
              <div className="mt-auto self-start">
                <LearnMoreButton 
                productId={index} 
                onClick={() => openModal(item.title)} 
                />              
              </div>
            </div>
          </div>
        ))}
      </div>
      </section>
      <br /> 

      {/* Future Enhancements */}
      <section className="px-4 py-8 bg-black">
      {/* Title Container */}
      <div className="mb-8 text-center">
        <h2 className="text-4xl font-extrabold text-teal-600">
        Future Enhancements
        </h2>
        <p className="mt-2 text-lg md:text-xl text-gray-300">
        Pioneering the Future of Blockchain Security and Fraud Detection.
        </p>
      {/* Optional Divider */}
      <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
      </div>

      {/* Features Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
        {FutureEnhancements.map((item, index) => (
          <div
            key={index}
            className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
          >
            {/* Background Image */}
            <div
              className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
              style={{ backgroundImage: `url(${item.image})` }}
            ></div>

            {/* Content Container */}
            <div className="relative z-10 flex flex-col flex-grow">
              <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

              {/* Description with consistent space below */}
              <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

              {/* Learn More Button Positioned at Bottom Left */}
              <div className="mt-auto self-start">
                <LearnMoreButton 
                productId={index} 
                onClick={() => openModal(item.title)} 
                />              
              </div>
            </div>
          </div>
        ))}
      </div>
      </section>
      <br /> 
        
        {/* Back to Products Button */}
        <div className="flex justify-center mt-10">
          <BackToProductsButton />
        </div>
      </div>

      {/* Back to Top Button */}
      {isBottom && (
        <div
          ref={scrollTopRef}
          className="fixed bottom-10 left-5 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
          onClick={scrollToTop}
        >
          <TiArrowUp
            size={24}
            className="text-white/40 hover:text-white transition-transform duration-300"
          />
          <span className="text-white/40 hover:text-white">BACK TO TOP</span>
        </div>
      )}

      {/* Footer */}
      <Footer />

      <ZeroPredictLearnMoreCustomModal 
      isOpen={isModalOpen} 
      onClose={closeModal} 
      title={modalTitle} 
      description={modalDescription} 
      image={modalImage} 
    />
  </div>
);
};

export default ZeroPredict;
