export const CoreFeatureDetails = {
  "Decentralised Peer-to-Peer Lending": {
    title: "Decentralised Peer-to-Peer Lending",
    description: `
      Connect borrowers directly with lenders, eliminating intermediaries and enabling flexible loan terms, interest rates, and repayment schedules.
      
      **Key Features:**
      **Direct Lending:** Borrowers and lenders connect directly via the platform.
      **No Intermediaries:** Avoid traditional banks for faster and cheaper access to loans.
      **Customisable Loan Terms:** Borrowers and lenders negotiate loan terms that suit both parties.
    `,
    image: "/assets/images/zero-lend/peer-to-peer-lending.webp"
  },

  "Smart Contract Automation": {
    title: "Smart Contract Automation",
    description: `
      Use blockchain smart contracts to automate loan agreements, disbursements, and repayments, reducing the risk of errors and delays.
      
      **Key Features:**
      **Self-Executing Contracts:** Contracts automatically enforce terms, repayments, and penalties.
      **Full Transparency:** Viewable on the blockchain for complete transparency.
      **Error Reduction:** Automation eliminates human error from payment processing.
    `,
    image: "/assets/images/zero-lend/smart-contract-automation.webp"
  },

  "AI-Driven Credit Scoring": {
    title: "AI-Driven Credit Scoring",
    description: `
      Leverage AI models to assess borrower creditworthiness, offering credit access to underserved communities using alternative data.
      
      **Key Features:**
      **Alternative Data Analysis:** Credit scoring goes beyond traditional credit reports.
      **Increased Fairness:** Provides access to loans for underserved borrowers.
      **Machine Learning Models:** AI models learn from borrower behaviour to improve credit assessments.
    `,
    image: "/assets/images/zero-lend/ai-credit-scoring.webp"
  },

  "Tokenisation of Loan Assets": {
    title: "Tokenisation of Loan Assets",
    description: `
      Tokenise loans as tradable assets, enabling lenders to trade their loan positions on secondary markets, improving liquidity.
      
      **Key Features:**
      **Loan-Backed Tokens (LBTs):** Loans are converted into tradable tokens.
      **Liquidity Creation:** Lenders can trade loan-backed tokens on secondary markets.
      **Portfolio Diversification:** Lenders manage risk by selling portions of their loan portfolios.
    `,
    image: "/assets/images/zero-lend/tokenised-loans.webp"
  },

  "Global Reach & Borderless Lending": {
    title: "Global Reach & Borderless Lending",
    description: `
      Empower global lending and borrowing with cross-border payments and multi-currency support, enabling financial inclusion.
      
      **Key Features:**
      **Cross-Border Lending:** Borrowers and lenders from different countries can transact seamlessly.
      **Multi-Currency Support:** Support for stablecoins, cryptocurrencies, and fiat payments.
      **Global Financial Inclusion:** Enables underserved communities to access funds from global lenders.
    `,
    image: "/assets/images/zero-lend/global-reach-lending.webp"
  },

  "Data Security & Privacy": {
    title: "Data Security & Privacy",
    description: `
      Protect user data with advanced encryption and Zero-Knowledge Proofs (ZKPs), ensuring borrower privacy throughout the lending process.
      
      **Key Features:**
      **Zero-Knowledge Proofs (ZKPs):** Borrower data remains private while verifying eligibility.
      **AES-256 Encryption:** Ensures end-to-end encryption of all loan and payment data.
      **Privacy-First Design:** Users maintain control of their personal financial information.
    `,
    image: "/assets/images/zero-lend/data-security-privacy.webp"
  }
};

export const TechnicalCapabilitiesDetails = {
  "Blockchain-Based Core": {
    title: "Blockchain-Based Core",
    description: `
      All lending activities, repayments, and smart contract operations are recorded on an immutable blockchain ledger, ensuring transparency, auditability, and security.
      
      **Key Features:**
      **Immutable Ledger:** Every transaction is recorded on a tamper-proof blockchain ledger.
      **Full Auditability:** All loan agreements and repayments are traceable for regulatory and audit purposes.
      **Smart Contract Security:** Smart contracts ensure that all loan terms are enforced automatically and securely.
    `,
    image: "/assets/images/zero-lend/blockchain-core.webp"
  },

  "Microservices Architecture": {
    title: "Microservices Architecture",
    description: `
      Modular microservices architecture enables Zero-Lend to operate each core service independently, facilitating agile development, faster updates, and high system availability.
      
      **Key Features:**
      **Independent Services:** Services like payments, credit scoring, and user authentication operate independently.
      **Scalability:** Add, update, or scale individual services without disrupting the entire system.
      **Continuous Integration:** Fast deployment cycles allow for constant system improvement.
    `,
    image: "/assets/images/zero-lend/microservices-architecture.webp"
  },

  "Decentralised Data Storage": {
    title: "Decentralised Data Storage",
    description: `
      Store user and transaction data across multiple nodes on a decentralised network to prevent single points of failure, increase redundancy, and enhance security.
      
      **Key Features:**
      **Decentralised Data Network:** Data is spread across multiple blockchain nodes.
      **Data Breach Protection:** Redundancy eliminates reliance on a central server, reducing breach risk.
      **Fault Tolerance:** If one node fails, other nodes maintain access to data.
    `,
    image: "/assets/images/zero-lend/decentralised-storage.webp"
  },

  "AI-Driven Credit Assessment": {
    title: "AI-Driven Credit Assessment",
    description: `
      AI models analyse borrower data and alternative data sources to deliver dynamic credit scoring, improving access to credit for underserved users.
      
      **Key Features:**
      **Alternative Data Sources:** Uses social data, payment history, and more for better credit insights.
      **Real-Time Credit Scoring:** Assess borrower credit risk dynamically with each application.
      **AI-Powered Insights:** Machine learning models evolve to provide more accurate predictions over time.
    `,
    image: "/assets/images/zero-lend/ai-credit-assessment.webp"
  },

  "Encryption & Privacy": {
    title: "Encryption & Privacy",
    description: `
      Protect all user data using advanced encryption techniques and privacy-preserving methods like Zero-Knowledge Proofs (ZKPs), ensuring data confidentiality at all times.
      
      **Key Features:**
      **AES-256 Encryption:** Encrypts user data at rest and in transit for complete protection.
      **Zero-Knowledge Proofs (ZKPs):** Verify borrower eligibility without exposing sensitive data.
      **Privacy-First Approach:** Personal and financial information is fully secured and not shared without permission.
    `,
    image: "/assets/images/zero-lend/data-encryption.webp"
  },

  "Global Payment Gateway": {
    title: "Global Payment Gateway",
    description: `
      Zero-Lend integrates with global payment gateways to enable cross-border payments in multiple cryptocurrencies and stablecoins, supporting international lending.
      
      **Key Features:**
      **Multi-Currency Payments:** Support for stablecoins and cryptocurrencies for borderless payments.
      **Seamless Integration:** Works with major crypto payment gateways to facilitate payments.
      **Global Remittances:** Facilitate borderless lending and borrowing with real-time payment settlement.
    `,
    image: "/assets/images/zero-lend/global-payment-gateway.webp"
  }
};

export const KeyBenefitsDetails = {
  "Financial Inclusion": {
    title: "Financial Inclusion",
    description: `
      Empower underserved communities with access to loans by leveraging alternative credit scoring and bypassing reliance on traditional credit checks.
      
      **Key Benefits:**
      **Access for the Underserved:** Include borrowers without formal credit histories.
      **Alternative Credit Scoring:** Leverage non-traditional data to assess creditworthiness.
      **Global Access:** Provide lending opportunities for communities worldwide.
    `,
    image: "/assets/images/zero-lend/financial-inclusion.webp"
  },

  "Tokenised Loan Portfolios": {
    title: "Tokenised Loan Portfolios",
    description: `
      Tokenise loans as digital assets that can be traded or transferred on secondary markets, providing liquidity and new investment opportunities for lenders.
      
      **Key Benefits:**
      **Increased Liquidity:** Lenders can sell or transfer loan positions on the market.
      **Flexible Investment:** Lenders can diversify risk by trading fractional loan ownership.
      **Tradable Assets:** Loans become tokenised digital assets, enabling new investment classes.
    `,
    image: "/assets/images/zero-lend/tokenised-portfolios.webp"
  },

  "Decentralised Lending": {
    title: "Decentralised Lending",
    description: `
      Connect borrowers directly with lenders, removing intermediaries to create a transparent and cost-effective lending experience with greater control over loan terms.
      
      **Key Benefits:**
      **Direct Borrower-Lender Interaction:** Eliminate costly intermediaries.
      **Lower Borrowing Costs:** Save on bank fees and reduce interest rates.
      **Customisable Loan Terms:** Borrowers and lenders negotiate terms directly.
    `,
    image: "/assets/images/zero-lend/decentralised-lending.webp"
  },

  "Reduced Costs & Fees": {
    title: "Reduced Costs & Fees",
    description: `
      Eliminate the need for banks and financial intermediaries, reducing the overall cost of borrowing while increasing returns for lenders.
      
      **Key Benefits:**
      **No Intermediary Fees:** Direct lending reduces costs for both borrowers and lenders.
      **Increased Lender Returns:** Lenders receive higher returns due to reduced platform fees.
      **Cost-Effective Borrowing:** Borrowers experience reduced fees and interest rates.
    `,
    image: "/assets/images/zero-lend/reduced-costs-fees.webp"
  },

  "Automated Smart Contract Payments": {
    title: "Automated Smart Contract Payments",
    description: `
      Smart contracts automate loan repayments and collections, ensuring that payments are processed on time without manual intervention, reducing operational overhead.
      
      **Key Benefits:**
      **Automated Payments:** Smart contracts automatically trigger payments and collections.
      **Reduced Administrative Overhead:** No need for manual repayment tracking.
      **Transparency & Trust:** Lenders and borrowers trust smart contracts to manage loans impartially.
    `,
    image: "/assets/images/zero-lend/automated-payments.webp"
  },

  "Improved Risk Management": {
    title: "Improved Risk Management",
    description: `
      Use AI-powered credit analysis to predict borrower risk, enabling lenders to make proactive decisions to reduce defaults and improve portfolio health.
      
      **Key Benefits:**
      **AI-Driven Risk Analysis:** Predict borrower defaults using advanced AI models.
      **Proactive Risk Intervention:** Early detection of risk enables lenders to take preventive measures.
      **Enhanced Portfolio Health:** Minimise loan defaults and improve lender returns.
    `,
    image: "/assets/images/zero-lend/risk-management.webp"
  }
};

export const UseCasesDetails = {
  "Personal Loans": {
    title: "Personal Loans",
    description: `
      Borrowers can access personal loans to cover emergency expenses, medical bills, or other financing needs via the Zero-Lend mobile app, offering fast approvals and flexible repayment options.
      
      **Key Use Cases:**
      **Emergency Funding:** Access cash quickly for unforeseen emergencies.
      **Medical Expenses:** Cover urgent healthcare costs with flexible repayment plans.
      **General Financing Needs:** Borrow for essential personal needs without delays.
    `,
    image: "/assets/images/zero-lend/personal-loans.webp"
  },

  "Business Loans": {
    title: "Business Loans",
    description: `
      Empower SMEs with access to working capital loans from a global pool of lenders, enabling growth and stability for small and medium-sized enterprises (SMEs) without relying on traditional financial institutions.
      
      **Key Use Cases:**
      **Working Capital Loans:** Get liquidity for daily operational needs.
      **Business Expansion:** Access funds to expand and grow business capacity.
      **SME Financing:** Provide loans for SMEs without the need for bank guarantees.
    `,
    image: "/assets/images/zero-lend/business-loans.webp"
  },

  "Microloans in Emerging Markets": {
    title: "Microloans in Emerging Markets",
    description: `
      Provide access to microloans for underserved communities in emerging markets, supporting essential needs like farming, education, and small-scale businesses.
      
      **Key Use Cases:**
      **Small Business Loans:** Empower micro-entrepreneurs with small loans to grow their businesses.
      **Essential Purchases:** Enable users to access funds for personal development or essential products.
      **Economic Development:** Drive financial inclusion in developing nations.
    `,
    image: "/assets/images/zero-lend/microloans.webp"
  },

  "Education Loans": {
    title: "Education Loans",
    description: `
      Students can access tuition loans through Zero-Lend’s platform, with repayments automatically managed via blockchain-based smart contracts to ensure transparency and trust.
      
      **Key Use Cases:**
      **Tuition Loans:** Access funds to pay for university or professional education fees.
      **Skill Development Loans:** Fund short courses or certification programs.
      **Student Support:** Assist students with living expenses and educational materials.
    `,
    image: "/assets/images/zero-lend/education-loans.webp"
  },

  "Investor Lending & Income": {
    title: "Investor Lending & Income",
    description: `
      Retail investors can lend funds to borrowers and earn passive income through interest payments, creating an alternative investment strategy to traditional asset classes.
      
      **Key Use Cases:**
      **Passive Income:** Investors earn interest on loans as a source of passive revenue.
      **Portfolio Diversification:** Diversify portfolios with loan-based investments.
      **Retail Lending:** Democratise lending by allowing everyday investors to become lenders.
    `,
    image: "/assets/images/zero-lend/investor-lending.webp"
  },

  "Cross-Border Lending": {
    title: "Cross-Border Lending",
    description: `
      Borrowers and lenders from different countries can engage in loans, facilitated by stablecoins and cryptocurrency payments, offering access to international funding opportunities.
      
      **Key Use Cases:**
      **International Lending:** Borrow or lend globally using blockchain and stablecoins.
      **Multi-Currency Payments:** Use stablecoins or cryptocurrencies for payments.
      **Access to Global Capital:** Enable borrowers to access funding from lenders worldwide.
    `,
    image: "/assets/images/zero-lend/cross-border-lending.webp"
  }
};

export const SecurityAndComplianceDetails = {
  "KYC/AML Compliance": {
    title: "KYC/AML Compliance",
    description: `
      Zero-Lend complies with Know Your Customer (KYC) and Anti-Money Laundering (AML) regulations, ensuring all users and transactions adhere to the latest global financial standards.
      
      **Key Features:**
      **Identity Verification:** Ensure borrowers and lenders are verified before accessing the platform.
      **Fraud Detection:** Identify and block suspicious activity linked to money laundering.
      **Regulatory Compliance:** Fully comply with KYC/AML guidelines to prevent illegal financial activities.
    `,
    image: "/assets/images/zero-lend/kyc-aml-compliance.webp"
  },

  "Audit-Ready Smart Contracts": {
    title: "Audit-Ready Smart Contracts",
    description: `
      Every smart contract deployed on Zero-Lend undergoes rigorous third-party security audits to prevent vulnerabilities, exploits, or security flaws.
      
      **Key Features:**
      **Third-Party Audits:** Independent audits ensure contract security and eliminate loopholes.
      **Secure Contract Execution:** Only verified and audited smart contracts can execute loans and payments.
      **Compliance with Best Practices:** Uses industry-standard coding guidelines to secure contract logic.
    `,
    image: "/assets/images/zero-lend/audit-ready-smart-contracts.webp"
  },

  "Biometric Login": {
    title: "Biometric Login",
    description: `
      Users can log into Zero-Lend securely using biometric authentication methods such as FaceID, TouchID, or fingerprint recognition.
      
      **Key Features:**
      **Biometric Security:** Users access the platform with their fingerprint, facial recognition, or retina scan.
      **Faster Logins:** Eliminates the need for passwords and PINs for quick access.
      **Multi-Layered Security:** Combines biometric authentication with encryption for higher security.
    `,
    image: "/assets/images/zero-lend/biometric-login.webp"
  },

  "Data Encryption (AES-256)": {
    title: "Data Encryption (AES-256)",
    description: `
      Zero-Lend protects all sensitive user information by encrypting it both at rest and in transit using the advanced AES-256 encryption standard.
      
      **Key Features:**
      **Encryption at Rest & In Transit:** Data is encrypted when stored and when transmitted.
      **Data Security:** Prevents unauthorised access, protecting user data from breaches.
      **Regulatory Compliance:** Meets encryption standards required by major financial regulators.
    `,
    image: "/assets/images/zero-lend/aes-256-encryption.webp"
  },

  "Zero-Knowledge Proofs (ZKPs)": {
    title: "Zero-Knowledge Proofs (ZKPs)",
    description: `
      Verify user credentials and validate user activity without revealing any sensitive data using Zero-Knowledge Proofs (ZKPs).
      
      **Key Features:**
      **Privacy Protection:** Verify information without disclosing sensitive details.
      **User Anonymity:** User information remains confidential during identity verification.
      **Regulatory Compliance:** Ensures legal compliance while safeguarding user privacy.
    `,
    image: "/assets/images/zero-lend/zkp-compliance.webp"
  },

  "Regulatory Compliance": {
    title: "Regulatory Compliance",
    description: `
      Zero-Lend operates in compliance with global lending laws and financial regulations, ensuring cross-border legitimacy and trustworthiness.
      
      **Key Features:**
      **Global Jurisdiction Compliance:** Operates legally in multiple regions and complies with local lending laws.
      **Regulatory Updates:** Adapts to new financial regulations as they are introduced.
      **Cross-Border Lending:** Provides seamless cross-border lending while adhering to multi-jurisdictional laws.
    `,
    image: "/assets/images/zero-lend/regulatory-compliance.webp"
  }
};


export const BusinessBenefitsDetails = {
  "Increased Lending Opportunities": {
    title: "Increased Lending Opportunities",
    description: `
      Expand access to lending beyond traditional markets, allowing lenders to connect with borrowers globally for greater market opportunities.
      
      **Key Features:**
      **Global Market Reach:** Access borrowers from multiple countries, removing geographical barriers.
      **Diverse Borrower Pool:** Tap into underserved markets and attract new borrowers worldwide.
      **Increased Loan Volume:** Drive higher loan issuance as lenders access more borrowers from diverse regions.
    `,
    image: "/assets/images/zero-lend/increased-lending-opportunities.webp"
  },

  "Faster Loan Processing": {
    title: "Faster Loan Processing",
    description: `
      Accelerate the loan approval process with automated smart contracts that reduce manual work and ensure faster disbursement.
      
      **Key Features:**
      **Automated Loan Approvals:** Smart contracts review, verify, and approve loans automatically.
      **Instant Disbursement:** Loan funds are disbursed instantly after approval, enhancing borrower experience.
      **Streamlined Repayment Process:** Automated repayments reduce manual tracking and intervention.
    `,
    image: "/assets/images/zero-lend/faster-loan-processing.webp"
  },

  "Lower Operational Costs": {
    title: "Lower Operational Costs",
    description: `
      Reduce operational expenses by automating loan processes and removing the need for intermediaries and costly third-party services.
      
      **Key Features:**
      **Automation of Manual Tasks:** Smart contracts automate loan origination, processing, and repayments.
      **Eliminate Intermediaries:** No need for brokers, agents, or banks to facilitate loans.
      **Cost Reduction for Lenders & Borrowers:** Lower fees for borrowers while increasing profitability for lenders.
    `,
    image: "/assets/images/zero-lend/lower-operational-costs.webp"
  },

  "Customisable Loan Terms": {
    title: "Customisable Loan Terms",
    description: `
      Allow borrowers and lenders to negotiate and agree on loan terms, interest rates, and repayment schedules, providing full control over loan conditions.
      
      **Key Features:**
      **Personalised Loan Conditions:** Borrowers and lenders can negotiate interest rates, repayment schedules, and collateral terms.
      **Flexible Repayment Schedules:** Adjust repayment plans to suit the needs of borrowers and lenders.
      **Customised Loan Products:** Design loans for specific use cases, like personal loans, business loans, and microloans.
    `,
    image: "/assets/images/zero-lend/customisable-loan-terms.webp"
  },

  "Passive Income for Lenders": {
    title: "Passive Income for Lenders",
    description: `
      Enable lenders to earn passive income by providing liquidity to borrowers, earning interest on repayments and building wealth over time.
      
      **Key Features:**
      **Interest Revenue:** Lenders receive a consistent return on investment through loan interest payments.
      **Loan Portfolio Diversification:** Spread risk across multiple borrowers and regions.
      **Automated Payment Collection:** Smart contracts ensure lenders receive payments without manual intervention.
    `,
    image: "/assets/images/zero-lend/passive-income-for-lenders.webp"
  },

  "Access to Unbanked Communities": {
    title: "Access to Unbanked Communities",
    description: `
      Provide financial access to underserved communities by enabling loans for individuals who lack access to traditional banking services.
      
      **Key Features:**
      **Support for Underserved Markets:** Facilitate loans for users without formal credit histories.
      **Financial Inclusion:** Bring financial services to regions where banking infrastructure is limited.
      **Global Reach:** Enable cross-border lending to unbanked communities in emerging markets.
    `,
    image: "/assets/images/zero-lend/access-to-unbanked-communities.webp"
  }
};

export const FutureEnhancementsDetails = {
  "AI-Powered Borrower Matching": {
    title: "AI-Powered Borrower Matching",
    description: `
      Leverage AI to connect borrowers with the most suitable lenders, ensuring personalised loan offers and higher approval rates.
      
      **Key Features:**
      **Personalised Loan Offers:** Customised loan offers based on borrower profiles and lender preferences.
      **Faster Loan Matching:** Use machine learning to quickly match borrowers with the ideal lender for their needs.
      **Enhanced Borrower-Lender Relationships:** Foster stronger relationships by providing optimal borrower-lender matches.
    `,
    image: "/assets/images/zero-lend/ai-powered-matching.webp"
  },

  "NFT-Backed Loans": {
    title: "NFT-Backed Loans",
    description: `
      Use non-fungible tokens (NFTs) as collateral for loans, enabling users to unlock liquidity for their digital art and collectibles.
      
      **Key Features:**
      **NFT Collateralisation:** Secure loans using NFT assets as collateral.
      **Liquidity for Digital Assets:** Allow NFT holders to access liquidity without selling their assets.
      **Tokenised Asset Valuation:** Use real-time market data to determine the value of NFT collateral.
    `,
    image: "/assets/images/zero-lend/nft-backed-loans.webp"
  },

  "Multi-Currency Support": {
    title: "Multi-Currency Support",
    description: `
      Enable borrowers and lenders to transact in multiple fiat and crypto currencies, enhancing flexibility and global accessibility.
      
      **Key Features:**
      **Multi-Currency Loan Processing:** Process loans in popular fiat and digital currencies like USD, EUR, BTC, and ETH.
      **Cross-Border Lending:** Facilitate global lending with currency-agnostic loan issuance.
      **Reduced Currency Exchange Risks:** Minimise exchange risks with currency selection and conversion options.
    `,
    image: "/assets/images/zero-lend/multi-currency-support.webp"
  },

  "Advanced Risk Scoring Models": {
    title: "Advanced Risk Scoring Models",
    description: `
      Introduce machine learning-driven models to assess borrower risk with higher accuracy, ensuring better lending decisions.
      
      **Key Features:**
      **AI-Powered Risk Assessment:** Use predictive analytics to assess borrower risk in real time.
      **Customisable Risk Models:** Allow lenders to set risk tolerance levels and use tailored risk models.
      **Default Risk Reduction:** Reduce the risk of defaults by predicting borrower behaviour more accurately.
    `,
    image: "/assets/images/zero-lend/advanced-risk-scoring.webp"
  },

  "DeFi Integration": {
    title: "DeFi Integration",
    description: `
      Connect Zero-Lend to DeFi protocols, providing users access to decentralised lending pools, staking, and liquidity services.
      
      **Key Features:**
      **On-Chain Lending Pools:** Offer access to liquidity pools where borrowers and lenders interact directly on-chain.
      **Staking & Yield Generation:** Allow users to stake funds in DeFi protocols and earn interest.
      **Cross-Protocol Compatibility:** Enable seamless interaction with leading DeFi protocols, such as Aave, Compound, and MakerDAO.
    `,
    image: "/assets/images/zero-lend/defi-integration.webp"
  },

  "Mobile App for Borrowers & Lenders": {
    title: "Mobile App for Borrowers & Lenders",
    description: `
      Launch a dedicated mobile app to give users the ability to track loans, manage payments, and stay updated on loan status from their smartphones.
      
      **Key Features:**
      **Real-Time Loan Tracking:** View the status of active loans, payments, and due dates.
      **Easy Borrower-Lender Communication:** In-app messaging allows borrowers and lenders to communicate directly.
      **Full Loan Management:** Borrowers and lenders can manage loan agreements, modify terms, and track repayments via the app.
    `,
    image: "/assets/images/zero-lend/mobile-app.webp"
  }
};
