import React, { useEffect, useState, useRef } from "react";
import AnimatedTitle from "../components/AnimatedTitle";
import { TiArrowDown, TiArrowUp } from "react-icons/ti";
import BackToProductsButton from "../components/BackToProductsButton";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Footer from "../components/Footer";
import LearnMoreButton from "../components/LearnMoreButton";
import ZeroPayLearnMoreCustomModal from "../products-pages/6-ZeroPayLearnMoreCustomModal";
import ZeroPayLogo from "../assets/Zero-Pay.webp";

import { 
  CoreFeatureDetails,
  PaymentCapabilitiesDetails,
  TechnicalArchitectureDetails,
  UseCasesDetails,  
  SecurityAndComplianceDetails,
  BusinessBenefitsDetails,  
  FutureEnhancementsDetails
} from "../data/6-ZeroPay/6-ZeroPayDeepFeaturesData";

import {
  CoreFeatures,
  PaymentCapabilities,
  TechnicalArchitecture,
  UseCases,  
  SecurityAndCompliance,
  BusinessBenefits,  
  FutureEnhancements
} from "../data/6-ZeroPay/6-ZeroPayFeaturesData"; 

gsap.registerPlugin(ScrollTrigger);

const ZeroPay = () => {
  const logoRef = useRef(null);
  const [scrollDownClicked, setScrollDownClicked] = useState(false);
  const [isBottom, setIsBottom] = useState(false);

  const coreFeaturesRefs = useRef([]);
  const scrollDownRef = useRef(null);
  const scrollTopRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalDescription, setModalDescription] = useState("");
  const [modalImage, setModalImage] = useState("");

  useEffect(() => {
    // Always scroll to the top on component mount
    window.scrollTo({ top: 0, behavior: "smooth" });

    // Animate the logo when the component mounts
    if (logoRef.current) {
      gsap.fromTo(
        logoRef.current,
        { opacity: 0, y: -40 },
        { opacity: 1, y: 0, duration: 1.5, ease: "power3.out" }
      );
    }

    // Animate the "Core Features" items in batches
    const batchSize = 3;
    for (let i = 0; i < coreFeaturesRefs.current.length; i += batchSize) {
      const batch = coreFeaturesRefs.current.slice(i, i + batchSize).filter(Boolean);
      if (batch.length > 0) {
        gsap.fromTo(
          batch,
          { opacity: 0, y: 50 },
          {
            opacity: 1,
            y: 0,
            duration: 1,
            stagger: 0.15,
            delay: (i / batchSize) * 1.5,
            ease: "power3.out",
            scrollTrigger: {
              trigger: batch[0],
              start: "top 90%",
              toggleActions: "play none none none",
            },
          }
        );
      }
    }

    // Animate the "Scroll Down" button
    if (scrollDownRef.current) {
      gsap.fromTo(
        scrollDownRef.current,
        { opacity: 0, y: -20 },
        { opacity: 1, y: 0, duration: 1 }
      );
    }

    // Animate the "Back to Top" button
    if (scrollTopRef.current) {
      gsap.fromTo(
        scrollTopRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          scrollTrigger: {
            trigger: scrollTopRef.current,
            start: "top bottom",
          },
        }
      );
    }
  }, []);

  // Check if the user has scrolled to the bottom
  const checkIfBottom = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 200) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  };

  // Scroll to main content
  const scrollToContent = () => {
    const content = document.getElementById("content");
    if (content) {
      const contentOffsetTop = content.offsetTop;
      window.scrollTo({
        top: contentOffsetTop - 100,
        behavior: "smooth",
      });
    }
    setScrollDownClicked(true);
  };

  // Scroll to the top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setScrollDownClicked(false);
  };

  // Add/remove scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", checkIfBottom);
    return () => {
      window.removeEventListener("scroll", checkIfBottom);
    };
  }, []);

  const openModal = (title) => {
    const featureDetails = 
      CoreFeatureDetails[title] || 
      PaymentCapabilitiesDetails[title] || 
      TechnicalArchitectureDetails[title] ||
      UseCasesDetails[title] || 
      SecurityAndComplianceDetails[title] || 
      BusinessBenefitsDetails[title] || 
      FutureEnhancementsDetails[title] || 
      CoreFeatureDetails["Default"];
    
    setModalTitle(featureDetails.title);
    setModalDescription(featureDetails.description);
    setModalImage(featureDetails.image);
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
    setModalTitle('');
    setModalDescription('');
    setModalImage('');
  };


  return (
    <div className="w-screen min-h-screen bg-black text-blue-50">
      {/* Hero Section */}
      <div className="relative w-screen h-screen overflow-hidden">
        <video
          src="/videos/zero-pay-hero.mp4"
          autoPlay
          loop
          muted
          className="absolute left-0 top-0 size-full object-cover"
        />
        {/* LOGO AND TITLE CONTAINER */}
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/60 logo-title-container">
        <div ref={logoRef} className="logo">
          <img
            src={ZeroPayLogo}
            alt="Zero Pay Logo"
            className="w-40 h-40 object-contain"
          />
        </div>
        <AnimatedTitle
          title="Zero-Pay"
          containerClass="text-center text-blue-50 font-zentry mt-4 mb-40" // Added margin-top to create spacing
        />
        </div>
      </div>

      {/* Scroll Down Button */}
      {!scrollDownClicked && (
        <div
          ref={scrollDownRef}
          className="fixed bottom-10 left-1/2 transform -translate-x-1/2 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
          onClick={scrollToContent}
        >
          <TiArrowDown size={24} className="text-white/40 hover:text-white" />
          <span className="text-white/40 hover:text-white">SCROLL DOWN</span>
        </div>
      )}

      {/* Content Section */}
      <div id="content" className="px-10 py-20 mx-auto max-w-7xl">
        {/* Introduction */}
        <div className="mb-10 text-center">
        <h2 className="text-4xl font-extrabold text-teal-600">Global Payment Redefined</h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
            Empower your business with Zero-Pay&apos;s cross-border payment solutions.
          </p>
        </div>

        {/* Core Features */}
        <section className="px-4 py-8 bg-black">
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">Core Features</h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
            Explore the essential functionalities that make Zero-Pay a leader in global payment processing, multi-currency support, and smart payment routing.
          </p>
          <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {CoreFeatures.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>
                <div className="mt-auto self-start">
                  <LearnMoreButton
                    productId={index}
                    onClick={() => openModal(item.title)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>

        <br />

        {/* Payment Capabilities */}
        <section className="px-4 py-8 bg-black">
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">Payment Capabilities</h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
            Seamless Transactions, Advanced Payment Options, and Total Control Over Your Financial Operations.
          </p>
          <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {PaymentCapabilities.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>
                <div className="mt-auto self-start">
                  <LearnMoreButton
                    productId={index}
                    onClick={() => openModal(item.title, item.description)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>


        <br />

        {/* Highlights Section */}
        <section className="mt-20 space-y-16">

        {/* First Highlight */}
        <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row">
        <div className="w-full md:w-1/2">
          <img
            src="/assets/images/zero-pay/workflow-automation.webp"
            alt="Compliance Workflow Automation"
            className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
          />
        </div>
        <div className="w-full md:w-1/2">
          <h3 className="text-3xl md:text-4xl font-extrabold text-white">Compliance Workflow Automation</h3>
          <p className="mt-4">
            Automate compliance workflows with <strong>Zero-Pay’s AI-powered tools</strong>, enabling dynamic task triggers based on <strong>regulatory updates</strong>. Minimise manual intervention and ensure real-time adaptability to policy changes, saving time and resources.
          </p>
        </div>
        </div>

        {/* Second Highlight */}
        <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row-reverse">
        <div className="w-full md:w-1/2">
          <img
            src="/assets/images/zero-pay/predictive-analytics.webp"
            alt="Predictive Analytics"
            className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
          />
        </div>
        <div className="w-full md:w-1/2">
          <h3 className="text-3xl md:text-4xl font-extrabold text-white">Predictive Analytics</h3>
          <p className="mt-4">
            Harness <strong>Zero-Pay’s advanced AI-driven analytics</strong> to anticipate compliance risks and emerging regulatory challenges. Stay ahead of potential breaches by identifying trends and anomalies <strong>before they escalate</strong>, ensuring proactive risk management.
          </p>
        </div>
        </div>

        <br /><br /><br />   
        
        {/* Technical Capabilities */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
            Technical Capabilities
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Explore the advanced technical infrastructure powering Zero-Pay&apos;s payment processing, multi-currency support, and intelligent payment routing. Built for speed, security, and seamless integration.
          </p>
          {/* Optional Divider */}
          <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {TechnicalArchitecture.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>
                
                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton
                    productId={index}
                    onClick={() => openModal(item.title, item.description)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>

        {/* UseCases */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
            Use Cases
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Effortless payment processing, seamless integrations, and a user-first design deliver a smooth and efficient experience with Zero-Pay.
          </p>
        {/* Optional Divider */}
        <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {UseCases.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton 
                  productId={index} 
                  onClick={() => openModal(item.title)} 
                  />              
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Security & Compliance */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
            Security & Compliance
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Safeguard your payments and financial data with advanced security protocols and full regulatory compliance, guaranteeing every transaction is protected and secure.
          </p>
        {/* Optional Divider */}
        <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {SecurityAndCompliance.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton 
                  productId={index} 
                  onClick={() => openModal(item.title)} 
                  />              
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>
        <br />        


        <br />

        
        </section>
        <br />
        <br /><br />

        {/* Third Highlight */}
        <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row">
        <div className="w-full md:w-1/2">
          <img
            src="/assets/images/zero-pay/real-time-monitoring.webp"
            alt="Real-Time Monitoring"
            className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
          />
        </div>
        <div className="w-full md:w-1/2">
          <h3 className="text-3xl md:text-4xl font-extrabold text-white">Real-Time Monitoring</h3>
          <p className="mt-4">
            Leverage Zero-Pay's event-driven architecture for <strong>real-time payment updates</strong> and compliance alerts. Monitor cross-border transactions and detect anomalies instantly, ensuring seamless <strong>24/7 operational visibility</strong>.
          </p>
        </div>
        </div>

              {/* Fourth Highlight */}
        <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row-reverse">
        <div className="w-full md:w-1/2">
          <img
            src="/assets/images/zero-pay/audit-reports.webp"
            alt="Audit-Ready Reports"
            className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
          />
        </div>
        <div className="w-full md:w-1/2">
          <h3 className="text-3xl md:text-4xl font-extrabold text-white">Audit-Ready Reports</h3>
          <p className="mt-4">
            Simplify compliance with <strong>instant, audit-ready reports</strong>. Zero-Pay streamlines report generation, providing detailed insights into <strong>transaction trends</strong>, revenue, and regulatory adherence at the click of a button.
          </p>
        </div>
        </div>
      </section>
      <br /><br /><br />   
      
      {/* BusinessBenefits */}
      <section className="px-4 py-8 bg-black">
      {/* Title Container */}
      <div className="mb-8 text-center">
        <h2 className="text-4xl font-extrabold text-teal-600">
        Business Benefits
        </h2>
        <p className="mt-2 text-lg md:text-xl text-gray-300">
        Maximise your business potential with accelerated payments, global reach, and cost-effective transaction processing, all while ensuring security, compliance, and data-driven decision-making.
        </p>
      {/* Optional Divider */}
      <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
      </div>

      {/* Features Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
        {BusinessBenefits.map((item, index) => (
          <div
            key={index}
            className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
          >
            {/* Background Image */}
            <div
              className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
              style={{ backgroundImage: `url(${item.image})` }}
            ></div>

            {/* Content Container */}
            <div className="relative z-10 flex flex-col flex-grow">
              <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

              {/* Description with consistent space below */}
              <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

              {/* Learn More Button Positioned at Bottom Left */}
              <div className="mt-auto self-start">
                <LearnMoreButton 
                productId={index} 
                onClick={() => openModal(item.title)} 
                />              
              </div>
            </div>
          </div>
        ))}
      </div>
      </section>
      <br /> 

      {/* Future Enhancements */}
      <section className="px-4 py-8 bg-black">
      {/* Title Container */}
      <div className="mb-8 text-center">
        <h2 className="text-4xl font-extrabold text-teal-600">
        Future Enhancements
        </h2>
        <p className="mt-2 text-lg md:text-xl text-gray-300">
        Unlock the future of payments with AI-driven routing, instant settlements, and seamless DeFi integration. Experience enhanced fraud detection, biometric authentication, and multi-currency wallet support, all designed to optimise efficiency, security, and financial innovation.
        </p>
      {/* Optional Divider */}
      <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
      </div>

      {/* Features Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
        {FutureEnhancements.map((item, index) => (
          <div
            key={index}
            className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
          >
            {/* Background Image */}
            <div
              className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
              style={{ backgroundImage: `url(${item.image})` }}
            ></div>

            {/* Content Container */}
            <div className="relative z-10 flex flex-col flex-grow">
              <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

              {/* Description with consistent space below */}
              <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

              {/* Learn More Button Positioned at Bottom Left */}
              <div className="mt-auto self-start">
                <LearnMoreButton 
                productId={index} 
                onClick={() => openModal(item.title)} 
                />              
              </div>
            </div>
          </div>
        ))}
      </div>
      </section>
      <br /> 

        {/* Back to Products Button */}
        <div className="flex justify-center mt-10">
          <BackToProductsButton />
        </div>
      </div>

      {/* Back to Top Button */}
      {isBottom && (
        <div
          ref={scrollTopRef}
          className="fixed bottom-10 left-5 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
          onClick={scrollToTop}
        >
          <TiArrowUp
            size={24}
            className="text-white/40 hover:text-white transition-transform duration-300"
          />
          <span className="text-white/40 hover:text-white">BACK TO TOP</span>
        </div>
      )}

      {/* Footer */}
      <Footer />

      <ZeroPayLearnMoreCustomModal 
        isOpen={isModalOpen} 
        onClose={closeModal} 
        title={modalTitle} 
        description={modalDescription} 
        image={modalImage} 
      />
    </div>
  );
};

export default ZeroPay;