export const CoreFeatures = [
    {
      title: "AI-Powered Insights",
      description: 
        "Real-time market analysis, predictive stock price movements, and personalised investment strategies driven by advanced AI algorithms.",
      image: "/assets/images/zero-stocks/ai-powered-insights.webp",
    },
    {
      title: "Customisable Portfolio Management",
      description: 
        "Easily create, modify, and track investment portfolios with support for risk profiling, diversification alerts, and real-time tracking.",
      image: "/assets/images/zero-stocks/portfolio-management.webp",
    },
    {
      title: "Technical Analysis & Charting Tools",
      description: 
        "Interactive mobile-friendly stock charts, technical indicators, and advanced analytics for in-depth market analysis.",
      image: "/assets/images/zero-stocks/technical-analysis.webp",
    },
    {
      title: "Sentiment Analysis",
      description: 
        "Natural Language Processing (NLP) tracks news and sentiment analysis to identify market sentiment shifts before they affect stock prices.",
      image: "/assets/images/zero-stocks/sentiment-analysis.webp",
    },
    {
      title: "Personalised Investment Strategies",
      description: 
        "AI-tailored investment strategies customises to user goals, risk tolerance, and past investment behavior.",
      image: "/assets/images/zero-stocks/personalised-strategies.webp",
    },
    {
      title: "Portfolio Diversification Alerts",
      description: 
        "Receive alerts on diversification opportunities, ensuring balanced portfolios for optimal risk-return ratios.",
      image: "/assets/images/zero-stocks/diversification-alerts.webp",
    }
  ];

  
  export const TechnicalCapabilities = [
    {
      title: "Microservices Architecture",
      description: 
        "Independent, scalable services for portfolio management, market data feeds, and compliance functions for seamless updates and high availability.",
      image: "/assets/images/zero-stocks/microservices-architecture.webp",
    },
    {
      title: "Cloud-Native Infrastructure",
      description: 
        "Runs on AWS, GCP, and Azure to ensure high availability, global reach, and disaster recovery.",
      image: "/assets/images/zero-stocks/cloud-infrastructure.webp",
    },
    {
      title: "AI-Powered Predictive Models",
      description: 
        "Machine learning models analyze historical data, technical indicators, and sentiment to predict stock price movements.",
      image: "/assets/images/zero-stocks/ai-predictive-models.webp",
    },
    {
      title: "Event-Driven Notifications",
      description: 
        "Users receive event-based notifications on stock price changes, market news, and portfolio updates.",
      image: "/assets/images/zero-stocks/event-driven-notifications.webp",
    },
    {
      title: "Cross-Platform Compatibility",
      description: 
        "Zero-Stocks works on both iOS and Android devices, offering users a unified experience across platforms.",
      image: "/assets/images/zero-stocks/cross-platform.webp",
    },
    {
      title: "Data Lakes & Warehousing",
      description: 
        "Centralised storage for large volumes of historical and real-time market data for AI analysis and backtesting.",
      image: "/assets/images/zero-stocks/data-lake.webp",
    }
  ];

  
  export const KeyBenefits = [
    {
      title: "Real-Time Market Insights",
      description: 
        "Access real-time data streams to get up-to-the-minute updates on stock price movements and market trends.",
      image: "/assets/images/zero-stocks/real-time-insights.webp",
    },
    {
      title: "Actionable AI-Driven Signals",
      description: 
        "Receive personalised buy, hold, or sell recommendations using machine learning models trained on historical market data.",
      image: "/assets/images/zero-stocks/actionable-signals.webp",
    },
    {
      title: "Faster, Smarter Decisions",
      description: 
        "Empower users to make smarter investment decisions with access to predictive analytics, sentiment analysis, and personalised risk assessments.",
      image: "/assets/images/zero-stocks/smart-decisions.webp",
    },
    {
      title: "Mobile-First User Experience",
      description: 
        "A seamless, user-friendly experience optimises for mobile devices, with responsive design and intuitive navigation.",
      image: "/assets/images/zero-stocks/mobile-first.webp",
    },
    {
      title: "AI-Driven Personalisation",
      description: 
        "Custom-tailored investment advice based on user preferences, market behavior, and individual risk tolerance.",
      image: "/assets/images/zero-stocks/ai-personalisation.webp",
    },
    {
      title: "Reduced Investment Risk",
      description: 
        "Minimise exposure to volatile markets using diversification alerts and AI-driven risk analysis.",
      image: "/assets/images/zero-stocks/risk-reduction.webp",
    }
  ];

  
  export const UseCases = [
    {
      title: "Beginner Investors",
      description: 
        "Zero-Stocks helps beginner investors learn the basics of trading while offering personalised guidance and low-risk entry points.",
      image: "/assets/images/zero-stocks/beginner-investors.webp",
    },
    {
      title: "Experienced Traders",
      description: 
        "Advanced charting tools and technical indicators empower experienced traders with deeper market insights and execution support.",
      image: "/assets/images/zero-stocks/experienced-traders.webp",
    },
    {
      title: "Financial Advisors",
      description: 
        "Advisors can manage client portfolios with AI-driven insights and predictive models for optimal client outcomes.",
      image: "/assets/images/zero-stocks/financial-advisors.webp",
    },
    {
      title: "Institutional Investors",
      description: 
        "Institutional clients gain access to data streams, AI analytics, and custom reporting tools to support large-scale trading operations.",
      image: "/assets/images/zero-stocks/institutional-investors.webp",
    },
    {
      title: "Corporate Finance Teams",
      description: 
        "Corporate finance teams use Zero-Stocks to manage company investments and navigate volatile market conditions.",
      image: "/assets/images/zero-stocks/corporate-finance.webp",
    },
    {
      title: "Wealth Management Firms",
      description: 
        "Wealth management firms use Zero-Stocks to provide personalised financial advisory services to high-net-worth clients.",
      image: "/assets/images/zero-stocks/wealth-management.webp",
    }
  ];
  
  export const SecurityAndCompliance = [
    {
      title: "AES-256 Encryption",
      description: 
        "All user data is encrypted using AES-256, ensuring end-to-end data protection and secure data storage.",
      image: "/assets/images/zero-stocks/encryption-security.webp",
    },
    {
      title: "Two-Factor Authentication (2FA)",
      description: 
        "Users must verify their identity with two-factor authentication (2FA) to protect against unauthorised access.",
      image: "/assets/images/zero-stocks/2fa-security.webp",
    },
    {
      title: "Regulatory Compliance",
      description: 
        "Zero-Stocks complies with global financial regulations, including GDPR, SEC regulations, and ISO 27001 security standards.",
      image: "/assets/images/zero-stocks/regulatory-compliance.webp",
    },
    {
      title: "Audit Logs & Monitoring",
      description: 
        "Every system interaction is logged to provide a complete audit trail for compliance and troubleshooting.",
      image: "/assets/images/zero-stocks/audit-logs.webp",
    },
    {
      title: "Role-Based Access Control (RBAC)",
      description: 
        "Access permissions are assigned based on user roles, ensuring that only authorised users can access sensitive data and perform specific actions.",
      image: "/assets/images/zero-stocks/rbac-security.webp",
    },
    {
      title: "Data Breach Notification System",
      description: 
        "In case of a security breach, an automated notification system alerts users and administrators to initiate swift corrective action.",
      image: "/assets/images/zero-stocks/breach-notification.webp",
    }
  ];
  
  export const BusinessBenefits = [
    {
      title: "Enhanced Profitability",
      description: 
        "Boost revenue with AI-driven trading strategies and actionable insights that increase investment returns.",
      image: "/assets/images/zero-stocks/profitability.webp",
    },
    {
      title: "Operational Efficiency",
      description: 
        "Automate portfolio management and reduce manual intervention, saving time and operational costs.",
      image: "/assets/images/zero-stocks/efficiency.webp",
    },
    {
      title: "Stronger Customer Engagement",
      description: 
        "Deliver a personalised investment experience that improves user retention and platform loyalty.",
      image: "/assets/images/zero-stocks/customer-engagement.webp",
    },
    {
      title: "Revenue from Subscription Models",
      description: 
        "monetise advanced features through tiered subscription plans and premium services.",
      image: "/assets/images/zero-stocks/revenue-subscription.webp",
    },
    {
      title: "Scalability with Cloud Infrastructure",
      description: 
        "Scale effortlessly with cloud-native infrastructure to support growing user bases and market demands.",
      image: "/assets/images/zero-stocks/scalability.webp",
    },
    {
      title: "Regulatory Compliance Advantage",
      description: 
        "Reduce legal risks and build trust by adhering to global financial regulations like GDPR and ISO standards.",
      image: "/assets/images/zero-stocks/compliance-advantage.webp",
    }
  ];

  
  export const FutureEnhancements = [
    {
      title: "AI Model Upgrades",
      description: 
        "Improve predictive accuracy and analysis capabilities with new machine learning models that incorporate more data points.",
      image: "/assets/images/zero-stocks/ai-model-upgrades.webp",
    },
    {
      title: "Global Expansion",
      description: 
        "Expand into new markets, complying with local regulatory requirements to provide access to global investors.",
      image: "/assets/images/zero-stocks/global-expansion.webp",
    },
    {
      title: "Gamification Features",
      description: 
        "Introduce badges, achievements, and challenges to increase user engagement and encourage investment learning.",
      image: "/assets/images/zero-stocks/gamification-features.webp",
    },
    {
      title: "Voice-Activated Trading",
      description: 
        "Enable users to place buy, sell, or hold commands via voice, offering a hands-free trading experience.",
      image: "/assets/images/zero-stocks/voice-activated-trading.webp",
    },
    {
      title: "Social Trading Integration",
      description: 
        "Allow users to follow, copy, and learn from experienced traders, building a social trading community within the platform.",
      image: "/assets/images/zero-stocks/social-trading.webp",
    },
    {
      title: "Quantum Computing Compatibility",
      description: 
        "Prepare for quantum advancements by ensuring Zero-Stocks is compatible with future quantum-based financial computation.",
      image: "/assets/images/zero-stocks/quantum-computing-compatibility.webp",
    }
  ];
  