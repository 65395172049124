export const CoreFeatures = [
    {
      title: "Dynamic Premium Adjustments",
      description: 
        "Leverage real-time IoT data to dynamically adjust insurance premiums, offering fair pricing based on actual risk behavior.",
      image: "/assets/images/zero-insure/dynamic-premium-adjustments.webp",
    },
    {
      title: "Automated Claims Processing",
      description: 
        "IoT devices trigger smart contracts to automatically process and pay claims, reducing settlement times to minutes.",
      image: "/assets/images/zero-insure/automated-claims-processing.webp",
    },
    {
      title: "Personalised Policy Customisation",
      description: 
        "Users can customise insurance coverage by sharing specific data from IoT devices, offering tailored insurance policies.",
      image: "/assets/images/zero-insure/policy-customisation.webp",
    },
    {
      title: "Fraud Detection and Prevention",
      description: 
        "Blockchain-powered audit trails and IoT-triggered validation prevent fraudulent claims and ensure payment accuracy.",
      image: "/assets/images/zero-insure/fraud-detection.webp",
    },
    {
      title: "Instant Payouts",
      description: 
        "Enable instant payouts for claims using blockchain-based settlement, reducing waiting times for policyholders.",
      image: "/assets/images/zero-insure/instant-payouts.webp",
    },
    {
      title: "On-Demand Insurance",
      description: 
        "Allow users to purchase short-term, event-based insurance directly from their device, like 1-day travel insurance.",
      image: "/assets/images/zero-insure/on-demand-insurance.webp",
    }
  ];

export const TechnicalCapabilities = [
    {
      title: "IoT Device Integration",
      description: 
        "Integrates with smart home devices, wearables, and connected vehicles to collect real-time data for policy adjustments.",
      image: "/assets/images/zero-insure/iot-integration.webp",
    },
    {
      title: "Blockchain-Powered Claims",
      description: 
        "Blockchain ensures immutable and transparent records of claims, policy adjustments, and payouts.",
      image: "/assets/images/zero-insure/blockchain-claims.webp",
    },
    {
      title: "AI-Powered Claims Validation",
      description: 
        "AI analyzes claims based on user-submitted photos, videos, and IoT device data, reducing false claims.",
      image: "/assets/images/zero-insure/ai-claims-validation.webp",
    },
    {
      title: "Event-Driven Smart Contracts",
      description: 
        "Trigger smart contracts in response to IoT events, automating policy changes and claims processing in real-time.",
      image: "/assets/images/zero-insure/smart-contracts.webp",
    },
    {
      title: "Multi-Cloud Deployment",
      description: 
        "Seamless migration of the platform across AWS, Azure, and GCP to prevent cloud service downtime.",
      image: "/assets/images/zero-insure/multi-cloud-deployment.webp",
    },
    {
      title: "On-Chain Policy Storage",
      description: 
        "Store policy data directly on the blockchain for full transparency and instant access by policyholders.",
      image: "/assets/images/zero-insure/on-chain-policy-storage.webp",
    }
  ];

export const KeyBenefits = [
    {
      title: "Hyper-Personalised Insurance",
      description: 
        "Offer personalised coverage and pricing based on the user's real-world behavior and usage data.",
      image: "/assets/images/zero-insure/hyper-personalisation.webp",
    },
    {
      title: "Faster Claims Settlements",
      description: 
        "Leverage smart contracts and IoT device triggers to settle claims in minutes instead of days or weeks.",
      image: "/assets/images/zero-insure/fast-claims-settlement.webp",
    },
    {
      title: "Reduced Claims Leakage",
      description: 
        "AI fraud detection ensures claims are authentic, reducing the overall loss ratio for insurers.",
      image: "/assets/images/zero-insure/claims-leakage.webp",
    },
    {
      title: "Customer Self-Service Portal",
      description: 
        "Policyholders can adjust policies, file claims, and track status updates from a single self-service portal.",
      image: "/assets/images/zero-insure/self-service-portal.webp",
    },
    {
      title: "Usage-Based Cost Savings",
      description: 
        "Customers pay for what they use, leading to fairer pricing, especially for driving, health, and travel insurance.",
      image: "/assets/images/zero-insure/usage-based-savings.webp",
    },
    {
      title: "Insurance-as-a-Service (IaaS)",
      description: 
        "White-label the Zero-Insure platform, allowing other insurers to launch branded insurance solutions.",
      image: "/assets/images/zero-insure/iaas-offering.webp",
    }
  ];

export const UseCases = [
    {
      title: "Usage-Based Insurance (UBI)",
      description: 
        "Insurance pricing is based on actual usage, such as driving behavior captured by vehicle telematics.",
      image: "/assets/images/zero-insure/ubi.webp",
    },
    {
      title: "Health & Wellness Insurance",
      description: 
        "Wearable devices track user health data to offer personalised health insurance coverage and adjust premiums.",
      image: "/assets/images/zero-insure/health-insurance.webp",
    },
    {
      title: "Gadget & Electronics Insurance",
      description: 
        "Leverage smart home IoT devices to monitor and issue claims for electronics, ensuring coverage for theft or damage.",
      image: "/assets/images/zero-insure/gadget-insurance.webp",
    },
    {
      title: "Pet Insurance",
      description: 
        "IoT-enabled pet collars track pet health, enabling tailored pet insurance coverage and automatic claims for vet bills.",
      image: "/assets/images/zero-insure/pet-insurance.webp",
    },
    {
      title: "Travel Disruption Insurance",
      description: 
        "Real-time flight cancellation or delay data triggers automatic payouts for travel insurance policyholders.",
      image: "/assets/images/zero-insure/travel-insurance.webp",
    },
    {
      title: "On-Demand Event Insurance",
      description: 
        "Offer insurance for specific events (like concerts or conferences) with short-term, flexible coverage options.",
      image: "/assets/images/zero-insure/event-insurance.webp",
    }
  ];

export const SecurityAndCompliance = [
    {
      title: "GDPR & ISO 27001 Compliance",
      description: 
        "Zero-Insure complies with GDPR and ISO 27001 to ensure global data privacy, security, and protection standards.",
      image: "/assets/images/zero-insure/gdpr-compliance.webp",
    },
    {
      title: "Blockchain Ledger",
      description: 
        "Immutable records of claims, policy changes, and payments are stored on a blockchain, ensuring data integrity.",
      image: "/assets/images/zero-insure/blockchain-ledger.webp",
    },
    {
      title: "Data Encryption & Secure API",
      description: 
        "Data is encrypted using AES-256, while secure APIs ensure data privacy and protection against cyber threats.",
      image: "/assets/images/zero-insure/data-encryption.webp",
    },
    {
      title: "Audit Trail & Reporting",
      description: 
        "Maintain an immutable audit trail of all policy and claim events, simplifying compliance and regulatory reporting.",
      image: "/assets/images/zero-insure/audit-trail.webp",
    },
    {
      title: "Role-Based Access Control (RBAC)",
      description: 
        "Ensure only authorised personnel have access to specific insurance data and claims, reducing internal fraud risk.",
      image: "/assets/images/zero-insure/rbac-control.webp",
    },
    {
      title: "Fraud Detection Systems",
      description: 
        "Advanced AI systems monitor for fraudulent claim behavior in real-time, ensuring policyholder trust and reducing leakage.",
      image: "/assets/images/zero-insure/fraud-detection-system.webp",
    }
  ];

  export const BusinessBenefits = [
    {
      title: "Revenue Growth Opportunities",
      description: 
        "Drive new revenue streams with dynamic premium pricing, on-demand insurance, and Insurance-as-a-Service (IaaS) offerings.",
      image: "/assets/images/zero-insure/revenue-growth-opportunities.webp",
    },
    {
      title: "Operational Cost Reduction",
      description: 
        "Reduce operational expenses with automated claims, IoT-driven risk assessments, and predictive maintenance of policies.",
      image: "/assets/images/zero-insure/operational-cost-reduction.webp",
    },
    {
      title: "Customer-Centric Offerings",
      description: 
        "Deliver hyper-personalised insurance experiences with usage-based policies, self-service portals, and on-demand coverage.",
      image: "/assets/images/zero-insure/customer-centric-offerings.webp",
    },
    {
      title: "Faster Time-to-Market",
      description: 
        "Accelerate product launches with white-label IaaS models and rapid deployment via a cloud-native architecture.",
      image: "/assets/images/zero-insure/faster-time-to-market.webp",
    },
    {
      title: "Enhanced Fraud Mitigation",
      description: 
        "Leverage blockchain audit trails and AI-driven fraud detection to prevent fraudulent claims and reduce loss ratios.",
      image: "/assets/images/zero-insure/fraud-mitigation.webp",
    },
    {
      title: "Data-Driven Decision Making",
      description: 
        "Enable insurers to make informed business decisions with predictive analytics, customer behavior insights, and real-time IoT data.",
      image: "/assets/images/zero-insure/data-driven-decisions.webp",
    }
  ];
  

export const FutureEnhancements = [
    {
      title: "AI-Powered Risk Forecasting",
      description: 
        "AI-driven forecasting models will predict potential claims and risk factors, enabling proactive risk mitigation.",
      image: "/assets/images/zero-insure/ai-forecasting.webp",
    },
    {
      title: "Voice-Activated Claims",
      description: 
        "Enable policyholders to file claims via voice commands, simplifying the claims process and enhancing user experience.",
      image: "/assets/images/zero-insure/voice-claims.webp",
    },
    {
      title: "Cross-Platform IoT Compatibility",
      description: 
        "Enhance compatibility with more IoT devices, enabling data collection from new wearable tech and connected cars.",
      image: "/assets/images/zero-insure/cross-platform.webp",
    },
    {
      title: "Predictive Analytics for Claims",
      description: 
        "AI models analyze past claims data to predict potential risks and claims, offering insurers better risk visibility.",
      image: "/assets/images/zero-insure/predictive-claims.webp",
    },
    {
      title: "Insurance Gamification",
      description: 
        "Introduce gamification elements to reward users for healthy behavior, driving engagement and reduced premiums.",
      image: "/assets/images/zero-insure/gamification.webp",
    },
    {
      title: "Enhanced Mobile Application",
      description: 
        "Develop a new mobile app version with biometric login, claim tracking, and self-service policy customisation options.",
      image: "/assets/images/zero-insure/enhanced-mobile-app.webp",
    }
  ];

