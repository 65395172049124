export const CoreFeatures = [
    {
      title: "Hyper-Personalised Financial Advice",
      description: 
        "AI-driven insights tailored to individual goals, risk tolerance, and financial situations, offering bespoke financial guidance.",
      image: "/assets/images/zero-insight/hyper-personalised-advice.webp",
    },
    {
      title: "Digital Twin Financial Modeling",
      description: 
        "Simulate financial scenarios like job loss, inflation, or market shifts using a digital twin of your financial state.",
      image: "/assets/images/zero-insight/digital-twin.webp",
    },
    {
      title: "Interactive Scenario Analysis",
      description: 
        "Model 'what-if' scenarios to visualise the impact of market volatility, economic changes, or life events on financial plans.",
      image: "/assets/images/zero-insight/scenario-analysis.webp",
    },
    {
      title: "Real-Time Market Insights",
      description: 
        "Stay ahead of market trends with real-time alerts and predictive analytics to make proactive financial decisions.",
      image: "/assets/images/zero-insight/market-insights.webp",
    },
    {
      title: "Interactive Dashboards",
      description: 
        "Access detailed dashboards showcasing financial health, net worth, risk exposure, and progress toward savings goals.",
      image: "/assets/images/zero-insight/interactive-dashboards.webp",
    },
    {
      title: "AI-Driven Decision Support",
      description: 
        "Empower users to make faster and smarter decisions with predictive analytics and AI-powered support for financial planning.",
      image: "/assets/images/zero-insight/ai-decision-support.webp",
    }
  ];
  
export const TechnicalCapabilities = [
    {
      title: "Microservices Architecture",
      description: 
        "Modular microservices design enables seamless updates and independent service scaling for maximum flexibility.",
      image: "/assets/images/zero-insight/microservices-architecture.webp",
    },
    {
      title: "AI & Machine Learning Models",
      description: 
        "AI models continuously learn from market data and user interactions to provide more accurate predictions and advice.",
      image: "/assets/images/zero-insight/ai-ml-models.webp",
    },
    {
      title: "Data Lakes & Warehousing",
      description: 
        "All financial data is stored in a secure data warehouse, supporting both real-time analytics and batch processing.",
      image: "/assets/images/zero-insight/data-warehousing.webp",
    },
    {
      title: "Blockchain-Powered Security",
      description: 
        "Immutable blockchain records ensure transparency for smart contracts, fee payments, and advisory agreements.",
      image: "/assets/images/zero-insight/blockchain-security.webp",
    },
    {
      title: "Cloud-Native Infrastructure",
      description: 
        "Supports AWS, Azure, and GCP for high availability, fault tolerance, and scalable global deployment.",
      image: "/assets/images/zero-insight/cloud-infrastructure.webp",
    },
    {
      title: "Predictive Analytics Engine",
      description: 
        "Utilise a predictive analytics engine to provide risk forecasts, performance insights, and opportunity detection in real time.",
      image: "/assets/images/zero-insight/predictive-analytics.webp",
    }
  ];

export const KeyBenefits = [
    {
      title: "Personalised Investment Advice",
      description: 
        "Receive hyper-personalised investment strategies tailored to your unique financial goals and risk appetite.",
      image: "/assets/images/zero-insight/personalised-advice.webp",
    },
    {
      title: "Faster Decision-Making",
      description: 
        "AI-driven predictive analytics provide real-time insights, empowering users to make timely and effective financial decisions.",
      image: "/assets/images/zero-insight/decision-making.webp",
    },
    {
      title: "Stress-Test Financial Plans",
      description: 
        "Test the resilience of financial plans against market volatility, inflation, and other unpredictable events.",
      image: "/assets/images/zero-insight/stress-testing.webp",
    },
    {
      title: "Reduce Advisory Costs",
      description: 
        "Cut advisory costs by relying on AI-driven guidance, democratizing access to quality financial advice for all users.",
      image: "/assets/images/zero-insight/advisory-costs.webp",
    },
    {
      title: "Regulatory Compliance",
      description: 
        "Comply with GDPR, CCPA, and other data privacy regulations to protect user data and ensure legal adherence.",
      image: "/assets/images/zero-insight/compliance.webp",
    },
    {
      title: "Enhanced User Experience",
      description: 
        "Deliver personalised dashboards, easy-to-read insights, and intuitive navigation, ensuring a seamless user experience.",
      image: "/assets/images/zero-insight/user-experience.webp",
    }
  ];

export const UseCases = [
    {
      title: "Retail Investors",
      description: 
        "Help retail investors access financial planning tools, goal tracking, and personalised investment advice.",
      image: "/assets/images/zero-insight/retail-investors.webp",
    },
    {
      title: "Financial Advisors",
      description: 
        "Empower advisors with real-time AI support to enhance their advisory services with personalised client insights.",
      image: "/assets/images/zero-insight/financial-advisors.webp",
    },
    {
      title: "Institutional Clients",
      description: 
        "Institutional investors can integrate Zero-Insight into internal platforms to power personalised advisory at scale.",
      image: "/assets/images/zero-insight/institutional-clients.webp",
    },
    {
      title: "Corporate Clients",
      description: 
        "Help companies provide employee financial wellness programs as part of a corporate benefits package.",
      image: "/assets/images/zero-insight/corporate-clients.webp",
    },
    {
      title: "Wealth Management Firms",
      description: 
        "Wealth managers can leverage predictive analytics for better investment strategies and portfolio rebalancing.",
      image: "/assets/images/zero-insight/wealth-management.webp",
    },
    {
      title: "Fintech Integration",
      description: 
        "Embed Zero-Insight’s advisory engine into fintech platforms to power financial advice directly within the app.",
      image: "/assets/images/zero-insight/fintech-integration.webp",
    }
  ];


export const SecurityAndCompliance = [
    {
      title: "GDPR & CCPA Compliance",
      description: 
        "Ensures adherence to GDPR and CCPA regulations, enabling user control over personal data and privacy rights.",
      image: "/assets/images/zero-insight/gdpr-compliance.webp",
    },
    {
      title: "2FA & Role-Based Access Control (RBAC)",
      description: 
        "Enhance platform security with multi-factor authentication and access restrictions based on user roles.",
      image: "/assets/images/zero-insight/access-control.webp",
    },
    {
      title: "Data Minimisation & Pseudonymisation",
      description: 
        "User data is pseudonymises where possible, ensuring privacy and reducing the risk of data breaches.",
      image: "/assets/images/zero-insight/data-minimisation.webp",
    },
    {
      title: "Audit Trails & Logging",
      description: 
        "Comprehensive audit logs record user and system actions, supporting transparency and regulatory oversight.",
      image: "/assets/images/zero-insight/audit-trails.webp",
    },
    {
      title: "Secure API Endpoints",
      description: 
        "All API endpoints are secured with HTTPS encryption and API key verification to prevent unauthorised access.",
      image: "/assets/images/zero-insight/secure-api.webp",
    },
    {
      title: "Data Encryption (AES-256)",
      description: 
        "All financial and personal data is encrypted using AES-256 to ensure maximum security and privacy compliance.",
      image: "/assets/images/zero-insight/data-encryption.webp",
    }
  ];

  export const BusinessBenefits = [
    {
      title: "Scalable Advisory Services",
      description: 
        "Scale personalised financial advisory services to thousands of users with AI-powered insights and automation.",
      image: "/assets/images/zero-insight/scalable-advisory.webp",
    },
    {
      title: "Reduced Operational Costs",
      description: 
        "Reduce costs with AI-driven automation for advisory services, customer support, and risk analysis, minimising reliance on human advisors.",
      image: "/assets/images/zero-insight/reduced-costs.webp",
    },
    {
      title: "Faster Go-To-Market",
      description: 
        "Speed up product launches with ZeroInsight’s ready-to-deploy advisory platform and API integrations.",
      image: "/assets/images/zero-insight/fast-go-to-market.webp",
    },
    {
      title: "Revenue Growth from White-Label Solutions",
      description: 
        "monetise white-label opportunities by licensing the ZeroInsight platform to financial institutions, fintechs, and advisory firms.",
      image: "/assets/images/zero-insight/white-label-revenue.webp",
    },
    {
      title: "Data-Driven Decision-Making",
      description: 
        "Leverage real-time insights, predictive analytics, and interactive dashboards to support faster and more confident decision-making.",
      image: "/assets/images/zero-insight/data-driven-decisions.webp",
    },
    {
      title: "Enhanced Client Retention",
      description: 
        "Boost user engagement and satisfaction with hyper-personalised financial guidance, interactive dashboards, and scenario analysis.",
      image: "/assets/images/zero-insight/client-retention.webp",
    }
  ];

export const FutureEnhancements = [
    {
      title: "Conversational AI Assistants",
      description: 
        "Voice-enabled AI assistants to offer 24/7 financial guidance, simplifying complex financial decisions for users.",
      image: "/assets/images/zero-insight/conversational-ai.webp",
    },
    {
      title: "Augmented Reality (AR) Planning",
      description: 
        "Use AR to visualise financial planning scenarios, such as retirement, in 3D to provide a more immersive experience.",
      image: "/assets/images/zero-insight/ar-planning.webp",
    },
    {
      title: "Quantum Computing Integration",
      description: 
        "Leverage quantum computing for ultra-fast market predictions and optimisation of complex financial portfolios.",
      image: "/assets/images/zero-insight/quantum-computing.webp",
    },
    {
      title: "Machine Learning-Enhanced Advisory",
      description: 
        "Develop machine learning models that automatically improve the quality of financial advice as user data grows.",
      image: "/assets/images/zero-insight/ml-advisory.webp",
    },
    {
      title: "DeFi Integration",
      description: 
        "Integrate with decentralised finance (DeFi) protocols to enable tokenised investment opportunities and lending options.",
      image: "/assets/images/zero-insight/defi-integration.webp",
    },
    {
      title: "Customisable User Dashboards",
      description: 
        "Allow users to create personalised dashboards to prioritize their preferred data points, KPIs, and financial insights.",
      image: "/assets/images/zero-insight/custom-dashboards.webp",
    }
  ];

