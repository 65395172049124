export const CoreFeatureDetails = {
  "Global Payment Processing": {
    title: "Global Payment Processing",
    description: `
      Process payments in multiple currencies across borders, supporting both fiat and digital currencies for seamless global transactions.
      
      **Key Features:**
      **Multi-Currency Support:** Handle multiple fiat and crypto currencies.
      **Cross-Border Capabilities:** Accept payments globally with real-time currency conversion.
      **Seamless Transactions:** Frictionless payment experience for customers and merchants alike.
    `,
    image: "/assets/images/zero-pay/global-payment-processing.webp"
  },

  "Multi-Currency Support": {
    title: "Multi-Currency Support",
    description: `
      Accept and process payments in various currencies with real-time currency conversion, supporting global commerce and reducing currency risks.
      
      **Key Features:**
      **Multi-Currency Acceptance:** Accept payments in multiple currencies.
      **Real-Time Currency Conversion:** Automatic conversion to reduce exchange rate risks.
      **Global Reach:** Supports fiat and crypto for borderless commerce.
    `,
    image: "/assets/images/zero-pay/multi-currency-support.webp"
  },

  "Smart Payment Routing": {
    title: "Smart Payment Routing",
    description: `
      Automatically selects the most cost-effective payment channel, ensuring lower fees and faster transaction times.
      
      **Key Features:**
      **Dynamic Channel Selection:** Routes payments to the cheapest, fastest path.
      **Fee Reduction:** Minimises transaction costs using optimal payment channels.
      **Real-Time Switching:** Automatically switches routes in case of network issues.
    `,
    image: "/assets/images/zero-pay/smart-payment-routing.webp"
  },

  "Real-Time Payments": {
    title: "Real-Time Payments",
    description: `
      Process payments in real-time, enabling businesses to receive funds instantly, enhancing liquidity and cash flow.
      
      **Key Features:**
      **Instant Settlement:** Funds are available to businesses instantly.
      **Liquidity Management:** Improve cash flow with real-time settlements.
      **24/7 Payment Processing:** Payments can be processed anytime, anywhere.
    `,
    image: "/assets/images/zero-pay/real-time-payments.webp"
  },

  "Payment Gateway Integration": {
    title: "Payment Gateway Integration",
    description: `
      Seamlessly integrates with Stripe, PayPal, and other popular payment gateways to enhance payment options for customers.
      
      **Key Features:**
      **Multi-Gateway Support:** Integrate with Stripe, PayPal, and more.
      **Unified API:** One integration connects to multiple payment gateways.
      **Fallback Payment Options:** Automatically switch to a backup gateway if one fails.
    `,
    image: "/assets/images/zero-pay/payment-gateway-integration.webp"
  },

  "Customisable Checkout": {
    title: "Customisable Checkout",
    description: `
      Merchants can customise their payment flows and checkout experience to match their brand. Customisations include logos, colours, and layouts to improve UX and increase conversions.
      
      **Key Features:**
      **Custom Branding:** Add logos, colours, and design elements.
      **Flexible Layouts:** Customise the layout to suit user experience.
      **Conversion Boosters:** Personalised experience boosts customer trust and sales.
    `,
    image: "/assets/images/zero-pay/customisable-checkout.webp"
  }
};


export const PaymentCapabilitiesDetails = {
  "One-Click Payments": {
    title: "One-Click Payments",
    description: `
      Allow returning customers to complete purchases instantly with pre-saved payment details, streamlining the checkout process for faster conversions.
      
      **Key Features:**
      **Instant Checkout:** Reduce checkout times with one-click payment completion.
      **Saved Payment Details:** Securely store customer payment information for seamless future transactions.
      **Faster Conversions:** Minimise friction and increase cart-to-checkout conversions.
    `,
    image: "/assets/images/zero-pay/one-click-payments.webp"
  },

  "Multi-Payment Options": {
    title: "Multi-Payment Options",
    description: `
      Offer customers a wide range of payment methods including credit/debit cards, e-wallets, and crypto payments, giving them the flexibility they need.
      
      **Key Features:**
      **Multiple Payment Methods:** Accept credit cards, PayPal, digital wallets, and crypto payments.
      **Customer Choice:** Allow customers to select their preferred payment method.
      **Enhanced Checkout Experience:** Provide a wider range of payment options to reduce cart abandonment.
    `,
    image: "/assets/images/zero-pay/multi-payment-options.webp"
  },

  "Split Payments": {
    title: "Split Payments",
    description: `
      Enable customers to split payments across multiple methods, such as paying with part credit card and part wallet balance, offering flexibility and convenience.
      
      **Key Features:**
      **Multi-Method Payments:** Split payments between credit cards, e-wallets, and crypto.
      **Flexible Checkout:** Offer customers the option to split payments to suit their financial needs.
      **Increased Sales:** Reduce checkout friction and avoid declined payments with payment splits.
    `,
    image: "/assets/images/zero-pay/split-payments.webp"
  },

  "Localised Payment Methods": {
    title: "Localised Payment Methods",
    description: `
      Support region-specific payment methods to cater to local preferences and increase customer satisfaction in global markets.
      
      **Key Features:**
      **Regional Payment Options:** Support payment methods like iDEAL, Sofort, and PayNow.
      **Currency Localisation:** Display prices in local currency to increase conversions.
      **Customer-Centric Approach:** Build trust by supporting familiar local payment options.
    `,
    image: "/assets/images/zero-pay/localised-payment-methods.webp"
  },

  "Pre-Authorisation & Hold": {
    title: "Pre-Authorisation & Hold",
    description: `
      Place payment holds on customer funds to guarantee payment security before shipping goods or offering services, reducing risk for merchants.
      
      **Key Features:**
      **Payment Holds:** Reserve funds until order completion or service delivery.
      **Risk Reduction:** Ensure customers have sufficient funds before processing transactions.
      **Merchant Security:** Prevent chargebacks by securing payments in advance.
    `,
    image: "/assets/images/zero-pay/payment-hold-preauthorisation.webp"
  },

  "Express Checkout": {
    title: "Express Checkout",
    description: `
      Fast-track the checkout process with a single-tap payment option, allowing repeat customers to skip the cart and pay directly from product pages.
      
      **Key Features:**
      **Single-Tap Payments:** Complete purchases directly from the product page.
      **Reduced Friction:** Eliminate multi-step checkouts for faster payments.
      **Higher Conversion Rates:** Boost checkout speed and reduce cart abandonment.
    `,
    image: "/assets/images/zero-pay/express-checkout.webp"
  }
};


export const TechnicalArchitectureDetails = {
  "Microservices Architecture": {
    title: "Microservices Architecture",
    description: `
      Leverages a microservices approach to isolate payment, currency conversion, and fraud detection services for fast updates and enhanced scalability.
      
      **Key Features:**
      **Service Isolation:** Payment, currency conversion, and fraud detection services operate as isolated microservices.
      **High Scalability:** Scale specific services without affecting the entire system.
      **Faster Updates:** Deploy individual updates for each service independently.
    `,
    image: "/assets/images/zero-pay/microservices-architecture.webp"
  },

  "Cloud-Native Design": {
    title: "Cloud-Native Design",
    description: `
      Built to run on AWS, Azure, or Google Cloud, ensuring global availability, fast deployments, and high scalability.
      
      **Key Features:**
      **Multi-Cloud Support:** Run on AWS, Azure, or Google Cloud.
      **Global Availability:** Ensure high availability with failover support.
      **Automatic Scaling:** Dynamically scale resources based on demand.
    `,
    image: "/assets/images/zero-pay/cloud-native-design.webp"
  },

  "Event-Driven Design": {
    title: "Event-Driven Design",
    description: `
      Uses an event-driven architecture with RabbitMQ message queues for real-time updates on payment status, chargebacks, and settlements.
      
      **Key Features:**
      **Real-Time Payment Status:** Get real-time updates on payment status, chargebacks, and settlements.
      **Asynchronous Messaging:** Use RabbitMQ message queues for asynchronous event handling.
      **High Responsiveness:** Process event triggers instantly for real-time data updates.
    `,
    image: "/assets/images/zero-pay/event-driven-design.webp"
  },

  "Data Encryption & Tokenisation": {
    title: "Data Encryption & Tokenisation",
    description: `
      Implements data encryption and tokenisation to protect sensitive payment information, ensuring regulatory compliance and data security.
      
      **Key Features:**
      **Data Encryption (AES-256):** End-to-end encryption using industry-standard AES-256 encryption.
      **Tokenisation:** Replace sensitive payment information with secure tokens.
      **Regulatory Compliance:** Ensure GDPR, PCI-DSS, and other data privacy compliance.
    `,
    image: "/assets/images/zero-pay/data-encryption.webp"
  },

  "Role-Based Access Control (RBAC)": {
    title: "Role-Based Access Control (RBAC)",
    description: `
      Role-based access ensures secure control over access to critical system components and payment data.
      
      **Key Features:**
      **Granular Permissions:** Set user roles with specific access rights.
      **Access Control:** Limit access to sensitive payment data and system resources.
      **Audit Trails:** Track access history for compliance and security audits.
    `,
    image: "/assets/images/zero-pay/rbac.webp"
  },

  "Serverless Functions": {
    title: "Serverless Functions",
    description: `
      Leverages serverless functions for low-latency, event-triggered payments and real-time settlement notifications.
      
      **Key Features:**
      **Event-Triggered Functions:** Execute payment tasks in response to specific events.
      **Cost-Efficient:** Pay only for usage with no infrastructure overhead.
      **Low Latency:** Achieve real-time processing and settlement notifications.
    `,
    image: "/assets/images/zero-pay/serverless-functions.webp"
  }
};


export const UseCasesDetails = {
  "E-Commerce Payments": {
    title: "E-Commerce Payments",
    description: `
      Facilitate payments for online stores with multi-currency support and customisable checkout options for higher conversions.
      
      **Key Features:**
      **Multi-Currency Payments:** Accept payments in local and international currencies.
      **Customisable Checkout:** Tailored checkout pages to increase conversions.
      **Fraud Detection:** Integrated fraud protection for secure payments.
    `,
    image: "/assets/images/zero-pay/ecommerce-payments.webp"
  },

  "Subscription Services": {
    title: "Subscription Services",
    description: `
      Enable subscription billing for SaaS platforms, streaming services, and membership-based companies with support for recurring payments.
      
      **Key Features:**
      **Recurring Billing:** Automate subscription billing for SaaS, streaming, and memberships.
      **Billing Flexibility:** Support for monthly, quarterly, and annual billing plans.
      **Retry Logic:** Automatic retry of failed payments to reduce churn.
    `,
    image: "/assets/images/zero-pay/subscription-services.webp"
  },

  "Cross-Border Payments": {
    title: "Cross-Border Payments",
    description: `
      Support global payments with real-time currency conversion and instant payouts for gig workers, freelancers, and international businesses.
      
      **Key Features:**
      **Real-Time Currency Conversion:** Convert payments into local currencies instantly.
      **Instant Payouts:** Immediate payouts to freelancers and gig workers.
      **Regulatory Compliance:** Ensure compliance with global payment regulations.
    `,
    image: "/assets/images/zero-pay/cross-border-payments.webp"
  },

  "Retail & POS Payments": {
    title: "Retail & POS Payments",
    description: `
      Enable in-store payments with support for contactless payments, QR codes, and POS systems for retail merchants.
      
      **Key Features:**
      **Contactless Payments:** Support for NFC and QR code payments.
      **POS System Integration:** Seamless integration with existing POS systems.
      **Real-Time Payment Confirmation:** Instant verification of payments.
    `,
    image: "/assets/images/zero-pay/retail-pos-payments.webp"
  },

  "Gig Economy Payments": {
    title: "Gig Economy Payments",
    description: `
      Support seamless payments for freelancers, gig workers, and digital nomads, offering instant payouts and reduced fees.
      
      **Key Features:**
      **Instant Payouts:** Quick payments to freelancers and gig workers.
      **Multi-Currency Support:** Pay in local currencies or crypto.
      **Reduced Fees:** Lower transaction fees to support gig economy workers.
    `,
    image: "/assets/images/zero-pay/gig-economy-payments.webp"
  },

  "Marketplace Payments": {
    title: "Marketplace Payments",
    description: `
      Facilitate multi-party payments and disbursements in online marketplaces and vendor platforms.
      
      **Key Features:**
      **Multi-Party Payouts:** Split payments between vendors and marketplace operators.
      **Automated Reconciliation:** Track and settle payments for each vendor.
      **Revenue Share Models:** Implement percentage-based commission models for platform operators.
    `,
    image: "/assets/images/zero-pay/marketplace-payments.webp"
  }
};


export const SecurityAndComplianceDetails = {
  "PCI-DSS Certification": {
    title: "PCI-DSS Certification",
    description: `
      Ensures compliance with PCI-DSS standards to protect customer payment data and maintain the highest security standards.
      
      **Key Features:**
      **Data Protection:** Meets PCI-DSS security standards for payment data protection.
      **Fraud Prevention:** Ensures secure handling and processing of customer payment details.
      **Audit Readiness:** Provides certification for payment system compliance audits.
    `,
    image: "/assets/images/zero-pay/pci-dss-certification.webp"
  },

  "End-to-End Encryption": {
    title: "End-to-End Encryption",
    description: `
      Applies end-to-end encryption to protect payment data during transmission, ensuring secure data transfers.
      
      **Key Features:**
      **Data Security:** Encrypts data from initiation to completion of the payment process.
      **MITM Protection:** Prevents man-in-the-middle attacks during payment transfers.
      **Compliance Assurance:** Ensures compliance with data privacy regulations such as GDPR.
    `,
    image: "/assets/images/zero-pay/end-to-end-encryption.webp"
  },

  "Fraud Detection & Prevention": {
    title: "Fraud Detection & Prevention",
    description: `
      Uses AI-driven algorithms to detect and block fraudulent transactions in real time, protecting businesses from financial loss.
      
      **Key Features:**
      **AI-Driven Analysis:** Identifies suspicious patterns to flag fraudulent payments.
      **Real-Time Blocking:** Automatically blocks high-risk transactions in real-time.
      **Risk Scoring:** Assigns a risk score to each transaction for enhanced monitoring.
    `,
    image: "/assets/images/zero-pay/fraud-detection.webp"
  },

  "KYC & AML Compliance": {
    title: "KYC & AML Compliance",
    description: `
      Adheres to Know Your Customer (KYC) and Anti-Money Laundering (AML) regulations to ensure business compliance.
      
      **Key Features:**
      **Identity Verification:** Verifies user identity to comply with AML regulations.
      **Risk Assessment:** Identifies and flags high-risk users and transactions.
      **Compliance Reporting:** Provides comprehensive reporting for regulatory compliance.
    `,
    image: "/assets/images/zero-pay/kyc-aml-compliance.webp"
  },

  "Audit Logs & Monitoring": {
    title: "Audit Logs & Monitoring",
    description: `
      Provides detailed audit logs of all payment transactions, ensuring businesses have full visibility into payment activity.
      
      **Key Features:**
      **Detailed Logs:** Tracks every payment action with time-stamped entries.
      **Event Monitoring:** Monitors key events like transaction failures, disputes, and refunds.
      **Regulatory Compliance:** Ensures availability of records for audits and compliance checks.
    `,
    image: "/assets/images/zero-pay/audit-logs.webp"
  },

  "Tokenised Payments": {
    title: "Tokenised Payments",
    description: `
      Tokenises payment card data, ensuring that customer payment details are never stored directly.
      
      **Key Features:**
      **Data Tokenisation:** Replaces payment card details with unique tokens.
      **Data Breach Protection:** Reduces risk of data breaches since card data is never stored.
      **PCI Compliance:** Simplifies PCI-DSS compliance by eliminating card data storage.
    `,
    image: "/assets/images/zero-pay/tokenised-payments.webp"
  }
};


export const BusinessBenefitsDetails = {
  "Faster Transactions": {
    title: "Faster Transactions",
    description: `
      Process payments in real time, reducing delays and giving businesses faster access to working capital.
      
      **Key Features:**
      **Real-Time Payment Processing:** Funds are made available instantly after customer payments.
      **Reduced Settlement Time:** Minimises the settlement period, providing quicker access to capital.
      **Business Liquidity:** Improves cash flow for daily business operations.
    `,
    image: "/assets/images/zero-pay/faster-transactions.webp"
  },

  "Global Reach": {
    title: "Global Reach",
    description: `
      Accept payments from customers worldwide in multiple currencies, enhancing customer satisfaction and conversions.
      
      **Key Features:**
      **Cross-Border Payments:** Supports international payments for global customers.
      **Multi-Currency Support:** Accepts payments in multiple currencies for seamless global trade.
      **Enhanced Customer Experience:** Removes barriers for international customers, leading to higher conversions.
    `,
    image: "/assets/images/zero-pay/global-reach.webp"
  },

  "Reduced Transaction Costs": {
    title: "Reduced Transaction Costs",
    description: `
      Lower operational costs and avoid high fees by routing payments via cost-effective channels using smart routing technology.
      
      **Key Features:**
      **Smart Payment Routing:** Automatically selects the most affordable payment channel.
      **Fee Reduction:** Minimises interchange fees and processing costs.
      **Operational Efficiency:** Reduces overall payment handling costs.
    `,
    image: "/assets/images/zero-pay/reduced-costs.webp"
  },

  "Increased Revenue Opportunities": {
    title: "Increased Revenue Opportunities",
    description: `
      Provide a broader range of payment options to customers, increasing the potential for higher conversions and repeat business.
      
      **Key Features:**
      **Multi-Payment Methods:** Offer credit cards, crypto payments, and e-wallets for customer convenience.
      **Increase in Conversion Rates:** Greater payment flexibility boosts conversion rates.
      **Repeat Business:** Customers are more likely to return due to simple, versatile payment options.
    `,
    image: "/assets/images/zero-pay/increased-revenue.webp"
  },

  "Enhanced Cash Flow": {
    title: "Enhanced Cash Flow",
    description: `
      Instant settlements and real-time payment processing ensure faster access to funds, improving business liquidity and cash flow.
      
      **Key Features:**
      **Faster Settlements:** Reduce the time to access payments, supporting operational cash flow.
      **On-Demand Payouts:** Withdraw funds instantly when needed for business use.
      **Improved Liquidity:** Enhances working capital to manage business expenses.
    `,
    image: "/assets/images/zero-pay/enhanced-cash-flow.webp"
  },

  "Data-Driven Decision Making": {
    title: "Data-Driven Decision Making",
    description: `
      Advanced analytics provide valuable insights into payment trends, customer preferences, and operational efficiency, enabling data-driven decisions.
      
      **Key Features:**
      **Analytics Dashboard:** Real-time access to data insights via an interactive dashboard.
      **Customer Insights:** Understand customer purchasing behavior and optimise marketing strategies.
      **Operational Reports:** Track key performance indicators (KPIs) to improve operational efficiency.
    `,
    image: "/assets/images/zero-pay/data-driven-insights.webp"
  }
};


export const FutureEnhancementsDetails = {
  "AI-Powered Payment Routing": {
    title: "AI-Powered Payment Routing",
    description: `
      Use AI to predict and select optimal payment routes, further reducing fees and enhancing speed.
      
      **Key Features:**
      **Predictive AI Models:** Uses AI to anticipate the fastest and cheapest payment routes.
      **Cost Reduction:** Reduces transaction fees by selecting optimal payment paths.
      **Dynamic Routing:** Adapts to network conditions and payment gateways in real-time.
    `,
    image: "/assets/images/zero-pay/ai-payment-routing.webp"
  },

  "Instant Settlements": {
    title: "Instant Settlements",
    description: `
      Enable instant payments, allowing businesses to receive payments immediately, eliminating the need for daily settlements.
      
      **Key Features:**
      **Real-Time Settlement:** Funds are settled instantly without waiting for batch processing.
      **Cash Flow Acceleration:** Immediate access to funds for operational liquidity.
      **Improved Customer Satisfaction:** Reduces customer waiting times, enhancing user experience.
    `,
    image: "/assets/images/zero-pay/instant-settlements.webp"
  },

  "DeFi Integration": {
    title: "DeFi Integration",
    description: `
      Integrate with decentralised finance (DeFi) protocols to allow blockchain-based payments and settlements using smart contracts.
      
      **Key Features:**
      **Smart Contract Payments:** Automates payments via DeFi smart contracts.
      **Cross-Chain Support:** Facilitates payments across multiple blockchains.
      **Decentralised Settlements:** Removes the need for intermediaries, reducing fees and enhancing security.
    `,
    image: "/assets/images/zero-pay/defi-integration.webp"
  },

  "Multi-Currency Wallets": {
    title: "Multi-Currency Wallets",
    description: `
      Merchants will be able to hold, send, and receive payments in multiple currencies within a unified wallet system.
      
      **Key Features:**
      **Unified Wallet:** Single wallet for multiple currencies (fiat and crypto).
      **Cross-Currency Payments:** Facilitates seamless payments across currencies.
      **Easy Transfers:** Merchants can send and receive payments in multiple currencies instantly.
    `,
    image: "/assets/images/zero-pay/multi-currency-wallets.webp"
  },

  "Enhanced Fraud Detection": {
    title: "Enhanced Fraud Detection",
    description: `
      Next-generation fraud detection with AI-driven predictive models that block suspicious activities before they happen.
      
      **Key Features:**
      **AI-Powered Detection:** Uses AI models to predict and block fraudulent activities in real time.
      **Proactive Security:** Identifies potential threats before they impact the business.
      **Machine Learning Models:** Continuously improves its detection capability using real-world transaction data.
    `,
    image: "/assets/images/zero-pay/enhanced-fraud-detection.webp"
  },

  "Biometric Authentication": {
    title: "Biometric Authentication",
    description: `
      Support biometric login (fingerprint, FaceID) for customer payments, providing a faster and more secure user experience.
      
      **Key Features:**
      **Fingerprint & FaceID Login:** Enables quick, secure logins for customers.
      **Multi-Factor Authentication (MFA):** Adds an additional layer of security for payment authentication.
      **User Convenience:** Enhances the customer experience with faster access to payment features.
    `,
    image: "/assets/images/zero-pay/biometric-authentication.webp"
  }
};
