// src/components/Products.jsx
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import AnimatedTitle from './AnimatedTitle';
import { TiArrowUp } from "react-icons/ti";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import HeroButton from './HeroButton';
import ScrollDownButton from './ScrollDownButton';
import products from '../data/productsData'; 

// Register ScrollTrigger once
gsap.registerPlugin(ScrollTrigger);

/** 
 * Helper function to parse all unique sub-sectors out of productsData,
 * splitting on "/" and trimming each sub-sector, e.g.
 * "FinTech / DeFi / Crypto Wallet" => ["FinTech", "DeFi", "Crypto Wallet"].
 */
function getUniqueSectors(productsArray) {
  const sectorSet = new Set();
  productsArray.forEach((prod) => {
    // Split on "/" and trim
    const subSectors = prod.sector.split("/").map(item => item.trim());
    subSectors.forEach(sub => sectorSet.add(sub));
  });
  // Convert set to sorted array
  return Array.from(sectorSet).sort();
}

const Products = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSector, setSelectedSector] = useState('All');
  const [scrollDownClicked, setScrollDownClicked] = useState(false);
  const [isBottom, setIsBottom] = useState(false);

  // We dynamically determine all sub-sector options from the products array
  const [sectorOptions, setSectorOptions] = useState([]);
  
  // Refs for layout & GSAP
  const sectionRefs = useRef([]);
  const scrollDownRef = useRef(null);
  const scrollTopRef = useRef(null);
  const productsRef = useRef(null);
  const productCardRefs = useRef([]); 
  const buttonRefs = useRef([]);

  // On mount, compute our dynamic drop-down options
  useEffect(() => {
    const uniqueSectors = getUniqueSectors(products);
    setSectorOptions(uniqueSectors);
  }, []);

  // ---------------------- FILTERED PRODUCTS ----------------------
  const filteredProducts = products.filter((product) => {
    // Combine name, sector, and description for search convenience
    const fullText = [
      product.name,
      product.sector,
      product.description
    ].join(' ').toLowerCase();

    // The search box matches any substring in name/sector/description
    const matchesSearch = fullText.includes(searchTerm.toLowerCase());

    // The drop-down filters by a single sub-sector (partial match).
    // If "All" is selected, we skip the sector filter.
    const sectorFilterPass =
      selectedSector === 'All'
        ? true
        : product.sector.toLowerCase().includes(selectedSector.toLowerCase());

    return matchesSearch && sectorFilterPass;
  });

  // ---------------------- GSAP + HOOKS ----------------------------
  useEffect(() => {
    // Scroll to top on mount
    window.scrollTo({ top: 0, behavior: "smooth" });

    // Hero Section (fade in from top)
    if (sectionRefs.current[0]) {
      gsap.fromTo(
        sectionRefs.current[0],
        { opacity: 0, y: -50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power3.out",
          scrollTrigger: {
            trigger: sectionRefs.current[0],
            start: "top 80%",
          },
        }
      );
    }

    // Products Section (fade in from bottom)
    if (productsRef.current) {
      gsap.fromTo(
        productsRef.current,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power3.out",
          scrollTrigger: {
            trigger: productsRef.current,
            start: "top 80%",
          },
        }
      );
    }

    // Scroll Down Button
    if (scrollDownRef.current) {
      gsap.fromTo(
        scrollDownRef.current,
        { opacity: 0, y: -20 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power3.out",
        }
      );
    }

    // Back to Top Button
    if (scrollTopRef.current) {
      gsap.fromTo(
        scrollTopRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: scrollTopRef.current,
            start: "top bottom",
          },
        }
      );
    }
  }, []);

  // Animate product cards on load or whenever the filtered array changes
  useEffect(() => {
    if (!productsRef.current || productCardRefs.current.length === 0) return;

    // Fade in first 3 cards immediately
    gsap.fromTo(
      productCardRefs.current.slice(0, 3).filter(Boolean),
      { opacity: 0, y: 0 },
      { opacity: 1, y: 0, duration: 1, ease: "power3.out" }
    );

    // Stagger the rest
    gsap.fromTo(
      productCardRefs.current.slice(3).filter(Boolean),
      { opacity: 0, y: 30 },
      {
        opacity: 1,
        y: 0,
        duration: 0.8,
        stagger: 0.15,
        ease: "power3.out",
        scrollTrigger: {
          trigger: productsRef.current,
          start: "top 90%",
          end: "bottom top",
          scrub: false,
          once: true,
        },
      }
    );
  }, [filteredProducts]);

  // GSAP Hover Animations for Buttons
  useEffect(() => {
    const buttons = buttonRefs.current.filter(Boolean);
    if (!buttons.length) return;

    buttons.forEach((btn) => {
      const onEnter = () => {
        gsap.to(btn, {
          background: "linear-gradient(135deg, #06b6d4, #3b82f6)",
          scale: 1.05,
          duration: 0.3,
          ease: "power3.out",
        });
      };
      const onLeave = () => {
        gsap.to(btn, {
          background: "",
          scale: 1,
          duration: 0.3,
          ease: "power3.out",
        });
      };
      btn.addEventListener("mouseenter", onEnter);
      btn.addEventListener("mouseleave", onLeave);

      // Cleanup
      return () => {
        btn.removeEventListener("mouseenter", onEnter);
        btn.removeEventListener("mouseleave", onLeave);
      };
    });
  }, []);

  // ---------------------- EVENT HANDLERS ---------------------------
  const handleMoreDetailsClick = (id) => {
    navigate(`/products/${id}`);
  };

  const handleViewOnChainClick = (link) => {
    window.open(link, '_blank');
  };

  const checkIfBottom = () => {
    // Show "Back to Top" if near page bottom
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 200) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkIfBottom);
    return () => {
      window.removeEventListener("scroll", checkIfBottom);
    };
  }, []);

  const scrollToContent = () => {
    if (productsRef.current) {
      const top = productsRef.current.offsetTop - 80; 
      window.scrollTo({ top, behavior: "smooth" });
      setScrollDownClicked(true);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setScrollDownClicked(false);
  };

  // ---------------------- INPUT HANDLERS ----------------------------
  const handleSearchChange = (e) => setSearchTerm(e.target.value);
  const handleSectorChange = (e) => setSelectedSector(e.target.value);

  // ---------------------- RENDER -----------------------------------
  return (
    <div className="w-screen min-h-screen bg-black text-blue-50 flex flex-col items-center p-5">
      {/* Hero Section */}
      <div
        className="relative w-full h-64 md:h-[32rem] overflow-hidden flex flex-col items-center justify-center mb-10"
        ref={(el) => (sectionRefs.current[0] = el)}
        style={{ perspective: "1000px" }}
      >
        <video
          src="/videos/cryptostore-hero.mp4"
          autoPlay
          loop
          muted
          className="absolute left-0 top-0 w-full h-full object-cover"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/60">
          <AnimatedTitle
            title="Our Fabulous Products"
            containerClass="text-center text-blue-50 font-zentry text-5xl md:text-7xl drop-shadow-lg"
          />
          <h3 className="mt-4 text-lg md:text-2xl font-bold tracking-wide">
            Explore the Innovation Behind Zero-Blockchain
          </h3>
          <p className="mt-2 text-sm md:text-lg max-w-2xl text-center opacity-90">
            Discover our suite of products designed to revolutionise the blockchain 
            and fintech industries, driving innovation and efficiency.
          </p>
        </div>
        {/* Scroll Down Button (hidden once clicked) */}
        {!scrollDownClicked && (
          <ScrollDownButton
            targetId="products-section"
            onClick={scrollToContent}
            ref={scrollDownRef}
          />
        )}
      </div>

      {/* Search and Filter Section */}
      <div className="w-full max-w-6xl mb-5" id="products-section" ref={productsRef}>
        <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-5">
          <input
            type="text"
            placeholder="Search products (name, sector, etc.)..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="w-full md:w-1/2 p-3 rounded-md bg-gray-800 text-white placeholder-gray-500 mb-3 md:mb-0"
            aria-label="Search products"
          />

          {/* Dynamically generated sector dropdown */}
          <select
            value={selectedSector}
            onChange={handleSectorChange}
            className="w-full md:w-1/4 p-3 rounded-md bg-gray-800 text-white"
            aria-label="Filter by sector"
          >
            <option value="All">All Sectors</option>
            {sectorOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        {/* Count of filtered products */}
        <p className="text-white-900 mb-5">
          Total Products: {filteredProducts.length}
        </p>

        {/* Products Grid */}
        <div 
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
          style={{ perspective: "1200px" }}
        >
          {filteredProducts.map((product, i) => (
            <div
              key={product.id}
              ref={(el) => (productCardRefs.current[i] = el)}
              className="relative overflow-hidden flex flex-col border-2 transition-transform duration-300"
              style={{
                borderColor: 'silver',
                borderRadius: '0.5rem',
                background: 'linear-gradient(135deg, rgba(38,38,64,0.9), rgba(56,56,96,0.9))',
                transformStyle: "preserve-3d",
              }}
              onMouseMove={(e) => {
                if (!e.currentTarget) return;
                const rect = e.currentTarget.getBoundingClientRect();
                const x = e.clientX - rect.left;
                const y = e.clientY - rect.top;
                const centerX = rect.width / 2;
                const centerY = rect.height / 2;
                const rotateX = ((y - centerY) / centerY) * 5;
                const rotateY = ((x - centerX) / centerX) * 5;
                gsap.to(e.currentTarget, {
                  rotateX: -rotateX,
                  rotateY: rotateY,
                  scale: 1.04,
                  duration: 0.3,
                  overwrite: 'auto'
                });
              }}
              onMouseLeave={(e) => {
                if (!e.currentTarget) return;
                gsap.to(e.currentTarget, {
                  rotateX: 0,
                  rotateY: 0,
                  scale: 1,
                  duration: 0.5,
                  ease: "power2.out",
                  overwrite: 'auto'
                });
              }}
            >
              {/* Product Image */}
              <div
                className="h-48 flex items-center justify-center border-b-2 w-full"
                style={{
                  borderColor: 'silver',
                  backgroundColor: 'black',
                }}
              >
                <img
                  src={product.image}
                  alt={product.name}
                  className="object-contain transition-all duration-300 border-2 border-silver rounded-sm"
                  style={{
                    maxHeight: "90%",
                    maxWidth: "90%",
                  }}
                />
              </div>

              {/* Plaque section for Title + Text */}
              <div className="p-5 flex-grow flex flex-col text-white">
                {/* Silver Outline Title */}
                <h2
                  className="text-2xl font-bold mb-3 text-center"
                  style={{
                    WebkitTextStroke: '1px silver',
                    WebkitTextFillColor: 'white',
                  }}
                >
                  {product.name}
                </h2>
                <div
                  className="border-2 border-silver p-3 mb-3 text-sm md:text-base"
                  style={{
                    borderRadius: '6px',
                    backgroundColor: 'rgba(255,255,255,0.1)',
                  }}
                >
                  <p className="mb-2 text-white-900 font-semibold">
                    {product.sector}
                  </p>
                  <p className="text-white-900">
                    {product.description}
                  </p>
                </div>

                {/* Buttons */}
                <div className="mt-auto flex justify-between items-center">
                  <HeroButton
                    onClick={() => handleMoreDetailsClick(product.id)}
                    ariaLabel={`More details about ${product.name}`}
                    additionalClasses="m-2 button-animate"
                    style={{ fontSize: '12px' }}
                    ref={(el) => (buttonRefs.current[i * 2] = el)}
                  >
                    More Details
                  </HeroButton>
                  
                  <HeroButton
                    onClick={() => handleViewOnChainClick(product.link)}
                    ariaLabel={`Go to ${product.name}`}
                    additionalClasses="m-2 bg-green-500 hover:bg-green-600 button-animate"
                    style={{ fontSize: '12px' }}
                    ref={(el) => (buttonRefs.current[i * 2 + 1] = el)}
                  >
                    Go to Product
                  </HeroButton>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Back to Top Button */}
      {isBottom && (
        <div
          ref={scrollTopRef}
          className="fixed bottom-10 left-5 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
          onClick={scrollToTop}
        >
          <TiArrowUp
            size={24}
            className="text-white/40 hover:text-white transition-transform duration-300"
          />
          <span className="text-white/40 hover:text-white">BACK TO TOP</span>
        </div>
      )}

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Products;
