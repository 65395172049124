export const CoreFeatures = [
    {
      title: "Real-Time Fraud Detection",
      description: 
        "Zero-Predict uses predictive algorithms and real-time monitoring to detect fraudulent activities before they escalate, essential for high-speed blockchain transactions.",
      image: "/assets/images/zero-predict/real-time-fraud-detection.webp",
    },
    {
      title: "Machine Learning & Pattern Recognition",
      description: 
        "The platform adapts to emerging threats with continuous self-learning ML models, ensuring proactive fraud detection.",
      image: "/assets/images/zero-predict/machine-learning-patterns.webp",
    },
    {
      title: "Advanced Anomaly Detection",
      description: 
        "AI identifies anomalies in transaction behavior in real time, flagging unusual patterns that may signal fraud.",
      image: "/assets/images/zero-predict/anomaly-detection.webp",
    },
    {
      title: "Customisable Alerts & Notifications",
      description: 
        "Receive instant alerts for spikes in transaction volume, suspicious activities, or behavioral anomalies.",
      image: "/assets/images/zero-predict/alerts-notifications.webp",
    },
    {
      title: "Proactive Response Automation",
      description: 
        "Automatically block or freeze suspicious accounts, eliminating the need for human intervention during critical security events.",
      image: "/assets/images/zero-predict/proactive-response.webp",
    },
    {
      title: "Comprehensive Analytics & Reporting",
      description: 
        "Access analytics and visual reports to analyze transaction patterns, track fraud attempts, and measure platform security metrics.",
      image: "/assets/images/zero-predict/comprehensive-analytics.webp",
    }
  ];

  export const TechnicalCapabilities = [
    {
      title: "Microservices-Based Architecture",
      description: 
        "Built on microservices to ensure scalable, modular, and independent components that can be upgraded seamlessly.",
      image: "/assets/images/zero-predict/microservices-architecture.webp",
    },
    {
      title: "Machine Learning Engine",
      description: 
        "A dynamic ML engine continuously retrains on incoming blockchain data, improving accuracy for fraud detection.",
      image: "/assets/images/zero-predict/ml-engine.webp",
    },
    {
      title: "Event-Driven Architecture",
      description: 
        "Event-based triggers facilitate real-time responses to fraud signals and alert administrators instantly.",
      image: "/assets/images/zero-predict/event-driven-architecture.webp",
    },
    {
      title: "API-First Integration",
      description: 
        "Zero-Predict integrates into existing blockchain infrastructures, fintech platforms, and crypto exchanges through easy-to-use APIs.",
      image: "/assets/images/zero-predict/api-first-integration.webp",
    },
    {
      title: "Cloud-Native Scalability",
      description: 
        "Supports AWS, Azure, and GCP for global reach, disaster recovery, and failover protection.",
      image: "/assets/images/zero-predict/cloud-scalability.webp",
    },
    {
      title: "Multi-Chain Blockchain Support",
      description: 
        "Supports multiple blockchain protocols like Ethereum, Solana, and Polygon, ensuring compatibility with DeFi protocols and exchanges.",
      image: "/assets/images/zero-predict/blockchain-support.webp",
    }
  ];
  

  export const KeyBenefits = [
    {
      title: "Proactive Threat Detection",
      description: 
        "Eliminate reactive approaches to fraud with proactive detection of threats before they can impact users.",
      image: "/assets/images/zero-predict/threat-detection.webp",
    },
    {
      title: "Automated Response System",
      description: 
        "Automate response actions, including account freezes and transaction reversals, reducing the need for manual intervention.",
      image: "/assets/images/zero-predict/automated-response.webp",
    },
    {
      title: "Seamless API Integration",
      description: 
        "Plug and play into your existing blockchain ecosystem, whether for exchanges, DeFi protocols, or enterprise applications.",
      image: "/assets/images/zero-predict/api-integration.webp",
    },
    {
      title: "Regulatory Compliance",
      description: 
        "Achieve compliance with GDPR, KYC, and AML through integrated identity verification and audit trails.",
      image: "/assets/images/zero-predict/compliance.webp",
    },
    {
      title: "Reduced Fraud-Related Losses",
      description: 
        "Reduce financial loss by blocking fraudulent activities in real-time, preventing theft, scams, and false claims.",
      image: "/assets/images/zero-predict/fraud-loss-prevention.webp",
    },
    {
      title: "Regulatory Audit Trails",
      description: 
        "Audit and review compliance activities with full audit trails of system actions and security events.",
      image: "/assets/images/zero-predict/audit-trails.webp",
    }
  ];

  export const UseCases = [
    {
      title: "Crypto Exchanges",
      description: 
        "Monitor blockchain transactions to prevent fraudulent withdrawals and detect illicit behavior in real time.",
      image: "/assets/images/zero-predict/crypto-exchanges.webp",
    },
    {
      title: "DeFi Protocols",
      description: 
        "Track transaction anomalies in DeFi ecosystems to prevent flash loan attacks, rug pulls, and liquidity drain scams.",
      image: "/assets/images/zero-predict/defi-protocols.webp",
    },
    {
      title: "Enterprise Blockchain Networks",
      description: 
        "Detect suspicious activities within blockchain-based supply chains and enterprise blockchain applications.",
      image: "/assets/images/zero-predict/enterprise-networks.webp",
    },
    {
      title: "Financial Institutions",
      description: 
        "Banks and fintechs secure cross-border blockchain payments and detect illicit transfers through KYC/AML compliance.",
      image: "/assets/images/zero-predict/financial-institutions.webp",
    },
    {
      title: "Tokenised Asset Platforms",
      description: 
        "Monitor suspicious transactions for tokenised assets, preventing wash trading, token manipulation, and fraud in tokenisation platforms.",
      image: "/assets/images/zero-predict/tokenised-assets.webp",
    },
    {
      title: "Gaming & NFT Marketplaces",
      description: 
        "Detect unusual activities, fraudulent transactions, and asset flipping in NFT marketplaces and blockchain-based games.",
      image: "/assets/images/zero-predict/gaming-nft-marketplace.webp",
    }
  ];
  
  export const SecurityAndCompliance = [
    {
      title: "ISO 27001 Certification",
      description: 
        "Zero-Predict is ISO 27001 certified, adhering to global information security standards.",
      image: "/assets/images/zero-predict/iso-27001.webp",
    },
    {
      title: "KYC/AML Compliance",
      description: 
        "Perform identity verification and anti-money laundering checks to comply with global financial regulations.",
      image: "/assets/images/zero-predict/kyc-aml-compliance.webp",
    },
    {
      title: "AES-256 Encryption",
      description: 
        "Secure transaction data with industry-standard AES-256 encryption to protect sensitive information.",
      image: "/assets/images/zero-predict/aes-256-encryption.webp",
    },
    {
      title: "Audit Trails & Logging",
      description: 
        "Maintain a full history of all system actions, providing a comprehensive audit trail for compliance purposes.",
      image: "/assets/images/zero-predict/audit-trail.webp",
    },
    {
      title: "Role-Based Access Control (RBAC)",
      description: 
        "Grant system access to users based on their roles and permissions, reducing the risk of unauthorised access.",
      image: "/assets/images/zero-predict/rbac.webp",
    },
    {
      title: "Data Privacy & GDPR Compliance",
      description: 
        "Comply with GDPR requirements by enabling user data protection and privacy rights through secure data minimisation techniques.",
      image: "/assets/images/zero-predict/gdpr-compliance.webp",
    }
  ];

  export const BusinessBenefits = [
    {
      title: "Fraud Loss Prevention",
      description: 
        "Zero-Predict prevents fraudulent activities in real time, saving businesses millions in potential losses and operational disruptions.",
      image: "/assets/images/zero-predict/fraud-loss-prevention.webp",
    },
    {
      title: "Regulatory Compliance Enablement",
      description: 
        "Achieve and maintain regulatory compliance with built-in KYC, AML, and GDPR adherence, reducing compliance costs and risks.",
      image: "/assets/images/zero-predict/compliance-enablement.webp",
    },
    {
      title: "Enhanced Brand Trust",
      description: 
        "Build customer confidence with proactive fraud prevention measures, ensuring a secure and trustworthy platform.",
      image: "/assets/images/zero-predict/brand-trust.webp",
    },
    {
      title: "Operational Efficiency",
      description: 
        "Reduce the need for manual fraud reviews with automated detection and response systems, saving time and resources.",
      image: "/assets/images/zero-predict/operational-efficiency.webp",
    },
    {
      title: "Increased Platform Engagement",
      description: 
        "Boost user retention and engagement by providing a secure environment for DeFi protocols, exchanges, and blockchain apps.",
      image: "/assets/images/zero-predict/platform-engagement.webp",
    },
    {
      title: "Revenue Growth Through API Monetisation",
      description: 
        "monetise Zero-Predict’s API by offering fraud detection services to fintechs, crypto exchanges, and enterprises.",
      image: "/assets/images/zero-predict/api-revenue-growth.webp",
    }
  ];

  export const FutureEnhancements = [
    {
      title: "Decentralised Identity (DID) Support",
      description: 
        "Integrate decentralised identity (DID) verification for secure, privacy-enhancing identity verification.",
      image: "/assets/images/zero-predict/did-support.webp",
    },
    {
      title: "Predictive Fraud Models",
      description: 
        "Upgrade the ML models to predict potential fraud before it occurs, enabling true proactive fraud detection.",
      image: "/assets/images/zero-predict/predictive-fraud-models.webp",
    },
    {
      title: "Multi-Chain Compatibility",
      description: 
        "Expand support for Solana, Polygon, and other blockchains, making Zero-Predict compatible with all major networks.",
      image: "/assets/images/zero-predict/multi-chain-support.webp",
    },
    {
      title: "Smart Contract Audit Tools",
      description: 
        "Enable automated smart contract auditing for blockchain developers, enhancing the integrity of smart contracts.",
      image: "/assets/images/zero-predict/smart-contract-audit.webp",
    },
    {
      title: "DeFi Liquidity Monitoring",
      description: 
        "Monitor and secure liquidity pools in decentralised finance (DeFi) to prevent flash loan exploits and rug pulls.",
      image: "/assets/images/zero-predict/defi-liquidity-monitoring.webp",
    },
    {
      title: "AI-Enhanced Risk Scoring",
      description: 
        "Use advanced AI scoring models to classify and prioritize risks, ensuring faster responses to high-priority threats.",
      image: "/assets/images/zero-predict/risk-scoring.webp",
    }
  ];

  