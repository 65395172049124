export const CoreFeatureDetails = {
  "Dynamic Premium Adjustments": {
    title: "Dynamic Premium Adjustments",
    description: `
      Leverage real-time IoT data to dynamically adjust insurance premiums, offering fair pricing based on actual risk behavior.
      
      **Key Features:**
      **Real-Time Data Feeds:** IoT devices continuously provide live data for real-time adjustments.
      **Fair Pricing:** Users pay based on actual behavior, promoting safer habits.
      **AI-Powered Analytics:** Machine learning models analyze data to determine appropriate premium rates.
    `,
    image: "/assets/images/zero-insure/dynamic-premium-adjustments.webp"
  },

  "Automated Claims Processing": {
    title: "Automated Claims Processing",
    description: `
      IoT devices trigger smart contracts to automatically process and pay claims, reducing settlement times to minutes.
      
      **Key Features:**
      **Instant Claims:** Claims are processed and paid within minutes of being triggered.
      **IoT-Triggered Smart Contracts:** Claims are validated and settled using blockchain-based automation.
      **Zero Manual Intervention:** Eliminates manual handling, reducing human error and speeding up payouts.
    `,
    image: "/assets/images/zero-insure/automated-claims-processing.webp"
  },

  "Personalised Policy Customisation": {
    title: "Personalised Policy Customisation",
    description: `
      Users can customise insurance coverage by sharing specific data from IoT devices, offering tailored insurance policies.
      
      **Key Features:**
      **User-Controlled Policy Design:** Users define the data they share to influence their policy and premium.
      **Tailored Coverage:** Custom policies based on user preferences and IoT data inputs.
      **Dynamic Adjustments:** Policy terms and premiums can be modified in real-time as user behavior changes.
    `,
    image: "/assets/images/zero-insure/policy-customisation.webp"
  },

  "Fraud Detection and Prevention": {
    title: "Fraud Detection and Prevention",
    description: `
      Blockchain-powered audit trails and IoT-triggered validation prevent fraudulent claims and ensure payment accuracy.
      
      **Key Features:**
      **Immutable Blockchain Ledger:** Verifiable, tamper-proof transaction history prevents claim manipulation.
      **IoT Validation:** IoT devices provide event data to verify claims, reducing the chance of false claims.
      **Anomaly Detection:** AI models analyze claim patterns to identify suspicious activity in real time.
    `,
    image: "/assets/images/zero-insure/fraud-detection.webp"
  },

  "Instant Payouts": {
    title: "Instant Payouts",
    description: `
      Enable instant payouts for claims using blockchain-based settlement, reducing waiting times for policyholders.
      
      **Key Features:**
      **Smart Contract Settlements:** Claims are settled instantly using blockchain-based smart contracts.
      **Real-Time Payouts:** Policyholders receive claim payouts in minutes, not days or weeks.
      **No Human Intervention:** Fully automated payments remove the need for human review or delays.
    `,
    image: "/assets/images/zero-insure/instant-payouts.webp"
  },

  "On-Demand Insurance": {
    title: "On-Demand Insurance",
    description: `
      Allow users to purchase short-term, event-based insurance directly from their device, like 1-day travel insurance.
      
      **Key Features:**
      **Short-Term Policies:** Event-driven insurance, such as 1-day travel or event insurance.
      **Mobile-First Experience:** Purchase and activate coverage directly from a smartphone.
      **Usage-Based Premiums:** Only pay for the time period or event that is insured, maximising cost savings.
    `,
    image: "/assets/images/zero-insure/on-demand-insurance.webp"
  }
};


export const TechnicalCapabilitiesDetails = {
  "IoT Device Integration": {
    title: "IoT Device Integration",
    description: `
      Integrates with smart home devices, wearables, and connected vehicles to collect real-time data for policy adjustments.
      
      **Key Features:**
      **Smart Device Sync:** Connects to wearables, smart home systems, and connected cars for seamless data flow.
      **Real-Time Risk Assessment:** Live data from IoT devices informs dynamic policy adjustments.
      **Usage-Based Insurance:** Leverages usage data to create pay-as-you-go policies for customers.
    `,
    image: "/assets/images/zero-insure/iot-integration.webp"
  },

  "Blockchain-Powered Claims": {
    title: "Blockchain-Powered Claims",
    description: `
      Blockchain ensures immutable and transparent records of claims, policy adjustments, and payouts.
      
      **Key Features:**
      **Immutable Records:** Every claim and policy update is stored on an unalterable blockchain ledger.
      **Transparent Process:** Policyholders can view claim status and transaction history in real-time.
      **Fraud Mitigation:** Prevents data tampering and provides a fully auditable claims trail.
    `,
    image: "/assets/images/zero-insure/blockchain-claims.webp"
  },

  "AI-Powered Claims Validation": {
    title: "AI-Powered Claims Validation",
    description: `
      AI analyzes claims based on user-submitted photos, videos, and IoT device data, reducing false claims.
      
      **Key Features:**
      **AI Image Analysis:** Uses machine learning to analyze photos, videos, and IoT sensor data for claim validation.
      **Fraud Detection:** Flags potentially fraudulent claims based on anomaly detection.
      **Faster Processing:** Reduces claim processing times by eliminating manual verification steps.
    `,
    image: "/assets/images/zero-insure/ai-claims-validation.webp"
  },

  "Event-Driven Smart Contracts": {
    title: "Event-Driven Smart Contracts",
    description: `
      Trigger smart contracts in response to IoT events, automating policy changes and claims processing in real-time.
      
      **Key Features:**
      **Event-Based Automation:** Triggers policy changes and claim payouts when IoT devices detect an event.
      **Real-Time Policy Updates:** Policies update dynamically in response to triggers from IoT devices.
      **Seamless Claims Processing:** Claims are automatically approved or rejected based on pre-set event criteria.
    `,
    image: "/assets/images/zero-insure/smart-contracts.webp"
  },

  "Multi-Cloud Deployment": {
    title: "Multi-Cloud Deployment",
    description: `
      Seamless migration of the platform across AWS, Azure, and GCP to prevent cloud service downtime.
      
      **Key Features:**
      **Cloud Agnostic:** Supports deployment on AWS, Azure, and GCP to avoid service dependency.
      **High Availability:** Redundancy across multiple cloud providers ensures continuous uptime.
      **Disaster Recovery:** Rapid failover and disaster recovery procedures ensure service continuity.
    `,
    image: "/assets/images/zero-insure/multi-cloud-deployment.webp"
  },

  "On-Chain Policy Storage": {
    title: "On-Chain Policy Storage",
    description: `
      Store policy data directly on the blockchain for full transparency and instant access by policyholders.
      
      **Key Features:**
      **Immutable Storage:** Policy data is permanently stored on the blockchain for verification and auditing.
      **Instant Access:** Policyholders and insurers can access and review policy details at any time.
      **Data Integrity:** Eliminates discrepancies in policy documents by maintaining a single source of truth.
    `,
    image: "/assets/images/zero-insure/on-chain-policy-storage.webp"
  }
};

export const KeyBenefitsDetails = {
  "Hyper-Personalised Insurance": {
    title: "Hyper-Personalised Insurance",
    description: `
      Offer personalised coverage and pricing based on the user's real-world behavior and usage data.
      
      **Key Features:**
      **Usage-Based Pricing:** Premiums are calculated using real-time behavioral data from IoT devices.
      **Customised Coverage:** Policyholders receive policies that adapt to their unique habits and activities.
      **Data-Driven Adjustments:** Real-time updates to policies as user behavior changes, like driving habits or health metrics.
    `,
    image: "/assets/images/zero-insure/hyper-personalisation.webp"
  },

  "Faster Claims Settlements": {
    title: "Faster Claims Settlements",
    description: `
      Leverage smart contracts and IoT device triggers to settle claims in minutes instead of days or weeks.
      
      **Key Features:**
      **Smart Contract Automation:** Claims are processed and settled automatically through blockchain-based contracts.
      **IoT-Driven Claims:** Claims are triggered by IoT sensors (e.g., vehicle crash sensors) for instant processing.
      **Instant Payments:** Claims are paid out in minutes, offering unparalleled speed in insurance settlements.
    `,
    image: "/assets/images/zero-insure/fast-claims-settlement.webp"
  },

  "Reduced Claims Leakage": {
    title: "Reduced Claims Leakage",
    description: `
      AI fraud detection ensures claims are authentic, reducing the overall loss ratio for insurers.
      
      **Key Features:**
      **AI Fraud Detection:** Identify false claims and anomalies using AI models trained on vast data sets.
      **Reduced Loss Ratio:** Lower insurer losses by ensuring only authentic claims are paid.
      **Fraud Prevention Alerts:** Get alerts when unusual patterns in claims are detected.
    `,
    image: "/assets/images/zero-insure/claims-leakage.webp"
  },

  "Customer Self-Service Portal": {
    title: "Customer Self-Service Portal",
    description: `
      Policyholders can adjust policies, file claims, and track status updates from a single self-service portal.
      
      **Key Features:**
      **Policy Management:** Policyholders can update policy details, beneficiaries, and coverage levels.
      **Claims Tracking:** Users can submit, track, and view claims in real-time.
      **Instant Self-Service:** Customers no longer need to call agents, saving time and improving customer experience.
    `,
    image: "/assets/images/zero-insure/self-service-portal.webp"
  },

  "Usage-Based Cost Savings": {
    title: "Usage-Based Cost Savings",
    description: `
      Customers pay for what they use, leading to fairer pricing, especially for driving, health, and travel insurance.
      
      **Key Features:**
      **Pay-as-You-Go Model:** Premiums are based on customer activity, like miles driven or time used.
      **Cost Savings:** Policyholders only pay for the coverage they actually use.
      **Adaptable Coverage:** Policy coverage dynamically adjusts as user behavior changes, like travel days or driving distance.
    `,
    image: "/assets/images/zero-insure/usage-based-savings.webp"
  },

  "Insurance-as-a-Service (IaaS)": {
    title: "Insurance-as-a-Service (IaaS)",
    description: `
      White-label the Zero-Insure platform, allowing other insurers to launch branded insurance solutions.
      
      **Key Features:**
      **White-Label Platform:** Offer other insurers a turnkey insurance platform with branding options.
      **Rapid Market Entry:** Insurers can quickly launch new insurance products with Zero-Insure's pre-built modules.
      **Custom Branding:** Clients can customise the platform to match their brand identity and customer experience.
    `,
    image: "/assets/images/zero-insure/iaas-offering.webp"
  }
};


export const UseCasesDetails = {
  "Usage-Based Insurance (UBI)": {
    title: "Usage-Based Insurance (UBI)",
    description: `
      Insurance pricing is based on actual usage, such as driving behavior captured by vehicle telematics.
      
      **Key Features:**
      **Telematics-Driven Pricing:** Premiums are calculated based on driving data (speed, braking, time of day, etc.).
      **Fair Pricing:** Policyholders pay for actual usage instead of estimated risk.
      **Usage-Based Policy Adjustments:** Rates adjust in real-time according to driver habits, offering a pay-as-you-drive model.
    `,
    image: "/assets/images/zero-insure/ubi.webp"
  },

  "Health & Wellness Insurance": {
    title: "Health & Wellness Insurance",
    description: `
      Wearable devices track user health data to offer personalised health insurance coverage and adjust premiums.
      
      **Key Features:**
      **Wearable Device Integration:** Data from fitness trackers and health wearables drives policy adjustments.
      **Customised Health Coverage:** Policies adapt based on health data, encouraging healthier living.
      **Dynamic Premium Adjustments:** Health-conscious users pay lower premiums as health goals are achieved.
    `,
    image: "/assets/images/zero-insure/health-insurance.webp"
  },

  "Gadget & Electronics Insurance": {
    title: "Gadget & Electronics Insurance",
    description: `
      Leverage smart home IoT devices to monitor and issue claims for electronics, ensuring coverage for theft or damage.
      
      **Key Features:**
      **IoT Device Monitoring:** Real-time tracking of electronics using connected smart home devices.
      **Theft & Damage Protection:** Coverage extends to theft, damage, and power surges affecting electronics.
      **Instant Claims Payouts:** Policyholders can receive payouts within minutes when IoT data confirms the claim.
    `,
    image: "/assets/images/zero-insure/gadget-insurance.webp"
  },

  "Pet Insurance": {
    title: "Pet Insurance",
    description: `
      IoT-enabled pet collars track pet health, enabling tailored pet insurance coverage and automatic claims for vet bills.
      
      **Key Features:**
      **IoT-Enabled Pet Collars:** Tracks pet health, activity, and location, enabling real-time data-driven policy adjustments.
      **Customised Coverage:** Policies adjust based on pet health data and activity levels.
      **Instant Vet Bill Reimbursement:** Claims for vet visits are processed automatically when IoT data confirms treatment.
    `,
    image: "/assets/images/zero-insure/pet-insurance.webp"
  },

  "Travel Disruption Insurance": {
    title: "Travel Disruption Insurance",
    description: `
      Real-time flight cancellation or delay data triggers automatic payouts for travel insurance policyholders.
      
      **Key Features:**
      **Real-Time Flight Data:** Automatically triggers claims based on live flight data from airlines.
      **Automatic Payouts:** No need for users to submit claims – payouts are automatically processed when delays are detected.
      **Short-Term Coverage:** Cover specific flights, routes, or travel dates with flexible short-term policies.
    `,
    image: "/assets/images/zero-insure/travel-insurance.webp"
  },

  "On-Demand Event Insurance": {
    title: "On-Demand Event Insurance",
    description: `
      Offer insurance for specific events (like concerts or conferences) with short-term, flexible coverage options.
      
      **Key Features:**
      **Event-Based Coverage:** Users can purchase insurance for events like concerts, weddings, and conferences.
      **Short-Term Policies:** Flexible policies for events lasting from a few hours to several days.
      **Fast Claims Processing:** Claims are settled instantly using event data like ticket cancellations or event postponements.
    `,
    image: "/assets/images/zero-insure/event-insurance.webp"
  }
};



export const SecurityAndComplianceDetails = {
  "GDPR & ISO 27001 Compliance": {
    title: "GDPR & ISO 27001 Compliance",
    description: `
      Zero-Insure complies with GDPR and ISO 27001 to ensure global data privacy, security, and protection standards.
      
      **Key Features:**
      **Global Privacy Compliance:** Adheres to GDPR and ISO 27001, safeguarding user data across all regions.
      **Data Access Controls:** Provides customers with rights to view, correct, and delete their personal data.
      **Secure Data Handling:** Ensures best practices for data collection, storage, and processing as per ISO 27001 standards.
    `,
    image: "/assets/images/zero-insure/gdpr-compliance.webp"
  },

  "Blockchain Ledger": {
    title: "Blockchain Ledger",
    description: `
      Immutable records of claims, policy changes, and payments are stored on a blockchain, ensuring data integrity.
      
      **Key Features:**
      **Immutable Records:** All claim, payment, and policy adjustments are stored on a tamper-proof blockchain ledger.
      **Data Integrity:** Ensures that policyholders, insurers, and regulators can trust the authenticity of all transactions.
      **Decentralised Storage:** Blockchain distributes data across multiple nodes, eliminating the risk of central point failure.
    `,
    image: "/assets/images/zero-insure/blockchain-ledger.webp"
  },

  "Data Encryption & Secure API": {
    title: "Data Encryption & Secure API",
    description: `
      Data is encrypted using AES-256, while secure APIs ensure data privacy and protection against cyber threats.
      
      **Key Features:**
      **AES-256 Encryption:** Ensures end-to-end encryption for sensitive customer data during storage and transmission.
      **Secure API Gateway:** Uses HTTPS and API keys to secure communication with third-party services.
      **Cyber Threat Protection:** Safeguards data from man-in-the-middle (MITM) and SQL injection attacks.
    `,
    image: "/assets/images/zero-insure/data-encryption.webp"
  },

  "Audit Trail & Reporting": {
    title: "Audit Trail & Reporting",
    description: `
      Maintain an immutable audit trail of all policy and claim events, simplifying compliance and regulatory reporting.
      
      **Key Features:**
      **Immutable Audit Logs:** Tracks every action related to claims, policy changes, and payouts.
      **Regulatory Reporting:** Produces audit-ready reports for regulators, ensuring transparency and accountability.
      **Tamper-Proof Evidence:** Ensures compliance with financial regulations and insurance industry standards.
    `,
    image: "/assets/images/zero-insure/audit-trail.webp"
  },

  "Role-Based Access Control (RBAC)": {
    title: "Role-Based Access Control (RBAC)",
    description: `
      Ensure only authorised personnel have access to specific insurance data and claims, reducing internal fraud risk.
      
      **Key Features:**
      **Role-Specific Permissions:** Control which employees can access claims, policies, and payment data.
      **Multi-Factor Authentication (MFA):** Adds an additional security layer to user access with MFA.
      **Access Logs & Alerts:** Monitors role-based access and triggers alerts for suspicious activities.
    `,
    image: "/assets/images/zero-insure/rbac-control.webp"
  },

  "Fraud Detection Systems": {
    title: "Fraud Detection Systems",
    description: `
      Advanced AI systems monitor for fraudulent claim behavior in real-time, ensuring policyholder trust and reducing leakage.
      
      **Key Features:**
      **AI-Powered Fraud Detection:** Uses AI to identify anomalies and flag potentially fraudulent claims.
      **Real-Time Monitoring:** Tracks suspicious activity as it happens, allowing for real-time intervention.
      **Reduced Claims Leakage:** Minimises financial losses due to fraudulent claims and increases insurer profitability.
    `,
    image: "/assets/images/zero-insure/fraud-detection-system.webp"
  }
};


export const BusinessBenefitsDetails = {
  "Revenue Growth Opportunities": {
    title: "Revenue Growth Opportunities",
    description: `
      Drive new revenue streams with dynamic premium pricing, on-demand insurance, and Insurance-as-a-Service (IaaS) offerings.
      
      **Key Features:**
      **On-Demand Insurance:** monetise event-based and short-term insurance policies.
      **Dynamic Premium Pricing:** Use real-time IoT data to offer usage-based insurance models.
      **Insurance-as-a-Service (IaaS):** White-label the Zero-Insure platform for third-party insurers to launch branded insurance.
    `,
    image: "/assets/images/zero-insure/revenue-growth-opportunities.webp"
  },

  "Operational Cost Reduction": {
    title: "Operational Cost Reduction",
    description: `
      Reduce operational expenses with automated claims, IoT-driven risk assessments, and predictive maintenance of policies.
      
      **Key Features:**
      **Automated Claims Processing:** Reduce reliance on human claims adjusters with IoT-triggered smart contracts.
      **IoT-Driven Risk Assessment:** Collect real-time usage data to preempt potential claims, lowering claims frequency.
      **Predictive Maintenance:** Forecast and prevent failures for insured devices and electronics.
    `,
    image: "/assets/images/zero-insure/operational-cost-reduction.webp"
  },

  "Customer-Centric Offerings": {
    title: "Customer-Centric Offerings",
    description: `
      Deliver hyper-personalised insurance experiences with usage-based policies, self-service portals, and on-demand coverage.
      
      **Key Features:**
      **Usage-Based Policies (UBI):** Adjust policy premiums based on real-world usage data.
      **Self-Service Portals:** Customers can manage policies, update coverage, and file claims independently.
      **On-Demand Insurance:** Offer customers short-term insurance options like daily, hourly, or event-based policies.
    `,
    image: "/assets/images/zero-insure/customer-centric-offerings.webp"
  },

  "Faster Time-to-Market": {
    title: "Faster Time-to-Market",
    description: `
      Accelerate product launches with white-label IaaS models and rapid deployment via a cloud-native architecture.
      
      **Key Features:**
      **Insurance-as-a-Service (IaaS):** Pre-built platform that insurers can white-label to launch new products quickly.
      **Rapid Product Deployment:** Launch new insurance products rapidly with modular, plug-and-play capabilities.
      **Cloud-Native Flexibility:** Zero-Insure is cloud-native, allowing seamless updates and version rollouts.
    `,
    image: "/assets/images/zero-insure/faster-time-to-market.webp"
  },

  "Enhanced Fraud Mitigation": {
    title: "Enhanced Fraud Mitigation",
    description: `
      Leverage blockchain audit trails and AI-driven fraud detection to prevent fraudulent claims and reduce loss ratios.
      
      **Key Features:**
      **AI-Powered Fraud Detection:** Identify fraudulent claims in real-time using AI models.
      **Blockchain Audit Trails:** Immutable records of policy changes, payments, and claims create a tamper-proof audit trail.
      **Anomaly Detection:** Identify unusual claim patterns using machine learning models.
    `,
    image: "/assets/images/zero-insure/fraud-mitigation.webp"
  },

  "Data-Driven Decision Making": {
    title: "Data-Driven Decision Making",
    description: `
      Enable insurers to make informed business decisions with predictive analytics, customer behavior insights, and real-time IoT data.
      
      **Key Features:**
      **Predictive Claims Analytics:** Forecast claims trends and identify policyholder risk early.
      **Customer Behavior Insights:** Use IoT device data to assess customer risk behavior and adjust premiums.
      **Real-Time Analytics Dashboards:** View insights and operational metrics in real time.
    `,
    image: "/assets/images/zero-insure/data-driven-decisions.webp"
  }
};


export const FutureEnhancementsDetails = {
  "AI-Powered Risk Forecasting": {
    title: "AI-Powered Risk Forecasting",
    description: `
      AI-driven forecasting models will predict potential claims and risk factors, enabling proactive risk mitigation.
      
      **Key Features:**
      **Risk Anticipation:** Forecast claims before they happen, allowing insurers to prepare mitigation strategies.
      **AI-Driven Models:** Leverages historical claims data and real-time IoT inputs for future predictions.
      **Proactive Risk Reduction:** Helps insurers manage risk exposure before it becomes a liability.
    `,
    image: "/assets/images/zero-insure/ai-forecasting.webp"
  },

  "Voice-Activated Claims": {
    title: "Voice-Activated Claims",
    description: `
      Enable policyholders to file claims via voice commands, simplifying the claims process and enhancing user experience.
      
      **Key Features:**
      **Voice-Driven Interface:** Allows users to file claims and request information via voice commands.
      **Hands-Free Claims Filing:** Simplifies the claims process, especially for policyholders on the go.
      **Natural Language Processing (NLP):** Uses NLP to understand and process user voice requests accurately.
    `,
    image: "/assets/images/zero-insure/voice-claims.webp"
  },

  "Cross-Platform IoT Compatibility": {
    title: "Cross-Platform IoT Compatibility",
    description: `
      Enhance compatibility with more IoT devices, enabling data collection from new wearable tech and connected cars.
      
      **Key Features:**
      **Broader IoT Integration:** Support for more smart devices, including wearables, smart cars, and home devices.
      **Data-Driven Policy Adjustments:** Use IoT data to dynamically adjust coverage and premiums.
      **Real-Time IoT Syncing:** Connects policyholder devices for up-to-date risk and usage data.
    `,
    image: "/assets/images/zero-insure/cross-platform.webp"
  },

  "Predictive Analytics for Claims": {
    title: "Predictive Analytics for Claims",
    description: `
      AI models analyze past claims data to predict potential risks and claims, offering insurers better risk visibility.
      
      **Key Features:**
      **Advanced Claims Analysis:** Analyzes past claims to identify future claim patterns.
      **Predictive Risk Assessment:** Identifies potential high-risk policyholders before claims are made.
      **Enhanced Risk Visibility:** Empowers insurers to see patterns in claims and prepare accordingly.
    `,
    image: "/assets/images/zero-insure/predictive-claims.webp"
  },

  "Insurance Gamification": {
    title: "Insurance Gamification",
    description: `
      Introduce gamification elements to reward users for healthy behavior, driving engagement and reduced premiums.
      
      **Key Features:**
      **Reward Systems:** Users earn rewards for healthy habits like safe driving or regular health checkups.
      **Behavioral Incentives:** Encourage positive behavior that lowers risk and premiums.
      **User Engagement:** Increase customer engagement with leaderboards, points, and rewards.
    `,
    image: "/assets/images/zero-insure/gamification.webp"
  },

  "Enhanced Mobile Application": {
    title: "Enhanced Mobile Application",
    description: `
      Develop a new mobile app version with biometric login, claim tracking, and self-service policy customisation options.
      
      **Key Features:**
      **Biometric Authentication:** Supports facial recognition and fingerprint login for easy and secure access.
      **Claim Tracking:** Policyholders can view and track the status of ongoing claims in real time.
      **Self-Service Customisation:** Users can update policy preferences and customise coverage directly from the app.
    `,
    image: "/assets/images/zero-insure/enhanced-mobile-app.webp"
  }
};
