export const CoreFeatureDetails = {
  "Real-Time Fraud Detection": {
    title: "Real-Time Fraud Detection",
    description: `
      Zero-Predict leverages advanced predictive algorithms and continuous monitoring to identify and halt fraudulent activities instantly, crucial for high-speed blockchain transactions.
      
      **Key Features:**
      **Predictive Analytics:** Detect fraud before it impacts your platform.
      **Instant Alerts:** Real-time notifications for suspicious activities.
      **Blockchain-Specific Detection:** Optimised for blockchain transactions and smart contracts.
      **Scalable Monitoring:** Handles high transaction volumes without performance degradation.
    `,
    image: "/assets/images/zero-predict/real-time-fraud-detection.webp",
  },
  "Machine Learning & Pattern Recognition": {
    title: "Machine Learning & Pattern Recognition",
    description: `
      Stay ahead of emerging threats with self-learning ML models that continuously adapt to detect and prevent sophisticated fraud schemes.
      
      **Key Features:**
      **Self-Learning Models:** Continuously evolves to detect new patterns.
      **Behavioral Analytics:** Analyze transaction behavior to identify anomalies.
      **Real-Time Updates:** Models improve automatically with new data.
      **Threat Mitigation:** Detect and prevent even the most subtle fraud attempts.
    `,
    image: "/assets/images/zero-predict/machine-learning-patterns.webp",
  },
  "Advanced Anomaly Detection": {
    title: "Advanced Anomaly Detection",
    description: `
      AI-powered anomaly detection identifies unusual transaction behaviors in real time, ensuring proactive responses to potential threats.
      
      **Key Features:**
      **Behavioral Pattern Recognition:** Flag irregularities in transaction flows.
      **Threshold-Based Alerts:** Notify users of anomalies exceeding predefined limits.
      **Comprehensive Analysis:** Combine historical and live data for precise detection.
      **Customisable Detection Parameters:** Tailor detection thresholds to your needs.
    `,
    image: "/assets/images/zero-predict/anomaly-detection.webp",
  },
  "Customisable Alerts & Notifications": {
    title: "Customisable Alerts & Notifications",
    description: `
      Stay informed with real-time alerts for unusual activities, allowing immediate action to secure your blockchain platform.
      
      **Key Features:**
      **Tailored Alert Configurations:** Set alerts for specific transaction thresholds.
      **Multi-Channel Notifications:** Receive alerts via email, SMS, or app.
      **Real-Time Monitoring:** Instantaneous updates for all flagged activities.
      **Actionable Insights:** Alerts include recommendations for immediate response.
    `,
    image: "/assets/images/zero-predict/alerts-notifications.webp",
  },
  "Proactive Response Automation": {
    title: "Proactive Response Automation",
    description: `
      Automate responses to fraud attempts, such as freezing suspicious accounts, to ensure swift and effective action without manual intervention.
      
      **Key Features:**
      **Automated Account Freezing:** Block fraudulent accounts immediately.
      **Transaction Rollback:** Reverse suspicious transactions in real time.
      **Custom Rules Engine:** Define automated responses for specific threat types.
      **24/7 Protection:** Ensures continuous security without human oversight.
    `,
    image: "/assets/images/zero-predict/proactive-response.webp",
  },
  "Comprehensive Analytics & Reporting": {
    title: "Comprehensive Analytics & Reporting",
    description: `
      Gain deep insights into transaction behaviors, fraud patterns, and platform security with advanced analytics and visual reporting tools.
      
      **Key Features:**
      **Transaction Heatmaps:** Visualise activity patterns across your platform.
      **Fraud Metrics Dashboard:** Monitor attempts, block rates, and resolution times.
      **Exportable Reports:** Generate compliance-ready reports for regulatory needs.
      **Custom Analytics:** Create bespoke analytics dashboards for detailed insights.
    `,
    image: "/assets/images/zero-predict/comprehensive-analytics.webp",
  }
};

export const TechnicalCapabilitiesDetails = {
  "Microservices-Based Architecture": {
    title: "Microservices-Based Architecture",
    description: `
      Built on a modern microservices architecture to ensure the scalability, flexibility, and resilience of the Zero-Predict platform.

      **Key Features:**
      **Modular Design:** Independent services allow isolated updates without affecting the entire system.
      **High Availability:** Microservices architecture ensures uptime through load balancing and fault tolerance.
      **Seamless Scaling:** Scale specific services on-demand to meet workload requirements.
      **Efficient Resource Utilisation:** Optimise performance by allocating resources to critical services dynamically.
    `,
    image: "/assets/images/zero-predict/microservices-architecture.webp",
  },

  "Machine Learning Engine": {
    title: "Machine Learning Engine",
    description: `
      A dynamic machine learning engine that continuously evolves to identify new fraud patterns and optimise detection accuracy.

      **Key Features:**
      **Continuous Training:** ML models retrain using real-time blockchain data to adapt to emerging threats.
      **Anomaly Detection Models:** Advanced models analyze deviations in transaction behavior.
      **Predictive Accuracy:** Leverages supervised and unsupervised learning to improve fraud prediction rates.
      **Threat Adaptation:** Quickly recognises new fraud vectors as they arise in the blockchain ecosystem.
    `,
    image: "/assets/images/zero-predict/ml-engine.webp",
  },

  "Event-Driven Architecture": {
    title: "Event-Driven Architecture",
    description: `
      Event-based triggers power real-time fraud detection and automated responses, ensuring swift action.

      **Key Features:**
      **Real-Time Event Processing:** Immediate detection of anomalies or suspicious activities.
      **Streamlined Alerts:** Administrators are instantly notified of critical events with actionable insights.
      **Low Latency Response:** Faster fraud detection and mitigation through optimised event triggers.
      **Scalable Processing:** Efficiently handles high transaction volumes in blockchain ecosystems.
    `,
    image: "/assets/images/zero-predict/event-driven-architecture.webp",
  },

  "API-First Integration": {
    title: "API-First Integration",
    description: `
      Simplify integration with blockchain systems, exchanges, and fintech platforms through an API-first approach.

      **Key Features:**
      **Developer-Friendly APIs:** Comprehensive documentation and SDKs enable seamless integration.
      **Customisable Endpoints:** Tailor APIs to fit specific use cases and fraud prevention requirements.
      **Fast Deployment:** Quickly embed fraud detection into existing systems with minimal development time.
      **Secure Access:** Enforce API key authentication and role-based permissions for safe integration.
    `,
    image: "/assets/images/zero-predict/api-first-integration.webp",
  },

  "Cloud-Native Scalability": {
    title: "Cloud-Native Scalability",
    description: `
      Leverage cloud-native architecture for global reach, high availability, and disaster recovery.

      **Key Features:**
      **Global Distribution:** Deploy on AWS, Azure, or GCP for seamless regional availability.
      **Auto-Scaling:** Automatically adjusts resources to meet varying traffic loads.
      **Disaster Recovery:** Built-in failover and data redundancy ensure zero downtime.
      **Cost Efficiency:** Pay-as-you-go model optimises costs during peak and low usage times.
    `,
    image: "/assets/images/zero-predict/cloud-scalability.webp",
  },

  "Multi-Chain Blockchain Support": {
    title: "Multi-Chain Blockchain Support",
    description: `
      Ensure compatibility across major blockchain networks to support a diverse range of decentralised applications (dApps).

      **Key Features:**
      **Multi-Protocol Support:** Compatible with Ethereum, Solana, Polygon, and other popular blockchains.
      **Seamless Interoperability:** Easily integrate with DeFi protocols, exchanges, and tokenisation platforms.
      **Scalable Solutions:** Handle varying transaction volumes across blockchains with high efficiency.
      **Future-Ready:** Adaptable to new blockchain protocols as the ecosystem evolves.
    `,
    image: "/assets/images/zero-predict/blockchain-support.webp",
  },
};


export const KeyBenefitsDetails = {
  "Proactive Threat Detection": {
    title: "Proactive Threat Detection",
    description: `
      Eliminate reactive approaches to fraud with proactive detection, identifying threats before they can impact users or systems.
      
      **Key Features:**
      **Real-Time Threat Identification:** Detect anomalies and potential fraud instantly.
      **Machine Learning Models:** Predict potential fraudulent activities based on historical data.
      **Early Warning Alerts:** Receive notifications about potential risks before they escalate.
      **Enhanced Security Measures:** Proactively secure assets and transactions.
    `,
    image: "/assets/images/zero-predict/threat-detection.webp",
  },
  "Automated Response System": {
    title: "Automated Response System",
    description: `
      Automate responses to security threats, reducing manual intervention and ensuring rapid resolution of fraud incidents.
      
      **Key Features:**
      **Automated Account Freezing:** Instantly halt suspicious activity.
      **Real-Time Transaction Reversal:** Undo fraudulent transactions automatically.
      **Customisable Rules Engine:** Define automated responses for specific threats.
      **Reduced Downtime:** Maintain platform integrity without disrupting operations.
    `,
    image: "/assets/images/zero-predict/automated-response.webp",
  },
  "Seamless API Integration": {
    title: "Seamless API Integration",
    description: `
      Easily integrate Zero-Predict's fraud detection capabilities into your existing blockchain ecosystem, enabling smooth and efficient operations.
      
      **Key Features:**
      **Plug-and-Play Design:** Fast integration with minimal development overhead.
      **Wide Compatibility:** Works with exchanges, DeFi protocols, and enterprise platforms.
      **Customisable Endpoints:** Tailor APIs to suit specific platform requirements.
      **Scalable Integration:** Support for high transaction volumes.
    `,
    image: "/assets/images/zero-predict/api-integration.webp",
  },
  "Regulatory Compliance": {
    title: "Regulatory Compliance",
    description: `
      Achieve full compliance with global regulations, including GDPR, KYC, and AML, ensuring your platform operates within legal frameworks.
      
      **Key Features:**
      **Integrated KYC/AML Checks:** Verify user identities and prevent money laundering.
      **GDPR Adherence:** Ensure data privacy and user control over personal data.
      **Audit Trail Generation:** Maintain transparent records of compliance activities.
      **Cross-Border Regulatory Support:** Adhere to varying international compliance standards.
    `,
    image: "/assets/images/zero-predict/compliance.webp",
  },
  "Reduced Fraud-Related Losses": {
    title: "Reduced Fraud-Related Losses",
    description: `
      Prevent financial losses by blocking fraudulent activities in real-time, ensuring the safety of assets and user trust.
      
      **Key Features:**
      **Real-Time Fraud Prevention:** Stop scams and theft before they occur.
      **Risk Mitigation Tools:** Identify and mitigate high-risk transactions.
      **Cost Savings:** Reduce expenses related to fraud investigations and resolutions.
      **Enhanced Customer Trust:** Build confidence in your platform's security.
    `,
    image: "/assets/images/zero-predict/fraud-loss-prevention.webp",
  },
  "Regulatory Audit Trails": {
    title: "Regulatory Audit Trails",
    description: `
      Maintain detailed audit trails for compliance verification, supporting transparency and accountability.
      
      **Key Features:**
      **Action Logging:** Record every system action and security event.
      **Compliance Monitoring:** Track adherence to regulatory requirements.
      **Exportable Reports:** Generate compliance-ready documents for audits.
      **Data Integrity:** Ensure audit trail immutability for legal purposes.
    `,
    image: "/assets/images/zero-predict/audit-trails.webp",
  }
};


export const UseCasesDetails = {
  "Crypto Exchanges": {
    title: "Crypto Exchanges",
    description: `
      Monitor blockchain transactions on crypto exchanges to prevent fraudulent withdrawals and detect illicit behavior in real time.
      
      **Key Features:**
      **Real-Time Monitoring:** Track transactions as they occur to flag unusual activity.
      **Fraudulent Withdrawal Detection:** Identify and block unauthorised or suspicious withdrawals.
      **Compliance Integration:** Ensure adherence to KYC/AML requirements for exchange transactions.
      **Enhanced User Trust:** Build confidence among users with robust security measures.
    `,
    image: "/assets/images/zero-predict/crypto-exchanges.webp",
  },
  "DeFi Protocols": {
    title: "DeFi Protocols",
    description: `
      Secure DeFi ecosystems by tracking transaction anomalies and preventing flash loan attacks, rug pulls, and liquidity drain scams.
      
      **Key Features:**
      **Flash Loan Detection:** Identify and mitigate flash loan exploits in real time.
      **Liquidity Monitoring:** Prevent unauthorised liquidity withdrawals and drain attempts.
      **Protocol Behavior Analysis:** Detect irregularities in transaction patterns and smart contract activity.
      **Secure Ecosystems:** Foster user trust in DeFi platforms through proactive security.
    `,
    image: "/assets/images/zero-predict/defi-protocols.webp",
  },
  "Enterprise Blockchain Networks": {
    title: "Enterprise Blockchain Networks",
    description: `
      Enhance the security of enterprise blockchain networks by detecting suspicious activities and securing supply chain transactions.
      
      **Key Features:**
      **Anomaly Detection:** Identify deviations in normal transaction behavior.
      **Supply Chain Security:** Monitor and validate transactions within blockchain-based supply chains.
      **Compliance Logging:** Maintain detailed records for regulatory and operational compliance.
      **Scalable Solutions:** Support high-volume enterprise-grade blockchain networks.
    `,
    image: "/assets/images/zero-predict/enterprise-networks.webp",
  },
  "Financial Institutions": {
    title: "Financial Institutions",
    description: `
      Help banks and fintechs secure cross-border blockchain payments and detect illicit transfers through integrated KYC/AML compliance.
      
      **Key Features:**
      **Cross-Border Monitoring:** Secure international payments on blockchain networks.
      **KYC/AML Compliance:** Automate regulatory checks for every transaction.
      **Fraud Detection:** Prevent illicit transfers, scams, and other fraudulent activities.
      **Improved Customer Trust:** Boost user confidence in blockchain-based banking solutions.
    `,
    image: "/assets/images/zero-predict/financial-institutions.webp",
  },
  "Tokenised Asset Platforms": {
    title: "Tokenised Asset Platforms",
    description: `
      Monitor suspicious transactions on tokenised asset platforms, preventing wash trading, token manipulation, and other fraudulent practices.
      
      **Key Features:**
      **Wash Trading Detection:** Identify and block coordinated market manipulation schemes.
      **Fraudulent Token Detection:** Flag unusual patterns in tokenised asset transactions.
      **Platform Compliance:** Ensure regulatory adherence across tokenisation activities.
      **Market Integrity:** Protect the reputation of tokenised asset markets.
    `,
    image: "/assets/images/zero-predict/tokenised-assets.webp",
  },
  "Gaming & NFT Marketplaces": {
    title: "Gaming & NFT Marketplaces",
    description: `
      Detect fraudulent transactions, asset flipping, and other unusual activities in NFT marketplaces and blockchain-based games.
      
      **Key Features:**
      **Marketplace Security:** Prevent asset flipping and fraudulent bidding.
      **Gaming Fraud Detection:** Identify cheating or unauthorised activities in blockchain games.
      **Anomaly Alerts:** Receive instant notifications of unusual transactions.
      **User Trust:** Enhance platform reliability by securing digital assets and transactions.
    `,
    image: "/assets/images/zero-predict/gaming-nft-marketplace.webp",
  }
};


export const SecurityAndComplianceDetails = {
  "ISO 27001 Certification": {
    title: "ISO 27001 Certification",
    description: `
      Zero-Predict ensures global compliance by adhering to the ISO 27001 certification, the gold standard for information security management.
      
      **Key Features:**
      **Risk Management Framework:** Structured policies to manage data security risks effectively.
      **Independent Audits:** Regular assessments by third-party auditors to maintain certification.
      **Operational Security:** Proactive measures to protect against evolving threats.
      **Global Recognition:** Assurance of the highest security standards for users worldwide.
    `,
    image: "/assets/images/zero-predict/iso-27001.webp",
  },
  "KYC/AML Compliance": {
    title: "KYC/AML Compliance",
    description: `
      Perform identity verification and anti-money laundering checks to meet global financial regulations and ensure secure transactions.
      
      **Key Features:**
      **Identity Verification:** Authenticate user identities before granting access to platform services.
      **Fraud Prevention:** Identify and block transactions linked to high-risk users or activities.
      **Real-Time Monitoring:** Monitor transactions for signs of money laundering in real time.
      **Regulatory Adherence:** Ensure full compliance with AML and KYC guidelines across jurisdictions.
    `,
    image: "/assets/images/zero-predict/kyc-aml-compliance.webp",
  },
  "AES-256 Encryption": {
    title: "AES-256 Encryption",
    description: `
      Protect sensitive transaction data with industry-standard AES-256 encryption, ensuring maximum security and privacy.
      
      **Key Features:**
      **Data Confidentiality:** Encrypt all data in transit and at rest using AES-256 protocols.
      **Tamper-Resistance:** Prevent unauthorised access and tampering with sensitive data.
      **End-to-End Encryption:** Ensure secure communication across all transactions and alerts.
      **Standards Compliance:** Adhere to encryption standards required for regulatory compliance.
    `,
    image: "/assets/images/zero-predict/aes-256-encryption.webp",
  },
  "Audit Trails & Logging": {
    title: "Audit Trails & Logging",
    description: `
      Maintain detailed records of all platform activities with comprehensive audit trails for compliance and internal review.
      
      **Key Features:**
      **Comprehensive Logs:** Track every transaction, user action, and system change.
      **Regulatory Oversight:** Provide transparent records to regulators during audits.
      **Fraud Investigation:** Facilitate quick resolution of fraud cases with detailed activity logs.
      **User Accountability:** Ensure traceability of all actions performed on the platform.
    `,
    image: "/assets/images/zero-predict/audit-trail.webp",
  },
  "Role-Based Access Control (RBAC)": {
    title: "Role-Based Access Control (RBAC)",
    description: `
      Secure the platform by granting system access to users based on their roles and permissions, minimising unauthorised activities.
      
      **Key Features:**
      **Granular Permissions:** Assign specific access levels to users depending on their roles.
      **User Segmentation:** Prevent unauthorised users from accessing sensitive data or features.
      **Access Logs:** Record every access attempt for complete accountability.
      **Dynamic Updates:** Quickly adjust permissions to meet changing organisational needs.
    `,
    image: "/assets/images/zero-predict/rbac.webp",
  },
  "Data Privacy & GDPR Compliance": {
    title: "Data Privacy & GDPR Compliance",
    description: `
      Ensure data privacy and protection by adhering to GDPR regulations, securing user rights through advanced data handling techniques.
      
      **Key Features:**
      **Data Minimisation:** Collect only the necessary user data to fulfill services.
      **User Consent:** Obtain clear and explicit consent for data collection and processing.
      **Right to Access:** Allow users to view and control their data on the platform.
      **Secure Deletion:** Permanently erase user data upon request, ensuring compliance with GDPR.
    `,
    image: "/assets/images/zero-predict/gdpr-compliance.webp",
  }
};


export const BusinessBenefitsDetails = {
  "Fraud Loss Prevention": {
    title: "Fraud Loss Prevention",
    description: `
      Zero-Predict minimises financial and operational losses by detecting and blocking fraudulent activities in real time, ensuring business continuity and customer protection.
      
      **Key Features:**
      **Real-Time Fraud Detection:** Prevent fraudulent transactions before they escalate.
      **Cost Savings:** Save millions by reducing financial theft and operational disruptions.
      **Scalable Solution:** Handle increased transaction volumes without compromising security.
      **Industry Coverage:** Suitable for blockchain platforms, DeFi protocols, and exchanges.
    `,
    image: "/assets/images/zero-predict/fraud-loss-prevention.webp",
  },
  "Regulatory Compliance Enablement": {
    title: "Regulatory Compliance Enablement",
    description: `
      Achieve seamless compliance with global regulations, including KYC, AML, and GDPR, reducing legal risks and compliance costs.
      
      **Key Features:**
      **Integrated Compliance Tools:** Ensure adherence to global financial regulations.
      **Cost Efficiency:** Lower compliance costs through automated regulatory checks.
      **Regulatory Reporting:** Simplify audits with comprehensive compliance logs.
      **Global Reach:** Comply with regulations across multiple jurisdictions.
    `,
    image: "/assets/images/zero-predict/compliance-enablement.webp",
  },
  "Enhanced Brand Trust": {
    title: "Enhanced Brand Trust",
    description: `
      Build and maintain customer confidence by showcasing Zero-Predict’s robust fraud prevention measures, creating a secure and reliable user environment.
      
      **Key Features:**
      **Proactive Security Measures:** Reinforce user confidence with visible security enhancements.
      **Customer Retention:** Retain users by providing a fraud-free experience.
      **Market Differentiation:** Stand out in competitive markets with advanced fraud prevention.
      **Reputation Protection:** Prevent reputational damage caused by fraudulent incidents.
    `,
    image: "/assets/images/zero-predict/brand-trust.webp",
  },
  "Operational Efficiency": {
    title: "Operational Efficiency",
    description: `
      Automate fraud detection and response processes to reduce manual workload, enabling businesses to focus on growth and innovation.
      
      **Key Features:**
      **Automated Detection:** Eliminate manual fraud review processes.
      **Resource Optimisation:** Free up human resources for higher-value tasks.
      **Faster Response Times:** Address fraudulent activities instantly.
      **Cost Savings:** Lower operational costs with automated systems.
    `,
    image: "/assets/images/zero-predict/operational-efficiency.webp",
  },
  "Increased Platform Engagement": {
    title: "Increased Platform Engagement",
    description: `
      Provide a secure user experience for DeFi protocols, exchanges, and blockchain applications, increasing user retention and activity.
      
      **Key Features:**
      **Enhanced Security:** Foster trust with a secure transaction environment.
      **User Retention:** Retain users by offering reliable fraud prevention.
      **Scalable Integration:** Adapt Zero-Predict for platforms of all sizes.
      **Revenue Growth:** Increased engagement translates to higher platform activity and revenue.
    `,
    image: "/assets/images/zero-predict/platform-engagement.webp",
  },
  "Revenue Growth Through API Monetisation": {
    title: "Revenue Growth Through API Monetisation",
    description: `
      monetise Zero-Predict’s advanced fraud detection capabilities by offering API access to fintechs, crypto exchanges, and enterprise platforms.
      
      **Key Features:**
      **API Accessibility:** Provide seamless fraud detection integration.
      **Revenue Streams:** Generate income through subscription-based API models.
      **Scalable Monetisation:** Expand client base with scalable API services.
      **Enterprise Partnerships:** Attract high-value enterprise clients for long-term growth.
    `,
    image: "/assets/images/zero-predict/api-revenue-growth.webp",
  }
};


export const FutureEnhancementsDetails = {
  "Decentralised Identity (DID) Support": {
    title: "Decentralised Identity (DID) Support",
    description: `
      Integrate decentralised identity (DID) technology to provide secure and privacy-focused identity verification for blockchain platforms and applications.
      
      **Key Features:**
      **Privacy-Preserving:** Users maintain control over their data and identities.
      **Blockchain-Based IDs:** Leverage blockchain to create tamper-proof identity credentials.
      **Global Compatibility:** Support DID standards for cross-platform verification.
      **Enhanced Security:** Reduce identity fraud through decentralised verification.
    `,
    image: "/assets/images/zero-predict/did-support.webp",
  },
  "Predictive Fraud Models": {
    title: "Predictive Fraud Models",
    description: `
      Upgrade machine learning models to predict potential fraudulent activities before they occur, achieving true proactive fraud prevention.
      
      **Key Features:**
      **Behavior Prediction:** Anticipate fraud based on user behavior and transaction patterns.
      **Real-Time Alerts:** Notify users and platforms of high-risk activities instantly.
      **Adaptive Learning:** Continuously improve models as new threats emerge.
      **Cross-Platform Utility:** Integrate predictive models across exchanges, DeFi, and enterprise blockchains.
    `,
    image: "/assets/images/zero-predict/predictive-fraud-models.webp",
  },
  "Multi-Chain Compatibility": {
    title: "Multi-Chain Compatibility",
    description: `
      Expand Zero-Predict’s fraud detection capabilities to support Solana, Polygon, and other leading blockchain networks for broader ecosystem coverage.
      
      **Key Features:**
      **Cross-Chain Support:** Monitor activities across multiple blockchains simultaneously.
      **Scalable Integration:** Add support for emerging blockchain networks easily.
      **Unified Dashboard:** Centralised monitoring of all blockchain activities.
      **Ecosystem Reach:** Extend fraud prevention to more projects and platforms.
    `,
    image: "/assets/images/zero-predict/multi-chain-support.webp",
  },
  "Smart Contract Audit Tools": {
    title: "Smart Contract Audit Tools",
    description: `
      Enable automated auditing of smart contracts for blockchain developers, ensuring security and integrity of deployed contracts.
      
      **Key Features:**
      **Code Vulnerability Detection:** Identify bugs and vulnerabilities in smart contract code.
      **Automated Reports:** Generate comprehensive audit reports instantly.
      **Security Standards:** Verify compliance with industry security standards.
      **Developer-Friendly:** Provide an easy-to-use interface for contract auditing.
    `,
    image: "/assets/images/zero-predict/smart-contract-audit.webp",
  },
  "DeFi Liquidity Monitoring": {
    title: "DeFi Liquidity Monitoring",
    description: `
      Secure decentralised finance (DeFi) ecosystems by monitoring liquidity pools to prevent exploits such as flash loan attacks and rug pulls.
      
      **Key Features:**
      **Liquidity Tracking:** Monitor liquidity levels in real time.
      **Exploit Prevention:** Identify and block suspicious liquidity movements.
      **DeFi-Specific Analytics:** Provide insights specific to decentralised finance.
      **Pool Security Alerts:** Notify users of potential vulnerabilities in pools.
    `,
    image: "/assets/images/zero-predict/defi-liquidity-monitoring.webp",
  },
  "AI-Enhanced Risk Scoring": {
    title: "AI-Enhanced Risk Scoring",
    description: `
      Leverage advanced AI to classify and prioritize risks, enabling faster and more effective responses to high-priority threats.
      
      **Key Features:**
      **Dynamic Scoring:** Continuously update risk scores based on new data.
      **Custom Thresholds:** Tailor risk thresholds for different platforms and use cases.
      **Visual Analytics:** Provide clear and actionable insights for risk management.
      **Scalable Application:** Use risk scoring across all blockchain ecosystems.
    `,
    image: "/assets/images/zero-predict/risk-scoring.webp",
  },
};


