import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import gsap from "gsap";
import { useWindowScroll } from "react-use";
import { TiLocationArrow, TiThMenu } from "react-icons/ti";
import { useNavigate, useLocation } from "react-router-dom";

import Button from "./Button"; // Button component for consistent styling
import zeroBlockchainImg from "../assets/images/ZeroBankLogoLong.webp";
import { navLists, moreNavLists } from "../constants";

const Navbar = () => {
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [isIndicatorActive, setIsIndicatorActive] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const audioElementRef = useRef(null);
  const navContainerRef = useRef(null);

  const { y: currentScrollY } = useWindowScroll();
  const [isNavVisible, setIsNavVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  // Toggle Audio
  const toggleAudioIndicator = () => {
    setIsAudioPlaying((prev) => !prev);
    setIsIndicatorActive((prev) => !prev);
  };

  // Play/Pause Audio Effect
  useEffect(() => {
    if (isAudioPlaying) {
      audioElementRef.current.play();
    } else {
      audioElementRef.current.pause();
    }
  }, [isAudioPlaying]);

  // Show/Hide Navbar on Scroll
  useEffect(() => {
    if (currentScrollY === 0) {
      setIsNavVisible(true);
      navContainerRef.current.classList.remove("floating-nav");
    } else if (currentScrollY > lastScrollY) {
      setIsNavVisible(false);
      navContainerRef.current.classList.add("floating-nav");
    } else if (currentScrollY < lastScrollY) {
      setIsNavVisible(true);
      navContainerRef.current.classList.add("floating-nav");
    }

    setLastScrollY(currentScrollY);
  }, [currentScrollY, lastScrollY]);

  // Animate Navbar Visibility
  useEffect(() => {
    gsap.to(navContainerRef.current, {
      y: isNavVisible ? 0 : -100,
      opacity: isNavVisible ? 1 : 0,
      duration: 0.2,
    });
  }, [isNavVisible]);

  // Map Navigation Items
  const navItems = navLists.map((nav) => {
    const path = nav === "Home" ? "/" : `/${nav.toLowerCase().replace(/\s+/g, '-')}`;
    return {
      id: `${nav.toLowerCase().replace(/\s+/g, '-')}-button`,
      title: nav,
      path,
    };
  });

  // Map Dropdown Items
  const dropdownItems = moreNavLists.map((nav) => {
    const path = `/${nav.toLowerCase().replace(/\s+/g, '-')}`;
    return {
      id: `${nav.toLowerCase().replace(/\s+/g, '-')}-button`,
      title: nav,
      path,
    };
  });

  // Handle Navigation
  const handleNavigate = (path) => {
    navigate(path);
    setIsDropdownOpen(false);
    setIsMenuOpen(false);
  };

  return (
    <div
        ref={navContainerRef}
        className="fixed inset-x-0 top-4 z-50 h-16 border-none transition-all duration-700 sm:inset-x-6"
      >
        <header className="absolute top-1/2 w-full -translate-y-1/2">
          <nav className="flex items-center justify-between p-4">
            {/* Logo */}
            <div className="flex items-center gap-7">
              <img
                src={zeroBlockchainImg}
                alt="Zero Blockchain Logo"
                className="w-[150px] cursor-pointer"
                onClick={() => window.open("https://www.zero-bank.xyz", "_blank")} // Opens www.zero-bank.xyz in a new tab
              />

            {/* Mobile Menu Icon */}
            <button
              className="md:hidden text-2xl text-gray-400 hover:text-white transition-colors duration-300"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              aria-label="Toggle Navigation Menu"
            >
              <TiThMenu />
            </button>
          </div>

          {/* Navigation Buttons */}
          <div className="hidden md:flex space-x-4">
            {navItems.map((nav) => (
              <Button
                key={nav.id}
                id={nav.id}
                title={nav.title}
                rightIcon={<TiLocationArrow />}
                containerClass={clsx({
                  "bg-teal-500 text-white hover:hover border border-teal-600 hover:bg-teal-600": location.pathname === nav.path, // Active state
                })}
                onClick={() => handleNavigate(nav.path)}
              />
            ))}

            {/* More Button with Dropdown */}
            <div className="relative">
              <Button
                id="more-button"
                title="More"
                rightIcon={<TiLocationArrow />}
                containerClass="hover:bg-gray-700 rounded-lg "
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              />
              {isDropdownOpen && (
                <div className="absolute right-0 mt-2 bg-gray-800 rounded-lg shadow-lg hover:hover border border-teal-600 ">
                  {dropdownItems.map((nav) => (
                    <button
                      key={nav.id}
                      className="block w-full px-4 py-2 text-left hover:bg-gray-700 text-blue-50 hover:hover border border-teal-600"
                      onClick={() => handleNavigate(nav.path)}
                    >
                      {nav.title}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Audio Indicator */}
          <div className="flex items-center gap-7">
            <button
              onClick={toggleAudioIndicator}
              className="flex items-center space-x-0.5"
              aria-label="Toggle Audio"
            >
              <audio
                ref={audioElementRef}
                className="hidden"
                src="/audio/loop.mp3"
                loop
              />
              {[1, 2, 3, 4].map((bar) => (
                <div
                  key={bar}
                  className={clsx("indicator-line", {
                    active: isIndicatorActive,
                  })}
                  style={{
                    animationDelay: `${bar * 0.1}s`,
                  }}
                />
              ))}
            </button>
          </div>
        </nav>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="absolute top-16 right-6 bg-gray-900 rounded-lg shadow-lg p-5 flex flex-col space-y-4 hover:hover border border-teal-600">
            {navItems.map((nav) => (
              <Button
                key={nav.id}
                id={nav.id}
                title={nav.title}
                rightIcon={<TiLocationArrow />}
                containerClass="border-gray-400 hover:bg-teal-500 hover:text-white hover:hover border border-teal-600"
                onClick={() => handleNavigate(nav.path)}
              />
            ))}

            {/* Mobile Dropdown for More */}
            <div>
              <Button
                id="mobile-more-button"
                title="More"
                containerClass="border-gray-400 hover:bg-teal-500 hover:text-white"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              />
              {isDropdownOpen && (
                <div className="mt-2 bg-gray-800 rounded-lg shadow-lg">
                  {dropdownItems.map((nav) => (
                    <button
                      key={nav.id}
                      className="block w-full px-4 py-2 text-left hover:bg-gray-700 text-blue-50"
                      onClick={() => handleNavigate(nav.path)}
                    >
                      {nav.title}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </header>
    </div>
  );
};

export default Navbar;
