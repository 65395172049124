export const CoreFeatures = [
    {
      title: "Modular Core Banking System",
      description: 
        "Customise the banking system with modular features that can be added, updated, or removed as required to meet business needs.",
      image: "/assets/images/zero-core/modular-core.webp",
    },
    {
      title: "Blockchain-Powered Ledger",
      description: 
        "A tamper-proof, immutable blockchain ledger that records all banking transactions for maximum transparency and security.",
      image: "/assets/images/zero-core/blockchain-ledger.webp",
    },
    {
      title: "Real-Time Payments",
      description: 
        "Enable real-time payments for domestic and cross-border transactions, reducing delays and improving cash flow.",
      image: "/assets/images/zero-core/real-time-payments.webp",
    },
    {
      title: "KYC/AML Compliance",
      description: 
        "Built-in KYC and AML compliance support for onboarding customers and adhering to local and international regulations.",
      image: "/assets/images/zero-core/kyc-aml-compliance.webp",
    },
    {
      title: "Customisable User Experience",
      description: 
        "Enable financial institutions to design personalised user interfaces and mobile-optimised customer experiences.",
      image: "/assets/images/zero-core/custom-user-experience.webp",
    },
    {
      title: "Advanced Fraud Detection",
      description: 
        "AI-driven fraud detection system to flag suspicious transactions and protect against fraudulent activities.",
      image: "/assets/images/zero-core/fraud-detection.webp",
    }
  ];

export const TechnicalCapabilities = [
    {
      title: "Microservices Architecture",
      description: 
        "Supports a microservices architecture for scalability, allowing independent development, testing, and deployment of services.",
      image: "/assets/images/zero-core/microservices-architecture.webp",
    },
    {
      title: "Cloud-Native Infrastructure",
      description: 
        "Deploy on AWS, Azure, or Google Cloud to ensure high availability, fault tolerance, and efficient disaster recovery.",
      image: "/assets/images/zero-core/cloud-native-infrastructure.webp",
    },
    {
      title: "Message Queue Integration",
      description: 
        "Leverages RabbitMQ to process real-time notifications, transactional messages, and payment events.",
      image: "/assets/images/zero-core/message-queue.webp",
    },
    {
      title: "Containerisation & Docker",
      description: 
        "Uses Docker to provide isolated, consistent environments for development, testing, and production.",
      image: "/assets/images/zero-core/containerisation.webp",
    },
    {
      title: "Data Encryption & Role-Based Access Control (RBAC)",
      description: 
        "Implements AES-256 encryption and RBAC to secure sensitive data and control user permissions.",
      image: "/assets/images/zero-core/data-encryption-rbac.webp",
    },
    {
      title: "Event-Driven Design",
      description: 
        "Trigger events in real-time for regulatory reporting, compliance alerts, and transaction updates.",
      image: "/assets/images/zero-core/event-driven-design.webp",
    }
  ];

export const KeyBenefits = [
    {
      title: "Enhanced Transparency",
      description: 
        "Gain full transparency with a blockchain-based ledger, where all banking transactions are immutable and fully auditable.",
      image: "/assets/images/zero-core/enhanced-transparency.webp",
    },
    {
      title: "Faster Cross-Border Payments",
      description: 
        "Bypass traditional banking networks like SWIFT and enable near-instant cross-border transactions at lower fees.",
      image: "/assets/images/zero-core/cross-border-payments.webp",
    },
    {
      title: "Regulatory Compliance",
      description: 
        "Support global regulatory compliance with tools to manage KYC, AML, and GDPR, ensuring audit-readiness at all times.",
      image: "/assets/images/zero-core/regulatory-compliance.webp",
    },
    {
      title: "Cost Efficiency",
      description: 
        "Lower operating costs by reducing reliance on legacy systems and central banks while enhancing operational agility.",
      image: "/assets/images/zero-core/cost-efficiency.webp",
    },
    {
      title: "Frictionless Customer Experience",
      description: 
        "Enable customers to experience faster payments, instant account creation, and seamless cross-border transactions.",
      image: "/assets/images/zero-core/customer-experience.webp",
    },
    {
      title: "Reduced Fraud Risk",
      description: 
        "Leverage predictive fraud detection to reduce fraudulent activity and improve risk management.",
      image: "/assets/images/zero-core/fraud-risk-reduction.webp",
    }
  ];

export const UseCases = [
    {
      title: "Challenger Banks",
      description: 
        "Launch modern challenger banks with a blockchain-powered, modular banking system that scales with business growth.",
      image: "/assets/images/zero-core/challenger-banks.webp",
    },
    {
      title: "Corporate Banking",
      description: 
        "Support corporate banking with real-time payments, cash management, and liquidity tools for large enterprises.",
      image: "/assets/images/zero-core/corporate-banking.webp",
    },
    {
      title: "Retail Banking",
      description: 
        "Deliver frictionless banking experiences for personal customers, offering real-time payments and instant account creation.",
      image: "/assets/images/zero-core/retail-banking.webp",
    },
    {
      title: "Wealth Management",
      description: 
        "Streamline wealth management operations with support for portfolio tracking, custodial services, and financial planning.",
      image: "/assets/images/zero-core/wealth-management.webp",
    },
    {
      title: "Fintech Integrations",
      description: 
        "Serve as the payment backbone for fintech apps, providing payment processing, lending, and embedded finance solutions.",
      image: "/assets/images/zero-core/fintech-integrations.webp",
    },
    {
      title: "Payment Processors",
      description: 
        "Offer a secure and fast payment gateway for online payment processing and e-commerce platforms.",
      image: "/assets/images/zero-core/payment-processors.webp",
    }
  ];

export const SecurityAndCompliance = [
    {
      title: "KYC/AML Compliance",
      description: 
        "Ensure compliance with Know Your Customer (KYC) and Anti-Money Laundering (AML) regulations using automated verification tools.",
      image: "/assets/images/zero-core/kyc-aml-compliance.webp",
    },
    {
      title: "GDPR Compliance",
      description: 
        "Adhere to GDPR data privacy regulations, providing users with data transparency, opt-out rights, and full consent control.",
      image: "/assets/images/zero-core/gdpr-compliance.webp",
    },
    {
      title: "Audit Trail",
      description: 
        "Record an immutable, verifiable history of transactions on the blockchain for audit purposes and regulatory review.",
      image: "/assets/images/zero-core/audit-trail.webp",
    },
    {
      title: "Data Encryption & Access Control",
      description: 
        "Protect sensitive data with AES-256 encryption and Role-Based Access Control (RBAC) for user permission management.",
      image: "/assets/images/zero-core/data-encryption-access-control.webp",
    },
    {
      title: "PCI-DSS Compliance",
      description: 
        "Ensure PCI-DSS certification to secure payment card transactions and protect customer financial information.",
      image: "/assets/images/zero-core/pci-dss-compliance.webp",
    },
    {
      title: "Fraud Detection Algorithms",
      description: 
        "AI-driven algorithms detect fraudulent activity in real time, mitigating risk and financial loss.",
      image: "/assets/images/zero-core/fraud-detection-algorithms.webp",
    }
  ];

  export const BusinessBenefits = [
    {
      title: "Revenue Growth Opportunities",
      description: 
        "Drive new revenue streams with cross-border payments, DeFi integrations, and tokenised banking products.",
      image: "/assets/images/zero-core/revenue-growth-opportunities.webp",
    },
    {
      title: "Operational Cost Reduction",
      description: 
        "Reduce costs through process automation, microservices, and cloud-native infrastructure, enhancing operational efficiency.",
      image: "/assets/images/zero-core/operational-cost-reduction.webp",
    },
    {
      title: "Customer-Centric Banking",
      description: 
        "Deliver a tailored customer experience with modular banking features and mobile-optimised user journeys.",
      image: "/assets/images/zero-core/customer-centric-banking.webp",
    },
    {
      title: "Compliance & Risk Mitigation",
      description: 
        "Maintain regulatory compliance with KYC, AML, GDPR, and PCI-DSS standards, ensuring audit-readiness at all times.",
      image: "/assets/images/zero-core/compliance-risk-mitigation.webp",
    },
    {
      title: "Speed to Market",
      description: 
        "Accelerate the launch of new financial products with modular plug-and-play architecture, reducing development timelines.",
      image: "/assets/images/zero-core/speed-to-market.webp",
    },
    {
      title: "Enhanced Security & Trust",
      description: 
        "Leverage blockchain-based audit trails, AES-256 encryption, and zero-knowledge proofs to build customer trust.",
      image: "/assets/images/zero-core/enhanced-security-trust.webp",
    }
  ];

export const FutureEnhancements = [
    {
      title: "AI-Powered Risk Scoring",
      description: 
        "Leverage AI models to predict borrower risk and assess creditworthiness, improving decision-making in lending and customer support.",
      image: "/assets/images/zero-core/ai-risk-scoring.webp",
    },
    {
      title: "DeFi Integration",
      description: 
        "Incorporate decentralised finance (DeFi) capabilities such as staking, liquidity pools, and decentralised lending into Zero-Core's ecosystem.",
      image: "/assets/images/zero-core/defi-integration.webp",
    },
    {
      title: "Green Banking Solutions",
      description: 
        "Launch eco-friendly banking initiatives to reduce the carbon footprint of financial transactions, promoting sustainable banking practices.",
      image: "/assets/images/zero-core/green-banking.webp",
    },
    {
      title: "Voice-Activated Banking",
      description: 
        "Enable customers to perform banking tasks via voice commands, enhancing accessibility and customer experience for all users.",
      image: "/assets/images/zero-core/voice-banking.webp",
    },
    {
      title: "Quantum-Secure Encryption",
      description: 
        "Introduce post-quantum encryption algorithms to future-proof Zero-Core's security from the threats posed by quantum computing.",
      image: "/assets/images/zero-core/quantum-security.webp",
    },
    {
      title: "Cross-Chain Interoperability",
      description: 
        "Support cross-chain interoperability to allow seamless movement of tokenised assets and payments between blockchain networks.",
      image: "/assets/images/zero-core/cross-chain-interoperability.webp",
    }
  ];
  