export const CoreFeatures = [
    {
      title: "User-Centric Design",
      description: 
        "Zero-Bank’s user-first approach offers personalised dashboards, intuitive navigation, and a consistent omni-channel experience.",
      image: "/assets/images/zero-bank/user-centric-design.webp",
    },
    {
      title: "Security Infrastructure",
      description: 
        "Experience bulletproof security with blockchain-backed transactions, end-to-end encryption, two-factor authentication, and real-time fraud detection.",
      image: "/assets/images/zero-bank/security-infrastructure.webp",
    },
    {
      title: "Financial Inclusion",
      description: 
        "Empowering the underbanked with micro-loans, multi-currency support, and reduced fees through blockchain-driven financial inclusion.",
      image: "/assets/images/zero-bank/financial-inclusion.webp",
    },
    {
      title: "Innovative Services",
      description: 
        "Seamlessly manage cryptocurrencies, DeFi, AI-powered investment advice, and digital asset management, all in one platform.",
      image: "/assets/images/zero-bank/innovative-services.webp",
    },
    {
      title: "Multi-Currency Wallets",
      description: 
        "Zero-Bank allows users to store, manage, and exchange multiple currencies in one digital wallet, supporting fiat and cryptocurrencies.",
      image: "/assets/images/zero-bank/multi-currency-wallets.webp",
    },
    {
      title: "Smart Contract Automation",
      description: 
        "Leverage smart contracts to automate loan disbursements, payments, and compliance checks, reducing manual intervention.",
      image: "/assets/images/zero-bank/smart-contract-automation.webp",
    }
  ];

export const SecurityAndCompliance = [
    {
      title: "Blockchain-Backed Transactions",
      description: 
        "Leverage the power of blockchain to ensure transparency, immutability, and trust in every financial transaction.",
      image: "/assets/images/zero-bank/blockchain-transactions.webp",
    },
    {
      title: "End-to-End Encryption",
      description: 
        "All user data is encrypted at rest and in transit, ensuring complete protection from unauthorised access.",
      image: "/assets/images/zero-bank/encryption.webp",
    },
    {
      title: "Two-Factor Authentication (2FA)",
      description: 
        "Secure account access with two-factor authentication, adding a second layer of protection to user accounts.",
      image: "/assets/images/zero-bank/2fa.webp",
    },
    {
      title: "Real-Time Fraud Detection",
      description: 
        "AI-driven monitoring actively identifies and mitigates suspicious activities to prevent fraudulent behaviour.",
      image: "/assets/images/zero-bank/fraud-detection.webp",
    },
    {
      title: "Zero Trust Architecture",
      description: 
        "No action is trusted by default, with verification required at every step, significantly reducing insider threats.",
      image: "/assets/images/zero-bank/zero-trust.webp",
    },
    {
      title: "KYC & AML Compliance",
      description: 
        "Zero-Bank ensures adherence to KYC/AML requirements, safeguarding users and supporting global regulatory frameworks.",
      image: "/assets/images/zero-bank/kyc-aml.webp",
    }
  ];

export const PaymentAndIntegration = [
    {
      title: "Seamless API Integration",
      description: 
        "Connect with external payment gateways and third-party services through well-documented APIs.",
      image: "/assets/images/zero-bank/api-integration.webp",
    },
    {
      title: "Interoperability",
      description: 
        "Link with major payment providers like PayPal, Visa, and MasterCard to enable fast and secure payments.",
      image: "/assets/images/zero-bank/interoperability.webp",
    },
    {
      title: "Custom Integrations",
      description: 
        "Build custom financial workflows by integrating Zero-Bank’s features directly into your business processes.",
      image: "/assets/images/zero-bank/custom-integrations.webp",
    },
    {
      title: "Payment Gateway Support",
      description: 
        "Zero-Bank integrates with major payment gateways to facilitate secure, fast, and frictionless payments globally.",
      image: "/assets/images/zero-bank/payment-gateway-support.webp",
    },
    {
      title: "Cross-Border Payments",
      description: 
        "Enable low-cost, real-time cross-border payments via blockchain-powered settlement mechanisms.",
      image: "/assets/images/zero-bank/cross-border-payments.webp",
    },
    {
      title: "QR Code Payments",
      description: 
        "Generate and scan QR codes to make instant payments, enhancing convenience for both users and merchants.",
      image: "/assets/images/zero-bank/qr-code-payments.webp",
    }
  ];

export const UserExperience = [
    {
      title: "Personalised Dashboards",
      description: 
        "Custom dashboards provide users with clear views of their financial data, including balances, expenses, and investments.",
      image: "/assets/images/zero-bank/personalised-dashboard.webp",
    },
    {
      title: "Omni-Channel Experience",
      description: 
        "Consistent access across mobile, desktop, and tablet, ensuring a smooth, uninterrupted experience for users.",
      image: "/assets/images/zero-bank/omni-channel-experience.webp",
    },
    {
      title: "Intuitive Navigation",
      description: 
        "Enjoy seamless navigation that allows users to easily move between banking, payments, and investment features.",
      image: "/assets/images/zero-bank/intuitive-navigation.webp",
    },
    {
      title: "Real-Time Notifications",
      description: 
        "Users receive real-time notifications for deposits, withdrawals, payments, and important account updates.",
      image: "/assets/images/zero-bank/real-time-notifications.webp",
    },
    {
      title: "Customisable Themes",
      description: 
        "Users can customise the visual appearance of their dashboards with various themes and display modes.",
      image: "/assets/images/zero-bank/customisable-themes.webp",
    },
    {
      title: "Accessibility Features",
      description: 
        "Zero-Bank supports screen readers, high-contrast modes, and keyboard navigation for improved accessibility.",
      image: "/assets/images/zero-bank/accessibility-features.webp",
    }
  ];

export const FinancialProductsAndServices = [
    {
      title: "Crypto Integration",
      description: 
        "Users can manage and trade cryptocurrencies like Bitcoin, Ethereum, and other altcoins within the app.",
      image: "/assets/images/zero-bank/crypto-integration.webp",
    },
    {
      title: "DeFi Services",
      description: 
        "Access decentralised financial services like staking, yield farming, and liquidity provision.",
      image: "/assets/images/zero-bank/defi-services.webp",
    },
    {
      title: "AI-Powered Investment Advice",
      description: 
        "Get market analysis and personalised investment recommendations powered by AI-driven machine learning algorithms.",
      image: "/assets/images/zero-bank/ai-investment-advice.webp",
    },
    {
      title: "Digital Asset Management",
      description: 
        "Manage traditional financial assets (stocks, bonds) alongside crypto holdings within a unified platform.",
      image: "/assets/images/zero-bank/digital-asset-management.webp",
    },
    {
      title: "Wealth Management Tools",
      description: 
        "Zero-Bank offers users access to wealth management services like portfolio tracking, savings goals, and investment strategies.",
      image: "/assets/images/zero-bank/wealth-management.webp",
    },
    {
      title: "Insurance Products",
      description: 
        "Offer life, travel, and health insurance plans via in-app insurance marketplace integration.",
      image: "/assets/images/zero-bank/insurance-products.webp",
    }
  ];

export const SystemArchitecture = [
    {
      title: "Microservices Architecture",
      description: 
        "Each feature operates as an independent service, enabling agile development, faster updates, and improved scalability.",
      image: "/assets/images/zero-bank/microservices.webp",
    },
    {
      title: "API Gateway",
      description: 
        "A secure gateway that enables third-party providers to access Zero-Bank’s services while maintaining strong security controls.",
      image: "/assets/images/zero-bank/api-gateway.webp",
    },
    {
      title: "Blockchain Integration",
      description: 
        "A blockchain-backed architecture for secure, transparent, and immutable transaction recording.",
      image: "/assets/images/zero-bank/blockchain-integration.webp",
    },
    {
      title: "Data Analytics Engine",
      description: 
        "A data analytics engine provides real-time insights and notifications, empowering users to make smarter financial decisions.",
      image: "/assets/images/zero-bank/data-analytics.webp",
    },
    {
      title: "Load Balancer",
      description: 
        "Distribute traffic evenly across multiple servers, improving response times and system availability under heavy load.",
      image: "/assets/images/zero-bank/load-balancer.webp",
    },
    {
      title: "Cloud-Native Infrastructure",
      description: 
        "Leverages AWS, GCP, and Azure for failover, disaster recovery, and global data availability to ensure uninterrupted banking services.",
      image: "/assets/images/zero-bank/cloud-infrastructure.webp",
    }
  ];

export const SustainabilityAndESG = [
    {
      title: "Energy-Efficient Infrastructure",
      description: 
        "Zero-Bank prioritizes sustainability by using energy-efficient cloud providers and eco-friendly blockchain protocols.",
      image: "/assets/images/zero-bank/energy-efficient.webp",
    },
    {
      title: "Green Partnerships",
      description: 
        "Collaborating with eco-friendly initiatives to promote green finance and sustainable development.",
      image: "/assets/images/zero-bank/green-partnerships.webp",
    },
    {
      title: "Carbon Footprint Reduction",
      description: 
        "Zero-Bank tracks and reduces its carbon footprint, aligning with the net-zero emission targets of sustainable finance.",
      image: "/assets/images/zero-bank/carbon-reduction.webp",
    },
    {
      title: "Sustainable Investment Options",
      description: 
        "Users can invest in ESG-compliant projects and green bonds directly through Zero-Bank's investment platform.",
      image: "/assets/images/zero-bank/sustainable-investments.webp",
    },
    {
      title: "Paperless Banking",
      description: 
        "By adopting e-statements, e-signatures, and paperless processes, Zero-Bank reduces paper waste and promotes sustainability.",
      image: "/assets/images/zero-bank/paperless-banking.webp",
    },
    {
      title: "ESG Reporting & Transparency",
      description: 
        "Zero-Bank publishes annual ESG impact reports, tracking progress on sustainability goals, carbon reduction, and green initiatives.",
      image: "/assets/images/zero-bank/esg-reporting.webp",
    }
  ];

export const CommunityEngagement = [
    {
      title: "Open Feedback Loops",
      description: 
        "Users can submit feature suggestions and feedback, which drives improvements in Zero-Bank’s products and services.",
      image: "/assets/images/zero-bank/feedback-loops.webp",
    },
    {
      title: "User Forums",
      description: 
        "A community space where users can discuss, support, and engage with each other.",
      image: "/assets/images/zero-bank/user-forums.webp",
    },
    {
      title: "Developer Support",
      description: 
        "External developers have access to APIs and resources for building applications on top of Zero-Bank’s platform.",
      image: "/assets/images/zero-bank/developer-support.webp",
    },
    {
      title: "Community Hackathons",
      description: 
        "Zero-Bank hosts regular hackathons to encourage innovation, offering prizes for the best user-generated features or financial tools.",
      image: "/assets/images/zero-bank/hackathons.webp",
    },
    {
      title: "Educational Webinars",
      description: 
        "Free webinars for users to learn about financial literacy, blockchain, and DeFi, fostering community development.",
      image: "/assets/images/zero-bank/educational-webinars.webp",
    },
    {
      title: "Social Responsibility Initiatives",
      description: 
        "Zero-Bank contributes to community-driven projects such as financial literacy programs and social welfare campaigns.",
      image: "/assets/images/zero-bank/social-responsibility.webp",
    }
  ];
  