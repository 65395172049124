export const CoreFeatureDetails = {
  "Real-Time Market Monitoring": {
    title: "Real-Time Market Monitoring",
    description: `
      Track live market activity with real-time transaction insights, giving regulators complete visibility into fast-moving markets.
      
      **Key Features:**
      **Live Transaction Insights**: Instantly track and monitor live market transactions as they occur.
      **Comprehensive Visibility**: Gain full transparency into fast-moving financial markets.
      **Dynamic Alerts**: Receive real-time notifications for abnormal market movements.
    `,
    image: "/assets/images/zero-supervise/real-time-monitoring.webp"
  },

  "Predictive Compliance": {
    title: "Predictive Compliance",
    description: `
      AI-driven predictive models forecast potential regulatory breaches before they occur, enabling preemptive risk mitigation.
      
      **Key Features:**
      **AI-Driven Risk Forecasting**: Identify potential compliance risks before they happen.
      **Preemptive Mitigation**: Take action ahead of regulatory breaches.
      **Data-Driven Insights**: Inform decision-making with predictive analytics.
    `,
    image: "/assets/images/zero-supervise/predictive-compliance.webp"
  },

  "Customisable Dashboards": {
    title: "Customisable Dashboards",
    description: `
      Build custom dashboards with drag-and-drop widgets, offering personalised layouts for regulatory insights.
      
      **Key Features:**
      **Drag-and-Drop Design**: Customise dashboard layouts for a personalised view.
      **Insightful Visualisations**: Gain visibility with charts, graphs, and widgets.
      **User-Specific Views**: Adapt dashboards to the unique needs of each user role.
    `,
    image: "/assets/images/zero-supervise/custom-dashboards.webp"
  },

  "Blockchain Audit Trails": {
    title: "Blockchain Audit Trails",
    description: `
      Create immutable audit trails using blockchain, ensuring a tamper-proof record of all financial and compliance activity.
      
      **Key Features:**
      **Immutable Records**: Secure and permanent log of all transactions.
      **Tamper-Proof History**: Record actions and changes with blockchain integrity.
      **Audit-Ready Reports**: Generate audit-compliant reports directly from blockchain trails.
    `,
    image: "/assets/images/zero-supervise/blockchain-audit-trails.webp"
  },

  "Automated Reporting": {
    title: "Automated Reporting",
    description: `
      Generate automated compliance reports that are audit-ready, reducing the manual workload of compliance officers.
      
      **Key Features:**
      **Audit-Ready Reports**: Generate compliance reports ready for audits.
      **Time-Saving Automation**: Eliminate manual effort through automated reporting.
      **On-Demand Reports**: Export reports instantly with up-to-date compliance data.
    `,
    image: "/assets/images/zero-supervise/automated-reporting.webp"
  },

  "Advanced Analytics": {
    title: "Advanced Analytics",
    description: `
      Utilise advanced analytics and visualisations to provide deep insights into compliance trends, supporting informed decisions.
      
      **Key Features:**
      **In-Depth Analytics**: Identify compliance trends with detailed analysis.
      **Visual Insights**: Interactive visual dashboards for enhanced understanding.
      **Data-Driven Decisions**: Support regulatory decision-making with analytical insights.
    `,
    image: "/assets/images/zero-supervise/advanced-analytics.webp"
  }
};


export const TechnicalCapabilitiesDetails = {
  "Microservices Architecture": {
    title: "Microservices Architecture",
    description: `
      Built with microservices to support modular deployment, scalability, and independent updates for individual services.
      
      **Key Features:**
      **Modular Design**: Each service operates independently, enabling modular updates.
      **Scalability**: Scale individual microservices as demand increases.
      **Fault Isolation**: Isolate faults to a single service, ensuring uninterrupted platform performance.
    `,
    image: "/assets/images/zero-supervise/microservices-architecture.webp"
  },

  "Cloud-Native Design": {
    title: "Cloud-Native Design",
    description: `
      Zero-Supervise operates in cloud environments like AWS and Azure, ensuring resilience, scalability, and global reach.
      
      **Key Features:**
      **Multi-Cloud Support**: Deployed on AWS, Azure, and GCP for flexibility and redundancy.
      **High Resilience**: Cloud-native design ensures uptime with failover support.
      **Global Availability**: Seamlessly accessible from multiple regions worldwide.
    `,
    image: "/assets/images/zero-supervise/cloud-native-design.webp"
  },

  "Blockchain Integration": {
    title: "Blockchain Integration",
    description: `
      Leverage blockchain technology to create tamper-proof, verifiable audit trails of regulatory activity and market oversight.
      
      **Key Features:**
      **Immutable Audit Trails**: Use blockchain to create verifiable, tamper-proof logs.
      **Data Integrity**: Ensure that all compliance records remain unchanged and traceable.
      **Enhanced Transparency**: Provide stakeholders with visibility into regulatory activities.
    `,
    image: "/assets/images/zero-supervise/blockchain-integration.webp"
  },

  "Event-Driven System": {
    title: "Event-Driven System",
    description: `
      Event-driven design triggers automated actions and real-time alerts when market anomalies or compliance risks arise.
      
      **Key Features:**
      **Automated Event Triggers**: React to market anomalies with automated alerts and actions.
      **Real-Time Notifications**: Receive instant alerts for compliance breaches or suspicious market activity.
      **Asynchronous Processing**: Enable non-blocking, event-driven workflows.
    `,
    image: "/assets/images/zero-supervise/event-driven-system.webp"
  },

  "Data Encryption & Role-Based Access Control": {
    title: "Data Encryption & Role-Based Access Control",
    description: `
      Protects user data with AES-256 encryption and limits access to sensitive information using Role-Based Access Control (RBAC).
      
      **Key Features:**
      **AES-256 Encryption**: Secure data using advanced encryption standards.
      **Role-Based Access Control (RBAC)**: Restrict access to sensitive information based on user roles.
      **Data Privacy Compliance**: Supports GDPR, MiFID, and other regulatory frameworks for privacy.
    `,
    image: "/assets/images/zero-supervise/data-encryption-rbac.webp"
  },

  "Real-Time Data Stream": {
    title: "Real-Time Data Stream",
    description: `
      Processes live transactional data feeds and updates dashboards, providing instant insights into financial markets.
      
      **Key Features:**
      **Live Market Data**: Process high-speed transactional data in real-time.
      **Dynamic Dashboards**: View live updates on dashboards with no manual refresh required.
      **Instant Market Insights**: Enable real-time decision-making for regulators and compliance teams.
    `,
    image: "/assets/images/zero-supervise/real-time-data-stream.webp"
  }
};


export const KeyBenefitsDetails = {
  "Proactive Risk Management": {
    title: "Proactive Risk Management",
    description: `
      Predict regulatory breaches before they occur, ensuring regulators stay ahead of potential issues.
      
      **Key Features:**
      **Predictive Risk Alerts**: Receive early alerts on potential regulatory breaches.
      **AI-Powered Risk Analysis**: Leverage AI models to predict and prevent compliance violations.
      **Preemptive Risk Mitigation**: Take action before issues escalate to reduce regulatory fines.
    `,
    image: "/assets/images/zero-supervise/risk-management.webp"
  },

  "Audit-Ready Reports": {
    title: "Audit-Ready Reports",
    description: `
      Create exportable reports ready for audit submission, drastically reducing manual reporting effort.
      
      **Key Features:**
      **On-Demand Reports**: Generate audit-ready reports instantly.
      **Regulatory Compliance**: Ensure reports meet global regulatory standards like GDPR, MiFID, and ISO 27001.
      **Customisable Formats**: Export reports in multiple formats (PDF, CSV) for compliance reviews.
    `,
    image: "/assets/images/zero-supervise/audit-ready-reports.webp"
  },

  "Compliance at Scale": {
    title: "Compliance at Scale",
    description: `
      Zero-Supervise scales with growing regulatory demands, ensuring compliance even as market complexity increases.
      
      **Key Features:**
      **Scalable Architecture**: Handles large-scale financial data and regulatory changes with ease.
      **Global Compliance Support**: Adapts to the latest global regulatory requirements.
      **Elastic Cloud Resources**: Automatically scales resources to support growing compliance needs.
    `,
    image: "/assets/images/zero-supervise/compliance-at-scale.webp"
  },

  "Enhanced Transparency": {
    title: "Enhanced Transparency",
    description: `
      Maintain a clear, transparent record of all activity using blockchain audit trails, providing verifiable data integrity.
      
      **Key Features:**
      **Blockchain-Based Audit Trails**: Immutable records of all compliance-related activities.
      **Data Integrity Assurance**: Ensure no changes to records without a verifiable trail.
      **Regulatory Trust**: Build trust with regulators by providing tamper-proof data visibility.
    `,
    image: "/assets/images/zero-supervise/transparent-records.webp"
  },

  "Time & Cost Savings": {
    title: "Time & Cost Savings",
    description: `
      Reduce manual effort with automated workflows, reporting, and risk analysis, allowing compliance teams to focus on strategic tasks.
      
      **Key Features:**
      **Automated Reporting**: Eliminate manual data collection and reporting processes.
      **Workflow Automation**: Streamline repetitive compliance tasks, saving time and operational costs.
      **Efficient Resource Use**: Enable teams to focus on high-priority regulatory tasks.
    `,
    image: "/assets/images/zero-supervise/time-cost-savings.webp"
  },

  "Global Regulatory Support": {
    title: "Global Regulatory Support",
    description: `
      Provides compliance tools aligned with major regulatory frameworks, including GDPR, MiFID, and ISO 27001.
      
      **Key Features:**
      **Multi-Jurisdictional Compliance**: Supports local, regional, and global regulatory compliance.
      **Pre-Built Frameworks**: Includes pre-configured frameworks for GDPR, MiFID, and ISO 27001.
      **Continuous Updates**: Stay ahead of regulatory changes with dynamic compliance updates.
    `,
    image: "/assets/images/zero-supervise/global-regulatory-support.webp"
  }
};


export const UserPersonasDetails = {
  "Regulatory Authorities": {
    title: "Regulatory Authorities",
    description: `
      Provide government bodies with oversight tools to monitor market behaviour, investigate misconduct, and enforce compliance.
      
      **Key Features:**
      **Market Oversight Tools**: Access to comprehensive market data and transaction tracking.
      **Misconduct Investigation**: Tools to investigate and flag potential regulatory breaches.
      **Compliance Enforcement**: Enforce compliance by issuing notices and tracking follow-ups.
    `,
    image: "/assets/images/zero-supervise/regulatory-authorities.webp"
  },

  "Central Banks": {
    title: "Central Banks",
    description: `
      Enable central banks to supervise monetary policy, systemic risk, and liquidity, ensuring financial market stability.
      
      **Key Features:**
      **Monetary Policy Supervision**: Tools for monitoring monetary policy compliance.
      **Liquidity Risk Management**: Ensure market liquidity is stable and risks are identified early.
      **Systemic Risk Monitoring**: Identify and respond to systemic financial risks in real-time.
    `,
    image: "/assets/images/zero-supervise/central-banks.webp"
  },

  "Compliance Teams": {
    title: "Compliance Teams",
    description: `
      Support compliance teams at financial institutions with real-time dashboards, reporting tools, and early warning alerts.
      
      **Key Features:**
      **Real-Time Compliance Dashboards**: Access a centralised view of all compliance activity.
      **Early Warning Alerts**: Proactive alerts for potential compliance breaches.
      **Custom Reporting Tools**: Customise reports to meet internal and external compliance needs.
    `,
    image: "/assets/images/zero-supervise/compliance-teams.webp"
  },

  "Internal Risk Managers": {
    title: "Internal Risk Managers",
    description: `
      Equip internal risk managers with predictive insights, enabling them to mitigate risks and ensure compliance continuity.
      
      **Key Features:**
      **Predictive Risk Insights**: Access AI-driven predictive insights to preempt potential risks.
      **Compliance Continuity Tools**: Ensure compliance processes remain uninterrupted.
      **Proactive Risk Mitigation**: Tools for identifying and mitigating emerging regulatory risks.
    `,
    image: "/assets/images/zero-supervise/internal-risk-managers.webp"
  },

  "Financial Institutions": {
    title: "Financial Institutions",
    description: `
      Banks, asset managers, and insurers rely on Zero-Supervise to manage regulatory compliance and maintain operational integrity.
      
      **Key Features:**
      **Operational Integrity**: Ensure ongoing operational compliance in daily financial activities.
      **Compliance Oversight**: Monitor adherence to internal and regulatory compliance frameworks.
      **Regulatory Alignment**: Ensure alignment with sector-specific regulations like MiFID and Basel III.
    `,
    image: "/assets/images/zero-supervise/financial-institutions.webp"
  },

  "IT Administrators": {
    title: "IT Administrators",
    description: `
      Manage system integration, custom dashboards, and security configurations, ensuring smooth operation across the organisation.
      
      **Key Features:**
      **System Integration Tools**: Easily integrate Zero-Supervise with existing infrastructure.
      **Custom Dashboards**: Customise dashboards to meet the unique needs of the institution.
      **Security Configuration**: Manage access controls, encryption, and security compliance settings.
    `,
    image: "/assets/images/zero-supervise/it-administrators.webp"
  }
};

export const KeyFeaturesDetails = {
  "Real-Time Market Surveillance": {
    title: "Real-Time Market Surveillance",
    description: `
      Stay ahead of market manipulation and misconduct with real-time surveillance of financial markets.
      
      **Key Features:**
      **Continuous Monitoring:** Track market activity 24/7 to detect anomalies and irregular trading patterns.
      **Market Abuse Detection:** Identify potential manipulation, insider trading, and other misconduct.
      **Custom Alerts:** Configure alerts for specific events or deviations from expected norms.
    `,
    image: "/assets/images/zero-supervise/real-time-surveillance.webp"
  },

  "Customisable Alert System": {
    title: "Customisable Alert System",
    description: `
      Personalise alerts to receive timely updates on critical compliance events and risk triggers.
      
      **Key Features:**
      **Dynamic Rule Engine:** Customise rules and thresholds to trigger alerts for specific activities.
      **Real-Time Notifications:** Receive instant alerts on market irregularities and suspicious activity.
      **Multi-Channel Alerts:** Alerts can be sent via email, SMS, or in-app notifications.
    `,
    image: "/assets/images/zero-supervise/alert-system.webp"
  },

  "Automated Workflow Triggers": {
    title: "Automated Workflow Triggers",
    description: `
      Save time and effort by automating workflows for compliance investigations, reporting, and regulatory submissions.
      
      **Key Features:**
      **Automated Task Triggers:** Automatically create tasks for compliance teams when specific conditions are met.
      **Audit Trail Integration:** Link automated workflows directly to audit logs for enhanced traceability.
      **Customisable Workflows:** Adapt workflows to meet unique regulatory and operational requirements.
    `,
    image: "/assets/images/zero-supervise/automated-workflows.webp"
  },

  "Compliance Analytics Dashboard": {
    title: "Compliance Analytics Dashboard",
    description: `
      Get a unified view of compliance performance, risk scores, and market metrics via an interactive analytics dashboard.
      
      **Key Features:**
      **Data Visualisation:** Visualise key metrics such as transaction volume, risk alerts, and performance indicators.
      **Custom Reports:** Export on-demand reports for regulatory audits and internal reviews.
      **Predictive Insights:** Use AI-powered forecasting to identify potential compliance issues.
    `,
    image: "/assets/images/zero-supervise/analytics-dashboard.webp"
  },

  "Predictive Risk Indicators": {
    title: "Predictive Risk Indicators",
    description: `
      Leverage AI to identify future compliance risks before they materialise, enabling early intervention and mitigation.
      
      **Key Features:**
      **Predictive Modelling:** Use machine learning models to predict where risks are likely to occur.
      **Risk Scoring:** Score risks on a priority basis to focus resources on high-impact threats.
      **Proactive Management:** Get early warnings of potential compliance breaches or suspicious activity.
    `,
    image: "/assets/images/zero-supervise/risk-indicators.webp"
  },

  "Comprehensive Audit Trail": {
    title: "Comprehensive Audit Trail",
    description: `
      Track every action, change, and event with a tamper-proof audit trail to ensure transparency and accountability.
      
      **Key Features:**
      **Immutable Logs:** Record every user action, system event, and transaction for full traceability.
      **Blockchain-Based Auditing:** Store audit trails on blockchain for tamper-proof security.
      **Audit-Ready Reports:** Export activity logs in regulatory-compliant formats for external audits.
    `,
    image: "/assets/images/zero-supervise/audit-trail.webp"
  }
};


export const SecurityAndComplianceDetails = {
  "GDPR & ISO 27001 Compliance": {
    title: "GDPR & ISO 27001 Compliance",
    description: `
      Adheres to GDPR and ISO 27001 standards, ensuring global data protection, privacy, and information security compliance.
      
      **Key Features:**
      **Global Compliance**: Aligns with GDPR and ISO 27001 to ensure international compliance with privacy and security standards.
      **Data Protection**: Safeguards personal data with strict controls on collection, storage, and processing.
      **Information Security**: Ensures continuous protection and confidentiality of sensitive information.
    `,
    image: "/assets/images/zero-supervise/gdpr-iso-compliance.webp"
  },

  "Data Encryption (AES-256)": {
    title: "Data Encryption (AES-256)",
    description: `
      Applies AES-256 encryption to protect sensitive data at rest and in transit, ensuring privacy and security.
      
      **Key Features:**
      **Advanced Encryption Standard (AES-256)**: Implements one of the strongest encryption methods for high security.
      **Data-in-Transit Protection**: Encrypts data being transmitted to prevent interception by third parties.
      **Data-at-Rest Security**: Ensures stored data is encrypted, preventing unauthorised access to sensitive information.
    `,
    image: "/assets/images/zero-supervise/aes-encryption.webp"
  },

  "Blockchain Audit Trail": {
    title: "Blockchain Audit Trail",
    description: `
      Ensures data integrity with blockchain-powered audit trails, providing verifiable, immutable records of all activities.
      
      **Key Features:**
      **Immutable Records**: Uses blockchain to create unchangeable, tamper-proof audit logs.
      **Regulatory Compliance**: Provides a clear, traceable record of compliance actions.
      **Transparent Oversight**: Regulators have visibility into all changes, enhancing accountability.
    `,
    image: "/assets/images/zero-supervise/blockchain-audit-trail.webp"
  },

  "Role-Based Access Control (RBAC)": {
    title: "Role-Based Access Control (RBAC)",
    description: `
      Only authorised users can access specific data and functionalities, limiting exposure to sensitive information.
      
      **Key Features:**
      **Role-Based Permissions**: Assign specific access rights to users based on roles and responsibilities.
      **Data Access Control**: Limit access to sensitive data to reduce security risks.
      **Compliance with Least Privilege Principle**: Ensures users have the minimum access necessary to perform their job.
    `,
    image: "/assets/images/zero-supervise/rbac-control.webp"
  },

  "Real-Time Anomaly Detection": {
    title: "Real-Time Anomaly Detection",
    description: `
      AI-driven anomaly detection identifies suspicious transactions in real-time, helping regulators take corrective action.
      
      **Key Features:**
      **AI-Powered Detection**: Leverages machine learning to identify patterns and detect unusual behaviour.
      **Real-Time Alerts**: Sends real-time notifications when suspicious activity is detected.
      **Fraud Prevention**: Blocks potential fraud before it escalates, saving time and money.
    `,
    image: "/assets/images/zero-supervise/anomaly-detection.webp"
  },

  "Multi-Factor Authentication (MFA)": {
    title: "Multi-Factor Authentication (MFA)",
    description: `
      Enhances access security by requiring multiple forms of user verification, ensuring only authorised users gain access.
      
      **Key Features:**
      **Two-Factor Authentication (2FA)**: Requires two distinct methods of user verification.
      **Biometric Security**: Supports fingerprint, facial recognition, and other biometric login methods.
      **One-Time Passwords (OTP)**: Issues a one-time password for extra security during the login process.
    `,
    image: "/assets/images/zero-supervise/mfa-security.webp"
  }
};


export const FutureEnhancementsDetails = {
  "AI-Enhanced Risk Scoring": {
    title: "AI-Enhanced Risk Scoring",
    description: `
      AI-driven risk models will predict potential compliance breaches more accurately, improving regulatory oversight.
      
      **Key Features:**
      **Advanced AI Risk Models**: Utilises machine learning to predict regulatory risks with high accuracy.
      **Proactive Risk Management**: Detects potential compliance breaches before they occur.
      **Predictive Insights**: Provides actionable insights that allow for preemptive interventions.
    `,
    image: "/assets/images/zero-supervise/ai-risk-scoring.webp"
  },

  "Voice-Activated Controls": {
    title: "Voice-Activated Controls",
    description: `
      Introduce voice commands to navigate dashboards and generate compliance reports on demand.
      
      **Key Features:**
      **Voice Command Navigation**: Control the system with voice-activated commands.
      **Hands-Free Reporting**: Generate compliance reports using natural language requests.
      **Enhanced Accessibility**: Improves accessibility for all users, especially those with limited mobility.
    `,
    image: "/assets/images/zero-supervise/voice-controls.webp"
  },

  "Mobile Application": {
    title: "Mobile Application",
    description: `
      Enable users to monitor and respond to compliance alerts on-the-go with a dedicated Zero-Supervise mobile app.
      
      **Key Features:**
      **Mobile Alerts**: Receive push notifications for compliance alerts directly on mobile devices.
      **On-the-Go Monitoring**: Track real-time compliance data while away from the office.
      **Mobile Dashboards**: View interactive dashboards optimised for mobile devices.
    `,
    image: "/assets/images/zero-supervise/mobile-app.webp"
  },

  "DeFi Oversight Module": {
    title: "DeFi Oversight Module",
    description: `
      Support regulatory oversight of DeFi protocols and crypto transactions, allowing regulators to monitor decentralised finance.
      
      **Key Features:**
      **DeFi Compliance Monitoring**: Track DeFi protocols for suspicious or non-compliant activity.
      **Crypto Transaction Tracking**: Monitor and analyse on-chain transactions from DeFi platforms.
      **AML/CFT Integration**: Ensure Anti-Money Laundering and Counter-Terrorism Financing (AML/CFT) compliance in DeFi activities.
    `,
    image: "/assets/images/zero-supervise/defi-oversight.webp"
  },

  "Customisable Reporting Templates": {
    title: "Customisable Reporting Templates",
    description: `
      Allow users to create and save custom report templates, making it easier to generate recurring audit reports.
      
      **Key Features:**
      **Custom Templates**: Users can create and save templates for future report generation.
      **Pre-Built Templates**: Provides ready-made templates for common audit and compliance reports.
      **One-Click Reporting**: Generate audit-ready reports in one click using pre-saved templates.
    `,
    image: "/assets/images/zero-supervise/custom-reporting-templates.webp"
  },

  "Multi-Language Support": {
    title: "Multi-Language Support",
    description: `
      Enable global regulatory teams to use the platform in their preferred language, improving usability across regions.
      
      **Key Features:**
      **Multi-Language Interface**: Supports multiple languages, enabling users to switch the platform to their preferred language.
      **Global Accessibility**: Facilitates use by international teams in their native language.
      **Language-Specific Reports**: Export reports in multiple languages to meet regulatory requirements in different regions.
    `,
    image: "/assets/images/zero-supervise/multi-language-support.webp"
  }
};


