import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Layout from './components/Layout';
import Hero from './components/Hero';
import Highlights from './components/Highlights';
import Model from './components/Model';
import Features from './components/Features';
import HowItWorks from './components/HowItWorks';
import Footer from './components/Footer';
import CryptoStore from './components/CryptoStore';
import ZeroCoin from './components/ZeroCoin';
import TokenDetails from './components/TokenDetails';
import Products from './components/Products';
import ProductDetails from './components/ProductDetails';
import Banking from './components/Banking';
import Support from './components/Support';
import Investments from './components/Investments';
import Wallet from './components/Wallet';
import AboutUs from './components/AboutUs';
import ICO from './components/ICO';
import Blockchain from './components/Blockchain';
import Roadmap from './components/Roadmap'; // New
import Tokenomics from './components/Tokenomics'; // New
import Governance from './components/Governance'; // New
import Developers from './components/Developers'; // New
import MarketData from './components/MarketData'; // New
import Portfolio from './components/Portfolio'; // New
import Dashboard from './components/Dashboard'; // New
import NFTMarketplace from './components/NFTMarketplace'; // New
import Partners from './components/Partners'; // New
import Blog from './components/Blog'; // New
import Compliance from './components/Compliance'; // New
import ContactUs from './components/ContactUs'; // New
import Careers from './components/Careers'; // New
import Services from './components/Services'; // New Services
import PrivacyPolicy from './components/PrivacyPolicy'; // New
import TermsOfUse from './components/TermsOfUse.jsx'; // New
import SalesPolicy from './components/SalesPolicy';
import SiteMap from './components/SiteMap';
import Legal from './components/Legal';
import ScrollToTop from './components/ScrollToTop';
import { hightlightsSlides } from './constants/index.jsx';
import './styles.css'; 

//Product-pages-Zero-Products
import ZeroBlockchain1 from "./products-pages/1-ZeroBlockchain";
import ZeroFlow from "./products-pages/2-ZeroFlow";
import ZeroCryptoWallet from "./products-pages/3-ZeroCryptoWallet";
import ZeroPulse from "./products-pages/4-ZeroPulse";
import ZeroWave from "./products-pages/5-ZeroWave";
import ZeroPay from "./products-pages/6-ZeroPay";
import ZeroComply from "./products-pages/7-ZeroComply";
import ZeroSupervise from "./products-pages/8-ZeroSupervise";
import ZeroLend from "./products-pages/9-ZeroLend";
import ZeroCore from "./products-pages/10-ZeroCore";
import ZeroInsure from "./products-pages/11-ZeroInsure";
import ZeroWealth from "./products-pages/12-ZeroWealth";
import ZeroGreen from "./products-pages/13-ZeroGreen";
import ZeroInsight from "./products-pages/14-ZeroInsight";
import ZeroTrade from "./products-pages/15-ZeroTrade";
import ZeroPredict from "./products-pages/16-ZeroPredict";
import ZeroStocks from "./products-pages/17-ZeroStocks";
import ZeroTokeniser from "./products-pages/18-ZeroTokeniser";
import ZeroStake from "./products-pages/19-ZeroStake";
import ZeroDex from "./products-pages/20-ZeroDex";
import ZeroBank from "./products-pages/21-ZeroBank";

import * as Sentry from '@sentry/react';

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Layout>
        <Routes>
          {/* Main Landing Page */}
          <Route path="/" element={
            <>
              <Hero />
              <Highlights slides={hightlightsSlides} />
              <Model />
              <Features />
              <HowItWorks />
              <Footer />
            </>
          } />
          {/* Existing Pages */}
          <Route path="/zero-coin" element={<ZeroCoin />} />
          <Route path="/crypto-store" element={<CryptoStore />} />
          <Route path="/token/:id" element={<TokenDetails />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/:id" element={<ProductDetails />} />
          <Route path="/banking" element={<Banking />} />
          <Route path="/ico" element={<ICO />} />
          <Route path="/blockchain" element={<Blockchain />} />
          <Route path="/investments" element={<Investments />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/support" element={<Support />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/roadmap" element={<Roadmap />} /> {/* New */}
          <Route path="/tokenomics" element={<Tokenomics />} /> {/* New */}
          <Route path="/governance" element={<Governance />} /> {/* New */}
          <Route path="/developers" element={<Developers />} /> {/* New */}
          <Route path="/market-data" element={<MarketData />} /> {/* New */}
          <Route path="/portfolio" element={<Portfolio />} /> {/* New */}
          <Route path="/dashboard" element={<Dashboard />} /> {/* New */}
          <Route path="/nft-marketplace" element={<NFTMarketplace />} /> {/* New */}
          <Route path="/partners" element={<Partners />} /> {/* New */}
          <Route path="/blog" element={<Blog />} /> {/* New */}
          <Route path="/compliance" element={<Compliance />} /> {/* New */}
          <Route path="/contact-us" element={<ContactUs />} /> {/* New */}
          <Route path="/careers" element={<Careers />} /> {/* New */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} /> {/* New */}
          <Route path="/terms-of-use" element={<TermsOfUse />} /> {/* New */}
          <Route path="/sales-policy" element={<SalesPolicy />} /> {/* New */}
          <Route path="/site-map" element={<SiteMap />} /> {/* New */}
          <Route path="/legal" element={<Legal />} />
          <Route path="/" element={<Home />} /> 
          
          {/* Product Pages in folder - product-pages */}
          <Route path="/products/1" element={<ZeroBlockchain1 />} />
          <Route path="/products/2" element={<ZeroFlow />} />
          <Route path="/products/3" element={<ZeroCryptoWallet />} />
          <Route path="/products/4" element={<ZeroPulse />} />
          <Route path="/products/5" element={<ZeroWave />} />
          <Route path="/products/6" element={<ZeroPay />} />
          <Route path="/products/7" element={<ZeroComply />} />
          <Route path="/products/8" element={<ZeroSupervise />} />
          <Route path="/products/9" element={<ZeroLend />} />
          <Route path="/products/10" element={<ZeroCore />} />
          <Route path="/products/11" element={<ZeroInsure />} />
          <Route path="/products/12" element={<ZeroWealth />} />
          <Route path="/products/13" element={<ZeroGreen />} />
          <Route path="/products/14" element={<ZeroInsight />} />
          <Route path="/products/15" element={<ZeroTrade />} />
          <Route path="/products/16" element={<ZeroPredict />} />
          <Route path="/products/17" element={<ZeroStocks />} />          
          <Route path="/products/18" element={<ZeroTokeniser />} />
          <Route path="/products/19" element={<ZeroStake />} />
          <Route path="/products/20" element={<ZeroDex />} />
          <Route path="/products/21"element={<ZeroBank />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default Sentry.withProfiler(App);
