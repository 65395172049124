export const CoreFeatures = [
    {
      title: "Multi-Standard Tokenisation",
      description:
        "Support for ERC-20, ERC-721, ERC-1155, and ERC-1400 standards to tokenise various asset types, including real estate, commodities, and equities.",
      image: "/assets/images/zero-tokeniser/multi-standard-tokenisation.webp",
    },
    {
      title: "Compliance Integration",
      description:
        "Built-in KYC/AML tools and automated regulatory reporting ensure that all tokenised assets adhere to global compliance standards.",
      image: "/assets/images/zero-tokeniser/compliance-integration.webp",
    },
    {
      title: "Cross-Chain Operability",
      description:
        "Seamless integration with multiple blockchains, including Ethereum, Binance Smart Chain, Solana, and Hyperledger.",
      image: "/assets/images/zero-tokeniser/cross-chain-operability.webp",
    },
    {
      title: "Advanced Portfolio Management",
      description:
        "Real-time portfolio tracking, rebalancing tools, and risk analysis for tokenised assets.",
      image: "/assets/images/zero-tokeniser/portfolio-management.webp",
    },
    {
      title: "Real-Time Analytics",
      description:
        "Live performance metrics and insights into token activity and market trends to inform investment decisions.",
      image: "/assets/images/zero-tokeniser/real-time-analytics.webp",
    },
    {
      title: "Customisable Dashboards",
      description:
        "Personalise dashboards with the tools and metrics most relevant to your asset management needs.",
      image: "/assets/images/zero-tokeniser/customisable-dashboards.webp",
    },
  ];
  
  
export const TechnicalCapabilities = [
    {
      title: "Modular Smart Contract Architecture",
      description:
        "Leverages modular and upgradeable smart contracts to support diverse tokenisation workflows and asset types.",
      image: "/assets/images/zero-tokeniser/modular-smart-contracts.webp",
    },
    {
      title: "Cloud-Native Infrastructure",
      description:
        "Built on AWS, GCP, and Azure to ensure global scalability, high availability, and disaster recovery.",
      image: "/assets/images/zero-tokeniser/cloud-infrastructure.webp",
    },
    {
      title: "Multi-Chain Compatibility",
      description:
        "Integrated with Ethereum, Binance Smart Chain, Solana, and Hyperledger, enabling cross-chain tokenisation and asset transfers.",
      image: "/assets/images/zero-tokeniser/multi-chain-compatibility.webp",
    },
    {
      title: "Event-Driven Notifications",
      description:
        "Alerts users about key events like token activity, regulatory updates, and market trends.",
      image: "/assets/images/zero-tokeniser/event-driven-notifications.webp",
    },
    {
      title: "Data Lakes & Warehousing",
      description:
        "Centralised storage for large-scale asset and transaction data, enabling real-time analytics and backtesting.",
      image: "/assets/images/zero-tokeniser/data-lakes.webp",
    },
    {
      title: "API Integration Framework",
      description:
        "Robust APIs allow seamless integration with third-party platforms for asset management, compliance, and trading.",
      image: "/assets/images/zero-tokeniser/api-integration.webp",
    },
  ];
  
  
export const KeyBenefits = [
    {
      title: "Real-Time Token Insights",
      description:
        "Access up-to-the-minute data on token activity, market movements, and asset performance.",
      image: "/assets/images/zero-tokeniser/real-time-insights.webp",
    },
    {
      title: "Comprehensive Compliance",
      description:
        "Built-in KYC/AML tools and automated compliance checks ensure global regulatory standards are met.",
      image: "/assets/images/zero-tokeniser/compliance.webp",
    },
    {
      title: "Faster Asset Liquidity",
      description:
        "Tokenisation enables faster transfer and trading of traditionally illiquid assets like real estate and commodities.",
      image: "/assets/images/zero-tokeniser/faster-liquidity.webp",
    },
    {
      title: "Multi-Chain Flexibility",
      description:
        "Cross-chain compatibility allows asset transfers and tokenisation across multiple blockchain networks.",
      image: "/assets/images/zero-tokeniser/multi-chain-flexibility.webp",
    },
    {
      title: "Automated Portfolio Rebalancing",
      description:
        "Automated tools to optimise portfolios by rebalancing based on market changes or predefined risk profiles.",
      image: "/assets/images/zero-tokeniser/portfolio-rebalancing.webp",
    },
    {
      title: "Enhanced Security",
      description:
        "Advanced encryption and multi-factor authentication ensure that tokenised assets and data are safe from breaches.",
      image: "/assets/images/zero-tokeniser/enhanced-security.webp",
    },
  ];
  
  
export const UseCases = [
    {
      title: "Real Estate Tokenisation",
      description:
        "Real estate developers and investors can tokenise properties, enabling fractional ownership and easier trading of assets.",
      image: "/assets/images/zero-tokeniser/real-estate-tokenisation.webp",
    },
    {
      title: "Commodities Tokenisation",
      description:
        "Commodities such as gold, oil, and agricultural products can be tokenised, providing liquidity and enabling fractional investment.",
      image: "/assets/images/zero-tokeniser/commodities-tokenisation.webp",
    },
    {
      title: "Equity Tokenisation",
      description:
        "Tokenise stocks or shares in companies, allowing for easier fractional ownership and access to capital markets.",
      image: "/assets/images/zero-tokeniser/equity-tokenisation.webp",
    },
    {
      title: "Private Equity & Venture Capital",
      description:
        "Private equity firms can tokenise their investments to enhance liquidity, enable fractionalisation, and simplify trading.",
      image: "/assets/images/zero-tokeniser/private-equity-tokenisation.webp",
    },
    {
      title: "Art & Collectibles Tokenisation",
      description:
        "Tokenise valuable art pieces and collectibles, making it easier to buy, sell, or trade in smaller, more affordable fractions.",
      image: "/assets/images/zero-tokeniser/art-collectibles-tokenisation.webp",
    },
    {
      title: "Supply Chain Asset Tokenisation",
      description:
        "Tokenise physical assets in supply chains, providing real-time tracking and improving transparency and traceability.",
      image: "/assets/images/zero-tokeniser/supply-chain-tokenisation.webp",
    },
  ];
  
  
export const SecurityAndCompliance = [
    {
      title: "AES-256 Encryption",
      description:
        "All user data and tokenised assets are encrypted using AES-256 encryption to ensure the highest level of data protection.",
      image: "/assets/images/zero-tokeniser/encryption-security.webp",
    },
    {
      title: "Two-Factor Authentication (2FA)",
      description:
        "Users must verify their identity with two-factor authentication (2FA), adding an extra layer of protection against unauthorised access.",
      image: "/assets/images/zero-tokeniser/2fa-security.webp",
    },
    {
      title: "Regulatory Compliance",
      description:
        "Zero-Tokeniser is fully compliant with international financial regulations, including GDPR, SEC regulations, and ISO 27001 security standards.",
      image: "/assets/images/zero-tokeniser/regulatory-compliance.webp",
    },
    {
      title: "Audit Logs & Monitoring",
      description:
        "Every system interaction is logged to ensure a complete audit trail, aiding in compliance and troubleshooting.",
      image: "/assets/images/zero-tokeniser/audit-logs.webp",
    },
    {
      title: "Role-Based Access Control (RBAC)",
      description:
        "Access permissions are defined based on user roles, ensuring that only authorised individuals can perform critical actions.",
      image: "/assets/images/zero-tokeniser/rbac-security.webp",
    },
    {
      title: "Data Breach Notification System",
      description:
        "In the event of a security breach, an automated notification system promptly alerts users and administrators to take corrective actions.",
      image: "/assets/images/zero-tokeniser/breach-notification.webp",
    },
  ];
  
  
export const BusinessBenefits = [
    {
      title: "Enhanced Profitability",
      description:
        "By offering tokenisation of real-world assets, businesses can tap into a broader investor base and increase liquidity, enhancing profitability.",
      image: "/assets/images/zero-tokeniser/enhanced-profitability.webp",
    },
    {
      title: "Operational Efficiency",
      description:
        "Automate the entire asset tokenisation process, reducing manual intervention, and improving operational efficiency and cost-effectiveness.",
      image: "/assets/images/zero-tokeniser/operational-efficiency.webp",
    },
    {
      title: "Stronger Customer Engagement",
      description:
        "Tokenisation enables businesses to offer fractional ownership of assets, creating new investment opportunities and increasing customer engagement.",
      image: "/assets/images/zero-tokeniser/customer-engagement.webp",
    },
    {
      title: "Revenue from Subscription Models",
      description:
        "Zero-Tokeniser’s platform offers subscription-based pricing models for ongoing access to tokenisation tools, generating steady revenue.",
      image: "/assets/images/zero-tokeniser/revenue-subscription.webp",
    },
    {
      title: "Scalability with Cloud Infrastructure",
      description:
        "Zero-Tokeniser’s cloud-native architecture ensures that businesses can scale their tokenisation operations effortlessly to meet growing demand.",
      image: "/assets/images/zero-tokeniser/scalability.webp",
    },
    {
      title: "Regulatory Compliance Advantage",
      description:
        "Zero-Tokeniser helps businesses maintain regulatory compliance, reducing legal risks and building trust with customers and investors.",
      image: "/assets/images/zero-tokeniser/compliance-advantage.webp",
    },
  ];
  
  
export const FutureEnhancements = [
    {
      title: "AI Model Upgrades",
      description:
        "Improve tokenisation efficiency and prediction accuracy with new machine learning models, incorporating a wider range of data sources and market conditions.",
      image: "/assets/images/zero-tokeniser/ai-model-upgrades.webp",
    },
    {
      title: "Global Expansion",
      description:
        "Expand the platform to new regions, offering compliance with local regulations and providing access to a broader pool of global investors.",
      image: "/assets/images/zero-tokeniser/global-expansion.webp",
    },
    {
      title: "Gamification Features",
      description:
        "Introduce gamification elements such as badges, achievements, and leaderboards to enhance user engagement and promote the educational aspect of tokenisation.",
      image: "/assets/images/zero-tokeniser/gamification-features.webp",
    },
    {
      title: "Voice-Activated Tokenisation",
      description:
        "Enable users to interact with the platform and initiate tokenisation processes via voice commands, offering a hands-free user experience.",
      image: "/assets/images/zero-tokeniser/voice-activated-tokenisation.webp",
    },
    {
      title: "Social Tokenisation Communities",
      description:
        "Create a community-based feature where users can collaborate, share tokenisation strategies, and follow others in the tokenisation space.",
      image: "/assets/images/zero-tokeniser/social-tokenisation.webp",
    },
    {
      title: "Quantum Computing Compatibility",
      description:
        "Prepare Zero-Tokeniser for the future by integrating quantum computing advancements to handle complex calculations and predictions faster and more securely.",
      image: "/assets/images/zero-tokeniser/quantum-computing-compatibility.webp",
    },
  ];
  
  
  
  
  
  