export const CoreFeatures = [
    {
      title: "Real-Time Market Data",
      description: 
        "Access live updates on stocks, forex, commodities, and indices from global markets to make timely and data-driven investment decisions.",
      image: "/assets/images/zero-pulse/real-time-market-data.webp",
    },
    {
      title: "Portfolio Management",
      description: 
        "Customise and track investment portfolios with comprehensive performance metrics, risk analysis, and diversification insights.",
      image: "/assets/images/zero-pulse/portfolio-management.webp",
    },
    {
      title: "Advanced Analytics",
      description: 
        "Leverage trend analysis, technical indicators, and predictive models to generate actionable insights for smarter investments.",
      image: "/assets/images/zero-pulse/advanced-analytics.webp",
    },
    {
      title: "Financial Reporting",
      description: 
        "Generate detailed reports on performance, P&L, and cash flow, enabling users to track investment success and profitability.",
      image: "/assets/images/zero-pulse/financial-reporting.webp",
    },
    {
      title: "Custom Alerts",
      description: 
        "Receive notifications for price changes, breaking news, and market trends, keeping users informed of critical market movements.",
      image: "/assets/images/zero-pulse/custom-alerts.webp",
    },
    {
      title: "Risk Management Tools",
      description: 
        "Customise stop-loss, trailing stops, and position sizing to manage exposure and protect portfolio performance.",
      image: "/assets/images/zero-pulse/risk-management-tools.webp",
    }
  ];

export const UserExperienceEnhancements = [
    {
      title: "Customised User Journey",
      description: 
        "User journey maps for beginner, intermediate, and expert investors to offer a tailored experience for all skill levels.",
      image: "/assets/images/zero-pulse/user-journey-map.webp",
    },
    {
      title: "Intuitive Interface",
      description: 
        "A sleek, intuitive design with clear action buttons, visual aids, and interactive dashboards for an easy-to-navigate experience.",
      image: "/assets/images/zero-pulse/intuitive-interface.webp",
    },
    {
      title: "Multi-Platform Compatibility",
      description: 
        "Access the platform via desktop, mobile, and tablet devices with a consistent, smooth, and synchronised user experience.",
      image: "/assets/images/zero-pulse/multi-platform-compatibility.webp",
    },
    {
      title: "Data Visualisation Tools",
      description: 
        "Use visual aids such as charts, graphs, and trendlines to display market insights and investment data with clarity and precision.",
      image: "/assets/images/zero-pulse/data-visualisation-tools.webp",
    },
    {
      title: "User-Centric Onboarding",
      description: 
        "Simplified onboarding with guided steps and interactive prompts to ensure a smooth setup process for new users.",
      image: "/assets/images/zero-pulse/user-onboarding.webp",
    },
    {
      title: "Theme Customisation",
      description: 
        "Users can select from multiple themes and adjust layouts for a personalised platform experience.",
      image: "/assets/images/zero-pulse/theme-customisation.webp",
    }
  ];

export const RiskManagementTools = [
    {
      title: "Stop-Loss & Trailing Stops",
      description: 
        "Minimise potential losses with custom stop-loss and trailing stop orders, giving users control over risk exposure.",
      image: "/assets/images/zero-pulse/stop-loss-tools.webp",
    },
    {
      title: "Position Sizing",
      description: 
        "Use dynamic position sizing to reduce risk exposure and diversify investments for improved portfolio performance.",
      image: "/assets/images/zero-pulse/position-sizing.webp",
    },
    {
      title: "Risk Analysis Reports",
      description: 
        "Generate risk analysis reports with in-depth coverage of exposure levels, diversification, and portfolio vulnerabilities.",
      image: "/assets/images/zero-pulse/risk-analysis-reports.webp",
    },
    {
      title: "Volatility Tracking",
      description: 
        "Track market volatility to identify potential price swings and manage exposure in turbulent market conditions.",
      image: "/assets/images/zero-pulse/volatility-tracking.webp",
    },
    {
      title: "Exposure Management",
      description: 
        "Monitor exposure across multiple assets to ensure a balanced and diversified portfolio.",
      image: "/assets/images/zero-pulse/exposure-management.webp",
    },
    {
      title: "Customisable Risk Alerts",
      description: 
        "Set custom alerts for risk events, margin calls, and extreme market shifts, enabling proactive management.",
      image: "/assets/images/zero-pulse/risk-alerts.webp",
    }
  ];

export const TechnicalCapabilities = [
    {
      title: "Microservices Architecture",
      description: 
        "Decoupled services manage analytics, data feeds, and notifications independently, allowing for seamless scalability and resilience.",
      image: "/assets/images/zero-pulse/microservices-architecture.webp",
    },
    {
      title: "Cloud Infrastructure",
      description: 
        "Hosted on cloud platforms like AWS and Azure, enabling high availability, fault tolerance, and global redundancy.",
      image: "/assets/images/zero-pulse/cloud-infrastructure.webp",
    },
    {
      title: "Data Pipeline",
      description: 
        "A real-time data pipeline ensures live feeds for market prices, analytics, and updates, supporting split-second decision-making.",
      image: "/assets/images/zero-pulse/data-pipeline.webp",
    },
    {
      title: "Message Queues",
      description: 
        "Employs RabbitMQ for real-time notifications and alerts on price changes, news updates, and market movements.",
      image: "/assets/images/zero-pulse/message-queues.webp",
    },
    {
      title: "Containerisation",
      description: 
        "Deploys containerised services using Docker to ensure fast updates, version control, and simplified deployment.",
      image: "/assets/images/zero-pulse/containerisation.webp",
    },
    {
      title: "API Integration",
      description: 
        "Connects to external data providers (Bloomberg, Reuters) for continuous live market data and news feeds.",
      image: "/assets/images/zero-pulse/api-integration.webp",
    }
  ];

  export const SecurityAndCompliance = [
    {
      title: "Bank-Grade Encryption",
      description: 
        "Applies AES-256 encryption to protect user data, passwords, and sensitive financial information both in transit and at rest.",
      image: "/assets/images/zero-pulse/bank-grade-encryption.webp",
    },
    {
      title: "Multi-Factor Authentication (MFA)",
      description: 
        "Provides an added layer of security using 2FA to prevent unauthorised access to user accounts and financial data.",
      image: "/assets/images/zero-pulse/mfa-security.webp",
    },
    {
      title: "GDPR Compliance",
      description: 
        "Ensures data privacy and compliance with GDPR regulations to protect the privacy of European users.",
      image: "/assets/images/zero-pulse/gdpr-compliance.webp",
    }
  ];

export const UseCases = [
    {
      title: "Portfolio Tracking",
      description: 
        "Enable retail investors to track their investments in stocks, commodities, and crypto, all in one dashboard.",
      image: "/assets/images/zero-pulse/portfolio-tracking.webp",
    },
    {
      title: "Financial Advisory",
      description: 
        "Support financial analysts in generating client reports and offering actionable insights for wealth management strategies.",
      image: "/assets/images/zero-pulse/financial-advisory.webp",
    },
    {
      title: "Algorithmic Trading",
      description: 
        "Empower hedge funds to automate trading with data-driven decisions and predictive analytics for better trade execution.",
      image: "/assets/images/zero-pulse/algorithmic-trading.webp",
    },
    {
      title: "Retail Investors",
      description: 
        "Provide retail investors with the tools to research, track, and analyze personal portfolios with ease.",
      image: "/assets/images/zero-pulse/retail-investors.webp",
    },
    {
      title: "Institutional Trading Desks",
      description: 
        "Support institutional traders with advanced analytics, technical analysis, and execution management for large portfolios.",
      image: "/assets/images/zero-pulse/institutional-trading.webp",
    },
    {
      title: "Market Research",
      description: 
        "Research market trends, conduct fundamental analysis, and study technical indicators with interactive dashboards.",
      image: "/assets/images/zero-pulse/market-research.webp",
    }
  ];



  


  

