// src/components/ProductDetails.jsx

import React, { useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TiArrowUp } from "react-icons/ti";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Footer from './Footer';
import AnimatedTitle from './AnimatedTitle';
import HeroButton from './HeroButton';
import products from '../data/productsData';

gsap.registerPlugin(ScrollTrigger);

const ProductDetails = () => {
    const { id } = useParams(); // Extract the product ID from the URL
    const navigate = useNavigate();
    const productRef = useRef(null);
    const backToTopRef = useRef(null);

    // Sample products data (Ensure this data is centralised or fetched from a data source)
    const products = [
        // ... [Insert your products array here or import it from a separate file]
    ];

    // Find the product based on the ID
    const product = products.find(p => p.id === parseInt(id));

    useEffect(() => {
        if (!product) {
            // If product not found, navigate back to products page
            navigate('/products');
        }

        // GSAP Animations
        if (productRef.current) {
            gsap.fromTo(
                productRef.current,
                { opacity: 0, y: 50 },
                {
                    opacity: 1,
                    y: 0,
                    duration: 1,
                    ease: "power3.out",
                    scrollTrigger: {
                        trigger: productRef.current,
                        start: "top 80%",
                    },
                }
            );
        }

        if (backToTopRef.current) {
            gsap.fromTo(
                backToTopRef.current,
                { opacity: 0, y: 20 },
                {
                    opacity: 1,
                    y: 0,
                    duration: 0.5,
                    ease: "power3.out",
                    scrollTrigger: {
                        trigger: backToTopRef.current,
                        start: "top bottom",
                    },
                }
            );
        }
    }, [product, navigate]);

    if (!product) {
        return null; // Render nothing if product is not found
    }

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return (
        <div className="w-screen min-h-screen bg-black text-blue-50 flex flex-col items-center p-5">
            {/* Hero Section */}
            <div className="relative w-full h-64 md:h-96 overflow-hidden flex flex-col items-center justify-center mb-10">
                <video
                    src="/videos/product-details-hero.mp4" // Ensure this video exists
                    autoPlay
                    loop
                    muted
                    className="absolute left-0 top-0 w-full h-full object-cover"
                />
                <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/60">
                    {/* AnimatedTitle */}
                    <AnimatedTitle
                        title={product.name}
                        containerClass="text-center text-blue-50 font-zentry text-5xl md:text-6xl"
                    />
                </div>
            </div>

            {/* Product Details Section */}
            <div
                className="w-full max-w-6xl bg-gray-900 rounded-lg shadow-md overflow-hidden p-10 mb-10"
                ref={productRef}
            >
                <div className="text-center">
                    <img
                        src={`/images/${product.image}`} // Ensure the image path is correct
                        alt={product.name}
                        className="mx-auto w-96 h-96 object-contain p-5 transform scale-125"
                        draggable="false"
                    />
                    <div className="mt-5 text-left">
                        <p className="text-gray-300">
                            <strong>Token Standard:</strong> {product.tokenStandard}
                        </p>
                        <p className="text-gray-300">
                            <strong>Deployment:</strong> {product.deployment}
                        </p>
                        <p className="text-gray-300">
                            <strong>Contract Address:</strong>{" "}
                            <a
                                href={`https://etherscan.io/token/${product.contractAddress}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-teal-500 underline"
                            >
                                {product.contractAddress}
                            </a>
                        </p>
                        <p className="text-gray-300">
                            <strong>Description:</strong> {product.description}
                        </p>
                        <p className="text-gray-300">
                            <strong>Price:</strong> {product.price}
                        </p>
                    </div>
                    {/* Action Buttons */}
                    <div className="flex justify-center mt-5">
                        <HeroButton
                            onClick={() => navigate('/products')}
                            ariaLabel="Back to Products"
                            additionalClasses="m-2 bg-red-500 hover:bg-red-600"
                        >
                            Back to Products
                        </HeroButton>
                        <HeroButton
                            onClick={() => window.open(product.link, '_blank')}
                            ariaLabel={`Visit ${product.name}`}
                            additionalClasses="m-2 bg-green-500 hover:bg-green-600"
                        >
                            Visit Product
                        </HeroButton>
                    </div>
                </div>
            </div>

            {/* Back to Top Button */}
            <div
                ref={backToTopRef}
                className="fixed bottom-10 left-5 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
                onClick={scrollToTop}
                aria-label="Back to Top"
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        scrollToTop();
                    }
                }}
            >
                <TiArrowUp
                    className="text-gray-400 hover:text-white transition-transform duration-300 hover:scale-110"
                    size={24}
                />
                <span className="relative z-20 text-sm uppercase text-gray-400 transition-colors duration-300 hover:text-white">
                    BACK TO TOP
                </span>
            </div>

            {/* Footer */}
            <Footer />
        </div>
    );

};

export default ProductDetails;
