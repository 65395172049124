export const CoreFeatureDetails = {
    "Multi-Standard Tokenisation": {
      title: "Multi-Standard Tokenisation",
      description: `
        Tokenise a wide range of asset types by supporting multiple blockchain standards, including ERC-20, ERC-721, ERC-1155, and ERC-1400.
        
        **Key Features:**
        **ERC-20**: Tokenise fungible assets like commodities, currencies, or digital assets.
        **ERC-721**: Tokenise unique assets such as real estate or rare collectibles.
        **ERC-1155**: Supports both fungible and non-fungible tokens within the same contract.
        **ERC-1400**: Tailored for security tokens and regulated assets.
      `,
      image: "/assets/images/zero-tokeniser/multi-standard-tokenisation.webp",
    },
  
    "Compliance Integration": {
      title: "Compliance Integration",
      description: `
        Ensure that all tokenised assets comply with global financial regulations, including built-in KYC/AML tools and automated reporting.
        
        **Key Features:**
        **KYC/AML Compliance**: Automatically verifies the identities of users and ensures compliance with anti-money laundering regulations.
        **Automated Reporting**: Streamline regulatory reporting, reducing the risk of compliance failures.
        **Audit Trails**: Maintain a transparent and immutable record of compliance checks for audit purposes.
      `,
      image: "/assets/images/zero-tokeniser/compliance-integration.webp",
    },
  
    "Cross-Chain Operability": {
      title: "Cross-Chain Operability",
      description: `
        Enable seamless integration with multiple blockchain networks, ensuring that tokenised assets can be easily traded and transferred across platforms.
        
        **Key Features:**
        **Multi-Blockchain Support**: Works with Ethereum, Binance Smart Chain, Solana, and Hyperledger, among others.
        **Token Transfers Across Chains**: Facilitate asset transfers without the need for centralised exchanges.
        **Liquidity Pool Integration**: Access liquidity across different chains, expanding market reach.
      `,
      image: "/assets/images/zero-tokeniser/cross-chain-operability.webp",
    },
  
    "Advanced Portfolio Management": {
      title: "Advanced Portfolio Management",
      description: `
        Take control of your investment portfolio with real-time tracking, risk analysis, and rebalancing tools designed for tokenised assets.
        
        **Key Features:**
        **Real-Time Portfolio Tracking**: View your asset holdings and performance in real-time.
        **Risk Analysis**: Use AI-powered risk assessments to ensure optimal risk-adjusted returns.
        **Rebalancing Tools**: Automatically rebalance your portfolio to maintain your desired asset allocation.
      `,
      image: "/assets/images/zero-tokeniser/portfolio-management.webp",
    },
  
    "Real-Time Analytics": {
      title: "Real-Time Analytics",
      description: `
        Access live data on token performance, market trends, and investment insights to drive decision-making.
        
        **Key Features:**
        **Live Metrics**: Track the performance of tokenised assets in real-time.
        **Market Trends**: Get insights on market movements, including price fluctuations and liquidity changes.
        **Data-Driven Insights**: Use AI and machine learning to identify patterns and predict future market behavior.
      `,
      image: "/assets/images/zero-tokeniser/real-time-analytics.webp",
    },
  
    "Customisable Dashboards": {
      title: "Customisable Dashboards",
      description: `
        Tailor your dashboard to display the metrics and tools most relevant to your asset management strategy.
        
        **Key Features:**
        **Widget Customisation**: Add or remove widgets to focus on critical metrics.
        **User-Specific Views**: Personalise the dashboard based on your role (investor, advisor, or fund manager).
        **Interactive Charts**: Use dynamic charts to visualise asset performance and trends.
      `,
      image: "/assets/images/zero-tokeniser/customisable-dashboards.webp",
    },
  };

  
  export const TechnicalCapabilitiesDetails = {
    "Modular Smart Contract Architecture": {
      title: "Modular Smart Contract Architecture",
      description: `
        **Overview:**
        Leverages modular and upgradeable smart contracts to support diverse tokenisation workflows and asset types.
        
        **Key Features:**
        **Modular Design:** Easily adapt and upgrade the smart contracts to accommodate new asset types or changing requirements.
        **Scalability:** Supports the growth of the platform, enabling the addition of new tokens, assets, and features.
        **Security:** Each module is independently verifiable, ensuring that vulnerabilities are isolated and easy to manage.
      `,
      image: "/assets/images/zero-tokeniser/modular-smart-contracts.webp"
    },
  
    "Cloud-Native Infrastructure": {
      title: "Cloud-Native Infrastructure",
      description: `
        **Overview:**
        Built on AWS, GCP, and Azure to ensure global scalability, high availability, and disaster recovery.
  
        **Key Features:**
        **Global Reach:** With cloud services from leading providers, Zero-Tokeniser ensures that its services are accessible globally with minimal latency.
        **High Availability:** Leverages cloud-native architecture to deliver uptime guarantees and automatic scaling during high demand periods.
        **Disaster Recovery:** Built-in data backup and recovery protocols ensure minimal downtime in case of unforeseen incidents.
      `,
      image: "/assets/images/zero-tokeniser/cloud-infrastructure.webp"
    },
  
    "Multi-Chain Compatibility": {
      title: "Multi-Chain Compatibility",
      description: `
        **Overview:**
        Integrated with Ethereum, Binance Smart Chain, Solana, and Hyperledger, enabling cross-chain tokenisation and asset transfers.
        
        **Key Features:**
        **Cross-Chain Integration:** Facilitate token transactions across various blockchain networks, giving users flexibility and access to different ecosystems.
        **Interoperability:** Ensures that tokenisation and transfers can be carried out smoothly across chains without network restrictions.
        **Scalability:** Supports future blockchain protocols to integrate new and emerging technologies.
      `,
      image: "/assets/images/zero-tokeniser/multi-chain-compatibility.webp"
    },
  
    "Event-Driven Notifications": {
      title: "Event-Driven Notifications",
      description: `
        **Overview:**
        Alerts users about key events like token activity, regulatory updates, and market trends.
        
        **Key Features:**
        **Real-Time Alerts:** Users receive timely notifications on their devices for events they care about, including price changes, regulatory updates, and portfolio rebalancing.
        **Custom Triggers:** Users can set custom triggers for notifications based on price thresholds, asset performance, or regulatory changes.
        **User-Centric Notifications:** Tailored alerts to ensure users only receive the most relevant information.
      `,
      image: "/assets/images/zero-tokeniser/event-driven-notifications.webp"
    },
  
    "Data Lakes & Warehousing": {
      title: "Data Lakes & Warehousing",
      description: `
        **Overview:**
        Centralised storage for large-scale asset and transaction data, enabling real-time analytics and backtesting.
        
        **Key Features:**
        **Centralised Data Storage:** All token activity and transaction data are stored in a secure and structured manner for easy retrieval.
        **Real-Time Analytics:** Data can be accessed and analyzed instantly, providing users with up-to-the-minute insights.
        **Backtesting Support:** Historical data allows for backtesting and optimising tokenisation strategies before they are implemented.
      `,
      image: "/assets/images/zero-tokeniser/data-lakes.webp"
    },
  
    "API Integration Framework": {
      title: "API Integration Framework",
      description: `
        **Overview:**
        Robust APIs allow seamless integration with third-party platforms for asset management, compliance, and trading.
        
        **Key Features:**
        **Seamless Integration:** Enables businesses and developers to integrate the Zero-Tokeniser platform with other third-party applications and services.
        **Scalability:** The API framework is designed to scale as the platform grows, allowing more integrations and partnerships to form over time.
        **Security and Access Control:** APIs come with built-in security protocols, ensuring that only authorised users and platforms have access.
      `,
      image: "/assets/images/zero-tokeniser/api-integration.webp"
    },
  };

  
  export const KeyBenefitsDetails = {
    "Real-Time Token Insights": {
      title: "Real-Time Token Insights",
      description: `
        **Overview:**
        Access up-to-the-minute data on token activity, market movements, and asset performance.
        
        **Key Features:**
        **Up-to-Date Data:** Get instant access to the latest token data, including price movements and transaction history.
        **Real-Time Market Trends:** Monitor asset performance with live metrics and trends to help you make informed decisions.
        **Actionable Insights:** Leverage data-driven insights to respond swiftly to market conditions.
      `,
      image: "/assets/images/zero-tokeniser/real-time-insights.webp"
    },
  
    "Comprehensive Compliance": {
      title: "Comprehensive Compliance",
      description: `
        **Overview:**
        Built-in KYC/AML tools and automated compliance checks ensure global regulatory standards are met.
        
        **Key Features:**
        **KYC/AML Compliance:** Automated processes for identity verification and anti-money laundering checks.
        **Global Regulatory Support:** Supports international compliance standards, ensuring smooth operations across different jurisdictions.
        **Automated Reporting:** Effortless and automatic generation of reports to ensure compliance with regulators.
      `,
      image: "/assets/images/zero-tokeniser/compliance.webp"
    },
  
    "Faster Asset Liquidity": {
      title: "Faster Asset Liquidity",
      description: `
        **Overview:**
        Tokenisation enables faster transfer and trading of traditionally illiquid assets like real estate and commodities.
        
        **Key Features:**
        **Improved Liquidity:** Tokenisation turns traditionally illiquid assets into easily tradable units, enhancing liquidity.
        **Global Market Access:** Facilitate the buying, selling, or trading of assets across global markets with ease.
        **Fractional Ownership:** Enable fractional ownership, allowing multiple investors to participate in high-value assets.
      `,
      image: "/assets/images/zero-tokeniser/faster-liquidity.webp"
    },
  
    "Multi-Chain Flexibility": {
      title: "Multi-Chain Flexibility",
      description: `
        **Overview:**
        Cross-chain compatibility allows asset transfers and tokenisation across multiple blockchain networks.
        
        **Key Features:**
        **Cross-Chain Transfers:** Move assets between different blockchain networks without friction.
        **Blockchain Agnostic:** Integrate with various blockchain ecosystems, offering more choices for asset management.
        **Network Flexibility:** Ensures tokens can be created and traded across multiple chains, including Ethereum, Binance Smart Chain, and Solana.
      `,
      image: "/assets/images/zero-tokeniser/multi-chain-flexibility.webp"
    },
  
    "Automated Portfolio Rebalancing": {
      title: "Automated Portfolio Rebalancing",
      description: `
        **Overview:**
        Automated tools to optimise portfolios by rebalancing based on market changes or predefined risk profiles.
        
        **Key Features:**
        **Dynamic Rebalancing:** Automatically adjusts portfolio holdings in response to market fluctuations.
        **Risk Management:** Helps investors maintain their desired risk profile by rebalancing their investments in real-time.
        **Custom Triggers:** Set triggers for rebalancing, ensuring the portfolio aligns with changing market conditions or user preferences.
      `,
      image: "/assets/images/zero-tokeniser/portfolio-rebalancing.webp"
    },
  
    "Enhanced Security": {
      title: "Enhanced Security",
      description: `
        **Overview:**
        Advanced encryption and multi-factor authentication ensure that tokenised assets and data are safe from breaches.
        
        **Key Features:**
        **AES-256 Encryption:** Industry-standard encryption for storing and transmitting sensitive data securely.
        **Multi-Factor Authentication:** Provides additional layers of security, ensuring only authorised users have access to token assets.
        **Security Audits:** Regular security checks and audits to identify vulnerabilities and maintain platform integrity.
      `,
      image: "/assets/images/zero-tokeniser/enhanced-security.webp"
    },
  };

  
  export const UseCasesDetails = {
    "Real Estate Tokenisation": {
      title: "Real Estate Tokenisation",
      description: `
        **Overview:**
        Real estate developers and investors can tokenise properties, enabling fractional ownership and easier trading of assets.
        
        **Key Features:**
        **Fractional Ownership:** Split properties into smaller, tradable units, allowing multiple investors to participate.
        **Increased Liquidity:** Tokenisation makes real estate assets easier to buy, sell, and trade on global platforms.
        **Global Access:** Enable investors from around the world to participate in real estate investments.
      `,
      image: "/assets/images/zero-tokeniser/real-estate-tokenisation.webp"
    },
  
    "Commodities Tokenisation": {
      title: "Commodities Tokenisation",
      description: `
        **Overview:**
        Commodities such as gold, oil, and agricultural products can be tokenised, providing liquidity and enabling fractional investment.
        
        **Key Features:**
        **Access to Commodities:** Tokenisation opens up access to investing in commodities with lower capital requirements.
        **Liquidity:** Tokenised commodities can be traded quickly and easily across different markets.
        **Fractional Trading:** Investors can own fractions of high-value commodities, making investment more accessible.
      `,
      image: "/assets/images/zero-tokeniser/commodities-tokenisation.webp"
    },
  
    "Equity Tokenisation": {
      title: "Equity Tokenisation",
      description: `
        **Overview:**
        Tokenise stocks or shares in companies, allowing for easier fractional ownership and access to capital markets.
        
        **Key Features:**
        **Fractional Shares:** Tokenised equity allows for ownership in small fractions, making investing more affordable.
        **Faster Transactions:** Tokenised stocks enable instant transfer and settlement of shares.
        **Access to Capital Markets:** Provides investors with easier access to private equity or traditional stock markets.
      `,
      image: "/assets/images/zero-tokeniser/equity-tokenisation.webp"
    },
  
    "Private Equity & Venture Capital": {
      title: "Private Equity & Venture Capital",
      description: `
        **Overview:**
        Private equity firms can tokenise their investments to enhance liquidity, enable fractionalisation, and simplify trading.
        
        **Key Features:**
        **Increased Liquidity:** Tokenised private equity allows for faster and more liquid transactions.
        **Fractional Investment:** Tokenisation allows investors to access traditionally high-value private equity markets.
        **Global Investment Access:** Tokenised private equity can attract global investors, expanding the market pool.
      `,
      image: "/assets/images/zero-tokeniser/private-equity-tokenisation.webp"
    },
  
    "Art & Collectibles Tokenisation": {
      title: "Art & Collectibles Tokenisation",
      description: `
        **Overview:**
        Tokenise valuable art pieces and collectibles, making it easier to buy, sell, or trade in smaller, more affordable fractions.
        
        **Key Features:**
        **Fractional Ownership:** Tokenise art and collectibles into tradable shares, making them more accessible to a broader audience.
        **Increased Market Access:** Facilitates easy buying, selling, and trading of rare items in the digital space.
        **Provenance and Tracking:** Tokenisation can track the ownership history of art pieces and collectibles, ensuring authenticity.
      `,
      image: "/assets/images/zero-tokeniser/art-collectibles-tokenisation.webp"
    },
  
    "Supply Chain Asset Tokenisation": {
      title: "Supply Chain Asset Tokenisation",
      description: `
        **Overview:**
        Tokenise physical assets in supply chains, providing real-time tracking and improving transparency and traceability.
        
        **Key Features:**
        **Real-Time Tracking:** Track physical assets in real-time through tokenisation, ensuring transparency at every stage.
        **Smart Contracts:** Use smart contracts to automate supply chain processes and enhance operational efficiency.
        **Increased Transparency:** Tokenised supply chain assets help mitigate fraud and improve transparency throughout the entire process.
      `,
      image: "/assets/images/zero-tokeniser/supply-chain-tokenisation.webp"
    }
  };

  
  export const SecurityAndComplianceDetails = {
    "AES-256 Encryption": {
      title: "AES-256 Encryption",
      description: `
        **Overview:**
        All user data and tokenised assets are encrypted using AES-256 encryption to ensure the highest level of data protection.
        
        **Key Features:**
        **Advanced Encryption Standard (AES):** AES-256 encryption offers industry-standard protection for sensitive data.
        **Data at Rest & In Transit:** Ensures that tokenised assets and personal information are securely encrypted, both while stored and during transmission.
        **Protection from Data Breaches:** Provides robust protection against unauthorised access and cyber threats.
      `,
      image: "/assets/images/zero-tokeniser/encryption-security.webp"
    },
  
    "Two-Factor Authentication (2FA)": {
      title: "Two-Factor Authentication (2FA)",
      description: `
        **Overview:**
        Users must verify their identity with two-factor authentication (2FA), adding an extra layer of protection against unauthorised access.
        
        **Key Features:**
        **Enhanced Security:** Requires a secondary verification step (e.g., SMS or authenticator app) in addition to the password.
        **Fraud Prevention:** Prevents unauthorised access even if login credentials are compromised.
        **User-Friendly:** Easy setup and management for users, providing an additional level of security without sacrificing user experience.
      `,
      image: "/assets/images/zero-tokeniser/2fa-security.webp"
    },
  
    "Regulatory Compliance": {
      title: "Regulatory Compliance",
      description: `
        **Overview:**
        Zero-Tokeniser is fully compliant with international financial regulations, including GDPR, SEC regulations, and ISO 27001 security standards.
        
        **Key Features:**
        **Global Compliance:** Adheres to global financial regulations, ensuring that tokenised assets and transactions meet necessary legal standards.
        **Data Privacy:** Full compliance with GDPR, ensuring that personal data is handled securely and transparently.
        **ISO 27001 Certified:** Complies with ISO 27001 standards for information security management, demonstrating adherence to industry best practices.
      `,
      image: "/assets/images/zero-tokeniser/regulatory-compliance.webp"
    },
  
    "Audit Logs & Monitoring": {
      title: "Audit Logs & Monitoring",
      description: `
        **Overview:**
        Every system interaction is logged to ensure a complete audit trail, aiding in compliance and troubleshooting.
        
        **Key Features:**
        **Comprehensive Audits:** Tracks and logs all user interactions and transactions, ensuring traceability.
        **Real-Time Monitoring:** Monitors system activity in real-time, helping to detect suspicious activities and potential security breaches.
        **Compliance Support:** The audit logs provide the necessary data for regulatory compliance and internal audits.
      `,
      image: "/assets/images/zero-tokeniser/audit-logs.webp"
    },
  
    "Role-Based Access Control (RBAC)": {
      title: "Role-Based Access Control (RBAC)",
      description: `
        **Overview:**
        Access permissions are defined based on user roles, ensuring that only authorised individuals can perform critical actions.
        
        **Key Features:**
        **Granular Permissions:** Define permissions for different users based on their roles, limiting access to sensitive data or critical actions.
        **Flexible Role Assignments:** Assign multiple roles to users or adjust access permissions according to business needs.
        **Least Privilege Principle:** Ensures that users only have access to the resources necessary for their role, reducing the risk of data exposure.
      `,
      image: "/assets/images/zero-tokeniser/rbac-security.webp"
    },
  
    "Data Breach Notification System": {
      title: "Data Breach Notification System",
      description: `
        **Overview:**
        In the event of a security breach, an automated notification system promptly alerts users and administrators to take corrective actions.
        
        **Key Features:**
        **Real-Time Alerts:** Instantly notifies users and administrators when a potential data breach occurs, enabling fast response times.
        **Automated Response:** The system automatically triggers corrective actions, such as account suspension or access revocation, to mitigate the impact of the breach.
        **Compliance Support:** Helps businesses remain compliant with legal requirements for data breach notifications.
      `,
      image: "/assets/images/zero-tokeniser/breach-notification.webp"
    }
  };

  
  export const BusinessBenefitsDetails = {
    "Enhanced Profitability": {
      title: "Enhanced Profitability",
      description: `
        **Overview:**
        By offering tokenisation of real-world assets, businesses can tap into a broader investor base and increase liquidity, enhancing profitability.
  
        **Key Benefits:**
        **Broader Investor Base:** Tokenisation attracts a larger pool of investors, including those interested in fractional ownership of high-value assets.
        **Increased Liquidity:** Tokenising traditionally illiquid assets allows for faster and easier trading, improving liquidity.
        **Diversified Investment Opportunities:** Tokenisation opens up new avenues for businesses to raise capital and increase profitability by offering fractional investment options.
      `,
      image: "/assets/images/zero-tokeniser/enhanced-profitability.webp"
    },
  
    "Operational Efficiency": {
      title: "Operational Efficiency",
      description: `
        **Overview:**
        Automate the entire asset tokenisation process, reducing manual intervention, and improving operational efficiency and cost-effectiveness.
  
        **Key Benefits:**
        **Automation of Processes:** Reduces the need for manual tasks, cutting down on errors and improving operational speed.
        **Cost Reduction:** With automation, businesses save on transaction costs and personnel resources.
        **Seamless Integration:** Effortlessly integrates with existing business workflows, reducing operational complexity.
      `,
      image: "/assets/images/zero-tokeniser/operational-efficiency.webp"
    },
  
    "Stronger Customer Engagement": {
      title: "Stronger Customer Engagement",
      description: `
        **Overview:**
        Tokenisation enables businesses to offer fractional ownership of assets, creating new investment opportunities and increasing customer engagement.
  
        **Key Benefits:**
        **Fractional Ownership:** Provides customers with the opportunity to invest in assets they otherwise couldn't afford, improving engagement.
        **New Investment Opportunities:** Businesses can offer their customers exclusive access to new types of investments, fostering loyalty and deeper engagement.
        **Broader Market Appeal:** Attracts a wider customer base, including retail investors who can now participate in asset markets more easily.
      `,
      image: "/assets/images/zero-tokeniser/customer-engagement.webp"
    },
  
    "Revenue from Subscription Models": {
      title: "Revenue from Subscription Models",
      description: `
        **Overview:**
        Zero-Tokeniser’s platform offers subscription-based pricing models for ongoing access to tokenisation tools, generating steady revenue.
  
        **Key Benefits:**
        **Recurring Revenue Stream:** Subscription models offer businesses predictable and recurring revenue from users accessing tokenisation services.
        **Flexible Pricing Tiers:** Allows businesses to offer tiered pricing plans based on the scale of usage, from basic to advanced tokenisation services.
        **Scalable Revenue Growth:** As businesses scale their tokenisation operations, subscription models can scale in parallel, providing additional revenue.
      `,
      image: "/assets/images/zero-tokeniser/revenue-subscription.webp"
    },
  
    "Scalability with Cloud Infrastructure": {
      title: "Scalability with Cloud Infrastructure",
      description: `
        **Overview:**
        Zero-Tokeniser’s cloud-native architecture ensures that businesses can scale their tokenisation operations effortlessly to meet growing demand.
  
        **Key Benefits:**
        **Cloud-Native Infrastructure:** Leverages AWS, GCP, and Azure to ensure global scalability, high availability, and cost efficiency.
        **On-Demand Scalability:** The platform can easily scale up or down based on business needs, helping to manage costs effectively.
        **Resilience and Flexibility:** With cloud infrastructure, businesses can quickly adapt to market changes and growth without worrying about hardware limitations.
      `,
      image: "/assets/images/zero-tokeniser/scalability.webp"
    },
  
    "Regulatory Compliance Advantage": {
      title: "Regulatory Compliance Advantage",
      description: `
        **Overview:**
        Zero-Tokeniser helps businesses maintain regulatory compliance, reducing legal risks and building trust with customers and investors.
  
        **Key Benefits:**
        **Global Compliance:** Fully compliant with key international regulations, including GDPR, SEC regulations, and ISO 27001 security standards.
        **Risk Mitigation:** Helps businesses mitigate risks associated with non-compliance, safeguarding reputation and trust.
        **Simplified Audits:** Provides automated reporting tools for businesses to ensure that all tokenisation activities meet the highest regulatory standards.
      `,
      image: "/assets/images/zero-tokeniser/compliance-advantage.webp"
    }
  };

  
  export const FutureEnhancementsDetails = {
    "AI Model Upgrades": {
      title: "AI Model Upgrades",
      description: `
        **Overview:**
        Improve tokenisation efficiency and prediction accuracy with new machine learning models, incorporating a wider range of data sources and market conditions.
  
        **Key Features:**
        **Enhanced Prediction Accuracy:** New AI models will improve the precision of predictions related to tokenised assets, leading to better decision-making.
        **Expanded Data Sources:** Incorporating more diverse data points, including social sentiment, macroeconomic indicators, and historical trends.
        **Market Condition Adaptation:** AI models will evolve to adapt to changing market conditions, offering more relevant insights.
      `,
      image: "/assets/images/zero-tokeniser/ai-model-upgrades.webp"
    },
  
    "Global Expansion": {
      title: "Global Expansion",
      description: `
        **Overview:**
        Expand the platform to new regions, offering compliance with local regulations and providing access to a broader pool of global investors.
  
        **Key Features:**
        **Localised Compliance:** The platform will expand to new markets with compliance features tailored to regional financial regulations.
        **Global Investor Access:** Facilitates access to international investors and enables businesses to tokenise assets globally.
        **Multi-Language Support:** The platform will offer multi-language support to ensure a seamless experience for users from different countries.
      `,
      image: "/assets/images/zero-tokeniser/global-expansion.webp"
    },
  
    "Gamification Features": {
      title: "Gamification Features",
      description: `
        **Overview:**
        Introduce gamification elements such as badges, achievements, and leaderboards to enhance user engagement and promote the educational aspect of tokenisation.
  
        **Key Features:**
        **Badges and Achievements:** Users can earn badges based on their tokenisation activities, enhancing motivation and engagement.
        **Leaderboards:** Competitive elements that encourage users to perform better by seeing how they rank against others.
        **Educational Challenges:** Interactive learning experiences for users to better understand tokenisation and blockchain technologies.
      `,
      image: "/assets/images/zero-tokeniser/gamification-features.webp"
    },
  
    "Voice-Activated Tokenisation": {
      title: "Voice-Activated Tokenisation",
      description: `
        **Overview:**
        Enable users to interact with the platform and initiate tokenisation processes via voice commands, offering a hands-free user experience.
  
        **Key Features:**
        **Voice Commands:** Users can initiate tokenisation processes, request information, and manage portfolios using voice recognition technology.
        **Hands-Free Operations:** Offers a more accessible and user-friendly interface for users on the go or with disabilities.
        **Increased Efficiency:** Allows for faster execution of tokenisation processes without the need for manual input.
      `,
      image: "/assets/images/zero-tokeniser/voice-activated-tokenisation.webp"
    },
  
    "Social Tokenisation Communities": {
      title: "Social Tokenisation Communities",
      description: `
        **Overview:**
        Create a community-based feature where users can collaborate, share tokenisation strategies, and follow others in the tokenisation space.
  
        **Key Features:**
        **User Collaboration:** Facilitates networking and collaboration between tokenisation experts and newcomers.
        **Strategy Sharing:** Users can share their tokenisation strategies and insights, fostering a knowledge-sharing environment.
        **Follow and Learn:** Users can follow top-performing tokenisation projects and experts for inspiration and guidance.
      `,
      image: "/assets/images/zero-tokeniser/social-tokenisation.webp"
    },
  
    "Quantum Computing Compatibility": {
      title: "Quantum Computing Compatibility",
      description: `
        **Overview:**
        Prepare Zero-Tokeniser for the future by integrating quantum computing advancements to handle complex calculations and predictions faster and more securely.
  
        **Key Features:**
        **Enhanced Computational Power:** Quantum computing will enable faster and more efficient processing of complex tokenisation models and asset predictions.
        **Future-Proofing:** Ensures that Zero-Tokeniser remains at the forefront of technology as quantum computing advances.
        **Increased Security:** Quantum computing will enhance cryptographic security features, ensuring the protection of tokenised assets.
      `,
      image: "/assets/images/zero-tokeniser/quantum-computing-compatibility.webp"
    }
  };
  