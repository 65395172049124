// src/data/productsData.js

import zeroBlockchainImg from '../assets/Zero-Blockchain.webp';
import zeroFlowImg from '../assets/Zero-Flow.webp';
import zeroCryptoWalletImg from '../assets/Zero-Crypto-Wallet.webp';
import zeroPulseImg from '../assets/Zero-Pulse.webp';
import zeroWaveImg from '../assets/Zero-Wave.webp';
import zeroPayImg from '../assets/Zero-Pay.webp';
import zeroComplyImg from '../assets/Zero-Comply.webp';
import zeroSuperviseImg from '../assets/Zero-Supervise.webp';
import zeroLendImg from '../assets/Zero-Lend.webp';
import zeroCoreImg from '../assets/Zero-Core.webp';
import zeroInsureImg from '../assets/Zero-Insure.webp';
import zeroWealthImg from '../assets/Zero-Wealth.webp';
import zeroGreenImg from '../assets/Zero-Green.webp';
import zeroInsightImg from '../assets/Zero-Insight.webp';
import zeroTradeImg from '../assets/Zero-Trade.webp';
import zeroPredictImg from '../assets/Zero-Predict.webp';
import zeroStocksImg from '../assets/Zero-Stocks.webp';
import zeroTokeniserImg from '../assets/Zero-Tokeniser.webp';
import zeroStakeImg from '../assets/Zero-Stake.webp';
import zeroDexImg from '../assets/Zero-Dex.webp';
import zeroBankImg from '../assets/Zero-Bank.webp';

const products = [
  {
    id: 1,
    name: 'Zero-Blockchain',
    sector: 'Blockchain / Data Analysis',
    description: 
      'Zero-Blockchain powers cross-chain interoperability, token ecosystems, decentralised finance, and robust security protocols, enhancing blockchain performance and user engagement across diverse networks.',
    link: 'https://zero-blockchain.xyz',
    image: zeroBlockchainImg
  },
  {
    id: 2,
    name: 'Zero-Flow',
    sector: 'FinTech / Payment Integration',
    description: 
      'Zero-Flow streamlines traditional payment methods with modern web technologies. It delivers a secure and user-friendly environment for handling payments, invoicing, and cross-border transactions.',
    link: 'https://zero-flow.xyz',
    image: zeroFlowImg
  },
  {
    id: 3,
    name: 'Zero-Crypto-Wallet',
    sector: 'FinTech / DeFi / Crypto Wallet',
    description: 
      'Zero-Crypto-Wallet is a Web 3.0-enabled crypto solution for effortless asset management. Enjoy advanced blockchain integration, intuitive UI, and robust security for all your digital currencies.',
    link: 'https://zero-crypto-wallet.xyz',
    image: zeroCryptoWalletImg
  },
  {
    id: 4,
    name: 'Zero-Pulse',
    sector: 'FinTech / Markets / BankTech',
    description: 
      'Zero-Pulse offers real-time market analytics, portfolio tracking, and risk assessment tools. Optimise your financial strategies with up-to-date insights into assets and market movements.',
    link: 'https://zero-pulse.xyz',
    image: zeroPulseImg
  },
  {
    id: 5,
    name: 'Zero-Wave',
    sector: 'FinTech / DeFi / BankTech',
    description: 
      'Zero-Wave revolutionises mobile banking with an all-in-one wallet model for DeFi and fiat transactions. Experience seamless financial management, staking, and payments in one secure app.',
    link: 'https://zero-wave.xyz',
    image: zeroWaveImg
  },
  {
    id: 6,
    name: 'Zero-Pay',
    sector: 'PayTech / FinTech',
    description: 
      'Zero-Pay provides a global payment infrastructure that is fast, reliable, and highly scalable. It is optimised for cross-border transactions, giving users frictionless transfers and transparent fees.',
    link: 'https://zero-pay.xyz',
    image: zeroPayImg
  },
  {
    id: 7,
    name: 'Zero-Comply',
    sector: 'RegTech / FinTech',
    description: 
      'Zero-Comply automates regulatory compliance for financial institutions. It streamlines reporting, monitors policy updates, and reduces the risk of non-compliance through intelligent automation.',
    link: 'https://zero-comply.xyz',
    image: zeroComplyImg
  },
  {
    id: 8,
    name: 'Zero-Supervise',
    sector: 'SupTech / FinTech',
    description: 
      'Zero-Supervise provides cutting-edge supervision tools for financial regulators. It enhances market oversight, detects anomalies in real time, and ensures a resilient, well-regulated financial ecosystem.',
    link: 'https://zero-supervise.xyz',
    image: zeroSuperviseImg
  },
  {
    id: 9,
    name: 'Zero-Lend',
    sector: 'LendTech / FinTech',
    description: 
      'Zero-Lend offers blockchain-based peer-to-peer lending services, promoting transparency and trust. Borrowers get quick access to funds, while lenders enjoy secure and traceable lending opportunities.',
    link: 'https://zero-blockchain-lend.xyz',
    image: zeroLendImg
  },
  {
    id: 10,
    name: 'Zero-Core',
    sector: 'BankTech / FinTech',
    description: 
      'Zero-Core introduces advanced blockchain solutions for modern banking. With enhanced security, speed, and seamless user experiences, it aims to future-proof banks in a fast-evolving fintech landscape.',
    link: 'https://zero-core.xyz',
    image: zeroCoreImg
  },
  {
    id: 11,
    name: 'Zero-Insure',
    sector: 'InsurTech / FinTech',
    description: 
      'Zero-Insure leverages real-time data and predictive analytics to tailor personalised insurance policies. Experience dynamic coverage, reduced premiums, and streamlined claims processes.',
    link: 'https://zero-insure.xyz',
    image: zeroInsureImg
  },
  {
    id: 12,
    name: 'Zero-Wealth',
    sector: 'WealthTech / FinTech',
    description: 
      'Zero-Wealth merges traditional advisory with AI-driven insights for efficient wealth management. From portfolio optimisation to automated rebalancing, it empowers users to achieve long-term financial growth.',
    link: 'https://zero-wealth.xyz',
    image: zeroWealthImg
  },
  {
    id: 13,
    name: 'Zero-Green',
    sector: 'GreenTech / FinTech',
    description: 
      'Zero-Green champions sustainability by providing a secure and innovative crowdfunding platform for eco-friendly projects. Invest responsibly to help drive impactful environmental progress across various transformative green initiatives worldwide.',
    link: 'https://zero-green.xyz',
    image: zeroGreenImg
  },  
  {
    id: 14,
    name: 'Zero-Insight',
    sector: 'AI / FinTech',
    description: 
      'Zero-Insight delivers personalised financial guidance through advanced AI and big data analytics. It adapts dynamically to real-time market signals, ensuring users receive timely, accurate, and data-driven investment advice tailored to their needs.',
    link: 'https://zero-insight.xyz',
    image: zeroInsightImg
  },  
  {
    id: 15,
    name: 'Zero-Trade',
    sector: 'AI / Trading',
    description: 
      'Zero-Trade harnesses advanced algorithms to bolster trading strategies and maximise efficiency in real time. It supports equities, cryptocurrencies, and more, enabling traders to make smarter, informed decisions even under volatile market conditions.',
    link: 'https://zero-trade.xyz',
    image: zeroTradeImg
  },  
  {
    id: 16,
    name: 'Zero-Predict',
    sector: 'AI / Fraud Detection',
    description: 
      'Zero-Predict is your ultimate shield against digital threats and fraud. It utilises cutting-edge machine learning to detect suspicious activities and prevent fraud in Web 3.0 transactions. It safeguards digital assets, reinforces trust in decentralised ecosystems, and promotes secure blockchain adoption across industries worldwide.',
    link: 'https://zero-predict.xyz',
    image: zeroPredictImg
  },
  {
    id: 17,
    name: 'Zero-Stocks',
    sector: 'FinTech / Stock Trading',
    description: 
      'Zero-Stocks revolutionises trading with cutting-edge technology. Zero-Stocks combines AI with real-time market data to deliver a seamless and intuitive mobile trading experience. Access personalised insights, automated alerts, advanced charting tools, and tailored recommendations, empowering smarter investments.',
    link: 'https://zero-stocks.xyz',
    image: zeroStocksImg
  },     
  {
    id: 18,
    name: 'Zero-Tokeniser',
    sector: 'FinTech / Blockchain / Asset Management',
    description: 
      'Smart Contract-Powered Asset Tokenisation Platform. Zero-Tokeniser revolutionises asset management by enabling the tokenisation of real-world assets—real estate, equities, and commodities. Blockchain-backed transparency, compliance, and efficiency power each transaction.',
    link: 'https://zero-tokeniser.xyz',
    image: zeroTokeniserImg
  },
  {
    id: 19,
    name: 'Zero-Stake',
    sector: 'FinTech / Blockchain / DeFi',
    description: 
      'Zero-Stake empowers decentralised finance through robust staking, liquidity, and governance features. Built on blockchain, it delivers seamless staking rewards, cross-chain liquidity, transparent community-driven participation, and secure, scalable solutions for global DeFi ecosystems.',
    link: 'https://zero-stake.xyz',
    image: zeroStakeImg
  },  
  {
    id: 20,
    name: 'Zero-Dex',
    sector: 'FinTech / Blockchain / Decentralised Exchange',
    description: 
      'Zero-Dex redefines decentralised trading with secure, transparent, and efficient exchange services. Offering advanced liquidity pools, order book management, and cross-chain swaps, it enables trustless trading in a high-performance environment.',
    link: 'https://zero-dex.xyz',
    image: zeroDexImg
  },
  {
    id: 21,
    name: 'Zero-Bank',
    sector: 'BankTech / FinTech',
    description: 
      'Zero-Bank delivers a feature-rich desktop banking experience with robust digital security and seamless integration. From quick transfers to personal finance insights, it unifies all essential banking services within a modern interface, offering convenience, efficiency, and security for all users.',
    link: 'https://zero-bank.xyz',
    image: zeroBankImg
  },
  
];

export default products;
