export const CoreFeatures = [
  {
    title: "Cross-Chain Interoperability",
    description:
      "Facilitates seamless integration and token transfers between Ethereum, Binance Smart Chain, and other major blockchains.",
    image: "/assets/images/zero-blockchain/cross-chain.webp",
  },
  {
    title: "Zero-Coin Protocol",
    description:
      "Native cryptocurrency for payments, staking, governance, and transaction fees within the ecosystem.",
    image: "/assets/images/zero-blockchain/zero-coin.webp",
  },
  {
    title: "Zero-Tokens Ecosystem",
    description:
      "Conservation-driven ERC-20 tokens symbolising endangered species, contributing directly to ecological initiatives.",
    image: "/assets/images/zero-blockchain/zero-tokens.webp",
  },
  {
    title: "Zero-Bank Decentralised Platform",
    description:
      "A digital financial solution for cross-border payments, tokenised asset investments, and DeFi lending/borrowing.",
    image: "/assets/images/zero-blockchain/zero-bank.webp",
  },
  {
    title: "Gamified Conservation",
    description:
      "Engage in conservation efforts through gamified challenges and rewards in the form of Zero-Tokens.",
    image: "/assets/images/zero-blockchain/gamified-conservation.webp",
  },
  {
    title: "Asset Tokenisation Framework",
    description:
      "Supports the tokenisation of real-world assets like real estate, equities, and commodities for improved liquidity.",
    image: "/assets/images/zero-blockchain/asset-tokenisation.webp",
  },
];



export const TechnicalCapabilities = [
  {
    title: "Ethereum Compatibility",
    description:
      "Full ERC-20 and ERC-721 support, ensuring integration with Ethereum wallets and dApps.",
    image: "/assets/images/zero-blockchain/ethereum-compatibility.webp",
  },
  {
    title: "Hybrid Consensus Mechanism",
    description:
      "Combines Proof-of-Stake (PoS) and Byzantine Fault Tolerance (BFT) for scalable and secure transactions.",
    image: "/assets/images/zero-blockchain/hybrid-consensus.webp",
  },
  {
    title: "Smart Contract Automation",
    description:
      "Features reusable and customisable smart contracts for tokenisation, compliance, and governance.",
    image: "/assets/images/zero-blockchain/smart-contracts.webp",
  },
  {
    title: "Developer SDK and APIs",
    description:
      "Provides robust SDKs and APIs to support dApp development, token issuance, and blockchain integration.",
    image: "/assets/images/zero-blockchain/sdk-apis.webp",
  },
  {
    title: "Data Encryption and Multi-Sig Wallets",
    description:
      "Ensures asset security through AES-256 encryption and shared custody with multi-signature wallets.",
    image: "/assets/images/zero-blockchain/multi-sig-wallets.webp",
  },
  {
    title: "Layer-2 Integration",
    description:
      "Enhances scalability with faster transaction speeds and reduced costs using Layer-2 solutions.",
    image: "/assets/images/zero-blockchain/layer-2.webp",
  },
];

export const BusinessBenefits = [
  {
    title: "Increased Asset Liquidity",
    description:
      "Enables fractional ownership and trading of tokenised assets, unlocking liquidity for traditionally illiquid assets.",
    image: "/assets/images/zero-blockchain/asset-liquidity.webp",
  },
  {
    title: "Enhanced Revenue Streams",
    description:
      "Generates income through transaction fees, token sales, DeFi protocols, and NFT trading.",
    image: "/assets/images/zero-blockchain/revenue-streams.webp",
  },
  {
    title: "Regulatory Compliance Advantage",
    description:
      "Adheres to global financial regulations, including KYC/AML protocols, building trust and reducing legal risks.",
    image: "/assets/images/zero-blockchain/regulatory-compliance.webp",
  },
  {
    title: "Scalable Ecosystem",
    description:
      "Cloud-native infrastructure and modular architecture support business growth and evolving market demands.",
    image: "/assets/images/zero-blockchain/scalable-ecosystem.webp",
  },
  {
    title: "Conservation Partnerships",
    description:
      "Strengthens environmental initiatives through conservation-focused tokenisation and global NGO collaborations.",
    image: "/assets/images/zero-blockchain/conservation-partnerships.webp",
  },
  {
    title: "Improved Operational Efficiency",
    description:
      "Automates complex processes such as compliance, tokenisation, and governance, reducing costs and saving time.",
    image: "/assets/images/zero-blockchain/operational-efficiency.webp",
  },
];



export const SecurityAndCompliance = [
  {
    title: "AES-256 Encryption",
    description:
      "All user data and transactions are protected with AES-256 encryption, ensuring top-tier security for sensitive information.",
    image: "/assets/images/zero-blockchain/aes-encryption.webp",
  },
  {
    title: "Two-Factor Authentication (2FA)",
    description:
      "Adds an extra layer of security by requiring users to verify their identity using two-factor authentication.",
    image: "/assets/images/zero-blockchain/2fa-security.webp",
  },
  {
    title: "Regulatory Compliance",
    description:
      "Fully compliant with international standards such as GDPR, ISO 27001, KYC, and AML, ensuring a trusted and transparent ecosystem.",
    image: "/assets/images/zero-blockchain/regulatory-standards.webp",
  },
  {
    title: "Audit Logs and Monitoring",
    description:
      "Comprehensive logging of all system interactions for enhanced traceability and support during compliance checks.",
    image: "/assets/images/zero-blockchain/audit-logs.webp",
  },
  {
    title: "Role-Based Access Control (RBAC)",
    description:
      "Access permissions are assigned based on user roles, ensuring only authorised individuals can perform critical actions.",
    image: "/assets/images/zero-blockchain/rbac-control.webp",
  },
  {
    title: "Data Breach Notification System",
    description:
      "In the event of a security breach, users and administrators are promptly notified with actionable steps to mitigate risks.",
    image: "/assets/images/zero-blockchain/data-breach-notification.webp",
  },
  {
    title: "Immutable Ledger",
    description:
      "Leverages blockchain immutability to ensure that transaction records cannot be altered or tampered with, providing secure audit trails.",
    image: "/assets/images/zero-blockchain/immutable-ledger.webp",
  },
  {
    title: "Periodic Third-Party Audits",
    description:
      "Smart contracts and the Zero Blockchain infrastructure undergo regular security audits by independent firms to ensure robustness.",
    image: "/assets/images/zero-blockchain/third-party-audit.webp",
  },
  {
    title: "Fraud Detection and Prevention",
    description:
      "Machine learning algorithms detect unusual activities, such as multiple failed login attempts or suspicious transactions.",
    image: "/assets/images/zero-blockchain/fraud-detection.webp",
  },
  {
    title: "Compliance Automation",
    description:
      "Built-in tools for automated compliance reporting, including AML/KYC checks and tax withholding processes.",
    image: "/assets/images/zero-blockchain/compliance-automation.webp",
  },
  {
    title: "Zero-Knowledge Proofs (ZKPs)",
    description:
      "Incorporates zero-knowledge proof protocols to allow secure verification of sensitive data without revealing it, enhancing privacy and trust.",
    image: "/assets/images/zero-blockchain/zero-knowledge-proofs.webp",
  },
  {
    title: "Geo-Fencing for Transactions",
    description:
      "Implements geo-fencing technology to restrict transactions and account access based on location, providing an additional layer of control and security.",
    image: "/assets/images/zero-blockchain/geo-fencing.webp",
  },
];

export const GovernanceFeatures = [
  {
    title: "Decentralised Voting Mechanism",
    description:
      "Empowers Zero-Coin holders to vote on key protocol updates, ecosystem partnerships, and community-driven proposals through a decentralised and transparent system.",
    image: "/assets/images/zero-blockchain/decentralised-voting.webp",
  },
  {
    title: "On-Chain Governance Framework",
    description:
      "Implements an on-chain governance model where proposals, votes, and outcomes are permanently recorded on the blockchain for transparency and immutability.",
    image: "/assets/images/zero-blockchain/on-chain-governance.webp",
  },
  {
    title: "Staking-Weighted Influence",
    description:
      "Provides governance influence based on the amount of Zero-Coin staked, ensuring that active participants have a proportional say in decision-making.",
    image: "/assets/images/zero-blockchain/staking-weighted-governance.webp",
  },
  {
    title: "Dynamic Proposal System",
    description:
      "Allows users to submit, debate, and refine proposals before voting, fostering collaboration and ensuring high-quality decision-making.",
    image: "/assets/images/zero-blockchain/dynamic-proposal-system.webp",
  },
  {
    title: "Treasury Oversight Committee",
    description:
      "A community-elected group that manages the allocation of funds from the ecosystem treasury, ensuring alignment with long-term goals and sustainability.",
    image: "/assets/images/zero-blockchain/treasury-oversight.webp",
  },
  {
    title: "DAO Integration for Conservation",
    description:
      "Integrates decentralised autonomous organisation (DAO) functionality to enable community-driven funding for conservation projects using Zero-Tokens.",
    image: "/assets/images/zero-blockchain/dao-conservation.webp",
  },
];


export const KeyBenefits = [
  {
    title: "Financial Empowerment",
    description:
      "Zero Blockchain democratises access to financial tools, enabling individuals and businesses to benefit from DeFi lending, borrowing, and tokenisation opportunities.",
    image: "/assets/images/zero-blockchain/financial-empowerment.webp",
  },
  {
    title: "Sustainability Integration",
    description:
      "Combines blockchain technology with environmental conservation efforts, creating tangible ecological benefits through tokenised contributions.",
    image: "/assets/images/zero-blockchain/sustainability-integration.webp",
  },
  {
    title: "Enhanced Liquidity",
    description:
      "Facilitates the tokenisation of illiquid assets like real estate and commodities, allowing for fractional ownership and easier trading on decentralised exchanges.",
    image: "/assets/images/zero-blockchain/enhanced-liquidity.webp",
  },
  {
    title: "Decentralised Governance",
    description:
      "Empowers the community with decision-making authority through decentralised voting, ensuring alignment with user priorities and platform transparency.",
    image: "/assets/images/zero-blockchain/decentralised-governance.webp",
  },
  {
    title: "Low-Cost Cross-Border Transactions",
    description:
      "Eliminates the need for intermediaries, enabling fast, low-cost payments and remittances globally via Zero-Bank.",
    image: "/assets/images/zero-blockchain/cross-border-transactions.webp",
  },
  {
    title: "Real-Time Analytics",
    description:
      "Provides users with live performance metrics and actionable insights for tokenised assets and DeFi activities, supporting smarter decision-making.",
    image: "/assets/images/zero-blockchain/real-time-analytics.webp",
  },
  {
    title: "Community-Driven Conservation",
    description:
      "Supports conservation projects via Zero-Tokens, with proceeds funding global environmental initiatives and NGO partnerships.",
    image: "/assets/images/zero-blockchain/community-conservation.webp",
  },
  {
    title: "Comprehensive Security Framework",
    description:
      "Integrates advanced security protocols, including multi-signature wallets, AES-256 encryption, and two-factor authentication (2FA), ensuring asset and data safety.",
    image: "/assets/images/zero-blockchain/security-framework.webp",
  },
  {
    title: "Cross-Chain Interoperability",
    description:
      "Enables seamless token transfers and dApp integrations across Ethereum, Binance Smart Chain, Solana, and other leading networks.",
    image: "/assets/images/zero-blockchain/cross-chain-interoperability.webp",
  },
  {
    title: "Scalable and Future-Ready",
    description:
      "Utilises sharding, Layer-2 solutions, and quantum-resilient encryption to prepare for future blockchain advancements and scalability demands.",
    image: "/assets/images/zero-blockchain/scalable-future.webp",
  },
  {
    title: "Decentralised Identity Management",
    description:
      "Empowers users with self-sovereign identity solutions, enabling secure and private authentication for transactions and platform access.",
    image: "/assets/images/zero-blockchain/decentralised-identity.webp",
  },
  {
    title: "Customisable dApp Ecosystem",
    description:
      "Supports developers with tools and SDKs to create bespoke decentralised applications, enhancing the platform’s utility and adaptability.",
    image: "/assets/images/zero-blockchain/custom-dapp-ecosystem.webp",
  },
];

export const UseCases = [
  {
    title: "Tokenised Microfinance",
    description:
      "Enable peer-to-peer microloans through tokenisation, empowering underbanked communities with access to financial resources and reducing dependency on traditional intermediaries.",
    image: "/assets/images/zero-blockchain/tokenised-microfinance.webp",
  },
  {
    title: "Carbon Credit Tokenisation",
    description:
      "Transform carbon credits into blockchain-based tokens, ensuring transparency and liquidity in carbon trading markets to promote sustainable practices.",
    image: "/assets/images/zero-blockchain/carbon-credit-tokenisation.webp",
  },
  {
    title: "Supply Chain Transparency",
    description:
      "Enhance supply chain management by tokenising goods and raw materials, enabling real-time tracking, traceability, and authenticity verification.",
    image: "/assets/images/zero-blockchain/supply-chain-transparency.webp",
  },
  {
    title: "Decentralised Philanthropy",
    description:
      "Facilitate transparent and accountable donation management using blockchain-based tokenisation, ensuring funds reach intended recipients without corruption.",
    image: "/assets/images/zero-blockchain/decentralised-philanthropy.webp",
  },
  {
    title: "Tokenised Education Credentials",
    description:
      "Provide immutable, verifiable digital credentials for educational achievements, enabling seamless recognition across borders and industries.",
    image: "/assets/images/zero-blockchain/education-credentials.webp",
  },
  {
    title: "Healthcare Data Tokenisation",
    description:
      "Securely manage patient data on the blockchain, enabling controlled access and monetisation while maintaining privacy and compliance with regulations.",
    image: "/assets/images/zero-blockchain/healthcare-data-tokenisation.webp",
  },
];

export const EcosystemPartners = [
  {
    title: "WWF (World Wide Fund for Nature)",
    description:
      "Collaborates with Zero Blockchain to use Zero-Tokens for fundraising campaigns supporting endangered species conservation.",
    image: "/assets/images/zero-blockchain/wwf-partnership.webp",
  },
  {
    title: "UN Environment Programme",
    description:
      "Supports Zero Blockchain in developing tokenised solutions for carbon credit trading and sustainable projects.",
    image: "/assets/images/zero-blockchain/un-environment-partnership.webp",
  },
  {
    title: "Ethereum Foundation",
    description:
      "Provides technical support and collaboration for enhancing Ethereum compatibility with Zero Blockchain’s ecosystem.",
    image: "/assets/images/zero-blockchain/ethereum-foundation.webp",
  },
  {
    title: "Binance Smart Chain",
    description:
      "Facilitates cross-chain functionality for Zero Blockchain, enabling seamless integration with Binance Smart Chain.",
    image: "/assets/images/zero-blockchain/binance-partnership.webp",
  },
  {
    title: "Conservation International",
    description:
      "Partners with Zero Blockchain to direct proceeds from Zero-Tokens toward reforestation and anti-poaching initiatives.",
    image: "/assets/images/zero-blockchain/conservation-international.webp",
  },
  {
    title: "OpenSea",
    description:
      "Zero Blockchain works with OpenSea to enable trading and showcasing of NFT versions of Zero-Tokens.",
    image: "/assets/images/zero-blockchain/opensea-partnership.webp",
  },
];

export const DeveloperResources = [
  {
    title: "Zero Blockchain SDK",
    description:
      "A comprehensive software development kit to enable developers to create dApps, integrate Zero-Tokens, and build custom tokenisation solutions.",
    image: "/assets/images/zero-blockchain/sdk.webp",
  },
  {
    title: "API Documentation",
    description:
      "Detailed API documentation to simplify integration with Zero Blockchain for asset tokenisation, payments, and conservation tools.",
    image: "/assets/images/zero-blockchain/api-docs.webp",
  },
  {
    title: "Smart Contract Templates",
    description:
      "Pre-built smart contract templates for ERC-20, ERC-721, and ERC-1400 standards to accelerate development.",
    image: "/assets/images/zero-blockchain/smart-contract-templates.webp",
  },
  {
    title: "Developer Forum",
    description:
      "A community-driven forum where developers can share insights, ask questions, and collaborate on Zero Blockchain projects.",
    image: "/assets/images/zero-blockchain/developer-forum.webp",
  },
  {
    title: "Code Samples",
    description:
      "Ready-to-use code samples for integrating Zero Blockchain features like tokenisation, staking, and governance into existing applications.",
    image: "/assets/images/zero-blockchain/code-samples.webp",
  },
  {
    title: "Testnet Access",
    description:
      "Developers gain access to Zero Blockchain’s testnet for testing and deploying applications in a secure environment.",
    image: "/assets/images/zero-blockchain/testnet-access.webp",
  },
];

export const ConservationInitiatives = [
  {
    title: "Endangered Species Tokenisation",
    description:
      "Tokenise endangered species as Zero-Tokens, enabling users to contribute directly to conservation efforts.",
    image: "/assets/images/zero-blockchain/endangered-species.webp",
  },
  {
    title: "Gamified Reforestation",
    description:
      "Engage users in reforestation challenges and reward them with Zero-Tokens for their contributions to environmental sustainability.",
    image: "/assets/images/zero-blockchain/reforestation-challenges.webp",
  },
  {
    title: "Tokenised Conservation Funds",
    description:
      "Enable crowdfunded conservation initiatives by pooling resources through token sales, supporting NGOs and projects globally.",
    image: "/assets/images/zero-blockchain/conservation-funds.webp",
  },
  {
    title: "Partnerships with NGOs",
    description:
      "Collaborate with global environmental organisations to implement conservation projects funded by Zero-Tokens.",
    image: "/assets/images/zero-blockchain/ngo-partnerships.webp",
  },
  {
    title: "Carbon Credit Tokenisation",
    description:
      "Tokenise carbon credits to incentivise environmentally responsible behaviours and investments.",
    image: "/assets/images/zero-blockchain/carbon-credits.webp",
  },
  {
    title: "Conservation Education Campaigns",
    description:
      "Raise awareness about endangered species and environmental issues through Zero-Token-backed educational initiatives.",
    image: "/assets/images/zero-blockchain/conservation-education.webp",
  },
];

export const DeveloperBenefits = [
  {
    title: "Comprehensive SDKs",
    description:
      "Access Zero Blockchain's developer SDKs to create dApps, integrate tokens, and build innovative blockchain solutions.",
    image: "/assets/images/zero-blockchain/sdk-tools.webp",
  },
  {
    title: "Smart Contract Templates",
    description:
      "Utilise ready-made smart contract templates for asset tokenisation, staking, and conservation initiatives.",
    image: "/assets/images/zero-blockchain/smart-contract-templates.webp",
  },
  {
    title: "Multi-Chain Integration",
    description:
      "Build projects compatible with Ethereum, Binance Smart Chain, and other supported networks through Zero Blockchain's APIs.",
    image: "/assets/images/zero-blockchain/multi-chain-integration.webp",
  },
  {
    title: "Developer Community Support",
    description:
      "Join a thriving community of developers to collaborate, share resources, and receive technical support.",
    image: "/assets/images/zero-blockchain/community-support.webp",
  },
  {
    title: "Testnet Access",
    description:
      "Experiment and test your blockchain solutions on Zero Blockchain's dedicated testnet environment before deployment.",
    image: "/assets/images/zero-blockchain/testnet-access.webp",
  },
  {
    title: "Custom Tokenisation Tools",
    description:
      "Develop custom tokenisation solutions using Zero Blockchain's flexible APIs and modular smart contract framework.",
    image: "/assets/images/zero-blockchain/custom-tokenisation.webp",
  },
];

export const FinancialBenefits = [
  {
    title: "Cost Reduction",
    description:
      "Reduce transaction fees and operational costs with blockchain-based automation and elimination of intermediaries.",
    image: "/assets/images/zero-blockchain/cost-reduction.webp",
  },
  {
    title: "Increased Liquidity",
    description:
      "Unlock liquidity for traditionally illiquid assets through tokenisation and decentralised trading.",
    image: "/assets/images/zero-blockchain/increased-liquidity.webp",
  },
  {
    title: "Revenue Diversification",
    description:
      "Generate new revenue streams with token sales, staking rewards, and decentralised lending.",
    image: "/assets/images/zero-blockchain/revenue-diversification.webp",
  },
  {
    title: "Enhanced Transparency",
    description:
      "Improve financial reporting and compliance with blockchain’s immutable ledger for transactions.",
    image: "/assets/images/zero-blockchain/enhanced-transparency.webp",
  },
  {
    title: "Cross-Border Payments",
    description:
      "Facilitate fast, cost-effective global transactions using Zero-Coin and decentralised payment systems.",
    image: "/assets/images/zero-blockchain/cross-border-payments.webp",
  },
  {
    title: "Asset Fractionalisation",
    description:
      "Enable investors to access fractional ownership in high-value assets, broadening investment opportunities.",
    image: "/assets/images/zero-blockchain/asset-fractionalisation.webp",
  },
];

export const OperationalBenefits = [
  {
    title: "Process Automation",
    description:
      "Streamline operations through smart contracts, reducing manual interventions and increasing efficiency.",
    image: "/assets/images/zero-blockchain/process-automation.webp",
  },
  {
    title: "Improved Scalability",
    description:
      "Leverage blockchain’s scalable architecture to handle growing volumes of transactions and users.",
    image: "/assets/images/zero-blockchain/improved-scalability.webp",
  },
  {
    title: "Data Integrity",
    description:
      "Maintain accurate and tamper-proof records with blockchain’s immutable ledger.",
    image: "/assets/images/zero-blockchain/data-integrity.webp",
  },
  {
    title: "Real-Time Analytics",
    description:
      "Access up-to-the-minute data insights and analytics for informed decision-making.",
    image: "/assets/images/zero-blockchain/real-time-analytics.webp",
  },
  {
    title: "Seamless Integration",
    description:
      "Integrate easily with existing systems using APIs and SDKs designed for interoperability.",
    image: "/assets/images/zero-blockchain/seamless-integration.webp",
  },
  {
    title: "Risk Mitigation",
    description:
      "Reduce operational risks with decentralised, secure processes and compliance monitoring.",
    image: "/assets/images/zero-blockchain/risk-mitigation.webp",
  },
];

export const SocialBenefits = [
  {
    title: "Increased Financial Inclusion",
    description:
      "Empower underbanked populations by providing access to decentralised banking services and tokenised assets.",
    image: "/assets/images/zero-blockchain/financial-inclusion.webp",
  },
  {
    title: "Support for Conservation",
    description:
      "Promote global conservation efforts by linking blockchain technology to tangible environmental projects.",
    image: "/assets/images/zero-blockchain/conservation-support.webp",
  },
  {
    title: "Decentralised Collaboration",
    description:
      "Enable communities and organisations to collaborate on projects transparently through blockchain networks.",
    image: "/assets/images/zero-blockchain/decentralised-collaboration.webp",
  },
  {
    title: "Education and Awareness",
    description:
      "Raise awareness of conservation issues and blockchain's potential through gamification and NFT initiatives.",
    image: "/assets/images/zero-blockchain/education-awareness.webp",
  },
  {
    title: "Strengthened Community Engagement",
    description:
      "Foster stronger connections by incentivising social impact activities with Zero-Tokens.",
    image: "/assets/images/zero-blockchain/community-engagement.webp",
  },
  {
    title: "Democratised Access",
    description:
      "Ensure equitable access to financial tools and investment opportunities through tokenisation and DeFi platforms.",
    image: "/assets/images/zero-blockchain/democratised-access.webp",
  },
];

export const EnvironmentalBenefits = [
  {
    title: "Conservation Funding",
    description:
      "Channel revenue from token sales and NFT transactions directly to conservation projects worldwide.",
    image: "/assets/images/zero-blockchain/conservation-funding.webp",
  },
  {
    title: "Carbon Footprint Reduction",
    description:
      "Incentivise eco-friendly practices and promote carbon offset projects through blockchain integration.",
    image: "/assets/images/zero-blockchain/carbon-footprint-reduction.webp",
  },
  {
    title: "Reforestation Initiatives",
    description:
      "Encourage participation in tree-planting activities by rewarding users with Zero-Tokens.",
    image: "/assets/images/zero-blockchain/reforestation-initiatives.webp",
  },
  {
    title: "Wildlife Protection",
    description:
      "Support anti-poaching and habitat preservation efforts via funding from conservation-driven Zero-Tokens.",
    image: "/assets/images/zero-blockchain/wildlife-protection.webp",
  },
  {
    title: "Sustainable Development",
    description:
      "Enable sustainable investments through tokenised projects focused on renewable energy and green infrastructure.",
    image: "/assets/images/zero-blockchain/sustainable-development.webp",
  },
  {
    title: "Gamified Eco-Engagement",
    description:
      "Boost user participation in environmental challenges such as waste reduction and biodiversity restoration.",
    image: "/assets/images/zero-blockchain/gamified-eco-engagement.webp",
  },
];

export const EconomicBenefits = [
  {
    title: "Job Creation",
    description:
      "Generate employment opportunities in blockchain development, conservation management, and financial services through the Zero Blockchain ecosystem.",
    image: "/assets/images/zero-blockchain/job-creation.webp",
  },
  {
    title: "Inclusive Financial Access",
    description:
      "Provide underbanked populations with access to decentralised banking, lending, and investment opportunities.",
    image: "/assets/images/zero-blockchain/inclusive-financial-access.webp",
  },
  {
    title: "Global Investment Opportunities",
    description:
      "Enable individuals to participate in international markets through tokenised assets and cross-border payments.",
    image: "/assets/images/zero-blockchain/global-investment-opportunities.webp",
  },
  {
    title: "Increased Liquidity",
    description:
      "Enhance liquidity for traditionally illiquid assets such as real estate and commodities via tokenisation.",
    image: "/assets/images/zero-blockchain/increased-liquidity.webp",
  },
  {
    title: "Revenue Generation for NGOs",
    description:
      "Empower NGOs with new fundraising streams through the sale of conservation-driven Zero-Tokens.",
    image: "/assets/images/zero-blockchain/revenue-generation-ngos.webp",
  },
  {
    title: "Cost Savings in Transactions",
    description:
      "Reduce fees for international payments and asset transfers using blockchain technology.",
    image: "/assets/images/zero-blockchain/cost-savings-transactions.webp",
  },
];

export const EnvironmentalSustainabilityBenefits = [
  {
    title: "Carbon Credit Tokenisation",
    description:
      "Tokenise carbon credits to enable efficient trading and incentivise industries to adopt eco-friendly practices.",
    image: "/assets/images/zero-blockchain/carbon-credit-tokenisation.webp",
  },
  {
    title: "Deforestation Mitigation",
    description:
      "Generate funding for reforestation and anti-deforestation projects through gamified conservation challenges.",
    image: "/assets/images/zero-blockchain/deforestation-mitigation.webp",
  },
  {
    title: "Biodiversity Preservation",
    description:
      "Support conservation efforts for endangered species through the issuance of Zero-Tokens tied to specific biodiversity projects.",
    image: "/assets/images/zero-blockchain/biodiversity-preservation.webp",
  },
  {
    title: "Reduced Environmental Impact of Banking",
    description:
      "Minimise the environmental footprint of traditional banking by promoting decentralised, paperless digital financial systems.",
    image: "/assets/images/zero-blockchain/reduced-banking-impact.webp",
  },
  {
    title: "Blockchain for Renewable Energy",
    description:
      "Use blockchain to tokenise renewable energy credits, encouraging investment in clean energy projects.",
    image: "/assets/images/zero-blockchain/renewable-energy-tokenisation.webp",
  },
  {
    title: "Water Resource Management",
    description:
      "Enable efficient monitoring and funding of water conservation projects through transparent blockchain-based systems.",
    image: "/assets/images/zero-blockchain/water-resource-management.webp",
  },
];

export const RoadmapPhases = [
  {
    title: "Phase 1 (2024)",
    description: 
      "Deploy smart contracts, launch Zero-Tokens, initiate conservation fundraising campaigns, and set up foundational governance frameworks.",
    image: "/assets/images/zero-blockchain/phase1-2024.webp",
  },
  {
    title: "Phase 2 (2025)",
    description: 
      "Introduce staking, lending, and liquidity pools; expand Zero-Bank globally; and integrate advanced compliance tools.",
    image: "/assets/images/zero-blockchain/phase2-2025.webp",
  },
  {
    title: "Phase 3 (2026)",
    description: 
      "Launch NFT marketplace integrations, support tokenisation of carbon credits and real estate, and enable gamified conservation tools.",
    image: "/assets/images/zero-blockchain/phase3-2026.webp",
  },
  {
    title: "Phase 4 (2027)",
    description: 
      "Enable cross-chain bridges, Layer-2 integrations, advanced DAO governance, and community-driven conservation rewards.",
    image: "/assets/images/zero-blockchain/phase4-2027.webp",
  },
  {
    title: "Phase 5 (2028)",
    description: 
      "Expand tokenised offerings to include new asset classes, such as art and intellectual property, and build strategic partnerships with global NGOs.",
    image: "/assets/images/zero-blockchain/phase5-2028.webp",
  },
  {
    title: "Phase 6 (2029)",
    description: 
      "Introduce quantum-ready blockchain compatibility, real-time ESG impact analytics, and enhanced decentralised governance for Zero Blockchain.",
    image: "/assets/images/zero-blockchain/phase6-2029.webp",
  }
];

export const UserAdoptionPhases = [
  {
    title: "Phase 1: Community Building",
    description: "Initiate educational campaigns and build a core community through targeted outreach, social media presence, and webinars.",
    image: "/assets/images/zero-blockchain/user-adoption-phase1.webp",
  },
  {
    title: "Phase 2: Early Adopter Incentives",
    description: "Launch staking bonuses, NFT rewards, and exclusive access to new features to encourage early adoption.",
    image: "/assets/images/zero-blockchain/user-adoption-phase2.webp",
  },
  {
    title: "Phase 3: Referral Program",
    description: "Introduce referral bonuses and loyalty rewards to increase user engagement and grow the community.",
    image: "/assets/images/zero-blockchain/user-adoption-phase3.webp",
  },
  {
    title: "Phase 4: Gamified Campaigns",
    description: "Expand user engagement with gamified challenges and conservation rewards tied to tokenised activities.",
    image: "/assets/images/zero-blockchain/user-adoption-phase4.webp",
  },
  {
    title: "Phase 5: Partner Integrations",
    description: "Collaborate with platforms to offer exclusive benefits for Zero Blockchain users, such as discounts or exclusive access.",
    image: "/assets/images/zero-blockchain/user-adoption-phase5.webp",
  },
  {
    title: "Phase 6: Global User Expansion",
    description: "Target new user bases through regional campaigns, translations, and localisation efforts to make the platform globally accessible.",
    image: "/assets/images/zero-blockchain/user-adoption-phase6.webp",
  },
];

export const DeveloperEcosystemPhases = [
  {
    title: "Phase 1: SDK Launch",
    description: "Introduce a developer SDK with comprehensive documentation for building dApps and tokenisation projects.",
    image: "/assets/images/zero-blockchain/developer-phase1.webp",
  },
  {
    title: "Phase 2: Hackathons",
    description: "Host global hackathons to incentivise innovative projects and collaborations using Zero Blockchain.",
    image: "/assets/images/zero-blockchain/developer-phase2.webp",
  },
  {
    title: "Phase 3: Incentivised Development",
    description: "Provide grants and rewards for developers creating ecosystem-enhancing tools and integrations.",
    image: "/assets/images/zero-blockchain/developer-phase3.webp",
  },
  {
    title: "Phase 4: Developer Tools Expansion",
    description: "Launch advanced APIs and support libraries to ease integrations and improve developer experience.",
    image: "/assets/images/zero-blockchain/developer-phase4.webp",
  },
  {
    title: "Phase 5: Developer Community Building",
    description: "Foster a global community of developers through forums, mentorship programs, and regular updates.",
    image: "/assets/images/zero-blockchain/developer-phase5.webp",
  },
  {
    title: "Phase 6: Developer Certification",
    description: "Introduce a certification program to validate expertise in using Zero Blockchain's SDK and tools.",
    image: "/assets/images/zero-blockchain/developer-phase6.webp",
  },
];

export const InstitutionalPartnershipPhases = [
  {
    title: "Phase 1: NGO Collaborations",
    description: "Partner with conservation NGOs to implement tokenised conservation projects on the platform.",
    image: "/assets/images/zero-blockchain/institutional-phase1.webp",
  },
  {
    title: "Phase 2: Financial Institution Pilots",
    description: "Engage with banks and financial institutions to test and adopt tokenisation solutions.",
    image: "/assets/images/zero-blockchain/institutional-phase2.webp",
  },
  {
    title: "Phase 3: Regulator Partnerships",
    description: "Work closely with regulators to ensure platform compliance and align with industry standards.",
    image: "/assets/images/zero-blockchain/institutional-phase3.webp",
  },
  {
    title: "Phase 4: Public-Private Partnerships",
    description: "Collaborate with governments and enterprises to expand Zero Blockchain into supply chain and infrastructure tokenisation.",
    image: "/assets/images/zero-blockchain/institutional-phase4.webp",
  },
  {
    title: "Phase 5: Corporate Sponsorships",
    description: "Attract corporate sponsors to drive innovation and fund large-scale conservation initiatives.",
    image: "/assets/images/zero-blockchain/institutional-phase5.webp",
  },
  {
    title: "Phase 6: Industry Alliances",
    description: "Form alliances with blockchain consortia to advance shared goals in DeFi and tokenisation.",
    image: "/assets/images/zero-blockchain/institutional-phase6.webp",
  },
];




export const EnhancedTokenUtilityPhases = [
  {
    title: "Phase 1: Expand Staking Options",
    description: 
      "Introduce enhanced staking mechanisms and reward options for Zero-Coin and Zero-Tokens, incentivising long-term engagement.",
    image: "/assets/images/zero-blockchain/token-staking.webp",
  },
  {
    title: "Phase 2: Partner Payment Integration",
    description: 
      "Enable Zero-Coin as a payment method across partner platforms, e-commerce sites, and marketplaces.",
    image: "/assets/images/zero-blockchain/partner-payment.webp",
  },
  {
    title: "Phase 3: Token-Backed Loans",
    description: 
      "Launch utility features such as token-backed loans, collateralisation services, and credit-based staking.",
    image: "/assets/images/zero-blockchain/token-loans.webp",
  },
  {
    title: "Phase 4: Multi-Chain Governance",
    description: 
      "Establish Zero-Coin as a governance token standard across multiple blockchain networks, empowering decentralised decision-making.",
    image: "/assets/images/zero-blockchain/multi-chain-governance.webp",
  },
  {
    title: "Phase 5: In-App Utility Expansion",
    description: 
      "Integrate Zero-Coin into in-app purchases, subscription services, and ecosystem-exclusive features.",
    image: "/assets/images/zero-blockchain/in-app-utility.webp",
  },
  {
    title: "Phase 6: Ecosystem Rewards",
    description: 
      "Implement a dynamic reward system for Zero-Coin users who actively contribute to the ecosystem, such as developers and conservationists.",
    image: "/assets/images/zero-blockchain/ecosystem-rewards.webp",
  }
];


export const ESGAndComplianceLeadershipPhases = [
  {
    title: "Phase 1: Conservation Reporting",
    description: 
      "Publish compliance-ready conservation reports showcasing measurable environmental impact across all initiatives.",
    image: "/assets/images/zero-blockchain/conservation-reporting.webp",
  },
  {
    title: "Phase 2: ESG Scoring Integration",
    description: 
      "Integrate ESG scoring metrics into tokenised asset offerings, providing users with transparent environmental, social, and governance data.",
    image: "/assets/images/zero-blockchain/esg-scoring.webp",
  },
  {
    title: "Phase 3: Global ESG Collaborations",
    description: 
      "Collaborate with leading ESG organisations to establish benchmarks and best practices for blockchain-integrated ESG initiatives.",
    image: "/assets/images/zero-blockchain/global-esg-collaboration.webp",
  },
  {
    title: "Phase 4: Blockchain-Ledger Auditing",
    description: 
      "Enable ESG transparency through blockchain-based auditing systems that provide immutable records of compliance and impact.",
    image: "/assets/images/zero-blockchain/blockchain-auditing.webp",
  },
  {
    title: "Phase 5: Carbon Credit Tokenisation",
    description: 
      "Introduce tokenised carbon credits to incentivise sustainable practices and allow users to offset their carbon footprints.",
    image: "/assets/images/zero-blockchain/carbon-credit-tokenisation.webp",
  },
  {
    title: "Phase 6: ESG Certification Programmes",
    description: 
      "Develop ESG certification programmes for tokenised projects, allowing businesses and individuals to earn badges for sustainability efforts.",
    image: "/assets/images/zero-blockchain/esg-certification.webp",
  }
];


export const PublicAndDAOGovernancePhases = [
  {
    title: "Phase 1: Governance Frameworks",
    description: 
      "Launch governance frameworks allowing Zero-Coin holders to participate in key decisions through voting and proposal reviews.",
    image: "/assets/images/zero-blockchain/governance-framework.webp",
  },
  {
    title: "Phase 2: DAO Voting",
    description: 
      "Introduce decentralised voting mechanisms for protocol upgrades, ecosystem changes, and allocation of treasury funds.",
    image: "/assets/images/zero-blockchain/dao-voting.webp",
  },
  {
    title: "Phase 3: Public Proposal System",
    description: 
      "Implement a proposal system enabling community-driven suggestions for new features and enhancements to the ecosystem.",
    image: "/assets/images/zero-blockchain/public-proposals.webp",
  },
  {
    title: "Phase 4: Fully Decentralised Governance",
    description: 
      "Transition the Zero Blockchain ecosystem to a fully decentralised governance model, empowering the community with decision-making authority.",
    image: "/assets/images/zero-blockchain/decentralised-governance.webp",
  },
  {
    title: "Phase 5: Token-Based Voting Incentives",
    description: 
      "Introduce token-based rewards to incentivise active participation in governance processes and voting activities.",
    image: "/assets/images/zero-blockchain/voting-incentives.webp",
  },
  {
    title: "Phase 6: Cross-Chain Governance Integration",
    description: 
      "Expand governance capabilities to support multi-chain ecosystems, enabling cross-chain participation in DAO activities.",
    image: "/assets/images/zero-blockchain/cross-chain-governance.webp",
  }
];


export const FutureEnhancements = [
  {
    title: "ZeroDAO Governance Framework",
    description:
      "Introduce decentralised governance, empowering Zero-Coin holders to influence key decisions within the ecosystem.",
    image: "/assets/images/zero-blockchain/dao-framework.webp",
  },
  {
    title: "Quantum Computing Integration",
    description:
      "Prepare for quantum advancements by ensuring compatibility with future computational paradigms.",
    image: "/assets/images/zero-blockchain/quantum-computing.webp",
  },
  {
    title: "Expanded Conservation Initiatives",
    description:
      "Launch new conservation tokens and gamified challenges to expand ecological impact.",
    image: "/assets/images/zero-blockchain/conservation-expansion.webp",
  },
  {
    title: "Educational Outreach and Certifications",
    description:
      "Develop workshops and certification programs for businesses and developers adopting Zero Blockchain.",
    image: "/assets/images/zero-blockchain/education-certifications.webp",
  },
  {
    title: "Carbon Credit Tokenisation",
    description:
      "Support sustainability efforts by tokenising carbon credits for trading and investment.",
    image: "/assets/images/zero-blockchain/carbon-credits.webp",
  },
  {
    title: "Cross-Chain Expansion",
    description:
      "Enable seamless interoperability with emerging blockchain networks, increasing ecosystem accessibility and utility.",
    image: "/assets/images/zero-blockchain/cross-chain-expansion.webp",
  }
];





