export const CoreFeatureDetails = {
    "Hyper-Personalised Financial Advice": {
      title: "Hyper-Personalised Financial Advice",
      description: `
        Leverage AI-driven insights that are tailored to individual goals, risk tolerance, and financial situations, offering bespoke financial guidance for every user.
        
        **Key Features:**
        **AI-Driven Personalisation:** Custom recommendations for savings, investments, and risk management.
        **User-Centric Advice:** Every user gets unique guidance, not a one-size-fits-all approach.
        **Real-Time Adjustments:** Recommendations update in real-time as financial conditions change.
      `,
      image: "/assets/images/zero-insight/hyper-personalised-advice.webp"
    },
  
    "Digital Twin Financial Modeling": {
      title: "Digital Twin Financial Modeling",
      description: `
        Simulate future financial scenarios using a digital twin of your financial state, allowing users to explore potential impacts from life changes or market shifts.
        
        **Key Features:**
        **Digital Twin Simulations:** Create a "digital twin" of your financial state to visualise potential future outcomes.
        **Scenario Modeling:** Simulate job loss, market crashes, or lifestyle changes to assess the impact on your finances.
        **Smarter Decision-Making:** Use simulation results to make informed decisions before taking real-world action.
      `,
      image: "/assets/images/zero-insight/digital-twin.webp"
    },
  
    "Interactive Scenario Analysis": {
      title: "Interactive Scenario Analysis",
      description: `
        Analyze "what-if" financial scenarios to visualise the potential effects of market volatility, inflation, and other economic factors on financial goals.
        
        **Key Features:**
        **Custom Scenario Planning:** Create custom scenarios to model specific life events, like inflation or job changes.
        **Risk Assessment Tools:** Test your portfolio's resilience to market volatility or economic uncertainty.
        **Impact Visualisation:** Instantly see how different factors affect your wealth, savings, and investment trajectory.
      `,
      image: "/assets/images/zero-insight/scenario-analysis.webp"
    },
  
    "Real-Time Market Insights": {
      title: "Real-Time Market Insights",
      description: `
        Stay ahead of the market with AI-driven predictive analytics, providing users with real-time alerts and proactive financial insights.
        
        **Key Features:**
        **Live Market Alerts:** Get notified of key market changes or risks that may impact your portfolio.
        **Predictive Market Analysis:** AI forecasts potential market changes, allowing for proactive investment decisions.
        **Personalised News Feed:** Receive curated financial news relevant to your portfolio and goals.
      `,
      image: "/assets/images/zero-insight/market-insights.webp"
    },
  
    "Interactive Dashboards": {
      title: "Interactive Dashboards",
      description: `
        Access an interactive dashboard that provides users with a comprehensive view of their financial health, risk exposure, and goal progress.
        
        **Key Features:**
        **Personalised Dashboards:** View your financial status, including savings goals, risk exposure, and net worth.
        **Real-Time Insights:** Live data updates keep you aware of changes to your financial plan.
        **Customisable View:** Tailor the dashboard to highlight the most important data for your goals.
      `,
      image: "/assets/images/zero-insight/interactive-dashboards.webp"
    },
  
    "AI-Driven Decision Support": {
      title: "AI-Driven Decision Support",
      description: `
        Empower users to make smarter, faster decisions with the help of predictive analytics and AI-driven financial guidance.
        
        **Key Features:**
        **Predictive Analytics:** AI models predict potential outcomes of financial decisions to reduce uncertainty.
        **Decision Support Tools:** Access tools to help with investment, savings, and financial planning decisions.
        **Proactive Guidance:** Receive step-by-step suggestions for key financial actions, like rebalancing investments.
      `,
      image: "/assets/images/zero-insight/ai-decision-support.webp"
    }
  };

  
  export const TechnicalCapabilitiesDetails = {
    "Microservices Architecture": {
      title: "Microservices Architecture",
      description: `
        A modular, microservices-based architecture allows for seamless updates and independent service scaling, enabling unparalleled flexibility.
        
        **Key Features:**
        **Modular Service Design:** Each feature operates as a self-contained service, allowing for faster updates and independent scaling.
        **Reduced Downtime:** Updates can be rolled out without affecting other components of the system.
        **Seamless Integration:** Integrate with third-party tools and APIs with minimal disruption to the core platform.
      `,
      image: "/assets/images/zero-insight/microservices-architecture.webp"
    },
  
    "AI & Machine Learning Models": {
      title: "AI & Machine Learning Models",
      description: `
        Advanced AI and machine learning models drive personalised financial advice, delivering predictive insights and accurate recommendations.
        
        **Key Features:**
        **Continuous Learning Models:** AI adapts to user behavior and financial trends to provide smarter predictions.
        **Predictive Intelligence:** Advanced AI identifies future market shifts, enabling proactive decision-making.
        **Behavioral Analysis:** Insights are tailored based on user interactions, creating hyper-personalised recommendations.
      `,
      image: "/assets/images/zero-insight/ai-ml-models.webp"
    },
  
    "Data Lakes & Warehousing": {
      title: "Data Lakes & Warehousing",
      description: `
        Securely store and manage all financial data using data lakes and data warehouses, enabling real-time analytics and batch processing.
        
        **Key Features:**
        **Real-Time Analytics:** Real-time access to financial data enables instant insights and up-to-date financial reports.
        **Batch Processing:** Run large-scale data processing tasks efficiently for reports, audits, and compliance tracking.
        **Data Governance:** Enforce strict data security and privacy standards to comply with global regulations.
      `,
      image: "/assets/images/zero-insight/data-warehousing.webp"
    },
  
    "Blockchain-Powered Security": {
      title: "Blockchain-Powered Security",
      description: `
        Blockchain technology powers immutable records, ensuring the security of smart contracts, fee payments, and advisory agreements.
        
        **Key Features:**
        **Immutable Ledger:** Transactions and records are immutable, ensuring complete transparency and accountability.
        **Smart Contract Security:** Self-executing contracts protect against fraud and unauthorised modifications.
        **Audit Trails:** View a verifiable history of key financial transactions for audit and compliance purposes.
      `,
      image: "/assets/images/zero-insight/blockchain-security.webp"
    },
  
    "Cloud-Native Infrastructure": {
      title: "Cloud-Native Infrastructure",
      description: `
        Cloud-native infrastructure supports AWS, Azure, and GCP, providing global availability, scalability, and redundancy.
        
        **Key Features:**
        **Global Cloud Deployment:** Host services on AWS, Azure, or GCP, ensuring high availability and global coverage.
        **Fault-Tolerant Design:** Reduce downtime with built-in redundancy and failover support.
        **Elastic Scalability:** Instantly scale resources up or down to match user demand, ensuring cost efficiency.
      `,
      image: "/assets/images/zero-insight/cloud-infrastructure.webp"
    },
  
    "Predictive Analytics Engine": {
      title: "Predictive Analytics Engine",
      description: `
        Advanced predictive analytics detect financial risks, forecast performance, and uncover emerging opportunities in real time.
        
        **Key Features:**
        **Risk Forecasting:** Identify potential financial risks and avoid losses with predictive risk analysis.
        **Opportunity Detection:** Spot emerging investment opportunities before they hit the mainstream.
        **Performance Insights:** Get a comprehensive view of financial performance to guide strategic decision-making.
      `,
      image: "/assets/images/zero-insight/predictive-analytics.webp"
    }
  };


  export const KeyBenefitsDetails = {
    "Personalised Investment Advice": {
      title: "Personalised Investment Advice",
      description: `
        Receive hyper-personalised investment strategies tailored to your unique financial goals and risk appetite, providing a truly bespoke financial experience.
        
        **Key Features:**
        **AI-Driven Personalisation:** Tailor investment plans to individual user needs, including financial goals, life events, and risk tolerance.
        **Dynamic Adjustment:** Recommendations evolve as user behavior, market conditions, and life changes occur.
        **Bespoke Strategy Building:** Each user receives a unique, custom-built financial strategy for wealth growth.
      `,
      image: "/assets/images/zero-insight/personalised-advice.webp"
    },
  
    "Faster Decision-Making": {
      title: "Faster Decision-Making",
      description: `
        AI-driven predictive analytics provide real-time insights, empowering users to make timely and effective financial decisions.
        
        **Key Features:**
        **Real-Time Alerts:** Receive instant alerts when market conditions shift, ensuring faster financial decisions.
        **Decision Support Systems:** Get AI-generated recommendations to avoid missed investment opportunities.
        **Speed-to-Action:** Accelerate decision-making through instant data-driven insights and opportunities.
      `,
      image: "/assets/images/zero-insight/decision-making.webp"
    },
  
    "Stress-Test Financial Plans": {
      title: "Stress-Test Financial Plans",
      description: `
        Test the resilience of financial plans against market volatility, inflation, and other unpredictable events to ensure financial stability.
        
        **Key Features:**
        **Scenario Analysis:** Run 'what-if' scenarios, like inflation, job loss, or major expenses, to prepare for financial disruptions.
        **Resilience Testing:** Assess the ability of financial plans to handle extreme market volatility and life changes.
        **Dynamic Simulations:** Simulate potential economic events and visualise the impact on financial health.
      `,
      image: "/assets/images/zero-insight/stress-testing.webp"
    },
  
    "Reduce Advisory Costs": {
      title: "Reduce Advisory Costs",
      description: `
        Cut advisory costs by relying on AI-driven guidance, democratizing access to quality financial advice for all users.
        
        **Key Features:**
        **Cost-Effective Financial Advice:** Access top-tier advisory services at a fraction of the traditional cost.
        **Reduced Reliance on Human Advisors:** AI handles investment advice, freeing users from paying costly advisory fees.
        **Universal Access:** Democratize financial advice, making premium guidance available to all, regardless of wealth level.
      `,
      image: "/assets/images/zero-insight/advisory-costs.webp"
    },
  
    "Regulatory Compliance": {
      title: "Regulatory Compliance",
      description: `
        Comply with GDPR, CCPA, and other data privacy regulations to protect user data and ensure legal adherence.
        
        **Key Features:**
        **GDPR & CCPA Compliance:** Ensure compliance with global data privacy laws, protecting users' sensitive financial data.
        **Automated Compliance Monitoring:** Track and audit compliance in real time with automated policy enforcement.
        **User Consent & Data Rights:** Empower users to access, manage, and delete their personal data as required by law.
      `,
      image: "/assets/images/zero-insight/compliance.webp"
    },
  
    "Enhanced User Experience": {
      title: "Enhanced User Experience",
      description: `
        Deliver personalised dashboards, easy-to-read insights, and intuitive navigation, ensuring a seamless user experience.
        
        **Key Features:**
        **Personalised Dashboards:** Customisable dashboards provide users with data visualisations that matter most to them.
        **Simplified Navigation:** Intuitive interface design ensures users can find critical financial data in a few clicks.
        **Insight-Rich Experiences:** Users receive clear, concise insights that are easy to understand, reducing complexity.
      `,
      image: "/assets/images/zero-insight/user-experience.webp"
    }
  };

  
  export const UseCasesDetails = {
    "Retail Investors": {
      title: "Retail Investors",
      description: `
        Help retail investors access financial planning tools, goal tracking, and personalised investment advice, empowering individuals to achieve financial well-being.
        
        **Key Features:**
        **Personalised Financial Planning:** Retail investors can create tailored investment strategies and set personalised financial goals.
        **Goal Tracking:** Track progress towards milestones like home purchases, education, or retirement.
        **On-Demand Insights:** Access real-time updates and personalised investment advice, supporting smarter financial decisions.
      `,
      image: "/assets/images/zero-insight/retail-investors.webp"
    },
  
    "Financial Advisors": {
      title: "Financial Advisors",
      description: `
        Empower advisors with real-time AI support to enhance their advisory services with personalised client insights, improving the quality of advisory interactions.
        
        **Key Features:**
        **AI-Powered Client Insights:** Enable advisors to access predictive insights and recommendations for each client.
        **Personalised Client Reports:** Generate detailed financial analysis and recommendations for advisory sessions.
        **Advisory Augmentation:** Enhance traditional human advisory with real-time, AI-driven client insights and forecasts.
      `,
      image: "/assets/images/zero-insight/financial-advisors.webp"
    },
  
    "Institutional Clients": {
      title: "Institutional Clients",
      description: `
        Institutional investors can integrate Zero-Insight into internal platforms to power personalised advisory at scale, offering their clients bespoke investment experiences.
        
        **Key Features:**
        **Enterprise-Grade Integration:** Plug Zero-Insight directly into internal institutional platforms.
        **Custom Advisory Models:** Deploy personalised investment models tailored to institutional client needs.
        **Mass Advisory Scalability:** Scale advisory services to thousands of clients with personalised investment advice.
      `,
      image: "/assets/images/zero-insight/institutional-clients.webp"
    },
  
    "Corporate Clients": {
      title: "Corporate Clients",
      description: `
        Help companies provide employee financial wellness programs as part of a corporate benefits package, supporting employee financial well-being and literacy.
        
        **Key Features:**
        **Employee Financial Wellness:** Provide employees with financial literacy resources and goal-setting tools.
        **Corporate Benefits Integration:** Enable companies to embed financial wellness tools into their benefits platform.
        **Employee Goal Tracking:** Allow employees to track financial milestones like home savings, retirement, and debt reduction.
      `,
      image: "/assets/images/zero-insight/corporate-clients.webp"
    },
  
    "Wealth Management Firms": {
      title: "Wealth Management Firms",
      description: `
        Wealth managers can leverage predictive analytics for better investment strategies and portfolio rebalancing, ensuring superior returns for their clients.
        
        **Key Features:**
        **Predictive Portfolio Analysis:** Access AI-driven predictive analysis for rebalancing client portfolios.
        **Advanced Rebalancing Tools:** Automatically rebalance client portfolios in response to market volatility.
        **Risk Optimisation Models:** Implement models that optimise risk exposure and enhance client returns.
      `,
      image: "/assets/images/zero-insight/wealth-management.webp"
    },
  
    "Fintech Integration": {
      title: "Fintech Integration",
      description: `
        Embed Zero-Insight’s advisory engine into fintech platforms to power financial advice directly within the app, boosting user engagement and loyalty.
        
        **Key Features:**
        **Embedded Advisory Services:** Integrate Zero-Insight’s advisory engine into existing fintech platforms.
        **API-Driven Connectivity:** Seamlessly connect fintech apps with Zero-Insight via APIs for real-time advisory services.
        **Branded Advisory Tools:** Enable fintech apps to offer white-labeled advisory tools powered by Zero-Insight’s engine.
      `,
      image: "/assets/images/zero-insight/fintech-integration.webp"
    }
  };

  
  export const SecurityAndComplianceDetails = {
    "GDPR & CCPA Compliance": {
      title: "GDPR & CCPA Compliance",
      description: `
        Ensures adherence to GDPR and CCPA regulations, enabling user control over personal data and privacy rights. By implementing GDPR and CCPA compliance measures, ZeroInsight promotes transparency and user empowerment.
        
        **Key Features:**
        **Data Privacy Controls:** Provides users with control over their personal data, including the right to access, correct, or delete it.
        **Compliance Management:** Maintains full compliance with GDPR and CCPA legal requirements, ensuring smooth operations in multiple jurisdictions.
        **User Consent & Preferences:** Captures user consent before data collection and offers opt-out options for users at any time.
      `,
      image: "/assets/images/zero-insight/gdpr-compliance.webp"
    },
  
    "2FA & Role-Based Access Control (RBAC)": {
      title: "2FA & Role-Based Access Control (RBAC)",
      description: `
        Enhance platform security with multi-factor authentication and access restrictions based on user roles. This system ensures that only authorised users can access sensitive information and features.
        
        **Key Features:**
        **Multi-Factor Authentication (2FA):** Adds an extra layer of security using SMS, email, or biometric authentication for user login.
        **Role-Based Access Control (RBAC):** Restricts user access to only the functions, resources, and data relevant to their roles.
        **Session Management:** Actively monitors and restricts user sessions, limiting access duration and enhancing platform security.
      `,
      image: "/assets/images/zero-insight/access-control.webp"
    },
  
    "Data Minimisation & Pseudonymisation": {
      title: "Data Minimisation & Pseudonymisation",
      description: `
        User data is pseudonymises where possible, ensuring privacy and reducing the risk of data breaches. This approach is critical to enhancing user privacy and reducing exposure to cyber threats.
        
        **Key Features:**
        **Pseudonymises Data:** Personal identifiers are replaced with pseudonyms, limiting the risk of data misuse in case of a breach.
        **Minimal Data Collection:** Only essential user data is collected, supporting compliance with GDPR's data minimisation principle.
        **Anonymises Analytics:** Aggregate and anonymise user data for analysis, ensuring compliance while maintaining business intelligence capabilities.
      `,
      image: "/assets/images/zero-insight/data-minimisation.webp"
    },
  
    "Audit Trails & Logging": {
      title: "Audit Trails & Logging",
      description: `
        Comprehensive audit logs record user and system actions, supporting transparency and regulatory oversight. This feature strengthens accountability and enables post-incident investigation.
        
        **Key Features:**
        **Comprehensive Audit Logs:** Tracks all user actions, system changes, and API interactions for a complete operational history.
        **Tamper-Proof Records:** Ensures audit logs are immutable, preventing unauthorised edits and providing an unalterable history of system events.
        **Regulatory Compliance Reports:** Generate compliance reports for regulatory bodies or internal audits to demonstrate platform accountability.
      `,
      image: "/assets/images/zero-insight/audit-trails.webp"
    },
  
    "Secure API Endpoints": {
      title: "Secure API Endpoints",
      description: `
        All API endpoints are secured with HTTPS encryption and API key verification to prevent unauthorised access. These measures ensure safe and encrypted communication between users, partners, and the ZeroInsight platform.
        
        **Key Features:**
        **API Key Authentication:** Verifies client access using unique API keys and restricts usage to authorised entities.
        **HTTPS Encryption:** Encrypts all API communications using HTTPS to prevent eavesdropping and man-in-the-middle attacks.
        **Rate Limiting & DDoS Protection:** Implements API rate limiting to prevent abuse and distributed denial-of-service (DDoS) attacks.
      `,
      image: "/assets/images/zero-insight/secure-api.webp"
    },
  
    "Data Encryption (AES-256)": {
      title: "Data Encryption (AES-256)",
      description: `
        All financial and personal data is encrypted using AES-256 to ensure maximum security and privacy compliance. By employing one of the strongest encryption standards, ZeroInsight ensures user data confidentiality and protection.
        
        **Key Features:**
        **AES-256 Encryption:** Applies advanced AES-256 encryption to all sensitive user data, both in transit and at rest.
        **Database Encryption:** Ensures that data stored in databases is fully encrypted, protecting it from unauthorised access.
        **End-to-End Encryption:** Encrypts data from the user's device to the server, ensuring no interception of sensitive information.
      `,
      image: "/assets/images/zero-insight/data-encryption.webp"
    }
  };

  
  export const BusinessBenefitsDetails = {
    "Scalable Advisory Services": {
      title: "Scalable Advisory Services",
      description: `
        Scale personalised financial advisory services to thousands of users with AI-powered insights and automation. Deliver highly personalised advice at scale without the need for large teams of human advisors.
        
        **Key Features:**
        **AI-Powered Scalability:** Utilise AI models to deliver personalised financial advice to thousands of users simultaneously.
        **Unlimited Growth Potential:** Support an unlimited number of clients without requiring proportional increases in advisory resources.
        **Enhanced User Reach:** Expand financial advisory services to underserved markets, providing access to advice for users of all wealth levels.
      `,
      image: "/assets/images/zero-insight/scalable-advisory.webp"
    },
  
    "Reduced Operational Costs": {
      title: "Reduced Operational Costs",
      description: `
        Reduce costs with AI-driven automation for advisory services, customer support, and risk analysis, minimising reliance on human advisors. Automating repetitive processes leads to substantial cost savings for financial institutions.
        
        **Key Features:**
        **AI Automation:** Reduce the need for human intervention in advisory services, allowing more cost-efficient operations.
        **Risk Analysis Automation:** Use predictive models to automate financial risk assessments, reducing operational complexity.
        **Customer Service Chatbots:** Integrate AI chatbots to handle common user queries, minimising support costs while maintaining responsiveness.
      `,
      image: "/assets/images/zero-insight/reduced-costs.webp"
    },
  
    "Faster Go-To-Market": {
      title: "Faster Go-To-Market",
      description: `
        Speed up product launches with ZeroInsight’s ready-to-deploy advisory platform and API integrations. Companies can enter new markets or launch new products with minimal development and infrastructure costs.
        
        **Key Features:**
        **API-Driven Integration:** Easily integrate ZeroInsight’s advisory features into existing fintech platforms and banking systems.
        **Pre-Built Templates:** Launch new advisory features using pre-built templates for faster deployment.
        **Low Development Overhead:** Skip months of development with plug-and-play advisory modules that are ready to deploy.
      `,
      image: "/assets/images/zero-insight/fast-go-to-market.webp"
    },
  
    "Revenue Growth from White-Label Solutions": {
      title: "Revenue Growth from White-Label Solutions",
      description: `
        monetise white-label opportunities by licensing the ZeroInsight platform to financial institutions, fintechs, and advisory firms. Create additional revenue streams by offering private-labeled advisory products to institutional clients.
        
        **Key Features:**
        **White-Label Revenue:** Offer custom-branded versions of ZeroInsight’s platform to financial advisors, fintechs, and banks.
        **Revenue Sharing Opportunities:** Generate new revenue streams through revenue-sharing deals with partner institutions.
        **Multi-Tier Customisation:** Allow banks and fintechs to customise features, branding, and advisory tools to suit their end-user needs.
      `,
      image: "/assets/images/zero-insight/white-label-revenue.webp"
    },
  
    "Data-Driven Decision-Making": {
      title: "Data-Driven Decision-Making",
      description: `
        Leverage real-time insights, predictive analytics, and interactive dashboards to support faster and more confident decision-making. Companies can make well-informed decisions driven by machine learning models and real-time data streams.
        
        **Key Features:**
        **Predictive Analytics Engine:** Analyze past and current financial data to make predictions for future trends.
        **Interactive Dashboards:** Track financial performance, risks, and KPIs in real time with user-friendly dashboards.
        **Proactive Decision Support:** Get proactive recommendations and alerts for key financial decisions and investments.
      `,
      image: "/assets/images/zero-insight/data-driven-decisions.webp"
    },
  
    "Enhanced Client Retention": {
      title: "Enhanced Client Retention",
      description: `
        Boost user engagement and satisfaction with hyper-personalised financial guidance, interactive dashboards, and scenario analysis. By offering smarter, faster, and more interactive advisory experiences, ZeroInsight helps businesses retain clients for longer periods.
        
        **Key Features:**
        **Interactive Scenario Analysis:** Let users visualise different financial outcomes for key decisions, enhancing user engagement.
        **Custom Client Dashboards:** Provide users with interactive dashboards that offer a personalised experience.
        **Proactive Alerts & Notifications:** Notify users of market shifts, personal finance opportunities, and risk events in real time.
      `,
      image: "/assets/images/zero-insight/client-retention.webp"
    }
  };
   


  export const FutureEnhancementsDetails = {
    "Conversational AI Assistants": {
      title: "Conversational AI Assistants",
      description: `
        Voice-enabled AI assistants to offer 24/7 financial guidance, simplifying complex financial decisions for users. These assistants enable hands-free navigation of financial tools and instant access to advisory services.
        
        **Key Features:**
        **Voice-Activated Assistance:** Users can ask questions and receive AI-driven financial advice in real-time through voice commands.
        **Multi-Language Support:** Supports multiple languages, enabling users from different regions to engage with financial tools effortlessly.
        **24/7 Availability:** Users have access to non-stop support, providing immediate answers to financial queries at any time.
      `,
      image: "/assets/images/zero-insight/conversational-ai.webp"
    },
  
    "Augmented Reality (AR) Planning": {
      title: "Augmented Reality (AR) Planning",
      description: `
        Use AR to visualise financial planning scenarios, such as retirement, in 3D to provide a more immersive experience. Users can interact with their financial future in real time, making it easier to set and achieve goals.
        
        **Key Features:**
        **3D Financial Projections:** Users can visualise potential financial outcomes for retirement, education savings, and major purchases in an AR environment.
        **Interactive Scenario Exploration:** Users can manipulate financial inputs in AR to see the impact of different decisions on their financial future.
        **Next-Gen User Engagement:** Enhanced user engagement through AR, making financial education more accessible and enjoyable.
      `,
      image: "/assets/images/zero-insight/ar-planning.webp"
    },
  
    "Quantum Computing Integration": {
      title: "Quantum Computing Integration",
      description: `
        Leverage quantum computing for ultra-fast market predictions and optimisation of complex financial portfolios. With quantum-powered insights, users gain a competitive advantage in fast-paced financial markets.
        
        **Key Features:**
        **Quantum-Enhanced Market Predictions:** Access faster and more accurate market predictions by using quantum algorithms to process vast amounts of financial data.
        **Portfolio Optimisation:** Solve complex portfolio optimisation problems in seconds, a task that would take hours or days with traditional computing.
        **Ultra-Fast Risk Analysis:** Calculate risk exposure with ultra-low latency, enabling users to make more informed investment decisions in volatile markets.
      `,
      image: "/assets/images/zero-insight/quantum-computing.webp"
    },
  
    "Machine Learning-Enhanced Advisory": {
      title: "Machine Learning-Enhanced Advisory",
      description: `
        Develop machine learning models that automatically improve the quality of financial advice as user data grows. These self-learning systems adapt to user behavior and deliver more personalised advice over time.
        
        **Key Features:**
        **Continuous Learning Models:** Machine learning models are constantly trained and retrained to improve financial predictions and advice.
        **Behavioral Analysis:** Analyze user interactions and decisions to tailor advisory services, enhancing relevance and personalisation.
        **Predictive Accuracy:** Use machine learning to improve the accuracy of predictions for investment opportunities and potential risks.
      `,
      image: "/assets/images/zero-insight/ml-advisory.webp"
    },
  
    "DeFi Integration": {
      title: "DeFi Integration",
      description: `
        Integrate with decentralised finance (DeFi) protocols to enable tokenised investment opportunities and lending options. Users can access cutting-edge DeFi features directly through the ZeroInsight platform.
        
        **Key Features:**
        **Tokenised Investment Access:** Enable users to access DeFi lending, staking, and yield farming directly from the ZeroInsight platform.
        **Decentralised Lending:** Offer P2P lending options for users to access DeFi loans with better rates and faster processing times.
        **Liquidity Pools & Yield Farming:** Provide users with access to liquidity pools and yield farming opportunities for passive income generation.
      `,
      image: "/assets/images/zero-insight/defi-integration.webp"
    },
  
    "Customisable User Dashboards": {
      title: "Customisable User Dashboards",
      description: `
        Allow users to create personalised dashboards to prioritize their preferred data points, KPIs, and financial insights. Users have control over the content, layout, and presentation of their financial data.
        
        **Key Features:**
        **Drag-and-Drop Customisation:** Users can customise dashboard layouts using drag-and-drop features, ensuring a highly personalised experience.
        **Custom KPIs & Metrics:** Allow users to select the KPIs and metrics most relevant to their financial goals and display them prominently.
        **Theme Customisation:** Provide options for users to change dashboard themes, color schemes, and data visualisation styles.
      `,
      image: "/assets/images/zero-insight/custom-dashboards.webp"
    }
  };
  
