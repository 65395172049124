export const CoreFeatureDetails = {
    "Hybrid Investment Advisory": {
      title: "Hybrid Investment Advisory",
      description: `
        Experience the perfect blend of AI-driven robo-advisors and human financial advisory services, offering tailored guidance for all investors.
        
        **Key Features:**
        **AI-Driven Robo-Advisors:** Automated investment recommendations based on user goals and risk appetite.
        **Human Financial Advisors:** Access to human advisors for complex financial advice and support.
        **Balanced Wealth Management:** Users can leverage both machine-driven insights and human expertise for optimal results.
      `,
      image: "/assets/images/zero-wealth/hybrid-advisory.webp"
    },
  
    "Custom Portfolio Design": {
      title: "Custom Portfolio Design",
      description: `
        Empower users to design their own investment portfolios based on personalised financial goals, ESG preferences, and risk tolerance.
        
        **Key Features:**
        **Personalised Investment Strategies:** Custom portfolio creation tailored to user preferences and investment objectives.
        **ESG Preferences:** Select investments that align with personal ESG (Environmental, Social, Governance) values.
        **Goal-Centric Design:** Build portfolios that target specific goals like retirement, education, or savings milestones.
      `,
      image: "/assets/images/zero-wealth/custom-portfolio-design.webp"
    },
  
    "Predictive Market Analytics": {
      title: "Predictive Market Analytics",
      description: `
        Harness AI-driven predictive analytics to forecast market trends, identify growth opportunities, and make data-backed investment decisions.
        
        **Key Features:**
        **AI-Powered Market Predictions:** Forecast future market trends with data-driven insights powered by machine learning.
        **Risk Assessment:** Identify potential risks in the market to protect investments and reduce loss.
        **Actionable Insights:** Timely insights to inform better decision-making and portfolio adjustments.
      `,
      image: "/assets/images/zero-wealth/predictive-market-analytics.webp"
    },
  
    "ESG Investment Tools": {
      title: "ESG Investment Tools",
      description: `
        Allow users to prioritize ethical and sustainable investments with access to ESG investment opportunities and detailed impact reports.
        
        **Key Features:**
        **ESG Screening:** Filter and prioritize investments based on Environmental, Social, and Governance (ESG) criteria.
        **Impact Reports:** Access detailed reports showing the social and environmental impact of specific investments.
        **Sustainable Portfolio Options:** Build portfolios that align with sustainability goals and ethical finance.
      `,
      image: "/assets/images/zero-wealth/esg-investment-tools.webp"
    },
  
    "Auto-Rebalancing": {
      title: "Auto-Rebalancing",
      description: `
        Automate the rebalancing of investment portfolios to maintain optimal risk-to-reward ratios, ensuring users remain on track with their financial objectives.
        
        **Key Features:**
        **Automated Adjustments:** Automatically adjust portfolio allocations to maintain target asset ratios.
        **Goal Alignment:** Keep portfolios aligned with user-defined financial goals and risk preferences.
        **Market-Driven Rebalancing:** Adapt portfolios to market shifts, protecting against excessive risk exposure.
      `,
      image: "/assets/images/zero-wealth/auto-rebalancing.webp"
    },
  
    "Advanced Goal Tracking": {
      title: "Advanced Goal Tracking",
      description: `
        Set, track, and achieve financial goals with visual milestones and real-time progress updates.
        
        **Key Features:**
        **Goal Milestone Markers:** Visual indicators show progress toward specific savings and investment goals.
        **Goal Insights:** Receive data-driven insights to adjust and improve goal performance.
        **Custom Goal Setting:** Users can define and track multiple financial goals (retirement, home purchase, etc.).
      `,
      image: "/assets/images/zero-wealth/goal-tracking.webp"
    }
  };

  
  export const TechnicalCapabilitiesDetails = {
    "AI-Powered Robo-Advisory": {
      title: "AI-Powered Robo-Advisory",
      description: `
        Leverage advanced AI models to provide automated, data-driven investment recommendations tailored to individual user preferences and market conditions.
        
        **Key Features:**
        **Automated Investment Advice:** AI-driven robo-advisors deliver investment recommendations with no human intervention required.
        **Market-Responsive Insights:** Robo-advisory reacts to market changes in real-time to optimise returns.
        **Customised User Recommendations:** Tailored investment strategies based on risk tolerance, financial goals, and user preferences.
      `,
      image: "/assets/images/zero-wealth/ai-robo-advisory.webp"
    },
  
    "Microservices Architecture": {
      title: "Microservices Architecture",
      description: `
        Empower Zero-Wealth with a microservices-based architecture that ensures fast, scalable, and modular development, supporting seamless feature updates.
        
        **Key Features:**
        **Feature Modularity:** Each component operates independently, enabling faster updates and upgrades.
        **Scalable Design:** Scale individual services without affecting the entire system, ensuring continuous uptime.
        **Fault Tolerance:** System failures are isolated to specific microservices, ensuring platform stability.
      `,
      image: "/assets/images/zero-wealth/microservices-architecture.webp"
    },
  
    "Cloud-Native Infrastructure": {
      title: "Cloud-Native Infrastructure",
      description: `
        Leverage the power of AWS, Azure, and other cloud platforms to ensure high availability, disaster recovery, and auto-scaling for continuous uptime.
        
        **Key Features:**
        **Multi-Cloud Deployment:** Deploy seamlessly across AWS, Azure, and other major cloud providers.
        **Disaster Recovery:** High availability with automatic failover mechanisms for platform reliability.
        **On-Demand Scalability:** Instantly scale to meet peak user demand, supporting millions of users concurrently.
      `,
      image: "/assets/images/zero-wealth/cloud-native-infrastructure.webp"
    },
  
    "Blockchain Security": {
      title: "Blockchain Security",
      description: `
        Enhance platform security using blockchain technology to create tamper-proof transaction logs and guarantee auditability and transparency.
        
        **Key Features:**
        **Immutable Transaction Records:** Ensure all investment actions are recorded immutably on the blockchain.
        **Auditability & Transparency:** Investors can verify all portfolio changes and investment actions.
        **Tamper-Proof Logs:** Protect against unauthorised changes to transaction histories.
      `,
      image: "/assets/images/zero-wealth/blockchain-security.webp"
    },
  
    "AI & Machine Learning Models": {
      title: "AI & Machine Learning Models",
      description: `
        Predictive AI and machine learning models power Zero-Wealth's ability to deliver personalised financial insights, risk mitigation, and data-driven portfolio optimisation.
        
        **Key Features:**
        **Predictive Market Analytics:** Use machine learning to forecast market trends and generate actionable insights.
        **Data-Driven Risk Mitigation:** Minimise financial risk using pattern recognition and anomaly detection.
        **Real-Time Optimisation:** Continuously analyze data to suggest optimal portfolio changes for users.
      `,
      image: "/assets/images/zero-wealth/ai-ml-models.webp"
    },
  
    "Data-Driven Decision Engine": {
      title: "Data-Driven Decision Engine",
      description: `
        A powerful decision engine processes market data and user inputs in real-time to offer automated investment suggestions and portfolio rebalancing recommendations.
        
        **Key Features:**
        **Automated Decision Support:** Provides real-time investment advice based on live market data and user preferences.
        **Dynamic Portfolio Adjustments:** Automatically recommends rebalancing actions to maintain optimal portfolio performance.
        **Advanced Risk Management:** Mitigates risk by analyzing historical and real-time market data for smarter decisions.
      `,
      image: "/assets/images/zero-wealth/decision-engine.webp"
    }
  };

  
  export const KeyBenefitsDetails = {
    "24/7 Investment Advisory": {
      title: "24/7 Investment Advisory",
      description: `
        Access round-the-clock AI-driven investment guidance, allowing users to make informed financial decisions at any time.
        
        **Key Features:**
        **Always-On Support:** AI advisors provide non-stop investment recommendations, even outside of traditional market hours.
        **Instant Insights:** Users receive immediate investment insights based on real-time data analysis.
        **Eliminate Dependency:** No reliance on limited-time human advisor schedules, enabling 24/7 portfolio oversight.
      `,
      image: "/assets/images/zero-wealth/24-7-advisory.webp"
    },
  
    "Reduced Advisory Costs": {
      title: "Reduced Advisory Costs",
      description: `
        Make financial advisory services more accessible by reducing costs through AI-driven automation and hybrid advisory models.
        
        **Key Features:**
        **Affordable Investment Advice:** Lower advisory fees by replacing human reliance with AI and hybrid advisory services.
        **Retail Investor Access:** Democratize wealth management, making it accessible to users with smaller investment portfolios.
        **Cost-Efficient Operations:** Firms reduce operational costs by automating repetitive financial advisory tasks.
      `,
      image: "/assets/images/zero-wealth/reduced-costs.webp"
    },
  
    "Personalised Investment Plans": {
      title: "Personalised Investment Plans",
      description: `
        Create custom investment plans tailored to users' financial goals, risk tolerance, and time horizons using advanced AI algorithms.
        
        **Key Features:**
        **Custom Portfolios:** Generate user-specific investment portfolios based on individual preferences and risk profiles.
        **Goal-Driven Plans:** Set savings goals (like retirement or home purchase) and track investment progress towards these milestones.
        **Dynamic Adjustments:** Plans adapt dynamically as users’ goals, preferences, or market conditions change.
      `,
      image: "/assets/images/zero-wealth/personalised-plans.webp"
    },
  
    "Transparent Portfolio Management": {
      title: "Transparent Portfolio Management",
      description: `
        Provide users with real-time visibility into their portfolio's allocation, performance, and potential growth, fostering trust and confidence.
        
        **Key Features:**
        **Live Portfolio Insights:** View portfolio breakdowns, allocation statistics, and real-time valuation updates.
        **Growth Projections:** AI-driven predictions show potential future growth of portfolios.
        **Full Data Transparency:** Users have complete transparency regarding asset performance, fees, and returns.
      `,
      image: "/assets/images/zero-wealth/transparent-portfolio.webp"
    },
  
    "On-Demand Human Support": {
      title: "On-Demand Human Support",
      description: `
        Offer users the ability to connect with human financial advisors for personal consultations and deeper financial guidance.
        
        **Key Features:**
        **Direct Access to Experts:** Users can schedule one-on-one calls or video sessions with experienced financial advisors.
        **Blended Advisory Model:** Combine the scalability of AI with the personalised touch of human advisors.
        **User-Centric Support:** Investors get human help when complex issues arise, adding reassurance and clarity to decision-making.
      `,
      image: "/assets/images/zero-wealth/human-support.webp"
    },
  
    "Frictionless User Experience": {
      title: "Frictionless User Experience",
      description: `
        Offer a seamless, intuitive, and user-friendly experience with an easy onboarding process, goal tracking, and personalised recommendations.
        
        **Key Features:**
        **Simple Onboarding:** New users can set up their investment accounts within minutes with minimal steps required.
        **Intuitive Navigation:** The platform provides clear, step-by-step guidance to avoid confusion during user journeys.
        **Personalised UI/UX:** Interfaces adjust based on user preferences, offering a tailored and interactive experience.
      `,
      image: "/assets/images/zero-wealth/frictionless-ux.webp"
    }
  };

  
  export const UseCasesDetails = {
    "Novice Investors": {
      title: "Novice Investors",
      description: `
        Enable beginners to embark on their investment journey with guided onboarding, financial education, and user-friendly investment tools.
        
        **Key Features:**
        **Guided Onboarding:** Step-by-step instructions to help novice investors set up their first investment portfolios.
        **Financial Literacy Tools:** Access educational content and tutorials to build investment knowledge.
        **Simple Portfolio Management:** Beginner-friendly investment options and auto-rebalancing to reduce complexity.
      `,
      image: "/assets/images/zero-wealth/novice-investors.webp"
    },
  
    "Experienced Investors": {
      title: "Experienced Investors",
      description: `
        Provide experienced investors with sophisticated tools for advanced market insights, custom portfolio design, and personalised tracking.
        
        **Key Features:**
        **Predictive Insights:** AI-driven predictions on market movements help experienced investors make informed decisions.
        **Custom Portfolio Design:** Design personalised portfolios tailored to specific goals, risk tolerance, and market outlook.
        **Advanced Goal Tracking:** Monitor and adjust investment goals with smart tracking tools.
      `,
      image: "/assets/images/zero-wealth/experienced-investors.webp"
    },
  
    "Financial Institutions": {
      title: "Financial Institutions",
      description: `
        Empower banks and financial institutions to offer branded wealth management solutions powered by Zero-Wealth's white-label platform.
        
        **Key Features:**
        **White-Label Wealth Solutions:** Co-branded platform enables banks to deliver investment services under their brand.
        **Client Retention Tools:** Improve customer retention by offering personalised investment plans and robo-advisory.
        **Scalable Solution:** Institutions can scale wealth offerings without heavy infrastructure costs.
      `,
      image: "/assets/images/zero-wealth/financial-institutions.webp"
    },
  
    "Wealth Advisors": {
      title: "Wealth Advisors",
      description: `
        Allow wealth advisors to co-manage investments with clients and offer data-driven advisory services through Zero-Wealth.
        
        **Key Features:**
        **Co-Management Tools:** Wealth advisors and clients can collaboratively manage investment portfolios in real time.
        **Market Insights & Alerts:** Advisors receive real-time market alerts and predictive insights.
        **Client Relationship Tools:** Integrated messaging and direct advisor support strengthen client relationships.
      `,
      image: "/assets/images/zero-wealth/wealth-advisors.webp"
    },
  
    "Retirement Planning": {
      title: "Retirement Planning",
      description: `
        Provide long-term investment strategies, guidance on risk management, and dynamic tracking to support users' retirement goals.
        
        **Key Features:**
        **Goal-Oriented Investments:** Tailored investment strategies are aligned with long-term retirement goals.
        **Risk Management Guidance:** Personalised risk recommendations to safeguard retirement savings.
        **Progress Tracking:** Users receive real-time updates on how well their retirement plan is progressing.
      `,
      image: "/assets/images/zero-wealth/retirement-planning.webp"
    },
  
    "Institutional Investors": {
      title: "Institutional Investors",
      description: `
        Equip institutional investors with advanced portfolio optimisation, customisable white-label solutions, and data-driven advisory.
        
        **Key Features:**
        **Portfolio Optimisation:** Access real-time analytics and optimisation tools to enhance portfolio performance.
        **Custom White-Label Solutions:** Institutions can deploy a branded investment platform tailored to their specific needs.
        **Data-Driven Advisory:** Advanced advisory tools powered by predictive AI models for smarter investment decisions.
      `,
      image: "/assets/images/zero-wealth/institutional-investors.webp"
    }
  };

  
  export const SecurityAndComplianceDetails = {
    "GDPR Compliance": {
      title: "GDPR Compliance",
      description: `
        Zero-Wealth ensures strict adherence to GDPR regulations, protecting user privacy through data encryption, secure processing, and privacy-by-design principles.
        
        **Key Features:**
        **Data Protection:** Ensures all personal data is stored and processed according to GDPR guidelines.
        **Privacy Safeguards:** Privacy-by-design principles are integrated into the platform, ensuring user data privacy.
        **Data Subject Rights:** Provides users with the right to access, correct, and delete their data.
      `,
      image: "/assets/images/zero-wealth/gdpr-compliance.webp"
    },
  
    "Multi-Factor Authentication (MFA)": {
      title: "Multi-Factor Authentication (MFA)",
      description: `
        Enhance account security with Multi-Factor Authentication (MFA), requiring users to verify their identity using multiple factors before access is granted.
        
        **Key Features:**
        **Biometric Authentication:** Use fingerprint or facial recognition for fast, secure access.
        **One-Time Passwords (OTPs):** Temporary passcodes sent to users for secure login.
        **SMS & Email Verification:** Additional layer of verification via SMS or email for enhanced login protection.
      `,
      image: "/assets/images/zero-wealth/mfa-login.webp"
    },
  
    "Role-Based Access Control (RBAC)": {
      title: "Role-Based Access Control (RBAC)",
      description: `
        Implement Role-Based Access Control (RBAC) to control user access to data, system features, and administrative tools based on their role.
        
        **Key Features:**
        **Granular Permissions:** Assign access permissions based on user roles (e.g., admin, advisor, investor).
        **Access Restrictions:** Limit access to sensitive financial data and operational features.
        **Auditability:** Role-based access is logged for compliance and audit purposes.
      `,
      image: "/assets/images/zero-wealth/rbac-control.webp"
    },
  
    "Data Encryption (AES-256)": {
      title: "Data Encryption (AES-256)",
      description: `
        Ensure end-to-end encryption of user data, financial transactions, and personal information using AES-256 encryption, the highest security standard.
        
        **Key Features:**
        **End-to-End Encryption:** Protects data in transit and at rest using AES-256 encryption.
        **Data Privacy:** User data, transaction history, and sensitive financial details remain encrypted at all times.
        **Regulatory Compliance:** Meets compliance requirements for data protection and security.
      `,
      image: "/assets/images/zero-wealth/data-encryption.webp"
    },
  
    "Immutable Audit Trail": {
      title: "Immutable Audit Trail",
      description: `
        Track every system event, transaction, and access point on an immutable audit trail for accountability, transparency, and compliance.
        
        **Key Features:**
        **Tamper-Proof Logs:** Immutable records of every system event, transaction, and policy change.
        **Regulatory Reporting:** Streamlines compliance reporting for regulators and auditors.
        **Fraud Detection:** Audit trail supports investigations into suspicious activities or fraudulent behavior.
      `,
      image: "/assets/images/zero-wealth/audit-trail.webp"
    },
  
    "ISO 27001 Certification": {
      title: "ISO 27001 Certification",
      description: `
        Zero-Wealth aligns with ISO 27001 standards to ensure the best practices for information security, operational risk management, and data protection.
        
        **Key Features:**
        **Data Security Best Practices:** Implements the latest standards for information security controls.
        **Risk Management:** Comprehensive risk assessments are conducted to identify and mitigate potential security threats.
        **Third-Party Audits:** Regular security audits and ISO 27001 certification to ensure ongoing compliance.
      `,
      image: "/assets/images/zero-wealth/iso-certification.webp"
    }
  };

  
  export const BusinessBenefitsDetails = {
    "Increased Investment Accessibility": {
      title: "Increased Investment Accessibility",
      description: `
        Enable wider access to investment opportunities by removing traditional wealth barriers and democratizing financial inclusion.
        
        **Key Features:**
        **Hybrid Advisory Model:** Combine AI robo-advisors with human support to make investment advice accessible to all.
        **Low Entry Barriers:** Reduce minimum investment thresholds, allowing everyday users to participate.
        **Personalised Wealth Journeys:** Provide beginner-friendly guidance, onboarding, and step-by-step tutorials.
      `,
      image: "/assets/images/zero-wealth/investment-accessibility.webp"
    },
  
    "Revenue Growth from Subscription Services": {
      title: "Revenue Growth from Subscription Services",
      description: `
        Drive steady revenue streams from subscription-based offerings and premium advisory features.
        
        **Key Features:**
        **Tiered Subscription Plans:** monetise premium features like ESG investments, predictive insights, and human advisory support.
        **Recurring Revenue:** Secure a consistent revenue stream from monthly and annual subscription models.
        **Exclusive Access:** Offer exclusive tools like advanced goal tracking, personalised notifications, and portfolio rebalancing.
      `,
      image: "/assets/images/zero-wealth/revenue-growth.webp"
    },
  
    "Cost Reduction via Automation": {
      title: "Cost Reduction via Automation",
      description: `
        Minimise operational costs with advanced automation, self-service portals, and predictive analytics.
        
        **Key Features:**
        **AI-Driven Portfolio Management:** Automate portfolio rebalancing, risk assessment, and goal tracking.
        **Compliance Automation:** Use automated KYC, AML, and reporting workflows to reduce manual compliance tasks.
        **Operational Efficiency:** Reduce manual intervention by enabling self-service client onboarding and profile management.
      `,
      image: "/assets/images/zero-wealth/cost-reduction.webp"
    },
  
    "Client Retention & Loyalty": {
      title: "Client Retention & Loyalty",
      description: `
        Increase user engagement and satisfaction by offering personalisation, human advisory support, and custom goal tracking.
        
        **Key Features:**
        **Personalised Advisory Journeys:** Guide users with AI-powered recommendations and human advisor support.
        **Customisable Portfolios:** Users can tailor their portfolios to match their financial goals and risk tolerance.
        **Ongoing Customer Engagement:** Offer gamification, incentives, and rewards for long-term retention and loyalty.
      `,
      image: "/assets/images/zero-wealth/client-loyalty.webp"
    },
  
    "White-Label Revenue Opportunities": {
      title: "White-Label Revenue Opportunities",
      description: `
        monetise Zero-Wealth by providing white-label wealth management solutions to banks, fintechs, and institutions.
        
        **Key Features:**
        **Branded Wealth Platforms:** Enable financial institutions to offer branded wealth management services to their customers.
        **Revenue Sharing:** Generate revenue from licensing fees, white-label sales, and onboarding fees for institutional clients.
        **Multi-Tenant Platform:** Provide multi-tenant support, allowing banks to serve multiple client bases with one platform.
      `,
      image: "/assets/images/zero-wealth/white-label-revenue.webp"
    },
  
    "ESG Investment Growth": {
      title: "ESG Investment Growth",
      description: `
        Leverage the growing demand for sustainable investing by offering ESG-driven financial products and portfolios.
        
        **Key Features:**
        **ESG Investment Tracking:** Enable users to monitor their ESG impact and align their investments with sustainability goals.
        **Green Investment Portfolios:** Provide options for climate-friendly, socially responsible, and ethical investments.
        **Investor Attraction:** Attract conscious investors and institutions seeking alignment with sustainable finance initiatives.
      `,
      image: "/assets/images/zero-wealth/esg-growth.webp"
    }
  };

  
  export const FutureEnhancementsDetails = {
    "AI-Driven Financial Coaching": {
      title: "AI-Driven Financial Coaching",
      description: `
        Empower users with AI-driven financial coaching to achieve their financial goals with step-by-step guidance and personalised recommendations.
        
        **Key Features:**
        **Personalised Coaching:** Tailored financial advice based on user goals, risk tolerance, and financial history.
        **Progress Tracking:** Real-time feedback on financial progress with actionable steps to stay on track.
        **Goal-Driven Insights:** AI-driven tools that break down complex goals into achievable micro-tasks.
      `,
      image: "/assets/images/zero-wealth/ai-financial-coaching.webp"
    },
  
    "Voice-Activated Portfolio Management": {
      title: "Voice-Activated Portfolio Management",
      description: `
        Hands-free portfolio management with voice-activated features for investment updates, portfolio changes, and insights.
        
        **Key Features:**
        **Voice Command Control:** Users can rebalance portfolios, check market updates, and receive recommendations via voice commands.
        **Multi-Language Support:** Voice assistant will support multiple languages for global accessibility.
        **AI-Powered Responses:** Voice engine will offer real-time responses with context-aware answers to user queries.
      `,
      image: "/assets/images/zero-wealth/voice-portfolio-management.webp"
    },
  
    "DeFi Integration for Wealth Growth": {
      title: "DeFi Integration for Wealth Growth",
      description: `
        Enable users to access DeFi opportunities directly from Zero-Wealth, including staking, yield farming, and decentralised lending.
        
        **Key Features:**
        **DeFi Yield Opportunities:** Provide users access to staking, liquidity pools, and DeFi yield farming directly from the platform.
        **Wealth Diversification:** Enable users to diversify wealth strategies with DeFi-based investment options.
        **On-Chain Analytics:** Offer real-time tracking of DeFi returns and risk levels for transparency and informed decision-making.
      `,
      image: "/assets/images/zero-wealth/defi-integration.webp"
    },
  
    "AI-Powered Sentiment Analysis": {
      title: "AI-Powered Sentiment Analysis",
      description: `
        Anticipate market shifts with AI sentiment analysis, offering users timely alerts and predictive insights.
        
        **Key Features:**
        **Market Sentiment Tracking:** Analyse global news, social media, and financial forums for early market indicators.
        **Risk Alerts & Notifications:** Proactive alerts notify users of market risks or potential opportunities.
        **Predictive Insights:** AI models predict potential market movements, helping users make informed investment decisions.
      `,
      image: "/assets/images/zero-wealth/sentiment-analysis.webp"
    },
  
    "Customisable Wealth Dashboards": {
      title: "Customisable Wealth Dashboards",
      description: `
        Future versions of Zero-Wealth will feature fully customisable dashboards, allowing users to personalise their investment experience.
        
        **Key Features:**
        **Personalised Dashboards:** Users can display the metrics and KPIs that matter most to them.
        **Drag-and-Drop Widgets:** Customise dashboard layout with drag-and-drop widgets, such as charts, graphs, and goal progress indicators.
        **Theme Customisation:** Offer light, dark, and custom themes for a personalised user experience.
      `,
      image: "/assets/images/zero-wealth/customisable-dashboards.webp"
    },
  
    "Augmented Reality (AR) Portfolio Visualisation": {
      title: "Augmented Reality (AR) Portfolio Visualisation",
      description: `
        Visualise financial portfolios in 3D augmented reality, providing a next-gen experience for investment tracking and decision-making.
        
        **Key Features:**
        **AR-Driven Insights:** View 3D representations of portfolios, investment growth, and diversification.
        **Immersive Analytics:** Users can explore their financial data spatially, interacting with 3D charts and graphs.
        **Next-Gen Visualisation:** Provide a futuristic experience for users seeking innovative wealth visualisation tools.
      `,
      image: "/assets/images/zero-wealth/ar-portfolio-visualisation.webp"
    }
  };
  