export const CoreFeatures = [
    {
      title: "Compliance Workflow Automation",
      description: 
        "Automate manual compliance processes with smart workflows that trigger tasks based on policy updates or regulatory changes.",
      image: "/assets/images/zero-comply/workflow-automation.webp",
    },
    {
      title: "Predictive Analytics",
      description: 
        "AI-driven analytics predict potential compliance breaches, enabling proactive decision-making to mitigate risks.",
      image: "/assets/images/zero-comply/predictive-analytics.webp",
    },
    {
      title: "Real-Time Monitoring",
      description: 
        "24/7 monitoring of compliance risks, instantly alerting users of violations or anomalies in regulatory processes.",
      image: "/assets/images/zero-comply/real-time-monitoring.webp",
    },
    {
      title: "Audit-Ready Reports",
      description: 
        "Generate downloadable audit-ready reports that meet global compliance standards, reducing audit preparation time.",
      image: "/assets/images/zero-comply/audit-reports.webp",
    },
    {
      title: "Global Compliance Support",
      description: 
        "Stay compliant with global regulatory frameworks, including GDPR, MiFID, and HIPAA, across multiple jurisdictions.",
      image: "/assets/images/zero-comply/global-compliance.webp",
    },
    {
      title: "Risk Management Tools",
      description: 
        "Identify, assess, and mitigate regulatory risks using advanced analytics, predictive algorithms, and role-based access controls.",
      image: "/assets/images/zero-comply/risk-management.webp",
    }
  ];

export const UserPersonas = [
    {
      title: "Compliance Officers",
      description: 
        "Ensure organisational compliance with global laws and regulatory frameworks, leveraging Zero-Comply for real-time monitoring and reporting.",
      image: "/assets/images/zero-comply/compliance-officer.webp",
    },
    {
      title: "Risk Managers",
      description: 
        "Proactively identify, manage, and mitigate compliance risks with predictive risk scoring and alerts.",
      image: "/assets/images/zero-comply/risk-manager.webp",
    },
    {
      title: "Legal Advisors",
      description: 
        "Access detailed audit reports and compliance records to support legal reviews and ensure regulatory adherence.",
      image: "/assets/images/zero-comply/legal-advisor.webp",
    },
    {
      title: "Executive Management",
      description: 
        "Gain strategic insights from dashboards and compliance analytics to inform business decision-making.",
      image: "/assets/images/zero-comply/executive-management.webp",
    },
    {
      title: "IT Administrators",
      description: 
        "Manage user roles, access controls, and integrations with third-party tools, ensuring seamless compliance operations.",
      image: "/assets/images/zero-comply/it-administrator.webp",
    },
    {
      title: "Auditors",
      description: 
        "Review compliance documentation, audit trails, and operational workflows to ensure full regulatory compliance.",
      image: "/assets/images/zero-comply/auditors.webp",
    }
  ];

export const KeyBenefits = [
    {
      title: "Time-Saving Automation",
      description: 
        "Eliminate manual compliance tasks, saving time and operational costs through process automation.",
      image: "/assets/images/zero-comply/time-saving-automation.webp",
    },
    {
      title: "Real-Time Alerts",
      description: 
        "Get notified immediately when compliance risks arise, enabling swift corrective action.",
      image: "/assets/images/zero-comply/real-time-alerts.webp",
    },
    {
      title: "Audit and Regulatory Confidence",
      description: 
        "Ensure your business is always audit-ready, with all necessary compliance documentation available on demand.",
      image: "/assets/images/zero-comply/audit-confidence.webp",
    },
    {
      title: "Risk Mitigation",
      description: 
        "Mitigate fines, penalties, and reputational damage by reducing the risk of non-compliance with predictive analytics.",
      image: "/assets/images/zero-comply/risk-mitigation.webp",
    },
    {
      title: "Cost Efficiency",
      description: 
        "Reduce the need for large compliance teams by automating workflows and utilising centralised compliance management tools.",
      image: "/assets/images/zero-comply/cost-efficiency.webp",
    },
    {
      title: "Adaptability & Scalability",
      description: 
        "Scale your compliance infrastructure as regulations evolve, supporting both SMEs and global enterprises.",
      image: "/assets/images/zero-comply/adaptability-scalability.webp",
    }
  ];

export const TechnicalArchitecture = [
    {
      title: "Microservices Architecture",
      description: 
        "Modular microservices support independent updates for reporting, data analytics, and compliance workflows.",
      image: "/assets/images/zero-comply/microservices-architecture.webp",
    },
    {
      title: "Cloud-Native Design",
      description: 
        "Deployed on AWS, Azure, and GCP, supporting multi-cloud environments for high availability and scalability.",
      image: "/assets/images/zero-comply/cloud-native.webp",
    },
    {
      title: "Containerised Deployment",
      description: 
        "Uses Docker containers to ensure consistent deployment, fast updates, and compatibility across environments.",
      image: "/assets/images/zero-comply/container-deployment.webp",
    },
    {
      title: "Event-Driven System",
      description: 
        "Employs RabbitMQ to deliver real-time event notifications, enabling instantaneous compliance alerts.",
      image: "/assets/images/zero-comply/event-driven-system.webp",
    },
    {
      title: "Data Encryption & Role-Based Access Control (RBAC)",
      description: 
        "Protects sensitive data with AES-256 encryption and RBAC, ensuring only authorised users can access sensitive compliance records.",
      image: "/assets/images/zero-comply/data-encryption.webp",
    },
    {
      title: "Tamper-Proof Audit Trail",
      description: 
        "Maintains a tamper-proof log of user actions and regulatory changes, ensuring full audit traceability.",
      image: "/assets/images/zero-comply/audit-trail.webp",
    }
  ];

export const KeyFeatures = [
    {
      title: "Automated Compliance Workflows",
      description: 
        "Automatically triggers compliance workflows for policy updates, new regulations, or internal review processes.",
      image: "/assets/images/zero-comply/automated-workflows.webp",
    },
    {
      title: "Data-Driven Insights",
      description: 
        "View dashboards and reports with actionable insights into compliance status and key risks.",
      image: "/assets/images/zero-comply/data-insights.webp",
    },
    {
      title: "Predictive Compliance Analysis",
      description: 
        "Anticipate where future compliance issues might occur using machine learning algorithms and predictive analysis.",
      image: "/assets/images/zero-comply/predictive-analysis.webp",
    },
    {
      title: "Role-Based Access Control (RBAC)",
      description: 
        "Control user access to specific compliance data and system features based on their role and responsibilities.",
      image: "/assets/images/zero-comply/rbac.webp",
    },
    {
      title: "Audit-Ready Compliance Reports",
      description: 
        "Export reports for regulatory authorities or internal reviews with all necessary compliance data included.",
      image: "/assets/images/zero-comply/aud-ready-reports.webp",
    },
    {
      title: "Customisable Dashboards",
      description: 
        "Users can personalise their dashboard with widgets, KPIs, and visualisations relevant to their role.",
      image: "/assets/images/zero-comply/custom-dashboards.webp",
    }
  ];

  export const SecurityAndCompliance = [
    {
      title: "PCI-DSS Certification",
      description: 
        "Complies with Payment Card Industry Data Security Standards (PCI-DSS) to protect sensitive payment data.",
      image: "/assets/images/zero-comply/pci-dss-certification.webp",
    },
    {
      title: "Data Encryption",
      description: 
        "Applies AES-256 encryption to secure all compliance data, ensuring that data remains confidential and tamper-proof.",
      image: "/assets/images/zero-comply/data-encryption.webp",
    },
    {
      title: "Audit Trail & Logging",
      description: 
        "Keeps an immutable log of user activity, ensuring all actions are traceable for audit purposes.",
      image: "/assets/images/zero-comply/audit-trail.webp",
    },
    {
      title: "Regulatory Compliance",
      description: 
        "Complies with regulatory standards like GDPR, HIPAA, MiFID, and more to support multi-jurisdictional compliance.",
      image: "/assets/images/zero-comply/regulatory-compliance.webp",
    },
    {
      title: "Role-Based Access Control-(RBAC)",
      description: 
        "Only authorised personnel have access to specific data and actions, reducing internal security risks.",
      image: "/assets/images/zero-comply/rbac-security.webp",
    },
    {
      title: "Access and Session Management",
      description: 
        "Monitor user sessions and control access to specific areas of the platform, with session timeout for enhanced security.",
      image: "/assets/images/zero-comply/access-session-management.webp",
    },
    {
      title: "Data Anonymisation & Masking",
      description: 
        "Anonymises sensitive data to protect personally identifiable information (PII) during processing and analytics.",
      image: "/assets/images/zero-comply/data-anonymisation.webp",
    },
    {
      title: "Multi-Factor Authentication (MFA)",
      description: 
        "Enhances user security with multi-factor authentication, requiring multiple verification steps for access.",
      image: "/assets/images/zero-comply/mfa-security.webp",
    },
    {
      title: "Fraud Detection & Prevention",
      description: 
        "AI-driven fraud detection models identify and block suspicious activities in real-time, minimising financial loss.",
      image: "/assets/images/zero-comply/fraud-detection.webp",
    }
  ];

export const FutureEnhancements = [
    {
      title: "AI-Powered Risk Scoring",
      description: 
        "Introduce AI models that predict regulatory risks based on historical compliance data and industry trends.",
      image: "/assets/images/zero-comply/ai-risk-scoring.webp",
    },
    {
      title: "Blockchain Integration",
      description: 
        "Leverage blockchain technology for a tamper-proof audit trail that ensures compliance data integrity.",
      image: "/assets/images/zero-comply/blockchain-integration.webp",
    },
    {
      title: "Customisable-Dashboards",
      description: 
        "Enable users to create personalised dashboards with metrics and KPIs specific to their role and responsibilities.",
      image: "/assets/images/zero-comply/customisable-dashboards.webp",
    },
    {
      title: "Automated Policy Updates",
      description: 
        "Automatically apply regulatory updates to workflows and compliance processes as policies evolve.",
      image: "/assets/images/zero-comply/automated-policy-updates.webp",
    },
    {
      title: "Mobile Access",
      description: 
        "Allow users to access compliance dashboards and receive alerts on mobile devices for on-the-go management.",
      image: "/assets/images/zero-comply/mobile-access.webp",
    },
    {
      title: "Custom Risk Models",
      description: 
        "Allow organisations to develop custom risk scoring models to assess specific compliance challenges unique to their business.",
      image: "/assets/images/zero-comply/custom-risk-models.webp",
    }
  ];
  

