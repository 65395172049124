export const CoreFeatures = [
    {
      title: "Decentralised Peer-to-Peer Lending",
      description: 
        "Borrowers can connect directly with lenders, removing intermediaries and allowing flexible loan terms, rates, and repayment schedules.",
      image: "/assets/images/zero-lend/peer-to-peer-lending.webp",
    },
    {
      title: "Smart Contract Automation",
      description: 
        "Utilise smart contracts to automate loan agreements, payment processing, and settlement, ensuring transparent, self-executing contracts.",
      image: "/assets/images/zero-lend/smart-contract-automation.webp",
    },
    {
      title: "AI-Driven Credit Scoring",
      description: 
        "Leverage AI models to assess borrower creditworthiness using alternative data, enabling access to credit for underserved users.",
      image: "/assets/images/zero-lend/ai-credit-scoring.webp",
    },
    {
      title: "Tokenisation of Loan Assets",
      description: 
        "Tokenise loans as tradable assets, allowing lenders to sell or transfer loan positions, providing liquidity for loan portfolios.",
      image: "/assets/images/zero-lend/tokenised-loans.webp",
    },
    {
      title: "Global Reach & Borderless Lending",
      description: 
        "Enable cross-border lending and borrowing using blockchain, with payments issued in stablecoins or digital currencies.",
      image: "/assets/images/zero-lend/global-reach-lending.webp",
    },
    {
      title: "Data Security & Privacy",
      description: 
        "Protect user data with blockchain encryption, ensuring privacy through Zero-Knowledge Proofs (ZKPs) and end-to-end encryption.",
      image: "/assets/images/zero-lend/data-security-privacy.webp",
    }
  ];

export const TechnicalCapabilities = [
    {
      title: "Blockchain-Based Core",
      description: 
        "All transactions, repayments, and smart contracts are recorded on an immutable blockchain ledger for transparency and security.",
      image: "/assets/images/zero-lend/blockchain-core.webp",
    },
    {
      title: "Microservices Architecture",
      description: 
        "Employ a modular architecture where each service (credit scoring, payments, smart contracts) operates independently for agility and scalability.",
      image: "/assets/images/zero-lend/microservices-architecture.webp",
    },
    {
      title: "Decentralised Data Storage",
      description: 
        "User and transaction data is decentralised, stored across blockchain nodes to prevent data breaches and increase redundancy.",
      image: "/assets/images/zero-lend/decentralised-storage.webp",
    },
    {
      title: "AI-Driven Credit Assessment",
      description: 
        "AI models analyse alternative data sources, providing a real-time assessment of borrower risk and improving access to credit.",
      image: "/assets/images/zero-lend/ai-credit-assessment.webp",
    },
    {
      title: "Encryption & Privacy",
      description: 
        "Data is encrypted using AES-256, and Zero-Knowledge Proofs (ZKPs) verify credentials without exposing sensitive data.",
      image: "/assets/images/zero-lend/data-encryption.webp",
    },
    {
      title: "Global Payment Gateway",
      description: 
        "Integrated with multi-crypto payment gateways for seamless international payments, supporting stablecoins and cryptocurrencies.",
      image: "/assets/images/zero-lend/global-payment-gateway.webp",
    }
  ];

export const KeyBenefits = [
    {
      title: "Financial Inclusion",
      description: 
        "Offer underserved communities access to loans by enabling alternative credit scoring and removing reliance on formal credit checks.",
      image: "/assets/images/zero-lend/financial-inclusion.webp",
    },
    {
      title: "Tokenised Loan Portfolios",
      description: 
        "Lenders can sell or trade their loans on secondary markets, increasing liquidity and providing flexibility in loan management.",
      image: "/assets/images/zero-lend/tokenised-portfolios.webp",
    },
    {
      title: "Decentralised Lending",
      description: 
        "Borrowers and lenders interact directly without intermediaries, enabling transparency, lower costs, and control over loan terms.",
      image: "/assets/images/zero-lend/decentralised-lending.webp",
    },
    {
      title: "Reduced Costs & Fees",
      description: 
        "Eliminate bank fees and costly intermediaries, reducing the total cost of borrowing and increasing lender returns.",
      image: "/assets/images/zero-lend/reduced-costs-fees.webp",
    },
    {
      title: "Automated Smart Contract Payments",
      description: 
        "Loans are managed through smart contracts, ensuring automatic execution of repayments and reducing administrative burdens.",
      image: "/assets/images/zero-lend/automated-payments.webp",
    },
    {
      title: "Improved Risk Management",
      description: 
        "AI-powered risk assessment identifies borrower risks and enables proactive intervention to reduce defaults.",
      image: "/assets/images/zero-lend/risk-management.webp",
    }
  ];

export const UseCases = [
    {
      title: "Personal Loans",
      description: 
        "Borrowers can access personal loans for emergencies, medical expenses, or general financing needs via Zero-Lend’s mobile app.",
      image: "/assets/images/zero-lend/personal-loans.webp",
    },
    {
      title: "Business Loans",
      description: 
        "SMEs can secure working capital loans from a pool of global lenders without relying on traditional banks.",
      image: "/assets/images/zero-lend/business-loans.webp",
    },
    {
      title: "Microloans in Emerging Markets",
      description: 
        "Enable users from developing countries to access small loans for essential needs via peer-to-peer lending models.",
      image: "/assets/images/zero-lend/microloans.webp",
    },
    {
      title: "Education Loans",
      description: 
        "Offer students access to tuition and study loans, with repayment managed through blockchain-powered smart contracts.",
      image: "/assets/images/zero-lend/education-loans.webp",
    },
    {
      title: "Investor Lending & Income",
      description: 
        "Retail investors lend to borrowers and receive interest payments, creating an additional source of passive income.",
      image: "/assets/images/zero-lend/investor-lending.webp",
    },
    {
      title: "Cross-Border Lending",
      description: 
        "Enable borrowers and lenders from different countries to engage in loans, powered by stablecoins and cryptocurrency payments.",
      image: "/assets/images/zero-lend/cross-border-lending.webp",
    }
  ];

export const SecurityAndCompliance = [
    {
      title: "KYC/AML Compliance",
      description: 
        "Zero-Lend follows Know Your Customer (KYC) and Anti-Money Laundering (AML) requirements to ensure regulatory compliance.",
      image: "/assets/images/zero-lend/kyc-aml-compliance.webp",
    },
    {
      title: "Audit-Ready Smart Contracts",
      description: 
        "All smart contracts undergo third-party audits to eliminate vulnerabilities and ensure code security.",
      image: "/assets/images/zero-lend/audit-ready-smart-contracts.webp",
    },
    {
      title: "Biometric Login",
      description: 
        "Provides users with biometric login options (like FaceID) for enhanced security during login and loan management.",
      image: "/assets/images/zero-lend/biometric-login.webp",
    },
    {
      title: "Data Encryption (AES-256)",
      description: 
        "All sensitive user data is encrypted at rest and in transit using the AES-256 standard, ensuring privacy and security.",
      image: "/assets/images/zero-lend/aes-256-encryption.webp",
    },
    {
      title: "Zero-Knowledge Proofs (ZKPs)",
      description: 
        "Verify user credentials without revealing sensitive information, ensuring privacy in every interaction.",
      image: "/assets/images/zero-lend/zkp-compliance.webp",
    },
    {
      title: "Regulatory Compliance",
      description: 
        "Adheres to local and international lending regulations, ensuring legality of operations in all jurisdictions.",
      image: "/assets/images/zero-lend/regulatory-compliance.webp",
    }
  ];

export const BusinessBenefits = [
    {
      title: "Increased Lending Opportunities",
      description: 
        "Enable lenders to reach borrowers globally, expanding access to lending opportunities beyond local markets.",
      image: "/assets/images/zero-lend/increased-lending-opportunities.webp",
    },
    {
      title: "Faster Loan Processing",
      description: 
        "Utilise smart contracts for instant loan approvals, disbursements, and repayments, reducing processing times significantly.",
      image: "/assets/images/zero-lend/faster-loan-processing.webp",
    },
    {
      title: "Lower Operational Costs",
      description: 
        "Eliminate the need for intermediaries and manual processes, reducing operational costs for lenders and borrowers.",
      image: "/assets/images/zero-lend/lower-operational-costs.webp",
    },
    {
      title: "Customisable Loan Terms",
      description: 
        "Enable borrowers and lenders to negotiate loan terms such as interest rates, repayment schedules, and collateral requirements.",
      image: "/assets/images/zero-lend/customisable-loan-terms.webp",
    },
    {
      title: "Passive Income for Lenders",
      description: 
        "Lenders can generate passive income through interest payments on loans, diversifying income streams and increasing returns.",
      image: "/assets/images/zero-lend/passive-income-for-lenders.webp",
    },
    {
      title: "Access to Unbanked Communities",
      description: 
        "Provide underserved populations with access to loans, especially in regions where access to traditional banks is limited.",
      image: "/assets/images/zero-lend/access-to-unbanked-communities.webp",
    }
  ];
  
export const FutureEnhancements = [
    {
      title: "AI-Powered Borrower Matching",
      description: 
        "Use AI to match borrowers with ideal lenders, personalising loan offers based on user profiles, credit scores, and loan preferences.",
      image: "/assets/images/zero-lend/ai-powered-matching.webp",
    },
    {
      title: "NFT-Backed Loans",
      description: 
        "Enable users to use NFTs as collateral for loans, unlocking liquidity for digital assets and tokenised art.",
      image: "/assets/images/zero-lend/nft-backed-loans.webp",
    },
    {
      title: "Multi-Currency Support",
      description: 
        "Offer loan processing in multiple fiat and digital currencies, providing flexibility for global borrowers and lenders.",
      image: "/assets/images/zero-lend/multi-currency-support.webp",
    },
    {
      title: "Advanced Risk Scoring Models",
      description: 
        "Deploy advanced machine learning models to improve borrower risk scoring, ensuring more accurate assessments of default risk.",
      image: "/assets/images/zero-lend/advanced-risk-scoring.webp",
    },
    {
      title: "DeFi Integration",
      description: 
        "Integrate with DeFi protocols to enable on-chain lending, staking, and liquidity pool access for Zero-Lend users.",
      image: "/assets/images/zero-lend/defi-integration.webp",
    },
    {
      title: "Mobile App for Borrowers & Lenders",
      description: 
        "Develop a mobile app that enables borrowers and lenders to manage loans, track payments, and access real-time loan status updates.",
      image: "/assets/images/zero-lend/mobile-app.webp",
    }
    /*{
      title: "Multi-Language Support",
      description: 
        "Provide support for multiple languages to ensure accessibility for users from diverse linguistic backgrounds.",
      image: "/assets/images/zero-lend/multi-language-support.webp",
    }*/
  ];

