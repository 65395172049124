import React, { useEffect, useRef } from "react";
import { TiLocationArrow } from "react-icons/ti";
import { gsap } from "gsap";

const BookAConsultationButton = ({ onOpenModal }) => {
  const buttonRef = useRef(null);

  useEffect(() => {
    if (buttonRef.current) {
      // Initial entrance animation
      gsap.fromTo(
        buttonRef.current,
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, duration: 1, ease: "power3.out" }
      );
    }
  }, []);

  const handleMouseEnter = () => {
    gsap.to(buttonRef.current, {
      boxShadow: "0 0 15px rgba(0, 255, 255, 1)",
      scale: 1.05,
      duration: 0.3,
      ease: "power3.out",
    });
  };

  const handleMouseLeave = () => {
    gsap.to(buttonRef.current, {
      boxShadow: "0 0 0 rgba(0, 255, 255, 0)",
      scale: 1,
      duration: 0.3,
      ease: "power3.out",
    });
  };

  const handleClick = (e) => {
    e.preventDefault(); // Prevent default behavior (if needed)
    if (onOpenModal) {
      onOpenModal(); // Open the modal
    }
  };

  return (
    <button
      ref={buttonRef}
      onClick={handleClick}
      aria-label="Book a consultation"
      className="mt-8 px-6 py-3 border border-gray-200 bg-transparent hover:bg-teal-500 hover:text-gray-800 text-gray-100 z-30 flex items-center rounded-md transition-all duration-300 ease-in-out"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span className="flex items-center">
        Book a Consultation
        <TiLocationArrow className="ml-2 text-xl" />
      </span>
    </button>
  );
};

export default BookAConsultationButton;
