export const CoreFeatures = [
    {
      title: "AI-Powered Trading Engine",
      description: 
        "Utilise advanced AI models trained on market data to identify patterns, forecast price movements, and execute trades autonomously.",
      image: "/assets/images/zero-trade/ai-trading-engine.webp",
    },
    {
      title: "Automated Trade Execution",
      description: 
        "Instantly execute buy/sell orders across multiple markets, supporting high-frequency trading (HFT) with millisecond precision.",
      image: "/assets/images/zero-trade/automated-trade-execution.webp",
    },
    {
      title: "Customisable Trading Logic",
      description: 
        "Design, backtest, and deploy your own custom trading algorithms, tailored to specific market conditions or personal preferences.",
      image: "/assets/images/zero-trade/custom-trading-logic.webp",
    },
    {
      title: "Advanced Risk Management",
      description: 
        "Set position limits, stop-loss, and take-profit strategies to manage exposure and control potential losses.",
      image: "/assets/images/zero-trade/risk-management.webp",
    },
    {
      title: "Multi-Asset Trading",
      description: 
        "Trade across multiple asset classes, including stocks, forex, cryptocurrencies, and commodities, all from one platform.",
      image: "/assets/images/zero-trade/multi-asset-trading.webp",
    },
    {
      title: "White-Label Capabilities",
      description: 
        "Rebrand and integrate Zero-Trade’s trading technology into your own platform as a white-label solution.",
      image: "/assets/images/zero-trade/white-label-capabilities.webp",
    }
  ];

  
export const TechnicalCapabilities = [
    {
      title: "Microservices Architecture",
      description: 
        "Modular microservices design supports rapid updates and isolated improvements for each core component of the platform.",
      image: "/assets/images/zero-trade/microservices-architecture.webp",
    },
    {
      title: "Cloud-Native Infrastructure",
      description: 
        "Global availability with deployment on AWS, Azure, or Google Cloud, ensuring high availability and disaster recovery.",
      image: "/assets/images/zero-trade/cloud-native-infrastructure.webp",
    },
    {
      title: "High-Frequency Trading (HFT) Capabilities",
      description: 
        "Execute thousands of trades per second, taking advantage of market micro-fluctuations to maximise profitability.",
      image: "/assets/images/zero-trade/hft-capabilities.webp",
    },
    {
      title: "API-First Approach",
      description: 
        "Access Zero-Trade features through APIs, enabling custom integrations with trading bots, fintech apps, or hedge fund platforms.",
      image: "/assets/images/zero-trade/api-first-approach.webp",
    },
    {
      title: "AI-Powered Signal Generation",
      description: 
        "Machine learning models analyze millions of market data points to generate precise buy/sell/hold signals in real time.",
      image: "/assets/images/zero-trade/ai-signal-generation.webp",
    },
    {
      title: "Dockerised Services",
      description: 
        "Containerised services for consistent deployments, enabling rapid updates and development environment parity.",
      image: "/assets/images/zero-trade/dockerised-services.webp",
    }
  ];

  
export const KeyBenefits = [
    {
      title: "Profit Optimisation",
      description: 
        "Maximise profits by leveraging AI-driven insights that exploit micro-price fluctuations and rapidly changing market conditions.",
      image: "/assets/images/zero-trade/profit-optimisation.webp",
    },
    {
      title: "Faster Trade Execution",
      description: 
        "Achieve lightning-fast execution speeds with millisecond precision, critical for success in high-frequency trading (HFT) environments.",
      image: "/assets/images/zero-trade/faster-trade-execution.webp",
    },
    {
      title: "Reduced Human Error",
      description: 
        "Automate decision-making, eliminating human biases, emotion-driven mistakes, and delayed execution times.",
      image: "/assets/images/zero-trade/reduced-human-error.webp",
    },
    {
      title: "Access to Multiple Markets",
      description: 
        "Gain exposure to various asset classes, from stocks and forex to crypto and commodities, using one platform.",
      image: "/assets/images/zero-trade/multi-market-access.webp",
    },
    {
      title: "Customisable Trading Logic",
      description: 
        "Users can create, customise, and backtest their own AI-based trading logic to suit personal investment goals.",
      image: "/assets/images/zero-trade/custom-trading-logic.webp",
    },
    {
      title: "Enhanced Portfolio Management",
      description: 
        "View, track, and manage all active trades and portfolios in one easy-to-use, customisable dashboard.",
      image: "/assets/images/zero-trade/portfolio-management.webp",
    }
  ];

export const UseCases = [
    {
      title: "Day Trading",
      description: 
        "Capitalise on short-term price movements to generate daily profits using real-time AI-driven trading logic.",
      image: "/assets/images/zero-trade/day-trading.webp",
    },
    {
      title: "Portfolio Diversification",
      description: 
        "Create diversified portfolios of stocks, forex, and crypto with automated AI-driven recommendations and analysis.",
      image: "/assets/images/zero-trade/portfolio-diversification.webp",
    },
    {
      title: "Algorithmic Trading",
      description: 
        "Build, test, and deploy complex trading algorithms, letting AI execute trades according to precise conditions.",
      image: "/assets/images/zero-trade/algorithmic-trading.webp",
    },
    {
      title: "Hedge Fund Automation",
      description: 
        "Institutional investors use Zero-Trade to support high-frequency trading and automate large-scale order execution.",
      image: "/assets/images/zero-trade/hedge-fund-automation.webp",
    },
    {
      title: "Cross-Asset Arbitrage",
      description: 
        "Exploit price differences across markets and asset classes to generate risk-free profits using real-time arbitrage signals.",
      image: "/assets/images/zero-trade/cross-asset-arbitrage.webp",
    },
    {
      title: "Copy Trading",
      description: 
        "Allow users to replicate the trades of expert traders by copying their trading logic and strategy in real time.",
      image: "/assets/images/zero-trade/copy-trading.webp",
    }
  ];

export const SecurityAndCompliance = [
    {
      title: "Data Encryption (AES-256)",
      description: 
        "Protect sensitive user data and trading information with AES-256 encryption for maximum security and compliance.",
      image: "/assets/images/zero-trade/aes-encryption.webp",
    },
    {
      title: "AML & KYC Compliance",
      description: 
        "Comply with international anti-money laundering (AML) and know-your-customer (KYC) regulations to ensure platform integrity.",
      image: "/assets/images/zero-trade/aml-kyc-compliance.webp",
    },
    {
      title: "2FA & Role-Based Access Control",
      description: 
        "Secure user accounts with two-factor authentication (2FA) and role-based access control (RBAC) for privileged permissions.",
      image: "/assets/images/zero-trade/2fa-access-control.webp",
    },
    {
      title: "Audit Trails & Logging",
      description: 
        "All actions are logged and auditable, providing a clear trail for regulatory compliance and internal review.",
      image: "/assets/images/zero-trade/audit-trails.webp",
    },
    {
      title: "Penetration Testing",
      description: 
        "Zero-Trade undergoes routine penetration testing to identify and resolve vulnerabilities before they can be exploited.",
      image: "/assets/images/zero-trade/penetration-testing.webp",
    },
    {
      title: "GDPR Compliance",
      description: 
        "Zero-Trade ensures compliance with GDPR regulations, ensuring user data privacy, transparency, and consent management.",
      image: "/assets/images/zero-trade/gdpr-compliance.webp",
    }
  ];

export const BusinessBenefits = [
    {
      title: "Revenue Growth from AI Trading Fees",
      description: 
        "Generate consistent revenue through transaction fees, subscription plans, and premium access to AI-driven trading tools.",
      image: "/assets/images/zero-trade/revenue-growth.webp",
    },
    {
      title: "Scalability with White-Label Solutions",
      description: 
        "monetise Zero-Trade's white-label capabilities, allowing institutions to rebrand and launch their own AI-driven trading platforms.",
      image: "/assets/images/zero-trade/white-label-scalability.webp",
    },
    {
      title: "Reduced Operational Costs",
      description: 
        "Automate trade execution, reduce reliance on manual intervention, and lower operational costs for hedge funds, banks, and fintech firms.",
      image: "/assets/images/zero-trade/reduced-costs.webp",
    },
    {
      title: "Faster Time-to-Market",
      description: 
        "Launch fintech trading products quickly using Zero-Trade’s API-first approach, enabling rapid integration and customisation.",
      image: "/assets/images/zero-trade/time-to-market.webp",
    },
    {
      title: "Increased Customer Retention",
      description: 
        "Deliver exceptional user experiences with fast execution, customisable bots, and AI-driven insights, boosting customer retention.",
      image: "/assets/images/zero-trade/customer-retention.webp",
    },
    {
      title: "Revenue from API Access",
      description: 
        "License API access for trading bots, fintech apps, and institutional trading desks, generating a new revenue stream.",
      image: "/assets/images/zero-trade/api-access-revenue.webp",
    }
  ];
  
export const FutureEnhancements = [
    {
      title: "AI Model Upgrades",
      description: 
        "New AI models will be released to enhance the accuracy of market predictions and trading signals.",
      image: "/assets/images/zero-trade/ai-model-upgrades.webp",
    },
    {
      title: "DeFi Trading Integration",
      description: 
        "Trade directly on decentralised exchanges (DEXs) to take advantage of DeFi liquidity pools and on-chain trading.",
      image: "/assets/images/zero-trade/defi-integration.webp",
    },
    {
      title: "Multi-Language Support",
      description: 
        "Support for multiple languages will allow Zero-Trade to expand into global markets, broadening its user base.",
      image: "/assets/images/zero-trade/multi-language-support.webp",
    },
    {
      title: "Voice-Controlled Trading",
      description: 
        "Users will be able to execute trades and interact with their dashboard via voice commands using AI voice recognition.",
      image: "/assets/images/zero-trade/voice-trading.webp",
    },
    {
      title: "NFT and Tokenised Asset Trading",
      description: 
        "Support for trading tokenised assets and NFTs, enabling users to participate in emerging Web3 markets.",
      image: "/assets/images/zero-trade/nft-trading.webp",
    },
    {
      title: "Quantum Trading Models",
      description: 
        "Integrate quantum computing models for ultra-fast market simulations and precision-driven trade execution.",
      image: "/assets/images/zero-trade/quantum-trading-models.webp",
    }
  ];

  