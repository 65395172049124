export const CoreFeatureDetails = {
  "Cross-Chain Interoperability": {
    title: "Cross-Chain Interoperability",
    description: `
      Enable seamless integration and token transfers across major blockchain networks.
      
      **Key Features:**
      **Multi-Chain Compatibility:** Supports Ethereum, Binance Smart Chain, and other leading blockchains.
      **Effortless Transfers:** Move assets seamlessly between supported chains.
      **Enhanced Flexibility:** Allows users to interact with dApps across different ecosystems.
    `,
    image: "/assets/images/zero-blockchain/cross-chain.webp",
  },

  "Zero-Coin Protocol": {
    title: "Zero-Coin Protocol",
    description: `
      The backbone cryptocurrency of the Zero Blockchain ecosystem.
      
      **Key Features:**
      **Payments and Fees:** Use Zero-Coin for transactions within the ecosystem.
      **Governance Participation:** Stakeholders can vote on protocol updates and proposals.
      **Staking Rewards:** Earn rewards through staking mechanisms.
    `,
    image: "/assets/images/zero-blockchain/zero-coin.webp",
  },

  "Zero-Tokens Ecosystem": {
    title: "Zero-Tokens Ecosystem",
    description: `
      Conservation-driven tokens representing endangered species.
      
      **Key Features:**
      **Symbolic Representation:** Tokens symbolise species like Zero-Tiger or Zero-Panda.
      **Tradable Assets:** Buy, sell, and trade on decentralised exchanges.
      **Direct Conservation Impact:** Proceeds contribute to environmental and conservation efforts.
    `,
    image: "/assets/images/zero-blockchain/zero-tokens.webp",
  },

  "Zero-Bank Decentralised Platform": {
    title: "Zero-Bank Decentralised Platform",
    description: `
      A cutting-edge platform for financial services and decentralised banking.
      
      **Key Features:**
      **Cross-Border Payments:** Facilitate fast and cost-effective transactions globally.
      **DeFi Lending and Borrowing:** Access decentralised financial products and services.
      **Asset Investments:** Tokenise and manage real-world investments like real estate or commodities.
    `,
    image: "/assets/images/zero-blockchain/zero-bank.webp",
  },

  "Gamified Conservation": {
    title: "Gamified Conservation",
    description: `
      Engage users in eco-friendly activities with rewards tied to conservation goals.
      
      **Key Features:**
      **Challenges and Rewards:** Participate in tasks like tree planting to earn Zero-Tokens.
      **Community Engagement:** Build a community of eco-conscious users through gamification.
      **Impactful Contributions:** Directly support biodiversity and environmental conservation.
    `,
    image: "/assets/images/zero-blockchain/gamified-conservation.webp",
  },

  "Asset Tokenisation Framework": {
    title: "Asset Tokenisation Framework",
    description: `
      Transform real-world assets into blockchain-based tokens for increased liquidity.
      
      **Key Features:**
      **Real-World Asset Support:** Tokenise real estate, equities, and commodities.
      **Fractional Ownership:** Enable partial investment in high-value assets.
      **Enhanced Liquidity:** Simplify trading and ownership of traditionally illiquid assets.
    `,
    image: "/assets/images/zero-blockchain/asset-tokenisation.webp",
  },
};

export const TechnicalCapabilitiesDetails = {
  "Ethereum Compatibility": {
    title: "Ethereum Compatibility",
    description: `
      Full support for Ethereum standards to ensure seamless integration with the blockchain ecosystem.
      
      **Key Features:**
      **ERC-20 and ERC-721 Support:** Enables the issuance and management of fungible and non-fungible tokens.
      **Wallet Compatibility:** Integrates with popular wallets like MetaMask and Trust Wallet.
      **dApp Integration:** Supports decentralised applications (dApps) built on Ethereum.
    `,
    image: "/assets/images/zero-blockchain/ethereum-compatibility.webp",
  },

  "Hybrid Consensus Mechanism": {
    title: "Hybrid Consensus Mechanism",
    description: `
      Combines cutting-edge consensus algorithms for a secure and scalable blockchain experience.
      
      **Key Features:**
      **Proof-of-Stake (PoS):** Energy-efficient and environmentally friendly consensus mechanism.
      **Byzantine Fault Tolerance (BFT):** Ensures network reliability even with malicious actors.
      **Enhanced Scalability:** Supports high transaction throughput without compromising security.
    `,
    image: "/assets/images/zero-blockchain/hybrid-consensus.webp",
  },

  "Smart Contract Automation": {
    title: "Smart Contract Automation",
    description: `
      Streamline operations with reusable and customisable smart contracts tailored for blockchain use cases.
      
      **Key Features:**
      **Tokenisation Frameworks:** Simplify asset tokenisation with pre-built contract templates.
      **Compliance Automation:** Enforce KYC/AML and regulatory standards automatically.
      **Governance Tools:** Customise voting and decision-making protocols for decentralised governance.
    `,
    image: "/assets/images/zero-blockchain/smart-contracts.webp",
  },

  "Developer SDK and APIs": {
    title: "Developer SDK and APIs",
    description: `
      Robust tools to accelerate blockchain development and integration with the Zero Blockchain ecosystem.
      
      **Key Features:**
      **Comprehensive SDK:** Simplifies dApp development and blockchain interaction.
      **Customisable APIs:** Supports token issuance, balance fetching, and transaction automation.
      **Developer Documentation:** Detailed guides and examples for seamless integration.
    `,
    image: "/assets/images/zero-blockchain/sdk-apis.webp",
  },

  "Data Encryption and Multi-Sig Wallets": {
    title: "Data Encryption and Multi-Sig Wallets",
    description: `
      Advanced security measures to protect user assets and ensure safe transactions.
      
      **Key Features:**
      **AES-256 Encryption:** Secures data at rest and in transit.
      **Multi-Signature Wallets:** Enables shared custody with multiple approval requirements.
      **Secure Key Management:** Reduces the risk of unauthorised access and theft.
    `,
    image: "/assets/images/zero-blockchain/multi-sig-wallets.webp",
  },

  "Layer-2 Integration": {
    title: "Layer-2 Integration",
    description: `
      Enhance scalability and reduce transaction costs with advanced Layer-2 solutions.
      
      **Key Features:**
      **Faster Transactions:** Achieves near-instantaneous processing speeds.
      **Cost Efficiency:** Significantly lower fees for high-volume transactions.
      **Seamless Integration:** Fully compatible with Layer-2 protocols like Polygon and Optimism.
    `,
    image: "/assets/images/zero-blockchain/layer-2.webp",
  },
};

export const BusinessBenefitsDetails = {
  "Increased Asset Liquidity": {
    title: "Increased Asset Liquidity",
    description: `
      Unlock liquidity for traditionally illiquid assets through fractional ownership and tokenisation.

      **Key Features:**
      **Fractional Ownership:** Allow investors to purchase smaller portions of high-value assets.
      **Improved Market Access:** Enable seamless trading of tokenised assets across blockchain platforms.
      **Liquidity Pools:** Integrate with DeFi protocols for instant liquidity solutions.
    `,
    image: "/assets/images/zero-blockchain/asset-liquidity.webp",
  },
  "Enhanced Revenue Streams": {
    title: "Enhanced Revenue Streams",
    description: `
      Create diversified revenue opportunities within the Zero Blockchain ecosystem.

      **Key Features:**
      **Transaction Fees:** Earn fees from asset tokenisation, trades, and transfers.
      **DeFi Protocols:** Generate yield through staking, lending, and liquidity pools.
      **NFT Sales:** Leverage conservation-focused NFTs for additional revenue.
      **Subscription Models:** Offer tiered services for premium ecosystem features.
    `,
    image: "/assets/images/zero-blockchain/revenue-streams.webp",
  },
  "Regulatory Compliance Advantage": {
    title: "Regulatory Compliance Advantage",
    description: `
      Build trust with users and regulators by adhering to global financial standards.

      **Key Features:**
      **KYC/AML Protocols:** Verify users and prevent money laundering.
      **Audit Trails:** Ensure transparency through blockchain-ledger logging.
      **Global Standards Adherence:** Comply with GDPR, ISO 27001, and other key regulations.
      **Automated Compliance Reporting:** Simplify regulatory reporting requirements.
    `,
    image: "/assets/images/zero-blockchain/regulatory-compliance.webp",
  },
  "Scalable Ecosystem": {
    title: "Scalable Ecosystem",
    description: `
      Future-proof your operations with a modular, cloud-native blockchain infrastructure.

      **Key Features:**
      **Cloud Infrastructure:** Utilise AWS and Google Cloud for global scalability.
      **Modular Architecture:** Add or upgrade features without disrupting the ecosystem.
      **Sharding Technology:** Enhance transaction speed and reduce costs.
      **Developer-Friendly SDKs:** Encourage ecosystem growth through easy integration.
    `,
    image: "/assets/images/zero-blockchain/scalable-ecosystem.webp",
  },
  "Conservation Partnerships": {
    title: "Conservation Partnerships",
    description: `
      Promote sustainability by supporting global conservation efforts.

      **Key Features:**
      **NGO Collaborations:** Partner with environmental organisations for conservation projects.
      **Tokenised Donations:** Enable transparent funding for ecological initiatives.
      **Gamified Incentives:** Reward users for engaging in conservation challenges.
      **Zero-Tokens Ecosystem:** Allocate funds directly to conservation activities.
    `,
    image: "/assets/images/zero-blockchain/conservation-partnerships.webp",
  },
  "Improved Operational Efficiency": {
    title: "Improved Operational Efficiency",
    description: `
      Streamline processes and reduce costs with advanced automation and integration.

      **Key Features:**
      **Smart Contract Automation:** Simplify compliance, governance, and asset management.
      **API Integrations:** Seamlessly connect with existing systems for real-time data flows.
      **Process Optimisation:** Reduce manual intervention in key operations.
      **Cost Savings:** Leverage automation to cut operational costs across the ecosystem.
    `,
    image: "/assets/images/zero-blockchain/operational-efficiency.webp",
  },
};

export const SecurityAndComplianceDetails = {
  "AES-256 Encryption": {
    title: "AES-256 Encryption",
    description: `
      Protect sensitive data and transactions with industry-standard AES-256 encryption.
      
      **Key Features:**
      **Data Protection:** Ensures user data is encrypted both in transit and at rest.
      **High-Security Standard:** Meets top-tier cryptographic requirements for safeguarding information.
      **Comprehensive Coverage:** Applies encryption across all blockchain activities, including wallets and smart contracts.
    `,
    image: "/assets/images/zero-blockchain/aes-encryption.webp",
  },
  "Two-Factor Authentication (2FA)": {
    title: "Two-Factor Authentication (2FA)",
    description: `
      Strengthen account security with two-factor authentication.
      
      **Key Features:**
      **Identity Verification:** Adds a second layer of security to prevent unauthorised access.
      **Flexible Options:** Supports app-based, SMS, or hardware token authentication.
      **Risk Reduction:** Reduces vulnerabilities from compromised passwords.
    `,
    image: "/assets/images/zero-blockchain/2fa-security.webp",
  },
  "Regulatory Compliance": {
    title: "Regulatory Compliance",
    description: `
      Ensure adherence to global financial and data protection standards.
      
      **Key Features:**
      **GDPR and ISO 27001:** Fully compliant with leading international data protection standards.
      **KYC/AML Integration:** Verifies user identities and prevents illicit activities.
      **Transparent Ecosystem:** Builds user trust with a compliance-driven approach.
    `,
    image: "/assets/images/zero-blockchain/regulatory-standards.webp",
  },
  "Audit Logs and Monitoring": {
    title: "Audit Logs and Monitoring",
    description: `
      Enable traceability and accountability through comprehensive system monitoring.
      
      **Key Features:**
      **Transaction Logs:** Record every interaction and transaction on the blockchain.
      **Compliance Checks:** Simplify regulatory audits with tamper-proof logs.
      **Real-Time Monitoring:** Alerts administrators to anomalies or unauthorised actions.
    `,
    image: "/assets/images/zero-blockchain/audit-logs.webp",
  },
  "Role-Based Access Control (RBAC)": {
    title: "Role-Based Access Control (RBAC)",
    description: `
      Restrict sensitive actions to authorised users based on defined roles.
      
      **Key Features:**
      **Access Hierarchies:** Assign permissions to users based on their roles and responsibilities.
      **Critical Protection:** Prevent unauthorised actions, such as token minting or contract upgrades.
      **Streamlined Management:** Simplify user permission updates and audits.
    `,
    image: "/assets/images/zero-blockchain/rbac-control.webp",
  },
  "Data Breach Notification System": {
    title: "Data Breach Notification System",
    description: `
      Ensure swift action in the event of security breaches with prompt notifications.
      
      **Key Features:**
      **Immediate Alerts:** Notify users and admins about potential breaches in real time.
      **Risk Mitigation Steps:** Provide actionable recommendations to secure accounts and assets.
      **Regulatory Alignment:** Complies with breach notification requirements under GDPR.
    `,
    image: "/assets/images/zero-blockchain/data-breach-notification.webp",
  },
  "Immutable Ledger": {
    title: "Immutable Ledger",
    description: `
      Leverage blockchain immutability to create secure and unalterable records.
      
      **Key Features:**
      **Tamper-Proof Transactions:** Guarantee the integrity of all blockchain records.
      **Audit Trails:** Provide verifiable proof of all activities for compliance.
      **Enhanced Transparency:** Strengthen trust by ensuring data cannot be retroactively modified.
    `,
    image: "/assets/images/zero-blockchain/immutable-ledger.webp",
  },
  "Periodic Third-Party Audits": {
    title: "Periodic Third-Party Audits",
    description: `
      Validate the security and performance of Zero Blockchain through independent evaluations.
      
      **Key Features:**
      **Smart Contract Audits:** Regularly review smart contracts for vulnerabilities.
      **Infrastructure Security Checks:** Assess the robustness of blockchain infrastructure.
      **Enhanced Credibility:** Build user and investor trust through transparency.
    `,
    image: "/assets/images/zero-blockchain/third-party-audit.webp",
  },
  "Fraud Detection and Prevention": {
    title: "Fraud Detection and Prevention",
    description: `
      Use machine learning to detect and prevent fraudulent activities.
      
      **Key Features:**
      **Anomaly Detection:** Identify suspicious activities like unusual transaction patterns.
      **Risk Alerts:** Notify users and admins about potential threats in real time.
      **Proactive Measures:** Minimise financial and reputational risks through early detection.
    `,
    image: "/assets/images/zero-blockchain/fraud-detection.webp",
  },
  "Compliance Automation": {
    title: "Compliance Automation",
    description: `
      Simplify regulatory processes with automated compliance tools.
      
      **Key Features:**
      **KYC/AML Reporting:** Automatically generate compliance reports for regulatory bodies.
      **Tax Withholding:** Manage tax obligations on token transactions.
      **Streamlined Workflows:** Reduce manual intervention in compliance tasks.
    `,
    image: "/assets/images/zero-blockchain/compliance-automation.webp",
  },
  "Zero-Knowledge Proofs (ZKPs)": {
    title: "Zero-Knowledge Proofs (ZKPs)",
    description: `
      Enhance privacy through secure data verification without revealing sensitive information.
      
      **Key Features:**
      **Data Privacy:** Verify identity or transactions without exposing details.
      **Scalable Security:** Protect sensitive operations while maintaining blockchain efficiency.
      **Advanced Protocols:** Strengthen trust with cryptographically proven methods.
    `,
    image: "/assets/images/zero-blockchain/zero-knowledge-proofs.webp",
  },
  "Geo-Fencing for Transactions": {
    title: "Geo-Fencing for Transactions",
    description: `
      Add location-based restrictions for enhanced transaction control and security.
      
      **Key Features:**
      **Location-Based Controls:** Restrict actions based on user or transaction geography.
      **Enhanced Security:** Prevent unauthorised cross-border activities.
      **Customisable Policies:** Tailor geo-fencing rules to business or regulatory needs.
    `,
    image: "/assets/images/zero-blockchain/geo-fencing.webp",
  },
};

export const GovernanceFeaturesDetails = {
  "Decentralised Voting Mechanism": {
    title: "Decentralised Voting Mechanism",
    description: `
      Enable community-driven decision-making with a decentralised voting system.
      
      **Key Features:**
      **Community Proposals:** Zero-Coin holders can propose updates or changes.
      **Transparent Voting:** All votes are recorded on the blockchain for full transparency.
      **Inclusive Participation:** Any Zero-Coin holder can contribute to key decisions.
    `,
    image: "/assets/images/zero-blockchain/decentralised-voting.webp",
  },
  "On-Chain Governance Framework": {
    title: "On-Chain Governance Framework",
    description: `
      Ensure accountability and transparency with an on-chain governance model.
      
      **Key Features:**
      **Immutable Records:** All proposals, votes, and decisions are stored on the blockchain.
      **Smart Contract Automation:** Automates governance processes to reduce manual oversight.
      **Community Trust:** Strengthens ecosystem trust through transparent governance mechanisms.
    `,
    image: "/assets/images/zero-blockchain/on-chain-governance.webp",
  },
  "Staking-Weighted Influence": {
    title: "Staking-Weighted Influence",
    description: `
      Align decision-making power with ecosystem contributions through staking-weighted governance.
      
      **Key Features:**
      **Proportional Influence:** More Zero-Coin staked means greater governance weight.
      **Incentivised Participation:** Encourages active involvement by rewarding stakers with governance power.
      **Fair Representation:** Balances influence to align with ecosystem engagement.
    `,
    image: "/assets/images/zero-blockchain/staking-weighted-governance.webp",
  },
  "Dynamic Proposal System": {
    title: "Dynamic Proposal System",
    description: `
      Foster innovation and collaboration with a flexible proposal system.
      
      **Key Features:**
      **Collaborative Refinement:** Community members can debate and refine proposals before voting.
      **Proposal Transparency:** All proposals are visible to the ecosystem for open discussion.
      **High-Quality Decisions:** Ensures that only well-thought-out ideas are implemented.
    `,
    image: "/assets/images/zero-blockchain/dynamic-proposal-system.webp",
  },
  "Treasury Oversight Committee": {
    title: "Treasury Oversight Committee",
    description: `
      Ensure responsible fund management through community-elected oversight.
      
      **Key Features:**
      **Community Accountability:** Members are elected by Zero-Coin holders.
      **Strategic Allocation:** Funds are directed towards long-term ecosystem sustainability.
      **Transparent Reporting:** Regular updates on fund usage and impact.
    `,
    image: "/assets/images/zero-blockchain/treasury-oversight.webp",
  },
  "DAO Integration for Conservation": {
    title: "DAO Integration for Conservation",
    description: `
      Enable community-led conservation efforts through decentralised funding.
      
      **Key Features:**
      **Zero-Token Utilisation:** Use Zero-Tokens to fund and vote on conservation projects.
      **Community Engagement:** Empower users to drive ecological impact initiatives.
      **DAO-Led Efficiency:** Streamline funding processes through decentralised governance.
    `,
    image: "/assets/images/zero-blockchain/dao-conservation.webp",
  },
};

export const KeyBenefitsDetails = {
  "Financial Empowerment": {
    title: "Financial Empowerment",
    description: `
      Democratises access to cutting-edge financial tools for users worldwide.
      
      **Key Features:**
      **DeFi Lending and Borrowing:** Users can access capital or earn interest without intermediaries.
      **Tokenisation Opportunities:** Transform physical and digital assets into tradeable tokens.
      **Broad Access:** Empowers individuals and businesses across diverse markets.
    `,
    image: "/assets/images/zero-blockchain/financial-empowerment.webp",
  },
  "Sustainability Integration": {
    title: "Sustainability Integration",
    description: `
      Combines blockchain with tangible environmental initiatives.
      
      **Key Features:**
      **Tokenised Conservation:** Contribute to ecological projects through Zero-Tokens.
      **Global Partnerships:** Collaborate with NGOs for impactful environmental outcomes.
      **Gamified Challenges:** Incentivise user participation in conservation activities.
    `,
    image: "/assets/images/zero-blockchain/sustainability-integration.webp",
  },
  "Enhanced Liquidity": {
    title: "Enhanced Liquidity",
    description: `
      Unlocks value from traditionally illiquid assets through tokenisation.
      
      **Key Features:**
      **Fractional Ownership:** Divide assets like real estate into smaller, tradeable tokens.
      **Decentralised Exchanges:** Enable seamless trading of tokenised assets.
      **Liquidity Pools:** Provide liquidity for tokens in DeFi markets.
    `,
    image: "/assets/images/zero-blockchain/enhanced-liquidity.webp",
  },
  "Decentralised Governance": {
    title: "Decentralised Governance",
    description: `
      Places decision-making power in the hands of the community.
      
      **Key Features:**
      **Voting Mechanisms:** Empower Zero-Coin holders to vote on ecosystem decisions.
      **Transparent Records:** All governance actions are immutably stored on-chain.
      **Community Alignment:** Ensure platform development reflects user priorities.
    `,
    image: "/assets/images/zero-blockchain/decentralised-governance.webp",
  },
  "Low-Cost Cross-Border Transactions": {
    title: "Low-Cost Cross-Border Transactions",
    description: `
      Revolutionises global payments with efficient, decentralised solutions.
      
      **Key Features:**
      **Zero-Bank Integration:** Facilitate international payments without SWIFT fees.
      **Instant Settlements:** Reduce delays associated with traditional banking.
      **Cost Efficiency:** Eliminate intermediaries to lower transaction costs.
    `,
    image: "/assets/images/zero-blockchain/cross-border-transactions.webp",
  },
  "Real-Time Analytics": {
    title: "Real-Time Analytics",
    description: `
      Equip users with actionable insights for smarter financial decisions.
      
      **Key Features:**
      **Performance Metrics:** Monitor live data on token and portfolio performance.
      **DeFi Insights:** Analyse lending, borrowing, and liquidity trends in real time.
      **Custom Dashboards:** Personalise analytics for tailored decision-making.
    `,
    image: "/assets/images/zero-blockchain/real-time-analytics.webp",
  },
  "Community-Driven Conservation": {
    title: "Community-Driven Conservation",
    description: `
      Support environmental projects through collective action and Zero-Tokens.
      
      **Key Features:**
      **Zero-Tokens Revenue:** Fund global conservation efforts via token sales.
      **Ecological Impact:** Align financial participation with environmental benefits.
      **NGO Partnerships:** Collaborate with trusted organisations for transparency.
    `,
    image: "/assets/images/zero-blockchain/community-conservation.webp",
  },
  "Comprehensive Security Framework": {
    title: "Comprehensive Security Framework",
    description: `
      Protect assets and data with state-of-the-art security protocols.
      
      **Key Features:**
      **AES-256 Encryption:** Secure data at rest and in transit.
      **Multi-Sig Wallets:** Shared access for added security layers.
      **Two-Factor Authentication (2FA):** Enhanced account protection.
    `,
    image: "/assets/images/zero-blockchain/security-framework.webp",
  },
  "Cross-Chain Interoperability": {
    title: "Cross-Chain Interoperability",
    description: `
      Connect multiple blockchain networks for seamless integration.
      
      **Key Features:**
      **Token Transfers:** Enable cross-chain movement of tokens and assets.
      **dApp Compatibility:** Integrate with Ethereum, Binance Smart Chain, and Solana.
      **Ecosystem Expansion:** Enhance interoperability for broader utility.
    `,
    image: "/assets/images/zero-blockchain/cross-chain-interoperability.webp",
  },
  "Scalable and Future-Ready": {
    title: "Scalable and Future-Ready",
    description: `
      Prepare for blockchain advancements with innovative scalability solutions.
      
      **Key Features:**
      **Layer-2 Integration:** Support faster transactions with lower costs.
      **Sharding Technology:** Improve performance with parallel transaction processing.
      **Quantum-Resilient Encryption:** Secure the platform against future risks.
    `,
    image: "/assets/images/zero-blockchain/scalable-future.webp",
  },
  "Decentralised Identity Management": {
    title: "Decentralised Identity Management",
    description: `
      Empower users with control over their identities and data.
      
      **Key Features:**
      **Self-Sovereign Identity:** Enable secure and private authentication.
      **Blockchain Records:** Store identity details on immutable ledgers.
      **User Privacy:** Minimise data exposure in transactions.
    `,
    image: "/assets/images/zero-blockchain/decentralised-identity.webp",
  },
  "Customisable dApp Ecosystem": {
    title: "Customisable dApp Ecosystem",
    description: `
      Foster innovation through developer-friendly tools and SDKs.
      
      **Key Features:**
      **Developer SDKs:** Build bespoke dApps and tokenisation solutions.
      **Ecosystem Flexibility:** Adapt the platform for diverse use cases.
      **Enhanced Utility:** Expand platform functionalities through custom apps.
    `,
    image: "/assets/images/zero-blockchain/custom-dapp-ecosystem.webp",
  },
};

export const UseCasesDetails = {
  "Tokenised Microfinance": {
    title: "Tokenised Microfinance",
    description: `
      Empower underbanked communities by enabling peer-to-peer microloans through tokenisation.
      
      **Key Features:**
      **Peer-to-Peer Lending:** Facilitate direct financial support without traditional intermediaries.
      **Reduced Costs:** Lower transaction and operational costs for microfinance activities.
      **Financial Inclusion:** Extend access to financial resources to underserved populations.
    `,
    image: "/assets/images/zero-blockchain/tokenised-microfinance.webp",
  },
  "Carbon Credit Tokenisation": {
    title: "Carbon Credit Tokenisation",
    description: `
      Promote sustainable practices by transforming carbon credits into blockchain-based tokens.
      
      **Key Features:**
      **Transparency:** Ensure clear visibility in carbon trading markets.
      **Liquidity:** Enable seamless trading of carbon credits globally.
      **Environmental Impact:** Drive adoption of sustainable initiatives through tokenised incentives.
    `,
    image: "/assets/images/zero-blockchain/carbon-credit-tokenisation.webp",
  },
  "Supply Chain Transparency": {
    title: "Supply Chain Transparency",
    description: `
      Tokenise goods and raw materials to enhance supply chain management with real-time insights.
      
      **Key Features:**
      **Traceability:** Monitor the journey of goods through the supply chain.
      **Authenticity Verification:** Prevent counterfeiting and fraud in supply chain networks.
      **Operational Efficiency:** Streamline logistics and reduce administrative overhead.
    `,
    image: "/assets/images/zero-blockchain/supply-chain-transparency.webp",
  },
  "Decentralised Philanthropy": {
    title: "Decentralised Philanthropy",
    description: `
      Use blockchain tokenisation to facilitate transparent and accountable donation management.
      
      **Key Features:**
      **Donation Traceability:** Ensure funds are allocated to intended causes.
      **Elimination of Corruption:** Minimise the risk of fund mismanagement.
      **Global Reach:** Expand access to philanthropic efforts across borders.
    `,
    image: "/assets/images/zero-blockchain/decentralised-philanthropy.webp",
  },
  "Tokenised Education Credentials": {
    title: "Tokenised Education Credentials",
    description: `
      Provide immutable, verifiable digital credentials for educational achievements.
      
      **Key Features:**
      **Cross-Border Recognition:** Ensure seamless acceptance of credentials globally.
      **Immutable Records:** Store educational data securely on the blockchain.
      **Enhanced Verification:** Simplify and speed up the credential verification process.
    `,
    image: "/assets/images/zero-blockchain/education-credentials.webp",
  },
  "Healthcare Data Tokenisation": {
    title: "Healthcare Data Tokenisation",
    description: `
      Securely manage patient data on the blockchain for controlled access and privacy.
      
      **Key Features:**
      **Data Privacy:** Maintain compliance with regulations like GDPR and HIPAA.
      **Controlled Access:** Allow patients to grant and revoke data access rights.
      **Monetisation Opportunities:** Enable patients to monetise their data ethically.
    `,
    image: "/assets/images/zero-blockchain/healthcare-data-tokenisation.webp",
  },
};

export const EcosystemPartnersDetails = {
  "WWF (World Wide Fund for Nature)": {
    title: "WWF (World Wide Fund for Nature)",
    description: `
      Collaborates with Zero Blockchain to drive impactful conservation efforts using blockchain technology.
      
      **Key Contributions:**
      **Fundraising Campaigns:** Utilises Zero-Tokens to support endangered species preservation.
      **Awareness Initiatives:** Engages global communities through gamified conservation challenges.
      **Long-Term Impact:** Channels blockchain innovations toward ecological sustainability.
    `,
    image: "/assets/images/zero-blockchain/wwf-partnership.webp",
  },
  "UN Environment Programme": {
    title: "UN Environment Programme",
    description: `
      Partners with Zero Blockchain to create blockchain-driven solutions for environmental sustainability.
      
      **Key Contributions:**
      **Carbon Credit Tokenisation:** Develops transparent and tradable carbon credits.
      **Sustainable Projects:** Drives funding for green initiatives through tokenisation.
      **Global Standards:** Ensures compliance with international environmental regulations.
    `,
    image: "/assets/images/zero-blockchain/un-environment-partnership.webp",
  },
  "Ethereum Foundation": {
    title: "Ethereum Foundation",
    description: `
      Collaborates with Zero Blockchain to enhance Ethereum compatibility and ecosystem integration.
      
      **Key Contributions:**
      **Technical Support:** Provides guidance for seamless ERC-20 and ERC-721 token integration.
      **Scalability Enhancements:** Aids in implementing Layer-2 solutions for Zero Blockchain.
      **Ecosystem Synergy:** Fosters innovation through collaborative development efforts.
    `,
    image: "/assets/images/zero-blockchain/ethereum-foundation.webp",
  },
  "Binance Smart Chain": {
    title: "Binance Smart Chain",
    description: `
      Enables cross-chain functionality for Zero Blockchain, expanding its interoperability and reach.
      
      **Key Contributions:**
      **Cross-Chain Integration:** Facilitates token transfers between Binance Smart Chain and Zero Blockchain.
      **Market Expansion:** Broadens accessibility for Zero-Tokens and Zero-Coin users.
      **DeFi Opportunities:** Supports staking and liquidity pool integrations.
    `,
    image: "/assets/images/zero-blockchain/binance-partnership.webp",
  },
  "Conservation International": {
    title: "Conservation International",
    description: `
      Works with Zero Blockchain to amplify ecological impact through targeted conservation efforts.
      
      **Key Contributions:**
      **Reforestation Initiatives:** Funds global reforestation projects via Zero-Tokens.
      **Anti-Poaching Programs:** Directs proceeds toward combating wildlife poaching.
      **Awareness Campaigns:** Engages communities with innovative conservation challenges.
    `,
    image: "/assets/images/zero-blockchain/conservation-international.webp",
  },
  "OpenSea": {
    title: "OpenSea",
    description: `
      Partners with Zero Blockchain to support NFT trading and showcase conservation-linked digital assets.
      
      **Key Contributions:**
      **NFT Marketplace Integration:** Facilitates trading of NFT versions of Zero-Tokens.
      **Global Visibility:** Highlights conservation efforts through digital collectibles.
      **Ecosystem Growth:** Expands the reach of Zero Blockchain’s conservation initiatives.
    `,
    image: "/assets/images/zero-blockchain/opensea-partnership.webp",
  },
};

export const DeveloperResourcesDetails = {
  "Zero Blockchain SDK": {
    title: "Zero Blockchain SDK",
    description: `
      A powerful software development kit designed to streamline dApp development and token integration within the Zero Blockchain ecosystem.
      
      **Key Features:**
      **dApp Creation Tools:** Build decentralised applications with built-in support for Zero-Tokens and Zero-Coin.
      **Tokenisation Support:** Easily implement asset tokenisation and custom token solutions.
      **Cross-Platform Compatibility:** Develop applications compatible with multiple blockchain networks.
    `,
    image: "/assets/images/zero-blockchain/sdk.webp",
  },
  "API Documentation": {
    title: "API Documentation",
    description: `
      Comprehensive API guides to facilitate seamless integration with Zero Blockchain’s features and services.
      
      **Key Features:**
      **Tokenisation Integration:** Step-by-step instructions for implementing asset tokenisation.
      **Payment APIs:** Simplify Zero-Coin and Zero-Token payment integration.
      **Conservation Tools:** Leverage APIs for conservation-focused dApps and workflows.
    `,
    image: "/assets/images/zero-blockchain/api-docs.webp",
  },
  "Smart Contract Templates": {
    title: "Smart Contract Templates",
    description: `
      Pre-built templates for popular blockchain standards, accelerating development time and reducing complexity.
      
      **Key Features:**
      **ERC-20 and ERC-721:** Templates for fungible and non-fungible token standards.
      **ERC-1400:** Security token templates for regulatory-compliant asset tokenisation.
      **Customisation Options:** Adapt templates for specific business and technical needs.
    `,
    image: "/assets/images/zero-blockchain/smart-contract-templates.webp",
  },
  "Developer Forum": {
    title: "Developer Forum",
    description: `
      A collaborative platform for developers to share knowledge, solve problems, and contribute to the Zero Blockchain community.
      
      **Key Features:**
      **Discussion Threads:** Engage with peers on Zero Blockchain development topics.
      **Expert Support:** Access guidance from experienced developers and moderators.
      **Open Collaboration:** Participate in community-driven projects and initiatives.
    `,
    image: "/assets/images/zero-blockchain/developer-forum.webp",
  },
  "Code Samples": {
    title: "Code Samples",
    description: `
      Ready-to-use examples showcasing practical implementations of Zero Blockchain features.
      
      **Key Features:**
      **Tokenisation Integration:** Sample code for creating tokenised assets.
      **DeFi Features:** Examples for staking, lending, and governance mechanisms.
      **Custom Solutions:** Templates for integrating Zero Blockchain into existing systems.
    `,
    image: "/assets/images/zero-blockchain/code-samples.webp",
  },
  "Testnet Access": {
    title: "Testnet Access",
    description: `
      A secure environment for developers to test and deploy applications without affecting the main blockchain.
      
      **Key Features:**
      **Sandbox Testing:** Experiment with tokenisation and dApp features safely.
      **Real-World Scenarios:** Simulate transaction flows and network interactions.
      **Developer Tools:** Access testnet-specific tools and resources for debugging and optimisation.
    `,
    image: "/assets/images/zero-blockchain/testnet-access.webp",
  },
};

export const ConservationInitiativesDetails = {
  "Endangered Species Tokenisation": {
    title: "Endangered Species Tokenisation",
    description: `
      Utilise blockchain to create Zero-Tokens representing endangered species, directly linking token ownership to conservation impact.
      
      **Key Features:**
      **Symbolic Tokens:** Each token represents a specific endangered species, such as tigers or pandas.
      **Direct Funding:** Proceeds from token sales are directed to conservation projects and NGOs.
      **Emotional Engagement:** Create a personal connection between users and the species they are helping to protect.
    `,
    image: "/assets/images/zero-blockchain/endangered-species.webp",
  },
  "Gamified Reforestation": {
    title: "Gamified Reforestation",
    description: `
      Engage users in environmental sustainability through interactive challenges and token rewards.
      
      **Key Features:**
      **Reforestation Games:** Users participate in activities like tree planting and ecosystem restoration.
      **Reward System:** Earn Zero-Tokens as incentives for environmental contributions.
      **Community Impact:** Foster collective action for large-scale environmental benefits.
    `,
    image: "/assets/images/zero-blockchain/reforestation-challenges.webp",
  },
  "Tokenised Conservation Funds": {
    title: "Tokenised Conservation Funds",
    description: `
      Pool financial resources globally through token sales to fund conservation initiatives.
      
      **Key Features:**
      **Crowdfunding:** Enable collective funding for large-scale conservation projects.
      **Global Reach:** Support NGOs and initiatives in multiple countries.
      **Impact Transparency:** Use blockchain to track and report fund allocation and outcomes.
    `,
    image: "/assets/images/zero-blockchain/conservation-funds.webp",
  },
  "Partnerships with NGOs": {
    title: "Partnerships with NGOs",
    description: `
      Collaborate with leading environmental organisations to implement impactful conservation projects.
      
      **Key Features:**
      **Project Funding:** Zero-Tokens fund initiatives like anti-poaching efforts and habitat preservation.
      **Expert Collaboration:** Work with specialists to maximise conservation effectiveness.
      **Global Network:** Partner with organisations across diverse regions and ecosystems.
    `,
    image: "/assets/images/zero-blockchain/ngo-partnerships.webp",
  },
  "Carbon Credit Tokenisation": {
    title: "Carbon Credit Tokenisation",
    description: `
      Transform carbon credits into tradable blockchain tokens to promote sustainability and responsible investments.
      
      **Key Features:**
      **Token Marketplace:** Enable buying, selling, and trading of tokenised carbon credits.
      **Corporate Adoption:** Encourage businesses to invest in carbon offsets transparently.
      **Environmental Incentives:** Reward environmentally responsible actions with tokenised credits.
    `,
    image: "/assets/images/zero-blockchain/carbon-credits.webp",
  },
  "Conservation Education Campaigns": {
    title: "Conservation Education Campaigns",
    description: `
      Raise awareness about endangered species and environmental challenges using Zero-Token-backed campaigns.
      
      **Key Features:**
      **Educational Initiatives:** Promote knowledge about conservation through workshops and digital content.
      **Token Rewards:** Incentivise participation in educational activities with Zero-Tokens.
      **Community Engagement:** Foster a global community dedicated to environmental awareness and action.
    `,
    image: "/assets/images/zero-blockchain/conservation-education.webp",
  },
};

export const DeveloperBenefitsDetails = {
  "Comprehensive SDKs": {
    title: "Comprehensive SDKs",
    description: `
      Access Zero Blockchain's robust software development kits (SDKs) to streamline dApp development and blockchain integration.
      
      **Key Features:**
      **Seamless Integration:** Build dApps with ease, leveraging Zero Blockchain's pre-built modules and APIs.
      **Custom Solutions:** Develop innovative blockchain projects tailored to specific use cases like DeFi, tokenisation, and conservation.
      **Cross-Platform Support:** Compatible with major operating systems and programming languages.
    `,
    image: "/assets/images/zero-blockchain/sdk-tools.webp",
  },
  "Smart Contract Templates": {
    title: "Smart Contract Templates",
    description: `
      Speed up blockchain development with ready-to-use smart contract templates for various applications.
      
      **Key Features:**
      **Tokenisation Contracts:** Pre-built contracts for creating ERC-20 and ERC-721 tokens.
      **Staking and Governance:** Simplify DeFi projects with staking and governance templates.
      **Customisation Options:** Tailor contracts to align with specific project needs.
    `,
    image: "/assets/images/zero-blockchain/smart-contract-templates.webp",
  },
  "Multi-Chain Integration": {
    title: "Multi-Chain Integration",
    description: `
      Develop blockchain solutions compatible with multiple networks, ensuring interoperability and flexibility.
      
      **Key Features:**
      **Cross-Chain Compatibility:** Build dApps that integrate with Ethereum, Binance Smart Chain, Solana, and other supported networks.
      **API Access:** Use Zero Blockchain’s APIs to connect with various chains seamlessly.
      **Enhanced Reach:** Deploy solutions across multiple blockchains for wider user adoption.
    `,
    image: "/assets/images/zero-blockchain/multi-chain-integration.webp",
  },
  "Developer Community Support": {
    title: "Developer Community Support",
    description: `
      Join Zero Blockchain's active developer community to share ideas, resources, and technical expertise.
      
      **Key Features:**
      **Collaboration Opportunities:** Work with other developers on innovative projects.
      **Resource Sharing:** Access a library of shared tools, guides, and code samples.
      **Technical Assistance:** Get support from experienced blockchain developers and Zero Blockchain’s technical team.
    `,
    image: "/assets/images/zero-blockchain/community-support.webp",
  },
  "Testnet Access": {
    title: "Testnet Access",
    description: `
      Test and refine blockchain solutions in a secure and controlled testnet environment before deployment.
      
      **Key Features:**
      **Sandbox Environment:** Experiment with dApps and contracts without impacting the main network.
      **Debugging Tools:** Identify and fix issues using testnet diagnostics.
      **Realistic Simulations:** Conduct trials under conditions that mimic the mainnet environment.
    `,
    image: "/assets/images/zero-blockchain/testnet-access.webp",
  },
  "Custom Tokenisation Tools": {
    title: "Custom Tokenisation Tools",
    description: `
      Leverage Zero Blockchain's flexible APIs and modular framework to develop bespoke tokenisation solutions.
      
      **Key Features:**
      **Asset Tokenisation:** Create tokenised assets like real estate, carbon credits, or commodities.
      **Modular Framework:** Build custom solutions using a library of modular tools and components.
      **Developer-Friendly APIs:** Simplify integration and functionality with user-friendly APIs.
    `,
    image: "/assets/images/zero-blockchain/custom-tokenisation.webp",
  },
};

export const FinancialBenefitsDetails = {
  "Cost Reduction": {
    title: "Cost Reduction",
    description: `
      Minimise expenses through blockchain-based automation and the removal of intermediaries.
      
      **Key Features:**
      **Lower Transaction Fees:** Reduce costs for payments, lending, and tokenisation by bypassing traditional financial systems.
      **Automation Savings:** Smart contracts automate workflows, eliminating manual intervention and operational inefficiencies.
      **No Middlemen:** Direct peer-to-peer transactions cut out intermediary fees.
    `,
    image: "/assets/images/zero-blockchain/cost-reduction.webp",
  },
  "Increased Liquidity": {
    title: "Increased Liquidity",
    description: `
      Tokenise assets to unlock liquidity for markets traditionally limited by illiquidity.
      
      **Key Features:**
      **Decentralised Trading:** Enable 24/7 trading of tokenised assets on decentralised exchanges.
      **Market Accessibility:** Expand access to high-value assets for a broader investor base.
      **Improved Cash Flow:** Liquidity solutions for real estate, commodities, and other traditionally illiquid assets.
    `,
    image: "/assets/images/zero-blockchain/increased-liquidity.webp",
  },
  "Revenue Diversification": {
    title: "Revenue Diversification",
    description: `
      Open new financial opportunities with innovative blockchain-based revenue models.
      
      **Key Features:**
      **Token Sales:** Raise funds through initial token offerings or NFT marketplaces.
      **Staking Rewards:** Encourage user engagement and generate passive income for participants.
      **Decentralised Lending:** Facilitate lending and borrowing for revenue generation within the ecosystem.
    `,
    image: "/assets/images/zero-blockchain/revenue-diversification.webp",
  },
  "Enhanced Transparency": {
    title: "Enhanced Transparency",
    description: `
      Build trust and ensure compliance with immutable blockchain records for all transactions.
      
      **Key Features:**
      **Immutable Ledger:** Maintain tamper-proof records for auditing and compliance purposes.
      **Real-Time Reporting:** Offer up-to-date transaction insights for stakeholders.
      **Trustworthy Ecosystem:** Foster confidence in financial activities with verifiable data.
    `,
    image: "/assets/images/zero-blockchain/enhanced-transparency.webp",
  },
  "Cross-Border Payments": {
    title: "Cross-Border Payments",
    description: `
      Simplify and accelerate international transactions using decentralised payment systems.
      
      **Key Features:**
      **Global Accessibility:** Transact seamlessly across borders without traditional banking restrictions.
      **Low Costs:** Reduce fees associated with international payments and currency exchanges.
      **Fast Settlements:** Process payments in minutes rather than days.
    `,
    image: "/assets/images/zero-blockchain/cross-border-payments.webp",
  },
  "Asset Fractionalisation": {
    title: "Asset Fractionalisation",
    description: `
      Democratise investment opportunities by enabling fractional ownership in high-value assets.
      
      **Key Features:**
      **Broadened Accessibility:** Allow smaller investors to participate in markets previously limited to high-net-worth individuals.
      **Enhanced Portfolio Options:** Diversify investments across multiple fractionalised assets.
      **Increased Market Participation:** Encourage liquidity and trading activity in tokenised assets.
    `,
    image: "/assets/images/zero-blockchain/asset-fractionalisation.webp",
  },
};

export const OperationalBenefitsDetails = {
  "Process Automation": {
    title: "Process Automation",
    description: `
      Enhance efficiency by automating complex workflows using smart contracts.
      
      **Key Features:**
      **Smart Contract Automation:** Minimise manual intervention by automating repetitive tasks.
      **Operational Efficiency:** Streamline processes, reducing time and costs.
      **Error Reduction:** Eliminate human errors in critical workflows.
    `,
    image: "/assets/images/zero-blockchain/process-automation.webp",
  },
  "Improved Scalability": {
    title: "Improved Scalability",
    description: `
      Scale operations effortlessly with blockchain's adaptable architecture.
      
      **Key Features:**
      **High Transaction Throughput:** Support growing user bases and transaction volumes.
      **Layer-2 Solutions:** Leverage technologies like sharding and rollups for faster processing.
      **Future-Proof Design:** Prepare for expanding business needs with scalable infrastructure.
    `,
    image: "/assets/images/zero-blockchain/improved-scalability.webp",
  },
  "Data Integrity": {
    title: "Data Integrity",
    description: `
      Guarantee accuracy and security of records with blockchain's immutable ledger.
      
      **Key Features:**
      **Tamper-Proof Records:** Ensure data cannot be altered post-recording.
      **Audit-Ready:** Simplify compliance with reliable, verifiable transaction logs.
      **Trustworthy Data:** Build confidence with secure and accurate record-keeping.
    `,
    image: "/assets/images/zero-blockchain/data-integrity.webp",
  },
  "Real-Time Analytics": {
    title: "Real-Time Analytics",
    description: `
      Make informed decisions with up-to-the-minute insights and analytics.
      
      **Key Features:**
      **Live Performance Metrics:** Track token activity, asset performance, and user behaviour.
      **Customisable Dashboards:** Tailor analytics views to business needs.
      **Actionable Insights:** Derive meaningful strategies from comprehensive data.
    `,
    image: "/assets/images/zero-blockchain/real-time-analytics.webp",
  },
  "Seamless Integration": {
    title: "Seamless Integration",
    description: `
      Incorporate blockchain solutions into existing systems with ease.
      
      **Key Features:**
      **Robust APIs and SDKs:** Enable efficient integration with minimal development effort.
      **Cross-Platform Compatibility:** Work with various platforms and legacy systems.
      **Modular Design:** Adopt blockchain features incrementally based on requirements.
    `,
    image: "/assets/images/zero-blockchain/seamless-integration.webp",
  },
  "Risk Mitigation": {
    title: "Risk Mitigation",
    description: `
      Reduce exposure to operational and security risks with decentralised systems.
      
      **Key Features:**
      **Decentralised Architecture:** Avoid single points of failure with distributed processes.
      **Compliance Monitoring:** Automate adherence to regulations and standards.
      **Enhanced Security Protocols:** Protect operations against fraud and cyber threats.
    `,
    image: "/assets/images/zero-blockchain/risk-mitigation.webp",
  },
};

export const SocialBenefitsDetails = {
  "Increased Financial Inclusion": {
    title: "Increased Financial Inclusion",
    description: `
      Empower underbanked populations by leveraging blockchain for financial accessibility.
      
      **Key Features:**
      **Decentralised Banking:** Provide access to savings, loans, and payment tools without traditional banks.
      **Tokenised Assets:** Enable investments in fractionalised assets to lower entry barriers.
      **Empowerment:** Promote economic independence for underprivileged communities.
    `,
    image: "/assets/images/zero-blockchain/financial-inclusion.webp",
  },
  "Support for Conservation": {
    title: "Support for Conservation",
    description: `
      Promote global conservation by linking technology to impactful environmental initiatives.
      
      **Key Features:**
      **Zero-Tokens for Conservation:** Direct contributions to reforestation and anti-poaching projects.
      **Transparent Fund Allocation:** Ensure resources reach intended environmental efforts.
      **Gamified Engagement:** Incentivise users to participate in ecological challenges.
    `,
    image: "/assets/images/zero-blockchain/conservation-support.webp",
  },
  "Decentralised Collaboration": {
    title: "Decentralised Collaboration",
    description: `
      Enhance transparency and inclusivity by enabling communities to work together using blockchain.
      
      **Key Features:**
      **Blockchain Transparency:** Build trust with immutable records of collaborative efforts.
      **Community-Led Projects:** Allow groups to initiate and manage projects collectively.
      **Shared Goals:** Align global efforts for social and environmental benefits.
    `,
    image: "/assets/images/zero-blockchain/decentralised-collaboration.webp",
  },
  "Education and Awareness": {
    title: "Education and Awareness",
    description: `
      Raise global awareness of critical issues through interactive and educational blockchain initiatives.
      
      **Key Features:**
      **Gamification:** Engage users with conservation-themed games and rewards.
      **NFT Awareness Campaigns:** Promote endangered species and ecological issues with NFTs.
      **Knowledge Sharing:** Educate communities on blockchain’s role in addressing global challenges.
    `,
    image: "/assets/images/zero-blockchain/education-awareness.webp",
  },
  "Strengthened Community Engagement": {
    title: "Strengthened Community Engagement",
    description: `
      Foster deeper connections and incentivise positive social impact with token rewards.
      
      **Key Features:**
      **Zero-Tokens Incentives:** Reward users for contributing to social and environmental projects.
      **Collaborative Events:** Organise community-driven initiatives supported by blockchain.
      **Shared Impact:** Build a sense of achievement through collective efforts.
    `,
    image: "/assets/images/zero-blockchain/community-engagement.webp",
  },
  "Democratised Access": {
    title: "Democratised Access",
    description: `
      Ensure equitable access to cutting-edge financial tools and investment opportunities.
      
      **Key Features:**
      **DeFi Platforms:** Offer decentralised lending, borrowing, and staking opportunities.
      **Inclusive Investments:** Lower barriers to entry for traditionally exclusive markets.
      **Global Reach:** Make advanced financial tools available to all regions and demographics.
    `,
    image: "/assets/images/zero-blockchain/democratised-access.webp",
  },
};

export const EnvironmentalBenefitsDetails = {
  "Conservation Funding": {
    title: "Conservation Funding",
    description: `
      Channel revenue from blockchain activities directly into impactful conservation projects.
      
      **Key Features:**
      **Token Revenue Allocation:** Proceeds from Zero-Tokens and NFTs support global conservation.
      **NGO Collaboration:** Partner with organisations to maximise the reach of funding.
      **Transparent Transactions:** Use blockchain for clear and traceable allocation of resources.
    `,
    image: "/assets/images/zero-blockchain/conservation-funding.webp",
  },
  "Carbon Footprint Reduction": {
    title: "Carbon Footprint Reduction",
    description: `
      Promote sustainable practices and carbon offset initiatives using blockchain technology.
      
      **Key Features:**
      **Carbon Credit Tokenisation:** Create and trade carbon offset credits transparently.
      **Eco-Incentives:** Reward users for eco-friendly activities.
      **Green Project Support:** Direct funding toward renewable energy and carbon capture projects.
    `,
    image: "/assets/images/zero-blockchain/carbon-footprint-reduction.webp",
  },
  "Reforestation Initiatives": {
    title: "Reforestation Initiatives",
    description: `
      Encourage environmental restoration by rewarding tree-planting efforts with Zero-Tokens.
      
      **Key Features:**
      **Tree-Planting Challenges:** Engage users in gamified reforestation activities.
      **Reward System:** Distribute Zero-Tokens for verified contributions.
      **Impact Measurement:** Monitor reforestation progress using blockchain data.
    `,
    image: "/assets/images/zero-blockchain/reforestation-initiatives.webp",
  },
  "Wildlife Protection": {
    title: "Wildlife Protection",
    description: `
      Fund critical anti-poaching and habitat preservation programs using conservation-driven blockchain solutions.
      
      **Key Features:**
      **Zero-Tokens for Wildlife:** Direct token revenues to protect endangered species.
      **Anti-Poaching Tech:** Leverage blockchain to track funding for field operations.
      **Habitat Restoration:** Support ecosystem recovery through dedicated funds.
    `,
    image: "/assets/images/zero-blockchain/wildlife-protection.webp",
  },
  "Sustainable Development": {
    title: "Sustainable Development",
    description: `
      Facilitate investments in renewable energy and green infrastructure through tokenised assets.
      
      **Key Features:**
      **Green Tokenisation:** Enable fractional ownership of eco-projects.
      **Renewable Energy Projects:** Back initiatives like solar, wind, and hydropower developments.
      **Blockchain Transparency:** Showcase the environmental impact of investments.
    `,
    image: "/assets/images/zero-blockchain/sustainable-development.webp",
  },
  "Gamified Eco-Engagement": {
    title: "Gamified Eco-Engagement",
    description: `
      Inspire user participation in environmental activities with gamified challenges and rewards.
      
      **Key Features:**
      **Eco-Challenges:** Motivate users to engage in biodiversity restoration and waste reduction.
      **Zero-Tokens Incentives:** Reward eco-friendly actions with token-based prizes.
      **Social Engagement:** Build a community of environmentally conscious participants.
    `,
    image: "/assets/images/zero-blockchain/gamified-eco-engagement.webp",
  },
};

export const EconomicBenefitsDetails = {
  "Job Creation": {
    title: "Job Creation",
    description: `
      Drive employment opportunities in various sectors enabled by Zero Blockchain's ecosystem.
      
      **Key Features:**
      **Blockchain Development Jobs:** Demand for developers skilled in blockchain and smart contracts.
      **Conservation Management Roles:** Employment in NGO partnerships and environmental project oversight.
      **DeFi and Financial Services Careers:** Opportunities in decentralised finance operations and asset tokenisation.
    `,
    image: "/assets/images/zero-blockchain/job-creation.webp",
  },
  "Inclusive Financial Access": {
    title: "Inclusive Financial Access",
    description: `
      Empower underbanked populations with decentralised access to essential financial services.
      
      **Key Features:**
      **Decentralised Banking:** Offer banking services without reliance on traditional intermediaries.
      **Microfinance Opportunities:** Enable small-scale loans and savings for underserved communities.
      **Tokenised Investments:** Provide access to fractional ownership in high-value assets.
    `,
    image: "/assets/images/zero-blockchain/inclusive-financial-access.webp",
  },
  "Global Investment Opportunities": {
    title: "Global Investment Opportunities",
    description: `
      Unlock access to international markets through tokenisation and cross-border financial solutions.
      
      **Key Features:**
      **International Asset Tokenisation:** Invest in tokenised assets from any region.
      **Cross-Border Payments:** Seamless and cost-effective global financial transactions.
      **Equal Participation:** Enable investors from diverse backgrounds to join global markets.
    `,
    image: "/assets/images/zero-blockchain/global-investment-opportunities.webp",
  },
  "Increased Liquidity": {
    title: "Increased Liquidity",
    description: `
      Enhance liquidity for illiquid assets through blockchain-based tokenisation.
      
      **Key Features:**
      **Fractional Ownership:** Tokenise real estate, commodities, and collectibles for trade.
      **Secondary Markets:** Facilitate trading of tokenised assets on decentralised exchanges.
      **Liquidity Pools:** Boost asset availability in DeFi ecosystems.
    `,
    image: "/assets/images/zero-blockchain/increased-liquidity.webp",
  },
  "Revenue Generation for NGOs": {
    title: "Revenue Generation for NGOs",
    description: `
      Provide NGOs with innovative fundraising mechanisms via blockchain and Zero-Tokens.
      
      **Key Features:**
      **Conservation Tokens:** Generate revenue by selling tokens linked to environmental causes.
      **Direct Contributions:** Allocate token proceeds to NGO-led projects transparently.
      **Partnership Models:** Collaborate with NGOs to create sustained funding channels.
    `,
    image: "/assets/images/zero-blockchain/revenue-generation-ngos.webp",
  },
  "Cost Savings in Transactions": {
    title: "Cost Savings in Transactions",
    description: `
      Reduce costs associated with financial transactions using blockchain technology.
      
      **Key Features:**
      **Low-Cost Cross-Border Transfers:** Eliminate high fees from traditional payment systems like SWIFT.
      **Automated Settlement:** Leverage smart contracts to cut administrative costs.
      **Transparent Fee Structure:** Ensure predictable and reduced costs for users.
    `,
    image: "/assets/images/zero-blockchain/cost-savings-transactions.webp",
  },
};

export const EnvironmentalSustainabilityBenefitsDetails = {
  "Carbon Credit Tokenisation": {
    title: "Carbon Credit Tokenisation",
    description: `
      Leverage blockchain technology to tokenise carbon credits, fostering transparent and efficient carbon trading markets.
      
      **Key Features:**
      **Incentivised Eco-Friendly Practices:** Encourage industries to adopt sustainable methods.
      **Transparent Transactions:** Ensure traceability and authenticity in carbon credit trading.
      **Market Accessibility:** Open carbon credit markets to small and medium enterprises.
    `,
    image: "/assets/images/zero-blockchain/carbon-credit-tokenisation.webp",
  },
  "Deforestation Mitigation": {
    title: "Deforestation Mitigation",
    description: `
      Support reforestation and anti-deforestation projects with blockchain-backed funding and gamified challenges.
      
      **Key Features:**
      **Tokenised Funding:** Allocate token sales directly to reforestation projects.
      **Gamification:** Reward users for participating in conservation efforts.
      **Global Reach:** Fund initiatives in regions most affected by deforestation.
    `,
    image: "/assets/images/zero-blockchain/deforestation-mitigation.webp",
  },
  "Biodiversity Preservation": {
    title: "Biodiversity Preservation",
    description: `
      Aid conservation of endangered species through Zero-Tokens linked to biodiversity-focused projects.
      
      **Key Features:**
      **Species-Specific Tokens:** Highlight individual conservation needs for each species.
      **Direct Contributions:** Channel token proceeds to biodiversity programs.
      **Awareness Campaigns:** Increase public understanding of biodiversity challenges.
    `,
    image: "/assets/images/zero-blockchain/biodiversity-preservation.webp",
  },
  "Reduced Environmental Impact of Banking": {
    title: "Reduced Environmental Impact of Banking",
    description: `
      Transform traditional banking by introducing decentralised, paperless digital financial systems.
      
      **Key Features:**
      **Energy Efficiency:** Reduce reliance on physical banking infrastructure.
      **Paperless Transactions:** Eliminate the need for paper-based banking documentation.
      **Decentralised Networks:** Lower the environmental footprint of financial operations.
    `,
    image: "/assets/images/zero-blockchain/reduced-banking-impact.webp",
  },
  "Blockchain for Renewable Energy": {
    title: "Blockchain for Renewable Energy",
    description: `
      Promote clean energy investments by tokenising renewable energy credits for trading and transparency.
      
      **Key Features:**
      **Investment Incentives:** Attract capital to renewable energy projects.
      **Market Accessibility:** Democratise access to clean energy credit trading.
      **Energy Accountability:** Provide clear records of energy source origins.
    `,
    image: "/assets/images/zero-blockchain/renewable-energy-tokenisation.webp",
  },
  "Water Resource Management": {
    title: "Water Resource Management",
    description: `
      Enhance monitoring and funding for water conservation projects using blockchain's transparency and accountability.
      
      **Key Features:**
      **Transparent Funding:** Ensure proper allocation of resources to water projects.
      **Real-Time Monitoring:** Track water usage and conservation efforts efficiently.
      **Global Collaboration:** Connect stakeholders in water resource management through decentralised networks.
    `,
    image: "/assets/images/zero-blockchain/water-resource-management.webp",
  },
};

export const RoadmapPhasesDetails = {
  "Phase 1 (2024)": {
    title: "Phase 1 (2024)",
    description: `
      **Key Objectives:**
      Deploy foundational smart contracts to initiate blockchain operations.
      Launch Zero-Tokens to support conservation-focused fundraising campaigns.
      Establish the Zero Blockchain governance framework to empower early community engagement.
      Begin collaborations with NGOs to fund and execute initial conservation projects.

      **Impact:**
      Sets the groundwork for Zero Blockchain’s ecosystem, creating the foundation for tokenisation and governance.
      Enables immediate environmental impact through fundraising and NGO partnerships.
    `,
    image: "/assets/images/zero-blockchain/phase1-2024.webp",
  },
  "Phase 2 (2025)": {
    title: "Phase 2 (2025)",
    description: `
      **Key Objectives:**
      Introduce DeFi functionalities, including staking, lending, and liquidity pools.
      Expand Zero-Bank operations globally, providing decentralised financial solutions.
      Integrate compliance tools for regulatory adherence, such as automated KYC and AML protocols.

      **Impact:**
      Diversifies revenue streams through DeFi products.
      Extends the reach of Zero-Bank, ensuring global accessibility.
      Strengthens user trust with robust compliance systems.
    `,
    image: "/assets/images/zero-blockchain/phase2-2025.webp",
  },
  "Phase 3 (2026)": {
    title: "Phase 3 (2026)",
    description: `
      **Key Objectives:**
      Launch NFT marketplace integrations to support conservation-themed tokenisation.
      Enable tokenisation for high-impact assets like carbon credits and real estate.
      Introduce gamified conservation tools to drive user engagement in ecological efforts.

      **Impact:**
      Enhances platform utility by tapping into the NFT and asset tokenisation markets.
      Drives ecological change through innovative gamification strategies.
      Establishes Zero Blockchain as a leader in conservation-driven blockchain initiatives.
    `,
    image: "/assets/images/zero-blockchain/phase3-2026.webp",
  },
  "Phase 4 (2027)": {
    title: "Phase 4 (2027)",
    description: `
      **Key Objectives:**
      Enable cross-chain bridges to connect with emerging blockchain networks.
      Implement Layer-2 solutions for faster, cost-efficient transactions.
      Advance DAO governance with decentralised decision-making tools.
      Reward users with community-driven conservation incentives.

      **Impact:**
      Improves scalability and accessibility across blockchains.
      Empowers the community with robust governance tools.
      Enhances user participation through conservation-focused rewards.
    `,
    image: "/assets/images/zero-blockchain/phase4-2027.webp",
  },
  "Phase 5 (2028)": {
    title: "Phase 5 (2028)",
    description: `
      **Key Objectives:**
      Expand tokenisation capabilities to include art, intellectual property, and new asset classes.
      Build strategic partnerships with global NGOs to amplify conservation efforts.
      Introduce tailored financial solutions for niche markets, such as creative industries.

      **Impact:**
      Broadens the appeal of Zero Blockchain by diversifying its tokenised offerings.
      Strengthens partnerships to create a global conservation impact.
      Provides new revenue streams through innovative asset tokenisation.
    `,
    image: "/assets/images/zero-blockchain/phase5-2028.webp",
  },
  "Phase 6 (2029)": {
    title: "Phase 6 (2029)",
    description: `
      **Key Objectives:**
      Introduce blockchain compatibility with quantum computing to future-proof the ecosystem.
      Launch real-time ESG impact analytics for tokenised projects and conservation metrics.
      Transition to enhanced decentralised governance with community-driven policies.

      **Impact:**
      Positions Zero Blockchain as a pioneer in quantum-ready blockchain technology.
      Strengthens ESG reporting, making the platform highly attractive to institutional investors.
      Empowers users with a fully decentralised and transparent governance model.
    `,
    image: "/assets/images/zero-blockchain/phase6-2029.webp",
  },
};


export const UserAdoptionPhasesDetails = {
  "Phase 1: Community Building": {
    title: "Phase 1: Community Building",
    description: `
      Lay the foundation for Zero Blockchain's user base by fostering an informed and engaged community.
      
      **Key Features:**
      **Educational Campaigns:** Offer detailed guides, tutorials, and infographics on blockchain technology and Zero Blockchain's ecosystem.
      **Targeted Outreach:** Leverage social media, newsletters, and digital marketing to attract users.
      **Webinars and AMAs:** Host live sessions to address user questions and showcase platform features.
    `,
    image: "/assets/images/zero-blockchain/user-adoption-phase1.webp",
  },
  "Phase 2: Early Adopter Incentives": {
    title: "Phase 2: Early Adopter Incentives",
    description: `
      Encourage early adoption through attractive rewards and exclusive benefits.
      
      **Key Features:**
      **Staking Bonuses:** Provide additional staking rewards for early participants.
      **Exclusive NFT Drops:** Reward early adopters with limited-edition conservation-themed NFTs.
      **Access to Beta Features:** Grant users early access to new platform functionalities.
    `,
    image: "/assets/images/zero-blockchain/user-adoption-phase2.webp",
  },
  "Phase 3: Referral Program": {
    title: "Phase 3: Referral Program",
    description: `
      Boost community growth with a robust referral and loyalty program.
      
      **Key Features:**
      **Referral Bonuses:** Reward users for bringing new members to the platform.
      **Loyalty Rewards:** Offer tokens or exclusive perks to long-term users.
      **Community Engagement Metrics:** Monitor and gamify referral activities to incentivise active participation.
    `,
    image: "/assets/images/zero-blockchain/user-adoption-phase3.webp",
  },
  "Phase 4: Gamified Campaigns": {
    title: "Phase 4: Gamified Campaigns",
    description: `
      Enhance user engagement through gamification and conservation-based challenges.
      
      **Key Features:**
      **Interactive Challenges:** Encourage users to complete tokenised conservation tasks for rewards.
      **Leaderboard Rankings:** Track user achievements and showcase top contributors.
      **Reward Tiers:** Introduce tiered rewards based on user activity and participation.
    `,
    image: "/assets/images/zero-blockchain/user-adoption-phase4.webp",
  },
  "Phase 5: Partner Integrations": {
    title: "Phase 5: Partner Integrations",
    description: `
      Drive adoption by collaborating with partner platforms and organisations.
      
      **Key Features:**
      **Exclusive Benefits:** Offer discounts or early access through partnered platforms.
      **Cross-Promotions:** Engage in joint marketing campaigns with ecosystem partners.
      **Shared Rewards:** Introduce co-branded initiatives to expand user reach.
    `,
    image: "/assets/images/zero-blockchain/user-adoption-phase5.webp",
  },
  "Phase 6: Global User Expansion": {
    title: "Phase 6: Global User Expansion",
    description: `
      Scale the platform's reach to a global audience through localisation and targeted campaigns.
      
      **Key Features:**
      **Regional Campaigns:** Tailor marketing efforts to specific geographic areas.
      **Language Localisation:** Translate the platform and resources into multiple languages.
      **User Support:** Establish local support teams for better user experience in key regions.
    `,
    image: "/assets/images/zero-blockchain/user-adoption-phase6.webp",
  },
};

export const DeveloperEcosystemPhasesDetails = {
  "Phase 1: SDK Launch": {
    title: "Phase 1: SDK Launch",
    description: `
      Establish the foundation for developer engagement with the introduction of a comprehensive SDK.
      
      **Key Features:**
      **Comprehensive Documentation:** Detailed guides and tutorials for integrating Zero Blockchain's functionalities.
      **Plug-and-Play Solutions:** Pre-configured modules for tokenisation, dApp development, and conservation projects.
      **Open-Source Access:** Provide developers with open-source SDKs for faster development cycles.
    `,
    image: "/assets/images/zero-blockchain/developer-phase1.webp",
  },
  "Phase 2: Hackathons": {
    title: "Phase 2: Hackathons",
    description: `
      Encourage innovation by hosting global hackathons for developers to create groundbreaking projects on Zero Blockchain.
      
      **Key Features:**
      **Global Reach:** Host hackathons in key regions to attract diverse talent.
      **Thematic Challenges:** Focus on areas like conservation, DeFi, and tokenisation.
      **Prizes and Rewards:** Offer Zero-Coin grants and Zero-Token NFTs as incentives.
    `,
    image: "/assets/images/zero-blockchain/developer-phase2.webp",
  },
  "Phase 3: Incentivised Development": {
    title: "Phase 3: Incentivised Development",
    description: `
      Reward developers who contribute to the ecosystem by creating tools, integrations, or dApps.
      
      **Key Features:**
      **Grant Programs:** Allocate funds for high-potential projects.
      **Developer Bounties:** Offer specific tasks with defined rewards to solve ecosystem challenges.
      **Ecosystem Expansion Incentives:** Reward integrations with existing platforms and networks.
    `,
    image: "/assets/images/zero-blockchain/developer-phase3.webp",
  },
  "Phase 4: Developer Tools Expansion": {
    title: "Phase 4: Developer Tools Expansion",
    description: `
      Expand the toolkit available to developers for seamless integration and enhanced user experiences.
      
      **Key Features:**
      **Advanced APIs:** Provide robust APIs for tokenisation, asset tracking, and governance.
      **Support Libraries:** Include pre-built libraries for multiple programming languages.
      **Testing Environments:** Offer sandbox and testnet environments for secure development.
    `,
    image: "/assets/images/zero-blockchain/developer-phase4.webp",
  },
  "Phase 5: Developer Community Building": {
    title: "Phase 5: Developer Community Building",
    description: `
      Create a thriving global developer community to support knowledge sharing and innovation.
      
      **Key Features:**
      **Forums and Discussion Boards:** Foster collaboration among developers.
      **Mentorship Programs:** Pair new developers with experienced blockchain experts.
      **Regular Updates:** Share news, tutorials, and success stories to keep developers engaged.
    `,
    image: "/assets/images/zero-blockchain/developer-phase5.webp",
  },
  "Phase 6: Developer Certification": {
    title: "Phase 6: Developer Certification",
    description: `
      Introduce a certification program to validate and enhance developer expertise with Zero Blockchain's tools.
      
      **Key Features:**
      **Certification Tiers:** Offer beginner, intermediate, and advanced certifications.
      **Hands-On Projects:** Assess developers through real-world tasks and challenges.
      **Recognised Accreditation:** Provide digital badges for certified developers to showcase their skills.
    `,
    image: "/assets/images/zero-blockchain/developer-phase6.webp",
  },
};

export const InstitutionalPartnershipPhasesDetails = {
  "Phase 1: NGO Collaborations": {
    title: "Phase 1: NGO Collaborations",
    description: `
      Establish partnerships with conservation-focused NGOs to initiate impactful tokenised projects.
      
      **Key Features:**
      **Tokenised Conservation Projects:** Launch Zero-Tokens dedicated to specific environmental campaigns.
      **Direct Funding Channels:** Ensure transparent allocation of funds to conservation efforts.
      **Awareness Campaigns:** Collaborate on educational initiatives highlighting blockchain's role in sustainability.
    `,
    image: "/assets/images/zero-blockchain/institutional-phase1.webp",
  },
  "Phase 2: Financial Institution Pilots": {
    title: "Phase 2: Financial Institution Pilots",
    description: `
      Engage with banks and financial institutions to test tokenisation solutions tailored to their needs.
      
      **Key Features:**
      **Pilot Programs:** Run tokenisation pilots for assets like real estate and securities.
      **Customised Solutions:** Develop bespoke tools to integrate blockchain into existing workflows.
      **Feedback Loops:** Gather insights to refine Zero Blockchain offerings for institutional adoption.
    `,
    image: "/assets/images/zero-blockchain/institutional-phase2.webp",
  },
  "Phase 3: Regulator Partnerships": {
    title: "Phase 3: Regulator Partnerships",
    description: `
      Collaborate with regulators to ensure compliance and build trust within the blockchain ecosystem.
      
      **Key Features:**
      **Compliance Frameworks:** Develop guidelines that adhere to global standards like GDPR, KYC, and AML.
      **Sandbox Testing:** Work within regulatory sandboxes to pilot new features.
      **Policy Influence:** Advocate for blockchain-friendly regulations through joint initiatives.
    `,
    image: "/assets/images/zero-blockchain/institutional-phase3.webp",
  },
  "Phase 4: Public-Private Partnerships": {
    title: "Phase 4: Public-Private Partnerships",
    description: `
      Partner with governments and enterprises to explore tokenisation use cases in infrastructure and supply chains.
      
      **Key Features:**
      **Supply Chain Tokenisation:** Implement token-based solutions for asset tracking and transparency.
      **Infrastructure Development:** Facilitate funding for public projects using tokenised investment models.
      **Data-Driven Collaboration:** Leverage blockchain analytics to improve decision-making processes.
    `,
    image: "/assets/images/zero-blockchain/institutional-phase4.webp",
  },
  "Phase 5: Corporate Sponsorships": {
    title: "Phase 5: Corporate Sponsorships",
    description: `
      Secure sponsorships from corporations to fund innovation and expand Zero Blockchain's reach.
      
      **Key Features:**
      **Innovation Grants:** Attract funding for research and development of new blockchain solutions.
      **Joint Ventures:** Collaborate on co-branded initiatives for tokenisation and DeFi.
      **Sponsorship Visibility:** Offer branding opportunities tied to conservation and sustainability projects.
    `,
    image: "/assets/images/zero-blockchain/institutional-phase5.webp",
  },
  "Phase 6: Industry Alliances": {
    title: "Phase 6: Industry Alliances",
    description: `
      Build alliances with blockchain consortia and industry groups to achieve shared goals in decentralised finance and tokenisation.
      
      **Key Features:**
      **Knowledge Sharing:** Participate in conferences and forums to promote Zero Blockchain’s vision.
      **Standardisation Efforts:** Collaborate on setting global standards for tokenisation and DeFi.
      **Joint Research Projects:** Work with other blockchain organisations to explore emerging technologies.
    `,
    image: "/assets/images/zero-blockchain/institutional-phase6.webp",
  },
};

export const EnhancedTokenUtilityPhasesDetails = {
  "Phase 1: Expand Staking Options": {
    title: "Phase 1: Expand Staking Options",
    description: `
      Introduce enhanced staking mechanisms to incentivise long-term user engagement and participation within the ecosystem.
      
      **Key Features:**
      **Dynamic Staking Tiers:** Offer tiered rewards based on staking duration and amount.
      **Dual Token Rewards:** Enable staking rewards in both Zero-Coin and Zero-Tokens to maximise benefits.
      **Auto-Staking Options:** Provide seamless staking with automatic re-staking features to simplify user interaction.
    `,
    image: "/assets/images/zero-blockchain/token-staking.webp",
  },
  "Phase 2: Partner Payment Integration": {
    title: "Phase 2: Partner Payment Integration",
    description: `
      Enable Zero-Coin as a payment method across partner platforms and e-commerce marketplaces.
      
      **Key Features:**
      **Global E-Commerce Integration:** Collaborate with online retailers to accept Zero-Coin payments.
      **Subscription Payments:** Facilitate recurring payment options using Zero-Coin for digital subscriptions.
      **Partner Discounts:** Offer exclusive discounts and perks for users paying with Zero-Coin on partnered platforms.
    `,
    image: "/assets/images/zero-blockchain/partner-payment.webp",
  },
  "Phase 3: Token-Backed Loans": {
    title: "Phase 3: Token-Backed Loans",
    description: `
      Launch token-backed loans to provide users with liquidity while maintaining ownership of their staked assets.
      
      **Key Features:**
      **Collateralisation Services:** Allow users to borrow against their Zero-Coin and Zero-Token holdings.
      **Flexible Loan Terms:** Provide variable interest rates and repayment options tailored to user needs.
      **Credit-Based Staking:** Reward users with better terms based on their staking history and ecosystem contribution.
    `,
    image: "/assets/images/zero-blockchain/token-loans.webp",
  },
  "Phase 4: Multi-Chain Governance": {
    title: "Phase 4: Multi-Chain Governance",
    description: `
      Position Zero-Coin as a governance token across multiple blockchain networks to empower decentralised decision-making.
      
      **Key Features:**
      **Cross-Chain Voting:** Enable governance participation across Ethereum, Binance Smart Chain, and other networks.
      **Proposal Transparency:** Record governance proposals and voting outcomes on-chain for immutability.
      **Decentralised Decision-Making:** Empower the community to vote on ecosystem upgrades and partnerships.
    `,
    image: "/assets/images/zero-blockchain/multi-chain-governance.webp",
  },
  "Phase 5: In-App Utility Expansion": {
    title: "Phase 5: In-App Utility Expansion",
    description: `
      Integrate Zero-Coin into a variety of in-app purchases, subscriptions, and ecosystem-exclusive features.
      
      **Key Features:**
      **Microtransactions:** Allow users to make small, seamless payments within the ecosystem using Zero-Coin.
      **Premium Features:** Unlock access to exclusive content, tools, and services with Zero-Coin payments.
      **Gaming and Entertainment:** Enable Zero-Coin use in gamified conservation challenges and NFT collectibles.
    `,
    image: "/assets/images/zero-blockchain/in-app-utility.webp",
  },
  "Phase 6: Ecosystem Rewards": {
    title: "Phase 6: Ecosystem Rewards",
    description: `
      Implement a reward system to incentivise active contributions to the Zero Blockchain ecosystem.
      
      **Key Features:**
      **Developer Rewards:** Offer Zero-Coin incentives for developers creating impactful dApps and integrations.
      **Conservationist Incentives:** Reward users participating in conservation challenges with Zero-Tokens and Zero-Coin.
      **Engagement Bonuses:** Provide dynamic rewards for activities such as voting, staking, and ecosystem referrals.
    `,
    image: "/assets/images/zero-blockchain/ecosystem-rewards.webp",
  },
};


export const ESGAndComplianceLeadershipPhasesDetails = {
  "Phase 1: Conservation Reporting": {
    title: "Phase 1: Conservation Reporting",
    description: `
      **Key Objectives:**
      Publish detailed compliance-ready conservation reports that highlight the environmental impact of all Zero Blockchain initiatives.
      Provide stakeholders with quantifiable metrics to assess the success of conservation efforts.

      **Key Features:**
      Integration with Zero Blockchain's immutable ledger to ensure data integrity in reports.
      Real-time dashboards to track conservation metrics, enabling users to monitor progress.

      **Impact:**
      Builds trust among users and partners by showcasing measurable contributions to environmental sustainability.
      Establishes Zero Blockchain as a leader in conservation-focused reporting.
    `,
    image: "/assets/images/zero-blockchain/conservation-reporting.webp",
  },
  "Phase 2: ESG Scoring Integration": {
    title: "Phase 2: ESG Scoring Integration",
    description: `
      **Key Objectives:**
      Integrate ESG scoring frameworks into tokenised asset offerings to provide transparency.
      Offer users comprehensive insights into the environmental, social, and governance performance of their investments.

      **Key Features:**
      ESG scores displayed on tokenised assets for user evaluation.
      Continuous updates to scoring metrics using blockchain-based data feeds.

      **Impact:**
      Empowers users to make informed investment decisions aligned with their ESG values.
      Enhances the credibility of Zero Blockchain's tokenisation platform.
    `,
    image: "/assets/images/zero-blockchain/esg-scoring.webp",
  },
  "Phase 3: Global ESG Collaborations": {
    title: "Phase 3: Global ESG Collaborations",
    description: `
      **Key Objectives:**
      Partner with leading ESG organisations to establish benchmarks and best practices for blockchain-integrated ESG initiatives.
      Share Zero Blockchain’s technology with global stakeholders to amplify impact.

      **Key Features:**
      Establish collaborative frameworks for ESG compliance and reporting.
      Host industry summits and workshops to promote ESG innovation.

      **Impact:**
      Positions Zero Blockchain as a pioneer in integrating blockchain with ESG standards.
      Strengthens the ecosystem through global partnerships and shared resources.
    `,
    image: "/assets/images/zero-blockchain/global-esg-collaboration.webp",
  },
  "Phase 4: Blockchain-Ledger Auditing": {
    title: "Phase 4: Blockchain-Ledger Auditing",
    description: `
      **Key Objectives:**
      Implement blockchain-based auditing systems to ensure transparency and accountability in ESG initiatives.
      Provide immutable records of compliance and impact for stakeholders.

      **Key Features:**
      Smart contracts to automate ESG compliance checks.
      Immutable ledger entries for traceability and trustworthiness.

      **Impact:**
      Reinforces trust among partners and users by ensuring data accuracy.
      Simplifies regulatory audits and reporting for all stakeholders.
    `,
    image: "/assets/images/zero-blockchain/blockchain-auditing.webp",
  },
  "Phase 5: Carbon Credit Tokenisation": {
    title: "Phase 5: Carbon Credit Tokenisation",
    description: `
      **Key Objectives:**
      Introduce tokenised carbon credits to incentivise eco-friendly practices among individuals and businesses.
      Create a marketplace for users to offset their carbon footprints through blockchain-based transactions.

      **Key Features:**
      Tokenised carbon credits tradeable on decentralised marketplaces.
      Integration with ESG scoring to highlight carbon offset achievements.

      **Impact:**
      Drives adoption of sustainable practices by making carbon offset accessible.
      Creates a new revenue stream for conservation-focused projects.
    `,
    image: "/assets/images/zero-blockchain/carbon-credit-tokenisation.webp",
  },
  "Phase 6: ESG Certification Programmes": {
    title: "Phase 6: ESG Certification Programmes",
    description: `
      **Key Objectives:**
      Develop certification programmes for tokenised projects, recognising contributions to sustainability.
      Reward businesses and individuals with blockchain-verified badges for their ESG efforts.

      **Key Features:**
      Blockchain-verified certification for ESG-compliant projects.
      Gamification elements to encourage participation in sustainability initiatives.

      **Impact:**
      Encourages widespread participation in ESG activities through recognition and rewards.
      Elevates Zero Blockchain's status as a leader in sustainability certification.
    `,
    image: "/assets/images/zero-blockchain/esg-certification.webp",
  },
};


export const PublicAndDAOGovernancePhasesDetails = {
  "Phase 1: Governance Frameworks": {
    title: "Phase 1: Governance Frameworks",
    description: `
      Launch comprehensive governance frameworks to enable Zero-Coin holders to actively participate in shaping the ecosystem.

      **Key Features:**
      **Voting Rights:** Empower token holders to vote on ecosystem decisions, including upgrades and new features.
      **Transparent Processes:** Ensure all governance activities are visible and recorded on the blockchain.
      **Proposal Reviews:** Introduce a structured process for submitting, debating, and reviewing proposals.
    `,
    image: "/assets/images/zero-blockchain/governance-framework.webp",
  },
  "Phase 2: DAO Voting": {
    title: "Phase 2: DAO Voting",
    description: `
      Implement decentralised voting mechanisms, allowing the community to have a direct say in key protocol decisions.

      **Key Features:**
      **Smart Contract Automation:** Automate voting and decision-making using secure smart contracts.
      **Treasury Allocation:** Allow users to vote on how ecosystem funds are distributed.
      **Inclusive Participation:** Ensure voting is accessible to all token holders, regardless of stake size.
    `,
    image: "/assets/images/zero-blockchain/dao-voting.webp",
  },
  "Phase 3: Public Proposal System": {
    title: "Phase 3: Public Proposal System",
    description: `
      Introduce a public proposal system to encourage community-driven ideas and ensure that user needs shape the ecosystem’s growth.

      **Key Features:**
      **Proposal Submission Portal:** Provide a user-friendly platform for submitting and tracking proposals.
      **Community Collaboration:** Allow discussions and refinements of proposals before voting.
      **Priority-Based Voting:** Rank proposals based on their impact and urgency for ecosystem growth.
    `,
    image: "/assets/images/zero-blockchain/public-proposals.webp",
  },
  "Phase 4: Fully Decentralised Governance": {
    title: "Phase 4: Fully Decentralised Governance",
    description: `
      Transition the Zero Blockchain ecosystem to a fully decentralised governance structure, empowering the community with complete control.

      **Key Features:**
      **Autonomous Ecosystem Management:** Allow the community to handle all critical decisions without centralised oversight.
      **Immutable Decision Records:** Ensure all decisions are permanently recorded on the blockchain.
      **Equitable Participation:** Create an inclusive environment where every token holder has an equal voice.
    `,
    image: "/assets/images/zero-blockchain/decentralised-governance.webp",
  },
  "Phase 5: Token-Based Voting Incentives": {
    title: "Phase 5: Token-Based Voting Incentives",
    description: `
      Introduce token-based incentives to reward active participation in governance and encourage consistent community engagement.

      **Key Features:**
      **Voting Rewards:** Provide Zero-Tokens or staking bonuses for participating in votes.
      **Activity Metrics:** Track and reward users based on their governance activity levels.
      **Incentive Tiers:** Offer tiered rewards to highly engaged participants, fostering a vibrant governance ecosystem.
    `,
    image: "/assets/images/zero-blockchain/voting-incentives.webp",
  },
  "Phase 6: Cross-Chain Governance Integration": {
    title: "Phase 6: Cross-Chain Governance Integration",
    description: `
      Expand governance functionalities to support multi-chain ecosystems, enabling participation across different blockchain networks.

      **Key Features:**
      **Interoperable Voting:** Allow token holders on multiple chains to participate in unified governance processes.
      **Unified Governance Protocol:** Integrate governance protocols across Ethereum, Binance Smart Chain, and other supported networks.
      **Seamless Decision Execution:** Execute cross-chain governance decisions through interconnected smart contracts.
    `,
    image: "/assets/images/zero-blockchain/cross-chain-governance.webp",
  },
};


export const FutureEnhancementsDetails = {
  "ZeroDAO Governance Framework": {
    title: "ZeroDAO Governance Framework",
    description: `
      Introduce a decentralised governance framework, enabling Zero-Coin holders to directly influence key decisions within the ecosystem.

      **Key Features:**
      **Community Proposals:** Allow users to propose and vote on ecosystem upgrades and initiatives.
      **Treasury Management:** Facilitate community-driven decisions on treasury allocations for development and conservation.
      **Decentralised Voting Mechanism:** Implement blockchain-powered voting to ensure transparency and fairness.
    `,
    image: "/assets/images/zero-blockchain/dao-framework.webp",
  },
  "Quantum Computing Integration": {
    title: "Quantum Computing Integration",
    description: `
      Prepare Zero Blockchain for the future by ensuring compatibility with quantum computing advancements.

      **Key Features:**
      **Quantum-Resilient Encryption:** Upgrade cryptographic algorithms to withstand quantum attacks.
      **Enhanced Computational Efficiency:** Leverage quantum capabilities for faster data processing and optimisation.
      **Forward Compatibility:** Build frameworks adaptable to future computational paradigms.
    `,
    image: "/assets/images/zero-blockchain/quantum-computing.webp",
  },
  "Expanded Conservation Initiatives": {
    title: "Expanded Conservation Initiatives",
    description: `
      Launch new initiatives to amplify Zero Blockchain's ecological impact through gamified and tokenised efforts.

      **Key Features:**
      **New Conservation Tokens:** Introduce tokens tied to additional endangered species and environmental causes.
      **Global Challenges:** Engage users with worldwide reforestation and biodiversity restoration campaigns.
      **NGO Collaborations:** Expand partnerships with environmental organisations to amplify outreach and impact.
    `,
    image: "/assets/images/zero-blockchain/conservation-expansion.webp",
  },
  "Educational Outreach and Certifications": {
    title: "Educational Outreach and Certifications",
    description: `
      Develop resources and programs to educate businesses and developers on blockchain adoption and conservation technologies.

      **Key Features:**
      **Workshops and Seminars:** Host events to train participants in tokenisation and blockchain use cases.
      **Certification Programs:** Provide credentials for expertise in Zero Blockchain tools and applications.
      **Learning Resources:** Create guides, video tutorials, and documentation to support ecosystem adoption.
    `,
    image: "/assets/images/zero-blockchain/education-certifications.webp",
  },
  "Carbon Credit Tokenisation": {
    title: "Carbon Credit Tokenisation",
    description: `
      Support sustainability by enabling the tokenisation of carbon credits for transparent trading and investment.

      **Key Features:**
      **Tokenised Carbon Assets:** Create blockchain-based tokens representing carbon offsets.
      **Transparent Marketplaces:** Facilitate real-time trading of carbon credits on decentralised platforms.
      **Incentive Programs:** Reward eco-friendly practices with tokenised credits to drive adoption.
    `,
    image: "/assets/images/zero-blockchain/carbon-credits.webp",
  },
  "Cross-Chain Expansion": {
    title: "Cross-Chain Expansion",
    description: `
      Enhance interoperability by integrating Zero Blockchain with emerging blockchain networks for increased utility.

      **Key Features:**
      **Multi-Chain Compatibility:** Support seamless interactions with new blockchain protocols and ecosystems.
      **Interoperable dApps:** Enable decentralised applications to operate across multiple chains effortlessly.
      **Global Accessibility:** Broaden user reach by ensuring the ecosystem is accessible on diverse networks.
    `,
    image: "/assets/images/zero-blockchain/cross-chain-expansion.webp",
  },
};
