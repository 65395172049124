import React, { useEffect, useState, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Footer from "./Footer";
import AnimatedTitle from "./AnimatedTitle";
import { TiArrowDown, TiArrowUp } from "react-icons/ti";
import BookAConsultationButton from "../components/BookAConsultationButton";
import BookAConsultationCustomModal from "../components/BookAConsultationCustomModal";

gsap.registerPlugin(ScrollTrigger);

const Services = () => {
  const [scrollDownClicked, setScrollDownClicked] = useState(false);
  const [isBottom, setIsBottom] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal

  const sectionRefs = useRef([]);
  const scrollDownRef = useRef(null);
  const scrollTopRef = useRef(null);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    // GSAP Animations for sections
    sectionRefs.current.forEach((section, index) => {
      if (section) {
        gsap.fromTo(
          section,
          { opacity: 0, y: 50 },
          {
            opacity: 1,
            y: 0,
            duration: 1,
            delay: index * 0.2,
            scrollTrigger: {
              trigger: section,
              start: "top 80%",
            },
          }
        );
      }
    });

    // GSAP Animation for Scroll Down Button
    if (scrollDownRef.current) {
      gsap.fromTo(
        scrollDownRef.current,
        { opacity: 0, y: -20 },
        { opacity: 1, y: 0, duration: 1 }
      );
    }
  }, []);

  const checkIfBottom = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 200) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  };

  const scrollToContent = () => {
    const content = document.getElementById("content");
    if (content) {
      const contentOffsetTop = content.offsetTop;
      window.scrollTo({
        top: contentOffsetTop - 100,
        behavior: "smooth",
      });
    }
    setScrollDownClicked(true);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setScrollDownClicked(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", checkIfBottom);
    return () => {
      window.removeEventListener("scroll", checkIfBottom);
    };
  }, []);

  // UPDATED services array with additional properties
  const services = [
    {
      title: "Digital Wallets",
      description:
        "Securely manage and store your digital assets with cutting-edge encryption technology.",
      icon: "/assets/images/services/digital-wallets.webp",
      features: [
        "Multi-signature security to prevent unauthorised access",
        "Automatic encrypted backups for peace of mind",
        "Compatibility with popular blockchains (Ethereum, Bitcoin, etc.)",
      ],
      useCases: [
        "Storing personal cryptocurrencies safely",
        "Facilitating easy wallet creation for new blockchain users",
      ],
    },
    {
      title: "Asset Tokenisation",
      description:
        "Convert physical and digital assets into blockchain tokens for seamless trading.",
      icon: "/assets/images/services/asset-tokenisation.webp",
      features: [
        "Improves liquidity by enabling fractional ownership",
        "Built-in compliance checks for regulated markets",
      ],
      useCases: [
        "Tokenising real estate properties",
        "Digitising ownership of high-value artwork",
      ],
    },
    {
      title: "Smart Contracts",
      description:
        "Automate processes and ensure compliance with transparent and secure smart contracts.",
      icon: "/assets/images/services/smart-contracts.webp",
      features: [
        "Eliminate intermediaries through automated execution",
        "Enhance trust via immutable, transparent agreements",
      ],
      useCases: [
        "Automating supply chain tracking",
        "Streamlining B2B financial transactions",
      ],
    },
    {
      title: "Decentralised Lending",
      description:
        "Access lending platforms with competitive rates and no intermediaries.",
      icon: "/assets/images/services/defi-lending.webp",
      features: [
        "Lower fees due to removal of centralised intermediaries",
        "Instant access to global lending pools",
      ],
      useCases: [
        "Earning interest on idle crypto holdings",
        "Securing quick loans without exhaustive paperwork",
      ],
    },
    {
      title: "Compliance Solutions",
      description:
        "Ensure regulatory adherence with integrated AML/KYC compliance tools.",
      icon: "/assets/images/services/compliance.webp",
      features: [
        "Automated identity verification",
        "Seamless integration with existing legal frameworks",
      ],
      useCases: [
        "Onboarding customers in crypto exchanges",
        "Managing compliance for token sales (ICOs/IEOs)",
      ],
    },
    {
      title: "Blockchain Consulting",
      description:
        "Get expert advice on implementing blockchain technology into your business.",
      icon: "/assets/images/services/consulting.webp",
      features: [
        "Customised strategy development for blockchain integration",
        "Comprehensive training for your team",
      ],
      useCases: [
        "Identifying the best blockchain platform for your project",
        "Optimising existing workflows through smart contract automation",
      ],
    },
  ];

  return (
    <div className="w-screen min-h-screen bg-black text-blue-50">
      {/* Hero Section */}
      <div className="relative w-screen h-screen overflow-hidden">
        <video
          src="/videos/services-hero.mp4"
          autoPlay
          loop
          muted
          className="absolute left-0 top-0 size-full object-cover"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/60">
          <AnimatedTitle
            title="Our Services"
            containerClass="text-center text-blue-50 font-zentry"
          />
        </div>
      </div>

      {/* Scroll Down Button */}
      {!scrollDownClicked && (
        <div
          ref={scrollDownRef}
          className="fixed bottom-10 left-1/2 transform -translate-x-1/2 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
          onClick={scrollToContent}
        >
          <TiArrowDown size={24} className="text-white/40 hover:text-white" />
          <span className="text-white/40 hover:text-white">SCROLL DOWN</span>
        </div>
      )}

      {/* Services Section */}
      <div id="content" className="px-10 py-20 mx-auto max-w-7xl">
        <h2 className="text-3xl font-bold text-center mb-10">What We Offer</h2>
        <p className="text-center mb-16">
          Explore the range of services designed to empower your blockchain journey.
        </p>
        <div className="grid gap-10 md:grid-cols-2 lg:grid-cols-3">
          {services.map((service, index) => (
            <div
              key={index}
              className="dashboard-section p-6 bg-black/70 rounded-lg shadow-lg text-center"
              ref={(el) => (sectionRefs.current[index] = el)}
            >
              <img
                src={service.icon}
                alt={service.title}
                className="w-74 h-36 mx-auto mb-4"
              />
              <h3 className="text-xl font-bold">{service.title}</h3>
              <p className="mt-4">{service.description}</p>
              {/* Render features and use cases */}
            </div>
          ))}
        </div>
      </div>

      {/* Simple CTA Section */}
      <div className="bg-black/80 py-16 px-10 text-center">
        <h2 className="text-3xl font-bold mb-6">Ready to Transform Your Business?</h2>
        <p className="mb-8 max-w-2xl mx-auto">
          Discover how our expert blockchain services can help you automate processes,
          reduce costs, and unlock new opportunities. Schedule a free consultation today.
        </p>

        <div className="flex justify-center mt-10">
          <BookAConsultationButton onOpenModal={handleOpenModal} />
        </div>
      </div>

      {/* Modal */}
      <BookAConsultationCustomModal isOpen={isModalOpen} onClose={handleCloseModal} />

      {/* Back to Top Button */}
      {isBottom && (
        <div
          ref={scrollTopRef}
          className="fixed bottom-10 left-5 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
          onClick={scrollToTop}
        >
          <TiArrowUp
            size={24}
            className="text-white/40 hover:text-white transition-transform duration-300"
          />
          <span className="text-white/40 hover:text-white">BACK TO TOP</span>
        </div>
      )}

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Services;