export const CoreFeatures = [
  {
      title: "Automated Market Maker (AMM)",
      description: 
          "Facilitates decentralised token swaps without intermediaries, ensuring efficient and transparent trading.",
      image: "/assets/images/zero-dex/automated-market-maker.webp",
  },
  {
      title: "Cross-Chain Compatibility",
      description: 
          "Enables seamless trading across multiple blockchain ecosystems, unlocking broader trading opportunities.",
      image: "/assets/images/zero-dex/cross-chain-compatibility.webp",
  },
  {
      title: "Yield Farming",
      description: 
          "Stake tokens in liquidity pools to earn rewards, providing a passive income opportunity for users.",
      image: "/assets/images/zero-dex/yield-farming.webp",
  },
  {
      title: "Blockchain Security",
      description: 
          "Ensures transaction immutability and transparency through decentralised blockchain technology.",
      image: "/assets/images/zero-dex/blockchain-security.webp",
  },
  {
      title: "Real-Time Trading",
      description: 
          "Delivers lightning-fast transaction execution, minimising delays and slippage for an optimised trading experience.",
      image: "/assets/images/zero-dex/real-time-trading.webp",
  },
  {
      title: "Advanced Analytics",
      description: 
          "Provides actionable insights into market trends, trading patterns, and liquidity performance to enhance decision-making.",
      image: "/assets/images/zero-dex/advanced-analytics.webp",
  },
  {
      title: "Decentralised Governance",
      description: 
          "Empowers users to participate in platform decision-making by voting on proposals and changes.",
      image: "/assets/images/zero-dex/decentralised-governance.webp",
  },
  {
      title: "Low Fees",
      description: 
          "Minimises transaction costs for trades and swaps, maximising profitability for users.",
      image: "/assets/images/zero-dex/low-fees.webp",
  },
  {
      title: "Liquidity Pool Management",
      description: 
          "Simplifies the process of adding or removing liquidity, with tools to monitor and optimise pool contributions.",
      image: "/assets/images/zero-dex/liquidity-pool-management.webp",
  },
];


export const TechnicalCapabilities = [
  {
      title: "Microservices-Based Architecture",
      description: 
          "Zero-Dex leverages microservices to ensure scalable, modular components that enhance trading performance and uptime.",
      image: "/assets/images/zero-dex/microservices-architecture.webp",
  },
  {
      title: "Automated Market Maker (AMM) Engine",
      description: 
          "The AMM engine optimises token swaps and ensures efficient pricing with deep liquidity.",
      image: "/assets/images/zero-dex/amm-engine.webp",
  },
  {
      title: "Cross-Chain Compatibility",
      description: 
          "Zero-Dex supports multi-chain transactions, integrating seamlessly with Ethereum, Binance Smart Chain, and other blockchain ecosystems.",
      image: "/assets/images/zero-dex/cross-chain-compatibility.webp",
  },
  {
      title: "Real-Time Trade Matching",
      description: 
          "Facilitates instantaneous trade execution and order matching, ensuring high-speed decentralised transactions.",
      image: "/assets/images/zero-dex/real-time-matching.webp",
  },
  {
      title: "API-First Integration",
      description: 
          "Provides developers with robust APIs to integrate Zero-Dex’s liquidity pools, trading, and analytics into external applications.",
      image: "/assets/images/zero-dex/api-integration.webp",
  },
  {
      title: "Cloud-Native Scalability",
      description: 
          "Zero-Dex operates on cloud platforms such as AWS and GCP, ensuring high availability and disaster recovery.",
      image: "/assets/images/zero-dex/cloud-scalability.webp",
  },
];


export const KeyBenefits = [
  {
      title: "Enhanced Liquidity Accessibility",
      description: 
          "Zero-Dex provides seamless access to deep liquidity pools, ensuring minimal slippage for traders.",
      image: "/assets/images/zero-dex/liquidity-accessibility.webp",
  },
  {
      title: "Decentralised and Transparent Trading",
      description: 
          "Trade with confidence knowing that every transaction is secured and transparent on the blockchain.",
      image: "/assets/images/zero-dex/decentralised-trading.webp",
  },
  {
      title: "Cross-Chain Token Swaps",
      description: 
          "Facilitate token swaps across multiple blockchain ecosystems, enabling broader market access.",
      image: "/assets/images/zero-dex/cross-chain-swaps.webp",
  },
  {
      title: "Lower Trading Fees",
      description: 
          "Enjoy competitive trading fees with no intermediaries, maximising cost efficiency for users.",
      image: "/assets/images/zero-dex/lower-trading-fees.webp",
  },
  {
      title: "User-Controlled Assets",
      description: 
          "Retain complete control of your assets with non-custodial wallets, ensuring true decentralisation.",
      image: "/assets/images/zero-dex/user-controlled-assets.webp",
  },
  {
      title: "Real-Time Trade Execution",
      description: 
          "Benefit from lightning-fast order matching and execution, optimising your trading experience.",
      image: "/assets/images/zero-dex/real-time-execution.webp",
  },
];


export const UseCases = [
  {
      title: "Decentralised Trading",
      description: 
          "Enable secure and transparent peer-to-peer token swaps without relying on centralised exchanges.",
      image: "/assets/images/zero-dex/decentralised-trading.webp",
  },
  {
      title: "Cross-Chain Liquidity Management",
      description: 
          "Facilitate seamless liquidity provision across multiple blockchain ecosystems, enhancing DeFi connectivity.",
      image: "/assets/images/zero-dex/cross-chain-liquidity.webp",
  },
  {
      title: "Yield Farming & Staking",
      description: 
          "Support yield farming and staking opportunities within liquidity pools to maximise user earnings.",
      image: "/assets/images/zero-dex/yield-farming-staking.webp",
  },
  {
      title: "Token Swapping for DeFi Protocols",
      description: 
          "Provide fast and efficient token swaps for users engaging with decentralised finance platforms.",
      image: "/assets/images/zero-dex/token-swapping.webp",
  },
  {
      title: "Institutional Liquidity Providers",
      description: 
          "Attract institutional participants by offering deep liquidity pools with minimised slippage.",
      image: "/assets/images/zero-dex/institutional-liquidity.webp",
  },
  {
      title: "NFT Market Liquidity",
      description: 
          "Integrate with NFT marketplaces to provide liquidity and enable trading of tokenised digital assets.",
      image: "/assets/images/zero-dex/nft-liquidity.webp",
  },
];



export const SecurityAndCompliance = [
  {
      title: "Blockchain-Backed Transactions",
      description: 
          "Ensures transparency, immutability, and security for all trades and liquidity operations on Zero-Dex.",
      image: "/assets/images/zero-dex/blockchain-transactions.webp",
  },
  {
      title: "End-to-End Encryption",
      description: 
          "Safeguards user data by encrypting information both at rest and in transit, ensuring maximum data security.",
      image: "/assets/images/zero-dex/encryption.webp",
  },
  {
      title: "Two-Factor Authentication (2FA)",
      description: 
          "Adds an extra layer of security to user accounts, protecting against unauthorised access.",
      image: "/assets/images/zero-dex/2fa.webp",
  },
  {
      title: "Real-Time Fraud Detection",
      description: 
          "AI-driven algorithms monitor and identify suspicious activities in real-time, mitigating fraud risks.",
      image: "/assets/images/zero-dex/fraud-detection.webp",
  },
  {
      title: "Zero Trust Architecture",
      description: 
          "Implements a verification-first approach, minimising risks associated with insider threats and unauthorised actions.",
      image: "/assets/images/zero-dex/zero-trust.webp",
  },
  {
      title: "Regulatory Compliance",
      description: 
          "Adheres to KYC and AML requirements, ensuring a compliant and secure decentralised trading environment.",
      image: "/assets/images/zero-dex/kyc-aml.webp",
  },
];


export const BusinessBenefits = [
{
  title: "Revenue Growth Through Liquidity Provision",
  description: 
    "Generate revenue by attracting traders and liquidity providers to Zero-Dex’s deep liquidity pools with minimal slippage.",
  image: "/assets/images/zero-dex/liquidity-revenue-growth.webp",
},
{
  title: "Enhanced Market Reach",
  description: 
    "Expand market presence by enabling cross-chain trading and liquidity management across multiple blockchain ecosystems.",
  image: "/assets/images/zero-dex/market-reach.webp",
},
{
  title: "Increased Platform Engagement",
  description: 
    "Boost user retention and trading volume by providing seamless token swaps, staking opportunities, and yield farming incentives.",
  image: "/assets/images/zero-dex/platform-engagement.webp",
},
{
  title: "Operational Efficiency",
  description: 
    "Streamline trading operations with an automated AMM and efficient fee structures, reducing manual intervention.",
  image: "/assets/images/zero-dex/operational-efficiency.webp",
},
{
  title: "Regulatory Compliance Enablement",
  description: 
    "Ensure adherence to global financial regulations with integrated KYC/AML tools and transaction monitoring.",
  image: "/assets/images/zero-dex/compliance-enablement.webp",
},
{
  title: "Enhanced Brand Trust",
  description: 
    "Build credibility among users and institutional clients with robust security, transparency, and reliable cross-chain trading capabilities.",
  image: "/assets/images/zero-dex/brand-trust.webp",
}
];


export const FutureEnhancements = [
{
  title: "Cross-Chain Asset Bridging",
  description: 
    "Expand cross-chain compatibility to support seamless asset transfers across emerging blockchain ecosystems.",
  image: "/assets/images/zero-dex/cross-chain-bridging.webp",
},
{
  title: "Advanced AMM Features",
  description: 
    "Introduce customisable AMM configurations, allowing users to set dynamic fee rates and liquidity weights.",
  image: "/assets/images/zero-dex/advanced-amm-features.webp",
},
{
  title: "NFT Trading Integration",
  description: 
    "Enable direct NFT trading and liquidity for digital assets alongside traditional token swaps.",
  image: "/assets/images/zero-dex/nft-trading.webp",
},
{
  title: "AI-Powered Market Analytics",
  description: 
    "Incorporate AI tools to provide real-time insights and predictive analytics for traders and liquidity providers.",
  image: "/assets/images/zero-dex/ai-market-analytics.webp",
},
{
  title: "Enhanced Governance Mechanisms",
  description: 
    "Introduce advanced DAO governance features to enable community-driven decision-making for platform upgrades.",
  image: "/assets/images/zero-dex/governance-mechanisms.webp",
},
{
  title: "Yield Optimisation Algorithms",
  description: 
    "Leverage AI-driven algorithms to maximise user rewards and liquidity efficiency in staking and farming pools.",
  image: "/assets/images/zero-dex/yield-optimisation.webp",
}
];


