export const CoreFeatureDetails = {
  "Modular Core Banking System": {
    title: "Modular Core Banking System",
    description: `
      Customise the banking system with modular features that can be added, updated, or removed as required to meet business needs.
      
      **Key Features:**
      **Customisable Modules:** Add, update, or remove core banking modules on demand.
      **Adaptable System:** Tailored to fit the unique needs of each financial institution.
      **Faster Upgrades:** Individual modules can be updated without impacting the entire system.
    `,
    image: "/assets/images/zero-core/modular-core.webp"
  },

  "Blockchain-Powered Ledger": {
    title: "Blockchain-Powered Ledger",
    description: `
      A tamper-proof, immutable blockchain ledger that records all banking transactions for maximum transparency and security.
      
      **Key Features:**
      **Immutable Records:** Transactions cannot be altered, ensuring integrity.
      **Tamper-Proof Ledger:** Built-in fraud resistance with blockchain validation.
      **Audit-Ready Logs:** All transactions are accessible for regulatory audits.
    `,
    image: "/assets/images/zero-core/blockchain-ledger.webp"
  },

  "Real-Time Payments": {
    title: "Real-Time Payments",
    description: `
      Enable real-time payments for domestic and cross-border transactions, reducing delays and improving cash flow.
      
      **Key Features:**
      **Instant Settlements:** Payments are settled in real-time, not days.
      **Cross-Border Support:** Supports cross-border payments in multiple currencies.
      **Improved Cash Flow:** Businesses and users get immediate access to funds.
    `,
    image: "/assets/images/zero-core/real-time-payments.webp"
  },

  "KYC/AML Compliance": {
    title: "KYC/AML Compliance",
    description: `
      Built-in KYC and AML compliance support for onboarding customers and adhering to local and international regulations.
      
      **Key Features:**
      **Automated Onboarding:** Streamlined KYC process for faster customer onboarding.
      **AML Monitoring:** Tracks and flags suspicious activity for compliance.
      **Global Compliance:** Adheres to multi-jurisdictional KYC/AML regulations.
    `,
    image: "/assets/images/zero-core/kyc-aml-compliance.webp"
  },

  "Customisable User Experience": {
    title: "Customisable User Experience",
    description: `
      Enable financial institutions to design personalised user interfaces and mobile-optimised customer experiences.
      
      **Key Features:**
      **Custom UI/UX Design:** Personalise the platform's look and feel.
      **Mobile-Optimised:** Create a seamless mobile experience for customers.
      **Adaptive User Journeys:** Customise onboarding and user flows to suit business needs.
    `,
    image: "/assets/images/zero-core/custom-user-experience.webp"
  },

  "Advanced Fraud Detection": {
    title: "Advanced Fraud Detection",
    description: `
      AI-driven fraud detection system to flag suspicious transactions and protect against fraudulent activities.
      
      **Key Features:**
      **AI-Powered Detection:** Identify unusual patterns and suspicious transactions.
      **Proactive Risk Alerts:** Automated alerts for potentially fraudulent activity.
      **Continuous Learning:** AI models evolve with new fraud patterns and behaviours.
    `,
    image: "/assets/images/zero-core/fraud-detection.webp"
  }
};

export const TechnicalCapabilitiesDetails = {
  "Microservices Architecture": {
    title: "Microservices Architecture",
    description: `
      Supports a microservices architecture for scalability, allowing independent development, testing, and deployment of services.
      
      **Key Features:**
      **Independent Services:** Develop, test, and deploy individual services independently.
      **Scalability:** Scale individual services up or down as demand changes.
      **Fault Isolation:** Failures in one service do not affect the entire system.
    `,
    image: "/assets/images/zero-core/microservices-architecture.webp"
  },

  "Cloud-Native Infrastructure": {
    title: "Cloud-Native Infrastructure",
    description: `
      Deploy on AWS, Azure, or Google Cloud to ensure high availability, fault tolerance, and efficient disaster recovery.
      
      **Key Features:**
      **Multi-Cloud Support:** Deploy ZeroCore on AWS, Azure, or Google Cloud.
      **High Availability:** Redundant infrastructure ensures maximum uptime.
      **Disaster Recovery:** Automated backups and failover mechanisms protect against data loss.
    `,
    image: "/assets/images/zero-core/cloud-native-infrastructure.webp"
  },

  "Message Queue Integration": {
    title: "Message Queue Integration",
    description: `
      Leverages RabbitMQ to process real-time notifications, transactional messages, and payment events.
      
      **Key Features:**
      **Real-Time Messaging:** Handle high-frequency payment events with real-time processing.
      **Asynchronous Processing:** Supports non-blocking operations for seamless processing.
      **Queue Management:** Prioritise, retry, and route messages as needed.
    `,
    image: "/assets/images/zero-core/message-queue.webp"
  },

  "Containerisation & Docker": {
    title: "Containerisation & Docker",
    description: `
      Uses Docker to provide isolated, consistent environments for development, testing, and production.
      
      **Key Features:**
      **Containerised Deployments:** Ship ZeroCore as lightweight, portable Docker containers.
      **Environment Consistency:** Ensures identical environments across development, testing, and production.
      **Rapid Deployment:** Spin up or shut down containers within seconds.
    `,
    image: "/assets/images/zero-core/containerisation.webp"
  },

  "Data Encryption & Role-Based Access Control (RBAC)": {
    title: "Data Encryption & Role-Based Access Control (RBAC)",
    description: `
      Implements AES-256 encryption and RBAC to secure sensitive data and control user permissions.
      
      **Key Features:**
      **AES-256 Encryption:** Protect sensitive data in transit and at rest.
      **Role-Based Access Control (RBAC):** Restrict access to sensitive data based on user roles.
      **Access Audits:** Track and log user access for security audits and compliance.
    `,
    image: "/assets/images/zero-core/data-encryption-rbac.webp"
  },

  "Event-Driven Design": {
    title: "Event-Driven Design",
    description: `
      Trigger events in real-time for regulatory reporting, compliance alerts, and transaction updates.
      
      **Key Features:**
      **Real-Time Event Processing:** Trigger actions as events occur in the system.
      **Compliance Alerts:** Automate regulatory reporting and compliance checks.
      **Streamlined Workflows:** Enable workflows to trigger automatically when key events happen.
    `,
    image: "/assets/images/zero-core/event-driven-design.webp"
  }
};

export const KeyBenefitsDetails = {
  "Enhanced Transparency": {
    title: "Enhanced Transparency",
    description: `
      Gain full transparency with a blockchain-based ledger, where all banking transactions are immutable and fully auditable.
      
      **Key Features:**
      **Immutable Ledger:** Blockchain ledger records all transactions, ensuring they cannot be altered or deleted.
      **Full Audit Trail:** Complete, time-stamped history of all transactions for regulatory and internal audits.
      **Increased Trust:** Transparency builds customer and regulator trust through verifiable transaction records.
    `,
    image: "/assets/images/zero-core/enhanced-transparency.webp"
  },

  "Faster Cross-Border Payments": {
    title: "Faster Cross-Border Payments",
    description: `
      Bypass traditional banking networks like SWIFT and enable near-instant cross-border transactions at lower fees.
      
      **Key Features:**
      **Near-Instant Transfers:** Speed up international payments with blockchain technology.
      **Lower Transaction Fees:** Cut down on costly intermediary fees charged by traditional networks like SWIFT.
      **24/7 Availability:** Unlike legacy banking, cross-border payments can be made at any time.
    `,
    image: "/assets/images/zero-core/cross-border-payments.webp"
  },

  "Regulatory Compliance": {
    title: "Regulatory Compliance",
    description: `
      Support global regulatory compliance with tools to manage KYC, AML, and GDPR, ensuring audit-readiness at all times.
      
      **Key Features:**
      **KYC & AML Compliance:** Streamline customer onboarding with automated KYC and AML checks.
      **GDPR Compliance:** Ensure customer data privacy and protection according to GDPR standards.
      **Audit-Ready Reporting:** Generate audit-ready compliance reports for regulators and auditors.
    `,
    image: "/assets/images/zero-core/regulatory-compliance.webp"
  },

  "Cost Efficiency": {
    title: "Cost Efficiency",
    description: `
      Lower operating costs by reducing reliance on legacy systems and central banks while enhancing operational agility.
      
      **Key Features:**
      **Reduced IT Overheads:** Migrate from costly legacy systems to cloud-native, modular infrastructure.
      **Faster Time-to-Market:** Agile system changes reduce development time for new financial products.
      **Operational Cost Savings:** Cut costs associated with traditional intermediaries and banking networks.
    `,
    image: "/assets/images/zero-core/cost-efficiency.webp"
  },

  "Frictionless Customer Experience": {
    title: "Frictionless Customer Experience",
    description: `
      Enable customers to experience faster payments, instant account creation, and seamless cross-border transactions.
      
      **Key Features:**
      **Instant Account Creation:** Reduce onboarding time with fast, frictionless account setup.
      **Fast Payment Processing:** Enable real-time payments for better customer experience.
      **Customised User Experience:** Personalise customer journeys with intuitive, mobile-optimised UIs.
    `,
    image: "/assets/images/zero-core/customer-experience.webp"
  },

  "Reduced Fraud Risk": {
    title: "Reduced Fraud Risk",
    description: `
      Leverage predictive fraud detection to reduce fraudulent activity and improve risk management.
      
      **Key Features:**
      **Predictive Fraud Detection:** Identify and block fraudulent transactions in real time.
      **AI-Powered Insights:** AI algorithms continuously learn and adapt to emerging fraud patterns.
      **Risk Scoring:** Apply risk scores to transactions to trigger further verification where needed.
    `,
    image: "/assets/images/zero-core/fraud-risk-reduction.webp"
  }
};

export const UseCasesDetails = {
  "Challenger Banks": {
    title: "Challenger Banks",
    description: `
      Launch modern challenger banks with a blockchain-powered, modular banking system that scales with business growth.
      
      **Key Features:**
      **Blockchain-Powered Core:** Use a blockchain-backed infrastructure for security and transparency.
      **Scalable Architecture:** Expand features and user capacity as the business grows.
      **Customisable Modules:** Tailor banking functionalities like payments, lending, and user experience.
    `,
    image: "/assets/images/zero-core/challenger-banks.webp"
  },

  "Corporate Banking": {
    title: "Corporate Banking",
    description: `
      Support corporate banking with real-time payments, cash management, and liquidity tools for large enterprises.
      
      **Key Features:**
      **Liquidity Management:** Manage liquidity with tools to optimise cash flow and working capital.
      **Real-Time Payments:** Enable fast payments to support corporate cash management.
      **Custom Corporate Accounts:** Customise banking features and permissions for large corporate clients.
    `,
    image: "/assets/images/zero-core/corporate-banking.webp"
  },

  "Retail Banking": {
    title: "Retail Banking",
    description: `
      Deliver frictionless banking experiences for personal customers, offering real-time payments and instant account creation.
      
      **Key Features:**
      **Personalised Customer Journeys:** Customised experiences for individual users through personal dashboards.
      **Instant Account Opening:** Speed up customer onboarding with fully digital account creation.
      **Real-Time Transactions:** Empower customers to send and receive payments instantly.
    `,
    image: "/assets/images/zero-core/retail-banking.webp"
  },

  "Wealth Management": {
    title: "Wealth Management",
    description: `
      Streamline wealth management operations with support for portfolio tracking, custodial services, and financial planning.
      
      **Key Features:**
      **Portfolio Tracking:** Enable tracking of assets, investments, and financial instruments.
      **Custodial Services:** Provide secure custodial services for high-net-worth clients.
      **Investment Planning:** Offer wealth planning tools for financial advisory and portfolio rebalancing.
    `,
    image: "/assets/images/zero-core/wealth-management.webp"
  },

  "Fintech Integrations": {
    title: "Fintech Integrations",
    description: `
      Serve as the payment backbone for fintech apps, providing payment processing, lending, and embedded finance solutions.
      
      **Key Features:**
      **Embedded Finance:** Integrate banking services into third-party apps and platforms.
      **Payment API Integration:** Seamlessly connect with payment APIs for payment processing and management.
      **Custom Fintech Modules:** Build, modify, and launch fintech products with modular components.
    `,
    image: "/assets/images/zero-core/fintech-integrations.webp"
  },

  "Payment Processors": {
    title: "Payment Processors",
    description: `
      Offer a secure and fast payment gateway for online payment processing and e-commerce platforms.
      
      **Key Features:**
      **Fast Payment Gateway:** Provide merchants with fast, secure payment processing capabilities.
      **Multi-Currency Support:** Support payments in multiple currencies for global e-commerce businesses.
      **Fraud Detection:** Implement fraud detection measures to reduce the risk of fraudulent payments.
    `,
    image: "/assets/images/zero-core/payment-processors.webp"
  }
};

export const SecurityAndComplianceDetails = {
  "KYC/AML Compliance": {
    title: "KYC/AML Compliance",
    description: `
      Ensure compliance with Know Your Customer (KYC) and Anti-Money Laundering (AML) regulations using automated verification tools.
      
      **Key Features:**
      **Automated Onboarding:** Onboard customers faster with real-time KYC/AML verification tools.
      **Identity Verification:** Verify user identities through government-issued ID and biometric checks.
      **Compliance Monitoring:** Continuously monitor customer activities to detect and report suspicious activities.
    `,
    image: "/assets/images/zero-core/kyc-aml-compliance.webp"
  },

  "GDPR Compliance": {
    title: "GDPR Compliance",
    description: `
      Adhere to GDPR data privacy regulations, providing users with data transparency, opt-out rights, and full consent control.
      
      **Key Features:**
      **Data Transparency:** Give users control over their data with detailed privacy policies and opt-out options.
      **Right to be Forgotten:** Users can request the deletion of their data, ensuring GDPR compliance.
      **Data Processing Audits:** Maintain records of data processing activities to demonstrate GDPR compliance.
    `,
    image: "/assets/images/zero-core/gdpr-compliance.webp"
  },

  "Audit Trail": {
    title: "Audit Trail",
    description: `
      Record an immutable, verifiable history of transactions on the blockchain for audit purposes and regulatory review.
      
      **Key Features:**
      **Immutable Records:** Ensure a tamper-proof record of all system activity for full auditability.
      **Traceable Transactions:** Track every user action and transaction for accountability and oversight.
      **Regulatory Compliance:** Satisfy compliance requirements with an auditable transaction history.
    `,
    image: "/assets/images/zero-core/audit-trail.webp"
  },

  "Data Encryption & Access Control": {
    title: "Data Encryption & Access Control",
    description: `
      Protect sensitive data with AES-256 encryption and Role-Based Access Control (RBAC) for user permission management.
      
      **Key Features:**
      **AES-256 Encryption:** Encrypt sensitive data at rest and in transit with industry-standard AES-256 encryption.
      **Role-Based Access Control (RBAC):** Grant permissions based on user roles, ensuring access is limited to authorised users.
      **Data Privacy:** Maintain confidentiality of sensitive data, reducing the risk of data breaches.
    `,
    image: "/assets/images/zero-core/data-encryption-access-control.webp"
  },

  "PCI-DSS Compliance": {
    title: "PCI-DSS Compliance",
    description: `
      Ensure PCI-DSS certification to secure payment card transactions and protect customer financial information.
      
      **Key Features:**
      **PCI-DSS Certification:** Meet Payment Card Industry Data Security Standards to protect cardholder data.
      **Data Encryption:** Encrypt payment card data to prevent unauthorised access or theft.
      **Secure Payment Processing:** Enable secure transactions, reducing the risk of card payment fraud.
    `,
    image: "/assets/images/zero-core/pci-dss-compliance.webp"
  },

  "Fraud Detection Algorithms": {
    title: "Fraud Detection Algorithms",
    description: `
      AI-driven algorithms detect fraudulent activity in real time, mitigating risk and financial loss.
      
      **Key Features:**
      **Real-Time Detection:** Instantly detect suspicious activities and prevent fraudulent transactions.
      **AI-Driven Analytics:** Leverage machine learning algorithms to predict potential fraud patterns.
      **Automated Alerts:** Receive immediate alerts for high-risk activities, enabling fast intervention.
    `,
    image: "/assets/images/zero-core/fraud-detection-algorithms.webp"
  }
};

export const BusinessBenefitsDetails = {
  "Revenue Growth Opportunities": {
    title: "Revenue Growth Opportunities",
    description: `
      **Unlock New Revenue Streams** by leveraging Zero-Core’s advanced banking infrastructure to support new business models.
      
      **Key Features:**
      **Cross-Border Payments:** Enable international payments, supporting multi-currency transactions.
      **DeFi Integrations:** Tap into decentralised finance capabilities like staking, lending, and liquidity pools.
      **Tokenisation of Assets:** Tokenise traditional financial products to unlock liquidity and create new trading markets.
    `,
    image: "/assets/images/zero-core/revenue-growth-opportunities.webp"
  },

  "Operational Cost Reduction": {
    title: "Operational Cost Reduction",
    description: `
      **Reduce Operating Costs** by using cloud-native infrastructure, microservices, and automation.
      
      **Key Features:**
      **Cloud-Native Deployment:** Deploy on AWS, Azure, or GCP for cost-effective scalability and disaster recovery.
      **Process Automation:** Automate payments, compliance, and reporting workflows to cut down on manual labour.
      **Microservices:** Update, scale, or replace components independently, saving development time and operational costs.
    `,
    image: "/assets/images/zero-core/operational-cost-reduction.webp"
  },

  "Customer-Centric Banking": {
    title: "Customer-Centric Banking",
    description: `
      **Transform Customer Journeys** by enabling hyper-personalisation and mobile-first design.
      
      **Key Features:**
      **Customisable UI/UX:** Deliver banking interfaces that match customer preferences and corporate branding.
      **Mobile-First Experiences:** Provide mobile-optimised banking for a fast, seamless customer experience.
      **User-Centric Product Design:** Enable institutions to co-create banking products tailored to customer needs.
    `,
    image: "/assets/images/zero-core/customer-centric-banking.webp"
  },

  "Compliance & Risk Mitigation": {
    title: "Compliance & Risk Mitigation",
    description: `
      **Stay Audit-Ready** and **Reduce Regulatory Risk** with built-in compliance controls and fraud detection.
      
      **Key Features:**
      **KYC/AML Compliance:** Automatically verify customer identities and prevent money laundering.
      **GDPR Adherence:** Give customers full control of their personal data and ensure compliance with global privacy laws.
      **Fraud Detection:** Real-time fraud detection using AI-driven models for instant anomaly detection.
    `,
    image: "/assets/images/zero-core/compliance-risk-mitigation.webp"
  },

  "Speed to Market": {
    title: "Speed to Market",
    description: `
      **Accelerate Product Launches** with modular components and pre-built integrations.
      
      **Key Features:**
      **Modular Banking Components:** Launch core banking, payments, and compliance modules independently.
      **API-Driven Integrations:** Leverage APIs for fast integration with payment gateways and fintech ecosystems.
      **DevOps-Ready:** Shorten development cycles with containerised environments for rapid testing and deployment.
    `,
    image: "/assets/images/zero-core/speed-to-market.webp"
  },

  "Enhanced Security & Trust": {
    title: "Enhanced Security & Trust",
    description: `
      **Build Trust & Confidence** by ensuring customer data is secure and transactions are tamper-proof.
      
      **Key Features:**
      **Blockchain Ledger:** Create a tamper-proof audit trail of all banking transactions.
      **Data Encryption:** End-to-end AES-256 encryption ensures sensitive data is secure.
      **Zero-Knowledge Proofs:** Verify identities and credentials without exposing personal data.
    `,
    image: "/assets/images/zero-core/enhanced-security-trust.webp"
  }
};


export const FutureEnhancementsDetails = {
  "AI-Powered Risk Scoring": {
    title: "AI-Powered Risk Scoring",
    description: `
      Leverage AI models to predict borrower risk and assess creditworthiness, improving decision-making in lending and customer support.
      
      **Key Features:**
      **Predictive Risk Analysis:** AI algorithms forecast potential risks before they escalate.
      **Enhanced Credit Assessments:** Improve borrower credit scoring accuracy and fairness.
      **Customisable Risk Models:** Tailor risk scoring models for specific business needs.
    `,
    image: "/assets/images/zero-core/ai-risk-scoring.webp"
  },

  "DeFi Integration": {
    title: "DeFi Integration",
    description: `
      Incorporate decentralised finance (DeFi) capabilities such as staking, liquidity pools, and decentralised lending into Zero-Core's ecosystem.
      
      **Key Features:**
      **DeFi Lending & Borrowing:** Enable on-chain lending and borrowing for users and institutions.
      **Staking & Yield Farming:** Allow users to stake tokens and earn passive income.
      **Liquidity Pools:** Facilitate liquidity pools for decentralised token swapping and yield generation.
    `,
    image: "/assets/images/zero-core/defi-integration.webp"
  },

  "Green Banking Solutions": {
    title: "Green Banking Solutions",
    description: `
      Launch eco-friendly banking initiatives to reduce the carbon footprint of financial transactions, promoting sustainable banking practices.
      
      **Key Features:**
      **Carbon-Neutral Payments:** Support payments and transactions with minimal environmental impact.
      **Green Energy Partnerships:** Collaborate with eco-friendly data centres powered by renewable energy.
      **ESG Reporting:** Provide tools to track and report the environmental impact of banking operations.
    `,
    image: "/assets/images/zero-core/green-banking.webp"
  },

  "Voice-Activated Banking": {
    title: "Voice-Activated Banking",
    description: `
      Enable customers to perform banking tasks via voice commands, enhancing accessibility and customer experience for all users.
      
      **Key Features:**
      **Voice Command Transactions:** Authorise transfers, payments, and other tasks via voice.
      **Multi-Language Support:** Offer voice banking services in multiple languages for global reach.
      **AI-Powered Voice Recognition:** Use AI to enhance accuracy and reduce misinterpretation of user commands.
    `,
    image: "/assets/images/zero-core/voice-banking.webp"
  },

  "Quantum-Secure Encryption": {
    title: "Quantum-Secure Encryption",
    description: `
      Introduce post-quantum encryption algorithms to future-proof Zero-Core's security from the threats posed by quantum computing.
      
      **Key Features:**
      **Post-Quantum Cryptography (PQC):** Implement encryption that is resistant to quantum computing attacks.
      **Data Protection:** Secure all user data, keys, and transaction details with quantum-resistant algorithms.
      **Future-Proof Security:** Ensure long-term security to safeguard financial data and assets.
    `,
    image: "/assets/images/zero-core/quantum-security.webp"
  },

  "Cross-Chain Interoperability": {
    title: "Cross-Chain Interoperability",
    description: `
      Support cross-chain interoperability to allow seamless movement of tokenised assets and payments between blockchain networks.
      
      **Key Features:**
      **Tokenised Asset Transfers:** Transfer assets between Ethereum, Binance Smart Chain, and other blockchains.
      **Cross-Chain Payments:** Process payments across multiple blockchain ecosystems.
      **Interoperable Smart Contracts:** Enable smart contracts to operate across multiple blockchain protocols.
    `,
    image: "/assets/images/zero-core/cross-chain-interoperability.webp"
  }
};
