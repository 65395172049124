export const CoreFeatures = [
    {
      title: "Sustainable Investment Hub",
      description: 
        "Access a curated selection of pre-vetted green projects focused on sustainability impact and financial viability.",
      image: "/assets/images/zero-green/sustainable-investment-hub.webp",
    },
    {
      title: "Fractional Investment",
      description: 
        "Invest small amounts towards larger, impactful green projects, supporting inclusivity and broader participation.",
      image: "/assets/images/zero-green/fractional-investment.webp",
    },
    {
      title: "AI-Powered Investment Insights",
      description: 
        "Predictive AI-driven analytics offer success rate forecasts, investor sentiment, and impact metrics for each project.",
      image: "/assets/images/zero-green/ai-insights.webp",
    },
    {
      title: "Smart Contract Disbursement",
      description: 
        "Funds are released to project initiators only when milestone goals are achieved, ensuring project accountability.",
      image: "/assets/images/zero-green/smart-contract-disbursement.webp",
    },
    {
      title: "Blockchain-Powered Transparency",
      description: 
        "All investments and transactions are stored on an immutable blockchain ledger, providing full visibility and auditability.",
      image: "/assets/images/zero-green/blockchain-transparency.webp",
    },
    {
      title: "Customised Investor Dashboard",
      description: 
        "Personalised dashboards give investors full visibility of project impact, returns, and performance metrics.",
      image: "/assets/images/zero-green/custom-dashboard.webp",
    }
  ];

export const UserPersonas = [
    {
      title: "Eco-Conscious Investors",
      description: 
        "Retail investors seeking ethical, impact-driven projects that align with personal sustainability goals.",
      image: "/assets/images/zero-green/eco-conscious-investors.webp",
    },
    {
      title: "Institutional Investors",
      description: 
        "Institutional investors and asset managers aiming to achieve ESG goals and diversify portfolios with green investments.",
      image: "/assets/images/zero-green/institutional-investors.webp",
    },
    {
      title: "Project Initiators",
      description: 
        "Startups, NGOs, and companies seeking capital for sustainable projects aligned with the United Nations Sustainable Development Goals (SDGs).",
      image: "/assets/images/zero-green/project-initiators.webp",
    },
    {
      title: "Wealth Managers",
      description: 
        "Wealth managers advising high-net-worth individuals on green investment opportunities with growth potential.",
      image: "/assets/images/zero-green/wealth-managers.webp",
    },
    {
      title: "ESG Analysts",
      description: 
        "ESG analysts monitoring the environmental, social, and governance impact of investments made via Zero-Green.",
      image: "/assets/images/zero-green/esg-analysts.webp",
    },
    {
      title: "Non-Governmental Organisations (NGOs)",
      description: 
        "NGOs focused on environmental sustainability that seek funding for eco-friendly initiatives.",
      image: "/assets/images/zero-green/ngo-initiators.webp",
    }
  ];

export const KeyBenefits = [
    {
      title: "Drive Sustainable Impact",
      description: 
        "Invest in projects that align with United Nations SDGs, supporting environmental, social, and economic change.",
      image: "/assets/images/zero-green/sustainable-impact.webp",
    },
    {
      title: "Access to Verified Projects",
      description: 
        "Invest only in pre-vetted, high-impact projects that have undergone due diligence for both environmental and financial viability.",
      image: "/assets/images/zero-green/verified-projects.webp",
    },
    {
      title: "Investor Accountability",
      description: 
        "Milestone-based fund disbursement ensures accountability, with funds released only when project goals are met.",
      image: "/assets/images/zero-green/investor-accountability.webp",
    },
    {
      title: "AI-Driven Insights",
      description: 
        "Predict project success rates and investor sentiment with AI-driven analytics, empowering better investment decisions.",
      image: "/assets/images/zero-green/ai-driven-insights.webp",
    },
    {
      title: "Investment Diversification",
      description: 
        "Diversify your portfolio across green sectors such as renewable energy, waste reduction, and sustainable technology.",
      image: "/assets/images/zero-green/investment-diversification.webp",
    },
    {
      title: "Investor Protection",
      description: 
        "Secure investments using blockchain-powered records, offering investors transparency, immutability, and security of funds.",
      image: "/assets/images/zero-green/investor-protection.webp",
    }
  ];

export const UseCases = [
    {
      title: "GreenTech Crowdfunding",
      description: 
        "Connect conscious investors with sustainable projects, enabling fractional investments in impactful green initiatives.",
      image: "/assets/images/zero-green/greentech-crowdfunding.webp",
    },
    {
      title: "Renewable Energy Projects",
      description: 
        "Support renewable energy projects, such as solar, wind, and hydroelectric initiatives, to drive global clean energy adoption.",
      image: "/assets/images/zero-green/renewable-energy.webp",
    },
    {
      title: "Waste Reduction Initiatives",
      description: 
        "Fund projects focused on reducing waste, supporting a circular economy, and promoting sustainable consumption.",
      image: "/assets/images/zero-green/waste-reduction.webp",
    },
    {
      title: "Eco-Friendly Startups",
      description: 
        "Back startups developing new sustainable products, services, and green innovations to drive long-term change.",
      image: "/assets/images/zero-green/eco-friendly-startups.webp",
    },
    {
      title: "Sustainable Agriculture",
      description: 
        "Invest in agricultural projects promoting organic farming, soil regeneration, and eco-friendly farming techniques.",
      image: "/assets/images/zero-green/sustainable-agriculture.webp",
    },
    {
      title: "Clean Water Initiatives",
      description: 
        "Fund clean water access projects aimed at providing safe, potable water to underserved communities globally.",
      image: "/assets/images/zero-green/clean-water-projects.webp",
    }
  ];


export const SecurityAndCompliance = [
    {
      title: "KYC & AML Compliance",
      description: 
        "All users undergo Know Your Customer (KYC) and Anti-Money Laundering (AML) checks to ensure regulatory compliance.",
      image: "/assets/images/zero-green/kyc-aml-compliance.webp",
    },
    {
      title: "GDPR & ISO 27001 Compliance",
      description: 
        "Zero-Green ensures compliance with GDPR and ISO 27001, protecting user data and maintaining privacy rights.",
      image: "/assets/images/zero-green/gdpr-compliance.webp",
    },
    {
      title: "Blockchain Transparency & Auditability",
      description: 
        "All transactions are recorded on an immutable blockchain ledger, providing complete transparency and enabling auditable project tracking.",
      image: "/assets/images/zero-green/blockchain-transparency-auditability.webp",
    },
    {
      title: "End-to-End Data Encryption",
      description: 
        "All communications and sensitive data are encrypted using AES-256, ensuring the confidentiality and protection of user information.",
      image: "/assets/images/zero-green/end-to-end-encryption.webp",
    },
    {
      title: "Role-Based Access Control (RBAC)",
      description: 
        "Role-Based Access Control (RBAC) limits access to sensitive information and platform features, ensuring secure user permissions.",
      image: "/assets/images/zero-green/rbac-access-control.webp",
    },
    {
      title: "Multi-Factor Authentication (MFA)",
      description: 
        "Multi-Factor Authentication (MFA) adds an additional layer of security, ensuring only authorised users access the platform.",
      image: "/assets/images/zero-green/mfa-security.webp",
    }
  ];

  export const BusinessBenefits = [
    {
      title: "Accelerated ESG Compliance",
      description: 
        "Enable corporations and financial institutions to meet ESG compliance goals through investments in verified, impact-driven green projects.",
      image: "/assets/images/zero-green/esg-compliance.webp",
    },
    {
      title: "New Revenue Streams from Green Finance",
      description: 
        "Capitalise on the growing market for sustainable finance by offering access to tokenised green bonds, fractional investments, and ESG-linked products.",
      image: "/assets/images/zero-green/green-finance-revenue.webp",
    },
    {
      title: "Reduced Risk with Blockchain Transparency",
      description: 
        "Mitigate investment risks with blockchain-powered transparency, ensuring that all fund disbursements are milestone-based and fully auditable.",
      image: "/assets/images/zero-green/blockchain-transparency.webp",
    },
    {
      title: "Brand Reputation and CSR Alignment",
      description: 
        "Enhance brand reputation by supporting projects that align with the United Nations Sustainable Development Goals (SDGs) and demonstrate corporate social responsibility (CSR).",
      image: "/assets/images/zero-green/csr-alignment.webp",
    },
    {
      title: "Increased Investor Engagement",
      description: 
        "Drive investor engagement with AI-powered impact reports, personalised dashboards, and real-time milestone tracking for project performance.",
      image: "/assets/images/zero-green/investor-engagement.webp",
    },
    {
      title: "Access to Impact-Driven Capital",
      description: 
        "Attract mission-driven investors and institutional backers by offering a platform where sustainable development meets financial returns.",
      image: "/assets/images/zero-green/impact-capital.webp",
    }
  ];

export const FutureEnhancements = [
    {
      title: "DeFi Integration",
      description: 
        "Integrate decentralised finance (DeFi) tools for faster payouts, crypto loans, and borderless payments on Zero-Green.",
      image: "/assets/images/zero-green/defi-integration.webp",
    },
    {
      title: "Impact Reporting Tools",
      description: 
        "Provide enhanced reporting to show the social, environmental, and financial impact of each funded project.",
      image: "/assets/images/zero-green/impact-reporting.webp",
    },
    {
      title: "Mobile App Development",
      description: 
        "Launch a mobile app for investors to track project performance, receive updates, and make new investments on the go.",
      image: "/assets/images/zero-green/mobile-app.webp",
    },
    {
      title: "AI-Driven Investor Insights",
      description: 
        "Introduce AI-powered investment recommendations based on investor preferences, behaviour, and risk appetite.",
      image: "/assets/images/zero-green/ai-driven-insights.webp",
    },
    {
      title: "Custom ESG Impact Scoring",
      description: 
        "Create a proprietary ESG scoring system to help investors assess project impact before making an investment.",
      image: "/assets/images/zero-green/esg-impact-scoring.webp",
    },
    {
      title: "Tokenised Green Bonds",
      description: 
        "Offer tokenised green bonds that allow fractional ownership of large green infrastructure projects, enabling broader participation.",
      image: "/assets/images/zero-green/tokenised-green-bonds.webp",
    }
  ];  