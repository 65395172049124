// src/components/CryptoStore.jsx

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import AnimatedTitle from './AnimatedTitle';
import { TiArrowUp } from "react-icons/ti";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import TokenCard from './TokenCard';
import HeroButton from './HeroButton';
import ScrollDownButton from './ScrollDownButton';

gsap.registerPlugin(ScrollTrigger);

const tokens = [    
  {
      id: 1,
      name: 'Zero-Gecko',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Psychedelic rock gecko, a critically endangered lisard.',
      creation: 'Appeared around 50 million years ago.',
      extinction: 'Critically endangered due to habitat loss.',
      image: '1-GECKO-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£10.00'
  },
  {
      id: 2,
      name: 'Zero-Tiger',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Tigers are large cats known for their stripes.',
      creation: 'Appeared around 2 million years ago.',
      extinction: 'Critically endangered with several subspecies extinct.',
      image: '2-TIGER-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£20.00'
  },
  {
      id: 3,
      name: 'Zero-Rhino',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Rhinos are large herbivores with characteristic horns.',
      creation: 'Appeared in the Eocene epoch, around 50 million years ago.',
      extinction: 'Several species are critically endangered.',
      image: '3-RHINO-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£30.00'
  },
  {
      id: 4,
      name: 'Zero-Stego',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Stegosaurus was a herbivorous dinosaur with distinctive back plates.',
      creation: 'Lived during the Late Jurassic period.',
      extinction: 'Extinct around 150 million years ago.',
      image: '4-STEGO-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£40.00'
  },
  {
      id: 5,
      name: 'Zero-Cheeto',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Cheetahs are the fastest land animals.',
      creation: 'Appeared around 4 million years ago.',
      extinction: 'Endangered due to habitat loss.',
      image: '5-CHEETO-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£50.00'
  },
  {
      id: 6,
      name: 'Zero-Bison',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Bison are large bovines native to North America and Europe.',
      creation: 'Appeared around 2 million years ago.',
      extinction: 'American bison were near extinction but are recovering.',
      image: '6-BISON-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£60.00'
  },
  {
      id: 7,
      name: 'Zero-Dino',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'General term for various dinosaurs.',
      creation: 'Appeared in the Mesozoic era.',
      extinction: 'Most became extinct 65 million years ago.',
      image: '7-DINO-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£70.00'
  },
  {
      id: 8,
      name: 'Zero-Moa',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Moas were large, flightless birds from New Zealand.',
      creation: 'Appeared in the Miocene epoch.',
      extinction: 'Became extinct around 1400 AD.',
      image: '8-MOA-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£80.00'
  },
  {
      id: 9,
      name: 'Zero-Woolly',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Woolly mammoths were large, hairy elephants.',
      creation: 'Appeared around 400,000 years ago.',
      extinction: 'Became extinct around 4,000 years ago.',
      image: '9-WOOLY-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£90.00'
  },
  {
      id: 10,
      name: 'Zero-Sabre',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Sabre-toothed cats were known for their long, curved canine teeth.',
      creation: 'Appeared around 2.5 million years ago.',
      extinction: 'Became extinct around 10,000 years ago.',
      image: '10-SABRE-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£100.00'
  },
  {
      id: 11,
      name: 'Zero-Quagga',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Quaggas were a subspecies of plains zebra.',
      creation: 'Appeared around 4 million years ago.',
      extinction: 'Became extinct in the late 19th century.',
      image: '11-QUAGGA-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£110.00'
  },
  {
      id: 12,
      name: 'Zero-Panda',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Giant pandas are bears native to China.',
      creation: 'Appeared around 2-3 million years ago.',
      extinction: 'Vulnerable due to habitat loss.',
      image: '12-PANDA-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£120.00'
  },
  {
      id: 13,
      name: 'Zero-Pteran',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Pteranodon was a large flying reptile.',
      creation: 'Lived during the Late Cretaceous period.',
      extinction: 'Became extinct 65 million years ago.',
      image: '13-PTERAN-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£130.00'
  },
  {
      id: 14,
      name: 'Zero-Thylac',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Thylacine, also known as the Tasmanian tiger.',
      creation: 'Appeared around 4 million years ago.',
      extinction: 'Became extinct in the 20th century.',
      image: '14-THYLAC-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£140.00'
  },
  {
      id: 15,
      name: 'Zero-Auroch',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Aurochs were large wild cattle.',
      creation: 'Appeared around 2 million years ago.',
      extinction: 'Became extinct in the 17th century.',
      image: '15-AUROCH-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£150.00'
  },
  {
      id: 16,
      name: 'Zero-Ivory',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Ivory-billed woodpecker, a large woodpecker species.',
      creation: 'Appeared in the Pleistocene epoch.',
      extinction: 'Critically endangered, possibly extinct.',
      image: '16-IVORY-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£160.00'
  },
  {
      id: 17,
      name: 'Zero-Pang',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Pangolins are scaly anteaters.',
      creation: 'Appeared around 55 million years ago.',
      extinction: 'Critically endangered due to poaching.',
      image: '17-PANG-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£170.00'
  },
  {
      id: 18,
      name: 'Zero-Condor',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'California condors are large vultures.',
      creation: 'Appeared around 40 million years ago.',
      extinction: 'Critically endangered but conservation efforts are ongoing.',
      image: '18-CONDOR-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£180.00'
  },
  {
      id: 19,
      name: 'Zero-Baiji',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Baiji, or Yangtze river dolphin.',
      creation: 'Appeared around 25 million years ago.',
      extinction: 'Declared functionally extinct in 2006.',
      image: '19-BAIJI-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£190.00'
  },
  {
      id: 20,
      name: 'Zero-Glypto',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Glyptodon was a large, armored mammal.',
      creation: 'Lived during the Pleistocene epoch.',
      extinction: 'Became extinct around 10,000 years ago.',
      image: '20-GLYPTO-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£200.00'
  },
  {
      id: 21,
      name: 'Zero-Megal',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Megalodon was a giant prehistoric shark.',
      creation: 'Lived during the Cenozoic era.',
      extinction: 'Became extinct around 2.6 million years ago.',
      image: '21-MEGAL-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£210.00'
  },
  {
      id: 22,
      name: 'Zero-Dire',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Dire wolves were large prehistoric canines.',
      creation: 'Lived during the Late Pleistocene epoch.',
      extinction: 'Became extinct around 10,000 years ago.',
      image: '22-DIRE-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£220.00'
  },
  {
      id: 23,
      name: 'Zero-Quetzal',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Quetzalcoatlus was a large pterosaur.',
      creation: 'Lived during the Late Cretaceous period.',
      extinction: 'Became extinct 65 million years ago.',
      image: '23-QUETZAL-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£230.00'
  },
  {
      id: 24,
      name: 'Zero-Lynx',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Iberian lynx, a wild cat species.',
      creation: 'Appeared around 5 million years ago.',
      extinction: 'Critically endangered due to habitat loss.',
      image: '24-LYNX-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£240.00'
  },
  {
      id: 25,
      name: 'Zero-Sauro',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Brachiosaurus, a large herbivorous dinosaur.',
      creation: 'Lived during the Late Jurassic period.',
      extinction: 'Became extinct around 150 million years ago.',
      image: '25-SAURO-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£250.00'
  },
  {
      id: 26,
      name: 'Zero-Okapi',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Okapi, a relative of the giraffe.',
      creation: 'Appeared around 16 million years ago.',
      extinction: 'Endangered due to habitat loss.',
      image: '26-OKAPI-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£260.00'
  },
  {
      id: 27,
      name: 'Zero-Kakapo',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Kakapo, a large flightless parrot from New Zealand.',
      creation: 'Appeared around 25 million years ago.',
      extinction: 'Critically endangered due to predation and habitat loss.',
      image: '27-KAKAPO-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£270.00'
  },
  {
      id: 28,
      name: 'Zero-Vaquita',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Vaquita, a small porpoise.',
      creation: 'Appeared around 2 million years ago.',
      extinction: 'Critically endangered with very few individuals left.',
      image: '28-VAQUITA-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£280.00'
  },
  {
      id: 29,
      name: 'Zero-Gorilla',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Mountain gorillas are large primates.',
      creation: 'Appeared around 2 million years ago.',
      extinction: 'Critically endangered due to poaching and habitat loss.',
      image: '29-GORILLA-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£290.00'
  },
  {
      id: 30,
      name: 'Zero-RedWolf',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Red wolf, a North American canid.',
      creation: 'Appeared around 2 million years ago.',
      extinction: 'Critically endangered with very few individuals left.',
      image: '30-RED-WOLF-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£300.00'
  },
  {
      id: 31,
      name: 'Zero-Tortoise',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Pinta Island tortoise, a giant tortoise species.',
      creation: 'Appeared around 3 million years ago.',
      extinction: 'Became extinct in 2012.',
      image: '31-TORTOISE-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£310.00'
  },
  {
      id: 32,
      name: 'Zero-Owl',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Spotted owl, a nocturnal bird of prey.',
      creation: 'Appeared around 20 million years ago.',
      extinction: 'Threatened due to habitat loss.',
      image: '32-OWL-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£320.00'
  },
  {
      id: 33,
      name: 'Zero-Manatee',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'West Indian manatee, a large marine mammal.',
      creation: 'Appeared around 50 million years ago.',
      extinction: 'Vulnerable due to habitat destruction and boat strikes.',
      image: '33-MANATEE-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£330.00'
  },
  {
      id: 34,
      name: 'Zero-Gibbon',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Hainan gibbon, a small ape.',
      creation: 'Appeared around 2 million years ago.',
      extinction: 'Critically endangered with fewer than 30 individuals left.',
      image: '34-GIBBON-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£340.00'
  },
  {
      id: 35,
      name: 'Zero-River',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Chinese paddlefish, a large freshwater fish.',
      creation: 'Appeared around 200 million years ago.',
      extinction: 'Declared extinct in 2020.',
      image: '35-RIVER-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£350.00'
  },
  {
      id: 36,
      name: 'Zero-Amur',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Amur leopard, a critically endangered big cat.',
      creation: 'Appeared around 2 million years ago.',
      extinction: 'Fewer than 100 individuals remain.',
      image: '36-AMUR-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£360.00'
  },
  {
      id: 37,
      name: 'Zero-Addax',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Addax, also known as the white antelope.',
      creation: 'Appeared around 2 million years ago.',
      extinction: 'Critically endangered due to habitat loss and hunting.',
      image: '37-ADDAX-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£370.00'
  },
  {
      id: 38,
      name: 'Zero-Tuatara',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Tuatara, a reptile endemic to New Zealand.',
      creation: 'Appeared around 200 million years ago.',
      extinction: 'Endangered due to habitat loss.',
      image: '38-TUATARA-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£380.00'
  },
  {
      id: 39,
      name: 'Zero-Ibis',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Northern bald ibis, a critically endangered bird.',
      creation: 'Appeared around 20 million years ago.',
      extinction: 'Critically endangered due to habitat loss.',
      image: '39-IBIS-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£390.00'
  },
  {
      id: 40,
      name: 'Zero-Elephant',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Sumatran elephant, a critically endangered subspecies.',
      creation: 'Appeared around 3 million years ago.',
      extinction: 'Critically endangered due to habitat loss and poaching.',
      image: '40-ELEPHANT-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£400.00'
  },
  {
      id: 41,
      name: 'Zero-Lemur',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Greater bamboo lemur, a critically endangered primate.',
      creation: 'Appeared around 50 million years ago.',
      extinction: 'Critically endangered due to habitat loss.',
      image: '41-LEMUR-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£410.00'
  },
  {
      id: 42,
      name: 'Zero-Dodo',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'The Dodo was a flightless bird native to Mauritius.',
      creation: 'Appeared in the Pleistocene epoch.',
      extinction: 'Became extinct in the late 17th century.',
      image: '42-DODO-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£420.00'
  },
  {
      id: 43,
      name: 'Zero-Harpy',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Harpy eagle, a powerful raptor.',
      creation: 'Appeared around 20 million years ago.',
      extinction: 'Near threatened due to habitat loss.',
      image: '43-HARPY-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£430.00'
  },
  {
      id: 44,
      name: 'Zero-Wolf',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Ethiopian wolf, a critically endangered canid.',
      creation: 'Appeared around 2 million years ago.',
      extinction: 'Fewer than 500 individuals remain.',
      image: '44-WOLF-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£440.00'
  },
  {
      id: 45,
      name: 'Zero-SunBear',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Sun bear, the smallest bear species.',
      creation: 'Appeared around 5 million years ago.',
      extinction: 'Vulnerable due to habitat loss and poaching.',
      image: '45-SUN-BEAR-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£450.00'
  },
  {
      id: 46,
      name: 'Zero-Orca',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Southern resident orcas, a population of killer whales.',
      creation: 'Appeared around 11 million years ago.',
      extinction: 'Critically endangered due to prey depletion and pollution.',
      image: '46-ORCA-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£460.00'
  },
  {
      id: 47,
      name: 'Zero-Pika',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Ili pika, a small mammal from China.',
      creation: 'Appeared around 20 million years ago.',
      extinction: 'Endangered due to habitat loss.',
      image: '47-PIKA-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£470.00'
  },
  {
      id: 48,
      name: 'Zero-Shark',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Great hammerhead shark, a critically endangered species.',
      creation: 'Appeared around 20 million years ago.',
      extinction: 'Critically endangered due to overfishing.',
      image: '48-SHARK-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£480.00'
  },
  {
      id: 49,
      name: 'Zero-Mynah',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Bali mynah, a critically endangered bird.',
      creation: 'Appeared around 2 million years ago.',
      extinction: 'Critically endangered due to habitat loss and pet trade.',
      image: '49-MYNAH-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£490.00'
  },
  {
      id: 50,
      name: 'Zero-Camel',
      tokenStandard: 'ERC-20 Smart Contract',
      deployment: 'Ethereum Mainnet',
      contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
      description: 'Wild Bactrian camel, a critically endangered species.',
      creation: 'Appeared around 3 million years ago.',
      extinction: 'Critically endangered due to habitat loss and hunting.',
      image: '50-CAMEL-FRONT-ERC20-ZERO-TOKEN.png',
      price: '£500.00'
  }
];

const CryptoStore = () => {
    const navigate = useNavigate();
    const [scrollDownClicked, setScrollDownClicked] = useState(false);
    const [isBottom, setIsBottom] = useState(false);

    const sectionRefs = useRef([]);
    const scrollDownRef = useRef(null);
    const scrollTopRef = useRef(null);

    const handleMoreDetailsClick = (id) => {
        navigate(`/token/${id}`);
    };

    const handlePurchaseClick = (name) => {
        alert(`Purchasing ${name}`);
    };

    const handleViewOnChainClick = (contractAddress) => {
        window.open(`https://etherscan.io/token/${contractAddress}`, '_blank'); // Updated to Etherscan
    };

    // Refs for GSAP animations
    const tokenRefs = useRef([]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });

        // GSAP Animations for sections
        sectionRefs.current.forEach((section, index) => {
            if (section) {
                gsap.fromTo(
                    section,
                    { opacity: 0, y: 50 },
                    {
                        opacity: 1,
                        y: 0,
                        duration: 1,
                        delay: index * 0.2,
                        scrollTrigger: {
                            trigger: section,
                            start: "top 80%",
                        },
                    }
                );
            }
        });

        // GSAP Animation for Scroll Down Button (Initial Animation)
        if (scrollDownRef.current) {
            gsap.fromTo(
                scrollDownRef.current,
                { opacity: 0, y: -20 },
                { opacity: 1, y: 0, duration: 1 }
            );
        }

        // GSAP Animation for Back to Top Button
        if (scrollTopRef.current) {
            gsap.fromTo(
                scrollTopRef.current,
                { opacity: 0, y: 20 },
                {
                    opacity: 1,
                    y: 0,
                    duration: 0.5,
                    scrollTrigger: {
                        trigger: scrollTopRef.current,
                        start: "top bottom",
                    },
                }
            );
        }
    }, []);

    // Check if the user is near the bottom of the page
    const checkIfBottom = () => {
        if (
            window.innerHeight + window.scrollY >=
            document.body.offsetHeight - 200
        ) {
            setIsBottom(true); // Show Back to Top button
        } else {
            setIsBottom(false); // Hide Back to Top button
        }
    };

    // Scroll to the content section with animation and hide the button
    const scrollToContent = () => {
        const content = document.getElementById("content");
        if (content) {
            const contentOffsetTop = content.offsetTop;
            window.scrollTo({
                top: contentOffsetTop - 100, // Adjust offset as needed
                behavior: "smooth",
            });
        }

        // Animate the Scroll Down button before hiding it
        if (scrollDownRef.current) {
            gsap.to(scrollDownRef.current, {
                opacity: 0,
                y: -20,
                duration: 0.5,
                onComplete: () => setScrollDownClicked(true),
            });
        }
    };

    // Scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setScrollDownClicked(false); // Show Scroll Down button (if needed)
    };

    // Add a scroll listener to monitor page position
    useEffect(() => {
        window.addEventListener("scroll", checkIfBottom);
        return () => {
            window.removeEventListener("scroll", checkIfBottom);
        };
    }, []);

    // GSAP Animations for token items
    useEffect(() => {
        tokenRefs.current.forEach((token, index) => {
            if (token) {
                gsap.fromTo(
                    token,
                    { opacity: 0, y: 50 },
                    {
                        opacity: 1,
                        y: 0,
                        duration: 1,
                        delay: index * 0.1,
                        scrollTrigger: {
                            trigger: token,
                            start: "top 80%",
                        },
                    }
                );
            }
        });
    }, []);

    return (
        <div className="w-screen min-h-screen bg-black text-blue-50 flex flex-col items-center p-5 relative">
            {/* Hero Section */}
            <div className="relative w-full h-64 md:h-96 overflow-hidden mb-10">
                <video
                    src="/videos/cryptostore-hero.mp4" // Ensure this video exists in your public/videos directory
                    autoPlay
                    loop
                    muted
                    className="absolute left-0 top-0 w-full h-full object-cover"
                />
                <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/60">
                    {/* AnimatedTitle with Updated Main Title */}
                    <AnimatedTitle
                        title="Zero-Token Store: Powering Your Future"
                        containerClass="text-center text-blue-50 font-zentry"
                    />
                </div>
            </div>

            {/* Scroll Down Button */}
            {!scrollDownClicked && (
                <ScrollDownButton
                    ref={scrollDownRef}
                    additionalClasses="fixed bottom-20 z-50 left-1/2 transform -translate-x-1/2"
                    onClick={scrollToContent}
                />
            )}

            {/* Content Section */}
            <div id="content" className="px-10 py-20 mx-auto max-w-7xl">
                {/* Introduction */}
                <div
                    className="mb-10 text-center"
                    ref={(el) => (sectionRefs.current[0] = el)}
                >
                    <h2 className="text-3xl font-bold text-white">Explore Our Crypto Tokens</h2>
                    <p className="mt-3 text-sm text-white-900">
                        Discover a range of endangered and extinct animal tokens developed on the Ethereum blockchain.
                    </p>
                </div>

                {/* All Tokens Description */}
                <div
                    className="mb-10 text-center"
                    ref={(el) => (sectionRefs.current[1] = el)}
                >
                    <h3 className="text-2xl font-bold text-white font-zentry">Our Collection</h3>
                    <p className="mt-3 text-sm text-white-900">
                        Each token represents a unique endangered or extinct species, symbolising our commitment to conservation through blockchain technology.
                    </p>
                </div>

                {/* Tokens List */}
                <div className="w-full max-w-6xl" id="tokens-list">
                    {tokens.map((token, index) => (
                        <TokenCard
                            key={token.id}
                            token={token}
                            onMoreDetails={handleMoreDetailsClick}
                            onPurchase={handlePurchaseClick}
                            onViewOnChain={handleViewOnChainClick}
                            ref={(el) => (tokenRefs.current[index] = el)}
                        />
                    ))}
                </div>

                {/* Optional Additional Content Sections */}
                {/* You can add more sections here if needed */}
            </div>

            {/* Back to Top Button */}
            {isBottom && (
                <div
                    ref={scrollTopRef}
                    className="fixed bottom-10 left-5 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300 z-50"
                    onClick={scrollToTop}
                    aria-label="Back to Top"
                >
                    <TiArrowUp
                        className="text-gray-400 hover:text-white transition-transform duration-300 hover:scale-110"
                        size={24}
                    />
                    <span className="relative z-20 text-sm uppercase text-gray-400 transition-colors duration-300 hover:text-white">
                        BACK TO TOP
                    </span>
                </div>
            )}

            {/* Footer */}
            <Footer />
        </div>
    );
};

export default CryptoStore;