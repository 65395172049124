export const CoreFeatureDetails = {
    "Seamless Payment Integration": {
      title: "Seamless Payment Integration",
      description: `
        Seamless Payment Integration enables businesses to easily integrate with global payment gateways such as PayPal, Stripe, and local payment processors. 
        It allows businesses to process payments through e-commerce websites, point-of-sale (POS) systems, and mobile payment apps. 
        This feature supports the following benefits:
        
         **Multi-channel Payment Support:** Accept payments through online, in-store, and mobile channels.
         **Faster Payment Processing:** Reduce settlement times and improve cash flow.
         **Enhanced User Experience:** Simplifies the customer checkout process.
  
        This system ensures smooth payment handling and empowers businesses to operate efficiently across multiple sales channels.
      `,
      image: "/assets/images/zero-flow/seamless-integration.webp"
    },
  
    "Multi-Currency & Cross-Border Payments": {
      title: "Multi-Currency & Cross-Border Payments",
      description: `
        Multi-Currency & Cross-Border Payments allow businesses to accept payments from customers in various currencies.
        With this feature, payments are automatically converted in real-time using the latest exchange rates, enabling frictionless cross-border transactions.
  
        Key Features:
         **Real-Time Exchange Rates:** Automatic currency conversion using up-to-date rates.
         **Cross-Border Payment Network:** Support for international payments without the delays of traditional SWIFT payments.
         **Fee Reduction:** Lower transaction fees and operational costs.
      `,
      image: "/assets/images/zero-flow/cross-border-payments.webp"
    },
  
    "Automation & Smart Payment Routing": {
      title: "Automation & Smart Payment Routing",
      description: `
        Automation & Smart Payment Routing enhances the efficiency of payment processing by selecting the most optimal route for each transaction. 
        This process ensures faster payments, lower fees, and better success rates.
  
        Key Benefits:
        **Cost-Effective Payments:** Routes payments through the cheapest possible channel.
        **Recurring Payment Support:** Enables subscription-based payments.
        **Faster Payment Times:** Reduces payment processing delays.
      `,
      image: "/assets/images/zero-flow/automation-smart-routing.webp"
    },
  
    "Compliance & Security": {
      title: "Compliance & Security",
      description: `
        Compliance & Security ensures that payments adhere to the latest security standards like PCI-DSS. 
        The system supports encrypted payments and maintains KYC (Know Your Customer) and AML (Anti-Money Laundering) compliance.
  
        Key Features:
        **KYC & AML Integration:** Ensures compliance with global financial regulations.
        **PCI-DSS Certification:** Protects payment data in transit and at rest.
        **Encrypted Payments:** Provides end-to-end encryption for all payment data.
      `,
      image: "/assets/images/zero-flow/security-compliance.webp"
    },
  
    "Real-Time Transaction Insights": {
      title: "Real-Time Transaction Insights",
      description: `
        Real-Time Transaction Insights provide businesses with live dashboards to track payments, monitor trends, and access critical data. 
        The insights help businesses make data-driven decisions, optimise performance, and manage customer payment behavior.
  
        Key Features:
        **Live Dashboard:** Real-time updates on payment status.
        **Customisable Reports:** Generate reports for settlement and chargeback reconciliation.
        **Trend Analysis:** Identify patterns in customer payments and behavior.
      `,
      image: "/assets/images/zero-flow/realtime-insights.webp"
    },
  
    "Developer-Friendly API & SDKs": {
      title: "Developer-Friendly API & SDKs",
      description: `
        Developer-Friendly API & SDKs offer developers the ability to integrate payment functionality into websites, mobile apps, and other platforms. 
        With detailed documentation, SDKs for multiple languages, and comprehensive support, developers can build custom workflows.
  
        Key Features:
        **Extensive SDKs:** Available for JavaScript, Python, PHP, and more.
        **Full API Access:** Customise workflows, payment methods, and functionality.
        **Comprehensive Documentation:** Step-by-step guides and examples for quick implementation.
      `,
      image: "/assets/images/zero-flow/developer-friendly-api.webp"
    },

    "Default": {
  title: "Default Title",
  description: "Content not available.",
  image: "/assets/images/default-image.webp"
}
  };


  export const PaymentCapabilitiesFeaturesDetails = {
    "Multi-Payment Gateway Support": {
      title: "Multi-Payment Gateway Support",
      description: `
        Integrate with multiple global payment gateways, enabling customers to pay with their preferred payment method.
        
        Key Features:
        **Multiple Gateway Support:** Access a wide range of payment providers including PayPal, Stripe, and local processors.
        **Flexibility for Customers:** Customers can select their preferred payment method.
        **Increased Conversions:** Support for multiple payment methods increases checkout completion rates.
      `,
      image: "/assets/images/zero-flow/payment-gateway-support.webp"
    },
  
    "Recurring Payments": {
      title: "Recurring Payments",
      description: `
        Facilitates automated subscription billing for SaaS, memberships, and subscription-based business models.
  
        Key Features:
        **Automated Billing:** Automatically bill customers at regular intervals.
        **Flexible Subscription Management:** Support for upgrades, downgrades, and plan changes.
        **Reduced Churn:** Automated payment retries for failed payments.
      `,
      image: "/assets/images/zero-flow/recurring-payments.webp"
    },
  
    "Real-Time Currency Conversion": {
      title: "Real-Time Currency Conversion",
      description: `
        Convert currencies in real time, allowing businesses to accept payments in different currencies and minimise exchange fees.
  
        Key Features:
        **Dynamic Currency Conversion:** Real-time exchange rate updates for accurate currency conversion.
        **Seamless Checkout:** Customers can view prices in their preferred currency.
        **Reduced Exchange Fees:** Minimise currency conversion costs for merchants and customers.
      `,
      image: "/assets/images/zero-flow/currency-conversion.webp"
    },
  
    "Cross-Border Payments": {
      title: "Cross-Border Payments",
      description: `
        Handle cross-border payments without the high fees and delays associated with SWIFT transfers, enabling frictionless international transactions.
  
        Key Features:
        **International Support:** Accept payments from global customers.
        **Faster Transfers:** Avoid delays from traditional SWIFT payments.
        **Lower Transaction Fees:** Benefit from lower fees compared to conventional cross-border transfers.
      `,
      image: "/assets/images/zero-flow/cross-border-transactions.webp"
    },
  
    "Payment Tokenisation": {
      title: "Payment Tokenisation",
      description: `
        Securely store customer payment information using tokenisation, enhancing security and simplifying repeat transactions.
  
        Key Features:
        **Data Encryption:** Replaces sensitive card details with secure tokens.
        **Reduced PCI-DSS Scope:** Merchants reduce their compliance burden.
        **Streamlined Payments:** Simplifies repeat transactions with saved tokens.
      `,
      image: "/assets/images/zero-flow/payment-tokenisation.webp"
    },
  
    "One-Click Payments": {
      title: "One-Click Payments",
      description: `
        Allow customers to make purchases quickly with saved payment details, improving user experience and increasing conversion rates.
  
        Key Features:
        **Faster Checkout:** Save customer payment details for quick re-use.
        **Increased Conversions:** Remove checkout friction, leading to higher conversion rates.
        **Secure Payments:** Ensure cardholder data is tokenised and securely stored.
      `,
      image: "/assets/images/zero-flow/one-click-payments.webp"
    },
  
    "Default": {
      title: "Default Title",
      description: "Content not available.",
      image: "/assets/images/default-image.webp"
    }
  };
  
  export const TechnicalCapabilitiesFeaturesDetails = {
    "Microservices Architecture": {
      title: "Microservices Architecture",
      description: `
        Leverages a microservices architecture, enabling scalability, modular development, and continuous updates with minimal downtime.
        
        Key Features:
        **Scalability:** Services can be independently scaled to handle load variations.
        **Modular Development:** Teams can build, test, and deploy individual services independently.
        **Continuous Updates:** Deploy new features without system downtime.
      `,
      image: "/assets/images/zero-flow/microservices-architecture.webp"
    },
  
    "Cloud-Native Deployment": {
      title: "Cloud-Native Deployment",
      description: `
        Cloud-native design ensures fast, resilient deployments on platforms like AWS, Azure, and Google Cloud for high availability and disaster recovery.
  
        Key Features:
        **Platform-Agnostic:** Works seamlessly on AWS, Azure, and Google Cloud.
        **High Availability:** Built-in disaster recovery and fault tolerance.
        **Resilient Deployments:** Enables rapid rollbacks and zero-downtime deployments.
      `,
      image: "/assets/images/zero-flow/cloud-native-design.webp"
    },
  
    "Containerisation": {
      title: "Containerisation",
      description: `
        Uses containerisation technology (like Docker) to streamline deployments, ensure consistency, and reduce environment-specific errors.
  
        Key Features:
        **Environment Consistency:** Eliminate "it works on my machine" issues.
        **Faster Deployments:** Deploy apps faster and with minimal configuration.
        **Cross-Platform Support:** Build once, run anywhere with Docker containers.
      `,
      image: "/assets/images/zero-flow/containerisation.webp"
    },
  
    "Message Queueing": {
      title: "Message Queueing",
      description: `
        Utilises message queues like RabbitMQ to handle real-time payment notifications, ensuring fast and asynchronous communication.
  
        Key Features:
        **Real-Time Notifications:** Enable instant updates on payment status.
        **Asynchronous Processing:** Decouple services to boost system performance.
        **Fault Tolerance:** Messages are stored until they are successfully processed.
      `,
      image: "/assets/images/zero-flow/message-queueing.webp"
    },
  
    "Scalable Database Solutions": {
      title: "Scalable Database Solutions",
      description: `
        Implements scalable databases such as PostgreSQL and MongoDB to manage large volumes of transaction data efficiently.
  
        Key Features:
        **High Volume Data Handling:** Support for large volumes of transaction data.
        **Horizontal Scaling:** Scale databases horizontally to meet business needs.
        **SQL & NoSQL Support:** Combines the power of relational (PostgreSQL) and non-relational (MongoDB) databases.
      `,
      image: "/assets/images/zero-flow/scalable-databases.webp"
    },
  
    "API Rate Limiting & Throttling": {
      title: "API Rate Limiting & Throttling",
      description: `
        Ensures optimal performance and security by managing API request rates and preventing abuse through rate limiting and throttling mechanisms.
  
        Key Features:
        **Request Control:** Limit requests per user, IP, or API key.
        **DDoS Protection:** Protect systems from distributed denial-of-service attacks.
        **Performance Optimisation:** Maintain consistent performance even during high load.
      `,
      image: "/assets/images/zero-flow/api-rate-limiting.webp"
    },
  
    "Default": {
      title: "Default Title",
      description: "Content not available.",
      image: "/assets/images/default-image.webp"
    }
  };


  export const SecurityAndComplianceFeaturesDetails = {
    "PCI-DSS Certification": {
      title: "PCI-DSS Certification",
      description: `
        Complies with PCI-DSS standards, ensuring that cardholder data is stored, processed, and transmitted securely.
        
        Key Features:
        **Data Security:** Protects sensitive payment information from unauthorised access.
        **Audit & Compliance:** Undergoes regular audits to ensure continuous compliance.
        **Fraud Mitigation:** Reduces the risk of data breaches through strict compliance protocols.
      `,
      image: "/assets/images/zero-flow/pci-dss.webp"
    },
  
    "Data Encryption": {
      title: "Data Encryption",
      description: `
        Utilises advanced encryption protocols to secure payment data both in transit and at rest, mitigating the risk of data breaches.
        
        Key Features:
        **End-to-End Encryption:** Ensures complete encryption of sensitive data from origin to destination.
        **AES-256 Encryption:** Utilises advanced AES-256 encryption for strong data protection.
        **Data Breach Prevention:** Prevents unauthorised access to sensitive payment information.
      `,
      image: "/assets/images/zero-flow/data-encryption.webp"
    },
  
    "KYC/AML Compliance": {
      title: "KYC/AML Compliance",
      description: `
        Supports Know Your Customer (KYC) and Anti-Money Laundering (AML) protocols, ensuring user verification and transaction monitoring.
        
        Key Features:
        **Customer Verification:** Verifies customer identity to meet compliance requirements.
        **Risk Scoring:** Flags high-risk users and transactions for further review.
        **AML Transaction Monitoring:** Continuously tracks payments to detect money laundering activities.
      `,
      image: "/assets/images/zero-flow/kyc-aml-compliance.webp"
    },
  
    "Fraud Detection & Prevention": {
      title: "Fraud Detection & Prevention",
      description: `
        Implements real-time fraud detection, using AI and data-driven algorithms to block suspicious payment activities.
        
        Key Features:
        **AI-Powered Detection:** Uses AI and machine learning to identify suspicious patterns.
        **Real-Time Alerts:** Detects and notifies of fraudulent activity in real time.
        **Transaction Scoring:** Rates transactions to determine potential fraud risks.
      `,
      image: "/assets/images/zero-flow/fraud-detection.webp"
    },
  
    "Secure Data Storage": {
      title: "Secure Data Storage",
      description: `
        Ensures all sensitive data is stored in secure environments with regular security audits and compliance checks.
        
        Key Features:
        **Secure Storage:** Encrypts all data stored in the database and file systems.
        **Audit Trails:** Provides an auditable log of data access and modifications.
        **Access Restrictions:** Limits access to sensitive data to authorised personnel only.
      `,
      image: "/assets/images/zero-flow/secure-data-storage.webp"
    },
  
    "Access Control & Monitoring": {
      title: "Access Control & Monitoring",
      description: `
        Implements strict access controls and continuous monitoring to prevent unauthorised access and ensure system integrity.
        
        Key Features:
        **Role-Based Access:** Restricts access based on user roles and permissions.
        **Real-Time Monitoring:** Monitors system activity for potential security breaches.
        **Intrusion Detection:** Identifies and responds to unauthorised access attempts.
      `,
      image: "/assets/images/zero-flow/access-control.webp"
    },
  
    "Default": {
      title: "Default Title",
      description: "Content not available.",
      image: "/assets/images/default-image.webp"
    }
  };

  
  export const AnalyticsAndInsightsFeaturesDetails = {
    "Real-Time Dashboard": {
      title: "Real-Time Dashboard",
      description: `
        Provides a live dashboard that tracks payment activity, revenue, refund rates, and customer payment behaviour in real time.
        
        Key Features:
        **Live Tracking:** View payments, refunds, and customer activity as it happens.
        **Revenue Monitoring:** Track daily, weekly, and monthly revenue streams.
        **User Insights:** Understand customer payment patterns and behaviours.
      `,
      image: "/assets/images/zero-flow/realtime-dashboard.webp"
    },
  
    "Customisable Reports": {
      title: "Customisable Reports",
      description: `
        Generate detailed reports for transaction history, settlement reconciliation, and chargeback analysis.
        
        Key Features:
        **On-Demand Reports:** Generate custom reports on demand.
        **Settlement Reconciliation:** Ensure all settlements match payment data.
        **Chargeback Analysis:** Monitor chargeback reasons and dispute outcomes.
      `,
      image: "/assets/images/zero-flow/custom-reports.webp"
    },
  
    "Revenue Insights": {
      title: "Revenue Insights",
      description: `
        Gain visibility into business performance, with insights into revenue streams, growth patterns, and payment success rates.
        
        Key Features:
        **Revenue Breakdown:** Identify which products, services, or payment methods generate the most revenue.
        **Growth Patterns:** See revenue growth trends over specific periods.
        **Payment Success Rates:** Track and optimise payment completion rates.
      `,
      image: "/assets/images/zero-flow/revenue-insights.webp"
    },
  
    "Refund & Chargeback Monitoring": {
      title: "Refund & Chargeback Monitoring",
      description: `
        Monitor refund requests and chargeback activity to identify patterns, reduce risk, and improve dispute resolution strategies.
        
        Key Features:
        **Refund Requests:** Track refund rates and customer refund requests.
        **Chargeback Analysis:** Identify patterns to reduce the likelihood of chargebacks.
        **Dispute Resolution:** Improve dispute handling and reduce operational impact.
      `,
      image: "/assets/images/zero-flow/chargeback-monitoring.webp"
    },
  
    "Customer Segmentation": {
      title: "Customer Segmentation",
      description: `
        Analyze customer data to segment users based on payment behavior, preferences, and transaction history for targeted marketing.
        
        Key Features:
        **Customer Insights:** Identify customer preferences and payment trends.
        **Segmented Targeting:** Tailor marketing and promotions to different customer segments.
        **Personalised Experiences:** Create personalised user experiences for better engagement.
      `,
      image: "/assets/images/zero-flow/customer-segmentation.webp"
    },
  
    "Predictive Analytics": {
      title: "Predictive Analytics",
      description: `
        Utilise machine learning to forecast trends, identify potential issues before they arise, and make data-driven strategic decisions.
        
        Key Features:
        **Trend Forecasting:** Predict revenue, payments, and user activity.
        **Proactive Issue Detection:** Identify potential issues before they impact revenue.
        **AI-Driven Insights:** Use machine learning to discover valuable insights.
      `,
      image: "/assets/images/zero-flow/predictive-analytics.webp"
    },
  
    "Default": {
      title: "Default Title",
      description: "Content not available.",
      image: "/assets/images/default-image.webp"
    }
  };

 
  export const UseCasesFeaturesDetails = {
    "E-commerce Payments": {
      title: "E-commerce Payments",
      description: `
        Enable e-commerce stores to accept online payments, offering a seamless, secure, and fast checkout experience.
        
        Key Features:
        **Seamless Checkout:** Streamlined payment experience for customers.
        **Multiple Payment Options:** Support for cards, digital wallets, and alternative payment methods.
        **Fraud Prevention:** Integrated security to detect and block fraudulent transactions.
      `,
      image: "/assets/images/zero-flow/ecommerce-payments.webp"
    },
  
    "Subscription Billing": {
      title: "Subscription Billing",
      description: `
        Support subscription-based business models by automating billing cycles and managing recurring payments for customers.
        
        Key Features:
        **Automated Billing:** Schedule and automate recurring payments.
        **Flexible Plans:** Offer customers multiple subscription tiers and billing cycles.
        **Churn Management:** Reduce subscription cancellations with automatic retries and dunning management.
      `,
      image: "/assets/images/zero-flow/subscription-billing.webp"
    },
  
    "International Money Transfers": {
      title: "International Money Transfers",
      description: `
        Provide international freelancers and contractors with faster, more affordable cross-border payment solutions.
        
        Key Features:
        **Fast Cross-Border Payments:** Faster payouts for freelancers and contractors.
        **Currency Conversion:** Automatically convert funds to local currencies at real-time rates.
        **Reduced Fees:** Lower fees compared to traditional SWIFT transfers.
      `,
      image: "/assets/images/zero-flow/international-payments.webp"
    },
  
    "Marketplace Payments": {
      title: "Marketplace Payments",
      description: `
        Enable payouts for marketplace vendors with split payments, escrow, and automated settlements.
        
        Key Features:
        **Split Payments:** Automatically divide payments between marketplace owners and vendors.
        **Escrow Services:** Hold funds in escrow until conditions are met.
        **Automated Settlements:** Automatically release payments on successful order completion.
      `,
      image: "/assets/images/zero-flow/marketplace-payments.webp"
    },
  
    "Mobile Payments": {
      title: "Mobile Payments",
      description: `
        Facilitate payments through mobile devices, offering a convenient and secure option for on-the-go transactions.
        
        Key Features:
        **Mobile Wallet Integration:** Support for Google Pay, Apple Pay, and other mobile wallets.
        **Contactless Payments:** Enable NFC-based tap-and-pay transactions.
        **Mobile-Optimises UX:** Ensure fast, user-friendly payment experiences for mobile users.
      `,
      image: "/assets/images/zero-flow/mobile-payments.webp"
    },
  
    "Point of Sale (POS) Systems": {
      title: "Point of Sale (POS) Systems",
      description: `
        Integrate with POS systems to streamline in-store transactions, inventory management, and sales reporting.
        
        Key Features:
        **POS Integration:** Connect to leading POS systems for seamless transactions.
        **Inventory Management:** Sync sales and inventory data in real time.
        **Sales Reporting:** Track store performance with analytics and reporting tools.
      `,
      image: "/assets/images/zero-flow/pos-systems.webp"
    },
  
    "Default": {
      title: "Default Title",
      description: "Content not available.",
      image: "/assets/images/default-image.webp"
    }
  };
  

  export const DeveloperToolsFeaturesDetails = {
    "API-First Approach": {
      title: "API-First Approach",
      description: `
        Zero-Flow's API-first design allows developers to build and customise payment workflows, integrate payment processing, and more.
        
        Key Features:
        **Custom Payment Flows:** Full control over the payment flow for bespoke solutions.
        **Modular Design:** Add, remove, or modify payment functionality as required.
        **Developer Flexibility:** Build for web, mobile, and server-side applications.
      `,
      image: "/assets/images/zero-flow/api-first-approach.webp"
    },
  
    "Webhook Support": {
      title: "Webhook Support",
      description: `
        Developers can use webhooks to receive updates on payment events like settlements, payment status, and chargeback notifications.
        
        Key Features:
        **Real-Time Notifications:** Get immediate updates for important payment events.
        **Custom Event Triggers:** Configure specific webhook triggers for settlements, refunds, and payment failures.
        **Enhanced Monitoring:** Stay informed of transaction changes to improve customer experience.
      `,
      image: "/assets/images/zero-flow/webhook-support.webp"
    },
  
    "Custom Checkout": {
      title: "Custom Checkout",
      description: `
        Developers can create customises checkout pages with full control over branding, payment flow, and user experience.
        
        Key Features:
        **Branded Checkout:** Full control over colors, fonts, and layouts.
        **UX Customisation:** Modify the user experience to increase conversion rates.
        **Multi-Payment Support:** Support for multiple payment methods on a single page.
      `,
      image: "/assets/images/zero-flow/custom-checkout.webp"
    },
  
    "Extensive API Documentation": {
      title: "Extensive API Documentation",
      description: `
        Access detailed API documentation, including integration guides and best practices for connecting to the Zero-Flow payment platform.
        
        Key Features:
        **Comprehensive Guides:** Step-by-step instructions for seamless integration.
        **Code Samples:** Ready-to-use code snippets in multiple programming languages.
        **Best Practices:** Ensure efficiency and security when building payment flows.
      `,
      image: "/assets/images/zero-flow/api-docs.webp"
    },
  
    "SDK Libraries": {
      title: "SDK Libraries",
      description: `
        Utilise SDKs available for multiple programming languages to accelerate development and ensure seamless integration.
        
        Key Features:
        **Multi-Language Support:** SDKs for JavaScript, Python, PHP, and more.
        **Plug-and-Play Integration:** Simple import and use for faster development.
        **Version Control:** Regular updates to ensure compatibility and security.
      `,
      image: "/assets/images/zero-flow/sdk-libraries.webp"
    },
  
    "Sandbox Environment": {
      title: "Sandbox Environment",
      description: `
        Test integrations in a safe sandbox environment before going live, ensuring functionality and reliability without impacting real transactions.
        
        Key Features:
        **Safe Testing:** Simulate payments, refunds, and API calls.
        **Debugging Tools:** Identify and resolve issues before production.
        **Live-Like Environment:** Replicates live transaction behavior for realistic testing.
      `,
      image: "/assets/images/zero-flow/sandbox-environment.webp"
    },
  
    "Default": {
      title: "Default Title",
      description: "Content not available.",
      image: "/assets/images/default-image.webp"
    }
  };
  