export const CoreFeatureDetails = {
    "AI-Powered Trading Engine": {
      title: "AI-Powered Trading Engine",
      description: `
        Revolutionise your trading strategy with an advanced AI-powered trading engine that identifies profitable opportunities using machine learning algorithms.
        
        **Key Features:**
        **Pattern Recognition:** AI models detect price patterns, market anomalies, and sentiment shifts.
        **Predictive Forecasting:** Machine learning forecasts price movements and volatility.
        **Autonomous Trading:** Execute buy/sell orders automatically without manual intervention.
        **Data-Driven Insights:** Analyze historical and real-time data for smarter decision-making.
      `,
      image: "/assets/images/zero-trade/ai-trading-engine.webp"
    },
  
    "Automated Trade Execution": {
      title: "Automated Trade Execution",
      description: `
        Instantly execute trades with millisecond precision, allowing high-frequency trading (HFT) and rapid response to market changes.
        
        **Key Features:**
        **High-Frequency Trading (HFT):** Millisecond-level precision for rapid market entry/exit.
        **Multi-Market Execution:** Execute trades across multiple exchanges simultaneously.
        **Order Prioritisation:** Prioritize buy/sell orders to optimise trade execution time.
        **Reduced Slippage:** Mitigate price slippage for optimal trade outcomes.
      `,
      image: "/assets/images/zero-trade/automated-trade-execution.webp"
    },
  
    "Customisable Trading Logic": {
      title: "Customisable Trading Logic",
      description: `
        Empower users to design, backtest, and deploy their own trading algorithms, allowing full customisation and optimisation of trading strategies.
        
        **Key Features:**
        **Drag-and-Drop Algorithm Builder:** Easily create custom trading logic without coding.
        **Backtesting Environment:** Test algorithms against historical data before going live.
        **Custom Indicators:** Create unique indicators that trigger trade conditions.
        **Algorithm Deployment:** Launch custom strategies directly on live markets.
      `,
      image: "/assets/images/zero-trade/custom-trading-logic.webp"
    },
  
    "Advanced Risk Management": {
      title: "Advanced Risk Management",
      description: `
        Stay in control with advanced risk management tools designed to limit exposure and safeguard against potential losses.
        
        **Key Features:**
        **Stop-Loss & Take-Profit:** Set automatic exit points to protect profits or limit losses.
        **Position Limits:** Restrict the maximum exposure for any given trade.
        **Volatility Alerts:** Receive alerts when market volatility exceeds pre-set thresholds.
        **Risk Analytics:** Use real-time data to identify and mitigate potential risks.
      `,
      image: "/assets/images/zero-trade/risk-management.webp"
    },
  
    "Multi-Asset Trading": {
      title: "Multi-Asset Trading",
      description: `
        Trade a variety of asset classes, including stocks, forex, cryptocurrencies, and commodities, from one unified platform.
        
        **Key Features:**
        **Cross-Asset Trading:** Trade across diverse markets like forex, stocks, and crypto.
        **Unified Portfolio View:** View all asset classes in a single, easy-to-read dashboard.
        **Market Diversification:** Minimise risk through multi-asset investment strategies.
        **Seamless Switching:** Switch between asset classes instantly during market shifts.
      `,
      image: "/assets/images/zero-trade/multi-asset-trading.webp"
    },
  
    "White-Label Capabilities": {
      title: "White-Label Capabilities",
      description: `
        Launch your own branded trading platform using Zero-Trade's white-label technology for faster go-to-market time.
        
        **Key Features:**
        **Custom Branding:** Replace Zero-Trade branding with your company's logo and brand colors.
        **Full Platform Customisation:** Customise UI/UX elements, features, and trading logic.
        **Turnkey Solution:** Get a market-ready trading platform with minimal development time.
        **API Access:** Integrate Zero-Trade&apos;s core engine into existing fintech applications.
      `,
      image: "/assets/images/zero-trade/white-label-capabilities.webp"
    }
  };

  export const TechnicalCapabilitiesDetails = {
    "Microservices Architecture": {
      title: "Microservices Architecture",
      description: `
        Achieve unparalleled flexibility and scalability with Zero-Trade's modular microservices architecture.
        
        **Key Features:**
        **Service Independence:** Each core function runs independently, enabling isolated upgrades and fault-tolerant operations.
        **Seamless Scaling:** Scale individual services independently to handle peak loads or growing demand.
        **Faster Deployments:** Deploy updates or hotfixes without affecting the overall system.
        **High Resilience:** Reduce system failure risks with independent service nodes.
      `,
      image: "/assets/images/zero-trade/microservices-architecture.webp"
    },
  
    "Cloud-Native Infrastructure": {
      title: "Cloud-Native Infrastructure",
      description: `
        Deploy globally on AWS, Azure, or Google Cloud for maximum availability, fault tolerance, and disaster recovery.
        
        **Key Features:**
        **Global Availability:** Deploy Zero-Trade across multiple data centers worldwide.
        **Disaster Recovery:** High availability with failover systems to prevent downtime.
        **Auto-Scaling:** Automatically scale up resources to handle high-volume traffic and large trading spikes.
        **Cloud Agnostic:** Leverage support for AWS, Azure, and GCP for infrastructure flexibility.
      `,
      image: "/assets/images/zero-trade/cloud-native-infrastructure.webp"
    },
  
    "High-Frequency Trading (HFT) Capabilities": {
      title: "High-Frequency Trading (HFT) Capabilities",
      description: `
        Gain a competitive edge with Zero-Trade’s high-frequency trading (HFT) engine, enabling millisecond trade execution.
        
        **Key Features:**
        **Millisecond Trade Execution:** Capitalise on market micro-fluctuations for profit maximisation.
        **Ultra-Low Latency:** Execute trades with ultra-low latency, ensuring the fastest order placement.
        **Multi-Market HFT:** Simultaneously trade across multiple exchanges and asset classes.
        **Arbitrage Trading:** Take advantage of cross-exchange price differences with high-speed arbitrage opportunities.
      `,
      image: "/assets/images/zero-trade/hft-capabilities.webp"
    },
  
    "API-First Approach": {
      title: "API-First Approach",
      description: `
        Integrate Zero-Trade's trading engine into your fintech app, trading bot, or hedge fund platform using its API-first approach.
        
        **Key Features:**
        **Comprehensive API Access:** Access the core functions of the Zero-Trade engine, including trading logic, signal generation, and market data.
        **Custom Integration:** Embed Zero-Trade within existing fintech, hedge fund, or brokerage platforms.
        **Secure API Gateway:** All API endpoints are encrypted, authenticated, and rate-limited for security.
        **Webhook Support:** Enable event-driven execution with webhook alerts for market changes.
      `,
      image: "/assets/images/zero-trade/api-first-approach.webp"
    },
  
    "AI-Powered Signal Generation": {
      title: "AI-Powered Signal Generation",
      description: `
        Leverage machine learning models to detect market signals and generate precise buy, sell, and hold recommendations.
        
        **Key Features:**
        **Data-Driven Predictions:** AI models analyze live and historical market data for real-time signal generation.
        **Sentiment Analysis:** Process social media, news, and market sentiment to detect market shifts.
        **Actionable Alerts:** Get real-time buy, sell, or hold alerts based on advanced AI predictions.
        **Self-Learning Models:** Models continuously learn from market changes, improving predictions over time.
      `,
      image: "/assets/images/zero-trade/ai-signal-generation.webp"
    },
  
    "Dockerised Services": {
      title: "Dockerised Services",
      description: `
        Streamline deployments and achieve full development parity with Dockerised services for Zero-Trade.
        
        **Key Features:**
        **Rapid Deployments:** Deploy new containers instantly without downtime.
        **Consistent Environments:** Maintain identical development, testing, and production environments.
        **CI/CD Integration:** Integrate with CI/CD pipelines for smooth continuous deployments.
        **Portability:** Deploy on any cloud provider or on-premises system with Docker compatibility.
      `,
      image: "/assets/images/zero-trade/dockerised-services.webp"
    }
  };


export const KeyBenefitsDetails = {
  "Profit Optimisation": {
    title: "Profit Optimisation",
    description: `
      Achieve higher returns by leveraging AI-driven strategies that capitalise on micro-price fluctuations and real-time market shifts.
      
      **Key Features:**
      **AI-Driven Trade Insights:** Identify profit opportunities using advanced machine learning models trained on live market data.
      **Micro-Price Fluctuations:** Exploit small changes in asset prices to generate consistent profits, particularly in high-frequency trading.
      **Dynamic Strategy Adjustment:** Adapt to sudden market shifts in real time to capture profit-maximising opportunities.
      **Real-Time Alerts:** Receive actionable insights and alerts to optimise profit margins on ongoing trades.
    `,
    image: "/assets/images/zero-trade/profit-optimisation.webp"
  },

  "Faster Trade Execution": {
    title: "Faster Trade Execution",
    description: `
      Stay ahead of competitors with millisecond trade execution, enabling ultra-fast order placement in volatile markets.
      
      **Key Features:**
      **Millisecond Precision:** Execute trades within milliseconds, crucial for high-frequency trading (HFT) strategies.
      **Low Latency Connectivity:** Enjoy near-instant connectivity with financial exchanges, ensuring rapid order fulfillment.
      **Priority Order Placement:** Ensure your trades are prioritized over slower market participants.
      **Cross-Market Speed:** Speed across multiple asset classes, including crypto, forex, and equities, for simultaneous execution.
    `,
    image: "/assets/images/zero-trade/faster-trade-execution.webp"
  },

  "Reduced Human Error": {
    title: "Reduced Human Error",
    description: `
      Eliminate human errors caused by emotional decision-making, delays, or manual mistakes through automated AI-powered systems.
      
      **Key Features:**
      **Automated Execution:** Trade execution is automated, eliminating the chance of human input errors.
      **Elimination of Emotional Bias:** Avoid emotional decision-making by relying on data-driven, AI-generated signals.
      **Pre-Configured Strategies:** Create, test, and automate trading logic to ensure consistent, reliable trade execution.
      **Minimised Risk of Mistakes:** Remove manual input errors and achieve higher trade accuracy.
    `,
    image: "/assets/images/zero-trade/reduced-human-error.webp"
  },

  "Access to Multiple Markets": {
    title: "Access to Multiple Markets",
    description: `
      Trade multiple asset classes, from traditional stocks to modern digital assets, all within one unified platform.
      
      **Key Features:**
      **Multi-Asset Support:** Trade stocks, forex, cryptocurrencies, commodities, and indices from one account.
      **Diversified Trading Opportunities:** Diversify investments across asset classes to spread risk and increase returns.
      **Cross-Market Arbitrage:** Identify arbitrage opportunities between multiple exchanges and asset classes.
      **Unified Trading Interface:** Access multiple asset classes via one simple, intuitive dashboard.
    `,
    image: "/assets/images/zero-trade/multi-market-access.webp"
  },

  "Customisable Trading Logic": {
    title: "Customisable Trading Logic",
    description: `
      Build and customise your own trading logic, enabling the creation of unique strategies to suit individual investment goals.
      
      **Key Features:**
      **Strategy Builder:** Drag-and-drop logic builder for creating custom AI-driven trading strategies.
      **Backtesting Engine:** Test custom strategies against historical market data to improve profitability.
      **No-Code Customisation:** Users can configure strategies without needing to write complex code.
      **Adaptive AI Logic:** Continuously refine and improve trading logic based on live market feedback.
    `,
    image: "/assets/images/zero-trade/custom-trading-logic.webp"
  },

  "Enhanced Portfolio Management": {
    title: "Enhanced Portfolio Management",
    description: `
      View, track, and manage your trading portfolios in one unified, customisable dashboard.
      
      **Key Features:**
      **Portfolio Dashboard:** Access a single view of all your investments, trades, and portfolios.
      **Real-Time Portfolio Tracking:** Monitor live performance metrics, including total returns, P&L, and allocation data.
      **Customisable Widgets:** Personalise the dashboard to display only the metrics and data that matter most to you.
      **Multi-Portfolio View:** View and manage multiple portfolios from one dashboard, reducing complexity.
    `,
    image: "/assets/images/zero-trade/portfolio-management.webp"
  }
};

  export const UseCasesDetails = {
    "Day Trading": {
      title: "Day Trading",
      description: `
        Maximise daily returns by taking advantage of intraday price movements with AI-driven predictive logic.
        
        **Key Features:**
        **Real-Time Trade Signals:** AI identifies short-term trading opportunities and sends buy/sell signals instantly.
        **AI-Driven Scalping:** Utilise rapid buy/sell orders to take advantage of micro-fluctuations in asset prices.
        **Volatility Analysis:** Track and analyze daily volatility to predict profitable entry and exit points.
        **Profit Targeting:** Set profit targets for intraday trading, allowing automatic exit once targets are achieved.
      `,
      image: "/assets/images/zero-trade/day-trading.webp"
    },
  
    "Portfolio Diversification": {
      title: "Portfolio Diversification",
      description: `
        Build a diversified investment strategy using multiple asset classes, including stocks, crypto, and forex.
        
        **Key Features:**
        **Multi-Asset Diversification:** Trade a mix of asset classes, from equities to crypto, for a balanced risk profile.
        **AI-Driven Recommendations:** AI suggests diversification strategies to minimise risk and increase returns.
        **Custom Asset Allocation:** Personalise asset allocation ratios for optimal diversification.
        **Portfolio Rebalancing:** Automated portfolio rebalancing based on risk exposure and market shifts.
      `,
      image: "/assets/images/zero-trade/portfolio-diversification.webp"
    },
  
    "Algorithmic Trading": {
      title: "Algorithmic Trading",
      description: `
        Build, test, and deploy custom AI-powered algorithms to automate complex, rule-based trading strategies.
        
        **Key Features:**
        **Customisable Algo Builder:** Design rule-based trading logic, from simple conditions to advanced multi-step logic.
        **Backtesting Engine:** Test strategies on historical market data to assess profitability before live deployment.
        **Real-Time Deployment:** Launch live algorithms to execute trades 24/7 with minimal human intervention.
        **Strategy Optimisation:** Use AI to enhance and refine algorithmic performance with continuous learning.
      `,
      image: "/assets/images/zero-trade/algorithmic-trading.webp"
    },
  
    "Hedge Fund Automation": {
      title: "Hedge Fund Automation",
      description: `
        Automate hedge fund operations by integrating Zero-Trade's AI engine to support large-scale, high-frequency trading.
        
        **Key Features:**
        **High-Frequency Trading (HFT) Capabilities:** Execute thousands of trades per second using AI-generated signals.
        **Bulk Trade Execution:** Process large-scale order execution with split-order functionality to minimise market impact.
        **Multi-Fund Management:** Manage multiple funds simultaneously, with separate strategies and reporting for each.
        **Institutional API Access:** Direct API access for hedge funds to customise AI-driven trading logic.
      `,
      image: "/assets/images/zero-trade/hedge-fund-automation.webp"
    },
  
    "Cross-Asset Arbitrage": {
      title: "Cross-Asset Arbitrage",
      description: `
        Generate risk-free profits by capitalising on price differences between assets across multiple markets and exchanges.
        
        **Key Features:**
        **Real-Time Arbitrage Signals:** Identify arbitrage opportunities in crypto, forex, and commodity markets.
        **Multi-Asset Arbitrage:** Simultaneously track prices of stocks, crypto, forex, and indices to detect profitable discrepancies.
        **Instant Trade Execution:** Execute arbitrage trades instantly to lock in price differences before market corrections.
        **Zero-Risk Profit Strategy:** Exploit price inefficiencies across exchanges with minimal market exposure.
      `,
      image: "/assets/images/zero-trade/cross-asset-arbitrage.webp"
    },
  
    "Copy Trading": {
      title: "Copy Trading",
      description: `
        Empower users to copy the successful trading strategies of expert traders in real time.
        
        **Key Features:**
        **Live Trade Mirroring:** Replicate expert traders' strategies automatically as they execute live trades.
        **Expert Trader Marketplace:** Browse and follow top-performing traders with transparent performance metrics.
        **Custom Copy Parameters:** Users can set copy parameters, such as risk exposure and trade limits.
        **Stop-Loss & Risk Management:** Protect users from excessive losses by enabling stop-loss limits and automatic risk controls.
      `,
      image: "/assets/images/zero-trade/copy-trading.webp"
    }
  };

  export const SecurityAndComplianceDetails = {
    "Data Encryption (AES-256)": {
      title: "Data Encryption (AES-256)",
      description: `
        Protect sensitive user data and trading information with the industry's gold standard encryption—AES-256.
        
        **Key Features:**
        **End-to-End Encryption:** All data in transit and at rest is encrypted using AES-256, ensuring complete security.
        **Data Privacy Compliance:** Compliance with GDPR, CCPA, and other data privacy regulations to protect personal information.
        **Mitigation of Data Breaches:** Encryption prevents unauthorised access even if data is intercepted.
        **Secure Transaction Logs:** All transaction records and user actions are encrypted to prevent tampering and ensure auditability.
      `,
      image: "/assets/images/zero-trade/aes-encryption.webp"
    },
  
    "AML & KYC Compliance": {
      title: "AML & KYC Compliance",
      description: `
        Maintain platform integrity with international Anti-Money Laundering (AML) and Know-Your-Customer (KYC) compliance.
        
        **Key Features:**
        **Identity Verification:** Validate users' identities before they access the platform, ensuring regulatory compliance.
        **Suspicious Activity Detection:** Real-time detection and alerts for unusual activities, supporting AML compliance.
        **Regulatory Adherence:** Full compliance with FATF, FinCEN, and global AML laws.
        **Automated Risk Assessment:** Utilise AI to evaluate users' risk levels during onboarding and flag high-risk profiles.
      `,
      image: "/assets/images/zero-trade/aml-kyc-compliance.webp"
    },
  
    "2FA & Role-Based Access Control": {
      title: "2FA & Role-Based Access Control",
      description: `
        Safeguard user accounts and system resources with multi-layered security and role-based access control.
        
        **Key Features:**
        **Two-Factor Authentication (2FA):** Users must verify their identity using SMS, email, or authenticator apps.
        **Role-Based Permissions:** Set access control limits for admin, trader, and analyst roles to ensure secure data access.
        **Privileged Account Monitoring:** Track and audit privileged user actions for regulatory and compliance purposes.
        **Access Logging:** Create detailed logs of all login attempts and access events for later review.
      `,
      image: "/assets/images/zero-trade/2fa-access-control.webp"
    },
  
    "Audit Trails & Logging": {
      title: "Audit Trails & Logging",
      description: `
        Ensure transparency and accountability with comprehensive logging of all platform actions.
        
        **Key Features:**
        **Immutable Audit Logs:** Record every user action, login, trade, and system event in an immutable log.
        **Compliance Reporting:** Generate audit reports for regulators and internal compliance reviews.
        **Fraud Investigation:** Use activity logs to trace suspicious user activity for investigation and resolution.
        **Regulatory Compliance:** Comply with international regulations that require complete traceability of platform actions.
      `,
      image: "/assets/images/zero-trade/audit-trails.webp"
    },
  
    "Penetration Testing": {
      title: "Penetration Testing",
      description: `
        Identify and resolve vulnerabilities in the Zero-Trade platform before they can be exploited.
        
        **Key Features:**
        **Regular Security Assessments:** Ongoing penetration tests to identify weak points and reinforce security.
        **Vulnerability Management:** Prioritize and fix vulnerabilities based on criticality and exploitability.
        **Red Team Simulations:** Conduct simulated attacks to mimic real-world threats and improve incident response.
        **Penetration Test Reports:** Generate reports for regulators, investors, and stakeholders to demonstrate security strength.
      `,
      image: "/assets/images/zero-trade/penetration-testing.webp"
    },
  
    "GDPR Compliance": {
      title: "GDPR Compliance",
      description: `
        Stay compliant with the General Data Protection Regulation (GDPR) to protect user privacy and avoid costly fines.
        
        **Key Features:**
        **Data Privacy by Design:** Adopt privacy principles into the design and development of the platform.
        **User Consent Management:** Ensure users can provide, review, and revoke consent for data processing.
        **Data Subject Rights:** Allow users to exercise their rights to data access, correction, deletion, and portability.
        **Breach Notification Protocols:** Comply with GDPR’s 72-hour breach notification requirements to inform affected users.
      `,
      image: "/assets/images/zero-trade/gdpr-compliance.webp"
    }
  }; 


  export const BusinessBenefitsDetails = {
    "Revenue Growth from AI Trading Fees": {
      title: "Revenue Growth from AI Trading Fees",
      description: `
        **Drive Consistent Revenue Growth with AI-Powered Trading Tools**
        
        Zero-Trade enables businesses to monetise advanced AI-driven trading tools, generating consistent revenue streams from diverse sources.
        
        **Key Features:**
        **Transaction Fees:** Collect a percentage of every trade executed on the platform.
        **Subscription Plans:** Offer tiered subscription models for premium AI-driven analysis and strategy development tools.
        **Premium Access:** Generate revenue from exclusive access to cutting-edge AI features, including predictive market analysis and signal generation.
      `,
      image: "/assets/images/zero-trade/revenue-growth.webp"
    },
  
    "Scalability with White-Label Solutions": {
      title: "Scalability with White-Label Solutions",
      description: `
        **Expand Your Brand and Product Portfolio with White-Label Capabilities**
        
        monetise Zero-Trade’s white-label capabilities, allowing institutions to create and brand their own AI-driven trading platforms.
        
        **Key Features:**
        **Custom Branding:** Launch fully branded trading platforms under your own name with Zero-Trade’s infrastructure.
        **Pre-Built Modules:** Reduce development costs and time-to-market with pre-built, ready-to-deploy trading modules.
        **Revenue Sharing Models:** Offer revenue-sharing arrangements for white-label clients, enabling long-term business partnerships.
      `,
      image: "/assets/images/zero-trade/white-label-scalability.webp"
    },
  
    "Reduced Operational Costs": {
      title: "Reduced Operational Costs",
      description: `
        **Reduce Costs by Automating Trade Execution and Minimising Manual Intervention**
        
        Zero-Trade automates key trading processes, significantly reducing operational costs for hedge funds, banks, and fintech firms.
        
        **Key Features:**
        **Automated Trade Execution:** Execute trades automatically, reducing the need for manual intervention.
        **Reduced Labor Costs:** Lower staffing costs by replacing manual processes with AI-driven automation.
        **Scalable Operations:** Scale trading operations across multiple asset classes without increasing operational overhead.
      `,
      image: "/assets/images/zero-trade/reduced-costs.webp"
    },
  
    "Faster Time-to-Market": {
      title: "Faster Time-to-Market",
      description: `
        **Accelerate Product Launches with Zero-Trade’s API-First Approach**
        
        Launch fintech trading products faster with Zero-Trade’s API-driven architecture, enabling seamless integration and customisation.
        
        **Key Features:**
        **API-First Integration:** Integrate trading functionality directly into existing apps and platforms.
        **Pre-Built Trading Features:** Leverage a library of pre-built components to reduce development time.
        **Customisable UI/UX:** Build and customise the user experience to meet the specific needs of clients and users.
      `,
      image: "/assets/images/zero-trade/time-to-market.webp"
    },
  
    "Increased Customer Retention": {
      title: "Increased Customer Retention",
      description: `
        **Boost Customer Retention with Customisable Bots and AI-Driven Insights**
        
        Retain customers with a personalised, user-centric experience that delivers value through fast execution, intuitive interfaces, and customised bots.
        
        **Key Features:**
        **AI-Driven Insights:** Offer personalised insights and performance dashboards to keep users engaged.
        **Custom Trading Bots:** Allow users to design and deploy their own trading bots, ensuring continuous engagement.
        **Gamified Experiences:** Introduce gamification elements, such as leaderboards and social trading, to boost user retention.
      `,
      image: "/assets/images/zero-trade/customer-retention.webp"
    },
  
    "Revenue from API Access": {
      title: "Revenue from API Access",
      description: `
        **monetise Zero-Trade’s Powerful API for External Integrations**
        
        License API access to fintech apps, trading bots, and institutional platforms, generating a new revenue stream from API monetisation.
        
        **Key Features:**
        **API Licensing:** License access to Zero-Trade's powerful trading engine and execution tools.
        **Subscription Revenue:** Generate recurring revenue by offering subscription-based API access.
        **Third-Party Integration:** Allow fintech platforms and hedge funds to integrate advanced trading features into their own products.
      `,
      image: "/assets/images/zero-trade/api-access-revenue.webp"
    }
  };

  export const FutureEnhancementsDetails = {
    "AI Model Upgrades": {
      title: "AI Model Upgrades",
      description: `
        **Enhance Market Accuracy with Advanced AI Models**
        
        Zero-Trade will introduce advanced AI models to improve the accuracy of market predictions, trading signals, and real-time price forecasting.
        
        **Key Features:**
        **Enhanced Predictive Accuracy:** Improved machine learning models will deliver more precise buy/sell signals.
        **Continuous Learning:** AI models will learn from real-time market fluctuations and user behaviors to refine performance.
        **Signal Customisation:** Users will have the ability to customise AI signal settings for personalised trading strategies.
      `,
      image: "/assets/images/zero-trade/ai-model-upgrades.webp"
    },
  
    "DeFi Trading Integration": {
      title: "DeFi Trading Integration",
      description: `
        **Seamless Trading on Decentralised Exchanges (DEXs)**
        
        Zero-Trade will integrate with DeFi protocols, enabling users to trade on decentralised exchanges (DEXs) and access liquidity pools.
        
        **Key Features:**
        **On-Chain Trading:** Users can trade directly on DEXs like Uniswap, PancakeSwap, and SushiSwap.
        **Liquidity Pool Access:** Access liquidity pools for faster execution and reduced slippage.
        **DeFi Yield Opportunities:** Participate in liquidity mining and yield farming while trading.
      `,
      image: "/assets/images/zero-trade/defi-integration.webp"
    },
  
    "Multi-Language Support": {
      title: "Multi-Language Support",
      description: `
        **Expand Global Reach with Multi-Language Capabilities**
        
        Multi-language support will broaden Zero-Trade's user base, enabling expansion into new global markets and regions.
        
        **Key Features:**
        **Multi-Language User Interface:** Support for multiple languages like Spanish, French, Chinese, and more.
        **Regional Compliance:** Customisation of content to meet local regulatory and financial compliance requirements.
        **Language-Specific Documentation:** Localised help docs, FAQs, and tutorials for users in different countries.
      `,
      image: "/assets/images/zero-trade/multi-language-support.webp"
    },
  
    "Voice-Controlled Trading": {
      title: "Voice-Controlled Trading",
      description: `
        **Revolutionise Trading with Voice-Activated Commands**
        
        Zero-Trade will introduce voice-controlled trading, allowing users to execute trades and navigate the dashboard with simple voice commands.
        
        **Key Features:**
        **AI Voice Recognition:** Execute trades, open dashboards, and check analytics using voice commands.
        **Natural Language Processing (NLP):** Voice assistant will understand natural language commands like "Buy Bitcoin" or "View Portfolio."
        **Hands-Free Trading:** Enables hands-free trading, ideal for users on the move or in multi-tasking scenarios.
      `,
      image: "/assets/images/zero-trade/voice-trading.webp"
    },
  
    "NFT and Tokenised Asset Trading": {
      title: "NFT and Tokenised Asset Trading",
      description: `
        **Enable NFT and Tokenised Asset Trading on One Platform**
        
        Zero-Trade will support trading of tokenised assets and NFTs, empowering users to participate in emerging Web3 markets.
        
        **Key Features:**
        **NFT Marketplace Access:** Users can trade NFTs directly within the platform.
        **Tokenised Asset Support:** Trade fractional ownership of real estate, fine art, and tokenised investment vehicles.
        **Seamless Asset Swaps:** Enable asset swaps between traditional financial instruments and Web3 assets.
      `,
      image: "/assets/images/zero-trade/nft-trading.webp"
    },
  
    "Quantum Trading Models": {
      title: "Quantum Trading Models",
      description: `
        **Leverage Quantum Computing for Ultra-Fast Market Simulations**
        
        Zero-Trade will integrate quantum trading models to deliver ultra-fast simulations, enabling unparalleled precision in trade execution.
        
        **Key Features:**
        **Quantum Speed Advantage:** Harness the computational power of quantum computing for faster, more precise market analysis.
        **Advanced Trade Simulations:** Run complex financial simulations to predict market scenarios and optimise trading decisions.
        **High-Precision Execution:** Execute trades at microsecond speeds, enabling high-frequency trading at unprecedented performance levels.
      `,
      image: "/assets/images/zero-trade/quantum-trading-models.webp"
    }
  };