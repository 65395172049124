export const CoreFeatureDetails = {
  "Compliance Workflow Automation": {
    title: "Compliance Workflow Automation",
    description: `
      Automate manual compliance processes with smart workflows that trigger tasks based on policy updates or regulatory changes.
      
      **Key Features:**
      **Smart Triggers:** Activate workflows automatically when compliance changes occur.
      **Task Automation:** Reduce manual effort with automated task generation.
      **Dynamic Updates:** Update workflows in real-time as regulatory frameworks evolve.
    `,
    image: "/assets/images/zero-comply/workflow-automation.webp"
  },

  "Predictive Analytics": {
    title: "Predictive Analytics",
    description: `
      AI-driven analytics predict potential compliance breaches, enabling proactive decision-making to mitigate risks.
      
      **Key Features:**
      **AI-Driven Risk Assessment:** Identify potential compliance issues before they happen.
      **Data-Driven Insights:** Real-time analytics to inform decision-making.
      **Anomaly Detection:** Detect deviations from compliance norms.
    `,
    image: "/assets/images/zero-comply/predictive-analytics.webp"
  },

  "Real-Time Monitoring": {
    title: "Real-Time Monitoring",
    description: `
      24/7 monitoring of compliance risks, instantly alerting users of violations or anomalies in regulatory processes.
      
      **Key Features:**
      **Continuous Monitoring:** Round-the-clock oversight of compliance risks.
      **Instant Alerts:** Immediate notifications for anomalies or violations.
      **Live Dashboard:** Visualise risk status in real-time.
    `,
    image: "/assets/images/zero-comply/real-time-monitoring.webp"
  },

  "Audit-Ready Reports": {
    title: "Audit-Ready Reports",
    description: `
      Generate downloadable audit-ready reports that meet global compliance standards, reducing audit preparation time.
      
      **Key Features:**
      **Instant Report Generation:** Create ready-to-submit audit reports.
      **Customisable Reports:** Tailor reports to meet jurisdictional standards.
      **Compliance Proof:** Showcase evidence of compliance with global regulations.
    `,
    image: "/assets/images/zero-comply/audit-reports.webp"
  },

  "Global Compliance Support": {
    title: "Global Compliance Support",
    description: `
      Stay compliant with global regulatory frameworks, including GDPR, MiFID, and HIPAA, across multiple jurisdictions.
      
      **Key Features:**
      **Multi-Jurisdiction Support:** Handle compliance in multiple regions.
      **GDPR, MiFID, HIPAA Compliance:** Supports key global frameworks.
      **Regulatory Updates:** Stay up-to-date with the latest changes in global regulations.
    `,
    image: "/assets/images/zero-comply/global-compliance.webp"
  },

  "Risk Management Tools": {
    title: "Risk Management Tools",
    description: `
      Identify, assess, and mitigate regulatory risks using advanced analytics, predictive algorithms, and role-based access controls.
      
      **Key Features:**
      **Risk Assessment:** Detect and assess risk factors impacting compliance.
      **Risk Mitigation:** Leverage predictive analytics to pre-emptively reduce risk.
      **User Access Control:** Use role-based access to restrict sensitive operations.
    `,
    image: "/assets/images/zero-comply/risk-management.webp"
  }
};

export const UserPersonasDetails = {
  "Compliance Officers": {
    title: "Compliance Officers",
    description: `
      Ensure organisational compliance with global laws and regulatory frameworks, leveraging Zero-Comply for real-time monitoring and reporting.
      
      **Key Features:**
      **Compliance Insights:** View real-time monitoring dashboards.
      **Workflow Automation:** Streamline compliance task management.
      **Reporting Tools:** Create audit-ready compliance reports.
    `,
    image: "/assets/images/zero-comply/compliance-officer.webp"
  },

  "Risk Managers": {
    title: "Risk Managers",
    description: `
      Proactively identify, manage, and mitigate compliance risks with predictive risk scoring and alerts.
      
      **Key Features:**
      **Risk Scoring:** Identify high-risk areas using predictive algorithms.
      **Proactive Alerts:** Get instant notifications for emerging compliance risks.
      **Mitigation Strategies:** Access mitigation tools to address compliance risks.
    `,
    image: "/assets/images/zero-comply/risk-manager.webp"
  },

  "Legal Advisors": {
    title: "Legal Advisors",
    description: `
      Access detailed audit reports and compliance records to support legal reviews and ensure regulatory adherence.
      
      **Key Features:**
      **Audit Logs:** Access immutable records of all compliance activities.
      **Regulatory Guidance:** Receive updates on regulatory changes.
      **Evidence Reports:** Export audit evidence reports for internal and external use.
    `,
    image: "/assets/images/zero-comply/legal-advisor.webp"
  },

  "Executive Management": {
    title: "Executive Management",
    description: `
      Gain strategic insights from dashboards and compliance analytics to inform business decision-making.
      
      **Key Features:**
      **Compliance Insights:** View compliance health at a glance.
      **Business Intelligence:** Use predictive analytics to forecast regulatory risk.
      **Executive Reports:** Get tailored reports for senior executives.
    `,
    image: "/assets/images/zero-comply/executive-management.webp"
  },

  "IT Administrators": {
    title: "IT Administrators",
    description: `
      Manage user roles, access controls, and integrations with third-party tools, ensuring seamless compliance operations.
      
      **Key Features:**
      **User Role Management:** Configure user permissions and access levels.
      **System Integrations:** Connect compliance with third-party apps.
      **Access Logs:** View and manage access logs for user activities.
    `,
    image: "/assets/images/zero-comply/it-administrator.webp"
  },

  "Auditors": {
    title: "Auditors",
    description: `
      Review compliance documentation, audit trails, and operational workflows to ensure full regulatory compliance.
      
      **Key Features:**
      **Audit Trail:** Review a tamper-proof log of all compliance actions.
      **Compliance Evidence:** Access reports for regulatory audits.
      **Reporting Tools:** Create audit evidence packs for compliance.
    `,
    image: "/assets/images/zero-comply/auditors.webp"
  }
};

export const KeyBenefitsDetails = {
  "Time-Saving Automation": {
    title: "Time-Saving Automation",
    description: `
      Eliminate manual compliance tasks, saving time and operational costs through process automation.
      
      **Key Features:**
      **Workflow Automation:** Eliminate manual compliance tasks.
      **Task Efficiency:** Focus on higher-level strategic initiatives.
      **Cost Reduction:** Reduce operational costs with fewer manual processes.
    `,
    image: "/assets/images/zero-comply/time-saving-automation.webp"
  },

  "Real-Time Alerts": {
    title: "Real-Time Alerts",
    description: `
      Get notified immediately when compliance risks arise, enabling swift corrective action.
      
      **Key Features:**
      **Proactive Alerts:** Receive instant notifications of compliance risks.
      **24/7 Monitoring:** Always stay ahead of potential threats.
      **Incident Management:** Resolve issues quickly with on-time alerts.
    `,
    image: "/assets/images/zero-comply/real-time-alerts.webp"
  },

  "Audit and Regulatory Confidence": {
    title: "Audit and Regulatory Confidence",
    description: `
      Ensure your business is always audit-ready, with all necessary compliance documentation available on demand.
      
      **Key Features:**
      **Audit Readiness:** Prepare for audits with complete documentation.
      **On-Demand Reports:** Export custom audit evidence reports.
      **Full Traceability:** Track every compliance action for audit purposes.
    `,
    image: "/assets/images/zero-comply/audit-confidence.webp"
  },

  "Risk Mitigation": {
    title: "Risk Mitigation",
    description: `
      Mitigate fines, penalties, and reputational damage by reducing the risk of non-compliance with predictive analytics.
      
      **Key Features:**
      **Risk Prediction:** Identify compliance risks in advance.
      **Fine Avoidance:** Mitigate potential fines for non-compliance.
      **Reputational Protection:** Safeguard your brand reputation.
    `,
    image: "/assets/images/zero-comply/risk-mitigation.webp"
  },

  "Cost Efficiency": {
    title: "Cost Efficiency",
    description: `
      Reduce the need for large compliance teams by automating workflows and utilising centralised compliance management tools.
      
      **Key Features:**
      **Operational Efficiency:** Reduce headcount by automating processes.
      **Centralised Management:** Simplify compliance oversight with one system.
      **Lower Compliance Costs:** Reduce costs through automation.
    `,
    image: "/assets/images/zero-comply/cost-efficiency.webp"
  },

  "Adaptability & Scalability": {
    title: "Adaptability & Scalability",
    description: `
      Scale your compliance infrastructure as regulations evolve, supporting both SMEs and global enterprises.
      
      **Key Features:**
      **Scalable Compliance:** Adapt as business and regulations grow.
      **Cloud-Native Design:** Supports SMEs and enterprises alike.
      **Regulatory Agility:** Adjust compliance workflows for new regulations.
    `,
    image: "/assets/images/zero-comply/adaptability-scalability.webp"
  }
};

export const TechnicalArchitectureDetails = {
  "Microservices Architecture": {
    title: "Microservices Architecture",
    description: `
      Modular microservices support independent updates for reporting, data analytics, and compliance workflows.
      
      **Key Features:**
      **Service Isolation:** Independent microservices for analytics, reporting, and workflows.
      **Seamless Updates:** Update services without downtime.
      **High Scalability:** Scale each service independently based on usage.
    `,
    image: "/assets/images/zero-comply/microservices-architecture.webp"
  },

  "Cloud-Native Design": {
    title: "Cloud-Native Design",
    description: `
      Deployed on AWS, Azure, and GCP, supporting multi-cloud environments for high availability and scalability.
      
      **Key Features:**
      **Multi-Cloud Support:** Runs on AWS, Azure, and Google Cloud.
      **High Availability:** Achieve 99.99% uptime through multi-region failover.
      **Elastic Scaling:** Scale resources up or down based on demand.
    `,
    image: "/assets/images/zero-comply/cloud-native.webp"
  },

  "Containerised Deployment": {
    title: "Containerised Deployment",
    description: `
      Uses Docker containers to ensure consistent deployment, fast updates, and compatibility across environments.
      
      **Key Features:**
      **Containerisation:** Deploy all components using Docker containers.
      **Faster Rollouts:** Roll out updates quickly and revert changes easily.
      **Cross-Environment Compatibility:** Ensure deployments work across dev, staging, and production environments.
    `,
    image: "/assets/images/zero-comply/container-deployment.webp"
  },

  "Event-Driven System": {
    title: "Event-Driven System",
    description: `
      Employs RabbitMQ to deliver real-time event notifications, enabling instantaneous compliance alerts.
      
      **Key Features:**
      **Event Streaming:** Instantly stream events for real-time notifications.
      **RabbitMQ Integration:** Leverage RabbitMQ for event-driven architecture.
      **Event-Triggered Actions:** Trigger compliance workflows on key events.
    `,
    image: "/assets/images/zero-comply/event-driven-system.webp"
  },

  "Data Encryption & Role-Based Access Control (RBAC)": {
    title: "Data Encryption & Role-Based Access Control (RBAC)",
    description: `
      Protects sensitive data with AES-256 encryption and RBAC, ensuring only authorised users can access sensitive compliance records.
      
      **Key Features:**
      **AES-256 Encryption:** Encrypt all compliance data for security.
      **Role-Based Access (RBAC):** Limit access based on user roles.
      **Data Privacy Compliance:** Supports GDPR, HIPAA, and MiFID compliance.
    `,
    image: "/assets/images/zero-comply/data-encryption.webp"
  },

  "Tamper-Proof Audit Trail": {
    title: "Tamper-Proof Audit Trail",
    description: `
      Maintains a tamper-proof log of user actions and regulatory changes, ensuring full audit traceability.
      
      **Key Features:**
      **Immutable Logs:** Store a tamper-proof, immutable record of user actions.
      **User Accountability:** Track every action for accountability.
      **Audit-Ready:** Access full logs for audits and regulatory inspections.
    `,
    image: "/assets/images/zero-comply/audit-trail.webp"
  }
};

export const KeyFeaturesDetails = {
  "Automated Compliance Workflows": {
    title: "Automated Compliance Workflows",
    description: `
      Automatically triggers compliance workflows for policy updates, new regulations, or internal review processes.
      
      **Key Features:**
      **Workflow Triggers:** Automatically trigger workflows based on regulatory changes.
      **Custom Workflows:** Create custom workflows to match compliance needs.
      **Error Reduction:** Eliminate manual errors in compliance processes.
    `,
    image: "/assets/images/zero-comply/automated-workflows.webp"
  },

  "Data-Driven Insights": {
    title: "Data-Driven Insights",
    description: `
      View dashboards and reports with actionable insights into compliance status and key risks.
      
      **Key Features:**
      **Dashboard Analytics:** Real-time visualisation of compliance KPIs.
      **Risk Insights:** View potential risk areas from visualised data.
      **Custom Reports:** Generate custom reports with actionable insights.
    `,
    image: "/assets/images/zero-comply/data-insights.webp"
  },

  "Predictive Compliance Analysis": {
    title: "Predictive Compliance Analysis",
    description: `
      Anticipate where future compliance issues might occur using machine learning algorithms and predictive analysis.
      
      **Key Features:**
      **AI-Based Predictions:** Identify potential issues before they happen.
      **Risk Forecasting:** Forecast compliance risk using past trends.
      **Actionable Insights:** Enable proactive compliance management.
    `,
    image: "/assets/images/zero-comply/predictive-analysis.webp"
  },

  "Role-Based Access Control (RBAC)": {
    title: "Role-Based Access Control (RBAC)",
    description: `
      Control user access to specific compliance data and system features based on their role and responsibilities.
      
      **Key Features:**
      **User Access Control:** Restrict user access to sensitive data.
      **Custom Roles:** Define roles with custom access permissions.
      **Audit Logs:** Track user access and permissions for compliance.
    `,
    image: "/assets/images/zero-comply/rbac.webp"
  },

  "Audit-Ready Compliance Reports": {
    title: "Audit-Ready Reports",
    description: `
      Export reports for regulatory authorities or internal reviews with all necessary compliance data included.
      
      **Key Features:**
      **Pre-Built Reports:** Create audit-ready reports for regulators.
      **Data Integrity:** Ensure that report data cannot be modified.
      **Custom Report Builder:** Customise reports with filters and fields.
    `,
    image: "/assets/images/zero-comply/aud-ready-reports.webp"
  },

  "Customisable Dashboards": {
    title: "Customisable Dashboards",
    description: `
      Users can personalise their dashboard with widgets, KPIs, and visualisations relevant to their role.
      
      **Key Features:**
      **Custom Widgets:** Customise dashboards with role-based widgets.
      **User-Specific KPIs:** See KPIs relevant to the user’s role.
      **Personalised Layouts:** Drag, drop, and rearrange dashboard components.
    `,
    image: "/assets/images/zero-comply/custom-dashboards.webp"
  }
};

export const SecurityAndComplianceDetails = {
  "PCI-DSS Certification": {
    title: "PCI-DSS Certification",
    description: `
      Complies with Payment Card Industry Data Security Standards (PCI-DSS) to protect sensitive payment data.
      
      **Key Features:**
      **Data Protection:** Meets PCI-DSS security standards for payment data protection.
      **Fraud Prevention:** Ensures secure handling and processing of customer payment details.
      **Audit Readiness:** Provides certification for payment system compliance audits.
    `,
    image: "/assets/images/zero-comply/pci-dss-certification.webp"
  },

  "Data Encryption": {
    title: "Data Encryption",
    description: `
      Applies AES-256 encryption to secure all compliance data, ensuring that data remains confidential and tamper-proof.
      
      **Key Features:**
      **Data Security:** Encrypts compliance data using AES-256 encryption.
      **End-to-End Protection:** Protects sensitive data at rest and in transit.
      **Confidentiality Assurance:** Ensures only authorised parties can access encrypted data.
    `,
    image: "/assets/images/zero-comply/data-encryption.webp"
  },

  "Audit Trail & Logging": {
    title: "Audit Trail & Logging",
    description: `
      Keeps an immutable log of user activity, ensuring all actions are traceable for audit purposes.
      
      **Key Features:**
      **Immutable Logs:** Ensures all user actions are recorded in a tamper-proof log.
      **Audit Traceability:** Provides full traceability for all user activities.
      **Compliance Support:** Meets audit and regulatory requirements for record-keeping.
    `,
    image: "/assets/images/zero-comply/audit-trail.webp"
  },

  "Regulatory Compliance": {
    title: "Regulatory Compliance",
    description: `
      Complies with regulatory standards like GDPR, HIPAA, MiFID, and more to support multi-jurisdictional compliance.
      
      **Key Features:**
      **Global Compliance:** Adheres to GDPR, HIPAA, MiFID, and industry-specific regulations.
      **Cross-Border Support:** Supports compliance for global and multi-jurisdictional operations.
      **Compliance Assurance:** Ensures compliance with financial, data privacy, and healthcare regulations.
    `,
    image: "/assets/images/zero-comply/regulatory-compliance.webp"
  },

  "Role-Based Access Control-(RBAC)": {
    title: "Role-Based Access Control (RBAC)",
    description: `
      Only authorised personnel have access to specific data and actions, reducing internal security risks.
      
      **Key Features:**
      **Granular Access Control:** Restrict access to specific data and system features.
      **Custom Roles & Permissions:** Assign roles based on user responsibilities.
      **Risk Mitigation:** Minimise internal security risks by limiting data access.
    `,
    image: "/assets/images/zero-comply/rbac-security.webp"
  },

  "Access and Session Management": {
    title: "Access and Session Management",
    description: `
      Monitor user sessions and control access to specific areas of the platform, with session timeout for enhanced security.
      
      **Key Features:**
      **Session Control:** Automatically end inactive user sessions to prevent unauthorised access.
      **Access Restrictions:** Customise session rules for high-privilege accounts.
      **Session Logs:** View detailed logs of all user sessions for security monitoring.
    `,
    image: "/assets/images/zero-comply/access-session-management.webp"
  },

  "Data Anonymisation & Masking": {
    title: "Data Anonymisation & Masking",
    description: `
      Anonymises sensitive data to protect personally identifiable information (PII) during processing and analytics.
      
      **Key Features:**
      **Data Anonymisation:** Masks PII such as names, emails, and phone numbers.
      **Privacy Protection:** Reduces the risk of data breaches and identity theft.
      **GDPR Compliance:** Ensures compliance with GDPR and other data privacy regulations.
    `,
    image: "/assets/images/zero-comply/data-anonymisation.webp"
  },

  "Multi-Factor Authentication (MFA)": {
    title: "Multi-Factor Authentication (MFA)",
    description: `
      Enhances user security with multi-factor authentication, requiring multiple verification steps for access.
      
      **Key Features:**
      **Enhanced Security:** Requires multiple verification steps for user authentication.
      **Mitigate Account Takeovers:** Reduces the risk of unauthorised access with MFA.
      **User-Friendly Authentication:** Supports SMS, email, and authenticator app verification.
    `,
    image: "/assets/images/zero-comply/mfa-security.webp"
  },

  "Fraud Detection & Prevention": {
    title: "Fraud Detection & Prevention",
    description: `
      Utilises AI-driven algorithms and machine learning models to detect and block fraudulent activities in real-time.
      
      **Key Features:**
      **AI-Driven Analysis:** Uses predictive AI to detect unusual patterns or behaviours.
      **Real-Time Alerts:** Alerts administrators of suspicious activities as they happen.
      **Proactive Prevention:** Blocks fraudulent transactions and suspicious user activities in real-time.
    `,
    image: "/assets/images/zero-comply/fraud-detection.webp"
  }
};


export const FutureEnhancementsDetails = {
  "AI-Powered Risk Scoring": {
    title: "AI-Powered Risk Scoring",
    description: `
      Introduce AI models that predict regulatory risks based on historical compliance data and industry trends.
      
      **Key Features:**
      **Predictive Analytics:** Use AI-driven models to identify high-risk activities before they happen.
      **Proactive Risk Mitigation:** Take preventive actions to address risks in advance.
      **Machine Learning Models:** Continuously learn and improve risk detection accuracy.
    `,
    image: "/assets/images/zero-comply/ai-risk-scoring.webp"
  },

  "Blockchain Integration": {
    title: "Blockchain Integration",
    description: `
      Leverage blockchain technology for a tamper-proof audit trail that ensures compliance data integrity.
      
      **Key Features:**
      **Immutable Audit Trails:** Store tamper-proof logs using blockchain technology.
      **Data Transparency:** Increase visibility and trust with publicly verifiable records.
      **Cross-Platform Support:** Integrate with multiple blockchains (e.g., Ethereum, Hyperledger).
    `,
    image: "/assets/images/zero-comply/blockchain-integration.webp"
  },

  "Customisable-Dashboards": {
    title: "Customisable Dashboards",
    description: `
      Enable users to create personalised dashboards with metrics and KPIs specific to their role and responsibilities.
      
      **Key Features:**
      **Widget-Based Layouts:** Customise dashboards with draggable widgets.
      **User-Specific KPIs:** Tailor dashboards with role-specific performance metrics.
      **Personalisation:** Let users customise the layout to match personal preferences.
    `,
    image: "/assets/images/zero-comply/customisable-dashboards.webp"
  },

  "Automated Policy Updates": {
    title: "Automated Policy Updates",
    description: `
      Automatically apply regulatory updates to workflows and compliance processes as policies evolve.
      
      **Key Features:**
      **Regulatory Change Alerts:** Get instant notifications of regulatory changes.
      **Auto-Update Workflows:** Update workflows automatically to meet compliance changes.
      **Compliance Templates:** Use pre-configured templates for quick adaptation.
    `,
    image: "/assets/images/zero-comply/automated-policy-updates.webp"
  },

  "Mobile Access": {
    title: "Mobile Access",
    description: `
      Allow users to access compliance dashboards and receive alerts on mobile devices for on-the-go management.
      
      **Key Features:**
      **Mobile Notifications:** Receive push notifications for compliance alerts.
      **Responsive Design:** Access dashboards and reports on any mobile device.
      **On-The-Go Management:** Stay compliant, even when away from the office.
    `,
    image: "/assets/images/zero-comply/mobile-access.webp"
  },

  "Custom Risk Models": {
    title: "Custom Risk Models",
    description: `
      Allow organisations to develop custom risk scoring models to assess specific compliance challenges unique to their business.
      
      **Key Features:**
      **Custom Risk Algorithms:** Define risk scoring algorithms unique to your business needs.
      **Business-Specific Risk Analysis:** Customise risk models for industry-specific compliance challenges.
      **Dynamic Adjustments:** Modify risk parameters as new data or trends arise.
    `,
    image: "/assets/images/zero-comply/custom-risk-models.webp"
  }
};

