import React, { useEffect, useState, useRef } from "react";
import AnimatedTitle from "../components/AnimatedTitle";
import { TiArrowDown, TiArrowUp } from "react-icons/ti";
import BackToProductsButton from "../components/BackToProductsButton";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Footer from "../components/Footer";
import LearnMoreButton from "../components/LearnMoreButton";
import ZeroBlockchain1LearnMoreCustomModal from "./1-ZeroBlockchain1LearnMoreCustomModal";
import ZeroBlockchainLogo from "../assets/Zero-Blockchain.webp";

import {
  CoreFeatureDetails,
  TechnicalCapabilitiesDetails,
  KeyBenefitsDetails,
  BusinessBenefitsDetails,
  SecurityAndComplianceDetails,
  GovernanceFeaturesDetails,  
  UseCasesDetails,
  EcosystemPartnersDetails,
  DeveloperResourcesDetails,
  ConservationInitiativesDetails,
  DeveloperBenefitsDetails,
  FinancialBenefitsDetails,
  OperationalBenefitsDetails,
  SocialBenefitsDetails,
  EnvironmentalBenefitsDetails,
  EconomicBenefitsDetails,
  EnvironmentalSustainabilityBenefitsDetails,
  RoadmapPhasesDetails,
  UserAdoptionPhasesDetails,
  DeveloperEcosystemPhasesDetails,
  InstitutionalPartnershipPhasesDetails,
  EnhancedTokenUtilityPhasesDetails,
  ESGAndComplianceLeadershipPhasesDetails,
  PublicAndDAOGovernancePhasesDetails,
  FutureEnhancementsDetails,
} from "../data/1-ZeroBlockchain/1-ZeroBlockchainDeepFeaturesData";

import {
  CoreFeatures,
  TechnicalCapabilities,
  KeyBenefits,
  BusinessBenefits,
  SecurityAndCompliance,
  GovernanceFeatures,  
  UseCases,
  EcosystemPartners,
  DeveloperResources,
  ConservationInitiatives,
  DeveloperBenefits,
  FinancialBenefits,
  OperationalBenefits,
  SocialBenefits,
  EnvironmentalBenefits,
  EconomicBenefits,
  EnvironmentalSustainabilityBenefits,
  RoadmapPhases,
  UserAdoptionPhases,
  DeveloperEcosystemPhases,
  InstitutionalPartnershipPhases,
  EnhancedTokenUtilityPhases,
  ESGAndComplianceLeadershipPhases,
  PublicAndDAOGovernancePhases,
  FutureEnhancements,
} from "../data/1-ZeroBlockchain/1-ZeroBlockchainFeaturesData";

gsap.registerPlugin(ScrollTrigger);

const ZeroBlockchain = () => {
  const logoRef = useRef(null);
  const [scrollDownClicked, setScrollDownClicked] = useState(false);
  const [isBottom, setIsBottom] = useState(false);

  const coreFeaturesRefs = useRef([]);
  const scrollDownRef = useRef(null);
  const scrollTopRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalDescription, setModalDescription] = useState("");
  const [modalImage, setModalImage] = useState("");

  useEffect(() => {
    // Always scroll to the top on component mount
    window.scrollTo({ top: 0, behavior: "smooth" });

    // Animate the logo when the component mounts
    if (logoRef.current) {
      gsap.fromTo(
        logoRef.current,
        { opacity: 0, y: -40 },
        { opacity: 1, y: 0, duration: 1.5, ease: "power3.out" }
      );
    }

    // Animate the "Core Features" items in batches
    const batchSize = 3;
    for (let i = 0; i < coreFeaturesRefs.current.length; i += batchSize) {
      const batch = coreFeaturesRefs.current.slice(i, i + batchSize).filter(Boolean);
      if (batch.length > 0) {
        gsap.fromTo(
          batch,
          { opacity: 0, y: 50 },
          {
            opacity: 1,
            y: 0,
            duration: 1,
            stagger: 0.15,
            delay: (i / batchSize) * 1.5,
            ease: "power3.out",
            scrollTrigger: {
              trigger: batch[0],
              start: "top 90%",
              toggleActions: "play none none none",
            },
          }
        );
      }
    }

    // Animate the "Scroll Down" button
    if (scrollDownRef.current) {
      gsap.fromTo(
        scrollDownRef.current,
        { opacity: 0, y: -20 },
        { opacity: 1, y: 0, duration: 1 }
      );
    }

    // Animate the "Back to Top" button
    if (scrollTopRef.current) {
      gsap.fromTo(
        scrollTopRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          scrollTrigger: {
            trigger: scrollTopRef.current,
            start: "top bottom",
          },
        }
      );
    }
  }, []);

  // Check if the user has scrolled to the bottom
  const checkIfBottom = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 200) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  };

  // Scroll to main content
  const scrollToContent = () => {
    const content = document.getElementById("content");
    if (content) {
      const contentOffsetTop = content.offsetTop;
      window.scrollTo({
        top: contentOffsetTop - 100,
        behavior: "smooth",
      });
    }
    setScrollDownClicked(true);
  };

  // Scroll to the top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setScrollDownClicked(false);
  };

  // Add/remove scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", checkIfBottom);
    return () => {
      window.removeEventListener("scroll", checkIfBottom);
    };
  }, []);

  const openModal = (title) => {
    const featureDetails =
      CoreFeatureDetails[title] ||
      TechnicalCapabilitiesDetails[title] ||
      BusinessBenefitsDetails[title] ||
      SecurityAndComplianceDetails[title] ||
      GovernanceFeaturesDetails[title] ||
      KeyBenefitsDetails[title] ||
      UseCasesDetails[title] ||
      EcosystemPartnersDetails[title] ||
      DeveloperResourcesDetails[title] ||
      ConservationInitiativesDetails[title] ||
      DeveloperBenefitsDetails[title] ||
      FinancialBenefitsDetails[title] ||
      OperationalBenefitsDetails[title] ||
      SocialBenefitsDetails[title] ||
      EnvironmentalBenefitsDetails[title] ||
      EconomicBenefitsDetails[title] ||
      EnvironmentalSustainabilityBenefitsDetails[title] ||
      RoadmapPhasesDetails[title] ||
      UserAdoptionPhasesDetails[title] ||
      DeveloperEcosystemPhasesDetails[title] ||
      InstitutionalPartnershipPhasesDetails[title] ||
      EnhancedTokenUtilityPhasesDetails[title] ||
      ESGAndComplianceLeadershipPhasesDetails[title] ||
      PublicAndDAOGovernancePhasesDetails[title] ||
      FutureEnhancementsDetails[title] ||
      CoreFeatureDetails["Default"];

    setModalTitle(featureDetails.title);
    setModalDescription(featureDetails.description);
    setModalImage(featureDetails.image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalTitle('');
    setModalDescription('');
    setModalImage('');
  };

  return (
    <div className="w-screen min-h-screen bg-black text-blue-50">
      {/* Hero Section */}
      <div className="relative w-screen h-screen overflow-hidden">
        <video
          src="/videos/blockchain-hero.mp4"
          autoPlay
          loop
          muted
          className="absolute left-0 top-0 size-full object-cover"
        />

        {/* LOGO AND TITLE CONTAINER */}
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/60 logo-title-container">
        <div ref={logoRef} className="logo">
          <img
            src={ZeroBlockchainLogo}
            alt="Zero Blockchain Logo"
            className="w-40 h-40 object-contain"
          />
        </div>
        <AnimatedTitle
          title="Zero-Blockchain"
          containerClass="text-center text-blue-50 font-zentry mt-4 mb-40" // Added margin-top to create spacing
        />
        </div>
      </div>

      {/* Scroll Down Button */}
      {!scrollDownClicked && (
        <div
          ref={scrollDownRef}
          className="fixed bottom-10 left-1/2 transform -translate-x-1/2 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
          onClick={scrollToContent}
        >
          <TiArrowDown size={24} className="text-white/40 hover:text-white" />
          <span className="text-white/40 hover:text-white">SCROLL DOWN</span>
        </div>
      )}

      {/* Content Section */}
      <div id="content" className="px-10 py-20 mx-auto max-w-7xl">
        {/* Introduction */}
        <div className="mb-10 text-center">
        <h2 className="text-4xl font-extrabold text-teal-600">Comprehensive Blockchain Tools</h2>
          <p className="mt-3">
            Revolutionising blockchain solutions with cutting-edge tools and insights.
          </p>
        </div>

        {/* Core Features */}
        <section className="px-4 py-8 bg-black max-w-7xl mx-auto">
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">Core Blockchain Features</h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
            Seamless Cross-Chain Integration, Native Cryptocurrency Protocols, and Conservation-Focused Tokenisation for an Eco-Friendly Blockchain Ecosystem.
          </p>
          <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {CoreFeatures.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>
                <div className="mt-auto self-start">
                  <LearnMoreButton productId={index} onClick={() => openModal(item.title)} />
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>

        {/* Technical Capabilities */}
        <section className="px-4 py-8 bg-black max-w-7xl mx-auto">
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">Technical Capabilities</h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
            Advanced Consensus Mechanisms, Smart Contract Automation, and Ethereum Compatibility for a Secure and Scalable Blockchain Ecosystem.
          </p>
          <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {TechnicalCapabilities.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>
                <div className="mt-auto self-start">
                  <LearnMoreButton productId={index} onClick={() => openModal(item.title)} />
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>

        <br />

        {/* Highlights Section */}
        <section className="mt-20 space-y-16">

        {/* First Highlight */}
        <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row">
          <div className="w-full md:w-1/2">
            <img
              src="/assets/images/zero-blockchain/cross-chain.webp"
              alt="Cross-Chain Interoperability"
              className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
            />
          </div>
          <div className="w-full md:w-1/2">
            <h3 className="text-3xl md:text-4xl font-extrabold text-white">Cross-Chain Interoperability</h3>
            <p className="mt-4">
              Facilitates seamless integration and token transfers between Ethereum, Binance Smart Chain, and other major blockchains.
            </p>
          </div>
        </div>

        {/* Second Highlight */}
        <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row-reverse">
          <div className="w-full md:w-1/2">
            <img
              src="/assets/images/zero-blockchain/zero-coin.webp"
              alt="Zero-Coin Protocol"
              className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
            />
          </div>
          <div className="w-full md:w-1/2">
            <h3 className="text-3xl md:text-4xl font-extrabold text-white">Zero-Coin Protocol</h3>
            <p className="mt-4">
              Native cryptocurrency for payments, staking, governance, and transaction fees within the ecosystem.
            </p>
          </div>
        </div>

        {/* Third Highlight */}
        <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row">
          <div className="w-full md:w-1/2">
            <img
              src="/assets/images/zero-blockchain/smart-contracts.webp"
              alt="Smart Contract Automation"
              className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
            />
          </div>
          <div className="w-full md:w-1/2">
            <h3 className="text-3xl md:text-4xl font-extrabold text-white">Smart Contract Automation</h3>
            <p className="mt-4">
              Features reusable and customisable smart contracts for tokenisation, compliance, and governance.
            </p>
          </div>
        </div>

        {/* Fourth Highlight */}
        <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row-reverse">
          <div className="w-full md:w-1/2">
            <img
              src="/assets/images/zero-blockchain/conservation-partnerships.webp"
              alt="Conservation Partnerships"
              className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
            />
          </div>
          <div className="w-full md:w-1/2">
            <h3 className="text-3xl md:text-4xl font-extrabold text-white">Conservation Partnerships</h3>
            <p className="mt-4">
              Strengthens environmental initiatives through conservation-focused tokenisation and global NGO collaborations.
            </p>
          </div>
        </div>
        </section>
        <br /><br /><br />   
        
        {/* Key Benefits */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
           Key Benefits
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Empowering Financial Inclusion, Sustainability, and Governance with Enhanced Liquidity, Security, and Cross-Chain Interoperability.
          </p>
          {/* Optional Divider */}
          <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {KeyBenefits.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>
                
                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton
                    productId={index}
                    onClick={() => openModal(item.title, item.description)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>
        <br /><br /><br /> 

        {/* Business Benefits */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
          Business Benefits
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Unlocking Liquidity, Driving Revenue Growth, and Enhancing Operational Efficiency with Regulatory Compliance and Conservation Initiatives.
          </p>
          {/* Optional Divider */}
          <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {BusinessBenefits.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>
                
                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton
                    productId={index}
                    onClick={() => openModal(item.title, item.description)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>

        <br /><br /><br />        
        
        
        {/* Use Cases */}
        <section className="px-4 py-8 bg-black">
          <div className="mb-8 text-center">
            <h2 className="text-4xl font-extrabold text-teal-600">
            Use Cases
            </h2>
            <p className="mt-2 text-lg md:text-xl text-gray-300">
            Revolutionising Industries with Tokenised Microfinance, Carbon Credits, Supply Chain Transparency, and Decentralised Philanthropy.
            </p>
            <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
            {UseCases.map((item, index) => (
              <div
                key={index}
                className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
              >
                <div
                  className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                  style={{ backgroundImage: `url(${item.image})` }}
                ></div>

                <div className="relative z-10 flex flex-col flex-grow">
                  <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                  <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                  <div className="mt-auto self-start">
                    <LearnMoreButton
                      productId={index}
                      onClick={() => openModal(item.title, item.description)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>        

        {/* Security & Compliance */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
          Security & Compliance
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Elevating Blockchain Security with AES Encryption, Zero-Knowledge Proofs, and Compliance Automation for Trust and Transparency.
          </p>
        {/* Optional Divider */}
        <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {SecurityAndCompliance.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton 
                  productId={index} 
                  onClick={() => openModal(item.title)} 
                  />              
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>
        <br />        


        <br />
        {/* Governance Features */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
          Governance Features
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Empowering Transparent and Collaborative Decision-Making with Decentralised Voting, On-Chain Proposals, and DAO-Driven Conservation.
          </p>
        {/* Optional Divider */}
        <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {GovernanceFeatures.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton 
                  productId={index} 
                  onClick={() => openModal(item.title)} 
                  />              
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>
        <br />        


        <br />
        {/* Ecosystem Partners */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
          Ecosystem Partners
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Forging Strategic Alliances with Leading Conservation Organisations, Blockchain Innovators, and NFT Marketplaces to Drive Sustainability and Innovation.
          </p>
        {/* Optional Divider */}
        <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {EcosystemPartners.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton 
                  productId={index} 
                  onClick={() => openModal(item.title)} 
                  />              
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>
        <br />        


        <br />
        {/* Developer Resources */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
          Developer Resources
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Empowering Innovation with SDKs, APIs, and Community Support for Seamless dApp Development and Blockchain Integration.
          </p>
        {/* Optional Divider */}
        <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {DeveloperResources.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton 
                  productId={index} 
                  onClick={() => openModal(item.title)} 
                  />              
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>
        <br />        


        <br />
        {/* Conservation Initiatives */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
          Conservation Initiatives
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Driving Environmental Impact Through Tokenisation, Gamified Reforestation, and Global NGO Collaborations.
          </p>
        {/* Optional Divider */}
        <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {ConservationInitiatives.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton 
                  productId={index} 
                  onClick={() => openModal(item.title)} 
                  />              
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>
        <br />        

        <br />

        <br />
        {/* Developer Benefits */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
          Developer Benefits
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Empowering Innovators with SDKs, Smart Contract Templates, Multi-Chain Integration, and Community Support for Seamless Blockchain Development.
          </p>
        {/* Optional Divider */}
        <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {DeveloperBenefits.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton 
                  productId={index} 
                  onClick={() => openModal(item.title)} 
                  />              
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>
        <br />      

        <br />

        <br />
        {/* FinancialBenefits */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
          Financial Benefits
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Unlocking Liquidity, Reducing Costs, and Diversifying Revenue with Blockchain-Based Transparency and Cross-Border Efficiency.
          </p>
        {/* Optional Divider */}
        <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {FinancialBenefits.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton 
                  productId={index} 
                  onClick={() => openModal(item.title)} 
                  />              
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>
        <br />        

        <br />

        <br />
        {/* Operational Benefits */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
          Operational Benefits
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Driving Efficiency with Automation, Scalability, Data Integrity, and Real-Time Insights for Seamless and Secure Processes.
          </p>
        {/* Optional Divider */}
        <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {OperationalBenefits.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton 
                  productId={index} 
                  onClick={() => openModal(item.title)} 
                  />              
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>
        <br />        

        <br />

        <br />
        {/* Social Benefits */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
          Social Benefits
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Empowering Communities with Financial Inclusion, Conservation Support, Education, and Transparent Collaboration through Blockchain Innovation.
          </p>
        {/* Optional Divider */}
        <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {SocialBenefits.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton 
                  productId={index} 
                  onClick={() => openModal(item.title)} 
                  />              
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>
        <br />        

        <br />

        <br />
        {/* Environmental Benefits */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
          Environmental Benefits
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Advancing Global Sustainability Through Conservation Funding, Carbon Reduction, Reforestation, and Gamified Eco-Engagement Initiatives.
          </p>
        {/* Optional Divider */}
        <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {EnvironmentalBenefits.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton 
                  productId={index} 
                  onClick={() => openModal(item.title)} 
                  />              
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>
        <br />        

        <br />
        
        
        </section>
        <br />
        <br />     

        {/* Fifth Highlight */}
        <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row">
        <div className="w-full md:w-1/2">
          <img
            src="/assets/images/zero-blockchain/regulatory-compliance.webp"
            alt="Regulatory Compliance"
            className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
          />
        </div>
        <div className="w-full md:w-1/2">
          <h3 className="text-3xl md:text-4xl font-extrabold text-white">Regulatory Compliance</h3>
          <p className="mt-4">
            Fully compliant with international standards such as GDPR, ISO 27001, KYC, and AML, ensuring a trusted and transparent ecosystem.
          </p>
        </div>
      </div>
      <br />
      <br />

      {/* Sixth Highlight */}
      <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row-reverse">
        <div className="w-full md:w-1/2">
          <img
            src="/assets/images/zero-blockchain/community-support.webp"
            alt="Developer Community Support"
            className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
          />
        </div>
        <div className="w-full md:w-1/2">
          <h3 className="text-3xl md:text-4xl font-extrabold text-white">Developer Community Support</h3>
          <p className="mt-4">
            Join a thriving community of developers to collaborate, share resources, and receive technical support.
          </p>
        </div>
      </div>
      <br />
      <br />

      {/* Seventh Highlight */}
      <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row">
        <div className="w-full md:w-1/2">
          <img
            src="/assets/images/zero-blockchain/decentralised-governance.webp"
            alt="Decentralised Governance"
            className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
          />
        </div>
        <div className="w-full md:w-1/2">
          <h3 className="text-3xl md:text-4xl font-extrabold text-white">Decentralised Governance</h3>
          <p className="mt-4">
            Empowers the community with decision-making authority through decentralised voting, ensuring alignment with user priorities and platform transparency.
          </p>
        </div>
      </div><br /><br />

      {/* Eighth Highlight */}
      <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row-reverse">
        <div className="w-full md:w-1/2">
          <img
            src="/assets/images/zero-blockchain/ecosystem-rewards.webp"
            alt="Ecosystem Rewards"
            className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
          />
        </div>
        <div className="w-full md:w-1/2">
          <h3 className="text-3xl md:text-4xl font-extrabold text-white">Ecosystem Rewards</h3>
          <p className="mt-4">
            Implement a dynamic reward system for Zero-Coin users who actively contribute to the ecosystem, such as developers and conservationists.
          </p>
        </div>
      </div>
            
      <br /><br /><br />    
      
      <br />
        {/* EconomicBenefits */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
          Economic Benefits
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Empowering Financial Inclusion, Job Creation, and Global Investments While Boosting Liquidity and Cost-Efficient Transactions.
          </p>
        {/* Optional Divider */}
        <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {EconomicBenefits.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton 
                  productId={index} 
                  onClick={() => openModal(item.title)} 
                  />              
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>
        <br />        


        <br />

        <br />
        {/* EnvironmentalSustainabilityBenefits */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
          Environmental Sustainability Benefits
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Advancing Carbon Credit Tokenisation, Renewable Energy Investments, and Biodiversity Conservation for a Greener Future.
          </p>
        {/* Optional Divider */}
        <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {EnvironmentalSustainabilityBenefits.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton 
                  productId={index} 
                  onClick={() => openModal(item.title)} 
                  />              
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>
        <br />        


        <br />

        <br />
        {/* Roadmap Phases */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">          
          Zero Blockchain Roadmap Phases:
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Milestones from Smart Contract Deployment to Quantum-Ready Blockchain Integration, Shaping the Future of Decentralised Conservation and Financial Innovation.
          </p>
        {/* Optional Divider */}
        <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {RoadmapPhases.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton 
                  productId={index} 
                  onClick={() => openModal(item.title)} 
                  />              
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>
        <br />        


        <br />

        <br />
        {/* UserAdoptionPhases */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
          Zero Blockchain User Adoption Phases:
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Empowering Global Communities with Educational Outreach, Incentives, and Gamified Engagement to Drive Sustainable Growth and Ecosystem Expansion.
          </p>
        {/* Optional Divider */}
        <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {UserAdoptionPhases.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton 
                  productId={index} 
                  onClick={() => openModal(item.title)} 
                  />              
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>
        <br />       


        <br />

        <br />
        {/* Developer Ecosystem Phases */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
          Zero Blockchain Developer Ecosystem Phases:
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Advancing Innovation with SDK Launches, Hackathons, Incentives, and Certification Programs to Empower a Global Network of Developers.
          </p>
        {/* Optional Divider */}
        <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {DeveloperEcosystemPhases.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton 
                  productId={index} 
                  onClick={() => openModal(item.title)} 
                  />              
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>
        <br />        


        <br />

        <br />
        {/* InstitutionalPartnershipPhases */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
          Zero Blockchain Institutional Partnership Phases:
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Building Strategic Alliances with NGOs, Financial Institutions, Regulators, and Corporates to Drive Conservation, Compliance, and Tokenisation Innovation.
          </p>
        {/* Optional Divider */}
        <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {InstitutionalPartnershipPhases.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton 
                  productId={index} 
                  onClick={() => openModal(item.title)} 
                  />              
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>
        <br />        


        <br />

        <br />
        {/* EnhancedTokenUtilityPhases */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
          Enhanced Token Utility Phases:
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Expanding Zero-Coin's Ecosystem Through Staking, Governance, Collateralised Loans, and Multi-Platform Integrations for Maximum Utility and Engagement.
          </p>
        {/* Optional Divider */}
        <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {EnhancedTokenUtilityPhases.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton 
                  productId={index} 
                  onClick={() => openModal(item.title)} 
                  />              
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>
        <br />        


        <br />

        <br />
        {/* ESG And Compliance Leadership Phases */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
          ESG and Compliance Leadership Phases:
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Advancing Environmental, Social, and Governance Standards with Blockchain Transparency, Carbon Credit Tokenisation, and Global ESG Collaborations.
          </p>
        {/* Optional Divider */}
        <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {ESGAndComplianceLeadershipPhases.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton 
                  productId={index} 
                  onClick={() => openModal(item.title)} 
                  />              
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>
        <br />        


        <br />

        <br />
        {/* Public And DAO Governance Phases */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">
          Public and DAO Governance Phases:
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-300">
          Empowering Community-Led Decision-Making with Decentralised Frameworks, Token-Based Incentives, and Cross-Chain Governance Integration.
          </p>
        {/* Optional Divider */}
        <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {PublicAndDAOGovernancePhases.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton 
                  productId={index} 
                  onClick={() => openModal(item.title)} 
                  />              
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>
        <br />      

        <br />

      <br /> 

      {/* Future Enhancements */}
      <section className="px-4 py-8 bg-black">
      {/* Title Container */}
      <div className="mb-8 text-center">
        <h2 className="text-4xl font-extrabold text-teal-600">
        Future Enhancements
        </h2>
        <p className="mt-2 text-lg md:text-xl text-gray-300">
        Advancing the Zero Blockchain Ecosystem with Decentralised Governance, Quantum-Ready Innovations, Expanded Conservation Efforts, and Cross-Chain Interoperability.
        </p>
      {/* Optional Divider */}
      <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
      </div>

      {/* Features Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
        {FutureEnhancements.map((item, index) => (
          <div
            key={index}
            className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
          >
            {/* Background Image */}
            <div
              className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
              style={{ backgroundImage: `url(${item.image})` }}
            ></div>

            {/* Content Container */}
            <div className="relative z-10 flex flex-col flex-grow">
              <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

              {/* Description with consistent space below */}
              <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

              {/* Learn More Button Positioned at Bottom Left */}
              <div className="mt-auto self-start">
                <LearnMoreButton
                productId={index} 
                onClick={() => openModal(item.title)} 
                />              
              </div>
            </div>
          </div>
        ))}
      </div>
      </section>
      <br />
      
        {/* Back to Products Button */}
        <div className="flex justify-center mt-10">
          <BackToProductsButton />
        </div>
      </div>


      {/* Back to Top Button */}
      {isBottom && (
        <div
          ref={scrollTopRef}
          className="fixed bottom-10 left-5 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
          onClick={scrollToTop}
        >
          <TiArrowUp
            size={24}
            className="text-white/40 hover:text-white transition-transform duration-300"
          />
          <span className="text-white/40 hover:text-white">BACK TO TOP</span>
        </div>
      )}

      {/* Footer */}
      <Footer />

      <ZeroBlockchain1LearnMoreCustomModal 
      isOpen={isModalOpen} 
      onClose={closeModal} 
      title={modalTitle} 
      description={modalDescription} 
      image={modalImage} 
    />
  </div>
);
};

export default ZeroBlockchain;
