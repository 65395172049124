export const CoreFeatureDetails = {
  "Sustainable Investment Hub": {
    title: "Sustainable Investment Hub",
    description: `
      Gain access to a dedicated platform showcasing a curated selection of pre-vetted green projects that prioritise sustainability impact and financial viability.
      
      **Key Features:**
      **Curated Project Listings:** Explore a collection of projects with high-impact potential that align with UN SDGs.
      **Sustainability Focus:** Back projects in sectors like renewable energy, waste reduction, and sustainable agriculture.
      **Pre-Vetted Opportunities:** Only projects that meet stringent financial and impact viability are listed for investment.
    `,
    image: "/assets/images/zero-green/sustainable-investment-hub.webp"
  },

  "Fractional Investment": {
    title: "Fractional Investment",
    description: `
      Democratise access to green investment opportunities by enabling users to invest small amounts towards larger, impactful projects.
      
      **Key Features:**
      **Fractional Ownership:** Own a portion of large-scale green projects with minimal financial commitment.
      **Inclusivity & Accessibility:** Make impact investing accessible to all, regardless of financial capacity.
      **Shared Returns:** Earn proportional returns based on the amount of capital contributed to each project.
    `,
    image: "/assets/images/zero-green/fractional-investment.webp"
  },

  "AI-Powered Investment Insights": {
    title: "AI-Powered Investment Insights",
    description: `
      Leverage AI-driven analytics to gain valuable insights on success rates, investor sentiment, and impact metrics for each project.
      
      **Key Features:**
      **Predictive Analytics:** AI models predict the likelihood of project success based on data trends and sentiment analysis.
      **Impact Metrics:** Visualise the social, environmental, and economic impact of investments in real time.
      **Investor Sentiment Analysis:** AI gauges market sentiment to identify top-performing green projects.
    `,
    image: "/assets/images/zero-green/ai-insights.webp"
  },

  "Smart Contract Disbursement": {
    title: "Smart Contract Disbursement",
    description: `
      Ensure that funds are only disbursed when pre-set milestones are achieved, improving project accountability and transparency.
      
      **Key Features:**
      **Milestone-Driven Payments:** Funds are released in phases only after project goals are met.
      **Accountability Framework:** Milestones are pre-defined, ensuring clear accountability for project initiators.
      **Blockchain-Powered Automation:** Smart contracts automatically manage fund disbursement for complete transparency.
    `,
    image: "/assets/images/zero-green/smart-contract-disbursement.webp"
  },

  "Blockchain-Powered Transparency": {
    title: "Blockchain-Powered Transparency",
    description: `
      Ensure complete visibility and auditability of all investments and transactions through an immutable blockchain ledger.
      
      **Key Features:**
      **Immutable Ledger:** Every investment and transaction is recorded on a blockchain ledger, ensuring data integrity.
      **Auditability & Traceability:** Full transaction history and fund movements are accessible for audit purposes.
      **Real-Time Transparency:** Investors can track every step of their financial journey on the Zero-Green platform.
    `,
    image: "/assets/images/zero-green/blockchain-transparency.webp"
  },

  "Customised Investor Dashboard": {
    title: "Customised Investor Dashboard",
    description: `
      Personalised dashboards provide investors with a clear view of their investments, returns, and the impact of their contributions.
      
      **Key Features:**
      **Personalised Data Visualisation:** View tailored dashboards that highlight returns, impact, and project performance.
      **Impact Tracking:** Measure the social and environmental effects of your investments.
      **Portfolio Management:** Track multiple investments, returns, and earnings from one centralised dashboard.
    `,
    image: "/assets/images/zero-green/custom-dashboard.webp"
  }
};


export const UserPersonasDetails = {
  "Eco-Conscious Investors": {
    title: "Eco-Conscious Investors",
    description: `
      Retail investors who prioritise ethical and impact-driven projects that align with personal sustainability goals.
      
      **Who They Are:**
      **Socially Responsible Individuals:** Retail investors seeking opportunities to align their financial goals with sustainability.
      **Ethical Investors:** Those who prefer projects that contribute to environmental, social, and governance (ESG) goals.
      **Green-Conscious Investors:** Individuals looking for investments in renewable energy, waste reduction, and sustainable initiatives.
      
      **Pain Points:**
      - Limited access to transparent green investment opportunities.
      - Unclear measurement of the impact of their investments.
      - Need for secure, low-risk options for ethical investing.
    `,
    image: "/assets/images/zero-green/eco-conscious-investors.webp"
  },

  "Institutional Investors": {
    title: "Institutional Investors",
    description: `
      Institutional investors and asset managers aiming to achieve ESG goals and diversify portfolios with green investments.
      
      **Who They Are:**
      **Asset Managers & Financial Institutions:** Banks, fund managers, and insurance firms seeking sustainable investment opportunities.
      **Pension Funds:** Institutions integrating ESG principles into their investment strategy.
      **ESG-Focused Investors:** Organisations committed to promoting sustainability and ethical investing.
      
      **Pain Points:**
      - Meeting regulatory ESG requirements for sustainable investment.
      - Achieving portfolio diversification while aligning with green objectives.
      - Lack of access to transparent, high-impact green projects.
    `,
    image: "/assets/images/zero-green/institutional-investors.webp"
  },

  "Project Initiators": {
    title: "Project Initiators",
    description: `
      Startups, NGOs, and companies seeking capital for sustainable projects aligned with the United Nations Sustainable Development Goals (SDGs).
      
      **Who They Are:**
      **Green Entrepreneurs & Startups:** Businesses working on green technology, waste reduction, and renewable energy solutions.
      **Non-Governmental Organisations (NGOs):** NGOs focused on social impact projects, such as clean water and poverty alleviation.
      **Companies Advancing SDGs:** Enterprises whose projects align with the United Nations Sustainable Development Goals.
      
      **Pain Points:**
      - Limited access to funding from traditional financial institutions.
      - Difficulty proving impact and accountability to investors.
      - High transparency and compliance demands from green investors.
    `,
    image: "/assets/images/zero-green/project-initiators.webp"
  },

  "Wealth Managers": {
    title: "Wealth Managers",
    description: `
      Wealth managers advising high-net-worth individuals on green investment opportunities with growth potential.
      
      **Who They Are:**
      **High-Net-Worth Client Advisors:** Wealth advisors managing portfolios for high-net-worth individuals (HNWIs) and family offices.
      **Private Bank Advisors:** Professionals offering green investment advice to premium banking clients.
      **Green Wealth Strategists:** Wealth managers actively seeking to integrate ESG principles into client portfolios.
      
      **Pain Points:**
      - Limited green investment options with verified impact metrics.
      - Demand for transparent ESG scoring of investment opportunities.
      - Pressure to provide clients with socially responsible investment opportunities.
    `,
    image: "/assets/images/zero-green/wealth-managers.webp"
  },

  "ESG Analysts": {
    title: "ESG Analysts",
    description: `
      ESG analysts monitoring the environmental, social, and governance impact of investments made via Zero-Green.
      
      **Who They Are:**
      **Sustainability Researchers:** Professionals who assess the ESG impact of investments.
      **ESG Consultants:** Analysts providing ESG analysis to banks, investors, and corporations.
      **Impact Monitoring Specialists:** Experts tracking the impact of environmental, social, and governance factors on projects.
      
      **Pain Points:**
      - Difficulty accessing reliable ESG data for smaller investment projects.
      - Inadequate measurement tools to track real-time ESG performance.
      - Demand for transparent audit trails for all ESG investments.
    `,
    image: "/assets/images/zero-green/esg-analysts.webp"
  },

  "Non-Governmental Organisations (NGOs)": {
    title: "Non-Governmental Organisations (NGOs)",
    description: `
      NGOs focused on environmental sustainability that seek funding for eco-friendly initiatives.
      
      **Who They Are:**
      **Environmental NGOs:** Organisations working on reforestation, biodiversity, and conservation efforts.
      **Clean Water Advocates:** NGOs building projects to deliver clean water to underserved communities.
      **Circular Economy Advocates:** Organisations promoting the recycling, reuse, and waste reduction of consumer products.
      
      **Pain Points:**
      - Dependence on unpredictable grant funding and donor contributions.
      - Inability to raise sufficient funds for large-scale green projects.
      - Struggle to prove the impact and financial viability of their initiatives to investors.
    `,
    image: "/assets/images/zero-green/ngo-initiators.webp"
  }
};


export const KeyBenefitsDetails = {
  "Drive Sustainable Impact": {
    title: "Drive Sustainable Impact",
    description: `
      Invest in projects that align with the United Nations Sustainable Development Goals (SDGs), supporting positive environmental, social, and economic change.
      
      **Key Features:**
      **Support for SDG Projects:** Align investments with global initiatives like renewable energy, clean water, and sustainable communities.
      **Triple Impact Approach:** Measure financial, environmental, and social impact for every investment.
      **Direct Positive Change:** Participate in high-impact projects that address key global challenges like climate change and resource scarcity.
    `,
    image: "/assets/images/zero-green/sustainable-impact.webp"
  },

  "Access to Verified Projects": {
    title: "Access to Verified Projects",
    description: `
      Invest only in pre-vetted, high-impact projects that have undergone due diligence for both environmental and financial viability.
      
      **Key Features:**
      **Rigorous Due Diligence:** Projects are evaluated for risk, impact, and financial sustainability before being listed.
      **Pre-Vetted Opportunities:** Only high-potential projects that pass environmental, social, and governance (ESG) standards are included.
      **Investor Confidence:** Gain confidence knowing that all projects are verified and audited for viability and impact.
    `,
    image: "/assets/images/zero-green/verified-projects.webp"
  },

  "Investor Accountability": {
    title: "Investor Accountability",
    description: `
      Milestone-based fund disbursement ensures accountability, with funds released only when project goals are met.
      
      **Key Features:**
      **Milestone-Based Payments:** Funds are disbursed only after specific project goals and milestones are met.
      **Reduced Investment Risk:** Minimise investor risk by tying payments to measurable progress.
      **Increased Project Transparency:** View real-time status updates on project milestones to track the progress of initiatives.
    `,
    image: "/assets/images/zero-green/investor-accountability.webp"
  },

  "AI-Driven Insights": {
    title: "AI-Driven Insights",
    description: `
      Predict project success rates and investor sentiment with AI-driven analytics, empowering better investment decisions.
      
      **Key Features:**
      **Predictive Analytics:** AI forecasts the likelihood of project success using historical and real-time data.
      **Sentiment Analysis:** Understand investor sentiment around projects, helping guide investment choices.
      **Data-Driven Decisions:** Make smarter investment decisions based on AI-driven forecasts and success metrics.
    `,
    image: "/assets/images/zero-green/ai-driven-insights.webp"
  },

  "Investment Diversification": {
    title: "Investment Diversification",
    description: `
      Diversify your portfolio across green sectors such as renewable energy, waste reduction, and sustainable technology.
      
      **Key Features:**
      **Sector-Wide Access:** Invest in a wide range of green projects, including renewable energy, sustainable agriculture, and waste reduction.
      **Portfolio Risk Reduction:** Diversification spreads risk across different sectors to maintain stable returns.
      **Custom Investment Mix:** Create a personalised investment mix across multiple green impact areas.
    `,
    image: "/assets/images/zero-green/investment-diversification.webp"
  },

  "Investor Protection": {
    title: "Investor Protection",
    description: `
      Secure investments using blockchain-powered records, offering investors transparency, immutability, and security of funds.
      
      **Key Features:**
      **Blockchain-Backed Security:** All investment records are stored on a blockchain, ensuring transparency and immutability.
      **Audit Trails:** Maintain a complete audit trail for every financial transaction and milestone.
      **Investor Safeguards:** Protect investor funds with smart contracts that control disbursement and ensure secure payments.
    `,
    image: "/assets/images/zero-green/investor-protection.webp"
  }
};


export const UseCasesDetails = {
  "GreenTech Crowdfunding": {
    title: "GreenTech Crowdfunding",
    description: `
      Connect conscious investors with sustainable projects, enabling fractional investments in impactful green initiatives.
      
      **Key Features:**
      **Fractional Investment Model:** Allows investors to contribute small amounts towards larger green projects.
      **Wide Project Access:** Access to a curated selection of verified green initiatives ready for crowdfunding.
      **Investor-Project Connection:** Direct connection between investors and project initiators for transparency and accountability.
    `,
    image: "/assets/images/zero-green/greentech-crowdfunding.webp"
  },

  "Renewable Energy Projects": {
    title: "Renewable Energy Projects",
    description: `
      Support renewable energy projects, such as solar, wind, and hydroelectric initiatives, to drive global clean energy adoption.
      
      **Key Features:**
      **Clean Energy Investments:** Support green energy projects, including solar, wind, and hydroelectric power plants.
      **ESG Compliance:** Aligns with ESG and Sustainable Development Goals (SDGs) for global climate change mitigation.
      **Investment Opportunities:** Diversify portfolios with renewable energy projects to achieve long-term sustainable returns.
    `,
    image: "/assets/images/zero-green/renewable-energy.webp"
  },

  "Waste Reduction Initiatives": {
    title: "Waste Reduction Initiatives",
    description: `
      Fund projects focused on reducing waste, supporting a circular economy, and promoting sustainable consumption.
      
      **Key Features:**
      **Circular Economy Projects:** Invest in waste reduction and recycling initiatives that support sustainable consumption.
      **Green Innovation:** Back innovative startups and initiatives targeting waste management solutions.
      **Portfolio Diversification:** Add waste reduction initiatives to portfolios for both financial returns and environmental impact.
    `,
    image: "/assets/images/zero-green/waste-reduction.webp"
  },

  "Eco-Friendly Startups": {
    title: "Eco-Friendly Startups",
    description: `
      Back startups developing new sustainable products, services, and green innovations to drive long-term change.
      
      **Key Features:**
      **Support for Green Startups:** Fund early-stage companies developing sustainable products and services.
      **Green Technology Innovations:** Encourage the growth of eco-friendly products, green tech, and sustainable services.
      **Impact-Driven Returns:** Participate in high-growth green ventures for financial returns and environmental impact.
    `,
    image: "/assets/images/zero-green/eco-friendly-startups.webp"
  },

  "Sustainable Agriculture": {
    title: "Sustainable Agriculture",
    description: `
      Invest in agricultural projects promoting organic farming, soil regeneration, and eco-friendly farming techniques.
      
      **Key Features:**
      **Eco-Friendly Farming:** Invest in projects focused on soil health, crop rotation, and sustainable farming techniques.
      **Organic Agriculture:** Support organic farming initiatives to reduce chemical usage and improve soil health.
      **Green Food Supply Chain:** Promote sustainable supply chains for food production and ethical sourcing.
    `,
    image: "/assets/images/zero-green/sustainable-agriculture.webp"
  },

  "Clean Water Initiatives": {
    title: "Clean Water Initiatives",
    description: `
      Fund clean water access projects aimed at providing safe, potable water to underserved communities globally.
      
      **Key Features:**
      **Water Access Projects:** Invest in initiatives that provide clean drinking water to underserved communities.
      **Global Health Impact:** Contribute to health and safety improvements in developing regions by funding clean water projects.
      **ESG Alignment:** Align with Sustainable Development Goal (SDG) 6 – Clean Water and Sanitation for all.
    `,
    image: "/assets/images/zero-green/clean-water-projects.webp"
  }
};


export const SecurityAndComplianceDetails = {
  "KYC & AML Compliance": {
    title: "KYC & AML Compliance",
    description: `
      Ensure regulatory compliance with robust KYC (Know Your Customer) and AML (Anti-Money Laundering) procedures for all users.
      
      **Key Features:**
      **Identity Verification:** Verify user identities to prevent fraudulent activity and protect platform integrity.
      **AML Screening:** Monitor transactions to detect and prevent money laundering activities.
      **Regulatory Compliance:** Adhere to global financial compliance regulations to maintain platform trust.
    `,
    image: "/assets/images/zero-green/kyc-aml-compliance.webp"
  },

  "GDPR & ISO 27001 Compliance": {
    title: "GDPR & ISO 27001 Compliance",
    description: `
      Safeguard user data with adherence to GDPR and ISO 27001 standards, ensuring privacy and information security.
      
      **Key Features:**
      **Data Privacy Controls:** Protect user privacy with GDPR-compliant data processing and storage.
      **ISO 27001 Certification:** Align with global information security standards to guarantee data protection.
      **User Rights Management:** Provide users with full control over their personal data, including access and deletion requests.
    `,
    image: "/assets/images/zero-green/gdpr-compliance.webp"
  },

  "Blockchain Transparency & Auditability": {
    title: "Blockchain Transparency & Auditability",
    description: `
      Ensure complete transparency and accountability with immutable blockchain records and audit trails.
      
      **Key Features:**
      **Immutable Ledger:** All investment records and transactions are stored on a blockchain, ensuring no alterations or deletions.
      **Audit Trail:** Full traceability of every action on the platform for complete accountability.
      **Real-Time Transparency:** Investors and stakeholders can view up-to-date transaction history and project milestones.
    `,
    image: "/assets/images/zero-green/blockchain-transparency-auditability.webp"
  },

  "End-to-End Data Encryption": {
    title: "End-to-End Data Encryption",
    description: `
      Secure all platform communications and sensitive user data using AES-256 encryption to ensure confidentiality.
      
      **Key Features:**
      **AES-256 Encryption:** Encrypt all sensitive data to prevent interception and ensure confidentiality.
      **Secure Communication:** Protect all communications between users and the platform from potential breaches.
      **Data Integrity:** Safeguard data from unauthorised access or tampering through end-to-end encryption.
    `,
    image: "/assets/images/zero-green/end-to-end-encryption.webp"
  },

  "Role-Based Access Control (RBAC)": {
    title: "Role-Based Access Control (RBAC)",
    description: `
      Control access to platform features and sensitive data by implementing role-based access permissions.
      
      **Key Features:**
      **Access Control Policies:** Restrict access to key features and data based on user roles and permissions.
      **Data Security:** Ensure only authorised personnel can access or modify critical platform resources.
      **User Role Management:** Assign and manage access levels for users based on their role within the platform.
    `,
    image: "/assets/images/zero-green/rbac-access-control.webp"
  },

  "Multi-Factor Authentication (MFA)": {
    title: "Multi-Factor Authentication (MFA)",
    description: `
      Add an extra layer of security with multi-factor authentication, ensuring only authorised users access the platform.
      
      **Key Features:**
      **Multi-Factor Security:** Require users to verify their identity using multiple methods, like biometrics or SMS codes.
      **User Authentication:** Strengthen account access security with MFA to prevent unauthorised logins.
      **Enhanced Platform Security:** Mitigate security breaches and unauthorised access attempts using multi-layer verification.
    `,
    image: "/assets/images/zero-green/mfa-security.webp"
  }
};

export const BusinessBenefitsDetails = {
  "Accelerated ESG Compliance": {
    title: "Accelerated ESG Compliance",
    description: `
      Facilitate ESG compliance for corporations and financial institutions through investments in high-impact, sustainable projects.
      
      **Key Features:**
      **Verified ESG Projects:** Backed by ESG-aligned initiatives that contribute to sustainability goals.
      **Regulatory Alignment:** Ensure alignment with international ESG compliance standards like EU SFDR and other regulatory frameworks.
      **Impact-Driven Reporting:** Access real-time impact metrics to demonstrate compliance for reporting purposes.
    `,
    image: "/assets/images/zero-green/esg-compliance.webp"
  },

  "New Revenue Streams from Green Finance": {
    title: "New Revenue Streams from Green Finance",
    description: `
      Unlock new revenue streams by accessing sustainable finance opportunities, including tokenised green bonds, ESG-linked investments, and impact-driven products.
      
      **Key Features:**
      **Green Bond Access:** Provide access to tokenised green bonds for institutional and retail investors.
      **Fractional Investment:** Democratize access to large green projects with fractional investment opportunities.
      **Revenue from ESG-Linked Products:** Offer products like impact-linked loans, sustainable credit lines, and other ESG-related services.
    `,
    image: "/assets/images/zero-green/green-finance-revenue.webp"
  },

  "Reduced Risk with Blockchain Transparency": {
    title: "Reduced Risk with Blockchain Transparency",
    description: `
      Minimise investment risk with blockchain-backed transparency, ensuring milestone-based fund disbursement and full auditability of project transactions.
      
      **Key Features:**
      **Milestone-Triggered Disbursement:** Funds are only released when specific project milestones are met.
      **Immutable Audit Trails:** Blockchain records provide a clear, tamper-proof audit trail for every transaction.
      **Fraud Prevention:** The transparency of blockchain technology minimises fraudulent activity and misappropriation of funds.
    `,
    image: "/assets/images/zero-green/blockchain-transparency.webp"
  },

  "Brand Reputation and CSR Alignment": {
    title: "Brand Reputation and CSR Alignment",
    description: `
      Build a positive brand image and demonstrate commitment to Corporate Social Responsibility (CSR) by supporting sustainability-aligned projects.
      
      **Key Features:**
      **UN SDG Alignment:** Invest in projects aligned with the United Nations Sustainable Development Goals (SDGs).
      **CSR-Driven Investments:** Position your brand as a leader in sustainability and ethical investment practices.
      **Reputation Management:** Showcase your role in sustainability reports, enhancing the company's reputation for responsible investing.
    `,
    image: "/assets/images/zero-green/csr-alignment.webp"
  },

  "Increased Investor Engagement": {
    title: "Increased Investor Engagement",
    description: `
      Boost investor engagement with interactive tools, personalised dashboards, and AI-driven impact reports that provide clear, real-time project tracking.
      
      **Key Features:**
      **Customisable Investor Dashboards:** Provide full visibility of project performance, returns, and milestones.
      **Interactive Insights:** Allow investors to explore and monitor their impact and investment performance in real-time.
      **AI-Driven Impact Reports:** Leverage AI-generated insights to deliver clear, user-friendly reports on impact and ROI.
    `,
    image: "/assets/images/zero-green/investor-engagement.webp"
  },

  "Access to Impact-Driven Capital": {
    title: "Access to Impact-Driven Capital",
    description: `
      Attract capital from institutional investors and mission-driven backers who prioritize impact-driven investment opportunities.
      
      **Key Features:**
      **Mission-Driven Investor Access:** Tap into a growing pool of impact-focused investors.
      **Institutional Partnerships:** Build relationships with institutional investors who prioritize ESG and impact.
      **Capital for Sustainable Projects:** Secure funding for eco-friendly initiatives that generate both impact and returns.
    `,
    image: "/assets/images/zero-green/impact-capital.webp"
  }
};


export const FutureEnhancementsDetails = {
  "DeFi Integration": {
    title: "DeFi Integration",
    description: `
      Leverage Decentralised Finance (DeFi) protocols to enable borderless payments, faster payouts, and access to crypto loans.
      
      **Key Features:**
      **Faster Payouts:** Accelerate payouts to investors using smart contract automation and DeFi liquidity pools.
      **Crypto Loans:** Offer investors access to crypto loans, using their investments as collateral.
      **Borderless Payments:** Facilitate global transactions with borderless payments via stablecoins and DeFi platforms.
    `,
    image: "/assets/images/zero-green/defi-integration.webp"
  },

  "Impact Reporting Tools": {
    title: "Impact Reporting Tools",
    description: `
      Provide in-depth insights into the environmental, social, and financial impact of every funded project.
      
      **Key Features:**
      **Impact Metrics:** Track environmental, social, and governance (ESG) metrics for each project.
      **Visual Impact Reports:** Generate clear, visual reports to highlight the impact and progress of funded projects.
      **Stakeholder Reporting:** Share impact reports with investors and stakeholders to demonstrate accountability.
    `,
    image: "/assets/images/zero-green/impact-reporting.webp"
  },

  "Mobile App Development": {
    title: "Mobile App Development",
    description: `
      Launch a mobile app that allows investors to monitor project updates, manage investments, and receive notifications on the go.
      
      **Key Features:**
      **Investment Tracking:** Real-time tracking of all project updates and milestones.
      **Mobile Investments:** Investors can make new investments directly from their mobile devices.
      **Push Notifications:** Real-time alerts for investment updates, payout schedules, and funding milestones.
    `,
    image: "/assets/images/zero-green/mobile-app.webp"
  },

  "AI-Driven Investor Insights": {
    title: "AI-Driven Investor Insights",
    description: `
      Use AI to deliver personalised investment recommendations based on user behaviour, preferences, and risk appetite.
      
      **Key Features:**
      **Investor Sentiment Analysis:** AI analyses market sentiment and investor activity to predict trends.
      **Personalised Recommendations:** Provide users with investment suggestions aligned with their portfolio goals.
      **Predictive Analytics:** Use predictive analytics to identify top-performing projects and emerging investment opportunities.
    `,
    image: "/assets/images/zero-green/ai-driven-insights.webp"
  },

  "Custom ESG Impact Scoring": {
    title: "Custom ESG Impact Scoring",
    description: `
      Introduce a proprietary ESG (Environmental, Social, and Governance) scoring system to assess the impact of projects before investment.
      
      **Key Features:**
      **ESG Project Scoring:** Score projects on ESG impact to inform investors' decision-making process.
      **Impact Assessment Reports:** Generate reports to showcase a project's social and environmental contributions.
      **Custom Scoring Models:** Custom scoring models tailored to investor-specific sustainability goals and risk tolerance.
    `,
    image: "/assets/images/zero-green/esg-impact-scoring.webp"
  },

  "Tokenised Green Bonds": {
    title: "Tokenised Green Bonds",
    description: `
      Enable fractional ownership of large green infrastructure projects via tokenised green bonds.
      
      **Key Features:**
      **Fractional Ownership:** Allow investors to own fractions of large green infrastructure projects.
      **Tokenised Securities:** Issue bonds as tokenised securities for easy transferability and liquidity.
      **Transparent Investment Structure:** Provide transparency on investment structure and project returns for all stakeholders.
    `,
    image: "/assets/images/zero-green/tokenised-green-bonds.webp"
  }
};

