export const CoreFeatures = [
  {
    title: "Real-Time Staking Rewards",
    description: 
      "Earn rewards instantly for staking tokens, enabling seamless participation in DeFi ecosystems.",
    image: "/assets/images/zero-stake/real-time-rewards.webp",
  },
  {
    title: "Cross-Chain Liquidity Provision",
    description: 
      "Seamlessly provide liquidity across multiple blockchain networks, ensuring flexibility and accessibility.",
    image: "/assets/images/zero-stake/cross-chain-liquidity.webp",
  },
  {
    title: "Governance Portal",
    description: 
      "Engage in platform governance by voting on proposals and shaping the development of Zero-Stake.",
    image: "/assets/images/zero-stake/governance-portal.webp",
  },
  {
    title: "Yield Optimisation",
    description: 
      "Maximise returns on staked assets with AI-driven yield optimisation strategies.",
    image: "/assets/images/zero-stake/yield-optimisation.webp",
  },
  {
    title: "Advanced Analytics",
    description: 
      "Track staking performance and trends with real-time data and insights.",
    image: "/assets/images/zero-stake/advanced-analytics.webp",
  },
  {
    title: "Enhanced Security",
    description: 
      "Protect your assets with blockchain-backed security, smart contract audits, and anomaly detection.",
    image: "/assets/images/zero-stake/enhanced-security.webp",
  },
];


export const TechnicalCapabilities = [
{
  title: "Decentralised Infrastructure",
  description: 
    "Zero-Stake operates on a fully decentralised infrastructure, ensuring transparency, security, and reliability across its network.",
  image: "/assets/images/zero-stake/decentralised-infrastructure.webp",
},
{
  title: "Staking Module Integration",
  description: 
    "Easily integrate staking functionalities with customisable parameters, supporting various token standards.",
  image: "/assets/images/zero-stake/staking-module-integration.webp",
},
{
  title: "Cross-Chain Bridge Support",
  description: 
    "Facilitates seamless token transfers between multiple blockchain networks, enhancing interoperability and liquidity.",
  image: "/assets/images/zero-stake/cross-chain-bridge-support.webp",
},
{
  title: "Smart Contract Automation",
  description: 
    "Leverage automated smart contracts for staking, liquidity management, and governance activities, reducing operational overhead.",
  image: "/assets/images/zero-stake/smart-contract-automation.webp",
},
{
  title: "Advanced Analytics Engine",
  description: 
    "Provides actionable insights with real-time metrics on staking rewards, liquidity performance, and governance participation.",
  image: "/assets/images/zero-stake/advanced-analytics-engine.webp",
},
{
  title: "Blockchain Interoperability",
  description: 
    "Supports leading blockchain networks, including Ethereum, Binance Smart Chain, and Polygon, ensuring seamless integration across ecosystems.",
  image: "/assets/images/zero-stake/blockchain-interoperability.webp",
},
];


export const KeyBenefits = [
{
  title: "Enhanced Staking Rewards",
  description: 
    "Maximise user returns with optimised staking algorithms and flexible reward distribution schedules.",
  image: "/assets/images/zero-stake/enhanced-staking-rewards.webp",
},
{
  title: "Seamless Governance Participation",
  description: 
    "Empower users to vote on proposals and participate in platform decision-making with an intuitive governance system.",
  image: "/assets/images/zero-stake/seamless-governance-participation.webp",
},
{
  title: "Cross-Chain Liquidity",
  description: 
    "Enable liquidity sharing across multiple blockchains, ensuring high availability and reduced transaction costs.",
  image: "/assets/images/zero-stake/cross-chain-liquidity.webp",
},
{
  title: "Real-Time Analytics",
  description: 
    "Provide users with real-time metrics on staking performance, pool health, and governance participation for better decision-making.",
  image: "/assets/images/zero-stake/real-time-analytics.webp",
},
{
  title: "Secure Smart Contracts",
  description: 
    "Utilise audited and secure smart contracts to ensure trustless interactions and eliminate vulnerabilities.",
  image: "/assets/images/zero-stake/secure-smart-contracts.webp",
},
{
  title: "Efficient Resource Allocation",
  description: 
    "Optimise staking pool resources to enhance overall platform performance and reduce operational overhead.",
  image: "/assets/images/zero-stake/resource-allocation.webp",
},
];


export const UseCases = [
{
  title: "Decentralised Staking Platforms",
  description: 
    "Facilitate secure and transparent staking of tokens, ensuring optimal rewards and efficient resource allocation.",
  image: "/assets/images/zero-stake/decentralised-staking-platforms.webp",
},
{
  title: "Governance Token Systems",
  description: 
    "Enable users to vote on proposals and actively participate in decentralised governance mechanisms.",
  image: "/assets/images/zero-stake/governance-token-systems.webp",
},
{
  title: "Cross-Chain Liquidity Solutions",
  description: 
    "Support liquidity provision across multiple blockchains, ensuring seamless token swaps and liquidity sharing.",
  image: "/assets/images/zero-stake/cross-chain-liquidity-solutions.webp",
},
{
  title: "DeFi Yield Farming",
  description: 
    "Provide users with staking opportunities to earn rewards while contributing liquidity to decentralised finance protocols.",
  image: "/assets/images/zero-stake/defi-yield-farming.webp",
},
{
  title: "Enterprise Blockchain Solutions",
  description: 
    "Empower businesses with token staking for supply chain transparency, employee rewards, or secure internal governance.",
  image: "/assets/images/zero-stake/enterprise-blockchain-solutions.webp",
},
{
  title: "Gaming and Metaverse Economies",
  description: 
    "Integrate staking and governance systems within blockchain-based games and virtual worlds for enhanced user engagement.",
  image: "/assets/images/zero-stake/gaming-metaverse-economies.webp",
},
];



export const SecurityAndCompliance = [
{
  title: "Blockchain-Backed Security",
  description: 
    "Ensure transparency and immutability for staking transactions with blockchain-powered security infrastructure.",
  image: "/assets/images/zero-stake/blockchain-security.webp",
},
{
  title: "KYC/AML Integration",
  description: 
    "Perform identity verification and enforce anti-money laundering regulations for secure staking and liquidity operations.",
  image: "/assets/images/zero-stake/kyc-aml-integration.webp",
},
{
  title: "End-to-End Data Encryption",
  description: 
    "Secure all sensitive staking data with robust encryption protocols to prevent unauthorised access.",
  image: "/assets/images/zero-stake/end-to-end-encryption.webp",
},
{
  title: "Smart Contract Auditing",
  description: 
    "All staking smart contracts are audited for vulnerabilities, ensuring platform security and reliability.",
  image: "/assets/images/zero-stake/smart-contract-auditing.webp",
},
{
  title: "Role-Based Access Control (RBAC)",
  description: 
    "Restrict access to administrative functions based on user roles, enhancing operational security.",
  image: "/assets/images/zero-stake/rbac.webp",
},
{
  title: "GDPR & Privacy Compliance",
  description: 
    "Adhere to GDPR and other global privacy regulations by safeguarding user data and ensuring consent-based interactions.",
  image: "/assets/images/zero-stake/gdpr-compliance.webp",
},
];


export const BusinessBenefits = [
{
  title: "Enhanced Stakeholder Confidence",
  description: 
    "Increase trust among stakeholders by offering a secure, transparent staking environment powered by blockchain technology.",
  image: "/assets/images/zero-stake/stakeholder-confidence.webp",
},
{
  title: "Regulatory Compliance Enablement",
  description: 
    "Maintain compliance with global KYC, AML, and GDPR standards, reducing legal risks and ensuring operational continuity.",
  image: "/assets/images/zero-stake/compliance-enablement.webp",
},
{
  title: "Operational Scalability",
  description: 
    "Simplify operations with automated staking and liquidity management tools, reducing overhead and increasing efficiency.",
  image: "/assets/images/zero-stake/operational-scalability.webp",
},
{
  title: "Increased User Retention",
  description: 
    "Boost user engagement with seamless staking options, competitive rewards, and a user-friendly platform interface.",
  image: "/assets/images/zero-stake/user-retention.webp",
},
{
  title: "Revenue from Transaction Fees",
  description: 
    "Generate consistent revenue by leveraging transaction fees and staking commissions, driving sustainable growth.",
  image: "/assets/images/zero-stake/transaction-revenue.webp",
},
{
  title: "Brand Reputation as a Market Leader",
  description: 
    "Establish your platform as a trusted and innovative leader in the DeFi and staking ecosystem, attracting new users and partnerships.",
  image: "/assets/images/zero-stake/brand-reputation.webp",
},
];


export const FutureEnhancements = [
{
  title: "Cross-Chain Staking Integration",
  description: 
    "Enable staking across multiple blockchain networks, such as Ethereum, Solana, and Binance Smart Chain, for enhanced flexibility.",
  image: "/assets/images/zero-stake/cross-chain-staking.webp",
},
{
  title: "Dynamic Reward Mechanisms",
  description: 
    "Introduce adaptive reward systems that adjust based on staking duration, token volume, and market conditions.",
  image: "/assets/images/zero-stake/dynamic-rewards.webp",
},
{
  title: "Governance Token Integration",
  description: 
    "Empower users with governance tokens to influence platform decisions and participate in decentralised governance.",
  image: "/assets/images/zero-stake/governance-token.webp",
},
{
  title: "Real-Time Staking Analytics",
  description: 
    "Provide advanced analytics dashboards to track staking performance, pool health, and reward distribution in real time.",
  image: "/assets/images/zero-stake/staking-analytics.webp",
},
{
  title: "Enhanced Security Audits",
  description: 
    "Implement regular automated and manual audits for smart contracts, ensuring maximum security for staking operations.",
  image: "/assets/images/zero-stake/security-audits.webp",
},
{
  title: "NFT Staking Opportunities",
  description: 
    "Allow users to stake NFTs to earn unique rewards, expanding the use cases for digital assets within the platform.",
  image: "/assets/images/zero-stake/nft-staking.webp",
},
];
