export const CoreFeatures = [
    {
      title: "Hybrid Investment Advisory",
      description: 
        "A hybrid model that combines AI-driven robo-advisors with human financial advisory services, providing users with a balanced approach to wealth management.",
      image: "/assets/images/zero-wealth/hybrid-advisory.webp",
    },
    {
      title: "Custom Portfolio Design",
      description: 
        "Users can create personalised portfolios based on their financial goals, risk tolerance, and ESG investment preferences.",
      image: "/assets/images/zero-wealth/custom-portfolio-design.webp",
    },
    {
      title: "Predictive Market Analytics",
      description: 
        "Leverage AI-driven predictive analytics to forecast market trends, enabling proactive investment decisions.",
      image: "/assets/images/zero-wealth/predictive-market-analytics.webp",
    },
    {
      title: "ESG Investment Tools",
      description: 
        "Enable users to prioritize sustainable investments with ESG (Environmental, Social, and Governance) investment options.",
      image: "/assets/images/zero-wealth/esg-investment-tools.webp",
    },
    {
      title: "Auto-Rebalancing",
      description: 
        "The system automatically rebalances investment portfolios to maintain users' preferred risk and return ratios.",
      image: "/assets/images/zero-wealth/auto-rebalancing.webp",
    },
    {
      title: "Advanced Goal Tracking",
      description: 
        "Users can set and track financial goals with milestone markers and receive insights on progress toward achieving their goals.",
      image: "/assets/images/zero-wealth/goal-tracking.webp",
    }
  ];

export const TechnicalCapabilities = [
    {
      title: "AI-Powered Robo-Advisory",
      description: 
        "Advanced AI models drive robo-advisory services, offering automated investment recommendations based on user preferences and market conditions.",
      image: "/assets/images/zero-wealth/ai-robo-advisory.webp",
    },
    {
      title: "Microservices Architecture",
      description: 
        "Built with a microservices-based architecture for scalability, allowing independent updates to core features and modules.",
      image: "/assets/images/zero-wealth/microservices-architecture.webp",
    },
    {
      title: "Cloud-Native Infrastructure",
      description: 
        "Zero-Wealth runs on cloud platforms like AWS and Azure, ensuring high availability, disaster recovery, and scalability.",
      image: "/assets/images/zero-wealth/cloud-native-infrastructure.webp",
    },
    {
      title: "Blockchain Security",
      description: 
        "Uses blockchain to create tamper-proof transaction logs, ensuring security, transparency, and trust in the system.",
      image: "/assets/images/zero-wealth/blockchain-security.webp",
    },
    {
      title: "AI & Machine Learning Models",
      description: 
        "Predictive models trained on large datasets enable real-time decision-making, risk mitigation, and optimises portfolio strategies.",
      image: "/assets/images/zero-wealth/ai-ml-models.webp",
    },
    {
      title: "Data-Driven Decision Engine",
      description: 
        "Leverages a decision engine to support automated investment suggestions and risk-based optimisation for users.",
      image: "/assets/images/zero-wealth/decision-engine.webp",
    }
  ];

export const KeyBenefits = [
    {
      title: "24/7 Investment Advisory",
      description: 
        "Users have access to AI-driven investment guidance at all times, reducing the need to rely solely on human advisors.",
      image: "/assets/images/zero-wealth/24-7-advisory.webp",
    },
    {
      title: "Reduced Advisory Costs",
      description: 
        "Hybrid advisory services reduce the cost of financial advice, making it accessible to retail investors.",
      image: "/assets/images/zero-wealth/reduced-costs.webp",
    },
    {
      title: "Personalised Investment Plans",
      description: 
        "Custom investment plans are created for users based on their goals, risk appetite, and investment horizons.",
      image: "/assets/images/zero-wealth/personalised-plans.webp",
    },
    {
      title: "Transparent Portfolio Management",
      description: 
        "Users receive real-time transparency on their portfolio's performance, allocation, and potential future growth.",
      image: "/assets/images/zero-wealth/transparent-portfolio.webp",
    },
    {
      title: "On-Demand Human Support",
      description: 
        "In addition to AI guidance, users can connect with human financial advisors for in-depth consultations.",
      image: "/assets/images/zero-wealth/human-support.webp",
    },
    {
      title: "Frictionless User Experience",
      description: 
        "User-friendly interfaces provide seamless onboarding, simple goal tracking, and intuitive portfolio customisation.",
      image: "/assets/images/zero-wealth/frictionless-ux.webp",
    }
  ];

export const UseCases = [
    {
      title: "Novice Investors",
      description: 
        "Beginners can access guided onboarding and receive financial education, enabling them to build and manage their first investment portfolios.",
      image: "/assets/images/zero-wealth/novice-investors.webp",
    },
    {
      title: "Experienced Investors",
      description: 
        "Advanced investors can utilise predictive insights, custom portfolio designs, and personalised goal tracking.",
      image: "/assets/images/zero-wealth/experienced-investors.webp",
    },
    {
      title: "Financial Institutions",
      description: 
        "Banks and financial institutions can offer Zero-Wealth as a co-branded white-label solution for their clients.",
      image: "/assets/images/zero-wealth/financial-institutions.webp",
    },
    {
      title: "Wealth Advisors",
      description: 
        "Wealth advisors use Zero-Wealth to co-manage investments with clients and provide real-time market insights.",
      image: "/assets/images/zero-wealth/wealth-advisors.webp",
    },
    {
      title: "Retirement Planning",
      description: 
        "Users can plan for retirement using long-term investment strategies and receive guidance on risk management.",
      image: "/assets/images/zero-wealth/retirement-planning.webp",
    },
    {
      title: "Institutional Investors",
      description: 
        "Institutional investors benefit from portfolio optimisation, data-driven advisory, and customisable white-label solutions.",
      image: "/assets/images/zero-wealth/institutional-investors.webp",
    }
  ];

export const SecurityAndCompliance = [
    {
      title: "GDPR Compliance",
      description: 
        "Zero-Wealth ensures compliance with GDPR regulations to protect users' personal and financial data through encryption and privacy safeguards.",
      image: "/assets/images/zero-wealth/gdpr-compliance.webp",
    },
    {
      title: "Multi-Factor Authentication (MFA)",
      description: 
        "Users must complete MFA using biometric authentication, SMS codes, or one-time passwords for enhanced login security.",
      image: "/assets/images/zero-wealth/mfa-login.webp",
    },
    {
      title: "Role-Based Access Control (RBAC)",
      description: 
        "Access to system features and user data is controlled through Role-Based Access Control (RBAC), ensuring only authorised personnel can access sensitive information.",
      image: "/assets/images/zero-wealth/rbac-control.webp",
    },
    {
      title: "Data Encryption (AES-256)",
      description: 
        "All user data, including financial transactions and personal information, is encrypted using AES-256 for the highest level of security.",
      image: "/assets/images/zero-wealth/data-encryption.webp",
    },
    {
      title: "Immutable Audit Trail",
      description: 
        "Every financial transaction and system event is recorded on an immutable audit trail, ensuring transparency, traceability, and accountability.",
      image: "/assets/images/zero-wealth/audit-trail.webp",
    },
    {
      title: "ISO 27001 Certification",
      description: 
        "Zero-Wealth aligns with ISO 27001 certification standards, ensuring best practices for data security, information management, and operational risk management.",
      image: "/assets/images/zero-wealth/iso-certification.webp",
    }
  ];

  export const BusinessBenefits = [
    {
      title: "Increased Investment Accessibility",
      description: 
        "Democratise investment opportunities for everyday users by providing access to hybrid advisory services traditionally reserved for high-net-worth clients.",
      image: "/assets/images/zero-wealth/investment-accessibility.webp",
    },
    {
      title: "Revenue Growth from Subscription Services",
      description: 
        "Generate recurring revenue streams through premium subscription plans for advanced advisory tools, ESG investment options, and human advisor support.",
      image: "/assets/images/zero-wealth/revenue-growth.webp",
    },
    {
      title: "Cost Reduction via Automation",
      description: 
        "Reduce operational costs with AI-driven portfolio management, predictive analytics, and automated compliance checks, minimising human intervention.",
      image: "/assets/images/zero-wealth/cost-reduction.webp",
    },
    {
      title: "Client Retention & Loyalty",
      description: 
        "Boost client retention with personalised investment journeys, human advisory support, and AI-driven insights that offer users a unique, tailored experience.",
      image: "/assets/images/zero-wealth/client-loyalty.webp",
    },
    {
      title: "White-Label Revenue Opportunities",
      description: 
        "Offer white-label versions of Zero-Wealth to financial institutions, enabling them to launch co-branded wealth management platforms with ease.",
      image: "/assets/images/zero-wealth/white-label-revenue.webp",
    },
    {
      title: "ESG Investment Growth",
      description: 
        "Capitalise on the rising demand for ESG investment options, attracting conscious investors and aligning with the growing trend of sustainable finance.",
      image: "/assets/images/zero-wealth/esg-growth.webp",
    }
  ];
  
export const FutureEnhancements = [
    {
      title: "AI-Driven Financial Coaching",
      description: 
        "Zero-Wealth plans to introduce AI-powered financial coaching, offering users tailored recommendations and step-by-step guidance to achieve financial goals.",
      image: "/assets/images/zero-wealth/ai-financial-coaching.webp",
    },
    {
      title: "Voice-Activated Portfolio Management",
      description: 
        "Voice-activated features will allow users to rebalance portfolios, check market updates, and receive investment insights through voice commands.",
      image: "/assets/images/zero-wealth/voice-portfolio-management.webp",
    },
    {
      title: "DeFi Integration for Wealth Growth",
      description: 
        "Integration with DeFi platforms will enable users to participate in yield farming, staking, and decentralised lending to diversify wealth strategies.",
      image: "/assets/images/zero-wealth/defi-integration.webp",
    },
    {
      title: "AI-Powered Sentiment Analysis",
      description: 
        "Zero-Wealth will analyse global market sentiment using AI to provide predictive indicators and alerts to users about potential market shifts.",
      image: "/assets/images/zero-wealth/sentiment-analysis.webp",
    },
    {
      title: "Customisable Wealth Dashboards",
      description: 
        "Future enhancements will allow users to personalise their wealth management dashboards, showing only the metrics and data most relevant to them.",
      image: "/assets/images/zero-wealth/customisable-dashboards.webp",
    },
    {
      title: "Augmented Reality (AR) Portfolio Visualisation",
      description: 
        "With AR support, users will be able to visualise their financial portfolios in 3D space, offering a next-generation perspective on investment data.",
      image: "/assets/images/zero-wealth/ar-portfolio-visualisation.webp",
    }
  ];
  