export const CoreFeatureDetails = {
    "User-Centric Design": {
      title: "User-Centric Design",
      description: `
        Zero-Bank’s intuitive design ensures a seamless experience, combining personalisation and accessibility to cater to diverse financial needs.
  
        **Key Features:**
        **Personalised Dashboards**: Tailored financial views with spending insights, savings goals, and budgeting tools.
        **Omni-Channel Experience**: Consistent access across mobile, web, and desktop platforms.
        **Accessibility First**: Designed for inclusivity, supporting multiple languages and adaptive technologies.
      `,
      image: "/assets/images/zero-bank/user-centric-design.webp",
    },
  
    "Security Infrastructure": {
      title: "Security Infrastructure",
      description: `
        Zero-Bank employs cutting-edge security measures to protect user data and transactions.
  
        **Key Features:**
        **Blockchain-Backed Transactions**: Immutable transaction records secured by blockchain technology.
        **Two-Factor Authentication (2FA)**: Adds an extra layer of security for user accounts.
        **Real-Time Fraud Detection**: AI-driven monitoring to identify and mitigate threats instantly.
      `,
      image: "/assets/images/zero-bank/security-infrastructure.webp",
    },
  
    "Financial Inclusion": {
      title: "Financial Inclusion",
      description: `
        Empower underbanked communities by providing essential banking services with reduced barriers.
  
        **Key Features:**
        **Micro-Loans**: Offer small-scale loans to underserved populations with transparent terms.
        **Multi-Currency Support**: Enable seamless transactions in both fiat and cryptocurrencies.
        **Low-Cost Services**: Minimise fees for banking services through blockchain efficiencies.
      `,
      image: "/assets/images/zero-bank/financial-inclusion.webp",
    },
  
    "Innovative Services": {
      title: "Innovative Services",
      description: `
        Redefine banking by integrating cryptocurrencies, DeFi, and AI-powered financial tools into a unified platform.
  
        **Key Features:**
        **Cryptocurrency Management**: Buy, sell, and store cryptocurrencies securely within Zero-Bank.
        **DeFi Integration**: Access lending, staking, and liquidity pools directly.
        **AI-Powered Insights**: Receive personalised investment advice and portfolio recommendations.
      `,
      image: "/assets/images/zero-bank/innovative-services.webp",
    },
  
    "Multi-Currency Wallets": {
      title: "Multi-Currency Wallets",
      description: `
        Manage fiat and cryptocurrencies in one secure and versatile wallet.
  
        **Key Features:**
        **Unified Wallet**: Store, exchange, and transact in multiple currencies from a single account.
        **Instant Currency Conversion**: Transparent and real-time exchange rates for global transactions.
        **Cross-Border Compatibility**: Facilitate global payments and transfers without intermediaries.
      `,
      image: "/assets/images/zero-bank/multi-currency-wallets.webp",
    },
  
    "Smart Contract Automation": {
      title: "Smart Contract Automation",
      description: `
        Automate complex financial processes to enhance efficiency and reduce errors.
  
        **Key Features:**
        **Automated Loan Disbursements**: Smart contracts release funds upon meeting predefined criteria.
        **Payment Scheduling**: Automate recurring payments and settlements.
        **Compliance Checks**: Built-in KYC/AML verification for regulatory adherence.
      `,
      image: "/assets/images/zero-bank/smart-contract-automation.webp",
    },
  };

  
  export const SecurityAndComplianceDetails = {
    "Blockchain-Backed Transactions": {
      title: "Blockchain-Backed Transactions",
      description: `
        Leverage the transparency and trust of blockchain technology to secure every financial transaction.
  
        **Key Features:**
        **Immutable Records**: Every transaction is permanently recorded on the blockchain, ensuring traceability and preventing tampering.
        **Transparent Operations**: Blockchain ensures all stakeholders have a clear view of transactions in real time.
        **Enhanced Trust**: Builds confidence in the system by eliminating the need for intermediaries.
      `,
      image: "/assets/images/zero-bank/blockchain-transactions.webp",
    },
  
    "End-to-End Encryption": {
      title: "End-to-End Encryption",
      description: `
        Protect sensitive data with state-of-the-art encryption for complete security in transit and at rest.
  
        **Key Features:**
        **AES-256 Encryption**: Industry-standard encryption for securing user data.
        **Data Integrity**: Ensures that stored and transmitted data is never altered or accessed by unauthorised entities.
        **Secure Communication Channels**: Protects communication between clients and servers with SSL/TLS protocols.
      `,
      image: "/assets/images/zero-bank/encryption.webp",
    },
  
    "Two-Factor Authentication (2FA)": {
      title: "Two-Factor Authentication (2FA)",
      description: `
        Add an extra layer of security to user accounts with robust two-factor authentication.
  
        **Key Features:**
        **Second Layer Verification**: Uses OTPs, biometric authentication, or email confirmations to verify user identity.
        **Enhanced Account Security**: Protects against unauthorised access, even if login credentials are compromised.
        **Flexible Authentication Options**: Supports various 2FA methods tailored to user preferences.
      `,
      image: "/assets/images/zero-bank/2fa.webp",
    },
  
    "Real-Time Fraud Detection": {
      title: "Real-Time Fraud Detection",
      description: `
        Detect and prevent fraudulent activities instantly with AI-driven fraud detection tools.
  
        **Key Features:**
        **Anomaly Detection**: Identifies unusual patterns and flags potential fraud in real time.
        **Automated Alerts**: Notifies users and administrators of suspicious transactions immediately.
        **Adaptive Learning**: Continuously updates detection models to stay ahead of evolving threats.
      `,
      image: "/assets/images/zero-bank/fraud-detection.webp",
    },
  
    "Zero Trust Architecture": {
      title: "Zero Trust Architecture",
      description: `
        Secure the system by enforcing strict verification protocols at every access point, reducing risks from both internal and external threats.
  
        **Key Features:**
        **Identity Verification**: Requires validation of user identity at every access attempt.
        **Granular Access Control**: Restricts system access to only what is necessary for each user.
        **Threat Mitigation**: Prevents lateral movement within the system, containing potential breaches.
      `,
      image: "/assets/images/zero-bank/zero-trust.webp",
    },
  
    "KYC & AML Compliance": {
      title: "KYC & AML Compliance",
      description: `
        Ensure adherence to global regulatory standards with built-in KYC and AML compliance tools.
  
        **Key Features:**
        **Identity Verification**: Validates user identity during onboarding to meet KYC requirements.
        **Transaction Monitoring**: Tracks financial transactions to detect and report suspicious activities.
        **Automated Compliance**: Simplifies regulatory reporting, reducing manual intervention and compliance risks.
      `,
      image: "/assets/images/zero-bank/kyc-aml.webp",
    },
  };

  
  export const PaymentAndIntegrationDetails = {
    "Seamless API Integration": {
      title: "Seamless API Integration",
      description: `
        Connect with external payment gateways and third-party services effortlessly using Zero-Bank’s well-documented APIs.
  
        **Key Features:**
        **Extensive Documentation**: Comprehensive API guides for quick and easy integration.
        **Flexible Endpoints**: Supports a variety of use cases, including payments, account management, and reporting.
        **Secure Access**: API keys and authentication ensure data security during integration.
      `,
      image: "/assets/images/zero-bank/api-integration.webp",
    },
  
    "Interoperability": {
      title: "Interoperability",
      description: `
        Seamlessly link with major payment providers like PayPal, Visa, and MasterCard for fast, secure transactions.
  
        **Key Features:**
        **Global Network Compatibility**: Connect to a wide range of payment providers and financial institutions.
        **Instant Settlements**: Enable faster transaction completions without intermediaries.
        **Standardised Protocols**: Ensures compatibility with leading payment standards.
      `,
      image: "/assets/images/zero-bank/interoperability.webp",
    },
  
    "Custom Integrations": {
      title: "Custom Integrations",
      description: `
        Build tailored financial workflows by integrating Zero-Bank’s capabilities directly into your business processes.
  
        **Key Features:**
        **Bespoke Solutions**: Adapt Zero-Bank features to suit specific organisational needs.
        **Streamlined Operations**: Automate routine financial tasks with custom workflows.
        **Developer Support**: Access SDKs and dedicated support for seamless integration.
      `,
      image: "/assets/images/zero-bank/custom-integrations.webp",
    },
  
    "Payment Gateway Support": {
      title: "Payment Gateway Support",
      description: `
        Integrate with major payment gateways to facilitate secure, fast, and frictionless global payments.
  
        **Key Features:**
        **Multi-Gateway Compatibility**: Works with Stripe, PayPal, Square, and more.
        **Enhanced Security**: Uses end-to-end encryption to protect sensitive payment data.
        **Scalability**: Supports high transaction volumes for growing businesses.
      `,
      image: "/assets/images/zero-bank/payment-gateway-support.webp",
    },
  
    "Cross-Border Payments": {
      title: "Cross-Border Payments",
      description: `
        Enable real-time, low-cost cross-border payments using blockchain technology for settlement.
  
        **Key Features:**
        **Blockchain Settlement**: Eliminates intermediaries, reducing costs and delays.
        **Multi-Currency Support**: Handle transactions in fiat and cryptocurrencies.
        **Regulatory Compliance**: Adheres to global financial regulations for cross-border transactions.
      `,
      image: "/assets/images/zero-bank/cross-border-payments.webp",
    },
  
    "QR Code Payments": {
      title: "QR Code Payments",
      description: `
        Generate and scan QR codes for instant payments, enhancing user and merchant convenience.
  
        **Key Features:**
        **Instant Transactions**: Simplifies payments for everyday purchases with quick QR code scanning.
        **Customisable Codes**: Generate payment codes for specific amounts or transactions.
        **Wide Adoption**: Compatible with most mobile banking apps and payment systems.
      `,
      image: "/assets/images/zero-bank/qr-code-payments.webp",
    },
  };

  
  export const UserExperienceDetails = {
    "Personalised Dashboards": {
      title: "Personalised Dashboards",
      description: `
        Custom dashboards provide users with a clear and concise view of their financial data, tailored to their preferences.
  
        **Key Features:**
        **Financial Overview**: Displays balances, expenses, and investment portfolios in an organised manner.
        **Widgets**: Add or remove widgets for a personalised experience.
        **Interactive Insights**: Access detailed charts and graphs for better financial tracking.
      `,
      image: "/assets/images/zero-bank/personalised-dashboard.webp",
    },
  
    "Omni-Channel Experience": {
      title: "Omni-Channel Experience",
      description: `
        Ensure consistent access to Zero-Bank across mobile, desktop, and tablet devices for a seamless experience.
  
        **Key Features:**
        **Device Syncing**: Access your account across multiple devices without interruptions.
        **Responsive Design**: Optimised layouts for every screen size.
        **Cross-Platform Integration**: Switch between devices while maintaining active sessions.
      `,
      image: "/assets/images/zero-bank/omni-channel-experience.webp",
    },
  
    "Intuitive Navigation": {
      title: "Intuitive Navigation",
      description: `
        Enjoy a user-friendly interface that allows seamless transitions between banking, payments, and investment features.
  
        **Key Features:**
        **Streamlined Menus**: Simplified menu structures for quick access to core functionalities.
        **Quick Actions**: Perform transactions, transfers, or payments with minimal clicks.
        **Search Functionality**: Find services or transactions instantly with an integrated search bar.
      `,
      image: "/assets/images/zero-bank/intuitive-navigation.webp",
    },
  
    "Real-Time Notifications": {
      title: "Real-Time Notifications",
      description: `
        Stay updated with real-time alerts for deposits, withdrawals, payments, and other critical account activities.
  
        **Key Features:**
        **Custom Alerts**: Set preferences for specific notifications like low balance alerts or large transactions.
        **Instant Updates**: Receive updates as they happen for peace of mind.
        **Multi-Channel Delivery**: Notifications sent via app, email, or SMS.
      `,
      image: "/assets/images/zero-bank/real-time-notifications.webp",
    },
  
    "Customisable Themes": {
      title: "Customisable Themes",
      description: `
        Tailor the look and feel of your dashboard with various themes and display modes to suit your style.
  
        **Key Features:**
        **Light & Dark Modes**: Toggle between visual modes for comfort and usability.
        **Theme Variety**: Choose from multiple colour schemes and layouts.
        **User Profiles**: Save custom themes for individual user accounts.
      `,
      image: "/assets/images/zero-bank/customisable-themes.webp",
    },
  
    "Accessibility Features": {
      title: "Accessibility Features",
      description: `
        Zero-Bank is designed to be inclusive, offering tools for users with different accessibility needs.
  
        **Key Features:**
        **Screen Reader Support**: Full compatibility with popular screen readers.
        **High-Contrast Mode**: Enhanced readability for visually impaired users.
        **Keyboard Navigation**: Perform all actions using only the keyboard for improved accessibility.
      `,
      image: "/assets/images/zero-bank/accessibility-features.webp",
    },
  };

  
  export const FinancialProductsAndServicesDetails = {
    "Crypto Integration": {
      title: "Crypto Integration",
      description: `
        Manage and trade cryptocurrencies like Bitcoin, Ethereum, and other altcoins directly within the Zero-Bank platform.
  
        **Key Features:**
        **Comprehensive Support**: Access major cryptocurrencies alongside a range of altcoins.
        **Seamless Wallet Management**: Store, send, and receive cryptocurrencies with integrated wallets.
        **Real-Time Trading**: Buy and sell crypto with live market data and minimal latency.
      `,
      image: "/assets/images/zero-bank/crypto-integration.webp",
    },
  
    "DeFi Services": {
      title: "DeFi Services",
      description: `
        Unlock decentralised financial opportunities like staking, yield farming, and liquidity provision through Zero-Bank.
  
        **Key Features:**
        **Staking Opportunities**: Earn rewards by locking crypto in DeFi protocols.
        **Yield Farming**: Participate in liquidity pools to maximise returns.
        **Decentralised Lending**: Access peer-to-peer lending with secure smart contracts.
      `,
      image: "/assets/images/zero-bank/defi-services.webp",
    },
  
    "AI-Powered Investment Advice": {
      title: "AI-Powered Investment Advice",
      description: `
        Leverage advanced AI algorithms for market analysis and personalised investment recommendations.
  
        **Key Features:**
        **Market Forecasts**: Get predictive insights powered by machine learning.
        **Tailored Strategies**: Receive investment advice based on your financial goals and risk appetite.
        **Continuous Learning**: Benefit from AI models that evolve with market trends.
      `,
      image: "/assets/images/zero-bank/ai-investment-advice.webp",
    },
  
    "Digital Asset Management": {
      title: "Digital Asset Management",
      description: `
        Manage traditional financial assets like stocks and bonds alongside cryptocurrencies within a unified platform.
  
        **Key Features:**
        **Unified Dashboard**: View and manage all assets in one place.
        **Performance Tracking**: Monitor the performance of both traditional and digital investments.
        **Diversification Tools**: Balance portfolios across asset classes with ease.
      `,
      image: "/assets/images/zero-bank/digital-asset-management.webp",
    },
  
    "Wealth Management Tools": {
      title: "Wealth Management Tools",
      description: `
        Access comprehensive wealth management services like portfolio tracking, savings goals, and tailored investment strategies.
  
        **Key Features:**
        **Portfolio Insights**: Track your portfolio's performance over time.
        **Savings Goals**: Set and monitor financial targets with automated progress tracking.
        **Strategic Planning**: Use expert tools to build long-term investment strategies.
      `,
      image: "/assets/images/zero-bank/wealth-management.webp",
    },
  
    "Insurance Products": {
      title: "Insurance Products",
      description: `
        Explore life, travel, and health insurance plans via Zero-Bank's integrated insurance marketplace.
  
        **Key Features:**
        **In-App Marketplace**: Browse and compare insurance policies tailored to your needs.
        **Seamless Integration**: Manage insurance policies alongside financial accounts.
        **Quick Claims**: Submit and track claims through the app for added convenience.
      `,
      image: "/assets/images/zero-bank/insurance-products.webp",
    },
  };

  
  export const SystemArchitectureDetails = {
    "Microservices Architecture": {
      title: "Microservices Architecture",
      description: `
        Zero-Bank operates on a microservices architecture, where each feature functions as an independent service.
  
        **Key Features:**
        **Agile Development**: Enables faster updates and deployment cycles by isolating feature-level services.
        **Improved Scalability**: Scale individual services independently to meet user demand.
        **Fault Isolation**: Minimise the impact of failures by isolating issues to specific services.
      `,
      image: "/assets/images/zero-bank/microservices.webp",
    },
  
    "API Gateway": {
      title: "API Gateway",
      description: `
        A robust API Gateway ensures seamless integration with third-party providers while maintaining strong security controls.
  
        **Key Features:**
        **Secure Access**: Protects against unauthorised access with API-level security controls.
        **Third-Party Integration**: Facilitates smooth interaction with external services and payment gateways.
        **Centralised Management**: Monitors and manages all API traffic in one place.
      `,
      image: "/assets/images/zero-bank/api-gateway.webp",
    },
  
    "Blockchain Integration": {
      title: "Blockchain Integration",
      description: `
        Zero-Bank integrates blockchain technology for secure, transparent, and immutable transaction recording.
  
        **Key Features:**
        **Immutable Ledger**: Ensures transactions are tamper-proof and transparent.
        **Smart Contracts**: Automate processes like loan disbursements and compliance checks.
        **Trust and Transparency**: Builds user confidence by leveraging blockchain for financial records.
      `,
      image: "/assets/images/zero-bank/blockchain-integration.webp",
    },
  
    "Data Analytics Engine": {
      title: "Data Analytics Engine",
      description: `
        A powerful data analytics engine provides users with real-time insights to make informed financial decisions.
  
        **Key Features:**
        **Real-Time Data**: Analyse transactions and account activities instantly.
        **User Notifications**: Deliver actionable alerts and insights to keep users informed.
        **AI-Driven Insights**: Leverage machine learning to identify spending trends and optimise savings.
      `,
      image: "/assets/images/zero-bank/data-analytics.webp",
    },
  
    "Load Balancer": {
      title: "Load Balancer",
      description: `
        Distributes traffic evenly across servers to ensure optimal performance and availability, even under heavy load.
  
        **Key Features:**
        **High Availability**: Minimises downtime by efficiently distributing server traffic.
        **Improved Response Times**: Ensures consistent performance during traffic spikes.
        **Disaster Recovery**: Redirects traffic to healthy servers during system failures.
      `,
      image: "/assets/images/zero-bank/load-balancer.webp",
    },
  
    "Cloud-Native Infrastructure": {
      title: "Cloud-Native Infrastructure",
      description: `
        Built on AWS, GCP, and Azure for global availability, failover, and disaster recovery, Zero-Bank ensures uninterrupted service.
  
        **Key Features:**
        **Global Reach**: Offers fast and reliable access from any location.
        **Disaster Recovery**: Safeguards user data with automatic failover systems.
        **Elastic Scalability**: Adapts to user demand with on-demand cloud resources.
      `,
      image: "/assets/images/zero-bank/cloud-infrastructure.webp",
    },
  };

  
  export const SustainabilityAndESGDetails = {
    "Energy-Efficient Infrastructure": {
      title: "Energy-Efficient Infrastructure",
      description: `
        Zero-Bank leverages energy-efficient cloud providers and eco-friendly blockchain protocols to minimise environmental impact.
  
        **Key Features:**
        **Green Cloud Providers**: Partners with AWS, GCP, and Azure, ensuring minimal energy usage.
        **Eco-Friendly Blockchain**: Utilises protocols optimised for lower energy consumption, such as Proof-of-Stake.
        **Carbon-Conscious Development**: Designs software solutions with sustainability at their core.
      `,
      image: "/assets/images/zero-bank/energy-efficient.webp",
    },
  
    "Green Partnerships": {
      title: "Green Partnerships",
      description: `
        Zero-Bank collaborates with eco-friendly initiatives to promote green finance and sustainable development.
  
        **Key Features:**
        **Eco-Finance Initiatives**: Partners with organisations driving green finance projects.
        **Community Support**: Engages in community-driven sustainability efforts.
        **Global Outreach**: Expands partnerships to support worldwide environmental causes.
      `,
      image: "/assets/images/zero-bank/green-partnerships.webp",
    },
  
    "Carbon Footprint Reduction": {
      title: "Carbon Footprint Reduction",
      description: `
        Zero-Bank actively tracks and reduces its carbon emissions, aligning with global net-zero emission goals.
  
        **Key Features:**
        **Carbon Tracking**: Measures emissions across all operational activities.
        **Emission Reduction Programs**: Implements initiatives to cut energy use and waste.
        **Net-Zero Commitment**: Adheres to international standards for sustainable finance.
      `,
      image: "/assets/images/zero-bank/carbon-reduction.webp",
    },
  
    "Sustainable Investment Options": {
      title: "Sustainable Investment Options",
      description: `
        Users can invest in ESG-compliant projects and green bonds through Zero-Bank's investment platform.
  
        **Key Features:**
        **Green Bonds**: Provides access to bonds funding renewable energy and sustainable infrastructure.
        **ESG-Compliant Funds**: Curates investment opportunities aligned with environmental and social governance standards.
        **Impact Metrics**: Displays the environmental impact of each investment choice.
      `,
      image: "/assets/images/zero-bank/sustainable-investments.webp",
    },
  
    "Paperless Banking": {
      title: "Paperless Banking",
      description: `
        Zero-Bank promotes sustainability by adopting paperless processes such as e-statements and e-signatures.
  
        **Key Features:**
        **E-Statements**: Digital account statements replace paper, reducing waste.
        **E-Signatures**: Enable secure, eco-friendly contract signing.
        **Digital Processes**: Streamlines banking operations without physical paperwork.
      `,
      image: "/assets/images/zero-bank/paperless-banking.webp",
    },
  
    "ESG Reporting & Transparency": {
      title: "ESG Reporting & Transparency",
      description: `
        Zero-Bank publishes detailed ESG impact reports, tracking sustainability goals, carbon reduction, and green initiatives.
  
        **Key Features:**
        **Annual ESG Reports**: Provides insights into sustainability progress and goals.
        **Transparent Metrics**: Shares measurable outcomes for all green initiatives.
        **Compliance Monitoring**: Ensures adherence to global ESG standards.
      `,
      image: "/assets/images/zero-bank/esg-reporting.webp",
    },
  };

  
  export const CommunityEngagementDetails = {
    "Open Feedback Loops": {
      title: "Open Feedback Loops",
      description: `
        Zero-Bank encourages user participation by collecting feedback and feature suggestions to improve its products and services.
  
        **Key Features:**
        **User Surveys**: Periodic surveys to gather user opinions and insights.
        **Feature Voting**: Users can vote on proposed features to prioritise development efforts.
        **Transparent Updates**: Regular updates on how user feedback is being implemented.
      `,
      image: "/assets/images/zero-bank/feedback-loops.webp",
    },
  
    "User Forums": {
      title: "User Forums",
      description: `
        A dedicated space for users to discuss topics, share knowledge, and support each other within the Zero-Bank community.
  
        **Key Features:**
        **Discussion Boards**: Channels for general discussions, troubleshooting, and idea sharing.
        **Community Moderation**: Forums are managed by both staff and community members.
        **Resource Sharing**: Users can share tips, guides, and resources for financial tools.
      `,
      image: "/assets/images/zero-bank/user-forums.webp",
    },
  
    "Developer Support": {
      title: "Developer Support",
      description: `
        Zero-Bank provides extensive support to external developers building on its platform through APIs and resources.
  
        **Key Features:**
        **API Documentation**: Comprehensive guides for integrating Zero-Bank services.
        **Developer Forums**: A space for developers to seek help and share solutions.
        **Sandbox Environment**: A secure environment for testing applications.
      `,
      image: "/assets/images/zero-bank/developer-support.webp",
    },
  
    "Community Hackathons": {
      title: "Community Hackathons",
      description: `
        Regular hackathons hosted by Zero-Bank to foster innovation, with rewards for user-generated features and tools.
  
        **Key Features:**
        **Thematic Challenges**: Focused on topics like DeFi, financial literacy, and social impact.
        **Prizes and Recognition**: Monetary rewards and public recognition for winners.
        **Collaboration Opportunities**: Encourages teamwork and networking among participants.
      `,
      image: "/assets/images/zero-bank/hackathons.webp",
    },
  
    "Educational Webinars": {
      title: "Educational Webinars",
      description: `
        Free webinars offered by Zero-Bank to educate users on financial literacy, blockchain, and DeFi concepts.
  
        **Key Features:**
        **Expert Sessions**: Hosted by financial and blockchain experts.
        **Interactive Q&A**: Participants can engage directly with presenters.
        **On-Demand Access**: Recordings available for users who miss live sessions.
      `,
      image: "/assets/images/zero-bank/educational-webinars.webp",
    },
  
    "Social Responsibility Initiatives": {
      title: "Social Responsibility Initiatives",
      description: `
        Zero-Bank actively contributes to social welfare projects, including financial literacy programs and community development initiatives.
  
        **Key Features:**
        **Financial Literacy Campaigns**: Workshops and materials aimed at improving user understanding of finance.
        **Charitable Contributions**: Supports local and global social welfare projects.
        **Partnerships with NGOs**: Collaborates with non-profits for broader impact.
      `,
      image: "/assets/images/zero-bank/social-responsibility.webp",
    },
  };
  
