export const CoreFeatureDetails = {
    "AI-Powered Insights": {
      title: "AI-Powered Insights",
      description: `
        Leverage the power of artificial intelligence to make informed investment decisions.
        
        **Key Features:**
        **Real-Time Analysis:** Monitor stock trends and market data in real time.
        **Predictive Modeling:** Anticipate stock price movements using advanced AI algorithms.
        **Personalised Strategies:** Receive investment recommendations tailored to your goals and risk profile.
      `,
      image: "/assets/images/zero-stocks/ai-powered-insights.webp"
    },
  
    "Customisable Portfolio Management": {
      title: "Customisable Portfolio Management",
      description: `
        Manage and monitor your investments with ease through a customisable dashboard.
        
        **Key Features:**
        **Risk Profiling:** Assess and optimise portfolio risks.
        **Diversification Alerts:** Identify gaps and opportunities in your portfolio.
        **Real-Time Tracking:** Get live updates on portfolio performance.
      `,
      image: "/assets/images/zero-stocks/portfolio-management.webp"
    },
  
    "Technical Analysis & Charting Tools": {
      title: "Technical Analysis & Charting Tools",
      description: `
        Gain deeper insights into the market with interactive tools and analytics.
        
        **Key Features:**
        **Technical Indicators:** Use tools like moving averages, RSI, and Bollinger Bands.
        **Interactive Charts:** Visualise stock performance with dynamic, mobile-friendly charts.
        **Advanced Analytics:** Access data-backed insights for smarter trading decisions.
      `,
      image: "/assets/images/zero-stocks/technical-analysis.webp"
    },
  
    "Sentiment Analysis": {
      title: "Sentiment Analysis",
      description: `
        Stay ahead of the market by tracking shifts in public sentiment.
        
        **Key Features:**
        **NLP Integration:** Analyze financial news and market sentiment.
        **Trend Detection:** Spot emerging trends before they impact stock prices.
        **Sentiment Alerts:** Receive notifications about significant sentiment shifts.
      `,
      image: "/assets/images/zero-stocks/sentiment-analysis.webp"
    },
  
    "Personalised Investment Strategies": {
      title: "Personalised Investment Strategies",
      description: `
        Align your investments with AI-driven strategies tailored to your needs.
        
        **Key Features:**
        **Goal-Oriented Plans:** Customise strategies based on short- or long-term objectives.
        **Behavioral Analysis:** AI adapts to your investment habits for smarter recommendations.
        **Risk-Adjusted Returns:** Maximise returns while keeping risks within acceptable levels.
      `,
      image: "/assets/images/zero-stocks/personalised-strategies.webp"
    },
  
    "Portfolio Diversification Alerts": {
      title: "Portfolio Diversification Alerts",
      description: `
        Ensure optimal risk-return ratios with timely diversification advice.
        
        **Key Features:**
        **Gap Analysis:** Identify unbalanced sectors in your portfolio.
        **Rebalancing Suggestions:** Receive actionable insights to enhance diversification.
        **Automatic Alerts:** Stay informed about diversification opportunities.
      `,
      image: "/assets/images/zero-stocks/diversification-alerts.webp"
    }
  };

  
  export const TechnicalCapabilitiesDetails = {
    "Microservices Architecture": {
      title: "Microservices Architecture",
      description: `
        Build a scalable and reliable platform with a microservices architecture, enabling independent services for various operations.
        
        **Key Features:**
        **Independent Services:** Modular design for portfolio management, market data, and compliance.
        **Scalability:** Scale individual services based on usage and demand.
        **Seamless Updates:** Update services without impacting the entire system.
        **High Availability:** Ensure uninterrupted access to the platform.
      `,
      image: "/assets/images/zero-stocks/microservices-architecture.webp"
    },
  
    "Cloud-Native Infrastructure": {
      title: "Cloud-Native Infrastructure",
      description: `
        Leverage the power of cloud platforms like AWS, GCP, and Azure for enhanced reliability and scalability.
        
        **Key Features:**
        **Global Reach:** Deploy globally to ensure accessibility anywhere.
        **Disaster Recovery:** Built-in failover mechanisms for robust disaster recovery.
        **High Availability:** Minimised downtime with cloud-based scalability.
      `,
      image: "/assets/images/zero-stocks/cloud-infrastructure.webp"
    },
  
    "AI-Powered Predictive Models": {
      title: "AI-Powered Predictive Models",
      description: `
        Predict market trends and stock price movements with advanced AI-powered models.
        
        **Key Features:**
        **Historical Data Analysis:** Analyze historical trends for pattern identification.
        **Sentiment Analysis Integration:** Incorporate market sentiment into predictions.
        **Actionable Insights:** Provide users with predictive insights to inform investment decisions.
      `,
      image: "/assets/images/zero-stocks/ai-predictive-models.webp"
    },
  
    "Event-Driven Notifications": {
      title: "Event-Driven Notifications",
      description: `
        Stay informed with real-time notifications triggered by key market events.
        
        **Key Features:**
        **Stock Price Alerts:** Receive updates on stock price changes and milestones.
        **Market News:** Notifications for breaking news that impacts investments.
        **Portfolio Updates:** Get alerted about portfolio performance and diversification opportunities.
      `,
      image: "/assets/images/zero-stocks/event-driven-notifications.webp"
    },
  
    "Cross-Platform Compatibility": {
      title: "Cross-Platform Compatibility",
      description: `
        Enjoy a consistent and unified experience across all your devices with cross-platform compatibility.
        
        **Key Features:**
        **iOS & Android Support:** Access Zero-Stocks on both mobile operating systems.
        **Unified Design:** Maintain the same user interface across devices.
        **Responsive Performance:** Optimised performance for mobile devices.
      `,
      image: "/assets/images/zero-stocks/cross-platform.webp"
    },
  
    "Data Lakes & Warehousing": {
      title: "Data Lakes & Warehousing",
      description: `
        Centralise data storage for better analytics, backtesting, and decision-making.
        
        **Key Features:**
        **Centralised Data:** Store large volumes of historical and real-time data.
        **AI Integration:** Enable AI models to analyze and derive insights from data.
        **Backtesting Tools:** Use historical data for testing trading strategies and algorithms.
      `,
      image: "/assets/images/zero-stocks/data-lake.webp"
    }
  };

  
  export const KeyBenefitsDetails = {
    "Real-Time Market Insights": {
      title: "Real-Time Market Insights",
      description: `
        Stay ahead of the market with real-time data streams providing instant updates on stock price movements and trends.
        
        **Key Features:**
        **Real-Time Feeds:** Access live market data for actionable insights.
        **Market Trends Analysis:** Identify emerging trends to make proactive decisions.
        **Competitive Advantage:** Gain an edge with up-to-the-minute updates.
      `,
      image: "/assets/images/zero-stocks/real-time-insights.webp"
    },
  
    "Actionable AI-Driven Signals": {
      title: "Actionable AI-Driven Signals",
      description: `
        Make informed investment decisions with personalised buy, hold, or sell recommendations powered by AI.
        
        **Key Features:**
        **AI Signal Generation:** Use machine learning models trained on historical data.
        **Personalised Recommendations:** Tailored advice based on user investment goals.
        **Market-Specific Strategies:** Receive actionable signals for diverse market conditions.
      `,
      image: "/assets/images/zero-stocks/actionable-signals.webp"
    },
  
    "Faster, Smarter Decisions": {
      title: "Faster, Smarter Decisions",
      description: `
        Leverage predictive analytics and sentiment analysis to make smarter, faster investment decisions.
        
        **Key Features:**
        **Predictive Analytics:** Forecast market movements for proactive decision-making.
        **Sentiment Analysis:** Understand market sentiment shifts before they occur.
        **Personalised Risk Assessments:** Evaluate and minimise investment risks.
      `,
      image: "/assets/images/zero-stocks/smart-decisions.webp"
    },
  
    "Mobile-First User Experience": {
      title: "Mobile-First User Experience",
      description: `
        Enjoy an intuitive and responsive user interface optimised for mobile devices.
        
        **Key Features:**
        **Responsive Design:** Seamless navigation across all screen sizes.
        **Mobile Optimisation:** Fast performance and efficient use of mobile resources.
        **User-Friendly Interface:** Intuitive design for enhanced usability.
      `,
      image: "/assets/images/zero-stocks/mobile-first.webp"
    },
  
    "AI-Driven Personalisation": {
      title: "AI-Driven Personalisation",
      description: `
        Benefit from AI-tailored investment strategies designed to meet individual user preferences and goals.
        
        **Key Features:**
        **Custom-Tailored Advice:** Personalised strategies based on risk tolerance and goals.
        **Behavioral Analysis:** Adapt recommendations based on user behavior.
        **Dynamic Updates:** Continuously optimised advice reflecting market changes.
      `,
      image: "/assets/images/zero-stocks/ai-personalisation.webp"
    },
  
    "Reduced Investment Risk": {
      title: "Reduced Investment Risk",
      description: `
        Minimise risk exposure with AI-driven tools that provide diversification alerts and advanced risk analysis.
        
        **Key Features:**
        **Diversification Alerts:** Identify opportunities to balance portfolios.
        **Risk Analysis:** Evaluate potential market volatility and investment threats.
        **Optimised Portfolios:** Ensure portfolios align with optimal risk-return ratios.
      `,
      image: "/assets/images/zero-stocks/risk-reduction.webp"
    }
  };

  
  export const UseCasesDetails = {
    "Beginner Investors": {
      title: "Beginner Investors",
      description: `
        Empower new investors with the tools and guidance they need to confidently enter the stock market.
        
        **Key Features:**
        **Educational Resources:** Learn the basics of trading with tutorials and guided walkthroughs.
        **Personalised Guidance:** AI-powered recommendations tailored for low-risk entry points.
        **Simplified Interface:** Easy-to-use dashboards designed for beginners.
      `,
      image: "/assets/images/zero-stocks/beginner-investors.webp"
    },
  
    "Experienced Traders": {
      title: "Experienced Traders",
      description: `
        Enhance trading strategies with advanced tools and in-depth market insights.
        
        **Key Features:**
        **Advanced Charting Tools:** Access technical indicators, trend lines, and candlestick charts.
        **Execution Support:** Optimise trade execution with predictive analytics.
        **Market Insights:** Leverage AI-driven insights for better decision-making.
      `,
      image: "/assets/images/zero-stocks/experienced-traders.webp"
    },
  
    "Financial Advisors": {
      title: "Financial Advisors",
      description: `
        Enable financial advisors to optimise client portfolios using AI-driven insights and predictive models.
        
        **Key Features:**
        **Portfolio Management:** Manage multiple client portfolios with ease.
        **Predictive Models:** Use AI to predict market trends and enhance client outcomes.
        **Custom Reports:** Generate detailed reports for client reviews.
      `,
      image: "/assets/images/zero-stocks/financial-advisors.webp"
    },
  
    "Institutional Investors": {
      title: "Institutional Investors",
      description: `
        Support large-scale trading operations with advanced data streams and analytics tools.
        
        **Key Features:**
        **Data Streams:** Access high-frequency data for better trading decisions.
        **AI Analytics:** Leverage AI models for precise market predictions.
        **Custom Reporting Tools:** Generate custom insights tailored to institutional needs.
      `,
      image: "/assets/images/zero-stocks/institutional-investors.webp"
    },
  
    "Corporate Finance Teams": {
      title: "Corporate Finance Teams",
      description: `
        Help corporate finance teams effectively manage company investments in volatile markets.
        
        **Key Features:**
        **Market Monitoring:** Track market conditions in real time to make informed decisions.
        **Risk Mitigation Tools:** Use AI-driven risk assessments to safeguard investments.
        **Investment Planning:** Create strategic investment plans for long-term growth.
      `,
      image: "/assets/images/zero-stocks/corporate-finance.webp"
    },
  
    "Wealth Management Firms": {
      title: "Wealth Management Firms",
      description: `
        Provide wealth management firms with the tools to deliver tailored financial advisory services to clients.
        
        **Key Features:**
        **Client Personalisation:** Offer bespoke strategies for high-net-worth individuals.
        **AI Insights:** Leverage AI models for accurate risk and portfolio analysis.
        **Comprehensive Dashboards:** Manage client data and investment goals seamlessly.
      `,
      image: "/assets/images/zero-stocks/wealth-management.webp"
    }
  };

  
  export const SecurityAndComplianceDetails = {
    "AES-256 Encryption": {
      title: "AES-256 Encryption",
      description: `
        Ensure the highest level of data security with AES-256 encryption, safeguarding user data both in transit and at rest.
        
        **Key Features:**
        **End-to-End Protection:** Encrypts data during transmission and storage.
        **Secure Data Storage:** Prevent unauthorised access with industry-standard encryption protocols.
        **Compliance-Ready:** Meets encryption requirements for global financial regulations.
      `,
      image: "/assets/images/zero-stocks/encryption-security.webp"
    },
  
    "Two-Factor Authentication (2FA)": {
      title: "Two-Factor Authentication (2FA)",
      description: `
        Add an extra layer of security to user accounts with mandatory two-factor authentication (2FA).
        
        **Key Features:**
        **Enhanced User Authentication:** Prevent unauthorised access with identity verification.
        **Device-Specific Codes:** Ensure secure logins with dynamically generated codes.
        **Seamless Integration:** Works across multiple devices and platforms.
      `,
      image: "/assets/images/zero-stocks/2fa-security.webp"
    },
  
    "Regulatory Compliance": {
      title: "Regulatory Compliance",
      description: `
        Maintain compliance with global financial and data protection regulations, ensuring a secure and lawful user experience.
        
        **Key Features:**
        **GDPR Adherence:** Protect user privacy and enable data minimisation practices.
        **SEC Compliance:** Meet US financial market standards for stock trading platforms.
        **ISO 27001 Standards:** Ensure information security management compliance.
      `,
      image: "/assets/images/zero-stocks/regulatory-compliance.webp"
    },
  
    "Audit Logs & Monitoring": {
      title: "Audit Logs & Monitoring",
      description: `
        Track every system interaction with comprehensive audit logs, aiding compliance and operational transparency.
        
        **Key Features:**
        **Complete Audit Trails:** Record all user and system activities.
        **Real-Time Monitoring:** Detect and respond to suspicious activities instantly.
        **Compliance Support:** Simplify regulatory reviews with detailed logs.
      `,
      image: "/assets/images/zero-stocks/audit-logs.webp"
    },
  
    "Role-Based Access Control (RBAC)": {
      title: "Role-Based Access Control (RBAC)",
      description: `
        Restrict access to sensitive data and functions by assigning permissions based on user roles.
        
        **Key Features:**
        **Granular Access Control:** Define permissions for each user role.
        **Data Security:** Prevent unauthorised access to critical systems.
        **Scalability:** Easily manage access as the team grows or shifts.
      `,
      image: "/assets/images/zero-stocks/rbac-security.webp"
    },
  
    "Data Breach Notification System": {
      title: "Data Breach Notification System",
      description: `
        Protect user trust with an automated notification system that alerts stakeholders immediately in the event of a data breach.
        
        **Key Features:**
        **Real-Time Alerts:** Notify users and admins instantly after detecting a breach.
        **Predefined Protocols:** Initiate corrective actions to minimise damage.
        **Compliance Reporting:** Meet regulatory requirements for breach notifications.
      `,
      image: "/assets/images/zero-stocks/breach-notification.webp"
    }
  };

  export const BusinessBenefitsDetails = {
    "Enhanced Profitability": {
      title: "Enhanced Profitability",
      description: `
        Maximise revenue streams and investment returns by leveraging advanced AI-driven insights for smarter trading decisions.
  
        **Key Features:**
        **Predictive Analytics:** AI models forecast stock trends, enabling optimal buy/sell timing.
        **Automated Strategies:** Automate portfolio rebalancing and trading to capture market opportunities instantly.
        **Cost Reduction:** Minimise human error and reduce costs through automated decision-making.
        **Performance Tracking:** Track and analyze portfolio performance with real-time analytics.
      `,
      image: "/assets/images/zero-stocks/profitability.webp",
    },
    "Operational Efficiency": {
      title: "Operational Efficiency",
      description: `
        Streamline portfolio management and daily operations with tools designed to automate and optimise workflows.
  
        **Key Features:**
        **Automated Portfolio Management:** Reduce manual workload with auto-balancing and diversification tools.
        **Scalable Architecture:** Handle growing user demands with microservices-based infrastructure.
        **Event-Driven Notifications:** Proactively respond to market events and portfolio changes.
        **Reduced Downtime:** Ensure uninterrupted operations with cloud-native systems and disaster recovery.
      `,
      image: "/assets/images/zero-stocks/efficiency.webp",
    },
    "Stronger Customer Engagement": {
      title: "Stronger Customer Engagement",
      description: `
        Deliver personalised user experiences that keep investors engaged and improve platform loyalty.
  
        **Key Features:**
        **AI-Personalised Dashboards:** Tailor portfolio views and recommendations for individual user goals.
        **Gamification Elements:** Introduce rewards and challenges to motivate active user participation.
        **Sentiment Analysis Integration:** Keep users informed with sentiment-driven market updates.
        **Real-Time Insights:** Provide actionable data on stocks, industries, and overall market health.
      `,
      image: "/assets/images/zero-stocks/customer-engagement.webp",
    },
    "Revenue from Subscription Models": {
      title: "Revenue from Subscription Models",
      description: `
        Generate consistent revenue streams with tiered subscription models offering premium features and tools.
  
        **Key Features:**
        **Freemium Access:** Attract new users with a basic free plan that drives upsell opportunities.
        **Premium Features:** Offer advanced analytics, technical tools, and personalised strategies at a cost.
        **Enterprise Plans:** Cater to institutional clients with high-value, large-scale portfolio solutions.
        **Subscription Analytics:** Monitor user subscriptions and optimise for revenue growth.
      `,
      image: "/assets/images/zero-stocks/revenue-subscription.webp",
    },
    "Scalability with Cloud Infrastructure": {
      title: "Scalability with Cloud Infrastructure",
      description: `
        Ensure seamless growth and global reach with a scalable, cloud-native infrastructure.
  
        **Key Features:**
        **Global Coverage:** Utilise AWS, GCP, and Azure for worldwide accessibility.
        **Disaster Recovery:** Safeguard operations with automated backups and recovery systems.
        **Elastic Resource Allocation:** Dynamically scale resources based on market demand.
        **Enhanced Speed:** Leverage optimised infrastructure for fast data retrieval and processing.
      `,
      image: "/assets/images/zero-stocks/scalability.webp",
    },
    "Regulatory Compliance Advantage": {
      title: "Regulatory Compliance Advantage",
      description: `
        Gain a competitive edge by adhering to global regulations and building trust with secure and compliant systems.
  
        **Key Features:**
        **GDPR Compliance:** Protect user data and provide transparency in data handling.
        **Audit Trails:** Ensure traceable actions for full regulatory compliance.
        **ISO Standards:** Operate under ISO 27001-certified security protocols.
        **AML/KYC Support:** Simplify identity verification and compliance for financial transactions.
      `,
      image: "/assets/images/zero-stocks/compliance-advantage.webp",
    },
  };  

  
  export const FutureEnhancementsDetails = {
    "AI Model Upgrades": {
      title: "AI Model Upgrades",
      description: `
        Enhance predictive analytics and decision-making capabilities by integrating next-generation machine learning models.
        
        **Key Features:**
        **Enhanced Predictive Accuracy:** Analyze broader datasets for improved stock price predictions.
        **Continuous Learning:** Models retrain with new market data for adaptive performance.
        **Multi-Factor Analysis:** Incorporate technical, sentiment, and macroeconomic indicators.
      `,
      image: "/assets/images/zero-stocks/ai-model-upgrades.webp"
    },
  
    "Global Expansion": {
      title: "Global Expansion",
      description: `
        Extend Zero-Stocks' services to international markets, meeting local regulatory standards to unlock global investment opportunities.
        
        **Key Features:**
        **Localised Compliance:** Adhere to regulations in multiple jurisdictions.
        **Multi-Currency Support:** Allow trading and reporting in various currencies.
        **Global Market Insights:** Provide region-specific analytics and data feeds.
      `,
      image: "/assets/images/zero-stocks/global-expansion.webp"
    },
  
    "Gamification Features": {
      title: "Gamification Features",
      description: `
        Drive user engagement with fun and educational gamified elements, enhancing the investment learning experience.
        
        **Key Features:**
        **Achievement Badges:** Reward users for milestones like diversification or returns.
        **Learning Challenges:** Offer educational modules with incentivized goals.
        **Community Rankings:** Create leaderboards to foster friendly competition.
      `,
      image: "/assets/images/zero-stocks/gamification-features.webp"
    },
  
    "Voice-Activated Trading": {
      title: "Voice-Activated Trading",
      description: `
        Enable hands-free trading with voice commands for faster and more convenient user interactions.
        
        **Key Features:**
        **Natural Language Processing (NLP):** Understand complex voice commands like "Buy 50 shares of XYZ."
        **Real-Time Execution:** Process voice commands instantly for timely trades.
        **Multi-Language Support:** Enable voice trading in multiple languages.
      `,
      image: "/assets/images/zero-stocks/voice-activated-trading.webp"
    },
  
    "Social Trading Integration": {
      title: "Social Trading Integration",
      description: `
        Build a collaborative community where users can share insights, strategies, and follow top traders.
        
        **Key Features:**
        **Copy Trading:** Replicate trades of experienced investors in real-time.
        **User Profiles:** Showcase trading history and success metrics.
        **Discussion Forums:** Enable knowledge sharing and collaborative learning.
      `,
      image: "/assets/images/zero-stocks/social-trading.webp"
    },
  
    "Quantum Computing Compatibility": {
      title: "Quantum Computing Compatibility",
      description: `
        Future-proof Zero-Stocks by ensuring its infrastructure can integrate quantum computing advancements for financial modeling and analysis.
        
        **Key Features:**
        **Quantum-Enhanced Simulations:** Perform ultra-complex market analyses and risk assessments.
        **Post-Quantum Security:** Adopt encryption protocols that withstand quantum threats.
        **Accelerated Calculations:** Process vast amounts of data faster than ever.
      `,
      image: "/assets/images/zero-stocks/quantum-computing-compatibility.webp"
    }
  };
  