export const CoreFeatures = [
    {
      title: "Real-Time Market Monitoring",
      description: 
        "Track live market activity with real-time transaction insights, giving regulators complete visibility into fast-moving markets.",
      image: "/assets/images/zero-supervise/real-time-monitoring.webp",
    },
    {
      title: "Predictive Compliance",
      description: 
        "AI-driven predictive models forecast potential regulatory breaches before they occur, enabling preemptive risk mitigation.",
      image: "/assets/images/zero-supervise/predictive-compliance.webp",
    },
    {
      title: "Customisable Dashboards",
      description: 
        "Build custom dashboards with drag-and-drop widgets, offering personalised layouts for regulatory insights.",
      image: "/assets/images/zero-supervise/custom-dashboards.webp",
    },
    {
      title: "Blockchain Audit Trails",
      description: 
        "Create immutable audit trails using blockchain, ensuring a tamper-proof record of all financial and compliance activity.",
      image: "/assets/images/zero-supervise/blockchain-audit-trails.webp",
    },
    {
      title: "Automated Reporting",
      description: 
        "Generate automated compliance reports that are audit-ready, reducing the manual workload of compliance officers.",
      image: "/assets/images/zero-supervise/automated-reporting.webp",
    },
    {
      title: "Advanced Analytics",
      description: 
        "Utilise advanced analytics and visualisations to provide deep insights into compliance trends, supporting informed decisions.",
      image: "/assets/images/zero-supervise/advanced-analytics.webp",
    }
  ];

export const TechnicalCapabilities = [
    {
      title: "Microservices Architecture",
      description: 
        "Built with microservices to support modular deployment, scalability, and independent updates for individual services.",
      image: "/assets/images/zero-supervise/microservices-architecture.webp",
    },
    {
      title: "Cloud-Native Design",
      description: 
        "Zero-Supervise operates in cloud environments like AWS and Azure, ensuring resilience, scalability, and global reach.",
      image: "/assets/images/zero-supervise/cloud-native-design.webp",
    },
    {
      title: "Blockchain Integration",
      description: 
        "Leverage blockchain technology to create tamper-proof, verifiable audit trails of regulatory activity and market oversight.",
      image: "/assets/images/zero-supervise/blockchain-integration.webp",
    },
    {
      title: "Event-Driven System",
      description: 
        "Event-driven design triggers automated actions and real-time alerts when market anomalies or compliance risks arise.",
      image: "/assets/images/zero-supervise/event-driven-system.webp",
    },
    {
      title: "Data Encryption & Role-Based Access Control",
      description: 
        "Protects user data with AES-256 encryption and limits access to sensitive information using Role-Based Access Control (RBAC).",
      image: "/assets/images/zero-supervise/data-encryption-rbac.webp",
    },
    {
      title: "Real-Time Data Stream",
      description: 
        "Processes live transactional data feeds and updates dashboards, providing instant insights into financial markets.",
      image: "/assets/images/zero-supervise/real-time-data-stream.webp",
    }
  ];
  
export const KeyBenefits = [
    {
      title: "Proactive Risk Management",
      description: 
        "Predict regulatory breaches before they occur, ensuring regulators stay ahead of potential issues.",
      image: "/assets/images/zero-supervise/risk-management.webp",
    },
    {
      title: "Audit-Ready Reports",
      description: 
        "Create exportable reports ready for audit submission, drastically reducing manual reporting effort.",
      image: "/assets/images/zero-supervise/audit-ready-reports.webp",
    },
    {
      title: "Compliance at Scale",
      description: 
        "Zero-Supervise scales with growing regulatory demands, ensuring compliance even as market complexity increases.",
      image: "/assets/images/zero-supervise/compliance-at-scale.webp",
    },
    {
      title: "Enhanced Transparency",
      description: 
        "Maintain a clear, transparent record of all activity using blockchain audit trails, providing verifiable data integrity.",
      image: "/assets/images/zero-supervise/transparent-records.webp",
    },
    {
      title: "Time & Cost Savings",
      description: 
        "Reduce manual effort with automated workflows, reporting, and risk analysis, allowing compliance teams to focus on strategic tasks.",
      image: "/assets/images/zero-supervise/time-cost-savings.webp",
    },
    {
      title: "Global Regulatory Support",
      description: 
        "Provides compliance tools aligned with major regulatory frameworks, including GDPR, MiFID, and ISO 27001.",
      image: "/assets/images/zero-supervise/global-regulatory-support.webp",
    }
  ];

export const UserPersonas = [
    {
      title: "Regulatory Authorities",
      description: 
        "Provide government bodies with oversight tools to monitor market behaviour, investigate misconduct, and enforce compliance.",
      image: "/assets/images/zero-supervise/regulatory-authorities.webp",
    },
    {
      title: "Central Banks",
      description: 
        "Enable central banks to supervise monetary policy, systemic risk, and liquidity, ensuring financial market stability.",
      image: "/assets/images/zero-supervise/central-banks.webp",
    },
    {
      title: "Compliance Teams",
      description: 
        "Support compliance teams at financial institutions with real-time dashboards, reporting tools, and early warning alerts.",
      image: "/assets/images/zero-supervise/compliance-teams.webp",
    },
    {
      title: "Internal Risk Managers",
      description: 
        "Equip internal risk managers with predictive insights, enabling them to mitigate risks and ensure compliance continuity.",
      image: "/assets/images/zero-supervise/internal-risk-managers.webp",
    },
    {
      title: "Financial Institutions",
      description: 
        "Banks, asset managers, and insurers rely on Zero-Supervise to manage regulatory compliance and maintain operational integrity.",
      image: "/assets/images/zero-supervise/financial-institutions.webp",
    },
    {
      title: "IT Administrators",
      description: 
        "Manage system integration, custom dashboards, and security configurations, ensuring smooth operation across the organisation.",
      image: "/assets/images/zero-supervise/it-administrators.webp",
    }
  ];
  
  export const KeyFeatures = [
    {
      title: "Real-Time Market Surveillance",
      description: 
        "Continuously monitor financial markets in real-time to detect potential misconduct, unusual trades, or market manipulation.",
      image: "/assets/images/zero-supervise/real-time-surveillance.webp",
    },
    {
      title: "Customisable Alert System",
      description: 
        "Set up personalised compliance alerts based on user-defined criteria, providing timely notifications for potential risks.",
      image: "/assets/images/zero-supervise/alert-system.webp",
    },
    {
      title: "Automated Workflow Triggers",
      description: 
        "Trigger automated workflows for investigation, audit preparation, and regulatory reporting, reducing manual workloads.",
      image: "/assets/images/zero-supervise/automated-workflows.webp",
    },
    {
      title: "Compliance Analytics Dashboard",
      description: 
        "Interactive dashboards offer a comprehensive view of market compliance metrics, enabling deeper analysis of key trends.",
      image: "/assets/images/zero-supervise/analytics-dashboard.webp",
    },
    {
      title: "Predictive Risk Indicators",
      description: 
        "AI-driven indicators forecast future risks based on historical data and real-time activity, supporting proactive risk management.",
      image: "/assets/images/zero-supervise/risk-indicators.webp",
    },
    {
      title: "Comprehensive Audit Trail",
      description: 
        "Maintain an immutable record of all actions, transactions, and regulatory checks, ensuring full transparency and traceability.",
      image: "/assets/images/zero-supervise/audit-trail.webp",
    }
  ];
  
export const SecurityAndCompliance = [
    {
      title: "GDPR & ISO 27001 Compliance",
      description: 
        "Adheres to GDPR and ISO 27001 standards, ensuring global data protection, privacy, and information security compliance.",
      image: "/assets/images/zero-supervise/gdpr-iso-compliance.webp",
    },
    {
      title: "Data Encryption (AES-256)",
      description: 
        "Applies AES-256 encryption to protect sensitive data at rest and in transit, ensuring privacy and security.",
      image: "/assets/images/zero-supervise/aes-encryption.webp",
    },
    {
      title: "Blockchain Audit Trail",
      description: 
        "Ensures data integrity with blockchain-powered audit trails, providing verifiable, immutable records of all activities.",
      image: "/assets/images/zero-supervise/blockchain-audit-trail.webp",
    },
    {
      title: "Role-Based Access Control (RBAC)",
      description: 
        "Only authorised users can access specific data and functionalities, limiting exposure to sensitive information.",
      image: "/assets/images/zero-supervise/rbac-control.webp",
    },
    {
      title: "Real-Time Anomaly Detection",
      description: 
        "AI-driven anomaly detection identifies suspicious transactions in real-time, helping regulators take corrective action.",
      image: "/assets/images/zero-supervise/anomaly-detection.webp",
    },
    {
      title: "Multi-Factor Authentication (MFA)",
      description: 
        "Enhances access security by requiring multiple forms of user verification, ensuring only authorised users gain access.",
      image: "/assets/images/zero-supervise/mfa-security.webp",
    }
  ];
  
export const FutureEnhancements = [
    {
        title: "AI-Enhanced Risk Scoring",
        description: 
        "AI-driven risk models will predict potential compliance breaches more accurately, improving regulatory oversight.",
        image: "/assets/images/zero-supervise/ai-risk-scoring.webp",
    },
    {
        title: "Voice-Activated Controls",
        description: 
        "Introduce voice commands to navigate dashboards and generate compliance reports on demand.",
        image: "/assets/images/zero-supervise/voice-controls.webp",
    },
    {
        title: "Mobile Application",
        description: 
        "Enable users to monitor and respond to compliance alerts on-the-go with a dedicated Zero-Supervise mobile app.",
        image: "/assets/images/zero-supervise/mobile-app.webp",
    },
    {
        title: "DeFi Oversight Module",
        description: 
        "Support regulatory oversight of DeFi protocols and crypto transactions, allowing regulators to monitor decentralised finance.",
        image: "/assets/images/zero-supervise/defi-oversight.webp",
    },
    {
        title: "Customisable Reporting Templates",
        description: 
        "Allow users to create and save custom report templates, making it easier to generate recurring audit reports.",
        image: "/assets/images/zero-supervise/custom-reporting-templates.webp",
    },
    {
        title: "Multi-Language Support",
        description: 
        "Enable global regulatory teams to use the platform in their preferred language, improving usability across regions.",
        image: "/assets/images/zero-supervise/multi-language-support.webp",
    }
  ];
  